import { LitElement, css, html } from 'lit';

export class GameItem extends LitElement {
  static properties = { item: { type: Object }, pad: { type: Boolean } };

  constructor() {
    super();
    this.item = {};
    this.pad = false;
  }

  willUpdate(changed) {
    if (changed.has('item')) {
      const pad = this.pad ? '1.5' : '0';
      const item = this.item;

      const styles = {
        top: `${item.top * 100 - Number(pad)}%`,
        left: `${item.left * 100 - Number(pad)}%`,
        width: `${item.width}%`,
        padding: `${pad}%`,
      };

      this.style.left = styles.left;
      this.style.top = styles.top;
      this.style.width = styles.width;
      this.style.padding = styles.padding;

      if (item.rotate && item.rotate > 0) {
        this.style.setProperty('transform', `rotate(${item.rotate - 90}deg)`);
      }
    }
  }

  render() {
    return html` ${this.item.icon}`;
  }
}
const OncGameItem = class OncGameItem extends GameItem {
  static styles = css`
    :host {
      position: absolute;

      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    :host(.border) {
      border: 1px solid cyan;
    }

    :host(.border.alt) {
      border: 1px solid blue;
    }
  `;
};
customElements.define('onc-game-item', OncGameItem);
