import { animate, fadeIn } from '@lit-labs/motion';
import { ContextConsumer } from '@lit/context';
import { Task } from '@lit/task';
import { taskContext } from '@tasks/task-context';
import { LitElement, css, html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { when } from 'lit/directives/when.js';
import { taskInsGraphics } from '~/graphics/task-ins-graphics';
import { borderCollie } from '~/graphics/trailer/border-collie-001.js';

export class TaskTrailer extends LitElement {
  static properties = {
    choiceOnly: { type: Boolean },
    shifted: {},
    shifted2: {},
    blink: {},
    visible: {},
    showActions: {},
    highlightDemo: {},
    highlightPlay: {},
    bgItems: { type: Array },
  };

  constructor() {
    super();
    this.shifted = false;
    this.shifted2 = false;
    this.visible = false;
    this.blink = false;
    this.showActions = false;
    this.taskController = undefined;
    this.highlightDemo = false;
    this.highlightPlay = false;
    this.choiceOnly = false;

    this.action = 'play';

    this.state = 0;
    this.bgItems = [];

    new ContextConsumer(this, {
      context: taskContext,

      callback: value => (this.taskController = value),
    });

    this.main = new Task(this, {
      task: () => {
        if (this.choiceOnly) {
          this.showActions = true;
          this.explainActions(this.taskController);
        }
      },
      args: () => [],
    });
  }

  render() {
    return html`
      ${this.renderBGItems()}
      ${when(this.showActions, () => {
        const game = this.taskController.gameName;

        return html`
          <div class="actions">
            <div class="action-wrapper  ${this.highlightDemo ? 'highlight' : ''}">
              <onc-svg-icon slot="icon" class="action action-demo" @click=${this.onDemo}
                >${taskInsGraphics[`${game}-game`]}</onc-svg-icon
              >
            </div>
            <div class="action-wrapper ${this.highlightPlay ? 'highlight' : ''}">
              <onc-svg-icon slot="icon" class="action" @click=${this.onPlay}
                >${taskInsGraphics[`${game}-game`]}</onc-svg-icon
              >
            </div>
          </div>
        `;
      })}
      ${when(
        this.choiceOnly,
        () => {
          return html`
            <div class="smudge visible shifted2 blink">
              <onc-svg-icon> ${borderCollie}</onc-svg-icon>
            </div>
          `;
        },
        () => {
          return html`
            <div
              class="smudge ${this.visible ? 'visible' : ''}  ${this.shifted ? 'shifted' : ''} ${this.blink
                ? 'blink'
                : ''} ${this.shifted2 ? 'shifted2' : ''}"
              ${animate({
                keyframeOptions: {
                  duration: 1000, // 2000
                },
                in: fadeIn,
                properties: ['left', 'top', 'width', 'height'],
                onComplete: () => this.onComplete(),
              })}
            >
              <onc-svg-icon> ${borderCollie}</onc-svg-icon>
            </div>
          `;
        },
      )}
    `;
  }

  renderBGItems() {
    const border = false;
    const items = this.bgItems.map(
      item => html`<onc-game-item class=${classMap({ border })} .item=${item}>${item.icon}</onc-game-item>`,
    );
    return html` <div class="content">${items}</div> `;
  }

  onPlay() {
    this.action = 'play';
    this.taskController.stop(this.soundPromise);
    this.disposed = true;
    this.dispatchEvent(new Event('next'));
  }

  onDemo() {
    this.action = 'demo';
    this.taskController.stop(this.soundPromise);
    this.disposed = true;
    this.dispatchEvent(new Event('next'));
  }

  async onComplete() {
    if (this.disposed) {
      return;
    }
    switch (this.state) {
      case 0: {
        this.shifted = true;
        this.blink = true;
        this.state = 1;
        break;
      }
      case 1: {
        try {
          const soundKey = `${this.taskController.gameName} trailer`;
          this.soundPromise = this.taskController.playSound(soundKey);
          await this.soundPromise;
          this.shifted = false;
          this.shifted2 = true;
          this.state = 2;
        } catch (error) {
          if (error?.name !== 'AbortError') {
            console.log(error);
          }
        }
        break;
      }
      case 2: {
        this.showActions = true;
        this.explainActions(this.taskController);
        break;
      }
    }
  }

  async explainActions(taskController) {
    try {
      this.highlightDemo = true;

      this.soundPromise = taskController.playSound('choice demo');

      await this.soundPromise;

      if (this.disposed) {
        return;
      }

      // setTimeout(async () => {
      this.highlightDemo = false;
      this.highlightPlay = true;

      this.soundPromise = taskController.playSound('choice play');

      await this.soundPromise;

      if (this.disposed) {
        return;
      }

      this.highlightPlay = false;
    } catch (error) {
      if (error?.name !== 'AbortError') {
        console.log(error);
      }
    }
  }

  connectedCallback() {
    super.connectedCallback();

    this.visible = true;
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.disposed = true;

    this.taskController.stop(this.soundPromise);
  }
}
const OncTaskTrailer = class OncTaskTrailer extends TaskTrailer {
  static styles = css`
    .actions {
      --md-filled-button-container-shape: 9px;
      --md-sys-color-primary: #006a6a;
      --md-sys-color-on-primary: #fff;

      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    .action {
      --onc-svg-icon-width: 100%;
      --onc-svg-icon-height: 100%;
      --adt-ins-display: none;
      --adt-dis-display: none; --adt-fin-display: none;

      cursor: pointer;
      display: block;
      width: 100%;
      width: 150px;
      width: 15rem;
    }

    .action-demo{
      --adt-fin-display: block;
    }

    .action-wrapperX {
      position: relative;

      padding: 5px;

      background: blue;
      background: linear-gradient(#e66465, #9198e5);
      background-clip: padding-box;
      border: 3px solid transparent;
      border-radius: 10px;
    }

    .action-wrapper {
      position: relative;

      padding: 5px;

      background: transparent
      background-clip: padding-box;
      border: 3px solid transparent;
      border-radius: 10px;
    }

    .action-wrapper.highlight {
      border: 3px solid red;
    }

    .action-wrapper + .action-wrapper {
      margin-left: 2em;
    }

    .action + .action {
      margin-left: 2em;
    }

    .smudge {
      position: absolute;
      top: 60%;
      right: 80%;

      display: none;

      width: 200px;
    }



    .visible {
      display: block;
    }

    .shifted {
      top: 50%;
      right: 30%;
      width: 300px;
    }

    .shifted2 {
      top: 50%;
      right: 5%;
      width: 300px;
    }


    @media only screen and (width < 750px  ) {
      .smudge{
        width: 100px;
      }

      .shifted {
        width: 200px;
      }

      .shifted2 {
        width: 200px;
      }

      .action {

        width: 10rem;
      }

    }

    .blink #right-eye,
    .blink #left-eye {
      transform-origin: 50% 50%;
      transform-box: fill-box;
      animation: squeeze 2.4s infinite;
    }

    @keyframes squeeze {
      85% {
        transform: none;
        animation-timing-function: ease-in;
      }

      93% {
        transform: translateY(2px) scaleY(0);
      }

      100% {
        animation-timing-function: ease-out;
      }
    }
  `;
};
customElements.define('onc-task-trailer', OncTaskTrailer);
