import { html } from 'lit';

export const fencepathNarrow = html`
  <svg version="1.1" viewBox="0 0 1365 254" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <clipPath id="clipPath9712">
        <rect x="7.629e-9" width="1365" height="125" fill="#121416" opacity=".598" stroke-width=".8154" />
      </clipPath>
    </defs>
    <g transform="translate(2.773 129)" clip-path="url(#clipPath9712)" stroke-width="1.333">
      <path d="m0 188h1365v-188h-1365z" fill="#bfbda6" />
      <path d="m-5.385 78.65 6.348-3.555 11.73-23.94c-17.68-6.592 0 0-42.76 2.001l-13.32 26.15z" fill="#cf6e0d" />
      <path
        d="m-42.82 41.37c18.29-0.07065 23.97 3.37 38.9-3.85 0.7851-0.3799 2.175-1.346 3.295-2.135l17.26-33.84-39.8 0.9238z"
        fill="#a6523b"
      />
      <path
        d="m30.81 130.9-16.9 37.58c21.14 1.988 30.69 1.22 42.12 5.801 8.105-12.16 15.52-35.1 14.72-44.24-35.07 2.742-11.16-1.318-39.94 0.8664"
        fill="#c78a57"
      />
      <path d="m91.8 78.65 6.085-3.555 9.952-23.94c-18.16-6.592 0 0-42.62 2.001l-11.37 26.15z" fill="#cf6e0d" />
      <path
        d="m51.59 41.37c18.29-0.07065 24.22 3.37 38.62-3.85 0.7571-0.3799 2.075-1.346 3.137-2.135l14.74-33.84-39.74 0.9238z"
        fill="#a6523b"
      />
      <path d="m-29.61 210.3c7.489 1.537 13.88 1.953 21.83 2.115l27.1-6.74 12.74-31.81-45.26 0.9651z" fill="#d98c4a" />
      <path d="m-33.88 161.5c1.902 3.878 0.3239 3.391 5.269 5.633l37.2 0.3306 15.72-35.86-42.6-1.177z" fill="#d98c4a" />
      <path
        d="m22.45 93.58-11.45 23.86c5.628 10.79 26.69 7.833 40.71 4.808l14.08-33.01c-11.44-4.083-28.55-5.979-43.35 4.328"
        fill="#cf6e0d"
      />
      <path
        d="m17.29 53.1-10.67 21.9c1.261 3.528 0.1413 4.555 4.211 6.816l35.04-1.742 14.48-32.42c-13.49-4.468-27.83-7.617-43.06 5.444"
        fill="#d68747"
      />
      <path d="m0 122.9 2.75-0.2 15.18-32.69-17.93-1.59z" fill="#a6523b" />
      <path
        d="m133 132.5-14.06 37.58c21.31 1.988 30.82 1.218 42.6 5.801 7.19-12.16 12.87-35.1 11.36-44.24-34.91 2.743-11.28-1.318-39.91 0.8678"
        fill="#c78a57"
      />
      <path d="m190 80.63 5.823-3.554 8.145-23.95c-18.69-6.592 0 0-42.52 2.001l-9.396 26.15z" fill="#cf6e0d" />
      <path
        d="m146.9 42.97c18.3-0.07065 24.51 3.37 38.36-3.85 0.7292-0.3799 1.976-1.346 2.978-2.135l12.19-33.84-39.71 0.9238z"
        fill="#a6523b"
      />
      <path d="m78.53 211.9c7.614 1.537 14.04 1.953 22.01 2.115l26.62-6.74 10.33-31.81-45.24 0.9664z" fill="#d98c4a" />
      <path
        d="m70.52 163.1c2.199 3.878 0.5812 3.391 5.704 5.633l37.27 0.3306 13.01-35.86-42.75-1.177z"
        fill="#a6523b"
      />
      <path
        d="m121.8 95.2-9.643 23.86c6.456 10.79 27.31 7.833 41.12 4.808l11.58-33.01c-11.77-4.082-29.05-5.977-43.07 4.33"
        fill="#cf6e0d"
      />
      <path d="m80.21 176.5-39.72-1.065-15.42 33.5 45.52 2.446c11.31-28.93 8.795-16.84 9.611-34.88" fill="#cf6e0d" />
      <path
        d="m113.4 55.13-9.014 21.9c1.532 3.528 0.4879 4.555 4.737 6.816l34.96-1.744 12.02-32.42c-13.86-4.468-28.46-7.617-42.71 5.444"
        fill="#d68747"
      />
      <path d="m60.94 120.6 3.073 6.69 40.23-2.933 12.71-32.69-35.32-3.11-5.931 0.5719z" fill="#c97317" />
      <path
        d="m16.05 10.84-12.25 23.86c4.607 10.79 24.55 7.833 37.92 4.808l15.22-33.01c-10.47-4.082-26.39-5.977-40.9 4.33"
        fill="#cf6e0d"
      />
      <path
        d="m107.7 9.778-10.49 23.86c5.393 10.79 25.11 7.833 38.24 4.808l12.8-33.01c-10.76-4.083-26.81-5.979-40.55 4.328"
        fill="#cf6e0d"
      />
      <path d="m175.6 164.7c2.498 3.878 0.8411 3.391 6.14 5.633l37.34 0.3306 10.29-35.86-42.88-1.177z" fill="#d98c4a" />
      <path d="m186.2 178.1-39.44-1.91-13.28 34.35 45.76 2.446c9.112-28.93 7.522-16.84 6.962-34.88" fill="#a6523b" />
      <path
        d="m210.2 56.7-7.354 21.9c1.804 3.528 0.8371 4.555 5.263 6.816l34.87-1.742 9.563-32.42c-14.21-4.468-29.07-7.617-42.35 5.444"
        fill="#d68747"
      />
      <path d="m162.8 122.1 3.586 6.69 40.06-2.933 10.23-32.69-35.6-3.11-5.895 0.5719z" fill="#c97317" />
      <path
        d="m201 11.51-8.682 23.86c6.224 10.79 25.74 7.833 38.66 4.808l10.3-33.01c-11.09-4.083-27.3-5.979-40.27 4.328"
        fill="#cf6e0d"
      />
      <path
        d="m239.4 133.2-11.12 37.58c21.47 1.988 30.93 1.22 43.08 5.801 6.238-12.16 10.12-35.1 7.895-44.24-34.71 2.742-11.39-1.32-39.86 0.8664"
        fill="#c78a57"
      />
      <path d="m292.3 81.41 5.547-3.555 6.269-23.95c-19.22-6.59 0 0-42.4 2.002l-7.346 26.15z" fill="#cf6e0d" />
      <path
        d="m247.8 41.64c18.28-0.07065 24.75 3.37 38.03-3.85 0.6998-0.3799 1.868-1.346 2.807-2.135l9.519-33.84-39.6 0.9238z"
        fill="#a6523b"
      />
      <path d="m191.2 212.6c7.738 1.537 14.2 1.953 22.19 2.115l26.1-6.741 7.843-31.81-45.19 0.9651z" fill="#d98c4a" />
      <path
        d="m225.3 95.85-7.774 23.86c7.306 10.79 27.94 7.833 41.52 4.808l8.998-33.01c-12.1-4.082-29.54-5.977-42.75 4.33"
        fill="#cf6e0d"
      />
      <path
        d="m341.6 130.9-8.307 37.58c21.59 1.988 30.97 1.22 43.44 5.801 5.325-12.16 7.493-35.1 4.596-44.24-34.44 2.742-11.47-1.318-39.74 0.8664"
        fill="#c78a57"
      />
      <path d="m390.7 78.65 5.272-3.555 4.479-23.94c-19.66-6.592 0 0-42.16 2.001l-5.392 26.15z" fill="#cf6e0d" />
      <path
        d="m341.9 41.37c18.26-0.07065 24.99 3.37 37.74-3.85 0.6705-0.3799 1.768-1.346 2.649-2.135l7.012-33.84-39.52 0.9238z"
        fill="#a6523b"
      />
      <path d="m299.4 210.3c7.841 1.537 14.33 1.953 22.31 2.115l25.57-6.74 5.469-31.81-45.04 0.9651z" fill="#d98c4a" />
      <path d="m283.9 161.5c2.79 3.878 1.098 3.391 6.557 5.633l37.27 0.3306 7.517-35.86-42.87-1.177z" fill="#a6523b" />
      <path
        d="m324.7 93.58-5.99 23.86c8.097 10.79 28.47 7.833 41.82 4.808l6.532-33.01c-12.38-4.083-29.93-5.979-42.36 4.328"
        fill="#cf6e0d"
      />
      <path d="m295.7 174.9-40.23 1.636-9.898 30.81 45.86 2.446c6.877-28.93 6.214-16.84 4.275-34.88" fill="#cf6e0d" />
      <path
        d="m310.3 53.1-5.661 21.9c2.069 3.528 1.182 4.555 5.769 6.816l34.66-1.742 7.057-32.42c-14.52-4.468-29.58-7.617-41.82 5.444"
        fill="#d68747"
      />
      <path d="m267.9 118.9 4.09 6.69 39.74-2.933 7.707-32.69-35.75-3.109-5.836 0.5705z" fill="#c97317" />
      <path
        d="m442.7 130.9-5.515 37.58c21.74 1.988 31.06 1.22 43.88 5.801 4.422-12.16 4.884-35.1 1.306-44.24-34.24 2.742-11.56-1.318-39.67 0.8664"
        fill="#c78a57"
      />
      <path d="m487.9 78.65 5.008-3.555 2.698-23.94c-20.15-6.592 0 0-42.02 2.001l-3.447 26.15z" fill="#cf6e0d" />
      <path
        d="m436.4 41.37c18.26-0.07065 25.25 3.37 37.44-3.85 0.6425-0.3799 1.668-1.346 2.49-2.135l4.496-33.84-39.46 0.9238z"
        fill="#a6523b"
      />
      <path d="m406.4 210.3c7.955 1.537 14.48 1.953 22.47 2.115l25.06-6.74 3.106-31.81-44.96 0.9651z" fill="#d98c4a" />
      <path d="m387.2 161.5c3.078 3.878 1.35 3.391 6.976 5.633l37.3 0.3306 4.853-35.86-42.96-1.177z" fill="#d98c4a" />
      <path
        d="m423.1 93.58-4.216 23.86c8.898 10.79 29.06 7.833 42.18 4.808l4.079-33.01c-12.68-4.083-30.37-5.979-42.03 4.328"
        fill="#cf6e0d"
      />
      <path d="m400 174.9-39.92-1.262-7.79 33.7 46.03 2.446c4.728-28.93 4.965-16.84 1.684-34.88" fill="#cf6e0d" />
      <path
        d="m405.6 53.1-4.034 21.9c2.33 3.528 1.521 4.555 6.277 6.816l34.52-1.742 4.648-32.42c-14.85-4.468-30.14-7.617-41.42 5.444"
        fill="#d68747"
      />
      <path d="m368 118.9 4.587 6.69 39.51-2.933 5.277-32.69-35.99-3.109-5.793 0.5705z" fill="#a6523b" />
      <path
        d="m545.3 132.5-2.677 37.58c21.91 1.988 31.18 1.218 44.36 5.801 3.507-12.16 2.235-35.1-2.039-44.24-34.07 2.743-11.68-1.318-39.64 0.8678"
        fill="#c78a57"
      />
      <path d="m586.7 80.63 4.747-3.554 0.8891-23.95c-20.69-6.592 0 0-41.92 2.001l-1.472 26.15z" fill="#cf6e0d" />
      <path
        d="m532.3 42.97c18.28-0.07065 25.53 3.37 37.2-3.85 0.6145-0.3799 1.568-1.346 2.331-2.135l1.941-33.84-39.43 0.9238z"
        fill="#a6523b"
      />
      <path d="m514.9 211.9c8.081 1.537 14.64 1.953 22.66 2.115l24.58-6.74 0.7012-31.81-44.95 0.9664z" fill="#d98c4a" />
      <path d="m492.1 163.1c3.375 3.878 1.609 3.391 7.41 5.633l37.36 0.3306 2.145-35.86-43.1-1.177z" fill="#a6523b" />
      <path
        d="m522.8 95.2-2.414 23.86c9.726 10.79 29.69 7.833 42.59 4.808l1.586-33.01c-13.01-4.082-30.86-5.977-41.76 4.33"
        fill="#cf6e0d"
      />
      <path d="m506 176.5-40.04-1.065-5.269 33.5 46.27 2.446c2.542-28.93 3.695-16.84-0.9571-34.88" fill="#cf6e0d" />
      <path
        d="m502.4 55.13-2.379 21.9c2.602 3.528 1.869 4.555 6.802 6.816l34.44-1.744 2.199-32.42c-15.21-4.468-30.77-7.617-41.06 5.444"
        fill="#d68747"
      />
      <path d="m469.7 120.6 5.099 6.69 39.35-2.933 2.809-32.69-36.27-3.11-5.757 0.5719z" fill="#c97317" />
      <g fill="#cf6e0d">
        <path
          d="m303.1 9.378-6.728 23.86c7.093 10.79 26.34 7.833 38.99 4.808l7.595-33.01c-11.4-4.083-27.74-5.979-39.86 4.328"
        />
        <path
          d="m391.6 10.84-5.017 23.86c7.877 10.79 26.94 7.833 39.38 4.808l5.231-33.01c-11.71-4.082-28.21-5.977-39.59 4.33"
        />
        <path
          d="m482.9 9.778-3.261 23.86c8.662 10.79 27.49 7.833 39.7 4.808l2.802-33.01c-12-4.083-28.62-5.979-39.24 4.328"
        />
      </g>
      <path
        d="m597.3 133.2-1.244 37.58c21.99 1.988 31.25 1.22 44.6 5.801 3.045-12.16 0.8971-35.1-3.727-44.24-33.99 2.742-11.73-1.32-39.63 0.8664"
        fill="#c78a57"
      />
      <path
        d="m581.6 41.64c18.26-0.07065 25.63 3.37 37.02-3.85 0.5998-0.3799 1.514-1.346 2.246-2.135l0.6278-33.84-39.36 0.9238z"
        fill="#a6523b"
      />
      <path
        d="m569.9 212.6c8.142 1.537 14.72 1.953 22.74 2.115l24.34-6.741-0.5105-31.81-44.92 0.9651z"
        fill="#d98c4a"
      />
      <path
        d="m573.3 95.85-1.505 23.86c10.14 10.79 29.99 7.833 42.79 4.808l0.3279-33.01c-13.17-4.082-31.1-5.977-41.62 4.33"
        fill="#cf6e0d"
      />
      <path
        d="m698.9 130.9 1.565 37.58c22.11 1.988 31.29 1.22 44.98 5.801 2.13-12.16-1.728-35.1-7.029-44.24-33.72 2.742-11.81-1.318-39.51 0.8664"
        fill="#c78a57"
      />
      <path d="m734.2 78.65 4.338-3.555-1.813-23.94c-21.39-6.592 0 0-41.63 2.001l1.48 26.15z" fill="#cf6e0d" />
      <path d="m635.8 78.65 4.606-3.555-0.012-23.94c-20.9-6.592 0 0-41.79 2.001l-0.4879 26.15z" fill="#cf6e0d" />
      <path
        d="m675.7 41.37c18.25-0.07065 25.87 3.37 36.72-3.85 0.5705-0.3799 1.414-1.346 2.087-2.135l-1.88-33.84-39.28 0.9238z"
        fill="#a6523b"
      />
      <path d="m677.6 210.3c8.245 1.537 14.84 1.953 22.87 2.115l23.79-6.74-2.887-31.81-44.79 0.9651z" fill="#d98c4a" />
      <path d="m649.3 161.5c3.808 3.878 1.99 3.391 8.037 5.633l37.36 0.3306-1.902-35.86-43.19-1.177z" fill="#a6523b" />
      <path
        d="m672.2 93.58 0.2799 23.86c10.93 10.79 30.53 7.833 43.08 4.808l-2.138-33.01c-13.45-4.083-31.5-5.979-41.22 4.328"
        fill="#cf6e0d"
      />
      <path d="m664.5 174.9-39.8 1.636-1.805 30.81 46.5 2.446c-0.7225-28.93 1.793-16.84-4.889-34.88" fill="#cf6e0d" />
      <path
        d="m647.2 53.1 0.0933 21.9c2.995 3.528 2.379 4.555 7.559 6.816l34.19-1.742-1.46-32.42c-15.69-4.468-31.58-7.617-40.39 5.444"
        fill="#d68747"
      />
      <path d="m622 118.9 5.848 6.69 38.96-2.933-0.8798-32.69-36.58-3.109-5.685 0.5705z" fill="#c97317" />
      <path
        d="m799.9 130.9 4.358 37.58c22.26 1.988 31.38 1.22 45.4 5.801 1.226-12.16-4.335-35.1-10.32-44.24-33.52 2.742-11.91-1.318-39.44 0.8664"
        fill="#c78a57"
      />
      <path d="m831.4 78.65 4.074-3.555-3.594-23.94c-21.89-6.592 0 0-41.48 2.001l3.424 26.15z" fill="#cf6e0d" />
      <path
        d="m770.1 41.37c18.24-0.07065 26.13 3.37 36.43-3.85 0.5425-0.3799 1.314-1.346 1.929-2.135l-4.395-33.84-39.22 0.9238z"
        fill="#a6523b"
      />
      <path d="m784.5 210.3c8.359 1.537 14.98 1.953 23.02 2.115l23.29-6.74-5.251-31.81-44.71 0.9651z" fill="#d98c4a" />
      <path d="m752.6 161.5c4.096 3.878 2.242 3.391 8.455 5.633l37.39 0.3306-4.568-35.86-43.27-1.177z" fill="#d98c4a" />
      <path
        d="m770.5 93.58 2.053 23.86c11.73 10.79 31.11 7.833 43.43 4.808l-4.591-33.01c-13.76-4.083-31.94-5.979-40.9 4.328"
        fill="#cf6e0d"
      />
      <path d="m768.9 174.9-40.26-1.262 1.062 33.7 46.67 2.446c-2.871-28.93 0.5425-16.84-7.481-34.88" fill="#cf6e0d" />
      <path
        d="m742.5 53.1 1.721 21.9c3.258 3.528 2.718 4.555 8.067 6.816l34.06-1.742-3.868-32.42c-16.02-4.468-32.15-7.617-39.98 5.444"
        fill="#d68747"
      />
      <path d="m722.2 118.9 6.344 6.69 38.75-2.933-3.31-32.69-36.8-3.109-5.643 0.5705z" fill="#a6523b" />
      <path
        d="m903.1 132.5 7.196 37.58c22.43 1.988 31.5 1.218 45.9 5.801 0.3106-12.16-6.986-35.1-13.66-44.24-33.35 2.743-12.02-1.318-39.42 0.8678"
        fill="#c78a57"
      />
      <path d="m930.7 80.63 3.812-3.554-5.403-23.95c-22.42-6.592 0 0-41.4 2.001l5.4 26.15z" fill="#cf6e0d" />
      <path
        d="m866.3 42.97c18.26-0.07065 26.42 3.37 36.19-3.85 0.5145-0.3799 1.214-1.346 1.77-2.135l-6.952-33.84-39.19 0.9238z"
        fill="#a6523b"
      />
      <path d="m893.5 211.9c8.485 1.537 15.14 1.953 23.21 2.115l22.81-6.74-7.654-31.81-44.7 0.9664z" fill="#d98c4a" />
      <path d="m857.9 163.1c4.394 3.878 2.499 3.391 8.89 5.633l37.46 0.3306-7.277-35.86-43.42-1.177z" fill="#a6523b" />
      <path
        d="m870.7 95.2 3.855 23.86c12.56 10.79 31.74 7.833 43.86 4.808l-7.085-33.01c-14.08-4.082-32.43-5.977-40.62 4.33"
        fill="#cf6e0d"
      />
      <path d="m875.1 176.5-40.32-1.065 3.532 33.5 46.91 2.446c-5.057-28.93-0.7278-16.84-10.12-34.88" fill="#cf6e0d" />
      <path
        d="m839.7 55.13 3.375 21.9c3.528 3.528 3.066 4.555 8.594 6.816l33.98-1.744-6.317-32.42c-16.38-4.468-32.77-7.617-39.63 5.444"
        fill="#d68747"
      />
      <path d="m824.3 120.6 6.857 6.69 38.58-2.933-5.777-32.69-37.08-3.11-5.608 0.5719z" fill="#c97317" />
      <g fill="#cf6e0d">
        <path
          d="m628.5 9.378-0.4572 23.86c9.928 10.79 28.39 7.833 40.24 4.808l-1.074-33.01c-12.47-4.083-29.31-5.979-38.71 4.328"
        />
        <path
          d="m717.3 10.84 1.252 23.86c10.71 10.79 28.99 7.833 40.64 4.808l-3.44-33.01c-12.78-4.082-29.78-5.977-38.46 4.33"
        />
        <path
          d="m808.5 9.778 3.009 23.86c11.5 10.79 29.54 7.833 40.96 4.808l-5.868-33.01c-13.07-4.083-30.19-5.979-38.1 4.328"
        />
      </g>
      <path d="m963.8 164.7c4.692 3.878 2.759 3.391 9.326 5.633l37.52 0.3306-9.994-35.86-43.55-1.177z" fill="#d98c4a" />
      <path d="m982.2 178.1-40.52-1.91 6.153 34.35 47.15 2.446c-7.25-28.93-2.002-16.84-12.77-34.88" fill="#a6523b" />
      <path
        d="m937.4 56.7 5.035 21.9c3.8 3.528 3.414 4.555 9.119 6.816l33.88-1.742-8.775-32.42c-16.74-4.468-33.39-7.617-39.27 5.444"
        fill="#d68747"
      />
      <path d="m927 122.1 7.37 6.69 38.39-2.933-8.255-32.69-37.36-3.11-5.569 0.5719z" fill="#c97317" />
      <path
        d="m902.7 11.51 4.817 23.86c12.33 10.79 30.18 7.833 41.38 4.808l-8.369-33.01c-13.4-4.083-30.69-5.979-37.83 4.328"
        fill="#cf6e0d"
      />
      <path
        d="m1010 133.2 10.14 37.58c22.61 1.988 31.62 1.22 46.36 5.801-0.6398-12.16-9.734-35.1-17.13-44.24-33.17 2.742-12.13-1.32-39.38 0.8664"
        fill="#c78a57"
      />
      <path d="m1033 81.41 3.536-3.555-7.278-23.95c-22.94-6.59 0 0-41.27 2.002l7.45 26.15z" fill="#cf6e0d" />
      <path
        d="m966.6 41.64c18.24-0.07065 26.66 3.37 35.86-3.85 0.4839-0.3799 1.106-1.346 1.598-2.135l-9.626-33.84-39.08 0.9238z"
        fill="#a6523b"
      />
      <path d="m1007 212.6c8.608 1.537 15.3 1.953 23.38 2.115l22.29-6.741-10.15-31.81-44.63 0.9651z" fill="#d98c4a" />
      <path
        d="m974.7 95.85 5.725 23.86c13.41 10.79 32.37 7.833 44.24 4.808l-9.67-33.01c-14.41-4.082-32.91-5.977-40.3 4.33"
        fill="#cf6e0d"
      />
      <path
        d="m1111 130.9 12.95 37.58c22.71 1.988 31.66 1.22 46.72 5.801-1.554-12.16-12.36-35.1-20.43-44.24-32.9 2.742-12.21-1.318-39.24 0.8664"
        fill="#c78a57"
      />
      <path d="m1130 78.65 3.26-3.555-9.067-23.94c-23.39-6.592 0 0-41.03 2.001l9.403 26.15z" fill="#cf6e0d" />
      <path
        d="m1060 41.37c18.22-0.07065 26.9 3.37 35.55-3.85 0.4559-0.3799 1.006-1.346 1.441-2.135l-12.13-33.84-39 0.9238z"
        fill="#a6523b"
      />
      <path d="m1113 210.3c8.71 1.537 15.44 1.953 23.51 2.115l21.75-6.74-12.52-31.81-44.5 0.9651z" fill="#d98c4a" />
      <path d="m1070 161.5c4.983 3.878 3.017 3.391 9.744 5.633l37.46 0.3306-12.76-35.86-43.54-1.177z" fill="#a6523b" />
      <path
        d="m1073 93.58 7.509 23.86c14.2 10.79 32.9 7.833 44.54 4.808l-12.14-33.01c-14.69-4.083-33.31-5.979-39.91 4.328"
        fill="#cf6e0d"
      />
      <path d="m1090 174.9-39.3 1.636 7.525 30.81 47.23 2.446c-9.486-28.93-3.307-16.84-15.46-34.88" fill="#cf6e0d" />
      <path
        d="m1036 53.1 6.729 21.9c4.064 3.528 3.759 4.555 9.624 6.816l33.67-1.742-11.28-32.42c-17.05-4.468-33.88-7.617-38.74 5.444"
        fill="#d68747"
      />
      <path d="m1030 118.9 7.874 6.69 38.07-2.933-10.78-32.69-37.51-3.109-5.513 0.5705z" fill="#c97317" />
      <path
        d="m1212 130.9 15.74 37.58c22.86 1.988 31.74 1.22 47.16 5.801-2.457-12.16-14.97-35.1-23.71-44.24-32.7 2.742-12.31-1.318-39.18 0.8664"
        fill="#c78a57"
      />
      <path d="m1228 78.65 2.997-3.555-10.85-23.94c-23.89-6.592 0 0-40.88 2.001l11.35 26.15z" fill="#cf6e0d" />
      <path
        d="m1155 41.37c18.22-0.07065 27.15 3.37 35.27-3.85 0.4265-0.3799 0.9051-1.346 1.281-2.135l-14.65-33.84-38.94 0.9238z"
        fill="#a6523b"
      />
      <path d="m1220 210.3c8.824 1.537 15.57 1.953 23.66 2.115l21.25-6.74-14.89-31.81-44.42 0.9651z" fill="#d98c4a" />
      <path d="m1174 161.5c5.271 3.878 3.268 3.391 10.16 5.633l37.48 0.3306-15.44-35.86-43.63-1.177z" fill="#d98c4a" />
      <path
        d="m1171 93.58 9.282 23.86c15.01 10.79 33.48 7.833 44.9 4.808l-14.58-33.01c-14.98-4.083-33.75-5.979-39.59 4.328"
        fill="#cf6e0d"
      />
      <path d="m1194 174.9-40.63-1.262 11.27 33.7 47.41 2.446c-11.63-28.93-4.559-16.84-18.05-34.88" fill="#cf6e0d" />
      <path
        d="m1131 53.1 8.357 21.9c4.327 3.528 4.098 4.555 10.13 6.816l33.54-1.742-13.69-32.42c-17.38-4.468-34.46-7.617-38.34 5.444"
        fill="#d68747"
      />
      <path d="m1131 118.9 8.371 6.69 37.86-2.933-13.21-32.69-37.75-3.109-5.471 0.5705z" fill="#a6523b" />
      <path
        d="m1315 132.5 18.58 37.58c23.03 1.988 31.87 1.218 47.64 5.801-3.372-12.16-17.62-35.1-27.06-44.24-32.53 2.743-12.42-1.318-39.16 0.8678"
        fill="#c78a57"
      />
      <path d="m1327 80.63 2.735-3.554-12.66-23.95c-24.42-6.592 0 0-40.79 2.001l13.32 26.15z" fill="#cf6e0d" />
      <path
        d="m1252 42.97c18.24-0.07065 27.43 3.37 35.03-3.85 0.3986-0.3799 0.8052-1.346 1.122-2.135l-17.21-33.84-38.91 0.9238z"
        fill="#a6523b"
      />
      <path d="m1330 211.9c8.95 1.537 15.74 1.953 23.85 2.115l20.77-6.74-17.29-31.81-44.4 0.9664z" fill="#d98c4a" />
      <path d="m1280 163.1c5.568 3.878 3.527 3.391 10.6 5.633l37.55 0.3306-18.14-35.86-43.76-1.177z" fill="#a6523b" />
      <path
        d="m1272 95.2 11.09 23.86c15.84 10.79 34.11 7.833 45.31 4.808l-17.08-33.01c-15.32-4.082-34.24-5.977-39.31 4.33"
        fill="#cf6e0d"
      />
      <path d="m1301 176.5-40.64-1.065 13.68 33.5 47.65 2.446c-13.82-28.93-5.828-16.84-20.69-34.88" fill="#cf6e0d" />
      <path
        d="m1229 55.13 10.01 21.9c4.598 3.528 4.446 4.555 10.66 6.816l33.46-1.744-16.14-32.42c-17.74-4.468-35.08-7.617-37.99 5.444"
        fill="#d68747"
      />
      <path d="m1233 120.6 8.884 6.69 37.68-2.933-15.68-32.69-38.03-3.11-5.435 0.5719z" fill="#c97317" />
      <g fill="#cf6e0d">
        <path
          d="m1004 9.378 6.772 23.86c13.2 10.79 30.77 7.833 41.71 4.808l-11.07-33.01c-13.72-4.083-31.13-5.979-37.4 4.328"
        />
        <path
          d="m1093 10.84 8.482 23.86c13.98 10.79 31.37 7.833 42.1 4.808l-13.44-33.01c-14.02-4.082-31.58-5.977-37.14 4.33"
        />
        <path
          d="m1184 9.778 10.24 23.86c14.77 10.79 31.91 7.833 42.42 4.808l-15.86-33.01c-14.3-4.083-31.99-5.979-36.79 4.328"
        />
      </g>
      <path
        d="m1327 56.7 11.67 21.9c4.868 3.528 4.795 4.555 11.18 6.816l33.36-1.742-18.6-32.42c-18.1-4.468-35.7-7.617-37.62 5.444"
        fill="#d68747"
      />
      <path d="m1321 90.66 14.96 31.46 9.398 6.692 19.41-1.518v-33.99l-0.056-0.1013-38.31-3.109z" fill="#c97317" />
      <path
        d="m1278 11.51 12.05 23.86c15.6 10.79 32.55 7.833 42.83 4.808l-18.37-33.01c-14.64-4.083-32.5-5.979-36.51 4.328"
        fill="#cf6e0d"
      />
      <path
        d="m1346 41.37c18.21-0.07065 27.66 3.37 34.68-3.85 0.3692-0.3799 0.7025-1.346 0.9597-2.135l-19.76-33.84-38.79 0.9238z"
        fill="#a6523b"
      />
    </g>
    <g transform="translate(.05856 .494)">
      <g stroke-width="1.336">
        <g fill="#8a7a5c">
          <path d="m707.9 130h-23.25v-119.1l11.48-10.86 11.77 10.86z" />
          <path d="m739 130h-23.23v-119.1l11.46-10.86 11.77 10.86z" />
          <path d="m770.2 130h-23.23v-119.1l11.46-10.86 11.77 10.86z" />
          <path d="m801.4 130h-23.25v-119.1l11.46-10.86 11.79 10.86z" />
          <path d="m832.6 130h-23.25v-119.1l11.48-10.86 11.77 10.86z" />
        </g>
        <g fill="#706142">
          <path d="m707.9 104.5h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
          <path d="m739 104.5h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
          <path d="m770.2 104.5h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
          <path d="m801.4 104.5h-11.63v25.4h11.63zm0-55.85h-11.63v44.84h11.63zm-11.63-48.55v37.54h11.63v-26.82z" />
          <path d="m832.6 104.5h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
        </g>
        <g fill="#8a7a5c">
          <path d="m863.8 130h-23.25v-119.1l11.48-10.86 11.77 10.86z" />
          <path d="m895.1 130h-23.23v-119.1l11.46-10.86 11.77 10.86z" />
          <path d="m926.3 130h-23.23v-119.1l11.46-10.86 11.77 10.86z" />
          <path d="m957.5 130h-23.25v-119.1l11.46-10.86 11.79 10.86z" />
          <path d="m988.7 130h-23.25v-119.1l11.48-10.86 11.77 10.86z" />
        </g>
        <g fill="#706142">
          <path d="m863.8 104.5h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
          <path d="m895.1 104.5h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
          <path d="m926.3 104.5h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
          <path d="m957.5 104.5h-11.63v25.4h11.63zm0-55.85h-11.63v44.84h11.63zm-11.63-48.55v37.54h11.63v-26.82z" />
          <path d="m988.7 104.5h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
        </g>
        <g fill="#8a7a5c">
          <path d="m1020 130h-23.25v-119.1l11.48-10.86 11.77 10.86z" />
          <path d="m1051 130h-23.23v-119.1l11.46-10.86 11.77 10.86z" />
          <path d="m1082 130h-23.23v-119.1l11.46-10.86 11.77 10.86z" />
          <path d="m1114 130h-23.25v-119.1l11.46-10.86 11.79 10.86z" />
          <path d="m1145 130h-23.25v-119.1l11.48-10.86 11.77 10.86z" />
        </g>
        <g fill="#706142">
          <path
            d="m1020 100.1h-0.164v4.478h-11.45v25.4h11.62zm0-55.85h-0.164v4.478h-11.45v44.84h11.62zm-11.62-44.08v37.54h11.62v-26.82z"
          />
          <path
            d="m1051 100.1h-0.164v4.478h-11.45v25.4h11.62zm0-55.85h-0.164v4.478h-11.45v44.84h11.62zm-11.62-44.08v37.54h11.62v-26.82z"
          />
          <path
            d="m1082 100.1h-0.164v4.478h-11.45v25.4h11.62zm0-55.85h-0.164v4.478h-11.45v44.84h11.62zm-11.62-44.08v37.54h11.62v-26.82z"
          />
          <path
            d="m1114 100.1h-0.164v4.478h-11.47v25.4h11.63zm0-55.85h-0.164v4.478h-11.47v44.84h11.63zm-11.63-44.08v37.54h11.63v-26.82z"
          />
          <path
            d="m1145 100.1h-0.164v4.478h-11.45v25.4h11.62zm0-55.85h-0.164v4.478h-11.45v44.84h11.62zm-11.62-44.08v37.54h11.62v-26.82z"
          />
        </g>
        <g fill="#8a7a5c">
          <path d="m1176 130h-23.25v-119.1l11.48-10.86 11.77 10.86z" />
          <path d="m1207 130h-23.23v-119.1l11.46-10.86 11.77 10.86z" />
          <path d="m1238 130h-23.23v-119.1l11.46-10.86 11.77 10.86z" />
          <path d="m1270 130h-23.25v-119.1l11.46-10.86 11.79 10.86z" />
          <path d="m1301 130h-23.25v-119.1l11.48-10.86 11.77 10.86z" />
        </g>
        <g fill="#706142">
          <path d="m1176 104.5h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
          <path d="m1207 104.5h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
          <path d="m1238 104.5h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
          <path d="m1270 104.5h-11.63v25.4h11.63zm0-55.85h-11.63v44.84h11.63zm-11.63-48.55v37.54h11.63v-26.82z" />
          <path d="m1301 104.5h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
        </g>
        <path d="m1332 130h-23.25v-119.1l11.48-10.86 11.77 10.86z" fill="#8a7a5c" />
        <path d="m1364 130h-23.23v-119.1l11.46-10.86 11.77 10.86z" fill="#8a7a5c" />
        <path
          d="m1332 104.5h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z"
          fill="#706142"
        />
        <path
          d="m1364 104.5h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z"
          fill="#706142"
        />
        <path d="m673.1 44.27h691.8v-6.539h-691.8z" fill="#94856b" />
        <path d="m673.1 100.1h691.8v-6.529h-691.8z" fill="#94856b" />
        <path d="m696.3 44.27h-11.63v4.478h11.63z" fill="#706142" />
        <path d="m707.9 44.27h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m707.9 42.26h-23.25v2.057h23.25z" fill="#78694f" />
        <path d="m727.5 44.27h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m739 44.27h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m739 42.26h-23.23v2.057h23.23z" fill="#78694f" />
        <path d="m758.6 44.27h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m770.2 44.27h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m770.2 42.26h-23.23v2.057h23.23z" fill="#78694f" />
        <path d="m789.8 44.27h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m801.4 44.27h-11.63v4.478h11.63z" fill="#5c4c33" />
        <path d="m801.4 42.26h-23.25v2.057h23.25z" fill="#78694f" />
        <path d="m821 44.27h-11.63v4.478h11.63z" fill="#706142" />
        <path d="m832.6 44.27h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m832.6 42.26h-23.25v2.057h23.25z" fill="#78694f" />
        <g fill="#b0a38c">
          <path
            d="m697.7 41.05c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
          />
          <path
            d="m728.9 41.05c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
          />
          <path
            d="m760.1 41.05c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
          />
          <path
            d="m791.3 41.05c0 0.8131-0.6358 1.469-1.462 1.469-0.7918 0-1.445-0.6565-1.445-1.469s0.6532-1.469 1.445-1.469c0.8265 0 1.462 0.6565 1.462 1.469"
          />
          <path
            d="m822.5 41.05c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
          />
        </g>
        <path d="m696.3 100.1h-11.63v4.478h11.63z" fill="#706142" />
        <path d="m707.9 100.1h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m707.9 98.12h-23.25v2.048h23.25z" fill="#78694f" />
        <path d="m727.5 100.1h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m739 100.1h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m739 98.12h-23.23v2.048h23.23z" fill="#78694f" />
        <path d="m758.6 100.1h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m770.2 100.1h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m770.2 98.12h-23.23v2.048h23.23z" fill="#78694f" />
        <path d="m789.8 100.1h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m801.4 100.1h-11.63v4.478h11.63z" fill="#5c4c33" />
        <path d="m801.4 98.12h-23.25v2.048h23.25z" fill="#78694f" />
        <path d="m821 100.1h-11.63v4.478h11.63z" fill="#706142" />
        <path d="m832.6 100.1h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m832.6 98.12h-23.25v2.048h23.25z" fill="#78694f" />
        <g fill="#b0a38c">
          <path
            d="m697.7 96.91c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
          />
          <path
            d="m728.9 96.91c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
          />
          <path
            d="m760.1 96.91c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
          />
          <path
            d="m791.3 96.91c0 0.8038-0.6358 1.461-1.462 1.461-0.7918 0-1.445-0.6578-1.445-1.461 0-0.8131 0.6532-1.479 1.445-1.479 0.8265 0 1.462 0.6658 1.462 1.479"
          />
          <path
            d="m822.5 96.91c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
          />
        </g>
        <path d="m852.2 44.27h-11.63v4.478h11.63z" fill="#706142" />
        <path d="m863.8 44.27h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m863.8 42.26h-23.25v2.057h23.25z" fill="#78694f" />
        <path d="m883.4 44.27h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m895.1 44.27h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m895.1 42.26h-23.23v2.057h23.23z" fill="#78694f" />
        <path d="m914.6 44.27h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m926.3 44.27h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m926.3 42.26h-23.23v2.057h23.23z" fill="#78694f" />
        <path d="m945.8 44.27h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m957.5 44.27h-11.63v4.478h11.63z" fill="#5c4c33" />
        <path d="m957.5 42.26h-23.25v2.057h23.25z" fill="#78694f" />
        <path d="m977.1 44.27h-11.63v4.478h11.63z" fill="#706142" />
        <path d="m988.7 44.27h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m988.7 42.26h-23.25v2.057h23.25z" fill="#78694f" />
        <g fill="#b0a38c">
          <path
            d="m853.7 41.05c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
          />
          <path
            d="m884.9 41.05c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
          />
          <path
            d="m916.1 41.05c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
          />
          <path
            d="m947.3 41.05c0 0.8131-0.6358 1.469-1.462 1.469-0.7918 0-1.445-0.6565-1.445-1.469s0.6532-1.469 1.445-1.469c0.8265 0 1.462 0.6565 1.462 1.469"
          />
          <path
            d="m978.6 41.05c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
          />
        </g>
        <path d="m852.2 100.1h-11.63v4.478h11.63z" fill="#706142" />
        <path d="m863.8 100.1h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m863.8 98.12h-23.25v2.048h23.25z" fill="#78694f" />
        <path d="m883.4 100.1h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m895.1 100.1h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m895.1 98.12h-23.23v2.048h23.23z" fill="#78694f" />
        <path d="m914.6 100.1h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m926.3 100.1h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m926.3 98.12h-23.23v2.048h23.23z" fill="#78694f" />
        <path d="m945.8 100.1h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m957.5 100.1h-11.63v4.478h11.63z" fill="#5c4c33" />
        <path d="m957.5 98.12h-23.25v2.048h23.25z" fill="#78694f" />
        <path d="m977.1 100.1h-11.63v4.478h11.63z" fill="#706142" />
        <path d="m988.7 100.1h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m988.7 98.12h-23.25v2.048h23.25z" fill="#78694f" />
        <g fill="#b0a38c">
          <path
            d="m853.7 96.91c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
          />
          <path
            d="m884.9 96.91c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
          />
          <path
            d="m916.1 96.91c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
          />
          <path
            d="m947.3 96.91c0 0.8038-0.6358 1.461-1.462 1.461-0.7918 0-1.445-0.6578-1.445-1.461 0-0.8131 0.6532-1.479 1.445-1.479 0.8265 0 1.462 0.6658 1.462 1.479"
          />
          <path
            d="m978.6 96.91c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
          />
        </g>
        <path d="m996.5 48.82h0.1626v-4.478h-0.1626z" fill="#d1c9bd" />
        <path d="m1008 44.27h-11.63v4.478h11.63z" fill="#706142" />
        <path d="m1020 44.27h-11.45v4.478h11.45z" fill="#5c4c33" />
        <path d="m1020 42.26h-23.25v2.057h23.25z" fill="#78694f" />
        <path d="m1028 48.82h0.164v-4.478h-0.164z" fill="#d1c9bd" />
        <path d="m1040 44.27h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m1051 44.27h-11.45v4.478h11.45z" fill="#5c4c33" />
        <path d="m1051 42.26h-23.23v2.057h23.23z" fill="#78694f" />
        <path d="m1059 48.82h0.164v-4.478h-0.164z" fill="#d1c9bd" />
        <path d="m1071 44.27h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m1082 44.27h-11.45v4.478h11.45z" fill="#5c4c33" />
        <path d="m1082 42.26h-23.23v2.057h23.23z" fill="#78694f" />
        <path d="m1090 48.82h0.164v-4.478h-0.164z" fill="#d1c9bd" />
        <path d="m1102 44.27h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m1113 44.27h-11.47v4.478h11.47z" fill="#5c4c33" />
        <path d="m1113 42.26h-23.25v2.057h23.25z" fill="#78694f" />
        <path d="m1121 48.82h0.1626v-4.478h-0.1626z" fill="#d1c9bd" />
        <path d="m1133 44.27h-11.63v4.478h11.63z" fill="#706142" />
        <path d="m1145 44.27h-11.45v4.478h11.45z" fill="#5c4c33" />
        <path d="m1145 42.26h-23.25v2.057h23.25z" fill="#78694f" />
        <g fill="#b0a38c">
          <path
            d="m1010 41.05c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
          />
          <path
            d="m1041 41.05c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
          />
          <path
            d="m1072 41.05c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
          />
          <path
            d="m1103 41.05c0 0.8131-0.6358 1.469-1.462 1.469-0.7918 0-1.445-0.6565-1.445-1.469s0.6532-1.469 1.445-1.469c0.8265 0 1.462 0.6565 1.462 1.469"
          />
          <path
            d="m1135 41.05c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
          />
        </g>
        <path d="m996.5 104.5h0.1626v-4.478h-0.1626z" fill="#d1c9bd" />
        <path d="m1008 100.1h-11.63v4.478h11.63z" fill="#706142" />
        <path d="m1020 100.1h-11.45v4.478h11.45z" fill="#5c4c33" />
        <path d="m1020 98.12h-23.25v2.048h23.25z" fill="#78694f" />
        <path d="m1028 104.5h0.164v-4.478h-0.164z" fill="#d1c9bd" />
        <path d="m1040 100.1h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m1051 100.1h-11.45v4.478h11.45z" fill="#5c4c33" />
        <path d="m1051 98.12h-23.23v2.048h23.23z" fill="#78694f" />
        <path d="m1059 104.5h0.164v-4.478h-0.164z" fill="#d1c9bd" />
        <path d="m1071 100.1h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m1082 100.1h-11.45v4.478h11.45z" fill="#5c4c33" />
        <path d="m1082 98.12h-23.23v2.048h23.23z" fill="#78694f" />
        <path d="m1090 104.5h0.164v-4.478h-0.164z" fill="#d1c9bd" />
        <path d="m1102 100.1h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m1113 100.1h-11.47v4.478h11.47z" fill="#5c4c33" />
        <path d="m1113 98.12h-23.25v2.048h23.25z" fill="#78694f" />
        <path d="m1121 104.5h0.1626v-4.478h-0.1626z" fill="#d1c9bd" />
        <path d="m1133 100.1h-11.63v4.478h11.63z" fill="#706142" />
        <path d="m1145 100.1h-11.45v4.478h11.45z" fill="#5c4c33" />
        <path d="m1145 98.12h-23.25v2.048h23.25z" fill="#78694f" />
        <g fill="#b0a38c">
          <path
            d="m1010 96.91c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
          />
          <path
            d="m1041 96.91c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
          />
          <path
            d="m1072 96.91c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
          />
          <path
            d="m1103 96.91c0 0.8038-0.6358 1.461-1.462 1.461-0.7918 0-1.445-0.6578-1.445-1.461 0-0.8131 0.6532-1.479 1.445-1.479 0.8265 0 1.462 0.6658 1.462 1.479"
          />
          <path
            d="m1135 96.91c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
          />
        </g>
        <path d="m1164 44.27h-11.63v4.478h11.63z" fill="#706142" />
        <path d="m1176 44.27h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m1176 42.26h-23.25v2.057h23.25z" fill="#78694f" />
        <path d="m1196 44.27h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m1207 44.27h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m1207 42.26h-23.23v2.057h23.23z" fill="#78694f" />
        <path d="m1227 44.27h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m1238 44.27h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m1238 42.26h-23.23v2.057h23.23z" fill="#78694f" />
        <path d="m1258 44.27h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m1270 44.27h-11.63v4.478h11.63z" fill="#5c4c33" />
        <path d="m1270 42.26h-23.25v2.057h23.25z" fill="#78694f" />
        <path d="m1289 44.27h-11.63v4.478h11.63z" fill="#706142" />
        <path d="m1301 44.27h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m1301 42.26h-23.25v2.057h23.25z" fill="#78694f" />
        <g fill="#b0a38c">
          <path
            d="m1166 41.05c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
          />
          <path
            d="m1197 41.05c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
          />
          <path
            d="m1228 41.05c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
          />
          <path
            d="m1259 41.05c0 0.8131-0.6358 1.469-1.462 1.469-0.7918 0-1.445-0.6565-1.445-1.469s0.6532-1.469 1.445-1.469c0.8265 0 1.462 0.6565 1.462 1.469"
          />
          <path
            d="m1291 41.05c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
          />
        </g>
        <path d="m1164 100.1h-11.63v4.478h11.63z" fill="#706142" />
        <path d="m1176 100.1h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m1176 98.12h-23.25v2.048h23.25z" fill="#78694f" />
        <path d="m1196 100.1h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m1207 100.1h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m1207 98.12h-23.23v2.048h23.23z" fill="#78694f" />
        <path d="m1227 100.1h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m1238 100.1h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m1238 98.12h-23.23v2.048h23.23z" fill="#78694f" />
        <path d="m1258 100.1h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m1270 100.1h-11.63v4.478h11.63z" fill="#5c4c33" />
        <path d="m1270 98.12h-23.25v2.048h23.25z" fill="#78694f" />
        <path d="m1289 100.1h-11.63v4.478h11.63z" fill="#706142" />
        <path d="m1301 100.1h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m1301 98.12h-23.25v2.048h23.25z" fill="#78694f" />
        <g fill="#b0a38c">
          <path
            d="m1166 96.91c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
          />
          <path
            d="m1197 96.91c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
          />
          <path
            d="m1228 96.91c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
          />
          <path
            d="m1259 96.91c0 0.8038-0.6358 1.461-1.462 1.461-0.7918 0-1.445-0.6578-1.445-1.461 0-0.8131 0.6532-1.479 1.445-1.479 0.8265 0 1.462 0.6658 1.462 1.479"
          />
          <path
            d="m1291 96.91c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
          />
        </g>
        <path d="m1320 44.27h-11.63v4.478h11.63z" fill="#706142" />
        <path d="m1332 44.27h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m1332 42.26h-23.25v2.057h23.25z" fill="#78694f" />
        <path d="m1352 44.27h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m1364 44.27h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m1364 42.26h-23.23v2.057h23.23z" fill="#78694f" />
        <path
          d="m1322 41.05c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
          fill="#b0a38c"
        />
        <path
          d="m1353 41.05c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
          fill="#b0a38c"
        />
        <path d="m1320 100.1h-11.63v4.478h11.63z" fill="#706142" />
        <path d="m1332 100.1h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m1332 98.12h-23.25v2.048h23.25z" fill="#78694f" />
        <path d="m1352 100.1h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m1364 100.1h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m1364 98.12h-23.23v2.048h23.23z" fill="#78694f" />
        <path
          d="m1322 96.91c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
          fill="#b0a38c"
        />
        <path
          d="m1353 96.91c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
          fill="#b0a38c"
        />
        <path d="m-0.05856 9.166v120.8h21.43v-119.1l-11.79-10.86z" fill="#8a7a5c" />
        <path d="m52.58 130h-23.25v-119.1l11.48-10.86 11.77 10.86z" fill="#8a7a5c" />
        <path
          d="m21.37 104.5h-11.63v25.4h11.63zm0-55.85h-11.63v44.84h11.63zm-11.63-48.55v37.54h11.63v-26.82z"
          fill="#706142"
        />
        <path
          d="m52.58 104.5h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z"
          fill="#706142"
        />
        <g fill="#8a7a5c">
          <path d="m83.77 130h-23.25v-119.1l11.48-10.86 11.77 10.86z" />
          <path d="m114.9 130h-23.23v-119.1l11.46-10.86 11.77 10.86z" />
          <path d="m146.1 130h-23.23v-119.1l11.46-10.86 11.77 10.86z" />
          <path d="m177.3 130h-23.25v-119.1l11.46-10.86 11.79 10.86z" />
          <path d="m208.5 130h-23.25v-119.1l11.48-10.86 11.77 10.86z" />
        </g>
        <g fill="#706142">
          <path
            d="m83.77 100.1h-0.164v4.478h-11.45v25.4h11.62zm0-55.85h-0.164v4.478h-11.45v44.84h11.62zm-11.62-44.08v37.54h11.62v-26.82z"
          />
          <path
            d="m114.9 100.1h-0.164v4.478h-11.45v25.4h11.62zm0-55.85h-0.164v4.478h-11.45v44.84h11.62zm-11.62-44.08v37.54h11.62v-26.82z"
          />
          <path
            d="m146.1 100.1h-0.164v4.478h-11.45v25.4h11.62zm0-55.85h-0.164v4.478h-11.45v44.84h11.62zm-11.62-44.08v37.54h11.62v-26.82z"
          />
          <path
            d="m177.3 100.1h-0.164v4.478h-11.47v25.4h11.63zm0-55.85h-0.164v4.478h-11.47v44.84h11.63zm-11.63-44.08v37.54h11.63v-26.82z"
          />
          <path
            d="m208.5 100.1h-0.164v4.478h-11.45v25.4h11.62zm0-55.85h-0.164v4.478h-11.45v44.84h11.62zm-11.62-44.08v37.54h11.62v-26.82z"
          />
        </g>
        <g fill="#8a7a5c">
          <path d="m239.7 130h-23.25v-119.1l11.48-10.86 11.77 10.86z" />
          <path d="m270.9 130h-23.23v-119.1l11.46-10.86 11.77 10.86z" />
          <path d="m302.1 130h-23.23v-119.1l11.46-10.86 11.77 10.86z" />
          <path d="m333.4 130h-23.25v-119.1l11.46-10.86 11.79 10.86z" />
          <path d="m364.6 130h-23.25v-119.1l11.48-10.86 11.77 10.86z" />
        </g>
        <g fill="#706142">
          <path d="m239.7 104.5h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
          <path d="m270.9 104.5h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
          <path d="m302.1 104.5h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
          <path d="m333.4 104.5h-11.63v25.4h11.63zm0-55.85h-11.63v44.84h11.63zm-11.63-48.55v37.54h11.63v-26.82z" />
          <path d="m364.6 104.5h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.61-48.55v37.54h11.61v-26.82z" />
        </g>
        <g fill="#8a7a5c">
          <path d="m395.8 130h-23.25v-119.1l11.48-10.86 11.77 10.86z" />
          <path d="m427 130h-23.23v-119.1l11.46-10.86 11.77 10.86z" />
          <path d="m458.2 130h-23.23v-119.1l11.46-10.86 11.77 10.86z" />
          <path d="m489.4 130h-23.25v-119.1l11.46-10.86 11.79 10.86z" />
          <path d="m520.6 130h-23.25v-119.1l11.48-10.86 11.77 10.86z" />
        </g>
        <g fill="#706142">
          <path d="m395.8 104.5h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
          <path d="m427 104.5h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
          <path d="m458.2 104.5h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
          <path d="m489.4 104.5h-11.63v25.4h11.63zm0-55.85h-11.63v44.84h11.63zm-11.63-48.55v37.54h11.63v-26.82z" />
          <path d="m520.6 104.5h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
        </g>
      </g>
      <path d="m553.3 130h-23.26v-119.1l11.48-10.87 11.77 10.87z" fill="#8a7a5c" stroke-width="1.337" />
      <path
        d="m553.3 104.6h-11.62v25.4h11.62zm0-55.87h-11.62v44.86h11.62zm-11.62-48.57v37.55h11.62v-26.83z"
        fill="#706142"
        stroke-width="1.337"
      />
      <g stroke-width="1.336">
        <g fill="#8a7a5c">
          <path d="m584.3 130h-23.25v-119.1l11.48-10.86 11.77 10.86z" />
          <path d="m615.5 130h-23.23v-119.1l11.46-10.86 11.77 10.86z" />
          <path d="m646.7 130h-23.23v-119.1l11.46-10.86 11.77 10.86z" />
          <path d="m677.9 130h-23.25v-119.1l11.46-10.86 11.79 10.86z" />
        </g>
        <g fill="#706142">
          <path d="m572.7 0.1902v37.54h11.62v-26.82z" />
          <path d="m584.3 48.82h-11.62v44.84h11.62z" />
          <path d="m584.3 104.5h-11.62v25.4h11.62z" />
          <path d="m603.9 0.1902v37.54h11.62v-26.82z" />
          <path d="m615.5 48.82h-11.62v44.84h11.62z" />
          <path d="m615.5 104.5h-11.62v25.4h11.62z" />
          <path d="m635.1 0.1902v37.54h11.62v-26.82z" />
          <path d="m646.7 48.82h-11.62v44.84h11.62z" />
          <path d="m646.7 104.5h-11.62v25.4h11.62z" />
          <path d="m666.3 0.1902v37.54h11.63v-26.82z" />
          <path d="m677.9 48.82h-11.63v44.84h11.63z" />
          <path d="m677.9 104.5h-11.63v25.4h11.63z" />
        </g>
        <path d="m-0.05856 44.27h691.8v-6.539h-691.8z" fill="#94856b" />
        <path d="m-0.05856 100.1h691.8v-6.529h-691.8z" fill="#94856b" />
        <path d="m-0.05856 48.82h9.804v-4.478h-9.804z" fill="#706142" />
        <path d="m21.37 44.27h-11.63v4.478h11.63z" fill="#5c4c33" />
        <path d="m-0.05856 42.26v2.057h21.43v-2.057z" fill="#78694f" />
        <path d="m40.96 44.27h-11.63v4.478h11.63z" fill="#706142" />
        <path d="m52.58 44.27h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m52.58 42.26h-23.25v2.057h23.25z" fill="#78694f" />
        <path
          d="m11.21 41.05c0 0.8131-0.6358 1.469-1.462 1.469-0.7918 0-1.445-0.6565-1.445-1.469s0.6532-1.469 1.445-1.469c0.8265 0 1.462 0.6565 1.462 1.469"
          fill="#b0a38c"
        />
        <path
          d="m42.42 41.05c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
          fill="#b0a38c"
        />
        <path d="m-0.05856 104.5h9.804v-4.478h-9.804z" fill="#706142" />
        <path d="m21.37 100.1h-11.63v4.478h11.63z" fill="#5c4c33" />
        <path d="m-0.05856 98.12v2.049h21.43v-2.049z" fill="#78694f" />
        <path d="m40.96 100.1h-11.63v4.478h11.63z" fill="#706142" />
        <path d="m52.58 100.1h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m52.58 98.12h-23.25v2.048h23.25z" fill="#78694f" />
        <path
          d="m11.21 96.91c0 0.8038-0.6358 1.461-1.462 1.461-0.7918 0-1.445-0.6578-1.445-1.461 0-0.8131 0.6532-1.479 1.445-1.479 0.8265 0 1.462 0.6658 1.462 1.479"
          fill="#b0a38c"
        />
        <path
          d="m42.42 96.91c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
          fill="#b0a38c"
        />
        <path d="m60.36 48.82h0.164v-4.478h-0.164z" fill="#d1c9bd" />
        <path d="m72.16 44.27h-11.63v4.478h11.63z" fill="#706142" />
        <path d="m83.61 44.27h-11.45v4.478h11.45z" fill="#5c4c33" />
        <path d="m83.61 42.26h-23.25v2.057h23.25z" fill="#78694f" />
        <path d="m91.57 48.82h0.1626v-4.478h-0.1626z" fill="#d1c9bd" />
        <path d="m103.3 44.27h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m114.8 44.27h-11.45v4.478h11.45z" fill="#5c4c33" />
        <path d="m114.8 42.26h-23.23v2.057h23.23z" fill="#78694f" />
        <path d="m122.7 48.82h0.1626v-4.478h-0.1626z" fill="#d1c9bd" />
        <path d="m134.5 44.27h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m146 44.27h-11.45v4.478h11.45z" fill="#5c4c33" />
        <path d="m146 42.26h-23.23v2.057h23.23z" fill="#78694f" />
        <path d="m154 48.82h0.1626v-4.478h-0.1626z" fill="#d1c9bd" />
        <path d="m165.7 44.27h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m177.2 44.27h-11.47v4.478h11.47z" fill="#5c4c33" />
        <path d="m177.2 42.26h-23.25v2.057h23.25z" fill="#78694f" />
        <path d="m185.2 48.82h0.1626v-4.478h-0.1626z" fill="#d1c9bd" />
        <path d="m196.9 44.27h-11.63v4.478h11.63z" fill="#706142" />
        <path d="m208.4 44.27h-11.45v4.478h11.45z" fill="#5c4c33" />
        <path d="m208.4 42.26h-23.25v2.057h23.25z" fill="#78694f" />
        <g fill="#b0a38c">
          <path
            d="m73.45 41.05c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
          />
          <path
            d="m104.6 41.05c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
          />
          <path
            d="m135.9 41.05c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
          />
          <path
            d="m167.1 41.05c0 0.8131-0.6358 1.469-1.462 1.469-0.7918 0-1.445-0.6565-1.445-1.469s0.6532-1.469 1.445-1.469c0.8265 0 1.462 0.6565 1.462 1.469"
          />
          <path
            d="m198.3 41.05c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
          />
        </g>
        <path d="m60.36 104.5h0.164v-4.478h-0.164z" fill="#d1c9bd" />
        <path d="m72.16 100.1h-11.63v4.478h11.63z" fill="#706142" />
        <path d="m83.61 100.1h-11.45v4.478h11.45z" fill="#5c4c33" />
        <path d="m83.61 98.12h-23.25v2.048h23.25z" fill="#78694f" />
        <path d="m91.57 104.5h0.1626v-4.478h-0.1626z" fill="#d1c9bd" />
        <path d="m103.3 100.1h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m114.8 100.1h-11.45v4.478h11.45z" fill="#5c4c33" />
        <path d="m114.8 98.12h-23.23v2.048h23.23z" fill="#78694f" />
        <path d="m122.7 104.5h0.1626v-4.478h-0.1626z" fill="#d1c9bd" />
        <path d="m134.5 100.1h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m146 100.1h-11.45v4.478h11.45z" fill="#5c4c33" />
        <path d="m146 98.12h-23.23v2.048h23.23z" fill="#78694f" />
        <path d="m154 104.5h0.1626v-4.478h-0.1626z" fill="#d1c9bd" />
        <path d="m165.7 100.1h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m177.2 100.1h-11.47v4.478h11.47z" fill="#5c4c33" />
        <path d="m177.2 98.12h-23.25v2.048h23.25z" fill="#78694f" />
        <path d="m185.2 104.5h0.1626v-4.478h-0.1626z" fill="#d1c9bd" />
        <path d="m196.9 100.1h-11.63v4.478h11.63z" fill="#706142" />
        <path d="m208.4 100.1h-11.45v4.478h11.45z" fill="#5c4c33" />
        <path d="m208.4 98.12h-23.25v2.048h23.25z" fill="#78694f" />
        <g fill="#b0a38c">
          <path
            d="m73.45 96.91c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
          />
          <path
            d="m104.6 96.91c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
          />
          <path
            d="m135.9 96.91c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
          />
          <path
            d="m167.1 96.91c0 0.8038-0.6358 1.461-1.462 1.461-0.7918 0-1.445-0.6578-1.445-1.461 0-0.8131 0.6532-1.479 1.445-1.479 0.8265 0 1.462 0.6658 1.462 1.479"
          />
          <path
            d="m198.3 96.91c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
          />
        </g>
        <path d="m228.1 44.27h-11.63v4.478h11.63z" fill="#706142" />
        <path d="m239.7 44.27h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m239.7 42.26h-23.25v2.057h23.25z" fill="#78694f" />
        <path d="m259.3 44.27h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m270.9 44.27h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m270.9 42.26h-23.23v2.057h23.23z" fill="#78694f" />
        <path d="m290.5 44.27h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m302.1 44.27h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m302.1 42.26h-23.23v2.057h23.23z" fill="#78694f" />
        <path d="m321.7 44.27h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m333.4 44.27h-11.63v4.478h11.63z" fill="#5c4c33" />
        <path d="m333.4 42.26h-23.25v2.057h23.25z" fill="#78694f" />
        <path d="m352.9 44.27h-11.63v4.478h11.63z" fill="#706142" />
        <path d="m364.6 44.27h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m364.6 42.26h-23.25v2.057h23.25z" fill="#78694f" />
        <g fill="#b0a38c">
          <path
            d="m229.6 41.05c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
          />
          <path
            d="m260.8 41.05c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
          />
          <path
            d="m292 41.05c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
          />
          <path
            d="m323.2 41.05c0 0.8131-0.6358 1.469-1.462 1.469-0.7918 0-1.445-0.6565-1.445-1.469s0.6532-1.469 1.445-1.469c0.8265 0 1.462 0.6565 1.462 1.469"
          />
          <path
            d="m354.3 41.05c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
          />
        </g>
        <path d="m228.1 100.1h-11.63v4.478h11.63z" fill="#706142" />
        <path d="m239.7 100.1h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m239.7 98.12h-23.25v2.048h23.25z" fill="#78694f" />
        <path d="m259.3 100.1h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m270.9 100.1h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m270.9 98.12h-23.23v2.048h23.23z" fill="#78694f" />
        <path d="m290.5 100.1h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m302.1 100.1h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m302.1 98.12h-23.23v2.048h23.23z" fill="#78694f" />
        <path d="m321.7 100.1h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m333.4 100.1h-11.63v4.478h11.63z" fill="#5c4c33" />
        <path d="m333.4 98.12h-23.25v2.048h23.25z" fill="#78694f" />
        <path d="m352.9 100.1h-11.63v4.478h11.63z" fill="#706142" />
        <path d="m364.6 100.1h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m364.6 98.12h-23.25v2.048h23.25z" fill="#78694f" />
        <g fill="#b0a38c">
          <path
            d="m229.6 96.91c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
          />
          <path
            d="m260.8 96.91c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
          />
          <path
            d="m292 96.91c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
          />
          <path
            d="m323.2 96.91c0 0.8038-0.6358 1.461-1.462 1.461-0.7918 0-1.445-0.6578-1.445-1.461 0-0.8131 0.6532-1.479 1.445-1.479 0.8265 0 1.462 0.6658 1.462 1.479"
          />
          <path
            d="m354.3 96.91c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
          />
        </g>
        <path d="m384.1 44.27h-11.63v4.478h11.63z" fill="#706142" />
        <path d="m395.8 44.27h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m395.8 42.26h-23.25v2.057h23.25z" fill="#78694f" />
        <path d="m415.4 44.27h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m427 44.27h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m427 42.26h-23.23v2.057h23.23z" fill="#78694f" />
        <path d="m446.6 44.27h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m458.2 44.27h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m458.2 42.26h-23.23v2.057h23.23z" fill="#78694f" />
        <path d="m477.8 44.27h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m489.4 44.27h-11.63v4.478h11.63z" fill="#5c4c33" />
        <path d="m489.4 42.26h-23.25v2.057h23.25z" fill="#78694f" />
        <path d="m509 44.27h-11.63v4.478h11.63z" fill="#706142" />
        <path d="m520.6 44.27h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m520.6 42.26h-23.25v2.057h23.25z" fill="#78694f" />
        <g fill="#b0a38c">
          <path
            d="m385.5 41.05c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
          />
          <path
            d="m416.7 41.05c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
          />
          <path
            d="m448.1 41.05c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
          />
          <path
            d="m479.2 41.05c0 0.8131-0.6358 1.469-1.462 1.469-0.7918 0-1.445-0.6565-1.445-1.469s0.6532-1.469 1.445-1.469c0.8265 0 1.462 0.6565 1.462 1.469"
          />
          <path
            d="m510.4 41.05c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
          />
        </g>
        <path d="m384.1 100.1h-11.63v4.478h11.63z" fill="#706142" />
        <path d="m395.8 100.1h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m395.8 98.12h-23.25v2.048h23.25z" fill="#78694f" />
        <path d="m415.4 100.1h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m427 100.1h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m427 98.12h-23.23v2.048h23.23z" fill="#78694f" />
        <path d="m446.6 100.1h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m458.2 100.1h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m458.2 98.12h-23.23v2.048h23.23z" fill="#78694f" />
        <path d="m477.8 100.1h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m489.4 100.1h-11.63v4.478h11.63z" fill="#5c4c33" />
        <path d="m489.4 98.12h-23.25v2.048h23.25z" fill="#78694f" />
        <path d="m509 100.1h-11.63v4.478h11.63z" fill="#706142" />
        <path d="m520.6 100.1h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m520.6 98.12h-23.25v2.048h23.25z" fill="#78694f" />
        <g fill="#b0a38c">
          <path
            d="m385.5 96.91c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
          />
          <path
            d="m416.7 96.91c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
          />
          <path
            d="m448.1 96.91c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
          />
          <path
            d="m479.2 96.91c0 0.8038-0.6358 1.461-1.462 1.461-0.7918 0-1.445-0.6578-1.445-1.461 0-0.8131 0.6532-1.479 1.445-1.479 0.8265 0 1.462 0.6658 1.462 1.479"
          />
          <path
            d="m510.4 96.91c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
          />
        </g>
        <path d="m541.5 44.27h-11.63v4.478h11.63z" fill="#706142" />
        <path d="m553.1 44.27h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m553.1 42.26h-23.25v2.057h23.25z" fill="#78694f" />
        <path d="m572.7 44.27h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m584.3 44.27h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m584.3 42.26h-23.23v2.057h23.23z" fill="#78694f" />
        <path d="m603.9 44.27h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m615.5 44.27h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m615.5 42.26h-23.23v2.057h23.23z" fill="#78694f" />
        <path d="m646.7 48.82h0.0187v-4.478h-0.0187z" fill="#d1c9bd" />
        <path d="m635.1 44.27h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m646.7 44.27h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m646.7 42.26h-23.25v2.057h23.25z" fill="#78694f" />
        <path d="m666.3 44.27h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m677.9 44.27h-11.63v4.478h11.63z" fill="#5c4c33" />
        <path d="m677.9 42.26h-23.25v2.057h23.25z" fill="#78694f" />
        <g fill="#b0a38c">
          <path
            d="m543 41.05c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
          />
          <path
            d="m574.2 41.05c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
          />
          <path
            d="m605.3 41.05c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
          />
          <path
            d="m636.5 41.05c0 0.8131-0.6358 1.469-1.462 1.469-0.7918 0-1.445-0.6565-1.445-1.469s0.6532-1.469 1.445-1.469c0.8265 0 1.462 0.6565 1.462 1.469"
          />
          <path
            d="m667.7 41.05c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
          />
        </g>
        <path d="m541.5 100.1h-11.63v4.478h11.63z" fill="#706142" />
        <path d="m553.1 100.1h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m553.1 98.12h-23.25v2.048h23.25z" fill="#78694f" />
        <path d="m572.7 100.1h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m584.3 100.1h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m584.3 98.12h-23.23v2.048h23.23z" fill="#78694f" />
        <path d="m603.9 100.1h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m615.5 100.1h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m615.5 98.12h-23.23v2.048h23.23z" fill="#78694f" />
        <path d="m646.7 104.5h0.0187v-4.478h-0.0187z" fill="#d1c9bd" />
        <path d="m635.1 100.1h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m646.7 100.1h-11.62v4.478h11.62z" fill="#5c4c33" />
        <path d="m646.7 98.12h-23.25v2.048h23.25z" fill="#78694f" />
        <path d="m666.3 100.1h-11.62v4.478h11.62z" fill="#706142" />
        <path d="m677.9 100.1h-11.63v4.478h11.63z" fill="#5c4c33" />
        <path d="m677.9 98.12h-23.25v2.048h23.25z" fill="#78694f" />
        <g fill="#b0a38c">
          <path
            d="m543 96.91c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
          />
          <path
            d="m574.2 96.91c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
          />
          <path
            d="m605.3 96.91c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
          />
          <path
            d="m636.5 96.91c0 0.8038-0.6358 1.461-1.462 1.461-0.7918 0-1.445-0.6578-1.445-1.461 0-0.8131 0.6532-1.479 1.445-1.479 0.8265 0 1.462 0.6658 1.462 1.479"
          />
          <path
            d="m667.7 96.91c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
          />
        </g>
      </g>
    </g>
  </svg>
`;
