// import { ref } from 'vue';
import { productName } from '~/version.js';

const SETTINGSKEY = 'client-settings';

/**
 * Store - maintains general properties
 */
export class Store {
  constructor() {
    this.snackbarHandler = undefined;
    this.customSearch = getLocalState('store.customSearch');
    this.navbarTitle = '';
    this.downloadFormat = getLocalState('store.format') ?? 'yaml';
    this.customFormat = getLocalState('store.customFormat') ?? 'xlsx';
    this.customDataType = getLocalState('store.customDataType') ?? 'perEvent';
    this.archiveFormat = getLocalState('store.archiveFormat') ?? 'file';
    this.audioFormat = getLocalState('store.audioFormat') ?? 'ogg';

    this.productName = productName;

    this.clipboard = {};
    this.rowsPerPage = getLocalState('store.rowsPerPage') ?? '10';
    this.options = { projectListUsers: false };

    const payload = getLocalState(SETTINGSKEY) ?? {};

    for (const [key, value] of Object.entries(payload)) {
      this[key] = value;

      console.log(key, value);
    }
  }

  setRowsPerPage = nv => {
    this.rowsPerPage = nv;
    setLocalState('store.rowsPerPage', nv);
  };

  showSnack = snack => {
    if (this.snackbarHandler) {
      this.snackbarHandler(snack);
    }
  };

  setSnackbarHandler = handler => {
    this.snackbarHandler = handler;
  };

  setAudioFormat = nv => {
    this.audioFormat = nv;
    setLocalState('store.audioFormat', nv);
  };

  setCustomSearch = nv => {
    this.customSearch = nv;
    setLocalState('store.customSearch', nv);
  };

  setNavbarTitle = nv => {
    this.navbarTitle = nv;
  };

  setDownloadFormat = nv => {
    this.downloadFormat = nv;
    setLocalState('store.format', nv);
  };

  setCustomFormat = nv => {
    this.customFormat = nv;
    setLocalState('store.customFormat', nv);
  };

  setCustomDataType = nv => {
    this.customDataType = nv;
    setLocalState('store.customDataType', nv);
  };

  setArchiveFormat = nv => {
    this.archiveFormat = nv;
    setLocalState('store.archiveFormat', nv);
  };
}

// ===
// Private functions
// ===

function getLocalState(key) {
  return JSON.parse(window.localStorage.getItem(key));
}

function setLocalState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state));
}
