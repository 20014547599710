!Object.fromEntries &&
  // eslint-disable-next-line unicorn/prefer-object-from-entries
  (Object.fromEntries = (/** @type {[String, any][]} */ array) => {
    /**
     * @type {{[x:string]: any}}
     */
    const accumulator = {};

    for (const [k, v] of array) {
      accumulator[k] = v;
    }
    return accumulator;
  });
