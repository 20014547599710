const ap = 'chicken/';

function* nums(max) {
  let index = 1;
  while (index <= max) {
    yield index++;
  }
}

function getAssetUrls() {
  let urls = [...nums(32)].map(n => `${ap}c${n}.svg`);
  urls = [...urls, `${ap}hutch_open.svg`, `${ap}hutch_closed.svg`, `${ap}egg.svg`, `${ap}half-egg.svg`];
  return urls;
}

const background = [
  // [0.15, 0.8, 18, 'media:app/tree.svg'],
  // [0.15, 0.05, 4, 'media:chicken/c31.svg'],

  [2.78, 25.03, 7.01, 37.34, 'media:app/tree.svg'],
  [83.36, 23.98, 16.55, 25.5, 'media:app/tower.svg'],
];

export const hutches = {
  hutchSize: 9,
  chickenSize: 8,
  egg: {
    size: 10,
    location: [0.02, 0.02],
  },
  locations: [
    [0.63, 0.05, 7],
    [0.7, 0.31, 7],
    [0.62, 0.57, 7],
    [0.7, 0.83, 7],
    [0.31, 0.13, 7],
    [0.43, 0.31, 7],
    [0.3, 0.55, 7],
    [0.43, 0.76, 7],
  ],
};

/**
 * @type {[x: number, y: number, w: number, h: number, angle: number, iconType: string][]}
 */
export const rewardLocations = [
  [74.09, 47.94, 5.39, 6.52, 0, 'f'],
  [32.87, 37.33, 5.39, 6.52, 60, 'h'],
  [13, 56.19, 5.39, 6.52, 0, 'f'],
  [88.73, 77.6, 5.39, 6.52, 0, 'f'],
  [30.63, 65.52, 5.39, 6.52, 100, 'h'],
  [6.54, 63.44, 5.39, 6.52, 0, 'f'],
  [64.77, 64.71, 5.39, 6.52, 0, 'f'],
  [47.62, 44.4, 5.39, 6.52, 60, 'h'],
  [93.66, 51.3, 5.39, 6.52, 0, 'f'],
  [10.68, 39.47, 5.39, 6.52, 0, 'f'],
  [80.12, 55.18, 5.39, 6.52, 0, 'f'],
  [28.35, 46.69, 5.39, 6.52, 70, 'h'],
  [52.96, 53.51, 5.39, 6.52, 100, 'h'],
  [17.98, 63.87, 5.39, 6.52, 0, 'f'],
  [74.17, 79.21, 5.39, 6.52, 0, 'f'],
  [60.53, 49.49, 5.39, 6.52, 0, 'f'],
  [93.84, 71.42, 5.39, 6.52, 0, 'f'],
  [43.28, 67.51, 5.39, 6.52, 100, 'h'],
  [41.94, 34.26, 5.39, 6.52, 100, 'h'],
  [87.21, 58.54, 5.39, 6.52, 0, 'f'],
  [21.29, 38.61, 5.39, 6.52, 0, 'f'],
  [72.68, 59.06, 5.39, 6.52, 0, 'f'],
  [38.95, 46.86, 5.39, 6.52, 60, 'h'],
  [67.44, 42.88, 5.39, 6.52, 0, 'f'],
  [64.91, 77.35, 5.39, 6.52, 0, 'f'],
  [19.89, 55.95, 5.39, 6.52, 0, 'f'],
  [46.71, 57.52, 5.39, 6.52, 100, 'h'],
  [81.7, 79.15, 5.39, 6.52, 0, 'f'],
  [7.1, 50.38, 5.39, 6.52, 0, 'f'],
  [55.46, 64.94, 5.39, 6.52, 120, 'h'],
  [86.3, 48.09, 5.39, 6.52, 0, 'f'],
  [36.54, 71.45, 5.39, 6.52, 80, 'h'],
  [16.53, 46.51, 5.39, 6.52, 0, 'f'],
  [78.53, 65.03, 5.39, 6.52, 0, 'f'],
  [40.01, 58.66, 5.39, 6.52, 100, 'h'],
  [94.29, 62.12, 5.39, 6.52, 0, 'f'],
  [66.46, 53.82, 5.39, 6.52, 0, 'f'],
  [24.62, 68.61, 5.39, 6.52, 80, 'h'],
  [85.72, 68.94, 5.39, 6.52, 0, 'f'],
  [33.38, 54.27, 5.39, 6.52, 80, 'h'],
  [71.58, 71.1, 5.39, 6.52, 0, 'f'],
  [12.08, 70.67, 5.39, 6.52, 0, 'f'],
  [49.55, 70.66, 5.39, 6.52, 80, 'h'],
  [58.68, 75.24, 5.39, 6.52, 0, 'f'],
];

export const hutch = [
  [11.47, 39.79, 9.57, 18.95],
  [34.07, 43.1, 9.57, 18.95],
  [52.17, 31.6, 9.57, 18.95],
  [74.9, 43.1, 9.57, 18.95],
  [5.54, 69.12, 9.57, 18.95],
  [29.48, 69.81, 9.57, 18.95],
  [56.7, 61.83, 9.57, 18.95],
  [82.27, 70.06, 9.57, 18.95],
];

const defaultSettings = {
  trailerPrePause: 500,
  trailerPostPause: 1000,
  audioTrailer: 'chicken-call.m4a',
};

const defaultStimuliSet = [...nums(32)].filter(n => ![9, 32].includes(n));

const defaultText = {
  fixation: 'Watch for the chickens to appear!',
  'stimulus-head': 'Keep watching for the chickens – and remember where they appear!',
  'stimulus-tail': 'Keep looking for more chickens!',
  'stimulus-hold': 'Keep looking for the chickens!',
  response: 'Now touch the hutches where you saw the chickens appear!',
  'feedback-incorrect': 'That hutch was not correct',
  'feedback-correct': 'The hutches were all correct',
  'block-feedback': 'Each time you’re right, you’ll get another egg!',
  'phase-feedback': 'Do you want to try this yourself, or do you want me to show you again?',
};

const eopText =
  'Uh oh &ndash; your child has not collected enough eggs this time! Go through the training phase together by pressing one of the &lsquo;ad&rsquo; buttons in the top corners of your screen for {adultInterventionHoldTimeSeconds} seconds. Then press the Instructions button for the Chicken game and read through the instructions with your child. If your child has simply stopped playing but you think understands how to play the game, press the &lsquo;Home&rsquo; button to return to the home screen.';

export default {
  getAssetUrls,
  background,
  hutches,
  defaultSettings,
  defaultStimuliSet,
  defaultText,
  eopText,
};
