import { LitElement, css, html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { when } from 'lit/directives/when.js';
import { closest } from './ponyfill.js';

// eslint-disable-next-line no-unused-vars
const noop = (/** @type {any} */ _options) => {};

export class Snackbar extends LitElement {
  static properties = {
    opened: { state: true },
    opening: { state: true },
    closing: { state: true },
  };

  constructor() {
    super();

    this.queue = [];
    this.snack = {};
  }

  render() {
    return html`
      <aside
        class=${classMap({
          snackbar: true,
          'snackbar-leading': this.snack.leading,
          'snackbar-open': this.opened,
          'snackbar-opening': this.opening,
          'snackbar-closing': this.closing,
        })}
      >
        <div class="snackbar-surface" role="status" aria-relevant="additions" @click=${this.surfaceClickHandler}>
          <div ref="labelEl" class="snackbar-label" aria-atomic="false">
            ${when(
              this.snack.message,
              () => html`${this.snack.message}`,
              () => html`<span style="display: inline-block; width: 0; height: '1px'" v-else>&nbsp;</span>`,
            )}
          </div>
          <div class="snackbar-actions" aria-atomic="true">
            ${this.renderActionText()} ${this.renderDismissAction()}
          </div>
        </div>
      </aside>
    `;
  }

  renderActionText() {
    if (this.snack.actionText) {
      return html` <md-filled-button class="mdc-button snackbar-action">${this.snack.actionText}</md-filled-button> `;
    }
  }

  renderDismissAction() {
    if (this.snack.dismissAction) {
      return html`
        <button type="button" class="mdc-icon-button snackbar-dismiss " title="Dismiss">
          <md-icon>close</md-icon>
        </button>
      `;
    }
  }

  /**
   * @param {Event} event_
   */
  surfaceClickHandler({ target }) {
    if (isActionButton_(target)) {
      this.close('action');
    } else if (isActionIcon_(target)) {
      this.close('dismiss');
    }
  }

  handleSnack = ({
    timeoutMs = 5000,
    closeOnEscape,
    message = '',
    actionText = '',
    dismissAction = true,
    stacked,
    leading,
    actionHandler = noop,
  }) => {
    this.queue.push(() => {
      this.snack = {
        timeoutMs,
        closeOnEscape,
        message,
        actionText,
        actionHandler,
        dismissAction,
        stacked,
        leading,
      };
      this.actionHandler_ = actionHandler;

      this.open();
    });
    if (this.queue.length === 1) {
      this.queue[0]();
    }
  };

  handleClosed = () => {
    this.opened = false;
    this.queue.shift();

    if (this.queue.length > 0) {
      Promise.resolve().then(() => this.queue[0]());
    }
  };

  clearAutoDismissTimer() {
    clearTimeout(this.autoDismissTimer);
    this.autoDismissTimer = 0;
  }

  runNextAnimationFrame(callback) {
    cancelAnimationFrame(this.animationFrame);
    this.animationFrame = requestAnimationFrame(() => {
      this.animationFrame = 0;
      clearTimeout(this.animationTimer);
      this.animationTimer = setTimeout(callback, 0);
    });
  }

  handleAnimationTimerEnd() {
    this.animationTimer = 0;
    this.opening = false;
    this.closing = false;
  }
  open() {
    this.opening = true;
    this.closing = false;

    this.notifyOpening();

    // Wait a frame once display is no longer "none", to establish basis for animation
    this.runNextAnimationFrame(() => {
      this.opened = true;

      this.animationTimer = setTimeout(() => {
        this.setTimeoutMs(this.snack.timeoutMs);
        this.handleAnimationTimerEnd();

        this.notifyOpened();

        if (this.autoDismissTimeoutMs !== -1) {
          this.autoDismissTimer = setTimeout(() => {
            this.close('dismiss');
          }, this.autoDismissTimeoutMs);
        }
      }, 150);
    });

    this.setTimeoutMs(this.snack.timeoutMs);

    if (this.autoDismissTimeoutMs !== -1) {
      this.autoDismissTimer = setTimeout(() => {
        this.close('dismiss');
      }, this.autoDismissTimeoutMs);
    }
  }

  show(snack) {
    this.handleSnack(snack);
  }

  close(reason = '') {
    if (!this.opened) {
      // Avoid redundant close calls (and events), e.g. repeated interactions as the snackbar is animating closed
      return;
    }

    cancelAnimationFrame(this.animationFrame);
    this.animationFrame = 0;
    this.clearAutoDismissTimer();

    this.opened = false;
    this.notifyClosing(reason);
    this.closing = true;
    this.opening = false;

    clearTimeout(this.animationTimer);
    this.animationTimer = setTimeout(() => {
      this.handleAnimationTimerEnd();
      this.notifyClosed(reason);
    }, 75);
  }

  notifyClosed(reason) {
    this.dispatchEvent(new CustomEvent('onc-snackbar-closed', { detail: { reason } }));

    if (this.actionHandler_ && reason === 'action') {
      this.actionHandler_({ reason });
    }

    this.handleClosed();
  }

  notifyClosing(reason) {
    this.dispatchEvent(new CustomEvent('onc-snackbar-closing', { detail: { reason } }));
  }

  notifyOpened() {
    this.dispatchEvent(new Event('onc-snackbar-opened'));
  }

  notifyOpening() {
    this.dispatchEvent(new Event('onc-snackbar-opening'));
  }

  setTimeoutMs(timeoutMs) {
    const minValue = 4000;
    const maxValue = 10_000;
    const indeterminateValue = -1;

    if (timeoutMs === -1 || (timeoutMs <= maxValue && timeoutMs >= minValue)) {
      this.autoDismissTimeoutMs = timeoutMs;
    } else {
      throw new Error(`
        timeoutMs must be an integer in the range ${minValue}–${maxValue}
        (or ${indeterminateValue} to disable), but got '${timeoutMs}'`);
    }
  }
}

function isActionButton_(target) {
  return Boolean(closest(target, '.snackbar-action'));
}

function isActionIcon_(target) {
  return Boolean(closest(target, '.snackbar-dismiss'));
}

const OncSnackbar = class OncSnackbar extends Snackbar {
  static styles = css`
    :host {
      --md-filled-button-container-shape: 10px;

      pointer-events: none;

      position: fixed;
      z-index: 8;
      right: 0;
      bottom: 0;
      left: 0;

      display: block;
      align-items: center;
      justify-content: center;

      box-sizing: border-box;
      margin: 8px;

      -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
    }

    .snackbar {
      pointer-events: none;

      display: none;
      align-items: center;
      justify-content: center;

      box-sizing: border-box;
    }

    .snackbar-surface {
      transform: scale(0.8);

      display: flex;
      align-items: center;
      justify-content: flex-start;

      box-sizing: border-box;
      min-width: 344px;
      max-width: 672px;
      padding-right: 8px;
      padding-left: 0;

      background-color: #333;
      border-radius: 4px;
      border-radius: var(--mdc-shape-small, 4px);
      box-shadow:
        0 3px 5px -1px rgb(0 0 0 / 20%),
        0 6px 10px 0 rgb(0 0 0 / 14%),
        0 1px 18px 0 rgb(0 0 0 / 12%);
    }

    .snackbar-label {
      flex-grow: 1;

      box-sizing: border-box;
      width: 100%;
      margin: 0;
      padding: 14px 8px 14px 16px;

      font-family: Roboto, sans-serif;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.25rem;
      color: rgb(255 255 255 / 87%);
      text-decoration: inherit;
      text-transform: inherit;
      letter-spacing: 0.0179em;

      visibility: hidden;

      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
    }

    @media (344px <= width < 480px) {
      .snackbar-surface {
        min-width: 100%;
      }
    }

    .snackbar-opening,
    .snackbar-open,
    .snackbar-closing {
      display: flex;
    }

    .snackbar-actions {
      display: flex;
      flex-shrink: 0;
      align-items: center;

      box-sizing: border-box;

      visibility: hidden;
    }

    .snackbar-open .snackbar-label,
    .snackbar-open .snackbar-actions {
      visibility: visible;
    }

    .snackbar-leading {
      justify-content: flex-start;
    }

    .snackbar-surface::before {
      pointer-events: none;
      content: '';

      position: absolute;
      top: 0;
      left: 0;

      box-sizing: border-box;
      width: 100%;
      height: 100%;

      border: 1px solid transparent;
      border-radius: inherit;
    }

    .snackbar-open .snackbar-surface {
      pointer-events: auto;

      transform: scale(1);

      opacity: 1;

      transition:
        opacity 150ms 0ms cubic-bezier(0, 0, 0.2, 1),
        -webkit-transform 150ms 0ms cubic-bezier(0, 0, 0.2, 1);
      transition:
        opacity 150ms 0ms cubic-bezier(0, 0, 0.2, 1),
        transform 150ms 0ms cubic-bezier(0, 0, 0.2, 1);
      transition:
        opacity 150ms 0ms cubic-bezier(0, 0, 0.2, 1),
        transform 150ms 0ms cubic-bezier(0, 0, 0.2, 1),
        -webkit-transform 150ms 0ms cubic-bezier(0, 0, 0.2, 1);
    }

    .snackbar-closing .snackbar-surface {
      transform: scale(1);
      transition: opacity 75ms 0ms cubic-bezier(0.4, 0, 1, 1);
    }

    .snackbar-label::before {
      content: attr(data-snackbar-label-text);
      display: inline;
    }

    .snackbar-action:not(:disabled) {
      color: #bb86fc;
    }

    .snackbar-dismiss {
      width: 36px;
      height: 36px;
      padding: 6px;

      font-size: 18px;
      color: rgb(255 255 255 / 87%);
    }

    .snackbar-action + .snackbar-dismiss {
      margin-right: 0;
      margin-left: 8px;
    }
  `;
};
customElements.define('onc-snackbar', OncSnackbar);
