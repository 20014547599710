import { GameTask, fixupDurationValues } from '@state/project/game-task.js';

/**
 * @typedef {import('@state/project/game-task.js').TaskRecord} TaskRecord
 * @typedef {import('@state/project/game-task.js').TaskModel} TaskModel
 */

/**
 * @typedef { TaskModel & {
 * durations: {[key:string]: string| {[key:string]: string}},
 * general: {showTrailer: Boolean, showLayout: Boolean, debugRewards: Boolean},
 * trailer: {prePause: String, postPause: String}}
 * } FarmTaskModel
 */

const fields_ = [
  ['prePause', 'ms'],
  ['postPause', 'ms'],
];

export class FarmGameSettings extends GameTask {
  /**
   *
   * @param {TaskRecord & {model: FarmTaskModel}} taskRecord
   */
  constructor(taskRecord) {
    super(taskRecord);

    const trailer = /** @type { {prePause: Number, postPause: Number}} */ (
      fixupDurationValues({ ...taskRecord.model.trailer }, fields_)
    );

    const { showTrailer, showLayout, debugRewards = false } = taskRecord.model.general;
    this.showTrailer_ = showTrailer;
    this.showLayout_ = showLayout;
    this.debugRewards_ = debugRewards;

    this.trailer = { prePause: trailer.prePause, postPause: trailer.postPause };
  }

  get showTrailer() {
    return this.showTrailer_;
  }

  get showLayout() {
    return this.showLayout_;
  }

  get debugRewards() {
    return this.debugRewards_;
  }

  get durations() {
    return {};
  }
}
