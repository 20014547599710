import { shuffle } from '@ounce/onc';
import { TaskControllerBase } from '@tasks/task-controller-base';
import { addMinutes, isBefore } from 'date-fns';
import { rewardLocations } from './chicken-data.js';
import { ChickenRewardSource } from './chicken-reward-source';
import { ChickenPhaseSetting } from './specs/chicken-phase-setting.js';
import { ChickenSettings } from './specs/chicken-settings.js';
import { stimuliSource } from './stimuli-it';

/**
 * @typedef {import('lit').ReactiveControllerHost} ReactiveControllerHost
 * @typedef {import('lit').ReactiveElement} ReactiveElement
 * @typedef {import("./specs/chicken-demo.js").ChickenDemo} ChickenDemo
 * @typedef {import('./specs/chicken-profile.js').ChickenProfile} ChickenProfile
 */

export class TaskController extends TaskControllerBase {
  /**
   * @param {ReactiveControllerHost & ReactiveElement} host
   * @param {string} taskId
   */
  constructor(host, taskId) {
    super(host, taskId);

    const taskRecord = this.project?.getById(taskId);
    this.taskSettings_ = new ChickenSettings(taskRecord);

    this.rewardSrc = new ChickenRewardSource(rewardLocations, { f: 'egg', h: 'halfEgg' });

    this.phases = undefined;
    this.totalBlocks_ = undefined;
    this.phaseIndex = 0;
    this.mode_ = 'play';

    const ss = this.taskSettings.stimuliSet;

    this.taskProfile = /** @type {ChickenProfile} */ (this.profile.tasks.get(taskId));

    const targets = this.taskProfile.targets;

    if (!targets?.length) {
      const newTargets =
        ss.kind === 'random'
          ? shuffle.getRandomSubarray(defaultStimuliSet_, ss.setSize)
          : this.listAsTargets(ss.setItems);

      const a = new Set(newTargets);

      const distractors = defaultStimuliSet_.filter(x => !a.has(x));

      this.taskProfile.targets = newTargets;
      this.taskProfile.distractors = distractors;
      this.taskProfile.kind = ss.kind;

      this.saveTaskProfile();
    }

    this.targets = this.taskProfile.targets;
  }

  get durations() {
    return this.taskSettings.durations;
  }

  get taskSettings() {
    return this.taskSettings_;
  }

  get mode() {
    return this.mode_;
  }
  set mode(value) {
    if (this.mode_ !== value) {
      this.phases = undefined;
    }
    this.mode_ = value;
  }

  get gameName() {
    return 'chicken';
  }

  saveTaskProfile() {
    const record = this.taskProfile.asRecord();

    this.saveTaskRecord(record);
  }

  setAdaptationLastAdaptive(difficulty) {
    this.taskProfile.adaptation.difficulty = difficulty;

    this.saveTaskProfile();
  }

  get rewards() {
    return this.rewardSrc.rewards;
  }

  /**
   * @param {number} count
   */
  addRewards(count) {
    this.rewardSrc.addRewards(count);
  }

  getStimuli() {
    const targets = this.mode === 'play' ? this.targets : ['32'];
    const stimuli = stimuliSource(targets);

    return stimuli;
  }

  getPhases() {
    if (this.phases === undefined) {
      const phases =
        this.mode === 'demo'
          ? getDemoPhases(this.taskSettings.demo)
          : getMainPhases(this.taskSettings.phases, this.taskProfile.adaptation);

      let totalBlocks = 0;
      for (const phase of phases) {
        totalBlocks += phase.blocks.length;
      }

      this.totalBlocks_ = totalBlocks;
      this.phases = phases;
    }
    return this.phases;
  }

  get totalBlocks() {
    if (this.totalBlocks_ === undefined) {
      this.getPhases();
    }

    return this.totalBlocks_;
  }

  setAdaptationLastRun() {
    this.taskProfile.adaptation.lastRun = new Date();
    this.saveTaskProfile();
  }
}

// ===
// Private functions
// ===

function* nums(max) {
  let index = 1;
  while (index <= max) {
    yield index++;
  }
}

const defaultStimuliSet_ = [...nums(32)].filter(n => ![9, 32].includes(n));

/**
 * @param {ChickenPhaseSetting} phase
 */
function getBlocks(phase) {
  if (phase.type === 'practice') {
    const { trialsPerBlock } = phase;

    return [{ id: 1, trials: trialsPerBlock }];
  }

  const { numberOfBlocks, trialsPerBlock, fixedSequenceArray: fsa } = phase;
  const fixedSequenceArray = fsa?.length > 0 ? fsa.split(',').map(value => Number(value.trim())) : undefined;

  const blocks = Array.from({ length: numberOfBlocks }, (v, id) => ({
    id,
    trials: trialsPerBlock,
    fixedSequenceArray,
  }));
  return blocks;
}

/**
 * @param {ChickenPhaseSetting[]} phases
 * @param {{ lastRun: number | Date; }} adaptation
 */
function determinePhaseStartIndex(phases, adaptation) {
  if (!adaptation?.lastRun) {
    return 0;
  }

  const now = new Date();
  let candidateIndex;

  for (const [index, phase] of phases.entries()) {
    if (phase.type === 'adaptive') {
      candidateIndex = index;
      continue;
    }

    const skipDate = addMinutes(adaptation.lastRun, phase.skipTimeMinutes);

    if (isBefore(skipDate, now)) {
      return index;
    }
  }

  // if the last phase had skip time settings and it was skipped, we will use it anyway
  return candidateIndex ?? phases.length - 1;
}

/**
 *
 * @param {ChickenDemo} demo
 */
function getDemoPhases(demo) {
  const demoPhase = {
    type: 'practice',
    initialDifficulty: demo.practice.difficulty,
    trialsPerBlock: demo.practice.trials,
    maxNoResponseTrials: demo.practice.maxNoResponseTrials,
    numberOfBlocks: 1,
  };

  const demoPhaseSettings = new ChickenPhaseSetting(demoPhase);

  const blocks = getBlocks(demoPhaseSettings);

  const animationPhase = {
    type: 'animation',
    initialDifficulty: demo.animation.difficulty,
    trialsPerBlock: demo.animation.trials,
    numberOfBlocks: 1,
    maxNoResponseTrials: 0,
  };

  const animationPhaseSettings = new ChickenPhaseSetting(animationPhase);

  const animationblocks = getBlocks(animationPhaseSettings);

  return [
    { index: 0, phaseSettings: animationPhaseSettings, blocks: animationblocks },
    { index: 1, phaseSettings: demoPhaseSettings, blocks },
  ];
}

/**
 * @param {ChickenPhaseSetting[]} phases
 * @param {{ lastRun: number | Date; }} [adaptation]
 */
function getMainPhases(phases, adaptation) {
  const startIndex = determinePhaseStartIndex(phases, adaptation);

  const selectedPhases = [];

  for (let index = startIndex; index < phases.length; index++) {
    const blocks = getBlocks(phases[index]);
    selectedPhases.push({ index, isLastPhase: false, phaseSettings: phases[index], blocks });
  }

  selectedPhases.at(-1).isLastPhase = true;

  return selectedPhases;
}
