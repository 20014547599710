/**
 * @typedef { import('./record-types.js').SheepDemoRecord}  SheepDemoRecord
 */

export class SheepDemo {
  /**
   *
   * @param {SheepDemoRecord} demoRecord
   */
  constructor(demoRecord) {
    this.animation = {
      goTrials: demoRecord.animation.goTrials,
      nogoTrials: demoRecord.animation.nogoTrials,
    };

    this.practice = {
      goTrials: demoRecord.practice.goTrials,
      nogoTrials: demoRecord.practice.nogoTrials,
      maxNoResponseTrials: demoRecord.practice.maxNoResponseTrials,
    };
  }
}
