import { html } from 'lit';

export const barnWideTall = html`
  <svg version="1.1" viewBox="0 0 80 100" preserveAspectRatio="xMinYMin slice" xmlns="http://www.w3.org/2000/svg">
    <g stroke-width="1.002">
      <path d="m40.14 86.2h-0.4796v-81.44h0.4796z" fill="#ce2605" />
      <path
        d="m41.02 3.525-30.32 31.98-7.052 42.7-1.828 2.171 2.05 1.742 2.308-2.737 7.035-42.62 27.82-29.32v-3.918z"
        fill="#b7a48f"
      />
      <path
        d="m2.05 81.22-2.05-1.742 1.829-2.171 7.052-42.7 30.92-32.61 30.92 32.61 6.968 42.17 2.049 2.743-2.144 1.621-2.45-3.272-6.936-42.01-28.42-29.96-28.42 29.96-7.036 42.62-2.308 2.739z"
        fill="#b7a48f"
      />
      <path d="m52.26 61.81h-24.88v-25h24.88z" fill="#d62300" />
    </g>
    <g transform="matrix(.9991 0 0 1.004 -2.463 -2.421)" fill="#fff">
      <path d="m31.27 62.62h22.2v-22.2h-22.2zm24.9 2.695h-27.59v-27.59h27.59z" />
      <path d="m30.39 63.52h24v-24h-24zm24.9 0.9025h-25.8v-25.8h25.8z" />
      <path d="m42.77 64.06h-0.9288v-24.9h0.9288z" />
      <path d="m30.27 64.17-0.8012-0.4012 12.37-24.81 0.8012 0.4012z" />
      <path d="m41.89 64.17-12.37-24.81 0.8012-0.4012 12.37 24.81-0.8025 0.4012z" />
      <path d="m42.77 64.17-0.8012-0.4012 12.37-24.81 0.8012 0.4012z" />
      <path d="m54.39 64.17-12.37-24.81 0.8012-0.4012 12.37 24.81z" />
    </g>
    <g stroke-width="1.002">
      <path d="m22.79 54.38h-3.747v-9.756h3.747z" fill="#352613" />
      <path d="m22.79 54.94h-3.747v-0.8999h3.747z" fill="#fff" />
      <path d="m60.75 54.38h-3.747v-9.756h3.747z" fill="#352613" />
      <path d="m60.75 54.94h-3.747v-0.8999h3.747z" fill="#fff" />
      <path d="m76.86 79.84-7.358-44.6-29.67-31.28-29.67 31.28-7.363 44.6v8.103h74.08v-8.103h-5e-3z" fill="#ff3000" />
      <path
        d="m41.02 3.525-30.32 31.98-7.052 42.7-1.828 2.171 2.05 1.742 2.308-2.737 7.035-42.62 27.82-29.32v-3.918z"
        fill="#b7a48f"
      />
      <path
        d="m2.05 81.22-2.05-1.742 1.829-2.171 7.052-42.7 30.92-32.61 30.92 32.61 6.968 42.17 2.049 2.743-2.144 1.621-2.45-3.272-6.936-42.01-28.42-29.96-28.42 29.96-7.036 42.62-2.308 2.739z"
        fill="#b7a48f"
      />
    </g>
    <path
      d="m76.86 90.6-7.358-50.93-29.67-35.72-29.67 35.72-7.363 50.93v9.253h74.08v-9.253h-5e-3z"
      fill="#ff3000"
      stroke-width="1.07"
    />
    <path d="m40.14 86.2h-0.4796v-81.44h0.4796z" fill="#ce2605" stroke-width="1.002" />
    <path
      d="m39.61 4.778v90.34h0.4796v-90.34zm-3.747 3.752-0.4796 0.5567v86.03h0.4796zm8.003 0v86.59h0.3996v-86.03zm-12.23 4.932-0.4796 0.5567v81.1h0.4796zm16.43 0.01113v81.65h0.4996v-81.09zm-20.65 4.932-0.4796 0.5567v76.16h0.4796zm24.85 0v76.71h0.4996v-76.15zm-29.07 4.944-0.4796 0.5567v70.66c0.1599 0 0.3197 0 0.4796 0.1113zm33.37 0v71.21h0.3996v-70.66zm-37.6 4.932-0.4796 0.5567v64.95c0.1599 0.1113 0.3197 0.1113 0.4796 0.1113zm41.79 0.01114v65.6c0.09991 0 0.2997-0.1113 0.4996-0.1113v-64.93zm-46.02 4.932-0.4796 0.5567v59.11c0.1599 0 0.3097 0.1113 0.4796 0.1113zm50.22 0v59.78c0.1998-0.1113 0.2997-0.1113 0.4996-0.1113v-59.11zm-54.44 4.944-0.3397 0.3786-0.1499 1.002v52.23c0.1699 0 0.3197 0.1113 0.4896 0.1113zm58.64 0v53.72c0.1998-0.1113 0.3996-0.1113 0.4996-0.1113v-52.22l-0.09991-1.013zm-62.87 26.56-0.4696 3.218v22.5c0.1599 0.1113 0.3097 0.1113 0.4696 0.2227zm67.17 0.03341v25.8c0.09991 0 0.2997-0.1113 0.4996-0.1113v-22.46z"
      fill="#ce2605"
      stroke-width="1.055"
    />
    <g stroke-width="1.002">
      <path
        d="m41.02 3.525-30.32 31.98-7.052 42.7-1.828 2.171 2.05 1.742 2.308-2.737 7.035-42.62 27.82-29.32v-3.918z"
        fill="#b7a48f"
      />
      <path
        d="m2.05 81.22-2.05-1.742 1.829-2.171 7.052-42.7 30.92-32.61 30.92 32.61 6.968 42.17 2.049 2.743-2.144 1.621-2.45-3.272-6.936-42.01-28.42-29.96-28.42 29.96-7.036 42.62-2.308 2.739z"
        fill="#b7a48f"
      />
      <path d="m52.26 61.81h-24.88v-25h24.88z" fill="#d62300" />
    </g>
    <g transform="matrix(.9991 0 0 1.004 -2.463 -2.421)" fill="#fff">
      <path d="m31.27 62.62h22.2v-22.2h-22.2zm24.9 2.695h-27.59v-27.59h27.59z" />
      <path d="m30.39 63.52h24v-24h-24zm24.9 0.9025h-25.8v-25.8h25.8z" />
      <path d="m42.77 64.06h-0.9288v-24.9h0.9288z" />
      <path d="m30.27 64.17-0.8012-0.4012 12.37-24.81 0.8012 0.4012z" />
      <path d="m41.89 64.17-12.37-24.81 0.8012-0.4012 12.37 24.81-0.8025 0.4012z" />
      <path d="m42.77 64.17-0.8012-0.4012 12.37-24.81 0.8012 0.4012z" />
      <path d="m54.39 64.17-12.37-24.81 0.8012-0.4012 12.37 24.81z" />
    </g>
    <g stroke-width="1.002">
      <path d="m22.79 54.38h-3.747v-9.756h3.747z" fill="#352613" />
      <path d="m22.79 54.94h-3.747v-0.8999h3.747z" fill="#fff" />
      <path d="m60.75 54.38h-3.747v-9.756h3.747z" fill="#352613" />
      <path d="m60.75 54.94h-3.747v-0.8999h3.747z" fill="#fff" />
      <path
        d="m41.02 3.525-30.32 31.98-7.052 42.7-1.828 2.171 2.05 1.742 2.308-2.737 7.035-42.62 27.82-29.32v-3.918z"
        fill="#b7a48f"
      />
      <path
        d="m2.05 81.22-2.05-1.742 1.829-2.171 7.052-42.7 30.92-32.61 30.92 32.61 6.968 42.17 2.049 2.743-2.144 1.621-2.45-3.272-6.936-42.01-28.42-29.96-28.42 29.96-7.036 42.62-2.308 2.739z"
        fill="#b7a48f"
      />
      <path
        d="m79.24 82.9 0.7643-0.4719-2.619-4.322v5.582l1.66-1.668-0.6319-0.6401-1.477 1.482v-2.722l0.7643 1.016 2.144-1.621-2.049-2.743-6.968-42.17-30.85-32.52v85.65h37.81v-6.208h-0.8431v-2.441l2.303 3.804z"
        fill="#8c7a5a"
        opacity=".35572"
      />
    </g>
    <path d="m69.03 99.16h-54.56v-32.35h54.56z" fill="#352613" stroke-width="2.106" />
    <path d="m68.76 100h-54.43v-0.8999h54.43z" fill="#fff" stroke-width="1.678" />
  </svg>
`;
