import { animate } from '@lit-labs/motion';
import { ContextConsumer } from '@lit/context';
import { Task } from '@lit/task';
import { ElapsedTimer, shuffle } from '@ounce/onc';
import gameDelay from 'delay';
import { LitElement, css, html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { createRef, ref } from 'lit/directives/ref.js';
import { styleMap } from 'lit/directives/style-map.js';
import { when } from 'lit/directives/when.js';
import { graphics } from '~/graphics/index.js';
import { chickenTaskContext } from './chicken-context.js';
import { hutch, hutches } from './chicken-data.js';

/**
 * @typedef {import('./chicken-types.js').Hutch} Hutch
 * @typedef {import('./chicken-types.js').Trial} Trial
 * @typedef {import('./chicken-types.js').TrialwithIndex} TrialwithIndex
 * @typedef {import('./chicken-types.js').ChickenBlock} ChickenBlock
 * @typedef {import('./specs/chicken-phase-setting.js').ChickenPhaseSetting} ChickenPhaseSetting
 */

const _eggDefault = {
  size: 3,
  location: [0.08, 0.15],
};

const background = [
  [2.78, 15.75, 7.01, 37.34, 'memory:water-tower'],
  [83.36, 23.98, 16.55, 25.5, 'app:tree'],
];

export class ChickenPresenter extends LitElement {
  static properties = {
    block: { type: Object },
    phaseSettings: { type: Object },
    hutches: { state: true },
    fingerStyles: { state: true },
    fingerShow: { state: true },
    state: { state: true },
  };

  /**
   * @type {number}
   */
  openHutchSize;

  chickenSize;

  constructor() {
    super();

    /** @type {ChickenBlock} */
    this.block = undefined;
    this.phaseSettings = undefined;
    this.hutchState = [];
    this.fingerStyles = {};
    this.fingerShow = false;
    this.state = 0;

    this.hutches = this.getHutches();
    this.backgroundItems = this.getBGItems(background);

    this.trialNumber = 0;
    this.showLayout = false;

    this.durations = {};
    this.progressValue = 0;

    this.clickCount = 0;
    this.animationPromises = [];

    this.targetRefs = [];

    for (let index = 0; index < 8; index++) {
      this.targetRefs.push(createRef());
    }

    this.trialResponses = [];
    this.maxNoResponseExceeded = false;

    new ContextConsumer(this, { context: chickenTaskContext, callback: value => (this.taskController = value) });

    this.responseTimer = new ElapsedTimer();
    this.noResponseCount = 0;

    this.getTrials = new Task(this, {
      /**
       * @param {[ChickenBlock, ChickenPhaseSetting]} param0
       */
      task: ([block, phaseSettings]) => {
        const { trials: numberOfTrials, difficulty, fixedSequenceArray } = block;
        const { type: phaseType, maxNoResponseTrials = 1 } = phaseSettings;

        this.phaseType = phaseType;
        this.difficulty = difficulty;
        this.maxNoResponseTrials = maxNoResponseTrials ?? 1;
        this.stimuli = this.taskController.getStimuli();
        this.durations = this.taskController.durations;
        this.showLayout = this.taskController.showLayout;

        const trials = this.makeTrials(this.stimuli, numberOfTrials, difficulty, fixedSequenceArray);

        this.trialsIt = trialsGenerator(trials);
        this.state = 0;
        this.trialNumber = 0;

        this.fingerShow = false;

        this.fingerStyles.top = '90.75%';
        this.fingerStyles.left = '44.34%';
        this.fingerStyles.width = '11.48%';
        this.fingerStyles.height = '23.32%';
        this.fingerStyles['--tap-display'] = 'none';

        if (!this.abortController) {
          this.abortController = new AbortController();
        }

        this.next().catch(error => {
          if (error?.name !== 'AbortError') {
            console.log(error);
          } else if (this.running) {
            this.done();
          }
        });
      },
      args: () => [this.block, this.phaseSettings],
    });
  }

  /**
   * @param {number} milliseconds
   */
  delay(milliseconds) {
    return gameDelay(milliseconds, { signal: this.abortController.signal });
  }

  /**
   * @param {string } key
   */
  playSound(key) {
    return this.taskController.playSound(key);
  }

  setupNoReponseHandler(initial = false) {
    if (this.phaseType === 'animation') {
      return;
    }
    const key = initial ? 'noResponseTimeoutInitial' : 'noResponseTimeout';

    this.noResponseTimeoutId = setTimeout(async () => {
      this.state = 3;
      this.handleNoResponse();
    }, this.durations[key]);
  }

  clearNoReponseTimeout() {
    clearTimeout(this.noResponseTimeoutId);
  }

  resetNoResponseTimer() {
    this.clearNoReponseTimeout();
    this.setupNoReponseHandler();
  }

  async next() {
    await this.taskController.pushItem({
      startBlock: new Date(),
      block: this.block.id,
      difficulty: this.difficulty,
    });

    if (this.phaseType === 'practice') {
      await this.playSound('demo block cue 1');
    }

    await this.playSound(`${this.difficulty}chickens`);

    if (this.phaseType !== 'practice') {
      await this.playSound(`block info`);
    }

    this.responseTimer.start();
    this.trialResults = [];

    this.running = true;
    for (const trial of this.trialsIt) {
      this.trial = trial;
      this.state = 0;

      this.updateProgressValue(trial.progress);

      await this.runTrial(trial);

      if (this.abortController.signal.aborted) {
        break;
      }

      this.animationPromises = [];

      const runPromise = new Promise(resolve => {
        this.runResolve = resolve;
      });

      if (this.phaseType === 'animation') {
        await this.updateComplete;
        requestAnimationFrame(() => this.cellChanged(trial));
      }
      this.setupNoReponseHandler(true);

      // accept clicks
      this.state = 2;
      this.responseTimer.start();
      this.onsetTime = this.responseTimer.elapsed();

      await this.playSound('go');

      // await run completion - either because we have completed the clicks (correct/incorrect) or
      // the no response timer expired
      const { trialData, maxNoResponseExceeded } = await runPromise;

      this.maxNoResponseExceeded = maxNoResponseExceeded;

      const response = {
        ...trialData,
        hutches: trial.stimuli.map(s => [s.hutch.hutchId, `c${s.stimulusId}`]),
      };

      if (trial.fixedSequence) {
        response.fixedSequence = true;
      }

      this.trialResponses.push(response);
      await this.taskController.pushItem(response);

      if (maxNoResponseExceeded || this.abortController.signal.aborted) {
        break;
      }
    }

    const endBlockItem = {
      endBlock: new Date(),
    };

    if (this.maxNoResponseExceeded) {
      endBlockItem.maxNoResponseExceeded = true;
    }

    await this.taskController.pushItem(endBlockItem);

    this.done();
  }

  /**
   * @param {number} progress
   */
  updateProgressValue(progress) {
    this.progressValue = progress;
    this.dispatchEvent(new Event('progress'));
  }

  async handleNoResponse() {
    this.trialCorrect = false;
    const trial = this.trial;

    this.trial.response = {
      trial: trial.index,
      noResponse: true,
    };

    this.noResponseCount += 1;

    await Promise.all([...this.animationPromises, this.playSound('no response')]);

    const value = { trialData: trial.response };

    if (this.noResponseCount >= this.maxNoResponseTrials) {
      value.maxNoResponseExceeded = true;
    }

    this.runResolve(value);
  }

  /**
   * @param {TrialwithIndex} trial
   */
  async runTrial(trial) {
    this.state = 1;

    this.hutchState.fill(0);
    this.clickCount = 0;
    this.fingerStyles.top = '90.75%';
    this.fingerStyles.left = '44.34%';

    const fixationPromises = [this.delay(this.durations.fixation)];

    const fixationKey =
      this.phaseType === 'animation' ? 'demo animation fixation cue' : 'demo practice-test fixation cue';

    fixationPromises.push(this.playSound(fixationKey));

    await Promise.all(fixationPromises);

    for (const stimulus of trial.stimuli) {
      await this.showStimulus(stimulus, this.durations.stimulus);

      await this.delay(this.durations.hold);
    }
  }

  /**
   * @param {{ hutch: any; stimulusId: any; }} stimulus
   * @param {number} duration
   */
  async showStimulus(stimulus, duration) {
    const item = stimulus.hutch;
    const number_ = stimulus.stimulusId;

    const { chickenSize } = hutches;

    const chickenIcon = graphics.chicken[`c${number_}`];

    const itemRemoved = this.hutches.filter(({ id }) => id !== item.id);

    /** @type {Hutch[]} */
    const newItems = [
      {
        icon: graphics.chicken.hutchOpen,
        top: item.top,
        left: item.left,
        width: hutches.hutchSize,
        id: 99,
      },

      {
        icon: chickenIcon,
        top: item.top + 0.05,
        left: item.left + 0.005,
        width: chickenSize,
        id: 98,
      },
    ];

    this.hutches = [...itemRemoved, ...newItems];

    await Promise.all([this.delay(duration), this.playSound('chicken call')]);

    const niIds = new Set(newItems.map(({ id }) => id));
    const filtered = this.hutches.filter(({ id }) => !niIds.has(id));
    this.hutches = [...filtered, item];
  }

  /**
   * @param {Hutch} item
   * @param {{ stimulusId: any; }} stimulus
   * @param {Boolean} isLastClick
   */
  async handleAsyncAction(item, stimulus, isLastClick) {
    const trial = this.trial;
    /**
     * @type {(arg0: any)=> void}
     */
    let animationPromiseResolve;

    const { chickenSize } = hutches;

    const animationPromise = new Promise(resolve => (animationPromiseResolve = resolve));

    this.animationPromises.push(animationPromise);

    // remove the hutch as we will render new item with enough info to
    // put it back after any animation(s)
    const number_ = stimulus.stimulusId;
    const chickenIcon = graphics.chicken[`c${number_}`];

    const removed = this.hutches.filter(({ id }) => id !== item.id);

    /** @type {Hutch[]} */
    const newItems = [
      {
        icon: graphics.chicken.hutchOpen,
        top: item.top,
        left: item.left,
        width: hutches.hutchSize,
        id: 99 + item.id,
        hutchId: item.hutchId,
      },

      {
        icon: chickenIcon,
        top: item.top + 0.05,
        left: item.left + 0.005,
        width: chickenSize,
        id: 199 + item.id,
        hutchId: item.hutchId,
      },
    ];

    this.hutches = [...removed, ...newItems];

    await this.playSound('good feedback');

    const duration = 500;

    if (isLastClick) {
      const egg = _eggDefault;

      const eggui = /** @type {Hutch} */ ({
        name: 'chicken:egg',
        top: item.top + egg.location[1],
        left: item.left + egg.location[0],
        width: egg.size,
        id: 299 + item.id,
        icon: graphics.chicken.egg,
        hutchId: item.hutchId,
      });
      newItems.push(eggui);
      this.hutches = [...this.hutches, eggui];

      await this.playSound('last tap');

      // we got to the end so the trial is correct
      this.trial.response.isCorrect = true;
    }

    await this.delay(duration);

    const niIds = new Set(newItems.map(({ id }) => id));
    const filtered = this.hutches.filter(({ id }) => !niIds.has(id));
    this.hutches = [...filtered, item];

    animationPromiseResolve();

    if (isLastClick) {
      await this.delay(1000);

      await Promise.all(this.animationPromises);

      this.runResolve({ trialData: trial.response });
    } else {
      if (this.phaseType === 'animation') {
        requestAnimationFrame(() => this.cellChanged(trial));
      }
    }
  }

  /**
   * @param {Hutch} item
   * @param {number} elapsed
   */
  handleAction(item, elapsed) {
    const trial = this.trial;

    if (!trial.response) {
      trial.response = { trial: trial.index, isCorrect: false, responses: [] };
    }

    const expectedIndex = this.clickCount++;

    const stimulus = trial.stimuli[expectedIndex];
    const currentResponse = {
      elapsed,
    };

    trial.response.responses.push(currentResponse);

    // the wrong hutch was clicked so we are done.
    // no more clicks allowed, awaiting any other click animations to end
    if (stimulus?.hutch.id !== item.id) {
      this.state = 3;

      trial.response.isCorrect = false;
      currentResponse.hutchId = item.id;

      const actual = trial.stimuli.find(s => s.hutch.id === item.id);

      if (actual) {
        currentResponse.stimulusId = `c${actual.stimulusId}`;
      }

      this.playSound('bad feedback').then(async () => {
        // need to await any in progress animations...
        await Promise.all(this.animationPromises);
        this.runResolve({ trialData: trial.response });
      });

      // all done
      return;
    }

    const isLastClick = expectedIndex === trial.stimuli.length - 1;

    if (isLastClick) {
      // trial complete, accept no more clicks
      this.state = 4;
    } else {
      this.resetNoResponseTimer();
    }

    // the remaining actions can be managed asynchronously
    this.handleAsyncAction(item, stimulus, isLastClick);
  }

  /**
   * @description Handler for UI clicks, will block user clicks from being processed during animation phase
   * @param {Hutch} item
   */
  handleItemClick(item) {
    if (this.phaseType !== 'animation') {
      return this.handleClick(item);
    }
  }

  /**
   * @param {Hutch} item
   */
  async handleClick(item) {
    if (this.state !== 2 || this.hutchState[item.hutchId] === 1) {
      return;
    }

    this.hutchState[item.hutchId] = 1;

    this.clearNoReponseTimeout();

    const elapsed = this.responseTimer.elapsed() - this.onsetTime;
    this.noResponseCount = 0;
    this.handleAction(item, elapsed);
  }

  done() {
    this.running = false;
    this.dispatchEvent(new Event('next'));
  }

  render() {
    return html`${this.getTrials.render({
      complete: () => {
        return html`
          ${this.renderBGItems()}

          <div class="content">
            ${when(
              this.fingerShow,
              () => html`
                <div
                  ${animate({
                    keyframeOptions: {
                      duration: 2000,
                    },
                    properties: ['left', 'top'],

                    onComplete: () => this.onComplete(),
                  })}
                  style=${styleMap(this.fingerStyles)}
                  class="finger-wrapper"
                >
                  <onc-svg-icon class="finger-icon">${graphics.app.fingerTap}</onc-svg-icon>
                </div>
              `,
            )}
            ${this.renderItems()}
          </div>
        `;
      },
    })}`;
  }

  renderBGItems() {
    const border = this.showLayout;

    const bgItems = this.backgroundItems.map(item => {
      return html` <onc-game-item class=${classMap({ border })} .item=${item}>${item.icon}</onc-game-item> `;
    });
    return html` <div class="content">${bgItems}</div> `;
  }

  renderItems() {
    const border = this.showLayout;
    const hutches = this.hutches.map(
      item => html`
        <onc-game-item
          pad
          class=${classMap({ hutch: true, border, scaled: item.click })}
          .item=${item}
          @click=${() => this.handleItemClick(item)}
          ${when(item.hutchId >= 0, () => ref(this.targetRefs[item.hutchId]))}
          >${item.icon}</onc-game-item
        >
      `,
    );
    return html` <div class="content">${hutches}</div> `;
  }

  /**
   * @param {import("./chicken-types.js").TrialwithIndex} trial
   */
  cellChanged(trial) {
    const hutchIndex = trial.stimuli[this.clickCount].hutch.hutchId;
    const element = /** @type {HTMLElement} */ (this.targetRefs[hutchIndex].value);

    this.fingerShow = true;

    const cellElement = element;
    const offsetParent = cellElement.offsetParent;

    const parentRect = offsetParent.getBoundingClientRect();
    const cellRect = cellElement.getBoundingClientRect();

    const relativeTop = cellRect.top - parentRect.top;
    const relativeLeft = cellRect.left - parentRect.left;

    const fw = (parentRect.width * 11.48) / 100;
    const fh = (parentRect.height * 23.32) / 100;

    const top = `${relativeTop + cellRect.height / 2 - fh * 0.2}px`;
    const left = `${relativeLeft + cellRect.width / 2 - fw * 0.4}px`;

    setTimeout(() => {
      this.fingerStyles = { ...this.fingerStyles, top, left };
    }, 100);
  }

  onComplete() {
    this.fingerStyles = { ...this.fingerStyles, ['--tap-display']: 'block' };

    setTimeout(() => {
      const hutchId = this.trial.stimuli[this.clickCount].hutch.hutchId;

      const item = /** @type {Element & {item: Hutch}} */ (this.targetRefs[hutchId].value)?.item;

      this.handleClick(item);

      this.fingerShow = false;
      this.fingerStyles = { ...this.fingerStyles, ['--tap-display']: 'none' };
    }, 500);
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    clearTimeout(this.noResponseTimeoutId);

    if (this.running) {
      console.log('running on disconnect');
      this.abortController.abort('disconnected');
    }
  }

  /**
   * @param {number} count
   */
  getHutchesForTrial(count) {
    count = Math.min(count, this.hutches.length);
    return shuffle.getRandomSubarray(this.hutches, count);
  }

  /**
   * @param {any[]} hutches
   * @param {Generator<any, void, unknown>} stimuli
   * @param {boolean} [fixedSequence]
   * @returns {Trial}

   */
  makeTrial(hutches, stimuli, fixedSequence) {
    const mappedHutches = hutches.map(h => {
      const { value } = stimuli.next();
      return { hutch: h, stimulusId: Number(value) };
    });

    return { stimuli: mappedHutches, fixedSequence };
  }

  /**
   * @param {Generator<any, void, unknown>} stimuli
   * @param {number} numberTrials
   * @param {number} difficulty
   * @param {string | any[]} fixedSequenceArray
   */
  makeTrials(stimuli, numberTrials, difficulty, fixedSequenceArray) {
    /** @type {Trial[]} */
    const trials = [];

    const isFixedSequence = fixedSequenceArray?.length > 0;

    if (isFixedSequence) {
      const hutches = [];

      let fsaIndex = 0;
      for (let n = 0; n < difficulty; n++) {
        hutches.push(this.hutches[fixedSequenceArray[fsaIndex++]]);
        fsaIndex %= fixedSequenceArray.length;
      }

      trials.push(this.makeTrial(hutches, stimuli, true));
    }

    while (trials.length < numberTrials) {
      const hutches = this.getHutchesForTrial(difficulty);
      trials.push(this.makeTrial(hutches, stimuli));
    }

    return isFixedSequence ? /** @type {Trial[]} */ (shuffle.getRandomSubarray(trials)) : trials;
  }

  onNext() {
    this.dispatchEvent(new Event('next'));
  }

  getBGItems(bgItems) {
    const itemsh = bgItems.map(([x, y, w, h, icon]) => [y / 100, x / 100, w, h, icon, 1]);

    return this.makeUIItems(itemsh);
  }

  getHutches() {
    const itemsh = hutch.map(([x, y, w, h], index) => [y / 100, x / 100, w, h, 'chicken:hutch-closed', index]);

    const uiItems = this.makeUIItems(itemsh);
    this.hutchState = Array.from({ length: uiItems.length }).fill(0);

    return uiItems;
  }

  /**
   * @param {any[]} items

   */
  makeUIItems(items) {
    /** @type {Hutch[]} */
    const uiItems = [];
    for (const [y, x, w, h, name, id] of items) {
      const [key, value] = name.split(':', 2);

      const icon = graphics[key][snakeToCamel(value)];

      uiItems.push({
        top: y,
        left: x,
        width: w,
        height: h,
        name,
        icon,
        id,
        hutchId: id,
      });
    }

    return uiItems;
  }
}
const OncChickenPresenter = class OncChickenPresenter extends ChickenPresenter {
  static styles = css`
    .content {
      position: absolute;
      width: 100%;
      height: 100%;
    }
    .hutch.scaled {
      transform: scale(1.05);
    }

    .finger-wrapper {
      position: absolute;
      top: 50%;
      left: 45%;

      width: 5%;
      height: auto;
      z-index: 10;
    }

    .finger-wrapper[noshow] {
      visibility: hidden;
    }
  `;
};
customElements.define('onc-chicken-presenter', OncChickenPresenter);

/**
 * @param {string} string
 */
function snakeToCamel(string) {
  return string.replaceAll(/([_-]\w)/g, g => g[1].toUpperCase());
}

/**
 * @param {Trial[]} trials
 * @returns {Generator<TrialwithIndex>}
 */

function* trialsGenerator(trials) {
  let index = 0;
  const theTrials = trials;

  const length = trials.length;

  while (index < length) {
    yield { index, progress: index / length, ...theTrials[index++] };
  }
}
