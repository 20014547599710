import { ContextConsumer } from '@lit/context';
import { msg } from '@lit/localize';
import { LitElement, css, html } from 'lit';
import { Auth } from '~/app/auth';
import { routerContext } from '~/app/contexts';
import { validate as validateExists } from '~/validators/exists.js';

const pwd_ = {
  text: {
    type: 'text',
    suffix: 'Hide password',
    icon: 'visibility_off',
    title: 'hide password',
  },
  password: { type: 'password', suffix: 'Show password', icon: 'visibility', title: 'show password' },
};

export class CheckPassword extends LitElement {
  static properties = {
    subject: { type: String },
    passwordMessages: { state: true },
    revealPassword: { state: true },
  };
  constructor() {
    super();

    new ContextConsumer(this, { context: routerContext, callback: value => (this.router = value) });

    /** @type {boolean | string[]} */
    this.passwordMessages = [];
    this.subject = '';
    this.password = '';
    this.revealPassword = false;
    this.mismatchCount = 0;
  }

  createRenderRoot() {
    return this;
  }

  render() {
    const pwd = this.revealPassword ? pwd_.text : pwd_.password;

    return html`
      <h1 class="lsi-headline4">${msg('Sign in', { id: 'sign-in-title', desc: 'Sign in title' })}</h1>

      <div class="sign-in--field">
        <md-filled-text-field
          @input=${this.onInput}
          id="password"
          .type=${pwd.type}
          class="sign-in__input"
          name="password"
          autocomplete="current_password"
          @blur=${this.onPasswordBlur}
          @focus=${this.onPasswordFocus}
          @keyup=${this.onEnter}
          .label=${msg('Password')}
        >
          <md-icon slot="trailing-icon" @click=${this.onToggle}>${pwd.icon}</md-icon>
        </md-filled-text-field>

        <onc-form-message .messages=${this.passwordMessages}></onc-form-message>
      </div>

      <div class="sign-in--row">
        <md-filled-button
          data-mdc-dialog-action="accept"
          @click=${this.checkValidationAndSignIn}
          class="onc-button onc-download--action"
          >${msg('Sign in')}</md-filled-button
        >

        <div class="sign-in--row-forgot">
          <a class="sign-in--navlink" @click=${this.onShowForgot}>${msg('Forgot password')}</a>
        </div>
      </div>
    `;
  }

  onShowForgot() {
    this.dispatchEvent(new Event('forgot'));
  }

  onToggle() {
    this.revealPassword = !this.revealPassword;
  }

  onInput({ target: { value } }) {
    this.password = value;
  }

  onEnter({ key, target: { value } }) {
    if (key === 'Enter' && value) {
      this.checkValidationAndSignIn();
    }
  }

  async checkValidationAndSignIn(event) {
    event?.preventDefault();
    if (!this.password) {
      return;
    }

    try {
      await Auth.logIn(this, {
        username: this.subject,
        password: this.password,
      });

      // window.location.pathname = '/';

      // @ts-ignore
      window.__router?.goto('/', { history: true });

      // this.router.goto('/', { history: true });
    } catch (error) {
      const { messages, key } = getMessageForErrorWithMismatch(error, this.mismatchCount);

      if (key.startsWith('password')) {
        this.passwordMessages = messages;
        if (key == 'passwordMismatch') {
          this.mismatchCount += 1;
        }
      } else {
        this.passwordMessages = messages;
      }
    }
  }

  onPasswordBlur({ target: { value } }) {
    const messages = validateExists(value);
    this.passwordMessages = messages;
  }

  onPasswordFocus() {
    this.passwordMessages = [];
  }
}

const OncCheckPassword = class OncCheckPassword extends CheckPassword {
  static styles = css`
    md-filled-text-field {
      --md-sys-color-surface-variant: #f5f5f5;
    }
    .sign-in--row md-filled-button {
      --md-sys-color-primary: var(--onc-signin-color, black);
      width: 50%;

      --md-filled-button-container-shape: 9px;
    }

    .sign-in--field {
      margin-bottom: 16px;
    }

    .sign-in__input {
      padding-top: 16px;
      width: 100%;

      --md-filled-text-field-trailing-icon-color: cornflowerblue;
      .mdc-textfield {
        width: 100%;
      }
    }

    .sign-in--row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1em;
    }

    .sign-in--navlink {
      color: cornflowerblue;
      cursor: pointer;
      text-decoration: none;
      outline: none;
      font-weight: 500;
      font-size: 14px;
      bottom: -5px;
    }

    .sign-in--navlink:hover {
      color: blue; // #0f9d58;
      text-decoration: underline;
    }

    .mdc-typography--headline4 {
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-family: Manrope, Roboto, sans-serif;

      font-size: 2.125rem;
      line-height: 2.5rem;
      font-weight: 400;
      letter-spacing: 0.0073529412em;
      text-decoration: inherit;
      text-transform: inherit;
    }
  `;
};
customElements.define('onc-check-password', OncCheckPassword);

// ===
// Private functions
// ===

const keyMap_ = {
  unknown: () => [msg(`Sorry, it looks like something’s not working right now. Please try again in a few minutes.`)],
  noSuchUser: () => msg(`Sorry, we can’t find an account with that email.`),
  samePassword: () => msg(`Sorry, that's your old password. Please enter a different one.`),
  checkLength: () => [msg(`Sorry, that password is too short. It needs to be eight characters or more.`)],
  passwordMismatch: () => [msg(`That's not the right password for that account.`)],
  passwordMismatchFirst: () => [msg(`Uh oh, that password doesn’t match that account. Please try again.`)],
};

/**
 * @param {Error&{response?:any}} error
 */
function getMessageForErrorWithMismatch(error, mismatchCount = 0) {
  if (error.response) {
    // might want to use key to look up client side error message
    const { payload: { key = 'unknown' /*, message*/ } = {} } = error.response;

    const lookupKey = key == 'passwordMismatch' && mismatchCount == 0 ? 'passwordMismatchFirst' : key;

    const item = (keyMap_[lookupKey] ?? keyMap_['unknown'])();

    return { messages: Array.isArray(item) ? item : [item], key };
  }

  return { messages: keyMap_['unknown'](), key: 'unknown' };
}
