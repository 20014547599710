import { ContextConsumer } from '@lit/context';
import { Task } from '@lit/task';
import { LitElement, html } from 'lit';
import { graphics } from '~/graphics/index.js';
import { memoryTaskContext } from './memory-context.js';

const progressBackground = [
  [0, 68.57, 100, 31.2, 'app:fencepathNarrowLong'],
  [100, 17.06, 29.33, 82.71, 'app:barnWideTall'],
];

const progressForeground = [0, 68.09, 24.61, 31.57, 'app:cowOnTractor'];

const _targetRatio = 16 / 10;

export class MemoryBlockFeedback extends LitElement {
  static properties = {
    showBorder: { type: Boolean },
    items: { state: true },
    layoutRect: { state: true },
    progress: { type: Number },
    lastProgress: { type: Number },
  };

  constructor() {
    super();

    // /** @type {Object[]} */
    this.items = [];
    this.layoutRect = {};
    this.soundController = undefined;
    this.state = 0;
    this.progress = 0;
    this.lastProgress = 0;
    this.showBorder = false;
    this.rewardsComplete = new Promise(resolve => (this.rewardsCompleteResolve = resolve));
    this.durations = {};

    new ContextConsumer(this, {
      context: memoryTaskContext,
      callback: value => (this.taskController = value),
    });

    this.getTrials = new Task(this, {
      task: async () => {
        this.durations = this.taskController.durations;
        this.state = 0;
        this.next();
      },
      args: () => [],
    });
  }

  playSound(key, loop = false) {
    return this.taskController.playSound(key, { loop });
  }

  async next() {
    this.state = 0;

    this.running = true;
    this.tractorSound = this.playSound('tractor', true);
  }

  async done() {
    await this.rewardsComplete;
    this.running = false;
    this.dispatchEvent(new Event('next'));
  }

  render() {
    return html`${this.getTrials.render({
      complete: () => {
        return html`
          <onc-layout-task ?showBorder=${this.showBorder} .targetRatio=${_targetRatio} @layout-rect=${this.onLayout}
            >${this.renderMemoryRewards()}</onc-layout-task
          >
          ${this.renderTaskProgress()}
        `;
      },
    })}`;
  }

  onLayout({ detail: { innerRect } }) {
    this.layoutRect = { width: innerRect.width, height: innerRect.height, left: innerRect.left, top: innerRect.top };
  }

  renderMemoryRewards() {
    const rewardItems = this.taskController.rewards;
    const showLayout = this.taskController.showLayout;
    return html`<onc-memory-rewards
      .rewardItems=${rewardItems}
      ?showLayout=${showLayout}
      @next=${this.onRewards}
    ></onc-memory-rewards>`;
  }

  onRewards() {
    this.rewardsCompleteResolve();
  }

  renderTaskProgress() {
    const bgItems = progressBackground;
    const showLayout = this.taskController.showLayout;
    return html`
      <onc-task-progress
        .bgItems=${bgItems}
        .pgItem=${progressForeground}
        @done=${this.onNext}
        ?showLayout=${showLayout}
        .progress=${this.progress}
        .lastProgress=${this.lastProgress}
        .layoutRect=${this.layoutRect}
      ></onc-task-progress>
    `;
  }

  onNext() {
    this.taskController.stop(this.tractorSound);

    if (this.running) {
      this.done();
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.running = false;
    this.taskController.stop(this.tractorSound);
  }

  async layUIItems(items) {
    const uiItems = [];
    for (const { x, y, w, h, graphic } of items) {
      const [key, value] = graphic.split(':', 2);

      const icon = graphics[key][value];

      uiItems.push({
        top: y / 100,
        left: x / 100,
        width: w,
        height: h,
        icon,
      });
    }

    return uiItems;
  }
}
const OncMemoryBlockFeedback = class OncMemoryBlockFeedback extends MemoryBlockFeedback {};
customElements.define('onc-memory-block-feedback', OncMemoryBlockFeedback);
