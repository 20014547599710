import { html } from 'lit';

export const collie = html`
  <svg version="1.1" viewBox="0 0 355 244" xmlns="http://www.w3.org/2000/svg">
    <g transform="matrix(1.002 0 0 1.001 8.828e-6 .003868)" stroke-width="1.333">
      <path d="m245.7 233.2h16.74c2.81 0 5.089 2.278 5.089 5.089v5.444h-21.83z" fill="#2b2b2b" />
      <path d="m56.29 233.2h16.74c2.81 0 5.089 2.278 5.089 5.089v5.444h-16.38z" fill="#2b2b2b" />
      <path
        d="m62.02 79.2s14.2 12.66 33.44 30.69c11.59 10.85 31.73 26.54 62.68 35.87 29.01 8.754 57.63 17.3 57.63 17.3 0.2426-4e-3 23.1 12.2 25.57 11.81 0.4159-0.0653 0.6318-0.104 0.6318-0.104v68.98h14.4l5.457-74.3c0.1813-0.1306 0.4039-0.2613 0.6505-0.3919 0.6785-0.3666 1.516-0.7292 2.005-1.094 29.73-22.07 30.7-16.74 38.56-35.96 4e-3 -0.0147 8e-3 -0.0267 0.0187-0.0387 0.136-0.3346 0.2706-0.6705 0.4012-1.005 0.012-0.0387 0.0347-0.0813 0.0507-0.124 0.5319-1.348 1.024-2.682 1.484-4.003 11.47-33.07 9.423-56.88 9.423-56.88l7.049 3.672c7.153 3.722 15.69 3.626 22.77-0.2586l1.661-0.9131 2.069-1.141 4.02-2.207 1.062-0.5865c1.5-0.8251 1.789-2.859 0.5785-4.07l-23.01-23.01-3.854-18.12-16.82-17.85h-22.86l-47.15 30.46-27.62 17.84s-0.208 8e-3 -0.6292 0.01999c-2.111 0.05732-9.534 0.2346-22.75 0.2959-0.224 4e-3 -0.4479 4e-3 -0.6785 4e-3 -0.3852 4e-3 -0.7745 4e-3 -1.168 4e-3 -0.5399 4e-3 -1.086 8e-3 -1.642 8e-3 -3.895 0.01066-8.235 0.01466-13.04 4e-3 -0.5359 0-1.076-4e-3 -1.618-4e-3 -1.638-8e-3 -3.326-0.016-5.064-0.02399-0.208 0-0.4199 0-0.6318-0.0027-8.486-0.05065-18.17-0.144-29.09-0.3053-0.7172-8e-3 -1.426-0.01466-2.127-0.01466h-0.4546c-0.3359 0-0.6665 0-0.9984 0.0027-42.07 0.3706-63.29 18.26-68.88 23.82-1.029 1.025-1.53 1.63-1.53 1.63"
        fill="#cecece"
      />
      <path
        d="m288 67.07 7.966 30.31 12.59-2.551 0.0693-0.01466s2.175 13.53-3.512 31.95c11.47-33.07 9.424-56.88 9.424-56.88l7.048 3.672c7.153 3.723 15.69 3.627 22.77-0.2573l1.661-0.9144 2.07-1.14 4.019-2.209-7.31-11.23-13.1-14.01-3.854 6.04-15.61-3.083-1.353-2.957-1.028-5.908-12.58 2.313-3.249 9.423z"
        fill="#cecece"
      />
      <path
        d="m240 35.88c0.0227 10.21 2.898 23.19 15.93 29.1 23.13 10.48 36.6 50.79 36.6 50.79s15.04-5.56 15.98-20.93c0.3119-5.029-0.8864-11.11-4.618-18.38-15.13-29.51 4.239-34.68 4.239-34.68v6.678l19.65 4.306 3.854-4.306c10.53 5.196 14.85 16.96 16.4 22.82l4.02-2.209 1.064-0.5865c1.498-0.8238 1.788-2.859 0.5772-4.068l-23.01-23.02-3.854-18.12-16.82-17.85h-22.86z"
        fill="#2b2b2b"
      />
      <path
        d="m13.37 210.6c8.509-0.0347 27.54-1.318 35.31-6.617-0.016 0.1466-0.03066 0.2973-0.04666 0.4439-2.034 20.05 3.564 39.27 3.564 39.27h14.97l0.03599-56.84c41.86-8.948 53.56-33.54 60-50.48 1.121 1.113 36.08 0.8638 37.66 0.8185 20.74-0.5932 51.55 19.34 66.97 30.39 5.883 4.214 9.528 7.137 9.528 7.137v68.98h15.04l5.457-74.3c0.216-0.116 0.4359-0.2466 0.6518-0.3932 0.4546-0.2999 0.9131-0.6705 1.372-1.094 14.53-13.45 24.22-58.07-11.72-67.18-1.406-0.3546-2.763-0.7518-4.07-1.184-33.3-10.95-36.31-44.88-36.39-45.82-4e-3 -0.01066-4e-3 -0.01866-4e-3 -0.01866s-7.322 0.2386-22.75 0.3159c-0.2226 4e-3 -0.4466 4e-3 -0.6785 4e-3 -0.3852 4e-3 -0.7745 4e-3 -1.168 4e-3 -0.5385 4e-3 -1.086 0.0067-1.641 0.0067-3.896 0.012-8.235 0.016-13.04 4e-3 -0.5345 0-1.074-4e-3 -1.618-4e-3 -1.637-0.0067-3.326-0.01466-5.064-0.02266-0.2079 0-0.4199 0-0.6318-4e-3 -8.637-0.04532-18.53-0.1386-29.71-0.3039-0.4999-8e-3 -1.001-0.012-1.494-0.016h-0.4546c-0.3359 0-0.6678 0-0.9984 4e-3 -50.27 0.2239-71.05 25.45-71.05 25.45-13.81 14.52-20.1 30.85-22.65 45.4h-0.0053l-38.75 85.11s5.755 0.9784 13.38 0.9478"
        fill="#2b2b2b"
      />
      <path d="m308.2 0.0237-17.06 2.231 10.6 14.68z" fill="#353535" />
      <path
        d="m303 132c0.1426-0.3466 0.2813-0.6932 0.4159-1.041-0.1533 0.3586-0.2959 0.7052-0.4159 1.041"
        fill="#cecece"
      />
      <path d="m326.6 48.41s0.6425-10.02-3.854-16.45l-10.66 11.3z" fill="#cecece" />
      <path d="m323.2 37.88 0.7705 5.909-6.68-2.441z" fill="#2b2b2b" />
      <path
        d="m0.61 209.7s33.94 4.378 48.69-5.669c3.114-2.119 5.739-4.883 7.53-8.462 10.28-20.55-0.9918-61.1-0.9918-61.1l-16.48-9.878h-4e-3z"
        fill="#1e1e1e"
      />
      <path
        d="m48.69 204.4c-2.05 20.05 3.56 39.3 3.56 39.3h14.97l-0.01333-56.84c41.83-8.94 53.55-33.52 60-50.48 8e-3 -0.0107 16.42-26.93 4.507-46.87h-4e-3c-3.487-5.845-8.601-10.97-16-14.46-26.66-12.59-45.98-1.869-52.16 2.482-1.388 0.9758-2.111 1.63-2.111 1.63-13.81 14.52-20.1 30.85-22.65 45.4h-4e-3c-0.1146 0.6678-0.2226 1.334-0.3279 1.993-3.383 22.13 1.72 39.7 1.72 39.7s14.02 10.41 9.404 32.37c-0.3786 1.792-0.6558 3.587-0.8518 5.38-0.016 0.1346-0.03066 0.2733-0.04266 0.4079"
        fill="#3a3a3a"
      />
    </g>
  </svg>
`;
