import { FarmGameSettings } from '@tasks/farm-game-settings.js';
import { ChickenDemo } from './chicken-demo.js';
import { ChickenDurations } from './chicken-durations.js';
import { ChickenPhaseSetting } from './chicken-phase-setting.js';

/**
 * @typedef { import('./record-types.js').ChickenTaskRecord}  ChickenTaskRecord
 */

export class ChickenSettings extends FarmGameSettings {
  /**
   *
   * @param {ChickenTaskRecord} taskRecord
   */
  constructor(taskRecord) {
    super(taskRecord);

    this.durations_ = new ChickenDurations(taskRecord.model.durations);

    this.phases_ = taskRecord.model.phases.map(phase => new ChickenPhaseSetting(phase));
    this.demo_ = new ChickenDemo(taskRecord.model.demo);

    this.stimuliSet_ = taskRecord.model.stimuliSet;

    this.phaseIndex = 0;
  }

  get durations() {
    return this.durations_;
  }

  get phases() {
    return this.phases_;
  }

  get demo() {
    return this.demo_;
  }

  get currentPhase() {
    return this.phases_[this.phaseIndex];
  }

  get stimuliSet() {
    return this.stimuliSet_;
  }
}
