import { ContextConsumer, ContextProvider } from '@lit/context';
import { api } from '@state/api';
import { Profile } from '@state/project/profile';
import { profileContext, projectContext, storeContext, userContext } from './contexts';

/**
 * @typedef {import('lit').ReactiveControllerHost} ReactiveControllerHost
 * @typedef {import('lit').ReactiveElement} ReactiveElement
 */

export class ProfileController {
  /**
   *
   * @param {ReactiveControllerHost & ReactiveElement} host
   */
  constructor(host) {
    this.provide(host, profileContext, 'profile');

    new ContextConsumer(host, {
      context: userContext,
      callback: value => this.getProfile(value),
      subscribe: true,
    });

    new ContextConsumer(host, {
      context: projectContext,
      callback: value => this.setCurrentProfile(value),
      subscribe: true,
    });

    new ContextConsumer(host, {
      context: storeContext,
      callback: ({ dataStore }) => (this.dataStore = dataStore),
    });

    (this.host = host).addController(this);
  }

  getProfile(user) {
    this.fetchProfile(user);
  }

  setCurrentProfile(project) {
    if (!project || !this.profiles) {
      return;
    }
    // console.log('set current profile', project.name);
    const profileRecord = this.profiles.get(project.projectId);

    if (!profileRecord.tasks) {
      profileRecord.tasks = {};
    }

    // ensure we have a task profile for every possible task in the project
    for (const {
      schemaId,
      _id: taskId,
      model: { name },
    } of project.projectTasks.values()) {
      if (!profileRecord.tasks[taskId]) {
        const taskSchemaType = schemaId.replace(/-schema$/, '');
        profileRecord.tasks[taskId] = { type: taskSchemaType, name };
      }
    }
    const projectProfile = new Profile(profileRecord);

    this.profile = projectProfile;
  }

  async fetchProfile(user, activeOnly = true) {
    if (!user?.loggedIn) {
      return;
    }

    try {
      // @ts-ignore
      const { projects: profileRecords } = await api.get(`/api/profile${activeOnly ? '?activeOnly' : ''}`).json();

      const projectProfiles = new Map();

      for (const profileRecord of profileRecords) {
        projectProfiles.set(profileRecord.projectId, profileRecord);
      }

      this.profiles = projectProfiles;

      // console.log(this.profiles);
    } catch (error) {
      console.dir(error);
    }
  }

  hostDisconnected() {}

  provide(host, context, name) {
    const provider = new ContextProvider(host, { context });

    let storage;

    Object.defineProperty(this, name, {
      get: function () {
        return storage;
      },
      set: function (value) {
        provider.setValue(value);
        storage = value;
      },
    });
  }
}
