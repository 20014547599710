import { html } from 'lit';

export const hutchOpen = html`
  <svg version="1.1" viewBox="0 0 610 1e3" xmlns="http://www.w3.org/2000/svg">
    <path d="m504 775.4h-398.6l-57.62-514 255-212.6 259 212.6-57.75 514z" fill="#ff9700" />
    <path
      d="m562.2 285.8-217.4-169.8-287.2 229.9-7.8-69.9 270-213.6 258.1 206.1-15.75 17.25z"
      fill="#683d28"
      opacity=".5"
    />
    <path
      d="m422.8 369.9c0 66.22-53.48 119.5-119.4 119.5-66.2 0-119.7-53.25-119.7-119.5 0-65.95 53.5-119.4 119.7-119.4 65.96 0 119.4 53.5 119.4 119.4"
      fill="#1e1d1b"
    />
    <path
      d="m334 553c0 17.4-13.99 31.38-31.21 31.38-17.4 0-31.21-13.98-31.21-31.38 0-17.22 13.81-31.21 31.21-31.21 17.22 0 31.21 13.99 31.21 31.21"
      fill="#ffb655"
    />
    <g fill="#c14e00">
      <path d="m126.5 684.4-35.6-35.59 35.6-35.6 35.59 35.6z" opacity=".8" />
      <path d="m197.7 684.4-35.44-35.59 35.44-35.6 35.6 35.6z" opacity=".8" />
      <path d="m268.4 684.4-35.59-35.59 35.59-35.6 35.6 35.6z" opacity=".8" />
      <path d="m339.7 684.4-35.6-35.59 35.6-35.6 35.59 35.6z" opacity=".8" />
      <path d="m410.9 684.4-35.44-35.59 35.44-35.6 35.6 35.6z" opacity=".8" />
      <path d="m482.2 684.4-35.6-35.59 35.6-35.6 35.59 35.6z" opacity=".8" />
    </g>
    <path d="m574 305.5-270.6-211.1-266.8 211-35.75-45.24 302.2-239.2 306.5 239.1-35.62 45.5z" fill="#683d28" />
    <path d="m422.8 775.4h-239.1v-405.5h239.1v405.5z" fill="#1e1d1b" />
    <path
      d="m302.8 980.4c-66.2 0-119.7-20.89-119.7-46.64v-158.4h239.1v158.4c0 25.7-53.4 46.6-119.4 46.6"
      fill="#ce7305"
    />
    <path
      d="m302.8 947.9c-66.2 0-119.7-17.58-119.7-39.25v-133.2h239.1v133.2c0 21.7-53.4 39.3-119.4 39.3"
      fill="#ef8800"
    />
    <path d="m303.4 20.38v754.6h201.2l54.12-481.8 15.28 11.87 35.59-45.52-306.2-239.2z" fill="#ff9700" opacity=".5" />
  </svg>
`;
