import { fixupDurationValues } from '@state/project/game-task';

const fields_ = [
  ['stimulus.go', 'ms'],
  ['stimulus.nogo', 'ms'],
  ['fixation.min', 'ms'],
  ['fixation.max', 'ms'],
  ['feedback', 'ms'],
];

/**
 * @typedef { import('./record-types.js').DurationsRecord}  DurationsRecord
 *
 * @typedef {{stimulus: {go:Number, nogo:Number}, fixation: {min: Number, max: Number}, feedback: Number}} SheepDurationsSettings
 */

export class SheepDurations {
  /**
   *
   * @param {DurationsRecord} durationsRecord
   */
  constructor(durationsRecord) {
    const durations = /** @type {SheepDurationsSettings } */ (fixupDurationValues({ ...durationsRecord }, fields_));

    this.stimulus = durations.stimulus;
    this.fixation = durations.fixation;
    this.feedback = durations.feedback;
  }
}
