export default {};

export const rewardLocations = [
  [25, 54.15, 4.68, 7.49],
  [55.41, 14.45, 4.68, 7.49],
  [8.35, 24.11, 4.68, 7.49],
  [64.72, 53.39, 4.68, 7.49],
  [20.52, 9.37, 4.68, 7.49],
  [29.69, 21.75, 4.68, 7.49],
  [48.62, 8.34, 4.68, 7.49],
  [3.89, 9.33, 4.68, 7.49],
  [41.27, 26.15, 4.68, 7.49],
  [61.18, 24.62, 4.68, 7.49],
  [31.15, 5.78, 4.68, 7.49],
  [1.65, 32.36, 4.68, 7.49],
  [11.89, 58.2, 4.68, 7.49],
  [51.54, 27.3, 4.68, 7.49],
  [17.9, 26.15, 4.68, 7.49],
  [10.22, 38.66, 4.68, 7.49],
  [42.58, 55.35, 4.68, 7.49],
  [47.07, 37.9, 4.68, 7.49],
  [25.27, 35.26, 4.68, 7.49],
  [64.76, 7, 4.68, 7.49],
  [1.54, 60.4, 4.68, 7.49],
  [33.38, 48.38, 4.68, 7.49],
  [53.1, 46.04, 4.68, 7.49],
  [36.5, 36.46, 4.68, 7.49],
  [18.29, 44.51, 4.68, 7.49],
  [56.17, 58.67, 4.68, 7.49],
  [4.49, 47.9, 4.68, 7.49],
  [11.89, 5.79, 4.68, 7.49],
  [63.14, 38.92, 4.68, 7.49],
  [39.11, 14.5, 4.68, 7.49],
];
