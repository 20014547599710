import { html } from 'lit';

export const c2 = html`
  <svg
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    viewBox="0 0 620 670"
  >
    <path
      d="m547.3 464c0 87.2-106.3 158.6-237.4 158.6-131 0-237.3-71.4-237.3-158.6 0-87.19 106.3-157.9 237.4-157.9s237.4 70.69 237.4 157.9"
      fill="#b3b3b3"
    />
    <g fill="#999">
      <path
        d="m237.2 140.3c8.156-3.126 15.88-2.404 22.88-0.4825 6.99 2.044 13.31 5.736 18.58 10.61 5.274 4.855 9.452 10.96 12.01 17.84 2.442 6.939 3.461 14.61 0.9325 22.9-8.072-3.109-13.66-6.329-18.79-9.599-5.048-3.27-9.376-6.63-13.4-10.4-4.039-3.751-7.766-7.895-11.42-12.75-3.649-4.938-7.108-10.38-10.79-18.11"
      />
      <path
        d="m309.3 90.26c7.692 5.781 12.46 12.64 16.26 19.76 3.675 7.171 6.092 14.74 7.322 22.6 1.201 7.846 1.188 16-0.4825 24.22-1.806 8.101-5.026 16.86-12.48 23.2-4.2-8.531-7.305-15.1-9.768-22.14-2.408-6.934-4.185-13.88-5.261-21.02-1.065-7.151-1.481-14.5-1.012-22.16 0.5638-7.7 1.792-15.61 5.419-24.46"
      />
      <path
        d="m339 107.8c6.11 6.752 9.142 13.91 11.15 21.1 1.89 7.214 2.459 14.54 1.75 21.85-0.6975 7.325-2.692 14.68-6.412 21.68-3.77 6.725-9.38 14.16-18.42 16.75-1.09-8.86-2.704-15.02-3.278-21.72-0.635-6.542-0.7125-12.96-0.0563-19.45 0.6475-6.488 1.978-13.02 4.241-19.69 2.374-6.675 5.426-13.4 11.03-20.51"
      />
    </g>
    <g fill="#3c3c3b">
      <path
        d="m81.36 312.8c10.06-5.778 21.74-8.356 33.96-8.53 6.102 0.1325 12.31 1.02 18.4 2.766 6.036 1.845 11.89 4.534 17.32 7.895 5.425 3.368 10.8 7 15.65 11.25 4.839 4.262 8.562 9.516 10.98 15.24 4.709 11.54 6.365 23.96 2.246 36.82l-2.7 0.86c-10.74-7.965-17.42-15.95-23.9-23.26-3.158-3.668-5.694-7.44-7.964-11.51-2.212-4.101-4.745-8.132-8.039-11.38-3.245-3.298-6.899-6.198-10.95-8.6-3.979-2.536-8.398-4.576-13.12-6.086-9.425-3.275-20.2-4.058-30.86-2.814l-1.025-2.649z"
      />
      <path
        d="m78.48 271.5c6.77-5.164 14.41-9.012 22.78-11.51 8.335-2.502 17.52-3.536 26.82-2.118 9.246 1.495 18.28 5.589 25.82 11.35 7.532 5.828 13.59 13.08 18.6 20.66 5.051 7.95 9.044 14.75 13.39 22.24 4.098 7.42 7.291 15.32 9.281 23.74 1.946 8.384 3.19 17.1 2.939 26.09-0.3188 8.872-1.181 18.35-7.008 26.74l-2.676-0.9225c-5.785-15.31-13.66-28.56-20.06-42.78-3.256-7.05-6.044-14.31-8.294-21.92-2.186-7.434-4.471-15.74-7.315-22.08-5.988-13.11-14.94-24.4-28.18-29.39-13.16-5.34-29.85-3.496-44.62 2.318l-1.488-2.418z"
      />
      <path
        d="m152 249c7.136-3.074 15.28-3.361 23.4-1.572 4.002 0.9925 7.968 2.536 11.72 4.549 3.664 2.116 7.098 4.674 10.21 7.524 3.124 2.858 6.355 5.709 9.232 8.874 2.834 3.192 4.671 6.972 5.372 11.02 0.6925 4.024 0.6962 8.181-0.275 12.35-0.8738 4.15-2.495 8.321-5.806 12.52l-2.788 0.5375c-4.632-2.591-7.818-5.155-10.57-7.67-2.834-2.481-5-4.926-6.865-7.309-1.859-2.389-2.951-4.884-3.496-7.741-0.5175-2.858-1.098-5.805-2.359-8.306-1.236-2.515-2.704-4.911-4.429-7.119-1.645-2.314-3.599-4.465-5.869-6.372-4.444-3.954-10.39-7.014-17-8.51l-0.4625-2.762z"
      />
      <path
        d="m306.2 465.2c-1.754-0.035-3.449-0.175-5.135-0.3625-110.6-3.508-170-71.05-170-71.05-16.25 38.41-16.38 81.62-16.38 81.62 2.625 82.95 89 149.5 195.2 149.5v-160c-0.8088 0.0962-1.579 0.25-2.401 0.25h-1.349z"
      />
    </g>
    <path
      d="m175.3 272.8-0.0625-0.0625s0.005 0.3075 0.005 0.7962c-0.28 2.844-0.5388 5.701-0.5388 8.608 0 0.8862 0.115 1.752 0.1338 2.641-0.7612 10.7-2.786 27.4-8.714 45.02-4.09 12.3-10.16 25.1-19.15 36.6-6.534 8.384-11.7 17.89-15.84 27.7 0 0 59.4 67.55 170 71.05h0.0275c1.664 0.1875 3.364 0.3275 5.109 0.3625h1.348c0.8238 0 1.594-0.1538 2.425-0.195v-289.3c-70.66-0.05-128.6 42.5-134.6 96.75"
      fill="#b3b3b3"
    />
    <path
      d="m489.6 396.5s-63.35 69.54-179.6 69.54v160c106.4 0 192.8-66.72 195.2-149.8 0 0-0.0537-41.82-15.55-79.76"
      fill="#575756"
    />
    <path
      d="m473.1 366.5c-8.21-10.57-14.02-22.25-18.14-33.65-7.049-19.48-9.185-38.19-9.866-49.38 0-0.4325 0.0575-0.8525 0.0575-1.285 0-1.936-0.24-3.829-0.3638-5.736-0.0537-2.285-0.0187-3.668-0.0187-3.668l-0.205 0.2438c-5.94-54.34-63.89-96.99-134.6-96.99v289.3c116.3 0 179.6-69.54 179.6-69.54-4.258-10.34-9.611-20.46-16.5-29.34"
      fill="#ccc"
    />
    <g fill="#999">
      <path
        d="m358.7 369v-0.08c0-29.96-27.11-54.28-49.46-54.28s-49.46 24.31-49.46 54.28c0 12-0.9275 43.52 21.75 43.52 6.681 0 12.59-3.081 16.58-7.831 3.748 0.3988 7.52 0.595 11.14 0.595 3.64 0 7.4-0.1962 11.16-0.595 3.972 4.75 9.886 7.831 16.58 7.831 22.61 0 21.72-31.39 21.72-43.45"
      />
      <path
        d="m266.5 236.5c-26.09 0-47.24 20.1-47.24 44.88 0 24.8 21.15 44.89 47.24 44.89 19.5 0 36.24 2.844 43.46-13.12v-49.36c-7.3-16.1-24-27.3-43.5-27.3"
      />
      <path
        d="m353.6 326.5c26.09 0 47.25-20.09 47.25-44.89 0-24.79-21.16-44.88-47.25-44.88-19.56 0-36.34 11.29-43.52 27.38-0.015-0.0288-0.0425-0.07-0.0563-0.105v49.36c0.0138-0.0412 0.0413-0.0687 0.0563-0.1112 7.188 16.1 23.96 13.24 43.52 13.24"
      />
    </g>
    <path
      d="m301.7 315.2-11.44 7.545c-4.601 3.04-6.054 9.398-3.231 14.14l17.91 30.06c1.415 2.382 3.276 3.57 5.135 3.57v-57.61c-3.049 0.003-6.064 0.7638-8.372 2.288"
      fill="#f39e2b"
    />
    <path
      d="m315.2 367.8 17.91-30.08c2.83-4.744 1.37-11.1-3.235-14.14l-11.44-7.545c-2.306-1.524-5.339-2.285-8.37-2.285v57.61c1.859 0 3.72-1.188 5.131-3.57"
      fill="#fbdd58"
    />
    <path
      d="m274.9 281.5c0 8.461-6.868 15.31-15.31 15.31-8.46 0-15.31-6.854-15.31-15.31 0-8.446 6.854-15.3 15.31-15.3 8.445 0 15.31 6.854 15.31 15.3"
      fill="#4e1412"
    />
    <path
      d="m374.5 281.5c0 8.461-6.864 15.31-15.31 15.31-8.45 0-15.31-6.854-15.31-15.31 0-8.446 6.86-15.3 15.31-15.3 8.449 0 15.31 6.854 15.31 15.3"
      fill="#4e1412"
    />
    <path
      d="m270.3 275.2c0 2.892-2.348 5.24-5.232 5.24-2.892 0-5.234-2.348-5.234-5.24s2.341-5.232 5.234-5.232c2.885 0 5.232 2.34 5.232 5.232"
      fill="#fff"
    />
    <path
      d="m369.6 275.2c0 2.892-2.338 5.24-5.23 5.24-2.891 0-5.232-2.348-5.232-5.24s2.341-5.232 5.232-5.232c2.892 0 5.23 2.34 5.23 5.232"
      fill="#fff"
    />
    <path
      d="m239.7 75.26c13.92 7.646 24.54 16.59 34.26 24.15 2.379 1.886 4.738 3.588 6.756 5.006 1.076 0.7612 1.96 1.118 2.931 1.778l3.934 2.614c5.669 3.835 12.23 9.39 17.55 16.1 10.96 13.54 15.95 30.45 16.19 45.69 0.1688 15.42-3.409 30.08-12.09 43.55-8.991-13.24-14.65-25.41-20.45-36.08-5.68-10.77-10.96-19.31-16.82-26.69-3.004-3.804-6.284-7.381-10.74-12.25l-3.612-4.004c-1.502-1.596-3.015-3.578-4.195-5.279-2.526-3.479-4.395-6.982-6.036-10.44-6.235-13.9-9.184-28.14-7.681-44.14"
      fill="#808080"
    />
    <g stroke-linecap="round" stroke-miterlimit="10" fill="none">
      <path
        stroke="#000"
        stroke-width="11.25"
        d="m394.3 281.5c0 19.45-15.78 35.22-35.22 35.22-19.45 0-35.22-15.78-35.22-35.22 0-19.45 15.78-35.22 35.22-35.22 19.45 0 35.22 15.78 35.22 35.22z"
      />
      <path
        stroke="#999"
        stroke-width="8.403"
        d="m400.5 344c10.28 10.28 10.28 26.94 0 37.21-10.28 10.28-26.94 10.28-37.21 0"
      />
      <path
        stroke="#999"
        stroke-width="8.403"
        d="m254.9 380.2c-10.28 10.28-26.94 10.28-37.21 0-10.28-10.28-10.28-26.94 0-37.21"
      />
      <path stroke="#000" stroke-width="6.436" d="m295.1 286.5c7.915-7.825 20.68-7.751 28.5 0.1638" />
      <path
        stroke="#000"
        stroke-width="11.25"
        d="m295.1 281.5c0 19.45-15.78 35.22-35.22 35.22-19.45 0-35.22-15.78-35.22-35.22 0-19.45 15.78-35.22 35.22-35.22 19.45 0 35.22 15.78 35.22 35.22z"
      />
    </g>
  </svg>
`;
