function* nums(max) {
  let index = 1;
  while (index <= max) {
    yield index++;
  }
}

const defaultSheepStimuliSet = [...nums(34)].filter(n => ![31, 10, 22, 23].includes(n));
const defaultChickenStimuliSet = [...nums(32)].filter(n => ![9, 32].includes(n));

export const rewardLocations = {
  chicken: [
    [17.47, 39.41, 3.26, 6.9],
    [49.6, 56.53, 3.26, 6.9],
    [57.68, 35.9, 3.26, 6.9],
    [1.13, 59.03, 3.26, 6.9],
    [30.6, 51.44, 3.26, 6.9],
    [62.22, 30.75, 3.26, 6.9],
    [4.88, 50.83, 3.26, 6.9],
    [23.71, 58.04, 3.26, 6.9],
    [43.74, 37.82, 3.26, 6.9],
    [63.71, 53.59, 3.26, 6.9],
    [29.72, 41.56, 3.26, 6.9],
    [17.35, 63.33, 3.26, 6.9],
    [35.12, 57.92, 3.26, 6.9],
    [48.34, 47.29, 3.26, 6.9],
    [56.9, 57.71, 3.26, 6.9],
  ],

  sheep: [
    [48.96, 32.73, 7.27, 12.87],
    [9.6, 40.92, 7.27, 12.87],
    [34.03, 36.46, 7.27, 12.87],
    [66.44, 30.69, 7.27, 12.87],
    [38.67, 45.49, 7.27, 12.87],
    [13.61, 49.47, 7.27, 12.87],
    [54.19, 43.23, 7.27, 12.87],
    [48.52, 64.8, 7.27, 12.87],
    [0.82, 38.22, 7.27, 12.87],
    [6.83, 56.2, 7.27, 12.87],
    [39.53, 59.56, 7.27, 12.87],
    [64.58, 41.24, 7.27, 12.87],
    [27.57, 64.11, 7.27, 12.87],
    [21.36, 43.45, 7.27, 12.87],
    [61.55, 60.32, 7.27, 12.87],
  ],
};

export default {
  defaultSheepStimuliSet,
  defaultChickenStimuliSet,
};
