import { animate } from '@lit-labs/motion';
import { ContextConsumer } from '@lit/context';
import { Task } from '@lit/task';
import { ElapsedTimer, shuffle } from '@ounce/onc';
import gameDelay from 'delay';
import { LitElement, css, html, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { createRef, ref } from 'lit/directives/ref.js';
import { styleMap } from 'lit/directives/style-map.js';
import { when } from 'lit/directives/when.js';
import { graphics } from '~/graphics/index.js';
import { sheepTaskContext } from './sheep-context.js';

/**
 * @typedef {import('./sheep-types.js').SheepBlock} SheepBlock
 * @typedef {import('./sheep-types.js').Trial} Trial
 * @typedef {import('./sheep-types.js').TrialwithIndex} TrialwithIndex
 * @typedef {import('./sheep-types.js').TrialDuration} TrialDuration
 * @typedef {{top: number, width:number, height: number, left: number, icon: string, id: string}} Item
 * @typedef {import('./specs/sheep-phase-setting.js').SheepPhaseSetting} SheepPhaseSetting
 * @typedef {import('./sheep-types.js').Adaptation} Adaptation
 * @typedef {import('./sheep-types.js').GoAdaptation} GoAdaptation
 * @typedef {import('./sheep-types.js').NoGoAdaptation} NoGoAdaptation
 */

const locations = [
  { left: 5.93, top: 61.16, width: 16.54, height: 29.28 },
  { left: 37.75, top: 63.86, width: 12.19, height: 21.59, duration: 1000, easing: 'ease-out' },
  { left: 44.27, top: 35.29, width: 9.7, height: 17.18, duration: 600, easing: 'ease-out' },
  { left: 51.96, top: 43.11, width: 8.63, height: 15.28, duration: 700, easing: 'ease-out' },
  { left: 83.36, top: 41.2, width: 5.22, height: 9.24, duration: 1000, easing: 'ease-in-out' },
];

const background = [
  [24.86, 49.82, 74.99, 32.55, 'sheep:longFence2'],
  [12.98, 14.41, 21.24, 32.73, 'app:tree'],
  // [0.15, 0.05, 4, 'chicken:c31'],
];

export class SheepPresenter extends LitElement {
  static properties = {
    block: { type: Object },
    stimulusDuration: { type: Object },
    phaseSettings: { type: Object },
    item: { state: true },
    stimulusLocation: { state: true },
    stimulusClass: { state: true },
    fingerStyles: { state: true },
    fingerShow: { state: true },
    trialAdaptation: { type: Object },
  };

  constructor() {
    super();

    this.state = 0;
    this.stimuli = undefined;
    this.trialsIt = undefined;
    this.trial = undefined;
    this.durations = {};
    this.stimulusDuration = {};
    this.stimulusLocation = {};
    this.sheepDuration = 2000;
    this.nogoCount = 0;
    this.trialAdaptation = undefined;

    this.targetRef = createRef();

    this.backgroundItems = this.getBGItems(background);

    this.stimulusClass = { 'sheep-card': true };
    /**
     * @type {Item}
     */
    this.item = undefined;

    /** @type {SheepBlock} */
    this.block = undefined;

    /** @type {SheepPhaseSetting} */
    this.phaseSettings = undefined;
    this.running = false;

    this.trialResponses = [];

    this.blockTrials = [];
    this.progressValue = 0;
    this.fingerStyles = {};
    this.durations = {};

    this.responseTimer = new ElapsedTimer();
    this.maxNoResponseExceeded = false;
    this.noResponseCount = 0;
    this.sheepState = 0;

    new ContextConsumer(this, {
      context: sheepTaskContext,

      callback: value => (this.taskController = value),
    });

    this.fingerShow = false;

    this.getTrials = new Task(this, {
      /**
       * @param {[SheepBlock, SheepPhaseSetting]} param0
       */
      task: ([block, phaseSettings]) => {
        const { type: phaseType, maxNoResponseTrials = 1 } = phaseSettings;
        this.phaseType = phaseType;
        this.stimuli = this.taskController.getStimuli();
        this.durations = this.taskController.durations;
        this.showLayout = this.taskController.showLayout;
        this.maxNoResponseTrials = maxNoResponseTrials;

        this.currentDuration = this.stimulusDuration;

        this.adaptation = block.adaptation;
        this.nogoStimulus = 'sheep:pig';
        this.goCueStimulus = 'sheep:s22';

        this.blockTrials = [];
        this.trialsIt = this.trialsGenerator(this.makeTrials(block));
        this.state = 0;

        this.abortController = new AbortController();

        this.fingerStyles.top = '93.04%';
        this.fingerStyles.left = '8.07%';
        this.fingerStyles.width = '11.48%';
        this.fingerStyles.height = '23.32%';

        this.fingerStyles['--tap-display'] = 'none';

        this.next().catch(error => {
          if (error?.name !== 'AbortError') {
            console.log(error);
          } else if (this.running) {
            this.done();
          }
        });
      },
      args: () => [this.block, this.phaseSettings],
    });
  }

  /**
   * @param {number} milliseconds
   */
  delay(milliseconds) {
    return gameDelay(milliseconds, { signal: this.abortController.signal });
  }

  /**
   * @param {Trial[]} trials
   * @returns {Generator<TrialwithIndex>}
   */

  *trialsGenerator(trials) {
    let index = 0;
    const theTrials = trials;

    const length = trials.length;

    while (index < length) {
      yield { index, progress: index / length, ...theTrials[index++] };
    }
  }

  /**
   * @param {string} key
   */
  playSound(key) {
    return this.taskController.playSound(key);
  }

  nextStimulus() {
    const { value } = this.stimuli.next();
    return value;
  }

  async next() {
    this.state = 0;
    this.running = true;

    const { type } = this.block;

    let cueKey;
    if (this.phaseType === 'animation') {
      cueKey = 'animation mixed block cue';
    } else {
      if (this.phaseType === 'practice') {
        await this.playSound('demo block cue 1');
      }
      cueKey = type === 'go' ? 'go block cue' : 'mixed block cue';
    }

    await this.playSound(cueKey);

    for (const trial of this.trialsIt) {
      this.trial = trial;

      this.updateProgressValue(trial.progress);

      this.blockTrials.push(trial);
      this.stimulusClass = { 'sheep-card': true };

      const { trialData, maxNoResponseExceeded } = await this.runTrial(this.trial);

      this.fingerShow = false;

      this.maxNoResponseExceeded = maxNoResponseExceeded;

      this.item = undefined;

      this.trialResponses.push(trialData);

      await this.taskController.pushItem(trialData);

      if (maxNoResponseExceeded || this.abortController.signal.aborted) {
        break;
      }

      if (this.phaseSettings.type === 'adaptive' && trial.type === 'nogo') {
        this.currentDuration =
          this.trialAdaptation?.adaptDuration(trialData.isCorrect, this.currentDuration) ?? this.currentDuration;
      }
    }

    this.done();
  }

  updateProgressValue(progress) {
    this.progressValue = progress;
    this.dispatchEvent(new Event('progress'));
  }

  /**
   * @param {TrialwithIndex} trial
   */
  async handleNoResponse(trial) {
    this.item = undefined;
    const elapsed = this.responseTimer.elapsed();

    // trial.response.push([undefined, this.responseTimer.elapsed()]);

    if (trial.type === 'go') {
      this.noResponseCount += 1;

      trial.response = {
        trial: trial.index,
        type: trial.type,
        stimulusId: getStimulusId(trial.stimulusId),
        elapsed,

        noResponse: true,
        duration: {
          fixation: trial.fixationDuration,
          stimulus: this.currentDuration[trial.type],
        },
      };
      await this.playSound(`no response go`);

      if (this.noResponseCount >= this.maxNoResponseTrials) {
        return { maxNoResponseExceeded: true, trialData: trial.response };
      }
    } else {
      trial.response = {
        trial: trial.index,
        type: trial.type,
        stimulusId: getStimulusId(trial.stimulusId),
        elapsed,
        isCorrect: true,
        predicted: trial.predicted,
        duration: {
          fixation: trial.fixationDuration,
          stimulus: this.currentDuration[trial.type],
        },
      };
      await this.playSound(`good feedback nogo`);
    }

    return { trialData: trial.response };
  }

  /**
   * @param {TrialwithIndex} trial
   */
  async runTrial(trial) {
    const audioPromise = this.phaseType === 'animation' ? this.playSound('demo mixed fixation cue') : undefined;

    await Promise.all([this.delay(trial.fixationDuration), audioPromise]);

    this.item = this.makeStimulusItem(trial);
    const { top, left, width, height } = this.item;

    this.sheepState = 0;

    const pad = '1.5';
    this.stimulusLocation = {
      top: `${top * 100 - Number(pad)}%`,
      left: `${left * 100 - Number(pad)}%`,
      width: `${width}%`,
      height: `${height}%`,
      padding: `${pad}%`,
    };

    this.sheepDuration = 2000;

    this.state = 1;

    const runPromise = new Promise(resolve => {
      this.runResolve = resolve;
    });

    if (this.phaseType === 'animation') {
      await (trial.type === 'go' ? this.playSound('demo sheep stimulus') : this.playSound('demo nogo stimulus'));

      await this.updateComplete;

      requestAnimationFrame(() => this.cellChanged(trial.type));
    }

    if (this.trial.type === 'nogo' || this.phaseType !== 'animation') {
      this.responseTimer.start();
      this.noResponseTimer = setTimeout(async () => {
        const response = await this.handleNoResponse(trial);
        this.runResolve(response);
      }, this.currentDuration[trial.type]);
    }

    return runPromise;
  }

  async handleClick() {
    if (this.phaseType !== 'animation') {
      return this.handleClickAction();
    }
  }

  async handleClickAction() {
    const elapsed = this.responseTimer.elapsed(); // - this.onsetTime;
    this.noResponseCount = 0;
    const trial = this.trial;

    clearTimeout(this.noResponseTimer);

    if (trial.type === 'nogo') {
      this.trial.response = {
        trial: trial.index,
        type: trial.type,
        stimulusId: getStimulusId(trial.stimulusId),
        elapsed,
        isCorrect: false,
        predicted: trial.predicted,
        duration: {
          fixation: trial.fixationDuration,
          stimulus: this.currentDuration[trial.type],
        },
      };

      const mode = this.taskController.mode;
      if (mode !== 'play') {
        await this.playSound('bad feedback nogo demo');
      }

      this.runResolve({ trialData: this.trial.response });

      return;
    }

    this.stimulusClass = { 'sheep-card': true, 'sheep-flipped': 1 };

    await this.delay(1000);
    const animatePromise = new Promise(resolve => (this.animateResolve = resolve));
    // this.item = this.setItemLocation(this.item, 1);

    const { top, left, width, height, duration = 1000, easing = 'linear' } = locations[1];

    const pad = '1.5';
    this.stimulusLocation = {
      top: `${top - Number(pad)}%`,
      left: `${left - Number(pad)}%`,
      width: `${width}%`,
      height: `${height}%`,
      padding: `${pad}%`,
    };

    this.sheepDuration = duration;
    this.sheepEasing = easing;
    this.trial.response = {
      trial: trial.index,
      type: trial.type,
      stimulusId: getStimulusId(trial.stimulusId),
      elapsed,
      isCorrect: true,
      duration: {
        fixation: trial.fixationDuration,
        stimulus: this.currentDuration[trial.type],
      },
    };

    const soundPromise = this.playSound(`sheep sound`);

    const feedbackPromise = this.delay(this.durations.feedback);

    await Promise.all([soundPromise, feedbackPromise, animatePromise]);

    this.runResolve({ trialData: this.trial.response });
  }

  onComplete() {
    this.fingerStyles = { ...this.fingerStyles, ['--tap-display']: 'block' };

    setTimeout(() => {
      this.handleClickAction();

      this.fingerShow = false;
      this.fingerStyles = { ...this.fingerStyles, top: '93.04%', left: '8.07%', ['--tap-display']: 'none' };
    }, 500);
  }

  /**
   * @param {'go'| 'nogo'} trialType
   */
  cellChanged(trialType) {
    const element = /** @type {HTMLElement} */ (this.targetRef.value);

    this.fingerShow = true;

    if (trialType === 'nogo') {
      return;
    }

    const cellElement = element;
    const offsetParent = cellElement.offsetParent;

    const parentRect = offsetParent.getBoundingClientRect();
    const cellRect = cellElement.getBoundingClientRect();

    const relativeTop = cellRect.top - parentRect.top;
    const relativeLeft = cellRect.left - parentRect.left;

    const fw = (parentRect.width * 11.48) / 100;
    const fh = (parentRect.height * 23.32) / 100;

    const top = `${relativeTop + cellRect.height / 2 - fh * 0.2}px`;
    const left = `${relativeLeft + cellRect.width / 2 - fw * 0.4}px`;

    setTimeout(() => {
      this.fingerStyles = { ...this.fingerStyles, top, left };
    }, 100);
  }

  done() {
    this.running = false;
    this.dispatchEvent(new Event('next'));
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    clearTimeout(this.noResponseTimer);

    if (this.running) {
      console.log('running on disconnect');
      this.abortController.abort('disconnected');
    }
  }

  async onCompleteTurn() {
    const nextState = this.sheepState + 1;

    const nextLocation = locations[nextState + 1];

    if (nextLocation) {
      const { top, left, width, height, duration = 1000, easing = 'linear' } = nextLocation;

      const pad = '1.5';
      this.stimulusLocation = {
        top: `${top - Number(pad)}%`,
        left: `${left - Number(pad)}%`,
        width: `${width}%`,
        height: `${height}%`,
        padding: `${pad}%`,
      };
      this.sheepState = nextState;
      this.sheepDuration = duration;
      this.sheepEasing = easing;
    } else {
      // console.log('on complete');
      this.animateResolve?.();
    }
  }

  render() {
    return html`${this.getTrials.render({
      complete: () => {
        return html`${this.renderBGItems()}${this.renderStimulusItem()}`;
      },
    })}`;
  }

  renderBGItems() {
    const border = this.showLayout;

    // console.log('bg items');

    const bgItems = this.backgroundItems.map(item => {
      return html` <onc-game-item class=${classMap({ border })} .item=${item}>${item.icon}</onc-game-item> `;
    });
    return html` <div class="content">${bgItems}</div> `;
  }

  renderStimulusItem() {
    if (!this.item) {
      return nothing;
    }

    const { icon } = this.item;

    return html`
      <div class="content">
        ${when(
          this.fingerShow,
          () => html`
            <div
              ${animate({
                keyframeOptions: {
                  duration: 1000,
                },
                properties: ['left', 'top'],

                onComplete: () => this.onComplete(),
              })}
              style=${styleMap(this.fingerStyles)}
              class="finger-wrapper"
            >
              <onc-svg-icon class="finger-icon">${graphics.app.fingerTap}</onc-svg-icon>
            </div>
          `,
        )}
        <div
          class="content"
          style=${styleMap(this.stimulusLocation)}
          @click=${() => this.handleClick()}
          ${ref(this.targetRef)}
          ${animate({
            keyframeOptions: {
              duration: this.sheepDuration,
              easing: this.sheepEasing ?? 'linear',
            },

            // nb. need to wait for fade in to complete before changing another property
            // else the change will be instant with no motion applied
            // in: fadeIn,
            properties: ['left', 'top', 'height', 'width'],
            onComplete: () => this.onCompleteTurn(),
          })}
        >
          <div class="sheep-scene">
            <div class=${classMap(this.stimulusClass)}>
              <div class="sheep-face ">
                <onc-svg-icon>${icon}</onc-svg-icon>
              </div>
              <div class="sheep-face sheep-face-front">
                <onc-svg-icon>${graphics.sheep.sBack}</onc-svg-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    `;
  }

  getFixationHoldTime() {
    const fh = this.durations.fixation;

    return getRandomInt(fh.min, fh.max);
  }

  /**
   *
   * @param {Object} param0
   * @returns
   */
  makeTrial({ type, ...rest }) {
    const trial = { type, ...rest, fixationDuration: this.getFixationHoldTime(), response: [] };
    return trial;
  }

  /**
   * @param {number} length
   * @returns {Trial[]}
   */
  makeNoGoTrials(length) {
    return Array.from({ length }, () => this.makeTrial({ type: 'nogo', stimulusId: this.nogoStimulus }));
  }

  /**
   * @param {number} length
   * @param {boolean} [predictedNoGo]
   *
   * @returns {Trial[]}
   */
  makeGoTrials(length, predictedNoGo) {
    return Array.from({ length }, (_, index) =>
      predictedNoGo && index === 0
        ? [
            this.makeTrial({ type: 'go', stimulusId: 22 }),
            this.makeTrial({ type: 'nogo', predicted: true, stimulusId: this.nogoStimulus }),
          ]
        : this.makeTrial({ type: 'go', stimulusId: Number(this.stimuli.next().value) }),
    );
  }

  /**
   * @param {SheepBlock} block
   */
  makeTrials(block) {
    if (block.type === 'go') {
      return this.makeGoTrials(block.trials);
    }

    const startrials = this.makeGoTrials(block.goTrialsStart);
    const goTrialCount = block.goTrials - block.goTrialsStart;
    const predictedNoGo = block.predictedNoGo === 'yes';

    const goTrials = this.makeGoTrials(goTrialCount, predictedNoGo);

    const nogoCount = block.nogoTrials - (predictedNoGo ? 1 : 0);

    // make nogo trials
    const nogoTrials = this.makeNoGoTrials(nogoCount);
    const remainder1 = /** @type {Trial[]} */ (shuffle.getRandomSubarray([...goTrials, ...nogoTrials]));
    const remainder = remainder1.flat();

    return [...startrials, ...remainder];
  }

  /**
   * @param {Item} item
   * @param {number} locationIndex
   */
  setItemLocation(item, locationIndex) {
    const newItem = { ...item, ...locations[locationIndex] };

    newItem.top /= 100;
    newItem.left /= 100;
    return newItem;
  }

  /**
   * @param {Trial} trial
   * @param {number} [locationIndex=0]
   *
   * @returns {Item}
   */
  makeStimulusItem(trial, locationIndex = 0) {
    const { stimulusId } = trial;
    const { top, left, width, height } = locations[locationIndex];
    const [key, value] = typeof stimulusId === 'string' ? stimulusId.split(':', 2) : ['sheep', `s${stimulusId}`];
    const icon = graphics[key][snakeToCamel(value)];

    const item = {
      top: top / 100,
      left: left / 100,
      width,
      height,
      icon,
      id: stimulusId,
    };

    return item;
  }

  getBGItems(bgItems) {
    const itemsh = bgItems.map(([x, y, w, h, icon]) => [y / 100, x / 100, w, h, icon, 1]);

    return this.makeUIItems(itemsh);
  }

  /**
   * @param {any[]} items

   */
  makeUIItems(items) {
    const uiItems = [];
    for (const [y, x, w, h, name] of items) {
      const [key, value] = name.split(':', 2);

      const icon = graphics[key][snakeToCamel(value)];

      uiItems.push({
        top: y,
        left: x,
        width: w,
        height: h,
        icon,
      });
    }

    return uiItems;
  }
}

const OncSheepPresenter = class OncSheepPresenter extends SheepPresenter {
  static styles = css`
    .content {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .finger-wrapper {
      position: absolute;
      top: 50%;
      left: 45%;

      width: 5%;
      height: auto;
      z-index: 10;
    }

    .finger-wrapper[noshow] {
      visibility: hidden;
    }

    .stimulus {
      --onc-svg-icon-width: 100%;
    }

    .sheep-scene {
      perspective: 600px;
      width: 100%;
      height: 100%;
    }

    .sheep-card {
      width: 100%;
      height: 100%;
      transform-origin: center right;
      transform-style: preserve-3d;
      cursor: pointer;
    }

    .sheep-face {
      position: absolute;
      width: 100%;
      height: 100%;
      /* color: white;
      font-weight: bold;
      font-size: 2rem; */
      backface-visibility: hidden;
      /* border-radius: 1.5vh;
      overflow: hidden;
      display: flex;
      flex-direction: column; */
    }

    .sheep-face-front {
      transform: rotateY(180deg);
      /* background: #6e17a4; */
    }

    .sheep-flipped {
      transition: transform 1s;
      transform: translateX(-100%) rotateY(180deg);
    }
  `;
};
customElements.define('onc-sheep-presenter', OncSheepPresenter);

// ===
// Private functions
// ===

/**
 * @param {string|number} id
 */
function getStimulusId(id) {
  if (typeof id === 'string') {
    return id;
  }

  return `sheep:s${id}`;
}
/**
 * @param {string} string
 */
function snakeToCamel(string) {
  return string.replaceAll(/([_-]\w)/g, g => g[1].toUpperCase());
}

const _rng = Math.random;

// Returns a random integer between min (included) and max (excluded)
// Using Math.round() will give you a non-uniform distribution!
function getRandomInt(min, max) {
  return Math.floor(_rng() * (max - min)) + min;
}
