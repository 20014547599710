import ky from 'ky';

export default {
  /**
   * @param {import("ky/distribution/types/options").Input} url
   */
  loadUrl(url) {
    return ky.get(url);
  },
};
