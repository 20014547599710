import { html } from 'lit';

export const eggChick = html`
  <svg version="1.1" viewBox="0 0 190 190" xmlns="http://www.w3.org/2000/svg">
    <g stroke-width="1.283">
      <path
        d="m90.68 110.1c-3.209 20.79-25.6 34.79-49.95 31.01-24.36-3.772-41.5-23.87-38.26-44.67 3.209-20.79 25.57-34.6 49.91-30.82 24.36 3.772 41.5 23.69 38.29 44.49"
        fill="#af5b2b"
      />
      <g fill="#b6382f">
        <path
          d="m41.2 23.15c2.058-0.4442 3.869 0.01158 5.464 0.7294 1.589 0.7446 2.958 1.859 4.035 3.213s1.848 2.963 2.204 4.699c0.3257 1.745 0.286 3.612-0.6218 5.495-1.808-1.039-3.021-2.014-4.118-2.982-1.082-0.9655-1.989-1.928-2.808-2.974-0.8232-1.044-1.559-2.169-2.252-3.461-0.6872-1.315-1.311-2.74-1.903-4.72"
        />
        <path
          d="m60.18 14.04c1.62 1.662 2.503 3.475 3.144 5.311 0.6103 1.847 0.9077 3.739 0.9116 5.658-0.0026 1.915-0.3065 3.859-1.007 5.759-0.7283 1.865-1.819 3.837-3.826 5.072-0.6846-2.188-1.184-3.866-1.511-5.636-0.318-1.743-0.4858-3.463-0.4796-5.209 0.01011-1.744 0.1821-3.512 0.5756-5.321 0.4179-1.817 1.001-3.656 2.191-5.633"
        />
        <path
          d="m66.57 19.17c1.207 1.835 1.664 3.656 1.877 5.444 0.1846 1.79 0.05128 3.557-0.3872 5.274-0.4345 1.72-1.181 3.399-2.325 4.93-1.145 1.464-2.754 3.032-5.002 3.316 0.06669-2.154-0.09102-3.685 0.01913-5.303 0.0886-1.585 0.3066-3.118 0.7027-4.641 0.3923-1.523 0.9499-3.033 1.735-4.539 0.8091-1.504 1.784-2.995 3.38-4.485"
        />
      </g>
      <g fill="#3c3c3b">
        <path
          d="m4.263 59.76c2.609-1.007 5.482-1.192 8.402-0.7819 1.448 0.2568 2.893 0.6972 4.28 1.338 1.37 0.6625 2.665 1.519 3.833 2.522s2.314 2.068 3.315 3.259c0.995 1.194 1.689 2.585 2.053 4.04 0.6962 2.924 0.6334 5.948-0.8207 8.865l-0.6756 0.1053c-2.262-2.296-3.561-4.445-4.836-6.429-0.6168-0.9912-1.082-1.986-1.474-3.038-0.3757-1.059-0.8297-2.114-1.495-3.01-0.6514-0.9064-1.414-1.733-2.291-2.455-0.8539-0.7512-1.831-1.401-2.901-1.935-2.121-1.13-4.659-1.714-7.243-1.811z"
        />
        <path
          d="m5.162 49.74c1.802-0.981 3.764-1.618 5.847-1.904 2.077-0.2889 4.306-0.1965 6.464 0.484 2.147 0.6972 4.147 2.007 5.731 3.66 1.579 1.667 2.754 3.617 3.667 5.611 0.9102 2.083 1.611 3.852 2.369 5.796 0.7027 1.921 1.172 3.924 1.336 6.002 0.1552 2.071 0.1308 4.196-0.2603 6.328-0.4028 2.105-0.9564 4.33-2.653 6.118l-0.6038-0.3198c-0.8142-3.863-2.2-7.312-3.202-10.94-0.5154-1.802-0.9116-3.638-1.167-5.535-0.2475-1.855-0.4858-3.92-0.9297-5.531-0.9373-3.349-2.656-6.371-5.619-8.047-2.938-1.759-6.98-1.935-10.71-1.093z"
        />
        <path
          d="m23.5 47.17c1.813-0.4699 3.761-0.2376 5.63 0.488 0.9167 0.3841 1.804 0.8974 2.622 1.517 0.7951 0.6393 1.518 1.376 2.156 2.17 0.6384 0.7975 1.303 1.596 1.872 2.457 0.5576 0.8666 0.8553 1.835 0.873 2.826 0.0166 0.985-0.1347 1.977-0.5193 2.935-0.3616 0.9579-0.9001 1.893-1.844 2.774l-0.6846 0.02425c-1.007-0.7881-1.671-1.518-2.235-2.219-0.5832-0.6958-1.009-1.359-1.366-1.996-0.3552-0.6382-0.5229-1.274-0.5475-1.975-0.01795-0.6997-0.04742-1.425-0.2565-2.069-0.2013-0.6447-0.4627-1.27-0.7922-1.861-0.3065-0.6125-0.6922-1.197-1.163-1.737-0.9116-1.107-2.216-2.056-3.735-2.656z"
        />
        <path
          d="m45.82 103.6c-0.327-0.0591-0.6384-0.1413-0.9463-0.235-20.54-4.036-29.16-21.87-29.16-21.87-4.454 8.692-6.06 18.99-6.06 18.99-2.575 19.86 11.12 38.23 30.99 41.3l5.89-38.15c-0.1577-0.0141-0.3077 0-0.4616-0.0232-0.08333-0.0141-0.168-0.0268-0.2513-0.0398"
        />
      </g>
      <path
        d="m28.5 53.72-9e-3 -0.01665s-0.01155 0.0732-0.02949 0.19c-0.1564 0.6704-0.3103 1.344-0.4168 2.037-0.03331 0.2119-0.0436 0.4225-0.07178 0.6342-0.5374 2.529-1.531 6.454-3.286 10.48-1.236 2.826-2.841 5.705-4.944 8.185-1.53 1.811-2.845 3.928-3.981 6.147 0 0 8.615 17.82 29.16 21.87l0.0051 1e-3c0.3039 0.0937 0.6168 0.1759 0.9412 0.235 0.08459 0.0127 0.168 0.0257 0.2526 0.0385 0.1539 0.0232 0.3039 9e-3 0.4601 0.0232l3.069-19.88 7.586-49.12c-13.21-2.046-25.61 6.429-28.73 19.19"
        fill="#af5b2b"
      />
      <path
        d="m82.74 92.13s-14.4 14.76-36.13 11.38l-5.89 38.15c19.87 3.078 38.47-10.33 42.01-30.06 0 0 1.53-9.977 0.02959-19.47"
        fill="#575756"
      />
      <path
        d="m80.68 84.67c-1.146-2.758-1.803-5.709-2.153-8.55-0.6002-4.847-0.3103-9.372-0.02562-12.06 0.01516-0.104 0.0423-0.2016 0.05771-0.3056 0.07052-0.4608 0.09614-0.9195 0.1436-1.378 0.07308-0.5455 0.1308-0.8757 0.1308-0.8757l-0.04742 0.05267c0.89-13.14-8.373-24.98-21.59-27.03l-7.586 49.12-3.067 19.88c21.74 3.366 36.13-11.38 36.13-11.38-0.4103-2.601-1.037-5.169-1.999-7.486"
        fill="#ea8232"
      />
      <path
        d="m59.64 79.17c0.0013-0.0051 0.0013-0.01014 0.0026-0.01412 0.868-5.625-3.51-10.97-7.694-11.62-4.19-0.6483-9.972 3.127-10.84 8.75-0.3475 2.254-1.435 8.141 2.813 8.797 1.253 0.1939 2.449-0.2132 3.334-0.9886 0.6897 0.1836 1.392 0.33 2.07 0.4351 0.6835 0.1053 1.393 0.1772 2.108 0.2119 0.6078 1.007 1.626 1.757 2.88 1.951 4.237 0.6563 4.98-5.26 5.33-7.522"
        fill="#ea575b"
      />
      <path
        d="m44.66 47.46c-6.211-0.9618-11.99 3.05-12.9 8.96-0.9142 5.915 3.384 11.48 9.596 12.45 4.645 0.7189 8.524 2.014 10.83-1.528l1.817-11.77c-1.128-4.091-4.699-7.388-9.344-8.105"
        fill="#b6382f"
      />
      <path
        d="m61.95 72.11c6.211 0.9618 11.99-3.049 12.9-8.963 0.9127-5.908-3.388-11.48-9.596-12.44-4.659-0.7204-9.069 1.354-11.37 4.927-0.0026-0.0076-0.0076-0.01774-0.0101-0.02679l-1.817 11.77c0.0051-0.0091 0.01263-0.0152 0.01768-0.02425 1.118 4.101 5.219 4.04 9.874 4.76"
        fill="#ea575b"
      />
      <path
        d="m50.04 67.47-3.002 1.378c-1.208 0.5546-1.788 2.017-1.29 3.253l3.157 7.83c0.2488 0.6201 0.6489 0.972 1.091 1.04l2.121-13.74c-0.7258-0.1117-1.471-0.04109-2.077 0.2363"
        fill="#f39e2b"
      />
      <path
        d="m51.3 80.32 5.37-6.509c0.8488-1.027 0.7359-2.598-0.2488-3.491l-2.447-2.222c-0.4923-0.4482-1.186-0.741-1.908-0.854l-2.121 13.74c0.4425 0.06806 0.9297-0.1464 1.353-0.6625"
        fill="#fbdd58"
      />
      <path
        d="m44.91 58.5c-0.3116 2.017-2.199 3.399-4.208 3.089-2.015-0.312-3.394-2.2-3.082-4.217 0.3103-2.015 2.195-3.397 4.208-3.085 2.011 0.3108 3.393 2.198 3.082 4.214"
        fill="#4e1412"
      />
      <path
        d="m68.63 62.08c-0.3116 2.017-2.198 3.399-4.208 3.089-2.012-0.312-3.394-2.2-3.082-4.217 0.3103-2.015 2.197-3.397 4.208-3.085 2.011 0.3108 3.394 2.198 3.082 4.214"
        fill="#4e1412"
      />
      <path
        d="m44.12 56.83c-0.1064 0.6896-0.7514 1.163-1.439 1.057-0.6886-0.1066-1.161-0.7526-1.054-1.443 0.1064-0.6896 0.75-1.161 1.44-1.054 0.6861 0.1066 1.159 0.7512 1.053 1.441"
        fill="#fff"
      />
      <path
        d="m67.72 60.52c-0.1064 0.6896-0.7489 1.163-1.437 1.057-0.6897-0.1066-1.161-0.7526-1.054-1.442 0.1064-0.6896 0.75-1.162 1.439-1.056 0.6886 0.1066 1.16 0.7512 1.053 1.441"
        fill="#fff"
      />
      <path
        d="m44.12 8c3.035 2.337 5.233 4.858 7.269 7.023 0.4962 0.5368 0.9961 1.03 1.423 1.442 0.2283 0.2222 0.4269 0.339 0.6334 0.5329l0.8398 0.7678c1.209 1.124 2.567 2.69 3.59 4.485 2.111 3.631 2.679 7.848 2.174 11.49-0.5269 3.685-1.92 7.045-4.482 9.937-1.654-3.488-2.552-6.603-3.542-9.354-0.9564-2.778-1.9-5.01-3.026-6.983-0.5756-1.017-1.225-1.992-2.107-3.317l-0.7117-1.087c-0.2988-0.4366-0.5872-0.9644-0.8052-1.414-0.4731-0.922-0.7896-1.827-1.053-2.714-0.9705-3.547-1.151-7.048-0.2026-10.81"
        fill="#ea575b"
      />
      <path
        class="egg-shell"
        d="m93.13 102-18.84-2.787 1.481-4.851-10.42-1.184-12.01-7.501-7.312 5.488-5.28-5.763-9.535 16.04-19.7-6.027 2.304 16.8-13.21-0.5006c-4.201 29.93 13.93 45.54 30.66 50.64 19.35 5.926 50.27-0.1297 59.87-42.64 1.149-5.075 1.862-11.2 1.94-17.73"
        fill="#dda16b"
      />
      <g class="egg-spots" fill="#d3bda3">
        <path
          d="m26.83 115.6c-2.989-0.9155-4.674-4.083-3.757-7.077 0.9142-2.995 4.078-4.681 7.066-3.765 2.99 0.9155 4.674 4.083 3.757 7.077-0.9142 2.995-4.078 4.681-7.066 3.765"
        />
        <path
          d="m27.73 139.9c-1.698-0.5202-2.652-2.318-2.134-4.018 0.5193-1.699 2.314-2.656 4.013-2.136 1.698 0.5202 2.652 2.318 2.134 4.018-0.5193 1.699-2.314 2.656-4.013 2.136"
        />
        <path
          d="m50.44 121.3c-2.181-0.6675-3.409-2.981-2.743-5.166 0.6666-2.186 2.976-3.414 5.157-2.747 2.181 0.6675 3.409 2.981 2.741 5.166-0.6666 2.186-2.976 3.414-5.157 2.747"
        />
        <path
          d="m51.7 149.8c-2.585-0.7921-4.038-3.533-3.251-6.121 0.7911-2.589 3.528-4.047 6.114-3.255 2.587 0.791 4.042 3.533 3.251 6.121-0.7896 2.59-3.528 4.047-6.114 3.255"
        />
        <path
          d="m74.65 113.6c-2.423-0.7421-3.786-3.312-3.047-5.738 0.7398-2.428 3.307-3.794 5.731-3.052 2.423 0.7421 3.786 3.312 3.047 5.741-0.7409 2.427-3.307 3.794-5.731 3.051"
        />
      </g>
      <path
        class="egg-crack"
        d="m1.328 109.5 12.85 1.402-1.425 1.509-1.95-16.7-0.1757-1.51 1.395 0.4688 19.48 6.549-0.4526 0.1759 9.502-15.29-8.824 15.71-0.1475 0.2607-0.3065-0.0847-19.81-5.481 1.219-1.043 2.697 16.59 0.2616 1.607-1.686-0.0963-12.9-0.741z"
        fill="#8c6239"
      />
      <path
        class="egg-crack"
        d="m92.59 104.3-17.98-3.214-1.26-0.226 0.2783-1.167 1.601-6.719 0.8232 0.981-10.6-0.362-0.1334-0.0051-0.08719-0.06165-11.74-8.431 12.17 7.787-0.2205-0.06679 10.6-0.5238 0.9564-0.04623-0.1347 1.028-0.8911 6.849-0.9795-1.393 18.07 2.629z"
        fill="#8c6239"
      />
      <path
        class="egg-shell"
        d="m170.8 177.6-9.665-18.21-18.08 4.59 0.3359-7.812-9.044-1.3-3.191-13.8-6.532-8.217 4.472-2.382-11.35-15.31c-15.38 15.04-28.9 37.97-18.77 57.05 12.1 22.8 54.42 20.25 74.74 11.04 6.41-2.904 11.8-6.092 16.29-9.477l-8.979-9.702z"
        fill="#dda16b"
      />
      <g class="egg-spots" fill="#d3bda3">
        <path
          d="m162 178.4c0.5154 0.9709 0.1475 2.178-0.8218 2.694-0.9694 0.5148-2.175 0.1464-2.69-0.8243-0.5154-0.9709-0.1475-2.177 0.8218-2.693 0.9705-0.5162 2.175-0.1477 2.69 0.8232"
        />
        <path
          d="m129.3 169.7c-0.3963-0.7472-0.1129-1.675 0.6319-2.071 0.7463-0.3982 1.672-0.1143 2.07 0.6331 0.3963 0.7461 0.1128 1.675-0.6334 2.071-0.7449 0.3982-1.672 0.1143-2.068-0.6331"
        />
        <path
          d="m146.6 169.7c-0.7539-1.42-0.2167-3.182 1.202-3.939 1.418-0.7551 3.177-0.2158 3.93 1.204 0.7539 1.419 0.2167 3.181-1.202 3.935-1.418 0.7551-3.177 0.217-3.93-1.203"
        />
        <path
          d="m112.9 154.4c-1.11-2.092-0.318-4.688 1.771-5.803 2.089-1.112 4.681-0.3185 5.792 1.773 1.11 2.092 0.318 4.688-1.771 5.803-2.089 1.112-4.681 0.3184-5.792-1.773"
        />
        <path
          d="m127.6 143.5c0.8192-0.4366 1.839-0.1259 2.276 0.6972 0.436 0.8217 0.1244 1.842-0.6962 2.28-0.8207 0.4366-1.839 0.1245-2.276-0.6972-0.436-0.8232-0.1244-1.843 0.6962-2.28"
        />
      </g>
      <path
        class="egg-crack"
        d="m167.4 171.7-6.843 4.272-0.672 0.4199-0.3898-0.6266-4.638-7.45 0.6976 0.0668-9.344 8.731 8.701-9.376 0.3642-0.3928 0.3334 0.4597 5.154 7.102-1.062-0.2068 6.525-4.749z"
        fill="#8c6239"
      />
      <path
        class="egg-crack"
        d="m126.7 132.2-8.965-4.011 1.054-0.7718 0.4551 9.198 0.0487 0.9836-7.0355-5.3066 6.785 3.982-0.7038 0.4159-1.074-9.148-0.1436-1.228 1.198 0.4547 9.185 3.482z"
        fill="#8c6239"
      />
    </g>
  </svg>
`;
