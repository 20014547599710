import { fixupDurationValues } from '@state/project/game-task';

const fields_ = [
  ['stimulus', 'ms'],
  ['fixation', 'ms'],
  ['feedback', 'ms'],
];

/**
 * @typedef { import('./record-types.js').DurationsRecord}  DurationsRecord
 *
 * @typedef {{stimulus: Number, fixation: Number, feedback: Number}} MemoryDurationsSettings
 */

export class MemoryDurations {
  /**
   *
   * @param {DurationsRecord} durationsRecord
   */
  constructor(durationsRecord) {
    const durations = /** @type {MemoryDurationsSettings } */ (fixupDurationValues({ ...durationsRecord }, fields_));

    this.stimulus = durations.stimulus;
    this.fixation = durations.fixation;
    this.feedback = durations.feedback;
  }
}
