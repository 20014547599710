import { animate, fadeIn } from '@lit-labs/motion';
import { Task } from '@lit/task';
import { LitElement, css, html, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { styleMap } from 'lit/directives/style-map.js';
import { graphics } from '~/graphics/index.js';

export class TaskProgress extends LitElement {
  static properties = {
    bgItems: { type: Array },
    pgItem: { type: Array },
    styles: { state: true },
    layoutRect: { type: Object },
    progress: { type: Number },
    lastProgress: { type: Number },
    showLayout: { type: Boolean },
  };

  constructor() {
    super();

    // /** @type {Object[]} */
    this.items = [];
    this.bgItems = [];
    this.pgItem = [];
    this.progress = 0;
    this.lastProgress = 0;
    this.state = 0;
    this.layoutRect = {};
    this.styles = {};
    this.showLayout = false;

    this.getTrials = new Task(this, {
      task: async () => {
        this.items = await this.makeUIItems(this.bgItems);

        // this.styles = { '--pg-left': '0' };
        this.duration = 1000;
      },
      args: () => [],
    });
  }

  /**
   * @param {Map<string,any>} changed
   */
  willUpdate(changed) {
    if (changed.has('layoutRect')) {
      const { width: layoutWidth, height: layoutHeight } = this.layoutRect;
      const width = layoutWidth > 0 ? (layoutWidth * this.pgItem[2]) / 100 : 200;
      const height = layoutHeight > 0 ? (layoutHeight * this.pgItem[3]) / 100 : 200;

      this.progressRange = document.body.clientWidth - width;

      this.styles.width = `${width}px`;
      this.styles.height = `${height}px`;

      if (this.styles['--pg-left'] === undefined) {
        const left = this.lastProgress * this.progressRange;

        this.styles['--pg-left'] = `${left}px`;
      }
    }
  }

  render() {
    return html`${this.getTrials.render({
      complete: () => {
        return html`${this.renderItems()} ${this.renderProgress()}`;
      },
    })}`;
  }

  scaledWidth(itemWidth) {
    const { width: layoutWidth } = this.layoutRect;
    if (itemWidth === 100) {
      return '100%';
    }
    const sw = layoutWidth > 0 ? (layoutWidth * itemWidth) / 100 : 200;

    return `${sw}px`;
  }

  scaledHeight(itemHeight) {
    const { height: layoutHeight } = this.layoutRect;
    const sh = layoutHeight > 0 ? (layoutHeight * itemHeight) / 100 : 200;

    return `${sh}px`;
  }

  renderItems() {
    const items = this.items.map(item => {
      const style = {
        bottom: '0',
        width: this.scaledWidth(item.width),
        height: this.scaledHeight(item.height),
      };

      if (item.left === 100) {
        style.right = '0';
      } else {
        style.left = `${item.left}%`;
      }

      const itemClasses = { 'item-wrapper': 1 };
      if (this.showLayout) {
        itemClasses.border = 1;
      }

      return html` <div class=${classMap(itemClasses)} style=${styleMap(style)}>${item.icon}</div> `;
    });

    return items;
  }

  renderProgress() {
    if (!this.styles.width) {
      return nothing;
    }

    const [key, value] = this.pgItem[4].split(':', 2);

    const icon = graphics[key][value];

    const progressClasses = { progress: 1 };
    if (this.showLayout) {
      progressClasses.border = 1;
    }

    return html`
      <div
        class=${classMap(progressClasses)}
        style=${styleMap(this.styles)}
        ${animate({
          keyframeOptions: {
            duration: this.duration,
          },
          in: fadeIn,
          properties: ['left', 'top'],
          onComplete: () => this.onComplete(),
        })}
      >
        ${icon}
      </div>
    `;
  }

  onComplete() {
    if (this.state === 0) {
      this.state = 1;
      this.duration = 5000;

      const left = this.progress * this.progressRange;

      this.styles = { ...this.styles, '--pg-left': `${left}px` };
    } else if (this.state === 1) {
      this.dispatchEvent(new Event('done'));
    }
  }

  async makeUIItems(items) {
    const uiItems = items.map(([left, top, width, height, graphic]) => {
      const [key, value] = graphic.split(':', 2);

      const icon = graphics[key][value];
      return { left, top, width, height, icon };
    });

    return uiItems;
  }
}
const OncTaskProgress = class OncTaskProgress extends TaskProgress {
  static styles = css`
    .progress {
      position: absolute;
      left: var(--pg-left, 0);
      bottom: 1%;
    }

    .progress svg {
      position: absolute;
      /* background: yellow; */
      width: 100%;
      height: 100%;
    }

    .progress.border svg {
      border: 1px solid yellow;
    }

    .item-wrapper {
      position: absolute;
      /* border: 1px solid red; */
    }

    .item-wrapper.border {
      border: 1px solid red;
    }

    .item-wrapper svg {
      position: absolute;
      /* background: cyan; */
      width: 100%;
      height: 100%;
    }

    .item-wrapper.border svg {
      border: 1px solid violet;
    }
  `;
};

customElements.define('onc-task-progress', OncTaskProgress);
