import { ContextConsumer, ContextProvider } from '@lit/context';
import { api } from '@state/api';
import { Store } from '@state/idb-database.js';
import { Project } from '@state/project/project';
import { projectContext, userContext } from './contexts';

/**
 * @typedef {import('lit').ReactiveControllerHost} ReactiveControllerHost
 * @typedef {import('lit').ReactiveElement} ReactiveElement
 */

export class ProjectController {
  /**
   *
   * @param {ReactiveControllerHost & ReactiveElement} host
   */
  constructor(host, callback) {
    this.provide(host, projectContext, 'project');

    new ContextConsumer(host, {
      context: userContext,
      callback: value => {
        value && this.fetchProjects(value);
      },
      subscribe: true,
    });

    this.statestore = new Store('profile');

    this.callback = callback;
    (this.host = host).addController(this);
  }

  fetchProjects = async (currentUser, activeOnly = true) => {
    const { userRef: userId } = currentUser;
    if (userId) {
      this.lastProjectIdKey = `lastProjectId:${userId}`;
      try {
        /** @type {Object} */
        const projectsInfo = await api.get(`/api/project`).json();

        const projects = new Map();

        for (const { tasks = [], ...projectInfo } of projectsInfo) {
          projects.set(projectInfo._id, new Project(projectInfo, tasks));
        }

        this.activeOnly = activeOnly;

        const lastProjectId = await this.statestore.get(this.lastProjectIdKey);

        this.projectId = projects.has(lastProjectId) ? lastProjectId : projectsInfo[0]?._id;
        this.projects = projects;

        const project = projects.get(this.projectId);

        this.setCurrentProject(project);
      } catch (error) {
        console.log(error.toString());
        this.projects = new Map();
      }
    } else {
      this.setCurrentProject();
    }
  };

  setCurrentProject = async project => {
    project && this.statestore.set(this.lastProjectIdKey, project.projectId);

    this.project = project;

    this.callback?.(project);
  };

  provide(host, context, name) {
    const provider = new ContextProvider(host, { context });

    let storage;

    Object.defineProperty(this, name, {
      get: function () {
        return storage;
      },
      set: function (value) {
        provider.setValue(value);

        storage = value;
      },
    });
  }

  hostDisconnected() {}
}
