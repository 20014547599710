import { html } from 'lit';

export const c11 = html`
  <svg
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    viewBox="0 0 620 670"
  >
    <path
      d="m504.6 464.4c0 87.2-82.9 158.6-185.3 158.6-102.3 0-185.2-71.5-185.2-158.6 0-87.2 83-157.9 185.2-157.9 102.3 0 185.2 70.69 185.2 157.9"
      fill="#b3b3b3"
    />
    <g fill="#d4145a">
      <path
        d="m246.5 139.9c8.156-3.126 15.88-2.404 22.88-0.4825 6.99 2.044 13.31 5.736 18.58 10.61 5.274 4.855 9.452 10.96 12.01 17.84 2.442 6.939 3.461 14.61 0.9325 22.9-8.072-3.109-13.66-6.329-18.79-9.599-5.048-3.27-9.376-6.63-13.4-10.4-4.039-3.751-7.766-7.895-11.42-12.75-3.7-4.95-7.1-10.39-10.8-18.12"
      />
      <path
        d="m318.6 90.4c7.692 5.781 12.46 12.64 16.26 19.76 3.675 7.171 6.092 14.74 7.322 22.6 1.201 7.846 1.188 16-0.4825 24.22-1.806 8.101-5.026 16.86-12.48 23.2-4.2-8.531-7.305-15.1-9.768-22.14-2.408-6.934-4.185-13.88-5.261-21.02-1.065-7.151-1.481-14.5-1.012-22.16 0.5638-7.7 1.792-15.61 5.419-24.46"
      />
      <path
        d="m348.2 107.3c6.11 6.752 9.142 13.91 11.15 21.1 1.89 7.214 2.459 14.54 1.75 21.85-0.6975 7.325-2.692 14.68-6.412 21.68-3.77 6.725-9.38 14.16-18.42 16.75-1.09-8.86-2.704-15.02-3.278-21.72-0.635-6.542-0.7125-12.96-0.055-19.45 0.6462-6.488 1.976-13.02 4.24-19.69 2.374-6.675 5.426-13.4 11.03-20.51"
      />
      <path
        d="m118.3 313c10.06-5.778 21.74-8.356 33.96-8.53 6.104 0.1325 12.31 1.02 18.4 2.766 6.036 1.844 11.89 4.534 17.32 7.895 5.425 3.368 10.8 7 15.65 11.25 4.839 4.261 8.562 9.515 10.98 15.24 4.709 11.54 6.365 23.96 2.246 36.82l-2.7 0.86c-10.74-7.965-17.42-15.95-23.9-23.26-3.158-3.668-5.694-7.44-7.964-11.51-2.212-4.101-4.745-8.132-8.039-11.38-3.245-3.298-6.899-6.198-10.95-8.6-3.979-2.536-8.398-4.576-13.12-6.086-9.425-3.275-20.2-4.058-30.86-2.815l-1.025-2.648z"
      />
    </g>
    <path
      d="m115.5 271.4c6.77-5.164 14.41-9.012 22.78-11.51 8.335-2.502 17.52-3.536 26.82-2.118 9.246 1.495 18.28 5.589 25.82 11.35 7.532 5.828 13.59 13.08 18.6 20.66 5.051 7.95 9.044 14.75 13.39 22.24 4.098 7.42 7.291 15.32 9.282 23.74 1.945 8.384 3.189 17.1 2.938 26.09-0.3188 8.872-1.181 18.35-7.008 26.74l-2.676-0.9225c-5.785-15.31-13.66-28.56-20.06-42.78-3.256-7.05-6.044-14.31-8.294-21.92-2.186-7.434-4.471-15.74-7.315-22.08-5.988-13.11-14.94-24.4-28.18-29.39-13.16-5.34-29.85-3.496-44.62 2.318l-1.488-2.418z"
      fill="#88a80d"
    />
    <path
      d="m189 249.2c7.136-3.074 15.28-3.361 23.4-1.572 4.002 0.9925 7.968 2.536 11.72 4.549 3.664 2.116 7.098 4.674 10.21 7.524 3.124 2.858 6.355 5.709 9.232 8.874 2.834 3.192 4.671 6.972 5.372 11.02 0.6925 4.025 0.6962 8.181-0.275 12.35-0.8738 4.15-2.495 8.321-5.806 12.52l-2.788 0.5375c-4.632-2.591-7.818-5.155-10.57-7.67-2.834-2.481-5-4.926-6.865-7.309-1.859-2.389-2.951-4.884-3.496-7.74-0.5175-2.859-1.098-5.806-2.359-8.308-1.236-2.515-2.704-4.911-4.429-7.119-1.645-2.314-3.599-4.465-5.869-6.372-4.444-3.954-10.39-7.014-17-8.51l-0.4625-2.762z"
      fill="#d4145a"
    />
    <path
      d="m316.3 465.9c-1.376-0.035-2.708-0.175-4.031-0.3625-86.8-3.508-133.4-71.05-133.4-71.05-12.76 38.41-12.82 81.62-12.82 81.62 2.009 82.95 69.82 149.5 153.2 149.5v-160c-0.6912 0.0962-1.295 0.25-1.941 0.25h-1.059z"
      fill="#fff"
    />
    <path
      d="m213.6 273.1-0.0487-0.0625s0.004 0.3062 0.004 0.7962c-0.22 2.844-0.4238 5.701-0.4238 8.608 0 0.8862 0.0913 1.752 0.105 2.64-0.5975 10.7-2.186 27.4-6.84 45.02-3.28 12.36-8.041 25.16-15.1 36.66-5.128 8.384-9.184 17.89-12.44 27.7 0 0 46.62 67.55 133.4 71.05h0.0212c1.306 0.1875 2.641 0.3275 4.01 0.3625h1.059c0.6462 0 1.25-0.1538 1.904-0.195v-289.3c-55.42-0.05-100.9 42.5-105.6 96.75"
      fill="#b3b3b3"
    />
    <path
      d="m460.3 396.1s-49.72 69.54-141 69.54v160c83.48 0 151.4-66.72 153.2-149.8 0 0-0.0425-41.82-12.21-79.76"
      fill="#fff"
    />
    <path
      d="m447.3 366.8c-6.445-10.57-11.01-22.25-14.24-33.65-5.534-19.48-7.21-38.19-7.745-49.38 0-0.4325 0.045-0.8525 0.045-1.285 0-1.936-0.1888-3.829-0.2862-5.736-0.0425-2.285-0.0138-3.668-0.0138-3.668l-0.1612 0.2438c-4.664-54.34-50.15-96.99-105.7-96.99v289.3c91.29 0 141-69.54 141-69.54-3.265-10.34-7.468-20.46-12.88-29.34"
      fill="#ccc"
    />
    <g fill="#999">
      <path
        d="m357.6 357.4v-0.0625c0-23.58-21.34-42.71-38.92-42.71-17.59 0-38.92 19.12-38.92 42.71 0 9.445-0.73 34.25 17.11 34.25 5.258 0 9.906-2.424 13.05-6.162 2.949 0.315 5.918 0.4688 8.768 0.4688 2.865 0 5.824-0.1538 8.779-0.4688 3.126 3.739 7.78 6.162 13.05 6.162 17.8 0.0125 17.1-24.69 17.1-34.18"
      />
      <path
        d="m275.9 236.9c-26.09 0-47.24 20.1-47.24 44.88 0 24.8 21.15 44.89 47.24 44.89 19.5 0 36.24 2.844 43.46-13.12v-49.36c-7.225-16.04-23.95-27.28-43.46-27.28"
      />
      <path
        d="m362.9 326.8c26.09 0 47.25-20.09 47.25-44.89 0-24.79-21.16-44.88-47.25-44.88-19.56 0-36.34 11.29-43.52 27.38-0.015-0.0288-0.0425-0.07-0.0562-0.105v49.36c0.0137-0.0412 0.0412-0.0687 0.0562-0.1112 7.188 16.1 23.96 13.24 43.52 13.24"
      />
    </g>
    <path
      d="m311 315.8-11.44 7.545c-4.601 3.04-6.054 9.398-3.231 14.14l17.91 30.06c1.415 2.382 3.276 3.57 5.135 3.57v-57.61c-3.049 0.004-6.064 0.765-8.372 2.288"
      fill="#f39e2b"
    />
    <path
      d="m324.5 367.5 17.91-30.08c2.83-4.744 1.37-11.1-3.235-14.14l-11.44-7.545c-2.306-1.524-5.339-2.285-8.37-2.285v57.61c1.859 0 3.72-1.188 5.131-3.57"
      fill="#fbdd58"
    />
    <path
      d="m284.1 281.9c0 8.461-6.868 15.31-15.31 15.31-8.46 0-15.31-6.854-15.31-15.31 0-8.446 6.854-15.3 15.31-15.3 8.445 0 15.31 6.854 15.31 15.3"
      fill="#4e1412"
    />
    <path
      d="m383.7 281.9c0 8.461-6.864 15.31-15.31 15.31-8.45 0-15.31-6.854-15.31-15.31 0-8.446 6.86-15.3 15.31-15.3 8.449 0 15.31 6.854 15.31 15.3"
      fill="#4e1412"
    />
    <path
      d="m279.7 275.9c0 2.892-2.348 5.24-5.232 5.24-2.892 0-5.234-2.348-5.234-5.24s2.341-5.232 5.234-5.232c2.885 0 5.232 2.34 5.232 5.232"
      fill="#fff"
    />
    <path
      d="m379 275.9c0 2.892-2.338 5.24-5.23 5.24-2.891 0-5.232-2.348-5.232-5.24s2.341-5.232 5.232-5.232c2.892 0 5.23 2.34 5.23 5.232"
      fill="#fff"
    />
    <path
      d="m249.1 75.77c13.92 7.646 24.54 16.59 34.26 24.15 2.379 1.886 4.738 3.588 6.756 5.006 1.076 0.7612 1.96 1.118 2.931 1.778l3.934 2.614c5.669 3.835 12.23 9.39 17.55 16.1 10.96 13.54 15.95 30.45 16.19 45.69 0.1688 15.42-3.409 30.08-12.09 43.55-8.991-13.24-14.65-25.41-20.45-36.08-5.68-10.77-10.96-19.31-16.82-26.69-3.004-3.804-6.284-7.381-10.74-12.25l-3.612-4.004c-1.502-1.596-3.015-3.578-4.195-5.279-2.526-3.479-4.395-6.982-6.036-10.44-6.235-13.9-9.184-28.14-7.681-44.14"
      fill="#88a80d"
    />
    <g fill="#ed1c24">
      <path
        d="m319.3 482c12.96 0 25.75 19.06 38.2 17.28 11.02-1.58 21.79-23.82 32.19-26.69 13.21-3.63 25.85 11.75 37.69 6.27 16.12-7.472 30.79-16.55 43.44-26.96-3.259-16.16-2.189-42.34-10.5-56.12-8.875 10.88-19.85 21.3-32.36 30.64-11 8.212-23.14-4.438-35.94 1.638-9.888 4.688-20.16 28.6-30.6 31.55-13.91 3.925-28.12-13.88-42.1-13.88-16.72 0-33.72 16.68-50.12 10.97-14.18-4.939-27.91-31.66-40.64-39.59-10.75-6.691-20.78 5.766-29.75-2.265-7.43-6.652-14.14-13.64-19.91-20.76-8.311 13.79-7.841 41.65-11.1 57.81 10.26 8.444 21.85 16.01 34.48 22.56 10.28 5.33 21.24-10.02 32.74-6.134 12.58 4.251 25.78 27.59 39.42 29.9 14.52 2.475 29.58-16.22 44.86-16.22"
      />
      <path
        d="m167.4 488c1.121 19.52 6.23 38.02 14.56 54.76 11.86 3.972 24.28 7.691 37.16 10.96 9.81 2.49 19.89-10.28 30.21-8.392 10.8 1.975 21.85 18.58 33.12 19.7 12.06 1.204 24.38-13.14 36.88-13.14 9.329 0 18.55 14.62 27.64 13.95 12.52-0.9462 24.8-17.5 36.78-19.52 11.06-1.872 21.86 10.85 32.36 8.266 14.11-3.474 27.69-7.496 40.6-11.82 8.329-16.74 13.44-35.24 14.56-54.76-14.95 9.906-31.78 18.38-50.08 25.09-11.9 4.37-24.42-7.001-37.45-4.201-11.88 2.555-24.16 19.42-36.79 20.54-9.056 0.8-18.28-13.79-27.64-13.79-15.25 0-30.14 13.91-44.52 11.82-11.03-1.596-21.78-18.78-32.15-21.49-11.5-3.005-22.55 8.334-33.08 4.08-15.32-6.175-29.48-13.59-42.25-22.05"
      />
      <path
        d="m198.8 569.1c27.82 34.61 71.45 56.9 120.5 56.9s92.68-22.3 120.5-56.9c-9.306 4.269-19.09 8.058-29.26 11.32-10.18 3.26-20.75-6.509-31.68-4.355-10.36 2.046-21.02 16.08-31.94 17.04-9.056 0.8-18.28-11.29-27.64-11.29-10.05 0-19.94 12.02-29.64 11.11-12.54-1.188-24.64-15.6-36.34-18.18-9.738-2.148-19.2 7.75-28.31 4.706-9.125-3.036-17.92-6.5-26.32-10.36"
      />
    </g>
  </svg>
`;
