import { html } from 'lit';

export const shrub = html`
  <svg
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    viewBox="0 0 290 470"
  >
    <g fill="#7ca03c">
      <path
        d="m271.1 146.3c0 69.62-56.44 126-126 126-69.63 0-126-56.44-126-126 0-69.63 56.44-126 126-126s126 56.38 126 126"
      />
      <path
        d="m160 20.01c0 8.284-6.688 14.99-14.98 14.99-8.284 0-14.98-6.705-14.98-14.99 0-8.269 6.686-14.97 14.98-14.97 8.288 0 14.98 6.706 14.98 14.97"
      />
      <path
        d="m130 23.76c0 8.265-6.724 14.99-15.01 14.99-8.25 0-14.98-6.724-14.98-14.99 0-8.269 6.724-14.97 14.98-14.97 8.289 0 15.01 6.706 15.01 14.97"
      />
      <path
        d="m102.5 35.01c0 8.269-6.724 14.98-14.98 14.98-8.288 0-14.98-6.705-14.98-14.98 0-8.284 6.686-14.99 14.98-14.99 8.251 0 14.98 6.704 14.98 14.99"
      />
      <path
        d="m77.21 50.01c0 8.265-6.688 14.98-14.98 14.98-8.284 0-14.98-6.705-14.98-14.98 0-8.288 6.686-14.98 14.98-14.98 8.288 0 14.98 6.688 14.98 14.98"
      />
      <path
        d="m58.21 71.26c0 8.269-6.72 14.98-14.98 14.98-8.288 0-14.98-6.705-14.98-14.98 0-8.284 6.682-14.98 14.98-14.98 8.255 0 14.98 6.691 14.98 14.98"
      />
      <path
        d="m44.84 97.51c0 8.265-6.688 14.98-14.98 14.98-8.288 0-14.98-6.705-14.98-14.98 0-8.288 6.686-14.99 14.98-14.99 8.288 0 14.98 6.7 14.98 14.99"
      />
      <path
        d="m35.34 123.8c0 8.269-6.724 14.98-14.98 14.98-8.284 0-14.97-6.705-14.97-14.98 0-8.284 6.686-14.98 14.97-14.98 8.251 0 14.98 6.691 14.98 14.98"
      />
      <path
        d="m33.84 153.8c0 8.269-6.688 14.98-14.98 14.98-8.288 0-14.97-6.701-14.97-14.98 0-8.288 6.686-14.99 14.97-14.99 8.288 0 14.98 6.7 14.98 14.99"
      />
      <path
        d="m33.84 153.8c0 8.269-6.688 14.98-14.98 14.98-8.288 0-14.97-6.701-14.97-14.98 0-8.288 6.686-14.99 14.97-14.99 8.288 0 14.98 6.7 14.98 14.99"
      />
      <path
        d="m130 20.01c0 8.284 6.688 14.99 14.98 14.99 8.288 0 14.98-6.705 14.98-14.99 0-8.269-6.686-14.97-14.98-14.97-8.288 0-14.98 6.706-14.98 14.97"
      />
      <path
        d="m160 23.76c0 8.265 6.724 14.99 14.98 14.99 8.288 0 14.98-6.724 14.98-14.99 0-8.269-6.686-14.97-14.98-14.97-8.251 0-14.98 6.706-14.98 14.97"
      />
      <path
        d="m187.6 35.01c0 8.269 6.724 14.98 14.98 14.98 8.288 0 15.01-6.705 15.01-14.98 0-8.284-6.724-14.99-15.01-14.99-8.251 0-14.98 6.704-14.98 14.99"
      />
      <path
        d="m212.7 50.01c0 8.265 6.72 14.98 15.01 14.98 8.25 0 14.98-6.705 14.98-14.98 0-8.288-6.72-14.98-14.98-14.98-8.292 0-15.01 6.688-15.01 14.98"
      />
      <path
        d="m231.8 71.26c0 8.269 6.684 14.98 14.98 14.98 8.288 0 14.98-6.705 14.98-14.98 0-8.284-6.682-14.98-14.98-14.98-8.291 0-14.98 6.691-14.98 14.98"
      />
      <path
        d="m245.1 97.51c0 8.265 6.688 14.98 14.98 14.98 8.288 0 14.98-6.705 14.98-14.98 0-8.288-6.686-14.99-14.98-14.99-8.288 0-14.98 6.7-14.98 14.99"
      />
      <path
        d="m254.6 123.8c0 8.269 6.688 14.98 14.98 14.98 8.284 0 14.98-6.705 14.98-14.98 0-8.284-6.686-14.98-14.98-14.98-8.288 0-14.98 6.691-14.98 14.98"
      />
      <path
        d="m256.1 153.8c0 8.269 6.724 14.98 15.01 14.98 8.25 0 14.98-6.701 14.98-14.98 0-8.288-6.724-14.99-14.98-14.99-8.289 0-15.01 6.7-15.01 14.99"
      />
      <path
        d="m249.6 183.8c0 8.269 6.688 14.98 14.98 14.98 8.25 0 14.98-6.701 14.98-14.98 0-8.269-6.724-14.98-14.98-14.98-8.288 0-14.98 6.706-14.98 14.98"
      />
      <path
        d="m238 211.3c0 8.284 6.724 14.99 14.98 14.99 8.284 0 15.01-6.705 15.01-14.99 0-8.269-6.724-14.98-15.01-14.98-8.251 0-14.98 6.706-14.98 14.98"
      />
      <path
        d="m219.6 233.8c0 8.284 6.724 14.98 15.01 14.98 8.25 0 14.98-6.686 14.98-14.98 0-8.269-6.724-14.98-14.98-14.98-8.289 0-15.01 6.706-15.01 14.98"
      />
      <path
        d="m196.1 252.5c0 8.265 6.724 14.98 14.98 14.98 8.284 0 14.98-6.705 14.98-14.98 0-8.269-6.686-14.98-14.98-14.98-8.251 0-14.98 6.706-14.98 14.98"
      />
      <path
        d="m169.7 266.3c0 8.288 6.724 14.98 14.98 14.98 8.288 0 14.98-6.686 14.98-14.98 0-8.265-6.686-14.98-14.98-14.98-8.251 0-14.98 6.71-14.98 14.98"
      />
      <path
        d="m141.5 272.5c0 8.269 6.688 14.98 14.98 14.98 8.25 0 14.98-6.701 14.98-14.98 0-8.288-6.724-14.99-14.98-14.99-8.288 0-14.98 6.7-14.98 14.99"
      />
      <path
        d="m40.71 183.8c0 8.269-6.724 14.98-14.98 14.98-8.284 0-14.98-6.701-14.98-14.98 0-8.269 6.686-14.98 14.98-14.98 8.251 0 14.98 6.706 14.98 14.98"
      />
      <path
        d="m52.34 211.3c0 8.284-6.724 14.99-14.98 14.99-8.284 0-15.01-6.705-15.01-14.99 0-8.269 6.724-14.98 15.01-14.98 8.251 0 14.98 6.706 14.98 14.98"
      />
      <path
        d="m70.71 233.8c0 8.284-6.688 14.98-14.98 14.98-8.284 0-14.98-6.686-14.98-14.98 0-8.269 6.686-14.98 14.98-14.98 8.288 0 14.98 6.706 14.98 14.98"
      />
      <path
        d="m94.21 252.5c0 8.265-6.724 14.98-14.98 14.98-8.284 0-14.98-6.705-14.98-14.98 0-8.269 6.686-14.98 14.98-14.98 8.251 0 14.98 6.706 14.98 14.98"
      />
      <path
        d="m120.6 266.3c0 8.288-6.724 14.98-14.98 14.98-8.284 0-15.01-6.686-15.01-14.98 0-8.265 6.724-14.98 15.01-14.98 8.251 0 14.98 6.71 14.98 14.98"
      />
      <path
        d="m148.8 272.5c0 8.269-6.684 14.98-14.98 14.98-8.288 0-14.98-6.701-14.98-14.98 0-8.288 6.682-14.99 14.98-14.99 8.291 0 14.98 6.7 14.98 14.99"
      />
    </g>
    <g fill="#634828">
      <path d="m148.5 450h-6.978v-235.8h6.978v235.8z" />
      <path
        d="m96.34 216.3v-24.42h-7.015v24.42c0 17.22 14.03 31.25 31.25 31.25h24.45v-6.978h-24.45c-13.38 0-24.24-10.9-24.24-24.28"
      />
      <path
        d="m192.3 230v-24.42h6.978v24.42c0 17.25-14.02 31.25-31.25 31.25h-24.42v-6.978h24.42c13.38 0 24.28-10.9 24.28-24.28"
      />
    </g>
    <path
      opacity=".4"
      d="m271.1 168.8c8.25 0 14.98-6.701 14.98-14.98 0-8.288-6.724-14.99-14.98-14.99-0.145 0-0.2525 0.0337-0.3612 0.0337v-0.1462c7.74-0.5612 13.88-6.958 13.88-14.86 0-8.284-6.686-14.98-14.98-14.98-0.22 0-0.4025 0.0525-0.655 0.0525 3.706-2.724 6.106-7.048 6.106-11.97 0-8.286-6.686-14.99-14.98-14.99-0.9075 0-1.745 0.1125-2.616 0.2762 2.654-2.71 4.288-6.398 4.288-10.49 0-8.284-6.684-14.98-14.98-14.98-2.434 0-4.689 0.6362-6.725 1.652 1.675-2.396 2.654-5.325 2.654-8.465 0-8.288-6.721-14.98-14.98-14.98-4.47 0-8.432 1.979-11.19 5.071 0.58-1.6 0.9825-3.289 0.9825-5.071 0-8.284-6.724-14.99-15.01-14.99-5.812 0-10.75 3.322-13.26 8.156-0.0375-0.01875-0.0375-0.01875-0.0737-0.03375 0.4388-1.384 0.7288-2.835 0.7288-4.38 0-8.268-6.686-14.97-14.98-14.97-7.338 0-13.41 5.287-14.68 12.25-0.145-0.01875-0.2525-0.03375-0.3975-0.05125 0.0325-0.3275 0.1075-0.6362 0.1075-0.9638 0-8.269-6.688-14.97-14.98-14.97v445.3h3.49v-165.5c2.326 1.474 5.05 2.344 7.994 2.344 8.141 0 14.79-6.541 14.94-14.66 2.471 4.868 7.486 8.231 13.3 8.231 8.288 0 14.98-6.686 14.98-14.98 0-1.726-0.3275-3.379-0.875-4.924 2.69 3.926 7.196 6.486 12.32 6.486 8.282 0 14.98-6.705 14.98-14.98 0-2.816-0.8338-5.432-2.181-7.688 2.728 2.779 6.505 4.506 10.72 4.506 8.25 0 14.98-6.688 14.98-14.98 0-3.398-1.202-6.524-3.126-9.031 1.961 0.9638 4.179 1.544 6.505 1.544 8.284 0 15.01-6.705 15.01-14.99 0-5.869-3.454-10.92-8.395-13.35 0.075-0.145 0.145-0.2712 0.22-0.4175 1.488 0.5062 3.088 0.8338 4.759 0.8338 8.25 0 14.98-6.701 14.98-14.98 0-6.795-4.58-12.47-10.76-14.3 0.0375-0.29 0.075-0.6 0.145-0.89 0.7112 0.1925 1.399 0.3 2.125 0.3"
      fill="#7ca03c"
    />
    <path
      d="m165.6 178.8c0 11.38-9.195 20.58-20.58 20.58-11.37 0-20.56-9.196-20.56-20.58 0-11.35 9.195-20.59 20.56-20.59 11.38 0 20.58 9.234 20.58 20.59"
      fill="#bc3a0c"
    />
    <path
      opacity=".5"
      d="m145 158.8v21.72h-20.46c0.6175 10.81 9.485 19.42 20.46 19.42 11.38 0 20.58-9.195 20.58-20.58 0-11.34-9.2-20.58-20.58-20.58"
      fill="#bc3a0c"
    />
    <path
      d="m145.7 157.5c4.544-4.578 4.544-11.99 0-16.55-4.578-4.581-11.99-4.581-16.58 0l16.58 16.55z"
      fill="#597222"
    />
    <path
      d="m165.6 90.01c0 11.37-9.195 20.56-20.58 20.56-11.37 0-20.56-9.194-20.56-20.56 0-11.38 9.195-20.59 20.56-20.59 11.38 0 20.58 9.212 20.58 20.59"
      fill="#bc3a0c"
    />
    <path
      opacity=".5"
      d="m145 70.01v21.72h-20.46c0.6175 10.81 9.485 19.43 20.46 19.43 11.38 0 20.58-9.195 20.58-20.56 0-11.37-9.2-20.59-20.58-20.59"
      fill="#bc3a0c"
    />
    <path
      d="m145.7 68.76c4.544-4.562 4.544-11.98 0-16.55-4.578-4.559-11.99-4.559-16.58 0l16.58 16.55z"
      fill="#597222"
    />
    <path
      d="m110.3 132.5c0 11.38-9.195 20.59-20.58 20.59-11.37 0-20.6-9.214-20.6-20.59 0-11.35 9.232-20.56 20.6-20.56 11.38 0 20.58 9.209 20.58 20.56"
      fill="#bc3a0c"
    />
    <path
      opacity=".5"
      d="m89.84 111.3v21.71h-20.46c0.6175 10.83 9.485 19.45 20.46 19.45 11.38 0 20.58-9.214 20.58-20.59 0-11.36-9.2-20.58-20.58-20.58"
      fill="#bc3a0c"
    />
    <path
      d="m90.46 111.3c4.581-4.559 4.581-11.98 0-16.54-4.544-4.581-11.96-4.581-16.54 0l16.54 16.54z"
      fill="#597222"
    />
    <path
      d="m223.1 132.5c0 11.38-9.195 20.59-20.58 20.59-11.34 0-20.56-9.214-20.56-20.59 0-11.35 9.232-20.56 20.56-20.56 11.38 0 20.58 9.209 20.58 20.56"
      fill="#bc3a0c"
    />
    <path
      opacity=".5"
      d="m202.5 111.3v21.71h-20.46c0.6175 10.83 9.485 19.45 20.46 19.45 11.38 0 20.58-9.214 20.58-20.59 0-11.36-9.2-20.58-20.58-20.58"
      fill="#bc3a0c"
    />
    <path
      d="m203.2 111.3c4.544-4.559 4.544-11.98 0-16.54-4.578-4.581-11.99-4.581-16.58 0l16.58 16.54z"
      fill="#597222"
    />
    <path
      d="m96.34 382.5 10.83 67.3c0 11.14 9.012 20.19 20.18 20.19h35.5c11.16 0 20.2-9.049 20.2-20.19l10.8-67.3h-97.5z"
      fill="#39592e"
    />
    <path d="m197.7 392.5h-105.4v-17.41h105.4v17.41z" fill="#39592e" />
  </svg>
`;
