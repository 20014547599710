import { msg } from '@lit/localize';
import { LitElement, css, html } from 'lit';
import { Auth } from '~/app/auth';
import { fullProductName } from '~/version';
import { getMessageForError } from '../message-error.js';

const keyMap_ = {
  unknown: () => [msg(`Sorry, it looks like something’s not working right now. Please try again in a few minutes.`)],
  noSuchUser: () => msg(`Sorry, we can’t find an account with that email.`),
  samePassword: () => msg(`Sorry, that's your old password. Please enter a different one.`),
  checkLength: () => [msg(`Sorry, that password is too short. It needs to be eight characters or more.`)],
  passwordMismatch: () => [msg(`That's not the right password for that account.`)],
  passwordMismatchFirst: () => [msg(`Uh oh, that password doesn’t match that account. Please try again.`)],
};

export class LightSignIn extends LitElement {
  static properties = {
    view: { state: true },
    code: { type: String },
    verify: { type: Boolean },
    version: { state: true },
  };
  constructor() {
    super();

    this.view = 'signin';
    this.username = '';
    this.verify = false;
    this.code = '';

    this.version = 0;

    this.views = {
      signin: () =>
        html`<onc-check-account .subject=${this.username} @next=${this.checkValidation}></onc-check-account>`,
      password: () =>
        html`<onc-check-password .subject=${this.username} @forgot=${this.onForgot}></onc-check-password>`,
      verify: () => html`<onc-verify-account .subject=${this.username} .code=${this.code}></onc-verify-account>`,
      forgot: () => html`
        <onc-forgot-password .subject=${this.username} @verify=${() => (this.view = 'verify')}></onc-forgot-password>
      `,
    };
  }

  // createRenderRoot() {
  //   return /** @type {HTMLElement} */ (document.body.querySelector('#login'));
  // }

  createRenderRoot() {
    return this;
  }

  render() {
    return html`
      <form action="" @submit=${this.onSubmit} class="sign-in">
        <div class="sign-in-background"></div>
        <div class="sign-in-page-wrapper">
          <div class="sign-in-grid-wrapper">
            <div class="sign-in-content-wrapper">
              <div class="sign-in sign-in-content">
                <div class="sign-in-info">
                  <div class="sign-in-info-logo">
                    <img class="lsi-top-app-bar-logo" src="/media/app.128.png" />
                  </div>
                  <div class="sign-in-info-title">${fullProductName}</div>
                </div>

                ${this.views[this.view]()}
              </div>
            </div>

            <div class="sign-in-close">
              <md-icon-button id="closebutton" @click=${this.onClose} class="sign-in-close-action "
                ><md-icon>clear</md-icon>
              </md-icon-button>
            </div>
          </div>
        </div>
      </form>
    `;
  }

  /**
   * @param {Event} event
   */
  onSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  connectedCallback() {
    super.connectedCallback();

    if (this.code || this.verify) {
      this.view = 'verify';
    }
  }

  disconnectedCallback() {
    // const myroot = document.body.querySelector('#login');

    // const myrealroot = /** @type {HTMLElement} */ (this.renderRoot);
    // myrealroot.innerHTML = '';
    super.disconnectedCallback();
  }

  onForgot = () => {
    this.view = 'forgot';
  };

  /**
   *
   * @param {Event} event
   */
  onClose(event) {
    event.preventDefault();
    if (this.view === 'forgot') {
      this.view = 'signin';
    } else {
      window.history.back();
    }
  }

  async sendVerifyAccount({ subject, resend }) {
    return Auth.sendVerifyAccount({ subject, resend }).catch(error => {
      const { messages } = getMessageForError(error, keyMap_);
      this.codeMessages = messages;
    });
  }

  async checkValidation({ detail: { status, username } }) {
    this.username = username;

    if (status === 'pending') {
      await this.sendVerifyAccount({ subject: username, resend: false });
      this.view = 'verify';
    } else {
      this.view = 'password';
    }
  }
}

const OncLightSignIn = class OncLightSignIn extends LightSignIn {
  static styles = css`
    *,
    ::before,
    ::after {
      box-sizing: border-box;
    }

    .main-content {
      --md-sys-color-secondary-container: #d0d0d0;

      padding: 0 16px;
    }

    .top-app-bar-logo {
      width: 32px;
    }

    .sign-in-page-wrapper {
      position: relative;
      z-index: 2;
      max-width: 1280px;
      margin: 0 auto;
    }

    .sign-in-grid-wrapper {
      display: flex;
      flex: 1 1 auto;
    }

    .sign-in-content-wrapper {
      width: 100%;
      max-width: 768px;
      min-height: auto;
      margin: 64px 0;

      background-color: white;
    }

    .sign-in-background {
      position: fixed;
      z-index: 1;
      inset: 0;

      background: rgb(36 24 0);
      background: linear-gradient(0deg, rgb(36 24 0 / 100%) 0%, rgb(121 78 9 / 100%) 35%, rgb(255 179 0 / 100%) 100%);
      background: var(--mdc-theme-background, #f4f4f4);
    }

    .sign-in {
      width: 100%;
      padding: 32px;
    }

    .sign-in-content {
      display: flex;
      flex-direction: column;
    }

    .sign-in-close-action {
      position: fixed;
      top: 16px;
      right: 16px;
    }

    .sign-in-info {
      display: flex;
      align-items: center;
    }

    .sign-in-info-title {
      margin-left: 1em;
      font-size: 22px;
    }
  `;
};
customElements.define('onc-light-sign-in', OncLightSignIn);
