import { msg } from '@lit/localize';
import { html, LitElement } from 'lit';

export class WarningDialog extends LitElement {
  static properties = {
    open: { type: Boolean },
    acceptLabel: { type: String },
    dismissLabel: { type: String },
  };
  constructor() {
    super();
    /**
     * Opens the dialog when set to `true` and closes it when set to `false`.
     */
    this.open = false;

    this.acceptLabel = '';
    this.dismissLabel = '';
  }

  show() {
    this.open = true;
  }

  render() {
    return html`
      <md-dialog .open=${this.open} @closed=${this.handleClosed}>
        <slot slot="headline" name="headline"></slot>
        <form id="form-id" method="dialog" slot="content"><slot></slot></form>

        <div slot="actions">
          <md-text-button form="form-id" class="onc-button" value="dismiss">${this.dismissLabel}</md-text-button>

          <md-text-button tabindex="0" form="form-id" value="accept" class="mdc-dialog__button onc-button"
            >${this.acceptLabel}
          </md-text-button>
        </div>
      </md-dialog>
    `;
  }

  handleClosed({ target: { returnValue } }) {
    this.returnValue = returnValue;
    this.dispatchEvent(new Event('closed'));
    this.open = false;
  }
}

const OncWarningDialog = class OncWarningDialog extends WarningDialog {
  constructor() {
    super();
    this.acceptLabel = msg('Save');
    this.dismissLabel = msg(`Don't save`);
  }
};

const OncDeleteDialog = class OncDeleteDialog extends WarningDialog {
  constructor() {
    super();
    this.acceptLabel = msg('Delete');
    this.dismissLabel = msg(`Cancel`);
  }
};

export { OncDeleteDialog, OncWarningDialog };

customElements.define('onc-warning-dialog', OncWarningDialog);
customElements.define('onc-delete-dialog', OncDeleteDialog);
