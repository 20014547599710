import { FarmGameSettings } from '@tasks/farm-game-settings';
import { MemoryDemo } from './memory-demo';
import { MemoryDurations } from './memory-durations';
import { MemoryPhaseSetting } from './memory-phase-setting';

/**
 * @typedef { import('./record-types.js').MemoryTaskRecord}  MemoryTaskRecord
 */

export class MemorySettings extends FarmGameSettings {
  /**
   * @param {MemoryTaskRecord} taskRecord
   */
  constructor(taskRecord) {
    super(taskRecord);

    this.durations_ = new MemoryDurations(taskRecord.model.durations);

    this.phases_ = taskRecord.model.phases.map(phase => new MemoryPhaseSetting(phase));
    this.demo_ = new MemoryDemo(taskRecord.model.demo);

    this.feedbackType = taskRecord.model.feedbackType;

    this.phaseIndex = 0;
  }

  get durations() {
    return this.durations_;
  }

  get phases() {
    return this.phases_;
  }

  get demo() {
    return this.demo_;
  }

  get currentPhase() {
    return this.phases_[this.phaseIndex];
  }
}
