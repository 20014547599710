import { Task } from '@lit/task';
import { shuffle } from '@ounce/onc';
import { LitElement, html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { graphics } from '~/graphics/index.js';

export class MemoryRewards extends LitElement {
  static properties = { rewardItems: { type: Object }, showLayout: { type: Boolean }, uiRewards: { state: true } };

  constructor() {
    super();

    // /** @type {Object[]} */
    this.items = [];
    this.rewardItems = {};
    this.rewards = [];
    this.uiRewards = [];
    this.rewardCount = 0;
    this.showLayout = false;
    this.getTrials = new Task(this, {
      task: async () => {
        this.rewards = shuffle.getRandomSubarray(await this.makeUIItems(this.rewardItems));

        this.rewardCount = this.rewardItems.sheep.rewards.length + this.rewardItems.chicken.rewards.length;

        window.requestAnimationFrame(t => this.step(t));
      },
      args: () => [],
    });
  }

  step(timestamp) {
    if (this.start === undefined) {
      this.start = timestamp;
    }
    const elapsed = timestamp - this.start;

    if (this.previousTimestamp !== timestamp) {
      const count = Math.min(Math.trunc(elapsed / 200), this.rewardCount);
      const uiCount = count - this.uiRewards.length;

      if (uiCount > 0) {
        const begin = this.uiRewards.length;

        this.uiRewards = [...this.uiRewards, ...this.rewards.slice(begin, begin + uiCount)];

        if (this.uiRewards.length === this.rewardCount) {
          this.done = true;
        }
      } else if (this.rewardCount <= 0) {
        this.done = true;
      }
    }

    this.previousTimestamp = timestamp;
    if (this.done) {
      this.dispatchEvent(new Event('next'));
    } else {
      window.requestAnimationFrame(t => this.step(t));
    }
  }

  render() {
    return html`${this.getTrials.render({
      complete: () => {
        return html`${this.renderRewards()}`;
      },
    })}`;
  }

  renderRewards() {
    const border = this.showLayout;
    const rewards = this.uiRewards.map(
      item => html`<onc-game-item class=${classMap({ border, alt: 1 })} .item=${item}>${item.icon}</onc-game-item>`,
    );
    return html` <div class="content">${rewards}</div> `;
  }

  async makeUIItems(items) {
    const { sheep, chicken } = items;

    const uiItems = [];

    for (const { x, y, w, h, graphic } of sheep.rewards) {
      const [key, value] = graphic.split(':', 2);

      const icon = graphics[key][value];

      uiItems.push({
        top: y / 100,
        left: x / 100,
        width: w,
        height: h,
        icon,
      });
    }

    for (const { x, y, w, h, graphic } of chicken.rewards) {
      const [key, value] = graphic.split(':', 2);

      const icon = graphics[key][value];

      uiItems.push({
        top: y / 100,
        left: x / 100,
        width: w,
        height: h,
        icon,
      });
    }

    return uiItems;
  }
}
const OncMemoryRewards = class OncMemoryRewards extends MemoryRewards {};
customElements.define('onc-memory-rewards', OncMemoryRewards);
