import { html } from 'lit';

export const egg = html`
  <svg
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    viewBox="0 0 220 290"
  >
    <path
      d="m216.6 151.5c-3.9-52.99-39.7-144.6-106.6-144.6-61.3 0-103.2 91.7-106.6 144.6-6.802 106.4 57.21 138.5 106.6 138.5 48.08 0 114.1-35.28 106.5-138.5"
      fill="#e8a38b"
    />
    <g transform="translate(2.582 6.576)" fill="#f00">
      <circle opacity=".403" cy="131.7" cx="172.8" r="11" />
      <circle opacity=".403" cy="166.8" cx="65.86" r="13.5" />
      <circle opacity=".403" cy="229.1" cx="145.9" r="11.5" />
      <circle opacity=".403" cy="59.55" cx="129.9" r="9.999" />
      <circle opacity=".403" cy="166.8" cx="123.1" r="9.749" />
      <circle opacity=".403" cy="227.2" cx="84.41" r="7.499" />
      <circle opacity=".403" cy="110" cx="60.22" r="6.999" />
      <circle opacity=".403" cy="121.1" cx="24.77" r="4.75" />
      <circle opacity=".403" cy="92.63" cx="132.5" r="4" />
      <circle opacity=".403" cy="70.32" cx="79.7" r="3.5" />
    </g>
  </svg>
`;
