import { ContextConsumer } from '@lit/context';
import { msg } from '@lit/localize';
import { differenceInHours } from 'date-fns';
import { LitElement, css, html, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { productName } from '~/version.js';
import { resultsContext, storeContext } from './contexts';

export class InitApp extends LitElement {
  static properties = {
    showTitle: { state: true },
  };

  titleTimer;

  constructor() {
    super();
    this.showTitle = false;

    new ContextConsumer(this, { context: storeContext, callback: ({ dataStore }) => (this.dataStore = dataStore) });
    new ContextConsumer(this, { context: resultsContext, callback: value => (this.results = value) });
  }

  render() {
    return html`
      <div class=${classMap({ 'app-init': 1, ['app-init-show']: this.showTitle })}>${this.renderChecking()}</div>
    `;
  }

  renderChecking() {
    if (!this.showTitle) {
      return nothing;
    }
    return html`
      <div class="app-content-title">
        <p><b>${productName}</b> - ${msg('Checking your details')}</p>
      </div>
      <div class="app-content-progress">
        <div class="app-content-progress-bar">
          <md-circular-progress indeterminate></md-circular-progress>
        </div>
      </div>
    `;
  }

  async connectedCallback() {
    super.connectedCallback();

    this.titleTimer = setTimeout(() => (this.showTitle = true), 500);

    await this.checkResults();

    this.dispatchEvent(new CustomEvent('init-app', { detail: { type: 'initialised' } }));
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    clearTimeout(this.titleTimer);
  }

  checkResults = async () => {
    const allResults = await this.results.getAllResults();
    const clearable = [];
    const now = new Date();

    if (allResults?.length > 0) {
      for (const results of allResults) {
        // @ts-ignore
        const { state, sessionInfo: { resumable } = {}, taskKey, created } = results;

        if (!taskKey.endsWith('state')) {
          const difference = differenceInHours(now, created);
          if (difference > 24) {
            clearable.push(taskKey);
          } else {
            if (!resumable) {
              if (state !== 'prestart' && state !== 'pending') {
                results.state = 'abandoned';
              }

              clearable.push(taskKey);
            }
          }
          await this.dataStore.save({ token: 'wiffle', value: results });
        }
      }
    }

    if (clearable.length > 0) {
      for (const taskKey of clearable) {
        await this.results.deleteResults(taskKey);
      }
    }
  };
}

const OncInitApp = class OncInitApp extends InitApp {
  static styles = css`
    :host {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .app-init {
      flex: 1;
      justify-content: center;

      font-family: 'Work Sans', Roboto, sans-serif;
      color: #fff;

      opacity: 0;
      background-color: #fcfcfc;
      background-image: linear-gradient(180deg, #919191, #fcfcfc);
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-position: 50%;
      background-size: cover;
    }

    .app-init-show {
      opacity: 1;
      transition: all 200ms ease-in;
    }

    .app-content-title {
      font-weight: bold;
      text-align: center;
    }

    .app-content-progress {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 100px;
    }

    .app-content-progress-bar {
      display: inline-block;
    }
  `;
};

customElements.define('onc-init-app', OncInitApp);
