import timestring from 'timestring';

/**
 * @typedef { { name: String, flow:{visible: String, enabled: String} } } TaskModel
 */

/**
 * @typedef { {
 * schemaId: string, _id: string, taskId: string, model: TaskModel
 * }} TaskRecord
 */

/**
 * @description Describes Task specifications
 */
export class GameTask {
  /**
   *
   * @param {TaskRecord} taskSettings
   */
  constructor(taskSettings) {
    if (new.target === GameTask) {
      throw new TypeError('Cannot construct TaskSpec instances directly');
    }
    const {
      model: { flow, name },
      schemaId,
      taskId,
      _id,
    } = taskSettings;

    this.name = name;

    this.schemaId = schemaId;
    this.taskId = taskId;
    this._id = _id;
    this.flow = flow;
  }
}

// ===
// Private functions
// ===

export function getDuration(value, units, asUnits) {
  value = String(value);
  const groups = value
    .toLowerCase()
    .replaceAll(/[^\w+.-]+/g, '')
    .match(/[+-]?[\d.]+[a-z]+/g);
  if (!groups) {
    value = `${value}${units}`;
  }

  return timestring(value, asUnits ?? 'ms');
}

/**
 * @param {{[key:string]:any}} model
 * @param {string[][]} fields
 */
export function fixupDurationValues(model, fields) {
  for (const parts of fields) {
    const [item, units = 's', asUnits] = parts;

    let parent = model;
    const items = item.split(/[.[\]]+/);
    const key = items.pop();

    for (const [index, item] of items.entries()) {
      if (Array.isArray(parent[item]) && items[index + 1] === '$') {
        const newItems = [...items.slice(index + 2), key];

        const newEntries = [];
        for (const entry of parent[item]) {
          const newEntry = fixupDurationValues(entry, [[newItems.join('.'), units, asUnits]]);

          newEntries.push(newEntry);
        }

        parent[item] = newEntries;
        parent = undefined;
        break;
      } else {
        // need to clone the entries as we descend the path
        // to avoid overwriting the original record.
        parent = parent[item] = { ...parent[item] };
      }
    }

    parent?.[key] !== undefined && (parent[key] = getDuration(parent[key], units, asUnits));
  }

  return model;
}
