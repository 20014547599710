import { html } from 'lit';

export const kennel = html`
  <svg
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    enable-background="new"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    viewBox="0 0 480 460"
  >
    <path d="m421.4 459.8h-374.5v-266.1h374.5v266.1z" fill="#89693a" />
    <path d="m461.6 213.5-442.6-1 215-187.6l227.6 188.6z" fill="#89693a" />
    <path
      d="m234 24.91-1.7 1.52v433.3h4.5v-432.5l-2.8-2.3zm38.3 31.7v403.1h4.5v-399.4l-4.5-3.7zm-75.6 0.91-4.5 3.92v398.3h4.5v-402.2zm115.6 32.28v369.9h4.6v-366.1l-4.6-3.81zm-155.6 2.63-4.5 3.92v363.4h4.5v-367.3zm195.7 30.58v336.7h4.5v-333l-4.5-3.7zm-235.9 4.4-4.5 4v328.3h4.5v-332.3zm275.9 28.7v303.6h4.5v-299.8l-4.5-3.8zm-315.7 6.1-4.56 4v293.5h4.56v-297.5zm355.8 27.2v24h4.5v-20.3l-4.5-3.7zm-396 7.8-4.56 4v11.3l4.56 0.1v-15.4z"
      fill="#4a2b0d"
    />
    <path d="m322 459.8h-170.5v-133.2h170.5v133.2z" fill="#382713" />
    <path
      d="m322 321c0 47.06-38.18 85.24-85.28 85.24s-85.28-38.18-85.28-85.24c0-47.1 38.18-85.28 85.28-85.28 47.05 0 85.22 38.18 85.22 85.28"
      fill="#382713"
    />
    <path opacity=".7" d="m239.8 34.76-211.2 179.1l18.25 0.4388v13.31l210.6-178.2-17.62-14.75z" fill="#634828" />
    <path d="m454.5 222.3-220.3-182.7-215.1 182.5-14.53-16.9 229.5-194.5l234.8 194.5-14.25 17.12z" fill="#634828" />
    <path
      d="m277.2 194.8c0 6.074-4.899 10.97-10.94 10.97h-59.04c-6.039 0-10.93-4.898-10.93-10.97v-19.29c0-6.039 4.891-10.94 10.93-10.94h59.04c6.039 0 10.94 4.899 10.94 10.94v19.29z"
      fill="#ffa300"
    />
    <path
      d="m313.2 417.3c0.6738 23.29 19.72 41.92 43.15 41.92h75.94c23.41 0 42.48-18.65 43.15-41.92h-162.2z"
      fill="#d17726"
    />
    <path
      opacity=".35"
      d="m234.2 11.01v449h87.89v-0.2688h99.36v-245.9l22.81 0.0337 10.39 8.622 14.22-17.14-234.6-194.4z"
      fill="#634828"
    />
  </svg>
`;
