import { html, LitElement } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { when } from 'lit/directives/when.js';
import styles from './form-message.scss';

export class FormMessage extends LitElement {
  static properties = {
    messages: { type: [Array, Boolean] },
    warning: { state: true },
  };

  constructor() {
    super();
    this.warning = false;
    this.messages = [];
  }

  render() {
    return html`${when(this.haveMessages(), () => this.renderMessages())}`;
  }

  getFormClasses() {
    return { 'form-message--info': this.warning };
  }

  haveMessages() {
    return Array.isArray(this.messages) ? this.messages?.length > 0 : !(this.messages == true);
  }

  renderMessages() {
    const messages = this.messages.map(message => html`<span>${message}</span>`);
    return html`
      <div class="form-message form-message--error form-message--password ${classMap(this.getFormClasses())}">
        <p class="form-message__text">
          <span> ${messages} </span>
        </p>
      </div>
    `;
  }
}

const OncFormMessage = class OncFormMessage extends FormMessage {
  static styles = [styles];
};
customElements.define('onc-form-message', OncFormMessage);
