export class Project {
  /**
   * @param {{ [x: string]: any; name: any; }} projectRecord
   * @param {any} taskSpecs
   */
  constructor(projectRecord, taskSpecs) {
    const { name, ...projectInfo } = projectRecord;

    /**
     * @type {Map<string, any>}
     */
    const projectTasks = new Map();

    for (const task of taskSpecs) {
      projectTasks.set(task._id, task);
    }

    this.name = name;
    this.tasks_ = projectTasks;
    this.fileSets = projectInfo.fileSets ?? [];
    this.general = projectInfo.general ?? {};

    this.project = projectInfo;
    this.projectId = projectInfo._id;
  }

  get options() {
    return this.project.options;
  }

  get taskIds() {
    return [...this.tasks_.values()].map(({ _id, schemaId }) => ({ _id, schemaId }));
  }

  get firstTask() {
    const [first] = [...this.tasks_.values()];
    return first;
  }

  get projectTasks() {
    return this.tasks_;
  }

  get tasks() {
    return [...this.tasks_.values()];
  }

  get languages() {
    return Object.keys(this.project.languages?.common ?? {});
  }

  get languageTables() {
    return this.project?.languages ?? {};
  }

  /**
   * @param {string} taskId
   */
  getlanguagesForTask(taskId) {
    return this.project.languages?.[taskId] ?? {};
  }

  /**
   * @param {string} taskId
   */
  getById(taskId) {
    return this.tasks_.get(taskId);
  }
}
