/**
 * @typedef {Blob | RequestInfo | URL | ArrayBuffer} URLInfo
 * @param {AudioContext} context
 * @param {URLInfo | URLInfo[]} urlsOrBuffers
 */
export async function loadAndDecode(context, urlsOrBuffers) {
  const audioBuffers = [];

  const items = Array.isArray(urlsOrBuffers) ? urlsOrBuffers : [urlsOrBuffers];

  for (const item of items) {
    const buffer = item instanceof ArrayBuffer ? item : await loadSound(item);

    audioBuffers.push(await context.decodeAudioData(buffer));
  }

  return audioBuffers;
}

// ===
// Private functions
// ===

/**
 * @param {Blob | RequestInfo | URL} url
 */
async function loadSound(url) {
  const isBlob = url instanceof Blob;

  return await (isBlob ? url.arrayBuffer() : fetchUrl(url));
}

/**
 * @param {RequestInfo | URL} url
 */
async function fetchUrl(url) {
  const response = await fetch(url, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'audio/*',
    }),
  });

  return response.arrayBuffer();
}
