import { Store } from '@state/idb-database.js';

let store_;

function setupDatabase() {
  store_ = new Store('queue');
  return store_;
}

function database() {
  return store_;
}

export { database as dataDb, setupDatabase };
