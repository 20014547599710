import { LitElement, css, html } from 'lit';
import { decodeCredential, onConnected } from './google-utils';

export class GoogleIdentity extends LitElement {
  static properties = {
    clientId: { type: String },
    prompt: { type: Boolean },
  };
  constructor() {
    super();

    this.clientId = undefined;
    this.prompt = false;
  }

  get button() {
    return this.renderRoot?.querySelector('.g-btn');
  }

  render() {
    return html`
      <div class="g-btn-wrapper" .class=${['api-loading']}>
        <span id="mybutton" class="g-btn"></span>
      </div>
    `;
  }

  handleCredentialResponse(response) {
    // console.log('Callback is called', response);
    const webToken = decodeCredential(response.credential);
    // console.log('Response payload', webToken);
    // console.log({
    //   email: webToken?.email,
    //   name: webToken?.name,
    //   imageUrl: webToken?.picture,
    //   accessToken: response.credential,
    //   accessTokenExpiration: new Date(webToken?.exp),
    //   provider: 'Google',
    //   error: undefined,
    // });

    this.dispatchEvent(
      new CustomEvent('credential', {
        detail: {
          credential: {
            email: webToken?.email,
            name: webToken?.name,
            imageUrl: webToken?.picture,
            accessToken: response.credential,
            accessTokenExpiration: new Date(webToken?.exp),
            provider: 'Google',
            error: undefined,
          },
        },
      }),
    );
  }

  firstUpdated() {
    const idConfiguration = {
      client_id: this.clientId,
      callback: data => this.handleCredentialResponse(data),
    };
    const options = {
      clientId: this.clientId,
      callback: data => this.handleCredentialResponse(data),
      buttonConfig: { theme: 'outline', size: 'large' },
      prompt: this.prompt,
    };

    onConnected(idConfiguration, this.button, options);
  }

  connectedCallback() {
    super.connectedCallback();

    if (!this.clientId) {
      throw new Error('Prop client id required');
    }
  }
}

const OncGoogleIdentity = class OncGoogleIdentity extends GoogleIdentity {
  static styles = css`
    .g-btn-wrapper {
      display: inline-block;
    }

    .g-btn-wrapper.api-loading {
      pointer-events: none;
      opacity: 0.5;
    }
  `;
};
customElements.define('onc-google-identity', OncGoogleIdentity);
