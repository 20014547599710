import { html } from 'lit';

export const barnTall = html`
  <svg
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    viewBox="0 0 200 270"
  >
    <path d="m45.84 225.7h-14.28v-1.034h14.28v1.034z" fill="#fff" />
    <path d="m187.7 262.5h-175.1v-57.38h175.1v57.38z" fill="#d1c5ac" />
    <path d="m46.2 242.9h-13.96v-24.68h13.96v24.68z" fill="#352613" />
    <path d="m167.7 242.9h-13.95v-24.68h13.95v24.68z" fill="#352613" />
    <path d="m154.9 241.8h11.84v-22.55h-11.84v22.55zm13.95 2.118h-16.08v-26.79h16.08v26.79z" fill="#fff" />
    <path d="m142.7 242.9h-13.95v-24.68h13.95v24.68z" fill="#352613" />
    <g fill="#fff">
      <path d="m129.9 241.8h11.84v-22.55h-11.84v22.55zm13.95 2.118h-16.08v-26.79h16.08v26.79z" />
      <path d="m135.2 242.7h1.022v-24.18h-1.022v24.18z" />
      <path d="m160.3 242.7h1.02v-24.18h-1.02v24.18z" />
      <path d="m153.6 225.7h14.28v-1.034h-14.28v1.034z" />
      <path d="m128.1 225.7h14.26v-1.034h-14.26v1.034z" />
    </g>
    <g fill="#d1c5ac">
      <path d="m12.56 211.2h175.1v-8.111h-175.1v8.111z" />
      <path d="m187.7 209.1h-175.1v-8.11h175.1v8.11z" />
      <path d="m113.7 265.9h-27.2v-2.491h27.2v2.491z" />
      <path d="m86.55 270.1h27.2v-2.504h-27.2v2.504z" />
    </g>
    <g fill="#bf1f00">
      <path
        d="m82.4 214.9c-1.245 0-2.242 1.009-2.242 2.242 0 1.246 0.9975 2.242 2.242 2.242 1.234 0 2.242-0.9962 2.242-2.242 0-1.234-1.009-2.242-2.242-2.242"
      />
      <path
        d="m86.88 214.9c-1.234 0-2.23 1.009-2.23 2.242 0 1.246 0.9962 2.242 2.23 2.242 1.246 0 2.242-0.9962 2.242-2.242 0-1.234-0.9962-2.242-2.242-2.242"
      />
      <path
        d="m91.36 214.9c-1.246 0-2.242 1.009-2.242 2.242 0 1.246 0.9962 2.242 2.242 2.242 1.232 0 2.23-0.9962 2.23-2.242 0-1.234-0.9975-2.242-2.23-2.242"
      />
      <path
        d="m95.82 214.9c-1.234 0-2.242 1.009-2.242 2.242 0 1.246 1.009 2.242 2.242 2.242 1.232 0 2.241-0.9962 2.241-2.242 0-1.234-1.009-2.242-2.241-2.242"
      />
      <path
        d="m100.3 214.9c-1.245 0-2.242 1.009-2.242 2.242 0 1.246 0.9975 2.242 2.242 2.242 1.234 0 2.23-0.9962 2.23-2.242 0-1.234-0.9962-2.242-2.23-2.242"
      />
      <path
        d="m104.8 214.9c-1.234 0-2.242 1.009-2.242 2.242 0 1.246 1.009 2.242 2.242 2.242 1.234 0 2.242-0.9962 2.242-2.242 0-1.234-1.009-2.242-2.242-2.242"
      />
      <path
        d="m109.2 214.9c-1.246 0-2.242 1.009-2.242 2.242 0 1.246 0.9962 2.242 2.242 2.242 1.232 0 2.23-0.9962 2.23-2.242 0-1.234-0.9975-2.242-2.23-2.242"
      />
      <path
        d="m113.7 214.9c-1.232 0-2.241 1.009-2.241 2.242 0 1.246 1.009 2.242 2.241 2.242 1.234 0 2.242-0.9962 2.242-2.242 0-1.234-1.009-2.242-2.242-2.242"
      />
      <path
        d="m118.2 214.9c-1.232 0-2.23 1.009-2.23 2.242 0 1.246 0.9975 2.242 2.23 2.242 1.246 0 2.242-0.9962 2.242-2.242 0-1.234-0.9962-2.242-2.242-2.242"
      />
    </g>
    <path d="m120.3 217.2h-40.24v-6.054h40.24v6.054z" fill="#ff3000" />
    <g fill="#8c7a5a">
      <path
        d="m123.1 258.8c0 0.3988-0.3112 0.7238-0.7088 0.7238h-5.359c-0.3975 0-0.7212-0.325-0.7212-0.7238v-1.245c0-0.3988 0.3238-0.7238 0.7212-0.7238h5.359c0.3975 0 0.7088 0.325 0.7088 0.7238v1.245z"
      />
      <path
        d="m130.6 258.8c0 0.3988-0.3238 0.7238-0.7088 0.7238h-5.37c-0.3862 0-0.71-0.325-0.71-0.7238v-1.245c0-0.3988 0.3238-0.7238 0.71-0.7238h5.37c0.385 0 0.7088 0.325 0.7088 0.7238v1.245z"
      />
      <path
        d="m126.7 261.9c0 0.3975-0.3238 0.7088-0.7088 0.7088h-5.37c-0.3988 0-0.71-0.3112-0.71-0.7088v-1.246c0-0.3988 0.3112-0.7088 0.71-0.7088h5.37c0.385 0 0.7088 0.31 0.7088 0.7088v1.246z"
      />
      <path
        d="m150.6 264.9c0 0.3975-0.3112 0.7088-0.6975 0.7088h-5.37c-0.3975 0-0.7212-0.3112-0.7212-0.7088v-1.259c0-0.3862 0.3238-0.6975 0.7212-0.6975h5.37c0.3862 0 0.6975 0.3112 0.6975 0.6975v1.259z"
      />
      <path
        d="m158.1 264.9c0 0.3975-0.3238 0.7088-0.71 0.7088h-5.37c-0.385 0-0.7088-0.3112-0.7088-0.7088v-1.259c0-0.3862 0.3238-0.6975 0.7088-0.6975h5.37c0.3862 0 0.71 0.3112 0.71 0.6975v1.259z"
      />
      <path
        d="m154.5 261.9c0 0.3975-0.3238 0.7088-0.71 0.7088h-5.37c-0.3988 0-0.71-0.3112-0.71-0.7088v-1.246c0-0.3988 0.3112-0.7088 0.71-0.7088h5.37c0.3862 0 0.71 0.31 0.71 0.7088v1.246z"
      />
      <path
        d="m146.6 268.1c0 0.3988-0.3112 0.7238-0.71 0.7238h-5.37c-0.385 0-0.7088-0.325-0.7088-0.7238v-1.245c0-0.4 0.3238-0.7238 0.7088-0.7238h5.37c0.3988 0 0.71 0.3238 0.71 0.7238v1.245z"
      />
    </g>
    <g fill="#d1c5ac">
      <path
        d="m177.5 224.7c0 0.3862-0.3238 0.6975-0.7225 0.6975h-5.356c-0.3988 0-0.71-0.3112-0.71-0.6975v-1.27c0-0.3875 0.3112-0.6988 0.71-0.6988h5.356c0.3988 0 0.7225 0.3112 0.7225 0.6988v1.27z"
      />
      <path
        d="m158.1 247.9c0 0.385-0.3238 0.6962-0.71 0.6962h-5.37c-0.385 0-0.7088-0.3112-0.7088-0.6962v-1.271c0-0.3862 0.3238-0.6975 0.7088-0.6975h5.37c0.3862 0 0.71 0.3112 0.71 0.6975v1.271z"
      />
      <path
        d="m154.5 214.7c0 0.3862-0.3238 0.71-0.71 0.71h-5.37c-0.3988 0-0.71-0.3238-0.71-0.71v-1.258c0-0.3875 0.3112-0.7112 0.71-0.7112h5.37c0.3862 0 0.71 0.3238 0.71 0.7112v1.258z"
      />
      <path
        d="m146.6 251.1c0 0.3975-0.3112 0.7225-0.71 0.7225h-5.37c-0.385 0-0.7088-0.325-0.7088-0.7225v-1.246c0-0.3988 0.3238-0.7225 0.7088-0.7225h5.37c0.3988 0 0.71 0.3238 0.71 0.7225v1.246z"
      />
      <path
        d="m27.14 236.1c0 0.3862-0.3112 0.71-0.71 0.71h-5.37c-0.385 0-0.7088-0.3238-0.7088-0.71v-1.258c0-0.4 0.3238-0.7112 0.7088-0.7112h5.37c0.3988 0 0.71 0.3112 0.71 0.7112v1.258z"
      />
      <path
        d="m80.52 239.2c0 0.3975-0.3238 0.7088-0.7225 0.7088h-5.356c-0.3988 0-0.71-0.3112-0.71-0.7088v-1.259c0-0.3862 0.3112-0.71 0.71-0.71h5.356c0.3988 0 0.7225 0.3238 0.7225 0.71v1.259z"
      />
      <path
        d="m54.91 233.2c0 0.385-0.3112 0.6962-0.7088 0.6962h-5.37c-0.3862 0-0.71-0.3112-0.71-0.6962v-1.259c0-0.3862 0.3238-0.71 0.71-0.71h5.37c0.3975 0 0.7088 0.3238 0.7088 0.71v1.259z"
      />
      <path
        d="m62.46 247.9c0 0.385-0.3238 0.6962-0.7088 0.6962h-5.37c-0.3988 0-0.71-0.3112-0.71-0.6962v-1.271c0-0.3862 0.3112-0.6975 0.71-0.6975h5.37c0.385 0 0.7088 0.3112 0.7088 0.6975v1.271z"
      />
      <path
        d="m182.2 239.2c0 0.3975-0.3238 0.7088-0.71 0.7088h-5.37c-0.385 0-0.7088-0.3112-0.7088-0.7088v-1.259c0-0.3862 0.3238-0.71 0.7088-0.71h5.37c0.3862 0 0.71 0.3238 0.71 0.71v1.259z"
      />
      <path
        d="m169.9 247.9c0 0.385-0.3238 0.6962-0.7225 0.6962h-5.358c-0.3975 0-0.7212-0.3112-0.7212-0.6962v-1.271c0-0.3862 0.3238-0.6975 0.7212-0.6975h5.358c0.3988 0 0.7225 0.3112 0.7225 0.6975v1.271z"
      />
      <path
        d="m50.89 251.1c0 0.3975-0.3238 0.7225-0.7088 0.7225h-5.37c-0.3988 0-0.71-0.325-0.71-0.7225v-1.246c0-0.3988 0.3112-0.7225 0.71-0.7225h5.37c0.385 0 0.7088 0.3238 0.7088 0.7225v1.246z"
      />
      <path
        d="m62.46 214.5c0 0.3988-0.3238 0.7238-0.7088 0.7238h-5.37c-0.3988 0-0.71-0.325-0.71-0.7238v-1.245c0-0.4 0.3112-0.7238 0.71-0.7238h5.37c0.385 0 0.7088 0.3238 0.7088 0.7238v1.245z"
      />
      <path
        d="m21.9 217.7c0 0.385-0.3238 0.6962-0.71 0.6962h-5.37c-0.3988 0-0.71-0.3112-0.71-0.6962v-1.259c0-0.3988 0.3112-0.71 0.71-0.71h5.37c0.3862 0 0.71 0.3112 0.71 0.71v1.259z"
      />
    </g>
    <g fill="#8c7a5a">
      <path
        d="m185.7 258.8c0 0.3988-0.3238 0.7238-0.7225 0.7238h-5.358c-0.3975 0-0.7088-0.325-0.7088-0.7238v-1.245c0-0.3988 0.3112-0.7238 0.7088-0.7238h5.358c0.3988 0 0.7225 0.325 0.7225 0.7238v1.245z"
      />
      <path
        d="m181.9 261.9c0 0.3975-0.3112 0.7088-0.7088 0.7088h-5.356c-0.4 0-0.7238-0.3112-0.7238-0.7088v-1.246c0-0.3988 0.3238-0.7088 0.7238-0.7088h5.356c0.3975 0 0.7088 0.31 0.7088 0.7088v1.246z"
      />
      <path
        d="m21.58 266.8c0-0.3988-0.3238-0.7238-0.7088-0.7238h-5.37c-0.3862 0-0.71 0.325-0.71 0.7238v1.245c0 0.3988 0.3238 0.7238 0.71 0.7238h5.37c0.385 0 0.7088-0.325 0.7088-0.7238v-1.245z"
      />
      <path
        d="m29.12 266.8c0-0.3988-0.3125-0.7238-0.6975-0.7238h-5.37c-0.3988 0-0.71 0.325-0.71 0.7238v1.245c0 0.3988 0.3112 0.7238 0.71 0.7238h5.37c0.385 0 0.6975-0.325 0.6975-0.7238v-1.245z"
      />
      <path
        d="m25.29 263.7c0-0.385-0.3112-0.6975-0.71-0.6975h-5.358c-0.3988 0-0.71 0.3125-0.71 0.6975v1.259c0 0.3988 0.3112 0.71 0.71 0.71h5.358c0.3988 0 0.71-0.3112 0.71-0.71v-1.259z"
      />
      <path
        d="m49.12 260.7c0-0.3975-0.3112-0.7088-0.7088-0.7088h-5.37c-0.3862 0-0.71 0.3112-0.71 0.7088v1.246c0 0.3988 0.3238 0.7088 0.71 0.7088h5.37c0.3975 0 0.7088-0.31 0.7088-0.7088v-1.246z"
      />
      <path
        d="m56.68 260.7c0-0.3975-0.3238-0.7088-0.7088-0.7088h-5.37c-0.3988 0-0.7225 0.3112-0.7225 0.7088v1.246c0 0.3988 0.3238 0.7088 0.7225 0.7088h5.37c0.385 0 0.7088-0.31 0.7088-0.7088v-1.246z"
      />
      <path
        d="m52.96 263.7c0-0.385-0.3112-0.6975-0.71-0.6975h-5.37c-0.385 0-0.7088 0.3125-0.7088 0.6975v1.259c0 0.3988 0.3238 0.71 0.7088 0.71h5.37c0.3988 0 0.71-0.3112 0.71-0.71v-1.259z"
      />
      <path
        d="m67.11 263.7c0-0.385-0.3112-0.6975-0.71-0.6975h-5.37c-0.3862 0-0.71 0.3125-0.71 0.6975v1.259c0 0.3988 0.3238 0.71 0.71 0.71h5.37c0.3988 0 0.71-0.3112 0.71-0.71v-1.259z"
      />
      <path
        d="m45.09 257.6c0-0.3988-0.3238-0.7238-0.71-0.7238h-5.369c-0.3988 0-0.71 0.325-0.71 0.7238v1.245c0 0.4 0.3112 0.7238 0.71 0.7238h5.369c0.3862 0 0.71-0.3238 0.71-0.7238v-1.245z"
      />
      <path
        d="m84.2 266.8c0-0.3988-0.3238-0.7238-0.7225-0.7238h-5.356c-0.3988 0-0.7225 0.325-0.7225 0.7238v1.245c0 0.3988 0.3238 0.7238 0.7225 0.7238h5.356c0.3988 0 0.7225-0.325 0.7225-0.7238v-1.245z"
      />
      <path
        d="m80.36 263.7c0-0.385-0.3125-0.6975-0.71-0.6975h-5.37c-0.3862 0-0.71 0.3125-0.71 0.6975v1.259c0 0.3988 0.3238 0.71 0.71 0.71h5.37c0.3975 0 0.71-0.3112 0.71-0.71v-1.259z"
      />
    </g>
    <path d="m69.89 244.7v-0.3375c0-0.9212-0.7588-1.681-1.681-1.681h-3.974v4.871h6.565v-2.852h-0.91z" fill="#5fa33b" />
    <path d="m33.29 241.8h11.85v-22.55h-11.85v22.55zm13.96 2.118h-16.09v-26.79h16.09v26.79z" fill="#fff" />
    <g fill="#5fa33b">
      <path d="m44.86 244.7v-0.3375c0-0.9212-0.7588-1.681-1.681-1.681h-3.974v4.871h6.565v-2.852h-0.91z" />
      <path d="m166.6 244.7v-0.3375c0-0.9212-0.7475-1.681-1.681-1.681h-3.962v4.871h6.554v-2.852h-0.91z" />
      <path d="m141.5 244.7v-0.3375c0-0.9212-0.7475-1.681-1.682-1.681h-3.961v4.871h6.554v-2.852h-0.91z" />
    </g>
    <path d="m90.95 243.9h-1.245v-4.585h1.245v4.585z" fill="#ddbb7f" />
    <path d="m88.69 261.3h22.86v-42.01h-22.86v42.01zm24.98 2.118h-27.1v-46.24h27.1v46.24z" fill="#3d2e1a" />
    <path d="m71.22 242.9h-13.96v-24.68h13.96v24.68z" fill="#352613" />
    <g fill="#fff">
      <path d="m58.32 241.8h11.85v-22.55h-11.85v22.55zm13.96 2.118h-16.09v-26.79h16.09v26.79z" />
      <path d="m39.72 242.7h-1.022v-24.18h1.022v24.18z" />
      <path d="m64.75 242.7h-1.02v-24.18h1.02v24.18z" />
      <path d="m71.36 225.7h-14.28v-1.034h14.28v1.034z" />
    </g>
    <g fill="#d1c5ac">
      <path d="m187.7 211.2h-175.1v-8.11h175.1v8.11z" />
      <path d="m187.7 209.1h-175.1v-8.11h175.1v8.11z" />
      <path d="m113.7 265.9h-27.2v-2.491h27.2v2.491z" />
      <path d="m113.7 270.1h-27.2v-2.504h27.2v2.504z" />
    </g>
    <path d="m112 234.1h-23.82v-1.819h23.82v1.819z" fill="#3d2e1a" />
    <path d="m112 248.9h-23.82v-1.806h23.82v1.806z" fill="#3d2e1a" />
    <path d="m82.92 224.6h-1.32v-8.185h1.32v8.185z" fill="#684d29" />
    <path d="m118.9 224.6h-1.32v-8.185h1.32v8.185z" fill="#684d29" />
    <g fill="#bf1f00">
      <path
        d="m82.4 214.9c-1.245 0-2.242 1.009-2.242 2.242 0 1.246 0.9975 2.242 2.242 2.242 1.234 0 2.242-0.9962 2.242-2.242 0-1.234-1.009-2.242-2.242-2.242"
      />
      <path
        d="m86.88 214.9c-1.234 0-2.23 1.009-2.23 2.242 0 1.246 0.9962 2.242 2.23 2.242 1.246 0 2.242-0.9962 2.242-2.242 0-1.234-0.9962-2.242-2.242-2.242"
      />
      <path
        d="m91.36 214.9c-1.246 0-2.242 1.009-2.242 2.242 0 1.246 0.9962 2.242 2.242 2.242 1.232 0 2.23-0.9962 2.23-2.242 0-1.234-0.9975-2.242-2.23-2.242"
      />
      <path
        d="m95.82 214.9c-1.234 0-2.242 1.009-2.242 2.242 0 1.246 1.009 2.242 2.242 2.242 1.232 0 2.241-0.9962 2.241-2.242 0-1.234-1.009-2.242-2.241-2.242"
      />
      <path
        d="m100.3 214.9c-1.245 0-2.242 1.009-2.242 2.242 0 1.246 0.9975 2.242 2.242 2.242 1.234 0 2.23-0.9962 2.23-2.242 0-1.234-0.9962-2.242-2.23-2.242"
      />
      <path
        d="m104.8 214.9c-1.234 0-2.242 1.009-2.242 2.242 0 1.246 1.009 2.242 2.242 2.242 1.234 0 2.242-0.9962 2.242-2.242 0-1.234-1.009-2.242-2.242-2.242"
      />
      <path
        d="m109.2 214.9c-1.246 0-2.242 1.009-2.242 2.242 0 1.246 0.9962 2.242 2.242 2.242 1.232 0 2.23-0.9962 2.23-2.242 0-1.234-0.9975-2.242-2.23-2.242"
      />
      <path
        d="m113.7 214.9c-1.232 0-2.241 1.009-2.241 2.242 0 1.246 1.009 2.242 2.241 2.242 1.234 0 2.242-0.9962 2.242-2.242 0-1.234-1.009-2.242-2.242-2.242"
      />
      <path
        d="m118.2 214.9c-1.232 0-2.23 1.009-2.23 2.242 0 1.246 0.9975 2.242 2.23 2.242 1.246 0 2.242-0.9962 2.242-2.242 0-1.234-0.9962-2.242-2.242-2.242"
      />
    </g>
    <path d="m120.3 217.2h-40.24v-6.054h40.24v6.054z" fill="#ff3000" />
    <g fill="#8c7a5a">
      <path
        d="m123.1 258.8c0 0.3988-0.3112 0.7238-0.7088 0.7238h-5.359c-0.3975 0-0.7212-0.325-0.7212-0.7238v-1.245c0-0.3988 0.3238-0.7238 0.7212-0.7238h5.359c0.3975 0 0.7088 0.325 0.7088 0.7238v1.245z"
      />
      <path
        d="m130.6 258.8c0 0.3988-0.3238 0.7238-0.7088 0.7238h-5.37c-0.3862 0-0.71-0.325-0.71-0.7238v-1.245c0-0.3988 0.3238-0.7238 0.71-0.7238h5.37c0.385 0 0.7088 0.325 0.7088 0.7238v1.245z"
      />
      <path
        d="m126.7 261.9c0 0.3975-0.3238 0.7088-0.7088 0.7088h-5.37c-0.3988 0-0.71-0.3112-0.71-0.7088v-1.246c0-0.3988 0.3112-0.7088 0.71-0.7088h5.37c0.385 0 0.7088 0.31 0.7088 0.7088v1.246z"
      />
      <path
        d="m150.6 264.9c0 0.3975-0.3112 0.7088-0.6975 0.7088h-5.37c-0.3975 0-0.7212-0.3112-0.7212-0.7088v-1.259c0-0.3862 0.3238-0.6975 0.7212-0.6975h5.37c0.3862 0 0.6975 0.3112 0.6975 0.6975v1.259z"
      />
      <path
        d="m158.1 264.9c0 0.3975-0.3238 0.7088-0.71 0.7088h-5.37c-0.385 0-0.7088-0.3112-0.7088-0.7088v-1.259c0-0.3862 0.3238-0.6975 0.7088-0.6975h5.37c0.3862 0 0.71 0.3112 0.71 0.6975v1.259z"
      />
      <path
        d="m154.5 261.9c0 0.3975-0.3238 0.7088-0.71 0.7088h-5.37c-0.3988 0-0.71-0.3112-0.71-0.7088v-1.246c0-0.3988 0.3112-0.7088 0.71-0.7088h5.37c0.3862 0 0.71 0.31 0.71 0.7088v1.246z"
      />
      <path
        d="m146.6 268.1c0 0.3988-0.3112 0.7238-0.71 0.7238h-5.37c-0.385 0-0.7088-0.325-0.7088-0.7238v-1.245c0-0.4 0.3238-0.7238 0.7088-0.7238h5.37c0.3988 0 0.71 0.3238 0.71 0.7238v1.245z"
      />
    </g>
    <g fill="#d1c5ac">
      <path
        d="m177.5 224.7c0 0.3862-0.3238 0.6975-0.7225 0.6975h-5.356c-0.3988 0-0.71-0.3112-0.71-0.6975v-1.27c0-0.3875 0.3112-0.6988 0.71-0.6988h5.356c0.3988 0 0.7225 0.3112 0.7225 0.6988v1.27z"
      />
      <path
        d="m158.1 247.9c0 0.385-0.3238 0.6962-0.71 0.6962h-5.37c-0.385 0-0.7088-0.3112-0.7088-0.6962v-1.271c0-0.3862 0.3238-0.6975 0.7088-0.6975h5.37c0.3862 0 0.71 0.3112 0.71 0.6975v1.271z"
      />
      <path
        d="m154.5 214.7c0 0.3862-0.3238 0.71-0.71 0.71h-5.37c-0.3988 0-0.71-0.3238-0.71-0.71v-1.258c0-0.3875 0.3112-0.7112 0.71-0.7112h5.37c0.3862 0 0.71 0.3238 0.71 0.7112v1.258z"
      />
      <path
        d="m146.6 251.1c0 0.3975-0.3112 0.7225-0.71 0.7225h-5.37c-0.385 0-0.7088-0.325-0.7088-0.7225v-1.246c0-0.3988 0.3238-0.7225 0.7088-0.7225h5.37c0.3988 0 0.71 0.3238 0.71 0.7225v1.246z"
      />
      <path
        d="m27.14 236.1c0 0.3862-0.3112 0.71-0.71 0.71h-5.37c-0.385 0-0.7088-0.3238-0.7088-0.71v-1.258c0-0.4 0.3238-0.7112 0.7088-0.7112h5.37c0.3988 0 0.71 0.3112 0.71 0.7112v1.258z"
      />
      <path
        d="m80.52 239.2c0 0.3975-0.3238 0.7088-0.7225 0.7088h-5.356c-0.3988 0-0.71-0.3112-0.71-0.7088v-1.259c0-0.3862 0.3112-0.71 0.71-0.71h5.356c0.3988 0 0.7225 0.3238 0.7225 0.71v1.259z"
      />
      <path
        d="m54.91 233.2c0 0.385-0.3112 0.6962-0.7088 0.6962h-5.37c-0.3862 0-0.71-0.3112-0.71-0.6962v-1.259c0-0.3862 0.3238-0.71 0.71-0.71h5.37c0.3975 0 0.7088 0.3238 0.7088 0.71v1.259z"
      />
      <path
        d="m62.46 247.9c0 0.385-0.3238 0.6962-0.7088 0.6962h-5.37c-0.3988 0-0.71-0.3112-0.71-0.6962v-1.271c0-0.3862 0.3112-0.6975 0.71-0.6975h5.37c0.385 0 0.7088 0.3112 0.7088 0.6975v1.271z"
      />
      <path
        d="m182.2 239.2c0 0.3975-0.3238 0.7088-0.71 0.7088h-5.37c-0.385 0-0.7088-0.3112-0.7088-0.7088v-1.259c0-0.3862 0.3238-0.71 0.7088-0.71h5.37c0.3862 0 0.71 0.3238 0.71 0.71v1.259z"
      />
      <path
        d="m169.9 247.9c0 0.385-0.3238 0.6962-0.7225 0.6962h-5.358c-0.3975 0-0.7212-0.3112-0.7212-0.6962v-1.271c0-0.3862 0.3238-0.6975 0.7212-0.6975h5.358c0.3988 0 0.7225 0.3112 0.7225 0.6975v1.271z"
      />
      <path
        d="m50.89 251.1c0 0.3975-0.3238 0.7225-0.7088 0.7225h-5.37c-0.3988 0-0.71-0.325-0.71-0.7225v-1.246c0-0.3988 0.3112-0.7225 0.71-0.7225h5.37c0.385 0 0.7088 0.3238 0.7088 0.7225v1.246z"
      />
      <path
        d="m62.46 214.5c0 0.3988-0.3238 0.7238-0.7088 0.7238h-5.37c-0.3988 0-0.71-0.325-0.71-0.7238v-1.245c0-0.4 0.3112-0.7238 0.71-0.7238h5.37c0.385 0 0.7088 0.3238 0.7088 0.7238v1.245z"
      />
      <path
        d="m21.9 217.7c0 0.385-0.3238 0.6962-0.71 0.6962h-5.37c-0.3988 0-0.71-0.3112-0.71-0.6962v-1.259c0-0.3988 0.3112-0.71 0.71-0.71h5.37c0.3862 0 0.71 0.3112 0.71 0.71v1.259z"
      />
    </g>
    <g fill="#8c7a5a">
      <path
        d="m185.7 258.8c0 0.3988-0.3238 0.7238-0.7225 0.7238h-5.358c-0.3975 0-0.7088-0.325-0.7088-0.7238v-1.245c0-0.3988 0.3112-0.7238 0.7088-0.7238h5.358c0.3988 0 0.7225 0.325 0.7225 0.7238v1.245z"
      />
      <path
        d="m181.9 261.9c0 0.3975-0.3112 0.7088-0.7088 0.7088h-5.356c-0.4 0-0.7238-0.3112-0.7238-0.7088v-1.246c0-0.3988 0.3238-0.7088 0.7238-0.7088h5.356c0.3975 0 0.7088 0.31 0.7088 0.7088v1.246z"
      />
      <path
        d="m21.58 266.8c0-0.3988-0.3238-0.7238-0.7088-0.7238h-5.37c-0.3862 0-0.71 0.325-0.71 0.7238v1.245c0 0.3988 0.3238 0.7238 0.71 0.7238h5.37c0.385 0 0.7088-0.325 0.7088-0.7238v-1.245z"
      />
      <path
        d="m29.12 266.8c0-0.3988-0.3125-0.7238-0.6975-0.7238h-5.37c-0.3988 0-0.71 0.325-0.71 0.7238v1.245c0 0.3988 0.3112 0.7238 0.71 0.7238h5.37c0.385 0 0.6975-0.325 0.6975-0.7238v-1.245z"
      />
      <path
        d="m25.29 263.7c0-0.385-0.3112-0.6975-0.71-0.6975h-5.358c-0.3988 0-0.71 0.3125-0.71 0.6975v1.259c0 0.3988 0.3112 0.71 0.71 0.71h5.358c0.3988 0 0.71-0.3112 0.71-0.71v-1.259z"
      />
      <path
        d="m49.12 260.7c0-0.3975-0.3112-0.7088-0.7088-0.7088h-5.37c-0.3862 0-0.71 0.3112-0.71 0.7088v1.246c0 0.3988 0.3238 0.7088 0.71 0.7088h5.37c0.3975 0 0.7088-0.31 0.7088-0.7088v-1.246z"
      />
      <path
        d="m56.68 260.7c0-0.3975-0.3238-0.7088-0.7088-0.7088h-5.37c-0.3988 0-0.7225 0.3112-0.7225 0.7088v1.246c0 0.3988 0.3238 0.7088 0.7225 0.7088h5.37c0.385 0 0.7088-0.31 0.7088-0.7088v-1.246z"
      />
      <path
        d="m52.96 263.7c0-0.385-0.3112-0.6975-0.71-0.6975h-5.37c-0.385 0-0.7088 0.3125-0.7088 0.6975v1.259c0 0.3988 0.3238 0.71 0.7088 0.71h5.37c0.3988 0 0.71-0.3112 0.71-0.71v-1.259z"
      />
      <path
        d="m67.11 263.7c0-0.385-0.3112-0.6975-0.71-0.6975h-5.37c-0.3862 0-0.71 0.3125-0.71 0.6975v1.259c0 0.3988 0.3238 0.71 0.71 0.71h5.37c0.3988 0 0.71-0.3112 0.71-0.71v-1.259z"
      />
      <path
        d="m45.09 257.6c0-0.3988-0.3238-0.7238-0.71-0.7238h-5.369c-0.3988 0-0.71 0.325-0.71 0.7238v1.245c0 0.4 0.3112 0.7238 0.71 0.7238h5.369c0.3862 0 0.71-0.3238 0.71-0.7238v-1.245z"
      />
      <path
        d="m84.2 266.8c0-0.3988-0.3238-0.7238-0.7225-0.7238h-5.356c-0.3988 0-0.7225 0.325-0.7225 0.7238v1.245c0 0.3988 0.3238 0.7238 0.7225 0.7238h5.356c0.3988 0 0.7225-0.325 0.7225-0.7238v-1.245z"
      />
      <path
        d="m80.36 263.7c0-0.385-0.3125-0.6975-0.71-0.6975h-5.37c-0.3862 0-0.71 0.3125-0.71 0.6975v1.259c0 0.3988 0.3238 0.71 0.71 0.71h5.37c0.3975 0 0.71-0.3112 0.71-0.71v-1.259z"
      />
    </g>
    <g fill="#5fa33b">
      <path d="m69.89 244.7v-0.3375c0-0.9212-0.7588-1.681-1.681-1.681h-3.974v4.871h6.565v-2.852h-0.91z" />
      <path d="m44.86 244.7v-0.3375c0-0.9212-0.7588-1.681-1.681-1.681h-3.974v4.871h6.565v-2.852h-0.91z" />
      <path d="m166.6 244.7v-0.3375c0-0.9212-0.7475-1.681-1.681-1.681h-3.962v4.871h6.554v-2.852h-0.91z" />
      <path d="m141.5 244.7v-0.3375c0-0.9212-0.7475-1.681-1.682-1.681h-3.961v4.871h6.554v-2.852h-0.91z" />
    </g>
    <path d="m90.95 243.9h-1.245v-4.585h1.245v4.585z" fill="#ddbb7f" />
    <g fill="#d1c5ac">
      <path d="m12.56 211.2h175.1v-8.111h-175.1v8.111z" />
      <path d="m187.7 209.1h-175.1v-8.11h175.1v8.11z" />
      <path d="m113.7 265.9h-27.2v-2.491h27.2v2.491z" />
      <path d="m86.55 270.1h27.2v-2.504h-27.2v2.504z" />
    </g>
    <g fill="#bf1f00">
      <path
        d="m82.4 214.9c-1.245 0-2.242 1.009-2.242 2.242 0 1.246 0.9975 2.242 2.242 2.242 1.234 0 2.242-0.9962 2.242-2.242 0-1.2-1.01-2.2-2.24-2.2"
      />
      <path
        d="m86.88 214.9c-1.234 0-2.23 1.009-2.23 2.242 0 1.246 0.9962 2.242 2.23 2.242 1.246 0 2.242-0.9962 2.242-2.242 0-1.2-0.99-2.2-2.24-2.2"
      />
      <path
        d="m91.36 214.9c-1.246 0-2.242 1.009-2.242 2.242 0 1.246 0.9962 2.242 2.242 2.242 1.232 0 2.23-0.9962 2.23-2.242 0-1.2-1-2.2-2.23-2.2"
      />
      <path
        d="m95.82 214.9c-1.234 0-2.242 1.009-2.242 2.242 0 1.246 1.009 2.242 2.242 2.242 1.232 0 2.241-0.9962 2.241-2.242 0-1.2-1.01-2.2-2.24-2.2"
      />
      <path
        d="m100.3 214.9c-1.245 0-2.242 1.009-2.242 2.242 0 1.246 0.9975 2.242 2.242 2.242 1.234 0 2.23-0.9962 2.23-2.242 0-1.2-1-2.2-2.23-2.2"
      />
      <path
        d="m104.8 214.9c-1.234 0-2.242 1.009-2.242 2.242 0 1.246 1.009 2.242 2.242 2.242 1.234 0 2.242-0.9962 2.242-2.242 0-1.234-1.009-2.242-2.242-2.242"
      />
      <path
        d="m109.2 214.9c-1.246 0-2.242 1.009-2.242 2.242 0 1.246 0.9962 2.242 2.242 2.242 1.232 0 2.23-0.9962 2.23-2.242 0-1.234-0.9975-2.242-2.23-2.242"
      />
      <path
        d="m113.7 214.9c-1.232 0-2.241 1.009-2.241 2.242 0 1.246 1.009 2.242 2.241 2.242 1.234 0 2.242-0.9962 2.242-2.242 0-1.234-1.009-2.242-2.242-2.242"
      />
      <path
        d="m118.2 214.9c-1.232 0-2.23 1.009-2.23 2.242 0 1.246 0.9975 2.242 2.23 2.242 1.246 0 2.242-0.9962 2.242-2.242 0-1.234-0.9962-2.242-2.242-2.242"
      />
    </g>
    <path d="m120.3 217.2h-40.24v-6.054h40.24v6.054z" fill="#ff3000" />
    <g fill="#8c7a5a">
      <path
        d="m123.1 258.8c0 0.3988-0.3112 0.7238-0.7088 0.7238h-5.359c-0.3975 0-0.7212-0.325-0.7212-0.7238v-1.245c0-0.3988 0.3238-0.7238 0.7212-0.7238h5.359c0.3975 0 0.7088 0.325 0.7088 0.7238v1.245z"
      />
      <path
        d="m130.6 258.8c0 0.3988-0.3238 0.7238-0.7088 0.7238h-5.37c-0.3862 0-0.71-0.325-0.71-0.7238v-1.245c0-0.3988 0.3238-0.7238 0.71-0.7238h5.37c0.385 0 0.7088 0.325 0.7088 0.7238v1.245z"
      />
      <path
        d="m126.7 261.9c0 0.3975-0.3238 0.7088-0.7088 0.7088h-5.37c-0.3988 0-0.71-0.3112-0.71-0.7088v-1.246c0-0.3988 0.3112-0.7088 0.71-0.7088h5.37c0.385 0 0.7088 0.31 0.7088 0.7088v1.246z"
      />
      <path
        d="m150.6 264.9c0 0.3975-0.3112 0.7088-0.6975 0.7088h-5.37c-0.3975 0-0.7212-0.3112-0.7212-0.7088v-1.259c0-0.3862 0.3238-0.6975 0.7212-0.6975h5.37c0.3862 0 0.6975 0.3112 0.6975 0.6975v1.259z"
      />
      <path
        d="m158.1 264.9c0 0.3975-0.3238 0.7088-0.71 0.7088h-5.37c-0.385 0-0.7088-0.3112-0.7088-0.7088v-1.259c0-0.3862 0.3238-0.6975 0.7088-0.6975h5.37c0.3862 0 0.71 0.3112 0.71 0.6975v1.259z"
      />
      <path
        d="m154.5 261.9c0 0.3975-0.3238 0.7088-0.71 0.7088h-5.37c-0.3988 0-0.71-0.3112-0.71-0.7088v-1.246c0-0.3988 0.3112-0.7088 0.71-0.7088h5.37c0.3862 0 0.71 0.31 0.71 0.7088v1.246z"
      />
      <path
        d="m146.6 268.1c0 0.3988-0.3112 0.7238-0.71 0.7238h-5.37c-0.385 0-0.7088-0.325-0.7088-0.7238v-1.245c0-0.4 0.3238-0.7238 0.7088-0.7238h5.37c0.3988 0 0.71 0.3238 0.71 0.7238v1.245z"
      />
    </g>
    <g fill="#d1c5ac">
      <path
        d="m177.5 224.7c0 0.3862-0.3238 0.6975-0.7225 0.6975h-5.356c-0.3988 0-0.71-0.3112-0.71-0.6975v-1.27c0-0.3875 0.3112-0.6988 0.71-0.6988h5.356c0.3988 0 0.7225 0.3112 0.7225 0.6988v1.27z"
      />
      <path
        d="m158.1 247.9c0 0.385-0.3238 0.6962-0.71 0.6962h-5.37c-0.385 0-0.7088-0.3112-0.7088-0.6962v-1.271c0-0.3862 0.3238-0.6975 0.7088-0.6975h5.37c0.3862 0 0.71 0.3112 0.71 0.6975v1.271z"
      />
      <path
        d="m154.5 214.7c0 0.3862-0.3238 0.71-0.71 0.71h-5.37c-0.3988 0-0.71-0.3238-0.71-0.71v-1.258c0-0.3875 0.3112-0.7112 0.71-0.7112h5.37c0.3862 0 0.71 0.3238 0.71 0.7112v1.258z"
      />
      <path
        d="m146.6 251.1c0 0.3975-0.3112 0.7225-0.71 0.7225h-5.37c-0.385 0-0.7088-0.325-0.7088-0.7225v-1.246c0-0.3988 0.3238-0.7225 0.7088-0.7225h5.37c0.3988 0 0.71 0.3238 0.71 0.7225v1.246z"
      />
      <path
        d="m27.14 236.1c0 0.3862-0.3112 0.71-0.71 0.71h-5.37c-0.385 0-0.7088-0.3238-0.7088-0.71v-1.258c0-0.4 0.3238-0.7112 0.7088-0.7112h5.37c0.3988 0 0.71 0.3112 0.71 0.7112v1.258z"
      />
      <path
        d="m80.52 239.2c0 0.3975-0.3238 0.7088-0.7225 0.7088h-5.356c-0.3988 0-0.71-0.3112-0.71-0.7088v-1.259c0-0.3862 0.3112-0.71 0.71-0.71h5.356c0.3988 0 0.7225 0.3238 0.7225 0.71v1.259z"
      />
      <path
        d="m54.91 233.2c0 0.385-0.3112 0.6962-0.7088 0.6962h-5.37c-0.3862 0-0.71-0.3112-0.71-0.6962v-1.259c0-0.3862 0.3238-0.71 0.71-0.71h5.37c0.3975 0 0.7088 0.3238 0.7088 0.71v1.259z"
      />
      <path
        d="m62.46 247.9c0 0.385-0.3238 0.6962-0.7088 0.6962h-5.37c-0.3988 0-0.71-0.3112-0.71-0.6962v-1.271c0-0.3862 0.3112-0.6975 0.71-0.6975h5.37c0.385 0 0.7088 0.3112 0.7088 0.6975v1.271z"
      />
      <path
        d="m182.2 239.2c0 0.3975-0.3238 0.7088-0.71 0.7088h-5.37c-0.385 0-0.7088-0.3112-0.7088-0.7088v-1.259c0-0.3862 0.3238-0.71 0.7088-0.71h5.37c0.3862 0 0.71 0.3238 0.71 0.71v1.259z"
      />
      <path
        d="m169.9 247.9c0 0.385-0.3238 0.6962-0.7225 0.6962h-5.358c-0.3975 0-0.7212-0.3112-0.7212-0.6962v-1.271c0-0.3862 0.3238-0.6975 0.7212-0.6975h5.358c0.3988 0 0.7225 0.3112 0.7225 0.6975v1.271z"
      />
      <path
        d="m50.89 251.1c0 0.3975-0.3238 0.7225-0.7088 0.7225h-5.37c-0.3988 0-0.71-0.325-0.71-0.7225v-1.246c0-0.3988 0.3112-0.7225 0.71-0.7225h5.37c0.385 0 0.7088 0.3238 0.7088 0.7225v1.246z"
      />
      <path
        d="m62.46 214.5c0 0.3988-0.3238 0.7238-0.7088 0.7238h-5.37c-0.3988 0-0.71-0.325-0.71-0.7238v-1.245c0-0.4 0.3112-0.7238 0.71-0.7238h5.37c0.385 0 0.7088 0.3238 0.7088 0.7238v1.245z"
      />
      <path
        d="m21.9 217.7c0 0.385-0.3238 0.6962-0.71 0.6962h-5.37c-0.3988 0-0.71-0.3112-0.71-0.6962v-1.259c0-0.3988 0.3112-0.71 0.71-0.71h5.37c0.3862 0 0.71 0.3112 0.71 0.71v1.259z"
      />
    </g>
    <g fill="#8c7a5a">
      <path
        d="m185.7 258.8c0 0.3988-0.3238 0.7238-0.7225 0.7238h-5.358c-0.3975 0-0.7088-0.325-0.7088-0.7238v-1.245c0-0.3988 0.3112-0.7238 0.7088-0.7238h5.358c0.3988 0 0.7225 0.325 0.7225 0.7238v1.245z"
      />
      <path
        d="m181.9 261.9c0 0.3975-0.3112 0.7088-0.7088 0.7088h-5.356c-0.4 0-0.7238-0.3112-0.7238-0.7088v-1.246c0-0.3988 0.3238-0.7088 0.7238-0.7088h5.356c0.3975 0 0.7088 0.31 0.7088 0.7088v1.246z"
      />
      <path
        d="m21.58 266.8c0-0.3988-0.3238-0.7238-0.7088-0.7238h-5.37c-0.3862 0-0.71 0.325-0.71 0.7238v1.245c0 0.3988 0.3238 0.7238 0.71 0.7238h5.37c0.385 0 0.7088-0.325 0.7088-0.7238v-1.245z"
      />
      <path
        d="m29.12 266.8c0-0.3988-0.3125-0.7238-0.6975-0.7238h-5.37c-0.3988 0-0.71 0.325-0.71 0.7238v1.245c0 0.3988 0.3112 0.7238 0.71 0.7238h5.37c0.385 0 0.6975-0.325 0.6975-0.7238v-1.245z"
      />
      <path
        d="m25.29 263.7c0-0.385-0.3112-0.6975-0.71-0.6975h-5.358c-0.3988 0-0.71 0.3125-0.71 0.6975v1.259c0 0.3988 0.3112 0.71 0.71 0.71h5.358c0.3988 0 0.71-0.3112 0.71-0.71v-1.259z"
      />
      <path
        d="m49.12 260.7c0-0.3975-0.3112-0.7088-0.7088-0.7088h-5.37c-0.3862 0-0.71 0.3112-0.71 0.7088v1.246c0 0.3988 0.3238 0.7088 0.71 0.7088h5.37c0.3975 0 0.7088-0.31 0.7088-0.7088v-1.246z"
      />
      <path
        d="m56.68 260.7c0-0.3975-0.3238-0.7088-0.7088-0.7088h-5.37c-0.3988 0-0.7225 0.3112-0.7225 0.7088v1.246c0 0.3988 0.3238 0.7088 0.7225 0.7088h5.37c0.385 0 0.7088-0.31 0.7088-0.7088v-1.246z"
      />
      <path
        d="m52.96 263.7c0-0.385-0.3112-0.6975-0.71-0.6975h-5.37c-0.385 0-0.7088 0.3125-0.7088 0.6975v1.259c0 0.3988 0.3238 0.71 0.7088 0.71h5.37c0.3988 0 0.71-0.3112 0.71-0.71v-1.259z"
      />
      <path
        d="m67.11 263.7c0-0.385-0.3112-0.6975-0.71-0.6975h-5.37c-0.3862 0-0.71 0.3125-0.71 0.6975v1.259c0 0.3988 0.3238 0.71 0.71 0.71h5.37c0.3988 0 0.71-0.3112 0.71-0.71v-1.259z"
      />
      <path
        d="m45.09 257.6c0-0.3988-0.3238-0.7238-0.71-0.7238h-5.369c-0.3988 0-0.71 0.325-0.71 0.7238v1.245c0 0.4 0.3112 0.7238 0.71 0.7238h5.369c0.3862 0 0.71-0.3238 0.71-0.7238v-1.245z"
      />
      <path
        d="m84.2 266.8c0-0.3988-0.3238-0.7238-0.7225-0.7238h-5.356c-0.3988 0-0.7225 0.325-0.7225 0.7238v1.245c0 0.3988 0.3238 0.7238 0.7225 0.7238h5.356c0.3988 0 0.7225-0.325 0.7225-0.7238v-1.245z"
      />
      <path
        d="m80.36 263.7c0-0.385-0.3125-0.6975-0.71-0.6975h-5.37c-0.3862 0-0.71 0.3125-0.71 0.6975v1.259c0 0.3988 0.3238 0.71 0.71 0.71h5.37c0.3975 0 0.71-0.3112 0.71-0.71v-1.259z"
      />
    </g>
    <g fill="#5fa33b">
      <path d="m69.89 244.7v-0.3375c0-0.9212-0.7588-1.681-1.681-1.681h-3.974v4.871h6.565v-2.852h-0.91z" />
      <path d="m44.86 244.7v-0.3375c0-0.9212-0.7588-1.681-1.681-1.681h-3.974v4.871h6.565v-2.852h-0.91z" />
      <path d="m166.6 244.7v-0.3375c0-0.9212-0.7475-1.681-1.681-1.681h-3.962v4.871h6.554v-2.852h-0.91z" />
      <path d="m141.5 244.7v-0.3375c0-0.9212-0.7475-1.681-1.682-1.681h-3.961v4.871h6.554v-2.852h-0.91z" />
    </g>
    <path d="m90.95 243.9h-1.245v-4.585h1.245v4.585z" fill="#ddbb7f" />
    <path d="m10.58 270.1h179.1v-14.6h-179.1v14.6z" fill="#8c7a5a" />
    <path d="m112.6 262.4h-24.97v-44.15h24.97v44.15z" fill="#684d29" />
    <path d="m88.69 261.3h22.86v-42.01h-22.86v42.01zm24.98 2.118h-27.1v-46.24h27.1v46.24z" fill="#3d2e1a" />
    <g fill="#5fa33b">
      <path
        d="m35.25 246.7c-0.9338 0-1.694-0.76-1.694-1.682v-0.6725c0-0.9225 0.76-1.681 1.694-1.681h7.936c0.9212 0 1.681 0.7588 1.681 1.681v0.6725c0 0.9225-0.76 1.682-1.681 1.682h-7.936z"
      />
      <path
        d="m60.28 246.7c-0.9338 0-1.681-0.76-1.681-1.682v-0.6725c0-0.9225 0.7475-1.681 1.681-1.681h7.936c0.9212 0 1.681 0.7588 1.681 1.681v0.6725c0 0.9225-0.76 1.682-1.681 1.682h-7.936z"
      />
      <path
        d="m157 246.7c-0.9225 0-1.681-0.76-1.681-1.682v-0.6725c0-0.9225 0.7588-1.681 1.681-1.681h7.936c0.9338 0 1.681 0.7588 1.681 1.681v0.6725c0 0.9225-0.7475 1.682-1.681 1.682h-7.936z"
      />
      <path
        d="m131.9 246.7c-0.935 0-1.682-0.76-1.682-1.682v-0.6725c0-0.9225 0.7475-1.681 1.682-1.681h7.935c0.935 0 1.682 0.7588 1.682 1.681v0.6725c0 0.9225-0.7475 1.682-1.682 1.682h-7.935z"
      />
    </g>
    <path d="m187.7 211.2h-175.1v-8.11h175.1v8.11z" fill="#beae90" />
    <g fill="#d1c5ac">
      <path d="m187.7 209.1h-175.1v-8.11h175.1v8.11z" />
      <path d="m113.7 265.9h-27.2v-2.491h27.2v2.491z" />
      <path d="m113.7 270.1h-27.2v-2.504h27.2v2.504z" />
    </g>
    <path d="m112 234.1h-23.82v-1.819h23.82v1.819z" fill="#3d2e1a" />
    <path d="m112 248.9h-23.82v-1.806h23.82v1.806z" fill="#3d2e1a" />
    <path d="m82.92 224.6h-1.32v-8.185h1.32v8.185z" fill="#684d29" />
    <path d="m118.9 224.6h-1.32v-8.185h1.32v8.185z" fill="#684d29" />
    <g fill="#bf1f00">
      <circle cy="217.1" cx="82.3" r="2.242" />
      <circle cy="217.1" cx="118.1" r="2.242" />
      <circle cy="217.1" cx="114.1" r="2.242" />
      <circle cy="217.1" cx="110.1" r="2.242" />
      <circle cy="217.1" cx="106.1" r="2.242" />
      <circle cy="217.1" cx="102.2" r="2.242" />
      <circle cy="217.1" cx="98.19" r="2.242" />
      <circle cy="217.1" cx="94.22" r="2.242" />
      <circle cy="217.1" cx="86.28" r="2.242" />
      <circle cy="217.1" cx="90.25" r="2.242" />
    </g>
    <path d="m120.3 217.2h-40.24v-6.054h40.24v6.054z" fill="#ff3000" />
    <path
      d="m123.1 258.8c0 0.3988-0.3112 0.7238-0.7088 0.7238h-5.359c-0.3975 0-0.7212-0.325-0.7212-0.7238v-1.245c0-0.3988 0.3238-0.7238 0.7212-0.7238h5.359c0.3975 0 0.7088 0.325 0.7088 0.7238v1.245z"
      fill="#766446"
    />
    <g fill="#6c5a3d">
      <path
        d="m130.6 258.8c0 0.3988-0.3238 0.7238-0.7088 0.7238h-5.37c-0.3862 0-0.71-0.325-0.71-0.7238v-1.245c0-0.3988 0.3238-0.7238 0.71-0.7238h5.37c0.385 0 0.7088 0.325 0.7088 0.7238v1.245z"
      />
      <path
        d="m126.7 261.9c0 0.3975-0.3238 0.7088-0.7088 0.7088h-5.37c-0.3988 0-0.71-0.3112-0.71-0.7088v-1.246c0-0.3988 0.3112-0.7088 0.71-0.7088h5.37c0.385 0 0.7088 0.31 0.7088 0.7088v1.246z"
      />
      <path
        d="m150.6 264.9c0 0.3975-0.3112 0.7088-0.6975 0.7088h-5.37c-0.3975 0-0.7212-0.3112-0.7212-0.7088v-1.259c0-0.3862 0.3238-0.6975 0.7212-0.6975h5.37c0.3862 0 0.6975 0.3112 0.6975 0.6975v1.259z"
      />
      <path
        d="m158.1 264.9c0 0.3975-0.3238 0.7088-0.71 0.7088h-5.37c-0.385 0-0.7088-0.3112-0.7088-0.7088v-1.259c0-0.3862 0.3238-0.6975 0.7088-0.6975h5.37c0.3862 0 0.71 0.3112 0.71 0.6975v1.259z"
      />
    </g>
    <path
      d="m154.5 261.9c0 0.3975-0.3238 0.7088-0.71 0.7088h-5.37c-0.3988 0-0.71-0.3112-0.71-0.7088v-1.246c0-0.3988 0.3112-0.7088 0.71-0.7088h5.37c0.3862 0 0.71 0.31 0.71 0.7088v1.246z"
      fill="#766446"
    />
    <path
      d="m146.6 268.1c0 0.3988-0.3112 0.7238-0.71 0.7238h-5.37c-0.385 0-0.7088-0.325-0.7088-0.7238v-1.245c0-0.4 0.3238-0.7238 0.7088-0.7238h5.37c0.3988 0 0.71 0.3238 0.71 0.7238v1.245z"
      fill="#766446"
    />
    <g fill="#beae90">
      <path
        d="m177.5 224.7c0 0.3862-0.3238 0.6975-0.7225 0.6975h-5.356c-0.3988 0-0.71-0.3112-0.71-0.6975v-1.27c0-0.3875 0.3112-0.6988 0.71-0.6988h5.356c0.3988 0 0.7225 0.3112 0.7225 0.6988v1.27z"
      />
      <path
        d="m158.1 247.9c0 0.385-0.3238 0.6962-0.71 0.6962h-5.37c-0.385 0-0.7088-0.3112-0.7088-0.6962v-1.271c0-0.3862 0.3238-0.6975 0.7088-0.6975h5.37c0.3862 0 0.71 0.3112 0.71 0.6975v1.271z"
      />
      <path
        d="m154.5 214.7c0 0.3862-0.3238 0.71-0.71 0.71h-5.37c-0.3988 0-0.71-0.3238-0.71-0.71v-1.258c0-0.3875 0.3112-0.7112 0.71-0.7112h5.37c0.3862 0 0.71 0.3238 0.71 0.7112v1.258z"
      />
      <path
        d="m146.6 251.1c0 0.3975-0.3112 0.7225-0.71 0.7225h-5.37c-0.385 0-0.7088-0.325-0.7088-0.7225v-1.246c0-0.3988 0.3238-0.7225 0.7088-0.7225h5.37c0.3988 0 0.71 0.3238 0.71 0.7225v1.246z"
      />
      <path
        d="m27.14 236.1c0 0.3862-0.3112 0.71-0.71 0.71h-5.37c-0.385 0-0.7088-0.3238-0.7088-0.71v-1.258c0-0.4 0.3238-0.7112 0.7088-0.7112h5.37c0.3988 0 0.71 0.3112 0.71 0.7112v1.258z"
      />
      <path
        d="m80.52 239.2c0 0.3975-0.3238 0.7088-0.7225 0.7088h-5.356c-0.3988 0-0.71-0.3112-0.71-0.7088v-1.259c0-0.3862 0.3112-0.71 0.71-0.71h5.356c0.3988 0 0.7225 0.3238 0.7225 0.71v1.259z"
      />
      <path
        d="m54.91 233.2c0 0.385-0.3112 0.6962-0.7088 0.6962h-5.37c-0.3862 0-0.71-0.3112-0.71-0.6962v-1.259c0-0.3862 0.3238-0.71 0.71-0.71h5.37c0.3975 0 0.7088 0.3238 0.7088 0.71v1.259z"
      />
      <path
        d="m62.46 247.9c0 0.385-0.3238 0.6962-0.7088 0.6962h-5.37c-0.3988 0-0.71-0.3112-0.71-0.6962v-1.271c0-0.3862 0.3112-0.6975 0.71-0.6975h5.37c0.385 0 0.7088 0.3112 0.7088 0.6975v1.271z"
      />
      <path
        d="m182.2 239.2c0 0.3975-0.3238 0.7088-0.71 0.7088h-5.37c-0.385 0-0.7088-0.3112-0.7088-0.7088v-1.259c0-0.3862 0.3238-0.71 0.7088-0.71h5.37c0.3862 0 0.71 0.3238 0.71 0.71v1.259z"
      />
      <path
        d="m169.9 247.9c0 0.385-0.3238 0.6962-0.7225 0.6962h-5.358c-0.3975 0-0.7212-0.3112-0.7212-0.6962v-1.271c0-0.3862 0.3238-0.6975 0.7212-0.6975h5.358c0.3988 0 0.7225 0.3112 0.7225 0.6975v1.271z"
      />
      <path
        d="m50.89 251.1c0 0.3975-0.3238 0.7225-0.7088 0.7225h-5.37c-0.3988 0-0.71-0.325-0.71-0.7225v-1.246c0-0.3988 0.3112-0.7225 0.71-0.7225h5.37c0.385 0 0.7088 0.3238 0.7088 0.7225v1.246z"
      />
      <path
        d="m62.46 214.5c0 0.3988-0.3238 0.7238-0.7088 0.7238h-5.37c-0.3988 0-0.71-0.325-0.71-0.7238v-1.245c0-0.4 0.3112-0.7238 0.71-0.7238h5.37c0.385 0 0.7088 0.3238 0.7088 0.7238v1.245z"
      />
      <path
        d="m21.9 217.7c0 0.385-0.3238 0.6962-0.71 0.6962h-5.37c-0.3988 0-0.71-0.3112-0.71-0.6962v-1.259c0-0.3988 0.3112-0.71 0.71-0.71h5.37c0.3862 0 0.71 0.3112 0.71 0.71v1.259z"
      />
    </g>
    <path
      d="m185.7 258.8c0 0.3988-0.3238 0.7238-0.7225 0.7238h-5.358c-0.3975 0-0.7088-0.325-0.7088-0.7238v-1.245c0-0.3988 0.3112-0.7238 0.7088-0.7238h5.358c0.3988 0 0.7225 0.325 0.7225 0.7238v1.245z"
      fill="#6c5a3d"
    />
    <path
      d="m181.9 261.9c0 0.3975-0.3112 0.7088-0.7088 0.7088h-5.356c-0.4 0-0.7238-0.3112-0.7238-0.7088v-1.246c0-0.3988 0.3238-0.7088 0.7238-0.7088h5.356c0.3975 0 0.7088 0.31 0.7088 0.7088v1.246z"
      fill="#766446"
    />
    <path
      d="m21.58 266.8c0-0.3988-0.3238-0.7238-0.7088-0.7238h-5.37c-0.3862 0-0.71 0.325-0.71 0.7238v1.245c0 0.3988 0.3238 0.7238 0.71 0.7238h5.37c0.385 0 0.7088-0.325 0.7088-0.7238v-1.245z"
      fill="#766446"
    />
    <g fill="#6c5a3d">
      <path
        d="m29.12 266.8c0-0.3988-0.3125-0.7238-0.6975-0.7238h-5.37c-0.3988 0-0.71 0.325-0.71 0.7238v1.245c0 0.3988 0.3112 0.7238 0.71 0.7238h5.37c0.385 0 0.6975-0.325 0.6975-0.7238v-1.245z"
      />
      <path
        d="m25.29 263.7c0-0.385-0.3112-0.6975-0.71-0.6975h-5.358c-0.3988 0-0.71 0.3125-0.71 0.6975v1.259c0 0.3988 0.3112 0.71 0.71 0.71h5.358c0.3988 0 0.71-0.3112 0.71-0.71v-1.259z"
      />
      <path
        d="m49.12 260.7c0-0.3975-0.3112-0.7088-0.7088-0.7088h-5.37c-0.3862 0-0.71 0.3112-0.71 0.7088v1.246c0 0.3988 0.3238 0.7088 0.71 0.7088h5.37c0.3975 0 0.7088-0.31 0.7088-0.7088v-1.246z"
      />
      <path
        d="m56.68 260.7c0-0.3975-0.3238-0.7088-0.7088-0.7088h-5.37c-0.3988 0-0.7225 0.3112-0.7225 0.7088v1.246c0 0.3988 0.3238 0.7088 0.7225 0.7088h5.37c0.385 0 0.7088-0.31 0.7088-0.7088v-1.246z"
      />
    </g>
    <g fill="#766446">
      <path
        d="m52.96 263.7c0-0.385-0.3112-0.6975-0.71-0.6975h-5.37c-0.385 0-0.7088 0.3125-0.7088 0.6975v1.259c0 0.3988 0.3238 0.71 0.7088 0.71h5.37c0.3988 0 0.71-0.3112 0.71-0.71v-1.259z"
      />
      <path
        d="m67.11 263.7c0-0.385-0.3112-0.6975-0.71-0.6975h-5.37c-0.3862 0-0.71 0.3125-0.71 0.6975v1.259c0 0.3988 0.3238 0.71 0.71 0.71h5.37c0.3988 0 0.71-0.3112 0.71-0.71v-1.259z"
      />
      <path
        d="m45.09 257.6c0-0.3988-0.3238-0.7238-0.71-0.7238h-5.369c-0.3988 0-0.71 0.325-0.71 0.7238v1.245c0 0.4 0.3112 0.7238 0.71 0.7238h5.369c0.3862 0 0.71-0.3238 0.71-0.7238v-1.245z"
      />
    </g>
    <path
      d="m84.2 266.8c0-0.3988-0.3238-0.7238-0.7225-0.7238h-5.356c-0.3988 0-0.7225 0.325-0.7225 0.7238v1.245c0 0.3988 0.3238 0.7238 0.7225 0.7238h5.356c0.3988 0 0.7225-0.325 0.7225-0.7238v-1.245z"
      fill="#6c5a3d"
    />
    <path
      d="m80.36 263.7c0-0.385-0.3125-0.6975-0.71-0.6975h-5.37c-0.3862 0-0.71 0.3125-0.71 0.6975v1.259c0 0.3988 0.3238 0.71 0.71 0.71h5.37c0.3975 0 0.71-0.3112 0.71-0.71v-1.259z"
      fill="#766446"
    />
    <path d="m70.8 247.6h-13.11v-2.852h13.11v2.852z" fill="#bc3f26" />
    <path
      opacity=".3"
      d="m69.89 244.7v-0.3375c0-0.9212-0.7588-1.681-1.681-1.681h-3.974v4.871h6.565v-2.852h-0.91z"
      fill="#5fa33b"
    />
    <path d="m45.78 247.5h-13.12v-2.852h13.12v2.852z" fill="#bc3f26" />
    <path
      opacity=".3"
      d="m44.86 244.7v-0.3375c0-0.9212-0.7588-1.681-1.681-1.681h-3.974v4.871h6.565v-2.852h-0.91z"
      fill="#5fa33b"
    />
    <path d="m167.5 247.6h-13.11v-2.852h13.11v2.852z" fill="#bc3f26" />
    <path
      opacity=".3"
      d="m166.6 244.7v-0.3375c0-0.9212-0.7475-1.681-1.681-1.681h-3.962v4.871h6.554v-2.852h-0.91z"
      fill="#5fa33b"
    />
    <path d="m142.4 247.6h-13.11v-2.852h13.11v2.852z" fill="#bc3f26" />
    <path
      opacity=".3"
      d="m141.5 244.7v-0.3375c0-0.9212-0.7475-1.681-1.682-1.681h-3.961v4.871h6.554v-2.852h-0.91z"
      fill="#5fa33b"
    />
    <path d="m90.95 243.9h-1.245v-4.585h1.245v4.585z" fill="#ddbb7f" />
    <path d="m63.72 242.7h1.02v-24.18h-1.02v24.18z" fill="#fff" />
    <path d="m31.56 225.7h14.28v-1.034h-14.28v1.034z" fill="#fff" />
    <path d="m187.6 185.9-17.4-104.9-70.14-73.6-70.08 73.59-17.5 104.9v19.06h175.1v-19.1z" fill="#ff3000" />
    <path d="m99.54 200.9h1.132v-191.5h-1.132v191.5z" fill="#ce2605" />
    <path
      d="m99.54 9.4v191.5h1.133v-191.5h-1.133zm9.988 7.879v183.6h1.135v-182.4l-1.135-1.189zm-18.84 0.0332-1.135 1.189v182.4h1.135v-183.6zm28.83 10.45v173.1h1.133v-171.9l-1.133-1.188zm-38.82 0.0332-1.133 1.188v171.9h1.133v-173.1zm48.81 10.44v162.6h1.135v-161.4l-1.135-1.191zm-58.8 0.0332-1.135 1.191v161.4h1.135v-162.6zm68.79 10.45v152.2h1.133v-151l-1.133-1.188zm-78.78 0.0332-1.133 1.188v150.9h1.133v-152.1zm88.77 10.44v141.7h1.135v-140.5l-1.135-1.189zm-98.76 0.0332-1.135 1.189v140.5h1.135v-141.6zm108.8 10.45v131.2h1.133v-130l-1.133-1.189zm-118.7 0.0332-1.133 1.189v130h1.133v-131.2zm128.7 10.44v120.7h1.135v-117.8l-0.3398-2.051-0.7949-0.834zm-138.7 0.0332-0.7637 0.8008-0.02344 0.03711-0.3477 2.096v117.8h1.135v-120.7zm148.7 56.28v64.41h1.133v-57.57l-1.133-6.836zm-158.7 0.0273-1.133 6.828v57.55h1.133v-64.38z"
      fill="#ce2605"
    />
    <path d="m59.7 126.1h-8.856v-22.95h8.856v22.95z" fill="#352613" />
    <path d="m59.7 127.5h-8.856v-2.118h8.856v2.118z" fill="#fff" />
    <path d="m149.6 126.1h-8.856v-22.95h8.856v22.95z" fill="#352613" />
    <path d="m149.6 127.5h-8.856v-2.118h8.856v2.118z" fill="#fff" />
    <path
      d="m6.926 258.7-1.819-1.109 6.205-10.16v-52.44l-3.925-3.925 1.494-1.506 4.549 4.559v53.91l-6.504 10.6z"
      fill="#3d2e1a"
    />
    <path
      d="m193.1 258.7-6.501-10.66v-53.91l4.546-4.559 1.495 1.506-3.924 3.925v52.44l6.19 10.15-1.8 1.2z"
      fill="#3d2e1a"
    />
    <path d="m129.5 143.6h-58.8v-58.81h58.8v58.81z" fill="#d62300" />
    <g fill="#fff">
      <path d="m101 143.8h-2.192v-58.81h2.192v58.81z" />
      <path d="m71.66 144.1-1.892-0.9462 29.21-58.61 1.888 0.95-29.21 58.61z" />
      <path d="m98.98 144.1-29.21-58.61 1.89-0.95 29.22 58.61-1.9 0.95z" />
      <path d="m101.2 144.1-1.892-0.9462 29.22-58.61 1.888 0.95-29.22 58.61z" />
      <path d="m128.6 144.1-29.22-58.61 1.89-0.95 29.22 58.61-1.888 0.95z" />
      <path d="m101 143.8h-2.192v-58.81h2.192v58.81z" />
      <path d="m101 143.8h-2.192v-58.81h2.192v58.81z" />
      <path d="m101 143.8h-2.192v-58.81h2.192v58.81z" />
    </g>
    <path
      opacity=".5"
      d="m31.24 81.65-16.66 100.4-4.322 5.109 4.846 4.099 5.455-6.441 16.62-100.2 65.74-68.96v-9.218l-71.68 75.21z"
    />
    <path
      d="m100.1 2.776-73.11 76.72-16.66 100.5-4.329 5.1 4.847 4.1 5.462-6.5 16.62-100.2 67.16-70.44 67.18 70.46 16.4 98.81 5.792 7.699 5.07-3.812-4.845-6.454-16.5-99.32-73.09-76.71z"
      fill="#b7a48f"
    />
    <path d="m67.54 146.8h65.15v-65.16h-65.15v65.16zm6.352-58.81h52.45v52.45h-52.45v-52.45z" fill="#fff" />
    <path
      opacity=".5"
      d="m193.1 258.7 1.806-1.109-6.19-10.16v-52.44l3.924-3.925-1.495-1.506-3.488 3.488v-6.402l1.806 2.392 5.069-3.812-4.845-6.452-16.5-99.24-72.9-76.49v267h89.33v-14.6h-1.991v-5.742l5.5 8.9z"
      fill="#8c7a5a"
    />
  </svg>
`;
