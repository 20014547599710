import { html } from 'lit';

export const beehive = html`
  <svg
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    style="enable-background:new"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    viewBox="0 0 400 550"
  >
    <g fill="#aaa7a5">
      <path d="m314.3 515.7h-248.2v-28.96h248.2v28.96z" />
      <path d="m80.58 549.4h-13.81v-63.35h36.98l-23.16 63.35z" />
      <path d="m300.5 549.4h13.81v-63.35h-36.96l23.15 63.35z" />
    </g>
    <path d="m318.1 184.4h-256.1v-46.24l128-47.39 128.1 47.39v46.24z" fill="#b28b6b" />
    <path d="m261.8 485.7h-144.2v-14.82h144.2v14.82z" fill="#685d56" />
    <path d="m261.8 526.9h-144.2v-40.58h144.2v40.58z" fill="#8e837b" />
    <path d="m196.8 135.7h-14.12v-14.19h14.12v14.19z" fill="#302015" />
    <path
      d="m31.83 220.7c0 2.526-2.096 4.568-4.568 4.568h-20.48c-2.471 0-4.512-2.041-4.512-4.568v-8.034c0-2.525 2.041-4.566 4.512-4.566h20.48c2.466 0 4.562 2.041 4.562 4.566v8.034z"
      fill="#ff8500"
    />
    <path
      d="m1.825 216.9v4c0 2.526 2.041 4.568 4.512 4.568h20.48c2.471 0 4.568-2.041 4.568-4.568v-4h-29.55z"
      fill="#0f0f0f"
    />
    <path
      d="m4.325 193.2c-0.11 0.0825-0.215 0.165-0.325 0.2425l11.5 14.91c0.11-0.05 0.215-0.1325 0.325-0.215 4.138-3.169 4.886-9.079 1.716-13.21-3.165-4.14-9.075-4.921-13.21-1.725"
      fill="#a5a5a5"
    />
    <path
      d="m11.83 189.4c-0.11 0.0275-0.27 0.0825-0.38 0.11l3.924 18.4c0.11-0.0275 0.27-0.0275 0.38-0.0487 5.1-1.079 8.325-6.102 7.251-11.18-1.072-5.104-6.074-8.355-11.18-7.282"
      fill="#c4c4c4"
    />
    <path
      d="m316.8 100.7c0 2.526 2.041 4.568 4.568 4.568h20.42c2.526 0 4.568-2.041 4.568-4.568v-8.006c0-2.525-2.041-4.566-4.568-4.566h-20.42c-2.521 0-4.562 2.044-4.562 4.569v8.006z"
      fill="#ff8500"
    />
    <path
      d="m346.8 96.99v4.006c0 2.525-2.041 4.566-4.568 4.566h-20.42c-2.526 0-4.568-2.041-4.568-4.566v-4.006h29.55z"
      fill="#0f0f0f"
    />
    <path
      d="m344.3 73.24c0.11 0.055 0.215 0.16 0.325 0.2425l-11.5 14.91c-0.11-0.0825-0.215-0.1375-0.325-0.215-4.138-3.196-4.891-9.111-1.716-13.25 3.165-4.109 9.075-4.885 13.21-1.688"
      fill="#a5a5a5"
    />
    <path
      d="m336.8 69.49c0.11 0.0275 0.27 0.055 0.38 0.11l-3.924 18.4c-0.11-0.0275-0.27-0.0275-0.38-0.055-5.1-1.101-8.325-6.098-7.251-11.2 1.072-5.075 6.074-8.326 11.18-7.254"
      fill="#c4c4c4"
    />
    <path
      d="m265.5 64.49c0 2.499 2.041 4.54 4.568 4.54h20.42c2.526 0 4.568-2.041 4.568-4.54v-8.034c0-2.525-2.041-4.566-4.568-4.566h-20.42c-2.521 0-4.562 2.041-4.562 4.566v8.034z"
      fill="#ff8500"
    />
    <path
      d="m295.5 60.74v4.028c0 2.499-2.041 4.54-4.568 4.54h-20.42c-2.526 0-4.568-2.041-4.568-4.54v-4.028h29.55z"
      fill="#0f0f0f"
    />
    <path
      d="m293.1 36.99c0.11 0.0825 0.16 0.165 0.27 0.27l-11.44 14.9c-0.11-0.0775-0.215-0.1325-0.325-0.215-4.138-3.196-4.886-9.106-1.716-13.25 3.165-4.101 9.075-4.882 13.21-1.712"
      fill="#a5a5a5"
    />
    <path
      d="m285.5 34.49c0.11 0.0275 0.27 0.055 0.38 0.0825l-3.924 18.42c-0.11-0.0275-0.27-0.055-0.3738-0.055-5.106-1.1-8.331-6.096-7.258-11.2 1.072-5.104 6.074-8.355 11.18-7.255"
      fill="#c4c4c4"
    />
    <path
      d="m368.1 198.2c0 2.499 2.046 4.54 4.572 4.54h20.41c2.526 0 4.572-2.041 4.572-4.54v-8.034c0-2.525-2.046-4.566-4.572-4.566h-20.41c-2.529 0-4.575 2.041-4.575 4.566v8.034z"
      fill="#ff8500"
    />
    <path
      d="m398.1 194.4v4.034c0 2.498-2.048 4.539-4.572 4.539h-20.41c-2.526 0-4.572-2.041-4.572-4.539v-4.034h29.56z"
      fill="#0f0f0f"
    />
    <path
      d="m395.5 170.7c0.11 0.055 0.215 0.16 0.27 0.2425l-11.44 14.91c-0.105-0.0825-0.215-0.1375-0.32-0.215-4.138-3.175-4.891-9.111-1.721-13.22 3.165-4.134 9.075-4.909 13.21-1.712"
      fill="#a5a5a5"
    />
    <path
      d="m388.1 166.9c0.105 0.0275 0.27 0.0825 0.3738 0.0825l-3.922 18.44c-0.105-0.0275-0.27-0.0275-0.375-0.055-5.106-1.101-8.33-6.102-7.258-11.2 1.079-5.088 6.075-8.339 11.18-7.266"
      fill="#c4c4c4"
    />
    <path
      d="m146.8 30.74c0 2.526-2.041 4.568-4.512 4.568h-20.48c-2.471 0-4.568-2.041-4.568-4.568v-8.034c0-2.525 2.096-4.566 4.568-4.566h20.48c2.471 0 4.512 2.041 4.512 4.566v8.034z"
      fill="#ff8500"
    />
    <path
      d="m116.8 26.99v4.028c0 2.526 2.096 4.568 4.568 4.568h20.48c2.471 0 4.512-2.041 4.512-4.568v-4.028h-29.55z"
      fill="#0f0f0f"
    />
    <path
      d="m119.3 3.242c-0.105 0.0825-0.215 0.16-0.3188 0.2425l11.5 14.91c0.1038-0.0825 0.2138-0.1375 0.3188-0.215 4.138-3.169 4.891-9.106 1.722-13.22-3.178-4.128-9.142-4.909-13.22-1.712"
      fill="#a5a5a5"
    />
    <path
      d="m126.8-0.5077c-0.16 0.0275-0.27 0.05-0.3738 0.105l3.922 18.4c0.105-0.0275 0.215-0.0275 0.375-0.055 5.106-1.1 8.33-6.096 7.251-11.2-1.072-5.069-6.069-8.321-11.18-7.243"
      fill="#c4c4c4"
    />
    <path d="m308.1 485.7h-234.2v-334.2h234.2v334.2z" fill="#b28b6b" />
    <path d="m51.83 163.2-8.76-21.44 146.6-60.11 149.4 53.15-7.75 21.79-141-50.16-138.5 56.78z" fill="#493820" />
    <path
      opacity=".7"
      d="m308.1 183.2h-234.3v2.421h88.08v15.69c0 1.991 1.611 3.604 3.542 3.604h51.05c1.986 0 3.599-1.612 3.599-3.604v-15.69l88.1-0.004v-2.421z"
      fill="#8c5e3f"
    />
    <path
      d="m308.1 240.7h-234.3v2.442h88.08v15.7c0 1.931 1.611 3.544 3.542 3.544h51.05c1.986 0 3.599-1.612 3.599-3.544v-15.7l88.1 0.0175v-2.442z"
      fill="#7c4c2d"
    />
    <path
      d="m308.1 349.5h-234.3v2.421h88.08v15.69c0 1.991 1.611 3.604 3.542 3.604h51.05c1.986 0 3.599-1.612 3.599-3.604v-15.69l88.1-0.004v-2.421z"
      fill="#7c4c2d"
    />
    <g fill="#ffb052">
      <path d="m108.1 185.7v67.2c0 5.481 4.462 9.992 9.992 9.992h3.979c5.48 0 9.991-4.511 9.991-9.992v-67.2h-23.96z" />
      <path
        d="m131.8 185.7v36.32c0 5.508 4.512 9.992 9.992 9.992h4.034c5.535 0 9.991-4.485 9.991-9.992v-36.32h-24.01z"
      />
      <path
        d="m84.32 185.7v13.16c0 5.508 4.512 9.992 9.992 9.992h4.034c5.48 0 9.991-4.485 9.991-9.992v-13.16h-24.01z"
      />
      <path d="m251.8 243.2v13.1c0 5.536 4.511 9.999 9.992 9.999h4.032c5.481 0 9.992-4.462 9.992-9.999v-13.1h-24.01z" />
      <path d="m228.1 243.2v28.59c0 5.48 4.462 9.992 9.998 9.992h3.974c5.48 0 9.998-4.512 9.998-9.992v-28.59h-23.98z" />
    </g>
    <path
      opacity=".5"
      d="m191.8 81.99v445.2h71.09v-11.6h25.69l12.58 34.39h13.81v-63.35h-7.038v-302h10.96v-32.91l13.21 4.676 7.741-21.79-148.1-52.64z"
      fill="#b28b6b"
    />
  </svg>
`;
