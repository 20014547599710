export class ChickenRewardSource {
  /**
   * @param {[x: number, y: number, w: number, h: number, angle: number, iconType: string][] } locations
   * @param {{f: string, h: string} } iconNames
   */
  constructor(locations, iconNames) {
    /**
     * @type {  [x: number, y: number, w: number, h: number, angle: number, iconType: string][]}
     */
    this.locations = locations;

    /**
     * @type {{f: string, h: string}}
     */
    this.iconNames = iconNames;

    /**
     * @type {Generator<[x: number, y: number, w: number, h: number,angle: number, iconType: string], [], unknown>}
     */
    this.locationsIt = this.locationsGen(this.locations);

    this.rewards_ = [];
  }

  /**
   * @param {[x: number, y: number, w: number, h: number, angle: number, iconType: string][]} locations
   * @returns {Generator<[x: number, y: number, w: number, h: number,angle: number, iconType: string], [], unknown>}
   */
  *locationsGen(locations) {
    for (;;) {
      for (const item of locations) {
        yield item;
      }
    }
  }

  getNextReward() {
    const [x, y, w, h, angle, iconType] = this.locationsIt.next().value;
    const graphic = this.iconNames[iconType];

    return { x, y, w, h, graphic, angle };
  }

  /**
   * @param {number} count
   */
  addRewards(count) {
    for (let index = 0; index < count; index++) {
      this.rewards_.push(this.getNextReward());
    }
  }

  get rewards() {
    return this.rewards_;
  }
}
