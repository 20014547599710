import { RewardSource } from '@tasks/sheep/reward-source.js';
export class MemoryRewardSource {
  constructor(rewardLocations, iconNames) {
    this.sheepRewardSource = new RewardSource(rewardLocations.sheep, iconNames.sheep);

    this.chickenRewardSource = new RewardSource(rewardLocations.chicken, iconNames.chicken);
  }

  addRewards({ sheep, chicken }) {
    this.sheepRewardSource.addRewards(sheep);
    this.chickenRewardSource.addRewards(chicken);
  }

  get rewards() {
    return { sheep: this.sheepRewardSource, chicken: this.chickenRewardSource };
  }
}
