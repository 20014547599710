import { html } from 'lit';

export const bgMotor = html`
  <svg preserveAspectRatio="xMidYMid slice" version="1.1" viewBox="0 0 1280 960" xmlns="http://www.w3.org/2000/svg">
    <path d="m0 960h1280v-960h-1280z" fill="#b2e380" />
    <path
      d="m0.68359 0.78516v55.949h-1.7246s36.871 73.176 192.74 44.557c155.1-28.62 201.59-41.343 297.75 15.896 96.165 57.219 216.91-76.707 382.71-3.5801 164.44 72.502 208.67-43.784 401.06-54.191h6.1035v-58.631h-1278.6z"
      fill="#78df0e"
      style="font-variation-settings:'wght' 900"
    />
    <path
      d="m1280 105.3v856.7h-1280v-873.9s16.78 87.38 151.2 47.47c134.3-39.92 192.5-53.58 275.1-11.7 185.5 93.9 281.5-65.78 441.5 23.4 160.2 89.3 267.2-95.81 412.2-42"
      fill="#23d829"
    />
    <path
      d="m1126 206.7c-13.2 0-26.4 0.2485-39.6 0.6973l-0.088 0.0117c-229.1 7.75-450.1 75-677.2 98.88-54.46 5.721-109.2 9.278-164 9.598-54.25 0.31-108-4.049-162.1-6.783-27.19-1.377-55.07-2.426-83-4.383v161.8c89.1-30.75 182.3-48.04 277-48.2 121.9-0.21 243.4 24.48 362.6 47.74 122.4 23.9 244.5 50.31 368.2 66.97 89.98 12.11 181.2 18.67 272.2 18.13v-330.9c-20.4-3.663-40.91-6.703-61.53-8.906-30.72-3.284-61.55-4.635-92.41-4.637z"
      fill="#fff"
      opacity=".5"
    />
    <path
      d="m1127 231.7c-13.77-0.0224-27.54 0.2262-41.31 0.6914-229.1 7.769-450.1 75-677.2 98.84-54.45 5.722-109.2 9.278-164 9.598-54 0.3-107.8-4.1-161.9-6.8-27.04-1.4-54.8-2.4-82.6-4.4v161.6c88.99-30.66 182.1-47.9 276.6-48.07 121.9-0.21 243.4 24.47 362.6 47.74 122.4 23.9 244.5 50.31 368.2 66.97 90.11 12.12 181.4 18.68 272.5 18.12v-330.7c-20.55-3.703-41.22-6.772-62-8.986-30.15-3.224-60.4-4.58-90.69-4.629z"
      fill="#00ac2e"
    />
    <path
      d="m279.1 361.8c-9.154 0-18.3 0.1462-27.46 0.4512l0.6-0.1c-85.5 2.9-171.1 18.9-252.2 47.3v257.3h1280v-172.3c-100.2 0.4135-200.4-7.05-299.6-21.61-123.5-18.11-245.4-44.84-367.9-68.26-109.6-20.98-221.2-42.81-333.4-42.81h-0.1035z"
      fill="#fff"
      opacity=".5"
    />
    <path
      d="m275.1 389.6c-7.576 0.0433-15.15 0.1883-22.73 0.4414-85.6 2.9-171.3 18.9-252.4 47.2v257.2h1280v-172.2c-100 0.4-200-7-298.9-21.6-123.5-18.1-245.3-44.8-367.9-68.2-111.1-21.3-224.5-43.5-338.1-42.8z"
      fill="#64c600"
    />
    <path
      d="m759.1 500.3c-33.51 0-67 1.178-100.4 3.898l0.3-0.1c-72.9 6-145.4 16.9-217.2 30.4-146.5 27.4-293.6 65.2-441.8 89.2v69.1h1280v-130.9c-127-23.5-255-46-383.8-55.9-45.59-3.482-91.36-5.684-137.1-5.684z"
      fill="#fff"
      opacity=".5"
    />
    <path
      d="m763 524.9c-34.67-0.0843-69.33 1.081-103.9 3.895-72.94 5.93-145.4 16.84-217.2 30.31-146.5 27.4-293.7 65.2-441.9 89.2v168.4h1280v-230.3c-127.1-23.41-254.4-45.92-383.4-55.79-44.43-3.395-89.03-5.569-133.6-5.678z"
      fill="#64c600"
    />
    <path
      d="m521 628.4c-45.74 0-91.51 2.198-137.1 5.682-129.1 9.9-256.6 32.5-383.9 55.9v130.9h1280v-69.16c-148.1-23.95-295.2-61.71-441.5-89.11-71.9-13.48-144.4-24.39-217.2-30.31-33.39-2.716-66.86-3.898-100.4-3.898h-0.0488 0.1z"
      fill="#fff"
      opacity=".5"
    />
    <path
      d="m517.6 653c-44.58 0.1083-89.18 2.282-133.6 5.678-129.2 9.8-256.7 32.4-384 55.9v245.4h1280v-183.7c-148-23.95-295-61.68-441.3-89.07-71.88-13.48-144.3-24.38-217.2-30.31-34.56-2.813-69.22-3.979-103.9-3.895z"
      fill="#64c600"
    />
  </svg>
`;
