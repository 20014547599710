import { Task } from '@lit/task';
import { LitElement, css, html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { graphics } from '~/graphics/index.js';

export class MotorRewards extends LitElement {
  static properties = { rewardItems: { type: Array }, showLayout: { type: Boolean }, uiRewards: { state: true } };

  constructor() {
    super();

    // /** @type {Object[]} */
    this.items = [];
    this.rewardItems = [];
    this.rewards = [];
    this.uiRewards = [];
    this.start = undefined;
    this.previousTimestamp = undefined;
    this.done = false;
    this.showLayout = false;

    this.getTrials = new Task(this, {
      task: async () => {
        this.rewards = await this.makeUIItems(this.rewardItems);

        window.requestAnimationFrame(t => this.step(t));
      },
      args: () => [],
    });
  }

  step(timestamp) {
    if (this.start === undefined) {
      this.start = timestamp;
    }
    const elapsed = timestamp - this.start;

    if (this.previousTimestamp !== timestamp) {
      const count = Math.min(Math.trunc(elapsed / 200), this.rewardItems.length);
      const uiCount = count - this.uiRewards.length;

      if (uiCount > 0) {
        const begin = this.uiRewards.length;

        this.uiRewards = [...this.uiRewards, ...this.rewards.slice(begin, begin + uiCount)];

        if (this.uiRewards.length === this.rewardItems.length) {
          this.done = true;
        }
      } else if (this.rewardItems.length === 0) {
        this.done = true;
      }
    }

    this.previousTimestamp = timestamp;
    if (this.done) {
      this.dispatchEvent(new Event('next'));
    } else {
      window.requestAnimationFrame(t => this.step(t));
    }
  }

  render() {
    return html`${this.getTrials.render({
      complete: () => {
        return html`${this.renderRewards()}`;
      },
    })}`;
  }

  renderRewards() {
    const border = this.showLayout;
    const rewards = this.uiRewards.map(
      item => html`
        <onc-game-item class=${classMap({ border, alt: 1, [`${item.css}`]: true })} .item=${item}
          >${item.icon}</onc-game-item
        >
      `,
    );
    return html` <div class="content">${rewards}</div> `;
  }

  async makeUIItems(items) {
    const uiItems = [];
    for (const { x, y, w, h, graphic } of items) {
      const icon = graphics.motor[graphic.name];
      const css = `motor-c${graphic.n}`;

      uiItems.push({
        top: y / 100,
        left: x / 100,
        width: w,
        height: h,
        icon,
        css,
      });
    }

    return uiItems;
  }
}
const OncMotorRewards = class OncMotorRewards extends MotorRewards {
  static styles = css`
    .motor-c0 {
      --motor-fill: #05429bff;
    }

    .motor-c1 {
      --motor-fill: #006837ff;
    }

    .motor-c2 {
      --motor-fill: #582abcff;
    }

    .motor-c3 {
      --motor-fill: #ba1a26ff;
    }

    .motor-c4 {
      --motor-fill: #e5bc55ff;
    }

    .motor-c5 {
      --motor-fill: #8c7691ff;
    }

    .motor-c6 {
      --motor-fill: #9e005dff;
    }

    .motor-c7 {
      --motor-fill: #754c24ff;
    }

    .motor-c8 {
      --motor-fill: #f97923ff;
    }

    .motor-c9 {
      --motor-fill: #4d4d4dff;
    }
  `;
};
customElements.define('onc-motor-rewards', OncMotorRewards);
