/**
 * @typedef { import('./record-types.js').MotorDemoRecord}  MotorDemoRecord
 */
export class MotorDemo {
  /**
   * @param {MotorDemoRecord} demoRecord
   */
  constructor(demoRecord) {
    const { animation, practice } = demoRecord;

    this.animation = {
      grid: animation.grid,
    };

    this.practice = {
      grid: practice.grid,
      maxNoResponseTrials: practice.maxNoResponseTrials,
    };
  }
}
