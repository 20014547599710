import { html } from 'lit';

export const halfEgg = html`
  <svg
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    viewBox="0 0 215 285"
  >
    <path
      fill="#dda16b"
      d="m107.5 1.893c-61.3 0-103.2 91.7-106.6 144.6-1.686 26.4 0.978 48.1 6.684 66l199.7-0.7c5.9-17.8 8.6-39.4 6.7-65.3h0.1c-3.9-52.99-39.7-144.6-106.6-144.6z"
    />
    <path
      fill="#bc8f79"
      d="m129.9 50.96c-5.6 0-10.1 4.56-10.1 10.18s4.5 10.18 10.1 10.18 10.2-4.55 10.2-10.18c0-5.62-4.5-10.18-10.2-10.18zm-50.22 17.32c-2.01 0-3.64 1.63-3.64 3.64s1.63 3.64 3.64 3.64 3.64-1.63 3.64-3.64-1.63-3.64-3.64-3.64zm52.82 21.98c-2.2 0-4 1.79-4 4s1.8 4 4 4 4-1.79 4-4-1.8-4-4-4zm-72.09 14.44c-3.82 0-6.91 3.1-6.91 6.9s3.09 6.9 6.91 6.9 6.91-3.1 6.91-6.9-3.09-6.9-6.91-6.9zm-35.65 13.1c-2.61 0-4.73 2.1-4.73 4.7 0 2.7 2.12 4.8 4.73 4.8s4.73-2.1 4.73-4.8c0-2.6-2.12-4.7-4.73-4.7zm148.2 4.5c-6 0-10.9 4.9-10.9 10.9s4.9 10.9 10.9 10.9 10.9-4.9 10.9-10.9-4.9-10.9-10.9-10.9zm-107.4 32.5c-7.44 0-13.46 6.1-13.46 13.5s6.03 13.5 13.46 13.5 13.46-6.1 13.46-13.5-6.03-13.5-13.46-13.5zm57.61 3.8c-5.4 0-9.8 4.4-9.8 9.9 0 5.4 4.4 9.8 9.8 9.8s9.8-4.4 9.8-9.8c0-5.5-4.4-9.9-9.8-9.9z"
    />
  </svg>
`;
