import { ContextConsumer } from '@lit/context';
import { msg } from '@lit/localize';
import { Task } from '@lit/task';
import { LitElement, css, html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { graphics } from '~/graphics';
import { motorTaskContext } from './motor-context';

const bgItems = [
  [10.23, 37.97, 29.56, 61.96, 'trailer:borderCollie'],
  [0.82, 15.39, 7.09, 37.48, 'memory:waterTower'],
  [78.57, 17.14, 20.65, 31.81, 'app:tree'],
];
const _targetRatio = 16 / 10;

export class EndGame extends LitElement {
  static properties = { showBorder: { type: Boolean } };
  constructor() {
    super();
    this.showBorder = false;

    new ContextConsumer(this, {
      context: motorTaskContext,
      callback: value => (this.taskController = value),
    });

    this.getTrials = new Task(this, {
      task: async () => {
        const [smudgeItem, ...uiItems] = await this.makeUIItems(bgItems);
        this.smudgeItem = smudgeItem;
        this.bgItems = uiItems;

        this.next();
      },
      args: () => [],
    });
  }

  playSound(key, loop = false) {
    return this.taskController.playSound(key, { loop });
  }

  async next() {
    await this.playSound(`end game`);
  }

  render() {
    return html`${this.getTrials.render({
      complete: () => {
        return html`
          ${this.renderBGItems()}
          <div class="game-item-wrapper">
            <onc-game-item class="blink" .item=${this.smudgeItem}>${this.smudgeItem.icon}</onc-game-item>
          </div>
          <onc-layout-task showBorder=${this.showBorder} .targetRatio=${_targetRatio}>
            <div class="end-game-text">${msg('The task has ended. Thanks for your help!')}</div>
            <div class="end-game-action">
              <onc-svg-icon @click=${this.onHome}>${graphics.app.adtHome}</onc-svg-icon>
            </div>
          </onc-layout-task>
        `;
      },
    })}`;
  }

  renderBGItems() {
    const border = false;
    const items = this.bgItems.map(
      item => html`
        <div class="game-item-wrapper">
          <onc-game-item class=${classMap({ border })} .item=${item}>${item.icon}</onc-game-item>
        </div>
      `,
    );
    return html` ${items}`;
  }

  onHome() {
    this.dispatchEvent(new Event('next'));
  }

  async makeUIItems(items) {
    const uiItems = [];
    for (const [x, y, w, h, name, allbg = 1] of items) {
      const [key, value] = name.split(':', 2);

      const icon = graphics[key][value];

      uiItems.push({
        top: y / 100,
        left: x / 100,
        width: w,
        name,
        icon,
        allbg,
      });
    }

    return uiItems;
  }
}

const OncEndGame = class OncMotorBlockFeedback extends EndGame {
  static styles = css`
    :host {
      position: relative;
    }

    .end-game-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      height: 100%;
    }

    .end-game-text {
      font-size: 18px;
      font-weight: 600;

      color: #fff;

      position: absolute;

      left: 50.21%;
      top: 55.27%;
      width: 42.41%;
      height: 17.84%;
    }

    .end-game-action-wrapper {
      /* align-self: flex-end; */
      width: 100%;
    }

    .end-game-action {
      position: absolute;

      left: 42.1%;
      top: 76.18%;
      width: 15.52%;
      height: 17.14%;
    }

    .smudge {
      position: absolute;
      top: 60%;
      left: 5%;

      width: 200px;
    }

    .blink #right-eye,
    .blink #left-eye {
      transform-origin: 50% 50%;
      transform-box: fill-box;
      animation: squeeze 2.4s infinite;
    }

    @keyframes squeeze {
      85% {
        transform: none;
        animation-timing-function: ease-in;
      }

      93% {
        transform: translateY(2px) scaleY(0);
      }

      100% {
        animation-timing-function: ease-out;
      }
    }
  `;
};
customElements.define('onc-end-game', OncEndGame);
