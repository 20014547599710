import { html } from 'lit';

export const c16 = html`
  <svg
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    viewBox="0 0 620 670"
  >
    <path
      d="m494.1 464c0 87.2-83 158.6-185.2 158.6-102.4 0-185.3-71.4-185.3-158.6s82.95-157.9 185.3-157.9c102.3 0 185.2 70.7 185.2 157.9"
      fill="#b3b3b3"
    />
    <g fill="#999">
      <path
        d="m236 86.49c8.156-3.126 15.88-2.404 22.88-0.4825 6.99 2.044 13.31 5.736 18.58 10.61 5.274 4.855 9.452 10.96 12.01 17.85 2.442 6.938 3.461 14.61 0.9325 22.9-8.072-3.109-13.66-6.329-18.79-9.599-5.048-3.27-9.376-6.63-13.4-10.4-4.039-3.751-7.766-7.895-11.42-12.75-3.654-4.95-7.111-10.39-10.79-18.12"
      />
      <path
        d="m308.1 37.74c7.692 5.781 12.46 12.64 16.26 19.76 3.675 7.171 6.092 14.74 7.322 22.6 1.201 7.846 1.188 16-0.4825 24.22-1.806 8.1-5.026 16.86-12.48 23.2-4.2-8.531-7.305-15.1-9.768-22.14-2.408-6.934-4.185-13.88-5.261-21.02-1.065-7.151-1.481-14.5-1.012-22.16 0.6-7.7 1.8-15.61 5.4-24.46"
      />
      <path
        d="m337.7 53.99c6.11 6.752 9.142 13.91 11.15 21.1 1.89 7.214 2.459 14.54 1.75 21.85-0.695 7.315-2.69 14.66-6.41 21.68-3.77 6.721-9.38 14.16-18.42 16.75-1.09-8.86-2.704-15.02-3.278-21.72-0.635-6.542-0.7125-12.96-0.055-19.45 0.6462-6.488 1.976-13.02 4.24-19.69 2.369-6.668 5.421-13.4 11.02-20.5"
      />
    </g>
    <path
      d="m305.9 465.2c-1.376-0.035-2.708-0.175-4.031-0.3625-86.8-3.508-133.4-71.05-133.4-71.05-12.76 38.41-12.82 81.62-12.82 81.62 2.009 82.95 69.82 149.5 153.2 149.5v-160c-0.6538 0.0413-1.258 0.195-1.904 0.195h-1.059z"
      fill="#29abe2"
    />
    <path
      d="m203.1 222.8-0.0488-0.0625s0.004 0.3075 0.004 0.7962c-0.22 2.844-0.4238 5.701-0.4238 8.608 0 0.8862 0.0913 1.752 0.105 2.641-0.5975 10.7-2.186 77.36-6.84 94.98-3.28 12.36-8.041 25.16-15.1 36.66-5.128 8.382-9.184 17.89-12.44 27.7 0 0 46.62 67.55 133.4 71.05h0.0213c1.306 0.1888 2.641 0.3288 4.01 0.3638h1.059c0.6462 0 1.25-0.1538 1.904-0.1962v-338.4c-55.48 0-101 41.62-105.7 95.86"
      fill="#b3b3b3"
    />
    <path
      d="m449.9 396.5s-49.72 69.54-141 69.54v160c83.48 0 151.4-66.72 153.2-149.8 0 0-0.0425-41.82-12.21-79.76"
      fill="#29abe2"
    />
    <path
      d="m436.9 366.5c-6.445-10.57-11.01-22.25-14.24-33.65-5.534-19.48-7.21-88.15-7.745-99.34 0-0.4338 0.045-0.8525 0.045-1.286 0-1.935-0.1888-3.829-0.2862-5.736-0.0425-2.284-0.0138-3.668-0.0138-3.668l-0.1612 0.245c-4.664-54.34-50.15-96.05-105.7-96.05v338.4c91.29 0 141-69.54 141-69.54-3.325-10.39-7.528-20.5-12.94-29.39"
      fill="#ccc"
    />
    <g fill="#999">
      <path
        d="m347.1 310.2v-0.0625c0-23.58-21.34-42.71-38.92-42.71-17.59 0-38.92 19.12-38.92 42.71 0 9.445-0.73 34.25 17.11 34.25 5.258 0 9.906-2.424 13.05-6.162 2.949 0.315 5.918 0.4688 8.768 0.4688 2.865 0 5.824-0.1538 8.779-0.4688 3.126 3.739 7.78 6.162 13.05 6.162 17.8 0 17.1-24.7 17.1-34.19"
      />
      <path
        d="m265.3 189c-26.09 0-47.24 20.1-47.24 44.88 0 24.8 21.15 44.89 47.24 44.89 19.5 0 36.24 2.844 43.46-13.12v-49.36c-7.2-16-23.9-27.2-43.5-27.2"
      />
      <path
        d="m352.3 279c26.09 0 47.25-20.09 47.25-44.89 0-24.78-21.16-44.88-47.25-44.88-19.56 0-36.34 11.29-43.52 27.38-0.015-0.0288-0.0425-0.07-0.0563-0.105v49.36c0.0138-0.0412 0.0413-0.0687 0.0563-0.1112 7.181 16.09 23.95 13.24 43.52 13.24"
      />
    </g>
    <path
      d="m300.5 267.8-11.44 7.545c-4.601 3.04-6.054 9.398-3.231 14.14l17.9 30.08c1.415 2.382 3.276 3.57 5.135 3.57v-57.61c-3.046 0-6.061 0.76-8.37 2.284"
      fill="#f39e2b"
    />
    <path
      d="m314 320.2 17.91-30.08c2.83-4.744 1.37-11.1-3.235-14.14l-11.44-7.545c-2.306-1.524-5.339-2.284-8.37-2.284v57.61c1.859 0 3.72-1.188 5.131-3.57"
      fill="#fbdd58"
    />
    <path
      d="m273.6 234c0 8.461-6.868 15.31-15.31 15.31-8.46 0-15.31-6.854-15.31-15.31 0-8.446 6.854-15.3 15.31-15.3 8.44 0 15.31 6.854 15.31 15.3"
      fill="#4e1412"
    />
    <path
      d="m373.2 234c0 8.461-6.864 15.31-15.31 15.31-8.45 0-15.31-6.854-15.31-15.31 0-8.446 6.86-15.3 15.31-15.3 8.448 0 15.31 6.854 15.31 15.3"
      fill="#4e1412"
    />
    <path
      d="m269.2 227.8c0 2.892-2.348 5.24-5.232 5.24-2.892 0-5.234-2.348-5.234-5.24s2.341-5.232 5.234-5.232c2.885 0 5.232 2.34 5.232 5.232"
      fill="#fff"
    />
    <path
      d="m368.5 227.8c0 2.892-2.338 5.24-5.23 5.24-2.891 0-5.232-2.348-5.232-5.24s2.341-5.232 5.232-5.232c2.892 0 5.23 2.34 5.23 5.232"
      fill="#fff"
    />
    <path
      d="m238.6 22.74c13.92 7.646 24.54 16.59 34.26 24.15 2.379 1.886 4.738 3.588 6.756 5.006 1.076 0.7612 1.96 1.118 2.931 1.778l3.934 2.614c5.669 3.835 12.23 9.39 17.55 16.1 10.96 13.54 15.95 30.45 16.19 45.69 0.1688 15.42-3.409 30.08-12.09 43.55-8.991-13.24-14.65-25.41-20.45-36.08-5.68-10.77-10.96-19.31-16.82-26.69-3.004-3.804-6.284-7.381-10.74-12.25l-3.612-4.004c-1.502-1.596-3.015-3.578-4.195-5.279-2.526-3.479-4.395-6.982-6.036-10.44-6.229-13.91-9.178-28.15-7.675-44.15"
      fill="#808080"
    />
    <path
      d="m189.7 331.5s6.494 47.09 50.75 76.25 35.4 225 35.4 225 1.576 20.84-30.62 15c-32.2-5.834-98.3-12.5-110.7-38.34-12.36-25.84-74.02-200 55.14-277.9"
      fill="#1a1a1a"
    />
    <path
      d="m189.7 331.5s6.494 47.09 50.75 76.25 35.4 225 35.4 225 1.576 20.84-30.62 15c-32.2-5.834-98.3-12.5-110.7-38.34"
      stroke="#e6e6e6"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="25"
      fill="none"
    />
    <path
      d="m189.7 331.5s6.494 47.09 50.75 76.25 35.4 225 35.4 225 1.576 20.84-30.62 15c-32.2-5.834-98.3-12.5-110.7-38.34"
      stroke="#333"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="22.5"
      fill="none"
    />
    <path
      d="m430.3 331.5s-6.494 47.09-50.75 76.25-35.4 225-35.4 225-1.576 20.84 30.62 15c32.2-5.834 98.3-12.5 110.7-38.34 12.36-25.84 74.02-200-55.14-277.9"
      fill="#1a1a1a"
    />
    <path
      d="m430.3 331.5s-6.494 47.09-50.75 76.25-35.4 225-35.4 225-1.576 20.84 30.62 15c32.2-5.834 98.3-12.5 110.7-38.34"
      stroke="#e6e6e6"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="25"
      fill="none"
    />
    <path
      d="m430.3 331.5s-6.494 47.09-50.75 76.25-35.4 225-35.4 225-1.576 20.84 30.62 15c32.2-5.834 98.3-12.5 110.7-38.34"
      stroke="#333"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="22.5"
      fill="none"
    />
    <path d="m243.2 91.49v60.44c0 15.35 29.35 27.8 65.56 27.8s65.56-12.44 65.56-27.8v-60.44h-131.1z" fill="#333" />
    <path d="m312.3 160.2-190.2-54.85 190.2-54.9 171 54.9-171 54.9z" fill="#1a1a1a" />
    <path
      d="m164.8 160.2v-48.66"
      stroke="#1a1a1a"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="17.5"
      fill="none"
    />
  </svg>
`;
