/**
 * @typedef { import('./record-types.js').PhaseRecord}  PhaseRecord
 */
export class MotorPhaseSetting {
  /**
   * @param {PhaseRecord} phaseRecord
   */
  constructor(phaseRecord) {
    const { showBlockFeedback = 'no', numberOfBlocks, grid, maxNoResponseTrials, type = 'main' } = phaseRecord;

    this.showBlockFeedback = showBlockFeedback === 'yes' ? true : false;
    this.numberOfBlocks = numberOfBlocks;
    this.grid = grid;
    this.maxNoResponseTrials = maxNoResponseTrials;

    const { rows, cols } = parseGrid(grid);
    this.rows = rows;
    this.cols = cols;
    this.type = type;
  }

  // get type() {
  //   return 'main';
  // }
}

function parseGrid(gridSpec) {
  const [cols, rows] = gridSpec.split('x').map(value => Number(value.trim()));

  return { rows, cols };
}
