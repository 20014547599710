import { html } from 'lit';

export const b10 = html`
  <svg version="1.1" viewBox="0 0 87.181 87.075" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(7.6729 -2.6458)" stroke-width=".26458">
      <path
        d="m17.862 23.423c5.4113-7.9788 12.401-15.085 20.576-20.7-9.4348-0.56126-19.07 1.9293-27.305 7.6271 1.9357 4.4458 3.9836 8.8426 6.1428 13.184 0.19544-0.03773 0.39087-0.07549 0.58666-0.11147"
        fill="var(--motor-fill, #f00)"
      />
      <path
        d="m17.277 23.529c-2.1593-4.3415-4.2071-8.7384-6.1428-13.184-1.0121 0.70062-2.0045 1.4464-2.9715 2.2437-7.2504 5.9804-12.069 13.815-14.336 22.249 6.427-5.9079 14.588-9.5866 23.451-11.308"
        fill="#f2f2f2 "
      />
      <path
        d="m17.497 23.978c0.12206-0.18309 0.24342-0.36653 0.36724-0.54893-0.19614 0.03598-0.39158 0.07374-0.58666 0.11184 0.07232 0.14605 0.1464 0.29104 0.21943 0.43709"
        fill="#f2f2f2 "
        opacity=".5"
      />
      <path
        d="m17.497 23.978c-0.07303-0.14605-0.14711-0.29104-0.21943-0.43709-8.8625 1.7212-17.024 5.3996-23.451 11.308-3.5327 13.143-0.86783 27.739 8.4511 39.039 1.8944 2.2969 3.9754 4.349 6.2021 6.1539-6.3952-18.658-2.1802-39.248 9.0167-56.063"
        fill="var(--motor-fill, #f00)"
      />
      <path
        d="m17.862 23.423c-0.12347 0.18238-0.24518 0.36547-0.36724 0.54893 10.769 21.56 24.256 41.762 40.047 59.968 2.0867-1.2012 4.1024-2.5894 6.0219-4.1722 11.745-9.6877 17.107-24.242 15.578-38.325-14.869-14.68-40.013-21.929-61.28-18.018"
        fill="var(--motor-fill, #f00)"
      />
      <path
        d="m17.497 23.978c-11.197 16.816-15.412 37.404-9.0167 56.063 13.895 11.264 33.483 12.874 49.064 3.9034-15.791-18.206-29.279-38.407-40.047-59.968"
        fill="#f2f2f2 "
      />
      <path
        d="m38.439 2.7324c-8.1746 5.6153-15.165 12.721-20.576 20.7 21.267-3.9108 46.411 3.3382 61.28 18.018-0.88548-8.1579-4.0812-16.158-9.693-22.962-8.0134-9.7158-19.358-15.062-31.012-15.756"
        fill="#f2f2f2 "
      />
      <path
        d="m79.507 46.071c0 24.105-19.54 43.646-43.646 43.646-10.284 0-19.734-3.556-27.191-9.5073 32.366-0.43746 58.473-26.81 58.473-59.28 0-1.8732-0.08819-3.7288-0.25753-5.5562 7.8034 7.8811 12.622 18.729 12.622 30.7"
        fill="#4d4d4d i"
        opacity=".2"
      />
      <path
        d="m23.59 29.085c6.0134-7.1853 8.1598-15.294 4.7935-18.111-3.3663-2.8173-10.97 0.7239-16.983 7.9092-6.0134 7.1853-8.1597 15.294-4.7935 18.111 3.3663 2.8173 10.97-0.7239 16.983-7.9092"
        fill="#ffffff "
        opacity=".39999"
      />
    </g>
  </svg>
`;
