import { adtHome } from './adt-home.js';
import { barnTall } from './barn-tall.js';
import { barnWideTall } from './barn-wide-tall.js';
import { collie } from './collie.js';
import { coopRunSmall } from './coop-run-small.js';
import { cowOnTractor } from './cow-on-tractor.js';
import { eggChick } from './egg-chick.js';
import { eggCrack0 } from './egg-crack-0.js';
import { eggCracked } from './egg-cracked.js';
import { eggHatching } from './egg-hatching.js';
import { fencepathNarrowLong } from './fencepath-narrow-long.js';
import { fencepathNarrow } from './fencepath-narrow.js';
import { fingerTap } from './finger-tap.js';
import { greenTractorDriver } from './green-tractor-driver.js';
import { straw } from './straw.js';
import { tractor } from './tractor.js';
import { tree } from './tree.js';

export const app = {
  adtHome,
  barnTall,
  barnWideTall,
  collie,
  coopRunSmall,
  cowOnTractor,
  eggCrack0,
  eggCracked,
  eggHatching,
  eggChick,
  fencepathNarrow,
  fencepathNarrowLong,
  fingerTap,
  greenTractorDriver,
  straw,
  tractor,
  tree,
};
