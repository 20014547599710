import { getDuration } from '@state/project/game-task';

/**
 * @typedef { import('./record-types.js').PhaseRecord}  PhaseRecord
 */

export class SheepPhaseSetting {
  /**
   * @param {PhaseRecord} phaseRecord
   */
  constructor(phaseRecord) {
    const {
      showBlockFeedback = 'no',
      type,
      skipTimeMinutes = '1s',
      properThreshold = 0,
      adaptation,
      maxNoResponseTrials,
      blocks,
    } = phaseRecord;

    this.showBlockFeedback = showBlockFeedback === 'yes' ? true : false;
    this.type = type;
    this.skipTimeMinutes = getDuration(skipTimeMinutes, 'm', 'm');
    this.properThreshold = properThreshold;
    this.adaptation = adaptation;
    this.maxNoResponseTrials = maxNoResponseTrials;
    this.blocks = blocks;
    this.numberOfBlocks = { go: this.blocks.go.count, mixed: this.blocks.mixed.count };
  }
}
