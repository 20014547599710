/**
 * @typedef { import('./record-types.js').ChickenDemoRecord}  ChickenDemoRecord
 */
export class ChickenDemo {
  /**
   *
   * @param {ChickenDemoRecord} demoRecord
   */
  constructor(demoRecord) {
    this.animation = {
      trials: demoRecord.animation.trials,
      difficulty: demoRecord.animation.difficulty,
    };

    this.practice = {
      trials: demoRecord.practice.trials,
      difficulty: demoRecord.practice.difficulty,
      maxNoResponseTrials: demoRecord.practice.maxNoResponseTrials,
    };
  }
}
