import { getDuration } from '@state/project/game-task';

/**
 * @typedef { import('./record-types.js').PhaseRecord}  PhaseRecord
 */

export class ChickenPhaseSetting {
  /**
   *
   * @param {PhaseRecord} phaseRecord
   */
  constructor(phaseRecord) {
    const {
      type = 'simple',
      showBlockFeedback = 'no',
      numberOfBlocks = 1,
      trialsPerBlock,
      fixedSequenceArray,
      properThreshold = 0,
      maxNoResponseTrials,
      initialDifficulty,
      skipTimeMinutes = '1s',
      maxDifficulty = 8,
      difficultyChange,
      increaseThreshold,
      increaseDelta,
      decreaseThreshold,
      decreaseDelta,
    } = phaseRecord;

    this.type = type;
    this.showBlockFeedback = showBlockFeedback === 'yes' ? true : false;
    this.numberOfBlocks = numberOfBlocks;
    this.trialsPerBlock = trialsPerBlock;
    this.fixedSequenceArray = fixedSequenceArray;
    this.properThreshold = properThreshold;
    this.maxNoResponseTrials = maxNoResponseTrials;
    this.initialDifficulty = initialDifficulty;
    this.skipTimeMinutes = getDuration(skipTimeMinutes, 'm', 'm');
    this.maxDifficulty = maxDifficulty;
    this.difficultyChange = difficultyChange;
    this.increaseThreshold = increaseThreshold;
    this.increaseDelta = increaseDelta;
    this.decreaseThreshold = decreaseThreshold;
    this.decreaseDelta = decreaseDelta;
  }
}
