export const audio = {
  'chicken trailer': 'C1 Chicken trailer.m4a',
  'choice demo': 'C2 Choice-demo.m4a',
  'choice play': 'C2 Choice-play.m4a',
  'good feedback': 'chicken-call.m4a',
  'chicken call': 'chicken-call.m4a',
  'bad feedback': `C10 bad feedback.m4a`,
  'block info': `C3 Block info.m4a`,
  hold: `C7 Hold.m4a`,
  'no response': `C14 no response.m4a`,
  'last tap': 'egg-glitter.m4a',
  go: 'C8 Go.m4a',
  'demo block cue 1': 'C4 Demo block cue.m4a',
  'demo practice-test fixation cue': 'C7 Hold.m4a',
  'demo animation fixation cue': 'C5 Demo animation fixation cue.m4a',
  'end game': 'C13 end game.m4a',
  'egg-roll': 'egg roll.m4a',
  'egg-crack': 'Egg crack.m4a',
  'chick tweet': 'chick-tweet.m4a',
};

export const chickensAudio = {
  '1chickens': 'C3.1  Block cue.m4a',
  '2chickens': 'C3.2 Block cue.m4a',
  '3chickens': 'C3 Block cue.m4a',
  '4chickens': 'C3.4 Block cue.m4a',
  '5chickens': 'C3.5 Block cue.m4a',
  '6chickens': 'C3.6 Block cue.m4a',
  '7chickens': 'C3.7 Block cue.m4a',
  '8chickens': 'C3.8 Block cue.m4a',
};
