import { html } from 'lit';

export const b0 = html`
  <svg version="1.1" viewBox="0 0 87.292 87.288" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(-130.97 -116.42)" stroke-width=".26458">
      <path
        d="m218.26 160.06c0 24.102-19.54 43.643-43.646 43.643-3.4219 0-6.7522-0.3951-9.9449-1.1395-12.665-2.9493-23.213-11.416-28.969-22.726-3.0268-5.9372-4.7308-12.658-4.7308-19.777 0-24.105 19.54-43.646 43.646-43.646 7.1191 0 13.843 1.7039 19.777 4.7308 11.314 5.7573 19.78 16.305 22.73 28.975 0.74435 3.1927 1.1395 6.5228 1.1395 9.9412"
        fill="#f2f2f2 "
      />
      <path
        d="m194.39 121.15-58.693 58.693c-3.0268-5.9372-4.7308-12.658-4.7308-19.777 0-24.105 19.54-43.646 43.646-43.646 7.1191 0 13.843 1.7039 19.777 4.7308"
        fill="var(--motor-fill, #f00)"
      />
      <path
        d="m218.26 160.06c0 24.102-19.54 43.643-43.646 43.643-3.4219 0-6.7522-0.3951-9.9449-1.1395-0.74435-3.1927-1.1359-6.5193-1.1359-9.9378 0-24.105 19.54-43.646 43.646-43.646 3.4184 0 6.7487 0.3951 9.9412 1.1395 0.74435 3.1927 1.1395 6.5228 1.1395 9.9412"
        fill="var(--motor-fill, #f00)"
      />
      <path
        d="m218.26 160.06c0 24.105-19.54 43.646-43.646 43.646-10.284 0-19.734-3.556-27.191-9.5073 32.366-0.43744 58.473-26.81 58.473-59.28 0-1.8732-0.0882-3.7288-0.25752-5.5562 7.8034 7.8811 12.622 18.729 12.622 30.7"
        fill="#4d4d4d "
        opacity=".2"
      />
      <path
        d="m162.34 143.06c6.0134-7.1853 8.1598-15.294 4.7935-18.111-3.3663-2.817-10.97 0.7239-16.983 7.9092-6.0134 7.1853-8.1598 15.294-4.7935 18.111 3.3663 2.8173 10.97-0.7239 16.983-7.9092"
        fill="#ffffff "
        opacity=".39999"
      />
    </g>
  </svg>
`;
