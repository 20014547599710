import { fixupDurationValues } from '@state/project/game-task';

const fields_ = [
  ['stimulus', 'ms'],
  ['fixation', 'ms'],
  ['hold', 'ms'],
  ['feedback', 'ms'],
  ['noResponseTimeoutInitial', 'ms'],
  ['noResponseTimeout', 'ms'],
];

/**
 * @typedef { import('./record-types.js').DurationsRecord}  DurationsRecord
 *
 * @typedef {{stimulus: Number, fixation: Number, hold: Number, feedback: Number,
 * noResponseTimeoutInitial: Number, noResponseTimeout: Number }} ChickenDurationsSettings
 */

export class ChickenDurations {
  /**
   *
   * @param {DurationsRecord} durationsRecord
   */
  constructor(durationsRecord) {
    const durations = /** @type {ChickenDurationsSettings } */ (fixupDurationValues({ ...durationsRecord }, fields_));

    this.stimulus = durations.stimulus;
    this.fixation = durations.fixation;
    this.hold = durations.hold;
    this.feedback = durations.feedback;
    this.noResponseTimeoutInitial = durations.noResponseTimeoutInitial;
    this.noResponseTimeout = durations.noResponseTimeout;
  }
}
