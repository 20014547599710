import { html } from 'lit';

export const longFence = html`
  <svg
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 720 185"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
  >
    <defs>
      <linearGradient
        id="linearGradient10602"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(0 366.2 -366.2 0 77.12 8.112)"
      >
        <stop stop-color="#3d5930" offset="0" />
        <stop stop-color="#3b472a" offset="1" />
      </linearGradient>
    </defs>
    <path d="m714.4 185h-16.21v-82.64l8.005-7.536 8.208 7.537v82.64z" fill="#786d4f" />
    <path d="m705.6 94.86v90.07h8.1v-82.64l-8.1-7.437z" fill="#5f5339" />
    <path d="m611.7 182.5h-16.21v-82.64l8.005-7.536 8.208 7.538v82.64z" fill="#786d4f" />
    <path d="m603.7 92.49v90.07h8.1v-82.64l-8.1-7.438z" fill="#5f5339" />
    <path d="m509.2 173.7h-16.21v-82.64l8.005-7.536 8.208 7.538v82.64z" fill="#786d4f" />
    <path d="m501.1 83.74v90.07h8.1v-82.64l-8.1-7.438z" fill="#5f5339" />
    <path d="m406.7 162.5h-16.21v-82.64l8.005-7.536 8.208 7.538v82.64z" fill="#786d4f" />
    <path d="m398.6 72.49v90.07h8.101v-82.64l-8.101-7.438z" fill="#5f5339" />
    <path d="m304.2 143.7h-16.21v-82.64l8.005-7.536 8.208 7.538v82.64z" fill="#786d4f" />
    <path d="m296.1 53.74v90.07h8.1v-82.64l-8.1-7.438z" fill="#5f5339" />
    <path d="m201.7 125h-16.21v-82.64l8.005-7.536 8.208 7.538v82.64z" fill="#786d4f" />
    <path d="m193.6 34.99v90.07h8.1v-82.64l-8.1-7.438z" fill="#5f5339" />
    <path d="m99.12 105h-16.21v-82.64l8.005-7.536 8.208 7.538v82.64z" fill="#786d4f" />
    <path d="m91.12 14.99v90.07h8.101v-82.64l-8.101-7.438z" fill="#5f5339" />
    <path d="m688.1 185h-16.21v-82.64l8.005-7.536 8.208 7.537v82.64z" fill="#786d4f" />
    <path d="m680.6 94.86v90.07h8.1v-82.64l-8.1-7.437z" fill="#5f5339" />
    <path d="m586.1 181.2h-16.21v-82.64l8.005-7.536 8.208 7.538v82.64z" fill="#786d4f" />
    <path d="m578.1 91.24v90.07h8.1v-82.64l-8.1-7.438z" fill="#5f5339" />
    <path d="m483.6 171.2h-16.21v-82.64l8.005-7.536 8.208 7.538v82.64z" fill="#786d4f" />
    <path d="m475.5 81.24v90.07h8.101v-82.64l-8.101-7.438z" fill="#5f5339" />
    <path d="m381.1 158.7h-16.21v-82.64l8.005-7.536 8.208 7.538v82.64z" fill="#786d4f" />
    <path d="m373 68.74v90.07h8.1v-82.64l-8.1-7.438z" fill="#5f5339" />
    <path d="m278.6 138.7h-16.21v-82.64l8.005-7.536 8.208 7.538v82.64z" fill="#786d4f" />
    <path d="m270.5 48.74v90.07h8.1v-82.64l-8.1-7.438z" fill="#5f5339" />
    <path d="m176.1 120h-16.21v-82.64l8.005-7.536 8.208 7.538v82.64z" fill="#786d4f" />
    <path d="m168 29.99v90.07h8.1v-82.64l-8.1-7.438z" fill="#5f5339" />
    <path d="m73.49 101.1h-16.21v-82.64l8.004-7.536 8.209 7.538v82.64z" fill="#786d4f" />
    <path d="m65.37 10.99v90.07h8.1v-82.64l-8.1-7.438z" fill="#5f5339" />
    <path d="m663.1 185h-16.21v-82.64l8.005-7.536 8.208 7.537v82.64z" fill="#786d4f" />
    <path d="m655 94.86v90.07h8.1v-82.64l-8.1-7.437z" fill="#5f5339" />
    <path d="m560.5 178.7h-16.21v-82.64l8.004-7.536 8.209 7.538v82.64z" fill="#786d4f" />
    <path d="m552.5 88.74v90.07h8.1v-82.64l-8.1-7.438z" fill="#5f5339" />
    <path d="m458 168.7h-16.21v-82.64l8.004-7.536 8.209 7.538v82.64z" fill="#786d4f" />
    <path d="m449.9 78.74v90.07h8.1v-82.64l-8.1-7.438z" fill="#5f5339" />
    <path d="m355.5 153.7h-16.21v-82.64l8.005-7.536 8.208 7.538v82.64z" fill="#786d4f" />
    <path d="m347.4 63.74v90.07h8.1v-82.64l-8.1-7.438z" fill="#5f5339" />
    <path d="m253 135h-16.21v-82.64l8.005-7.536 8.208 7.538v82.64z" fill="#786d4f" />
    <path d="m244.9 44.99v90.07h8.1v-82.64l-8.1-7.438z" fill="#5f5339" />
    <path d="m150.4 115h-16.21v-82.64l8.005-7.536 8.208 7.538v82.64z" fill="#786d4f" />
    <path d="m142.4 24.99v90.07h8.1v-82.64l-8.1-7.438z" fill="#5f5339" />
    <path d="m47.87 96.24h-16.21v-82.64l8.005-7.536 8.208 7.538v82.64z" fill="#786d4f" />
    <path d="m39.74 6.237v90.08h8.1v-82.64l-8.1-7.438z" fill="#5f5339" />
    <path d="m637.5 185h-16.21v-82.64l8.004-7.536 8.209 7.537v82.64z" fill="#786d4f" />
    <path d="m629.4 94.86v90.07h8.1v-82.64l-8.1-7.437z" fill="#5f5339" />
    <path d="m534.9 177.5h-16.21v-82.64l8.004-7.536 8.209 7.538v82.64z" fill="#786d4f" />
    <path d="m526.9 87.49v90.07h8.1v-82.64l-8.1-7.438z" fill="#5f5339" />
    <path d="m432.4 166.2h-16.21v-82.64l8.005-7.536 8.208 7.538v82.64z" fill="#786d4f" />
    <path d="m424.2 76.24v90.07h8.1v-82.64l-8.1-7.438z" fill="#5f5339" />
    <path d="m329.9 147.5h-16.21v-82.64l8.005-7.536 8.208 7.538v82.64z" fill="#786d4f" />
    <path d="m321.7 57.49v90.07h8.1v-82.64l-8.1-7.438z" fill="#5f5339" />
    <path d="m227.4 130h-16.21v-82.64l8.005-7.536 8.208 7.538v82.64z" fill="#786d4f" />
    <path d="m219.2 39.99v90.07h8.1v-82.64l-8.1-7.438z" fill="#5f5339" />
    <path d="m124.7 110h-16.21v-82.64l8.005-7.536 8.208 7.538v82.64z" fill="#786d4f" />
    <path d="m116.7 19.99v90.07h8.1v-82.64l-8.1-7.438z" fill="#5f5339" />
    <path d="m22.24 92.49h-16.21v-82.64l8.005-7.536 8.208 7.537v82.64z" fill="#786d4f" />
    <path d="m14.12 2.487v90.08h8.1v-82.64l-8.1-7.437z" fill="#5f5339" />
    <path d="m5.992 64.36 0.01375 0.0025v-3.209l-0.01375-0.0012v3.208z" fill="url(#linearGradient10602)" />
    <path d="m5.992 61.11v3.209l8.114 1.388v-3.209l-8.114-1.388z" fill="#534832" />
    <path d="m14.12 62.49v3.209l8.086 1.384v-3.21l-8.086-1.382z" fill="#4f442e" />
    <path d="m47.87 71.86 0.0525 0.01v-3.209l-0.0525-0.01v3.209z" fill="url(#linearGradient10602)" />
    <path d="m31.74 65.61v3.209l8.061 1.504v-3.21l-8.061-1.502z" fill="#534832" />
    <path d="m39.74 67.11v3.209l8.1 1.51v-3.209l-8.1-1.51z" fill="#4f442e" />
    <path d="m73.49 76.86h0.0012v-3.21h-0.0012v3.21z" fill="url(#linearGradient10602)" />
    <path d="m57.37 70.49v3.21l8.111 1.588v-3.21l-8.111-1.588z" fill="#534832" />
    <path d="m65.37 72.11v3.21l8.1 1.585v-3.21l-8.1-1.585z" fill="#4f442e" />
    <path d="m82.99 78.86 0.01 0.0025v-3.21l-0.01-0.0012v3.209z" fill="url(#linearGradient10602)" />
    <path d="m82.99 75.61v3.21l8.112 1.588v-3.21l-8.112-1.588z" fill="#534832" />
    <path d="m91.12 77.24v3.209l8.091 1.584v-3.209l-8.091-1.584z" fill="#4f442e" />
    <path d="m124.7 87.11 0.025 0.005v-3.21l-0.025-0.0037v3.209z" fill="url(#linearGradient10602)" />
    <path d="m108.6 80.74v3.209l8.089 1.584v-3.21l-8.089-1.582z" fill="#534832" />
    <path d="m116.7 82.36v3.209l8.1 1.586v-3.21l-8.1-1.585z" fill="#4f442e" />
    <path d="m150.4 92.24 0.0175 0.0037v-3.21l-0.0175-0.0025v3.209z" fill="url(#linearGradient10602)" />
    <path d="m134.2 85.86v3.209l8.095 1.585v-3.21l-8.095-1.584z" fill="#534832" />
    <path d="m142.4 87.49v3.209l8.1 1.586v-3.21l-8.1-1.585z" fill="#4f442e" />
    <path d="m176.1 97.36 0.0237 0.0037v-3.209l-0.0237-0.005v3.21z" fill="url(#linearGradient10602)" />
    <path d="m159.9 90.99v3.209l8.09 1.584v-3.209l-8.09-1.584z" fill="#534832" />
    <path d="m168 92.61v3.209l8.1 1.586v-3.21l-8.1-1.585z" fill="#4f442e" />
    <path d="m201.7 102.5 0.0288 0.005v-3.209l-0.0288-0.0062v3.21z" fill="url(#linearGradient10602)" />
    <path d="m185.5 96.11v3.209l8.085 1.582v-3.209l-8.085-1.582z" fill="#534832" />
    <path d="m193.6 97.61v3.209l8.1 1.586v-3.21l-8.1-1.585z" fill="#4f442e" />
    <path d="m211.1 104.2 0.004 0.001v-3.21l-0.004-0.001v3.21z" fill="url(#linearGradient10602)" />
    <path d="m211.1 101v3.21l8.112 1.588v-3.21l-8.112-1.588z" fill="#534832" />
    <path d="m219.2 102.6v3.21l8.098 1.584v-3.209l-8.098-1.585z" fill="#4f442e" />
    <path d="m253 112.2 0.0387 0.007v-3.209l-0.0387-0.009v3.21z" fill="url(#linearGradient10602)" />
    <path d="m236.7 106v3.209l8.074 1.519v-3.21l-8.074-1.518z" fill="#534832" />
    <path d="m244.9 107.5v3.209l8.1 1.524v-3.21l-8.1-1.522z" fill="#4f442e" />
    <path d="m278.6 116.9 0.0325 0.005v-3.21l-0.0325-0.005v3.21z" fill="url(#linearGradient10602)" />
    <path d="m262.4 110.7v3.21l8.081 1.45v-3.209l-8.081-1.451z" fill="#534832" />
    <path d="m270.5 112.2v3.209l8.1 1.455v-3.209l-8.1-1.455z" fill="#4f442e" />
    <path d="m304.2 121.5 0.0138 0.001v-3.209l-0.0138-0.002v3.21z" fill="url(#linearGradient10602)" />
    <path d="m288 115.4v3.209l8.1 1.455v-3.21l-8.1-1.454z" fill="#534832" />
    <path d="m296.1 116.9v3.209l8.1 1.455v-3.21l-8.1-1.454z" fill="#4f442e" />
    <path d="m329.9 125.9 0.0312 0.007v-3.21l-0.0312-0.006v3.209z" fill="url(#linearGradient10602)" />
    <path d="m313.6 119.7v3.209l8.082 1.451v-3.209l-8.082-1.451z" fill="#534832" />
    <path d="m321.7 121.2v3.209l8.1 1.455v-3.21l-8.1-1.454z" fill="#4f442e" />
    <path d="m355.5 130 0.0487 0.009v-3.209l-0.0487-0.009v3.209z" fill="url(#linearGradient10602)" />
    <path d="m339.4 124.1v3.209l8.065 1.349v-3.209l-8.065-1.349z" fill="#534832" />
    <path d="m347.4 125.5v3.209l8.1 1.355v-3.21l-8.1-1.354z" fill="#4f442e" />
    <path d="m364.9 131.5 0.0337 0.006v-3.21l-0.0337-0.005v3.209z" fill="url(#linearGradient10602)" />
    <path d="m364.9 128.4v3.21l8.112 1.281v-3.21l-8.112-1.281z" fill="#534832" />
    <path d="m373 129.6v3.21l8.068 1.274v-3.21l-8.068-1.274z" fill="#4f442e" />
    <path d="m390.5 135.4 0.0412 0.006v-3.208l-0.0412-0.008v3.209z" fill="url(#linearGradient10602)" />
    <path d="m390.5 132.1v3.209l8.112 1.281v-3.209l-8.112-1.281z" fill="#534832" />
    <path d="m398.6 133.5v3.209l8.06 1.274v-3.21l-8.06-1.272z" fill="#4f442e" />
    <path d="m432.4 141.5 0.015 0.001v-3.209l-0.015-0.002v3.21z" fill="url(#linearGradient10602)" />
    <path d="m416.2 136v3.209l8.098 1.154v-3.209l-8.098-1.154z" fill="#534832" />
    <path d="m424.2 137.1v3.209l8.1 1.155v-3.209l-8.1-1.155z" fill="#4f442e" />
    <path d="m458 144.9 0.045 0.007v-3.21l-0.045-0.008v3.21z" fill="url(#linearGradient10602)" />
    <path d="m441.9 139.5v3.209l8.068 1.056v-3.209l-8.068-1.056z" fill="#534832" />
    <path d="m449.9 140.6v3.209l8.1 1.061v-3.209l-8.1-1.061z" fill="#4f442e" />
    <path d="m483.6 148 0.0387 0.005v-3.21l-0.0387-0.004v3.209z" fill="url(#linearGradient10602)" />
    <path d="m467.5 142.9v3.209l8.075 0.9462v-3.21l-8.075-0.945z" fill="#534832" />
    <path d="m475.5 143.7v3.209l8.101 0.9488v-3.209l-8.101-0.9488z" fill="#4f442e" />
    <path d="m509.2 150.9 0.0562 0.006v-3.21l-0.0562-0.006v3.21z" fill="url(#linearGradient10602)" />
    <path d="m493.1 145.7v3.209l8.056 0.9438v-3.209l-8.056-0.9438z" fill="#534832" />
    <path d="m501.1 146.7v3.209l8.1 0.9488v-3.209l-8.1-0.9488z" fill="#4f442e" />
    <path d="m518.6 151.9 0.0388 0.002v-3.209l-0.0388-0.004v3.21z" fill="url(#linearGradient10602)" />
    <path d="m518.7 148.7v3.21l8.112 0.8238v-3.209l-8.112-0.825z" fill="#534832" />
    <path d="m526.9 149.5v3.209l8.062 0.82v-3.209l-8.062-0.82z" fill="#4f442e" />
    <path d="m560.5 155.9 0.0412 0.005v-3.21l-0.0412-0.004v3.209z" fill="url(#linearGradient10602)" />
    <path d="m544.4 151v3.209l8.07 0.8212v-3.21l-8.07-0.82z" fill="#534832" />
    <path d="m552.5 151.9v3.209l8.1 0.8238v-3.209l-8.1-0.8238z" fill="#4f442e" />
    <path d="m586.1 157.9 0.035 0.002v-3.209l-0.035-0.004v3.21z" fill="url(#linearGradient10602)" />
    <path d="m570 153.4v3.21l8.078 0.6338v-3.209l-8.078-0.635z" fill="#534832" />
    <path d="m578.1 154v3.209l8.1 0.6362v-3.209l-8.1-0.6362z" fill="#4f442e" />
    <path d="m611.7 159.5 0.0288 0.001v-3.209l-0.0288-0.002v3.21z" fill="url(#linearGradient10602)" />
    <path d="m595.6 155.4v3.209l8.085 0.4488v-3.21l-8.085-0.4475z" fill="#534832" />
    <path d="m603.7 155.9v3.209l8.1 0.45v-3.21l-8.1-0.4488z" fill="#4f442e" />
    <path d="m637.5 160.9 0.0213 0.001v-3.209l-0.0213-0.001v3.209z" fill="url(#linearGradient10602)" />
    <path d="m621.2 156.9v3.21l8.091 0.3788v-3.209l-8.091-0.38z" fill="#534832" />
    <path d="m629.4 157.2v3.209l8.1 0.38v-3.209l-8.1-0.38z" fill="#4f442e" />
    <path d="m663.1 161.7h0.015v-3.21h-0.015v3.21z" fill="url(#linearGradient10602)" />
    <path d="m646.9 158v3.21l8.099 0.2612v-3.21l-8.099-0.2612z" fill="#534832" />
    <path d="m655 158.2v3.21l8.1 0.2612v-3.21l-8.1-0.2612z" fill="#4f442e" />
    <path d="m672.5 162h0.0175v-3.209h-0.0175v3.209z" fill="url(#linearGradient10602)" />
    <path d="m672.5 158.7v3.21l8.112 0.0737v-3.21l-8.112-0.0737z" fill="#534832" />
    <path d="m680.6 158.9v3.21l8.084 0.0737v-3.21l-8.084-0.0737z" fill="#4f442e" />
    <path d="m714.4 162.1v-3.209 3.209z" fill="url(#linearGradient10602)" />
    <path d="m705.6 159-8.112 0.02v3.209l8.112-0.02v-3.209z" fill="#534832" />
    <path d="m714.4 159-8.1 0.02v3.209l8.1-0.02v-3.209z" fill="#4f442e" />
    <path
      d="m5.617 58.49c110 17.13 440 100.9 708.8 97.81"
      stroke="#82775d"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="5"
      fill="none"
    />
    <g fill="#a1977d">
      <path d="m705.6 157.7c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m680.6 157.5c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m654.9 157c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m629.4 156c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m603.7 154.5c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m578.1 152.7c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m552.4 150.5c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m526.7 148.2c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m501.1 145.5c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m475.6 142.6c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m449.9 139.4c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m424.2 136c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m398.7 132.1c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m373 128.4c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m347.4 124.2c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m321.7 120.1c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m296.1 115.6c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m270.5 111c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m244.9 106.2c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m219.2 101.4c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m193.5 96.36c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m167.9 91.36c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m142.2 86.24c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m116.7 80.99c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m90.99 75.74c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m65.24 70.86c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m39.62 65.74c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m14.12 61.11c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
    </g>
    <path d="m5.992 31.86v3.209l0.01375 0.0025v-3.209l-0.01375-0.0025z" fill="#3d592f" />
    <path d="m5.992 31.86v3.209l8.114 1.388v-3.209l-8.114-1.388z" fill="#534832" />
    <path d="m14.12 33.24v3.209l8.086 1.384v-3.21l-8.086-1.382z" fill="#4f442e" />
    <path d="m47.87 39.36v3.209l0.0525 0.01v-3.209l-0.0525-0.01z" fill="#3d592f" />
    <path d="m31.74 36.36v3.209l8.061 1.504v-3.21l-8.061-1.502z" fill="#534832" />
    <path d="m39.74 37.86v3.209l8.1 1.51v-3.209l-8.1-1.51z" fill="#4f442e" />
    <path d="m73.49 44.36v3.209h0.0012v-3.209h-0.0012z" fill="#3d592f" />
    <path d="m57.37 41.11v3.21l8.111 1.586v-3.209l-8.111-1.588z" fill="#534832" />
    <path d="m65.37 42.74v3.209l8.1 1.586v-3.21l-8.1-1.585z" fill="#4f442e" />
    <path d="m82.99 46.36v3.21l0.01 0.0012v-3.209l-0.01-0.0024z" fill="#3d592f" />
    <path d="m82.99 46.36v3.21l8.112 1.588v-3.21l-8.112-1.588z" fill="#534832" />
    <path d="m91.12 47.86v3.209l8.091 1.584v-3.209l-8.091-1.584z" fill="#4f442e" />
    <path d="m124.7 54.61v3.21l0.025 0.0037v-3.209l-0.025-0.005z" fill="#3d592f" />
    <path d="m108.6 51.49v3.209l8.089 1.584v-3.21l-8.089-1.582z" fill="#534832" />
    <path d="m116.7 52.99v3.209l8.1 1.586v-3.21l-8.1-1.585z" fill="#4f442e" />
    <path d="m150.4 59.74v3.21l0.0175 0.0025v-3.209l-0.0175-0.0037z" fill="#3d592f" />
    <path d="m134.2 56.61v3.21l8.095 1.584v-3.209l-8.095-1.585z" fill="#534832" />
    <path d="m142.4 58.24v3.21l8.1 1.585v-3.21l-8.1-1.585z" fill="#4f442e" />
    <path d="m176.1 64.86v3.21l0.0225 0.0038v-3.209l-0.0225-0.005z" fill="#3d592f" />
    <path d="m159.9 61.74v3.209l8.09 1.584v-3.209l-8.09-1.584z" fill="#534832" />
    <path d="m168 63.36v3.209l8.1 1.586v-3.21l-8.1-1.585z" fill="#4f442e" />
    <path d="m201.7 69.99v3.21l0.0288 0.005v-3.21l-0.0288-0.005z" fill="#3d592f" />
    <path d="m185.5 66.74v3.209l8.085 1.582v-3.209l-8.085-1.582z" fill="#534832" />
    <path d="m193.6 68.36v3.209l8.1 1.586v-3.21l-8.1-1.585z" fill="#4f442e" />
    <path d="m211.1 71.74v3.21h0.004v-3.209l-0.004-0.0012z" fill="#3d592f" />
    <path d="m211.1 71.74v3.21l8.112 1.588v-3.21l-8.112-1.588z" fill="#534832" />
    <path d="m219.2 73.36v3.209l8.098 1.585v-3.209l-8.098-1.585z" fill="#4f442e" />
    <path d="m253 79.74v3.21l0.04 0.0075v-3.21l-0.04-0.0075z" fill="#3d592f" />
    <path d="m236.7 76.74v3.209l8.074 1.519v-3.21l-8.074-1.518z" fill="#534832" />
    <path d="m244.9 78.24v3.209l8.1 1.524v-3.21l-8.1-1.522z" fill="#4f442e" />
    <path d="m278.6 84.36v3.209l0.0325 0.0063v-3.209l-0.0325-0.0063z" fill="#3d592f" />
    <path d="m262.4 81.49v3.21l8.081 1.45v-3.209l-8.081-1.451z" fill="#534832" />
    <path d="m270.5 82.99v3.209l8.1 1.455v-3.209l-8.1-1.455z" fill="#4f442e" />
    <path d="m304.2 88.99v3.21l0.0138 0.0013v-3.209l-0.0138-0.0025z" fill="#3d592f" />
    <path d="m288 86.11v3.209l8.1 1.455v-3.21l-8.1-1.454z" fill="#534832" />
    <path d="m296.1 87.49v3.209l8.1 1.455v-3.21l-8.1-1.454z" fill="#4f442e" />
    <path d="m329.9 93.36v3.21l0.0312 0.005v-3.209l-0.0312-0.0063z" fill="#3d592f" />
    <path d="m313.6 90.49v3.209l8.082 1.451v-3.209l-8.082-1.451z" fill="#534832" />
    <path d="m321.7 91.86v3.209l8.1 1.455v-3.21l-8.1-1.454z" fill="#4f442e" />
    <path d="m355.5 97.49v3.21l0.0487 0.007v-3.209l-0.0487-0.0088z" fill="#3d592f" />
    <path d="m339.4 94.86v3.209l8.065 1.349v-3.209l-8.065-1.349z" fill="#534832" />
    <path d="m347.4 96.11v3.209l8.1 1.355v-3.21l-8.1-1.354z" fill="#4f442e" />
    <path d="m364.9 98.99v3.21l0.0337 0.005v-3.21l-0.0337-0.005z" fill="#3d592f" />
    <path d="m364.9 98.99v3.21l8.112 1.281v-3.21l-8.112-1.281z" fill="#534832" />
    <path d="m373 100.2v3.21l8.068 1.274v-3.21l-8.068-1.274z" fill="#4f442e" />
    <path d="m390.5 102.9v3.209l0.04 0.006v-3.209l-0.04-0.006z" fill="#3d592f" />
    <path d="m390.5 102.9v3.209l8.112 1.281v-3.209l-8.112-1.281z" fill="#534832" />
    <path d="m398.6 104.1v3.209l8.06 1.274v-3.21l-8.06-1.272z" fill="#4f442e" />
    <path d="m432.4 109v3.21l0.015 0.001v-3.209l-0.015-0.002z" fill="#3d592f" />
    <path d="m416.2 106.7v3.21l8.098 1.154v-3.21l-8.098-1.154z" fill="#534832" />
    <path d="m424.2 107.9v3.21l8.1 1.154v-3.209l-8.1-1.155z" fill="#4f442e" />
    <path d="m458 112.4v3.21l0.0462 0.005v-3.209l-0.0462-0.006z" fill="#3d592f" />
    <path d="m441.9 110.2v3.209l8.068 1.056v-3.209l-8.068-1.056z" fill="#534832" />
    <path d="m449.9 111.4v3.209l8.1 1.061v-3.209l-8.1-1.061z" fill="#4f442e" />
    <path d="m483.6 115.5v3.21l0.0387 0.004v-3.209l-0.0387-0.005z" fill="#3d592f" />
    <path d="m467.5 113.5v3.209l8.075 0.9462v-3.21l-8.075-0.945z" fill="#534832" />
    <path d="m475.5 114.5v3.209l8.101 0.9488v-3.209l-8.101-0.9488z" fill="#4f442e" />
    <path d="m509.2 118.4v3.209l0.0562 0.006v-3.209l-0.0562-0.006z" fill="#3d592f" />
    <path d="m493.1 116.5v3.209l8.056 0.9438v-3.209l-8.056-0.9438z" fill="#534832" />
    <path d="m501.1 117.5v3.209l8.1 0.9488v-3.209l-8.1-0.9488z" fill="#4f442e" />
    <path d="m518.6 119.4v3.21l0.0388 0.004v-3.21l-0.0388-0.004z" fill="#3d592f" />
    <path d="m518.7 119.5v3.21l8.112 0.8238v-3.209l-8.112-0.825z" fill="#534832" />
    <path d="m526.9 120.2v3.209l8.062 0.82v-3.209l-8.062-0.82z" fill="#4f442e" />
    <path d="m560.5 123.4v3.21l0.0425 0.004v-3.21l-0.0425-0.004z" fill="#3d592f" />
    <path d="m544.4 121.7v3.209l8.07 0.8212v-3.21l-8.07-0.82z" fill="#534832" />
    <path d="m552.5 122.5v3.209l8.1 0.8238v-3.209l-8.1-0.8238z" fill="#4f442e" />
    <path d="m586.1 125.4v3.209l0.035 0.003v-3.209l-0.035-0.003z" fill="#3d592f" />
    <path d="m570 124.1v3.21l8.078 0.6338v-3.209l-8.078-0.635z" fill="#534832" />
    <path d="m578.1 124.7v3.209l8.1 0.6362v-3.209l-8.1-0.6362z" fill="#4f442e" />
    <path d="m611.7 127v3.21h0.0188c0.004-0.0287 0.006-0.06 0.01-0.09v-3.119l-0.0288-0.001" fill="#3d592f" />
    <path d="m611.9 130.1c-0.004 0.03-0.006 0.06-0.01 0.09l0.01 0.001v-0.0913" fill="#4d3e25" />
    <path d="m595.6 126.1v3.209l8.085 0.4488v-3.21l-8.085-0.4475z" fill="#534832" />
    <path d="m603.7 126.5v3.209l8.1 0.45v-3.21l-8.1-0.4488z" fill="#4f442e" />
    <path d="m637.5 128.4v3.209l0.0213 0.001v-3.209l-0.0213-0.001z" fill="#4d3e25" />
    <path d="m621.2 127.6v3.21l8.091 0.3788v-3.209l-8.091-0.38z" fill="#534832" />
    <path d="m629.4 128v3.209l8.1 0.38v-3.209l-8.1-0.38z" fill="#4f442e" />
    <path d="m663.1 129.2v3.21h0.0138v-3.21h-0.0138z" fill="#3d592f" />
    <path d="m646.9 128.7v3.209l8.099 0.2612v-3.209l-8.099-0.2612z" fill="#534832" />
    <path d="m655 129v3.209l8.1 0.2625v-3.21l-8.1-0.2612z" fill="#4f442e" />
    <path d="m672.5 129.5v3.21h0.0188v-3.21h-0.0188z" fill="#3d592f" />
    <path d="m672.5 129.5v3.21l8.112 0.0737v-3.21l-8.112-0.0738z" fill="#534832" />
    <path d="m680.6 129.5v3.21l8.084 0.0737v-3.21l-8.084-0.0738z" fill="#4f442e" />
    <path d="m714.4 129.6v3.209-3.209z" fill="#3d592f" />
    <path d="m705.6 129.7-8.112 0.02v3.209l8.112-0.02v-3.209z" fill="#534832" />
    <path d="m714.4 129.6-8.1 0.02v3.209l8.1-0.02v-3.209z" fill="#4f442e" />
    <path
      d="m5.617 29.24c110 17.13 440 100.9 708.8 97.86"
      stroke="#82775d"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="5"
      fill="none"
    />
    <g fill="#a1977d">
      <path d="m705.6 128.5c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m680.6 128.2c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m654.9 127.7c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m629.4 126.7c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m603.7 125.2c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m578.1 123.5c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m552.4 121.2c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m526.7 119c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m501.1 116.2c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m475.6 113.4c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m449.9 110.1c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m424.2 106.7c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m398.7 102.9c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m373 99.11c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m347.4 94.99c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m321.7 90.86c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m296.1 86.36c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m270.5 81.74c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m244.9 76.99c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m219.2 72.11c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m193.5 67.11c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m167.9 62.11c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m142.2 56.99c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m116.7 51.74c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m90.99 46.49c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m65.24 41.49c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m39.62 36.49c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
      <path d="m14.12 31.86c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25" />
    </g>
  </svg>
`;
