export const audio = {
  'memory trailer': 'Me1 memory trailer.m4a',
  'choice demo': 'C2 Choice-demo.m4a',
  'choice play': 'C2 Choice-play.m4a',
  'demo block cue 1': 'C4 Demo block cue.m4a',
  'demo fixation cue': 'Me5 fixation cue.m4a',
  'block cue': 'Me3 block cue.m4a',
  'chicken sound': 'chicken-call.m4a',
  'sheep sound': 'sheep-baa.m4a',
  'end game': 'C13 end game.m4a',
  tractor: 'small-tractor.m4a',
  'animation block cue': 'Me10 animation block cue.m4a',
  'animation fixation cue': 'Me11 animation fixation cue.m4a',
};
