import { ContextConsumer, ContextProvider } from '@lit/context';
import { Task } from '@lit/task';
import { ViewController } from '@tasks/chicken/view-controller.js';
import { LitElement, css, html, nothing } from 'lit';
import { profileContext, projectContext } from '~/app/contexts.js';
import { bg } from '~/graphics/bg.js';
import { audio } from './memory-audio.js';
import { memoryTaskContext } from './memory-context.js';
import { TaskController } from './task-controller.js';

/**
 * @typedef {import('./memory-types.js').TrialResult} TrialResult
 */

const _targetRatio = 16 / 10;

export class MemoryGame extends LitElement {
  static properties = { taskId: { type: String }, block: { state: true }, progressValue: { state: true } };

  taskProvider = new ContextProvider(this, { context: memoryTaskContext });

  /**
   * @type {(value?:{trialResponses:[], maxNoResponseExceeded: boolean}|{action:string}) => void}
   */
  viewNextPromiseResolve;

  constructor() {
    super();

    this.taskId = '';
    this.block = undefined;
    this.progressValue = 0;
    this.lastProgress = 0;
    this.blockProgress = 0;
    this.choiceOnly = false;

    new ContextConsumer(this, { context: projectContext, callback: value => (this.project = value) });
    new ContextConsumer(this, { context: profileContext, callback: value => (this.profile = value) });

    this.viewNextPromise = this.getViewNextPromise();
    this.taskController = undefined;

    this.viewController = new ViewController(this, [
      {
        name: 'none',
        render: () => nothing,
      },
      {
        name: 'trailer',
        render: () => html`
          <onc-task-trailer ?choiceOnly=${this.choiceOnly} @next=${this.onTrailerNext}></onc-task-trailer>
        `,
      },
      {
        name: 'presenter',
        render: (block, phaseSettings) => {
          const showBorder = this.taskController.showLayout;
          return html`
            <onc-task-progress-bar class="progress-bar" .value=${this.progressValue}> </onc-task-progress-bar>
            <onc-layout-task class="memory-layout" ?showBorder=${showBorder} .targetRatio=${_targetRatio}
              ><onc-memory-presenter
                @next=${this.viewNext}
                .block=${block}
                .phaseSettings=${phaseSettings}
                @progress=${this.onProgress}
              ></onc-memory-presenter
            ></onc-layout-task>
          `;
        },
      },
      {
        name: 'block-feedback',
        render: () => {
          const showBorder = this.taskController.showLayout;
          return html`
            <onc-memory-block-feedback
              ?showBorder=${showBorder}
              .progress=${this.blockProgress}
              .lastProgress=${this.lastProgress}
              @next=${this.viewNext}
              .block=${this.block}
            ></onc-memory-block-feedback>
          `;
        },
      },
      {
        name: 'end-game',
        render: () => {
          const showBorder = this.taskController.showLayout;
          return html`
            <onc-layout-task ?showBorder=${showBorder} .targetRatio=${_targetRatio}>
              <onc-end-game ?showBorder=${showBorder} @next=${this.viewNext}></onc-end-game>
            </onc-layout-task>
          `;
        },
      },
    ]);

    this.getSounds = new Task(this, {
      task: async ([taskId]) => {
        this.taskController = new TaskController(this, taskId);
        this.taskProvider.setValue(this.taskController);

        const stimuli = {
          targets: this.taskController.trialTargets,
          distractorsSheep: this.taskController.distractorsSheep,
          distractorsChicken: this.taskController.distractorsChicken,
        };

        await this.taskController.createSounds(audio);
        await this.taskController.setupResults([{ start: new Date(), stimuli }]);

        this.next();
      },
      args: () => [this.taskId],
    });
  }

  render() {
    return html`<onc-screen-svg>${bg}</onc-screen-svg>${this.getSounds.render({
        complete: () => {
          return html`${this.viewController.outlet()}`;
        },
      })} `;
  }

  /**
   * @param {Event&{target: {action: String}}} event
   */
  async onTrailerNext(event) {
    const action = event.target.action;

    this.taskController.mode = action;
    this.choiceOnly = true;

    this.viewNextPromiseResolve({ action });

    this.viewNextPromise = this.getViewNextPromise();
  }

  /**
   * @param {Event&{target: {trialResponses: [], maxNoResponseExceeded: boolean}}} event
   */
  async viewNext(event) {
    if (event) {
      const {
        target: { trialResponses, maxNoResponseExceeded },
      } = event;
      this.viewNextPromiseResolve({ trialResponses, maxNoResponseExceeded });
    } else {
      this.viewNextPromiseResolve();
    }
    this.viewNextPromise = this.getViewNextPromise();
  }

  async next() {
    this.taskController.taskStarted();

    for (;;) {
      await this.runPhases();

      if (this.taskController.mode === 'play') {
        break;
      }
    }

    this.done();
  }

  async runPhases() {
    const { showTrailer, debugRewards } = this.taskController;

    if (showTrailer) {
      this.viewController.goto('trailer');
      await this.viewNextPromise;

      if (this.taskController['aborted']) {
        return this.done();
      }
    }

    if (debugRewards) {
      this.lastProgress = 0;
      this.blockProgress = 0.9;

      this.taskController.addRewards({ sheep: 15, chicken: 15 });
      this.viewController.goto('block-feedback');
      await this.viewNextPromise;
    }

    // this.viewController.goto('end-game');
    // await this.viewNextPromise;

    const phases = this.taskController.getPhases();

    this.blockProgress = 0;
    const totalBlocks = this.taskController.totalBlocks;
    this.blockCount = 0;

    for (const { phaseSettings, blocks } of phases) {
      const { numberOfBlocks } = phaseSettings;

      const phaseData = {
        startPhase: new Date(),
        type: phaseSettings.type,
        totalBlocks,
        numberOfBlocks,
      };

      await this.taskController.pushItem(phaseData);

      const blockResult = await this.runBlocks(blocks, phaseSettings, totalBlocks);

      const endInfo = {
        endPhase: new Date(),
      };

      await this.taskController.pushItem(endInfo);

      if (blockResult?.maxNoResponseExceeded) {
        this.viewController.goto('end-game');
        await this.viewNextPromise;
        break;
      }

      if (this.taskController['aborted']) {
        break;
      }
    }
  }

  /**
   * @param {{ id: number; trials: number; }[]} blocks
   * @param {import("./specs/memory-phase-setting.js").MemoryPhaseSetting} phaseSettings
   * @param {number} totalBlocks
   */
  async runBlocks(blocks, phaseSettings, totalBlocks) {
    for (const block of blocks) {
      this.block = {
        ...block,
        maxNoResponseTrials: phaseSettings.maxNoResponseTrials,
      };

      this.viewController.goto('presenter', block, phaseSettings);
      this.requestUpdate();

      this.progressValue = 0;

      const { trialResponses, maxNoResponseExceeded } =
        /** @type {{trialResponses: [], maxNoResponseExceeded: boolean}}*/ (await this.viewNextPromise);

      this.blockCount += 1;
      this.lastProgress = this.blockProgress;
      this.blockProgress = this.blockCount / totalBlocks;

      if (maxNoResponseExceeded) {
        return { maxNoResponseExceeded, id: block.id };
      }

      if (phaseSettings.type !== 'main') {
        continue;
      }

      if (this.taskController['aborted']) {
        break;
      }

      const rewards = this.calculateRewards(trialResponses);

      this.taskController.addRewards(rewards);

      if (phaseSettings.showBlockFeedback) {
        this.viewController.goto('block-feedback', block);

        await this.viewNextPromise;
        if (this.taskController['aborted']) {
          break;
        }
      }
    }
  }

  calculateRewards(responses) {
    const rewards = { sheep: 0, chicken: 0 };
    for (const response of responses) {
      if (!response.noResponse) {
        if (response.stimulusId.startsWith('c')) {
          rewards.chicken += 1;
        } else {
          rewards.sheep += 1;
        }
      }
    }

    return rewards;
  }

  onProgress({ target: { progressValue } }) {
    this.progressValue = progressValue;
  }

  async done() {
    await this.taskController.taskCompleted();
    this.dispatchEvent(new Event('next'));
  }

  /**
   * @returns {Promise<({trialResponses: [], maxNoResponseExceeded: boolean}|{action:string})?>}
   */
  getViewNextPromise() {
    return new Promise(resolve => {
      this.viewNextPromiseResolve = resolve;
    });
  }
}
const OncMemoryGame = class OncMemoryGame extends MemoryGame {
  static styles = css`
    .memory-layout {
      overflow: hidden;
    }
  `;
};
customElements.define('onc-memory-game', OncMemoryGame);
