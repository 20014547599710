import { LitElement, css, html } from 'lit';

export class ScreenSvg extends LitElement {
  render() {
    return html`<slot></slot>`;
  }
}
const OncScreenSvg = class OncScreenSvg extends ScreenSvg {
  static styles = css`
    :host {
      position: absolute;
      z-index: -1;
      inset: 0;
      overflow: hidden;
    }

    ::slotted(svg) {
      width: 100%;
      height: 100%;
    }
  `;
};

customElements.define('onc-screen-svg', OncScreenSvg);
