import { createContext } from '@lit/context';

/**
 * @typedef {import('./task-controller-base.js').TaskControllerBase} TaskControllerBase
 */

/**
 * @type {import('@lit/context').Context<Symbol,TaskControllerBase>}
 */
export const taskContext = createContext(Symbol.for('task'));
