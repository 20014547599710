import { Task } from '@lit/task';
import { LitElement, css, html } from 'lit';
import loader from '../loader.js';

/** @type {{ [x:string]: string; }} */
export const cache_ = {};

export class SvgIcon extends LitElement {
  static properties = {
    src: { type: String },
    svgSource: { state: true },
  };

  constructor() {
    super();

    /** @type {string} */
    this.svgSource = undefined;

    this.src = '';

    this.getSvg = new Task(this, {
      task: async ([source]) => {
        if (!source) {
          return;
        }
        // console.log(source);

        let text = cache_[source];
        if (!text) {
          text = await loader.loadUrl(source).text();
          cache_[source] = text;
        }
        this.svgSource = text;
      },
      args: () => [this.src],
    });
  }

  render() {
    return html`<slot></slot>`;
  }
}

const OncSvgIcon = class OncSvgIcon extends SvgIcon {
  static styles = css`
    /* stylelint-disable-next-line selector-pseudo-element-no-unknown */
    ::sloted(svg) {
      width: var(--onc-svg-icon-width, auto);
      height: var(--onc-svg-icon-height, auto);
    }
  `;
};
customElements.define('onc-svg-icon', OncSvgIcon);
