import { animate } from '@lit-labs/motion';
import { ContextConsumer } from '@lit/context';
import { Task } from '@lit/task';
import { ElapsedTimer } from '@ounce/onc';
import gameDelay from 'delay';
import { LitElement, css, html, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { createRef, ref } from 'lit/directives/ref.js';
import { styleMap } from 'lit/directives/style-map.js';
import { when } from 'lit/directives/when.js';
import { graphics } from '~/graphics/index.js';
import { memoryTaskContext } from './memory-context.js';
import { stimuliSource } from './stimuli-it.js';

/**
 * @typedef {import('./memory-types.js').MemoryBlock} MemoryBlock
 * @typedef {import('./memory-types.js').TrialwithIndex} TrialwithIndex
 * @typedef {import('./memory-types.js').Trial} Trial
 * @typedef {import('./specs/memory-phase-setting.js').MemoryPhaseSetting} MemoryPhaseSetting
 *
 * @typedef {{
 *   type: string;
 *   top: any;
 *   left: any;
 *   width: any;
 *   name: string;
 *   icon: any;
 *   allbg: number;
 *   id: string;
 *   selected?: boolean;
 *   place: Number; styles: Object;
 * }} Item
 */

const locations = [
  [0.4, 0.2, 16],
  [0.4, 0.6, 16],
];

export class MemoryPresenter extends LitElement {
  static properties = {
    block: { type: Object },
    phaseSettings: { type: Object },
    items: { state: true },
    fingerStyles: { state: true },
    fingerShow: { state: true },
  };

  constructor() {
    super();

    this.items = [];

    this.soundController = undefined;

    this.targetRef = createRef();

    /**
     * @type {Generator<TrialwithIndex,void, unknown>}
     */
    this.trialsIt = undefined;

    /**
     * @type {TrialwithIndex}
     */
    this.trial = undefined;
    this.trialResponses = [];
    this.trialNumber = 0;
    this.showLayout = false;

    /** @type {MemoryBlock} */
    this.block = undefined;

    /** @type {MemoryPhaseSetting} */
    this.phaseSettings = undefined;

    this.responseTimer = new ElapsedTimer();

    new ContextConsumer(this, {
      context: memoryTaskContext,
      callback: value => (this.taskController = value),
    });

    this.noResponseCount = 0;
    this.maxNoResponseExceeded = false;
    this.progressValue = 0;
    this.fingerStyles = {};
    this.durations = {};

    this.fingerShow = false;

    this.getTrials = new Task(this, {
      /**
       *
       * @param {[MemoryBlock, MemoryPhaseSetting]} param0
       */
      task: ([block, phaseSettings]) => {
        const { type: phaseType, maxNoResponseTrials } = phaseSettings;
        this.phaseType = phaseType;

        this.targets = stimuliSource(this.taskController.targets);
        this.distractorsSheep = stimuliSource(this.taskController.distractorsSheep);
        this.distractorsChicken = stimuliSource(this.taskController.distractorsChicken);

        this.durations = this.taskController.durations;
        this.showLayout = this.taskController.showLayout;

        this.maxNoResponseTrials = maxNoResponseTrials;
        this.feedbackType = this.taskController.feedbackType;

        if (!this.abortController) {
          this.abortController = new AbortController();
        }

        this.trialsIt = this.trialsGenerator(this.makeTrials(block));
        this.trialNumber = 0;

        this.fingerStyles.top = '90.75%';
        this.fingerStyles.left = '44.34%';
        this.fingerStyles.width = '11.48%';
        this.fingerStyles.height = '23.32%';

        this.fingerStyles['--tap-display'] = 'none';

        this.next().catch(error => {
          if (error?.name !== 'AbortError') {
            console.log(error);
          } else if (this.running) {
            this.done();
          }
        });
      },
      args: () => [this.block, this.phaseSettings],
    });
  }

  /**
   * @param {number} milliseconds
   */
  delay(milliseconds) {
    return gameDelay(milliseconds, { signal: this.abortController.signal });
  }

  /**
   * @param {Trial[]} trials
   *
   * @returns {Generator<TrialwithIndex,void, unknown>}
   */
  *trialsGenerator(trials) {
    let index = 0;
    const theTrials = trials;

    const length = trials.length;

    while (index < length) {
      yield { index, progress: index / length, ...theTrials[index++] };
    }
  }

  /**
   * @param {string} key
   */
  playSound(key) {
    return this.taskController.playSound(key);
  }

  async next() {
    await this.taskController.pushItem({
      startBlock: new Date(),
      block: this.block.id,
    });

    if (this.phaseType === 'animation') {
      await this.playSound('animation block cue');
    } else {
      if (this.phaseType === 'practice') {
        await this.playSound('demo block cue 1');
      }

      const cueKey = `block cue`;
      await this.playSound(cueKey);
    }

    if (this.abortController.signal.aborted) {
      return;
    }

    this.running = true;

    for (const trial of this.trialsIt) {
      this.trial = trial;
      this.updateProgressValue(trial.progress);

      const { maxNoResponseExceeded, trialData } = await this.runTrial(this.trial);

      this.maxNoResponseExceeded = maxNoResponseExceeded;

      await this.taskController.pushItem(trialData);
      this.trialResponses.push(trialData);

      if (maxNoResponseExceeded || this.abortController.signal.aborted) {
        break;
      }
    }

    await this.taskController.pushItem({
      endBlock: new Date(),
    });
    this.done();
  }

  /**
   * @param {number} progress
   */
  updateProgressValue(progress) {
    this.progressValue = progress;
    this.dispatchEvent(new Event('progress'));
  }

  /**
   * @param {TrialwithIndex} trial
   */
  async handleNoResponse(trial) {
    this.items = [];
    const elapsed = this.responseTimer.elapsed();

    trial.response = {
      trial: trial.index,
      elapsed,
      noResponse: true,
      targetKind: trial.target.startsWith('c') ? 'chicken' : 'sheep',
      stimulusId: trial.target,
      distractorId: trial.distractor,
    };

    this.noResponseTimer = undefined;

    const maxNoResponseTrials = this.maxNoResponseTrials ?? 3;

    this.noResponseCount += 1;
    if (this.noResponseCount >= maxNoResponseTrials) {
      return { maxNoResponseExceeded: true, trialData: trial.response };
    }

    return { trialData: trial.response };
  }

  /**
   * @param {TrialwithIndex} trial
   */
  async runTrial(trial) {
    if (this.phaseType === 'animation') {
      await this.playSound('animation fixation cue');
    } else {
      const audioPromise =
        this.phaseType === 'main' || (this.phaseType === 'practice' && trial.index === 0)
          ? undefined
          : this.playSound('demo fixation cue');

      await Promise.all([this.delay(this.durations.fixation), audioPromise]);
    }

    this.items = this.makeUIItems(trial);

    const runPromise = new Promise(resolve => {
      this.runResolve = resolve;
    });

    if (this.phaseType === 'animation') {
      await this.updateComplete;

      requestAnimationFrame(() => this.cellChanged());
    } else {
      this.responseTimer.start();
      this.noResponseTimer = setTimeout(async () => {
        const response = await this.handleNoResponse(trial);

        this.runResolve(response);
      }, this.durations.stimulus);
    }

    return runPromise;
  }

  /**
   *
   * @param {Item} item
   */
  async handleClick(item) {
    return this.phaseType !== 'animation' && this.handleClickAction(item);
  }

  /**
   *
   * @param {Item} item
   */
  async handleClickAction(item) {
    const elapsed = this.responseTimer.elapsed();
    const durations = this.taskController.durations;
    clearTimeout(this.noResponseTimer);

    this.noResponseCount = 0;

    this.trial.response = {
      trial: this.trial.index,
      elapsed,
      isCorrect: item.type === 't',
      type: item.type,
      target: item.place === 0 ? 'left' : 'right',
      targetKind: item.name.startsWith('c') ? 'chicken' : 'sheep',
      stimulusId: this.trial.target,
      distractorId: this.trial.distractor,
    };

    if (this.feedbackType !== 'audio') {
      item.selected = true;
      this.items = [...this.items];
    }

    const key = item.name.startsWith('c') ? 'chicken sound' : 'sheep sound';

    const delayPromise = this.delay(durations.feedback);

    const feedbackPromises = [delayPromise];

    if (this.feedbackType !== 'visual') {
      const soundPromise = this.playSound(key);
      feedbackPromises.push(soundPromise);
    }

    await Promise.all(feedbackPromises);

    this.items = [];
    this.runResolve({ trialData: this.trial.response });
  }

  done() {
    this.running = false;
    this.dispatchEvent(new Event('next'));
  }

  render() {
    return html`${this.getTrials.render({
      complete: () => {
        return html`
          <div class="content">
            ${when(
              this.fingerShow,
              () => html`
                <div
                  ${animate({
                    keyframeOptions: {
                      duration: 2000,
                    },
                    properties: ['left', 'top'],

                    onComplete: () => this.onComplete(),
                  })}
                  style=${styleMap(this.fingerStyles)}
                  class="finger-wrapper"
                >
                  <onc-svg-icon class="finger-icon">${graphics.app.fingerTap}</onc-svg-icon>
                </div>
              `,
            )}
            ${this.renderItems()}
          </div>
        `;
      },
    })}`;
  }

  renderItems() {
    const border = this.showLayout;
    const items = this.items.map(
      (item, index) => html`
        <onc-game-item
          pad
          class=${classMap({ border, stimulus: 1, selected: item.selected })}
          .item=${item}
          @click=${() => this.handleClick(item)}
          ${index === 0 ? ref(this.targetRef) : nothing}
          >${item.icon}</onc-game-item
        >
      `,
    );
    return html` <div class="content">${items}</div> `;
  }

  onComplete() {
    this.fingerStyles = { ...this.fingerStyles, ['--tap-display']: 'block' };

    setTimeout(() => {
      const item = /** @type {Element&{item: Item}} */ (this.targetRef.value)?.item;

      this.handleClickAction(item);

      this.fingerShow = false;
      this.fingerStyles = { ...this.fingerStyles, top: '90.75%', left: '44.34%', ['--tap-display']: 'none' };
    }, 500);
  }

  cellChanged() {
    const element = /** @type {HTMLElement} */ (this.targetRef.value);
    if (!element) {
      console.log('no element');
      return;
    }
    this.fingerShow = true;

    const cellElement = element;
    const offsetParent = cellElement.offsetParent;

    const parentRect = offsetParent.getBoundingClientRect();
    const cellRect = cellElement.getBoundingClientRect();

    const relativeTop = cellRect.top - parentRect.top;
    const relativeLeft = cellRect.left - parentRect.left;

    const fw = (parentRect.width * 11.48) / 100;
    const fh = (parentRect.height * 23.32) / 100;

    const top = `${relativeTop + cellRect.height / 2 - fh * 0.2}px`;
    const left = `${relativeLeft + cellRect.width / 2 - fw * 0.4}px`;

    setTimeout(() => {
      this.fingerStyles = { ...this.fingerStyles, top, left };
    }, 100);
  }

  makeTrial() {
    const targetIndex = Math.random() >= 0.5 ? 0 : 1;
    const target = this.targets.next().value;
    const distractorSource = target.startsWith('c') ? this.distractorsChicken : this.distractorsSheep;
    const distractor = distractorSource.next().value;

    return { target, targetIndex, distractor, response: {} };
  }

  /**
   * @param {{ trials: number }} block
   */
  makeTrials(block) {
    if (!block) {
      return [];
    }

    const trials = Array.from({ length: block.trials }, () => this.makeTrial());

    return trials;
  }

  /**
   * @param {string} name
   * @param {string} type
   * @param {Number} place
   * @param {number[] | [any, any, any]} location

   */
  makeUIItem(name, type, place, location) {
    const [top, left, width] = location;
    const key = name.startsWith('c') ? 'chicken' : 'sheep';
    const icon = graphics[key][name];

    const styles = { top: `${top * 100}%`, left: `${left * 100}%`, width: `${width}%` };

    return { type, place, top, left, width, name, icon, allbg: 1, id: name, styles };
  }

  /**
   * @param {TrialwithIndex} trial
   */
  makeUIItems(trial) {
    const { target, targetIndex, distractor } = trial;

    /**
     * @type {Item[]}
     */
    const uiItems = [];

    uiItems.push(
      this.makeUIItem(target, 't', targetIndex, locations[targetIndex]),
      this.makeUIItem(distractor, 'd', 1 - targetIndex, locations[1 - targetIndex]),
    );

    return uiItems;
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.abortController.abort('disconnected');
    clearTimeout(this.noResponseTimer);
    this.runResolve?.({});
  }
}

const OncMemoryPresenter = class OncMemoryPresenter extends MemoryPresenter {
  static styles = css`
    :host {
      position: relative;
      width: 100%;
      height: 100%;

      display: block;
    }
    .memory-stimulus {
      position: absolute;
      border-radius: 7px;
    }

    .memory-stimulus.border {
      border: 1px solid cyan;
    }

    .stimulus {
      border-radius: 7px;
    }

    .selected {
      background-color: rgba(255, 255, 255, 0.6);
    }

    .finger-wrapper {
      position: absolute;
      top: 50%;
      left: 45%;

      width: 5%;
      height: auto;
      z-index: 10;
    }

    .finger-wrapper[noshow] {
      visibility: hidden;
    }
  `;
};
customElements.define('onc-memory-presenter', OncMemoryPresenter);
