import { ContextConsumer } from '@lit/context';
import { msg } from '@lit/localize';
import { LitElement, css, html } from 'lit';
import { when } from 'lit/directives/when.js';
import { Auth } from '~/app/auth';
import { routerContext } from '~/app/contexts';
import { HibpCheck } from '~/login/password-check.js';
import { validate as passwordValidate } from '~/validators/password.js';
import { getMessageForError } from '../message-error.js';

const pwd_ = {
  text: {
    type: 'text',
    suffix: 'Hide password',
    icon: 'visibility_off',
    title: 'hide password',
  },
  password: { type: 'password', suffix: 'Show password', icon: 'visibility', title: 'show password' },
};

/**
 * @type {{[x:string]: ()=> string | string[]}}
 */
const keyMap_ = {
  unknown: () => [msg(`Sorry, it looks like something’s not working right now. Please try again in a few minutes.`)],
  noSuchUser: () => msg(`Sorry, we can’t find an account with that email.`),
  samePassword: () => msg(`Sorry, that's your old password. Please enter a different one.`),
  checkLength: () => [msg(`Sorry, that password is too short. It needs to be eight characters or more.`)],
  passwordMismatch: () => [msg(`That's not the right password for that account.`)],
  passwordMismatchFirst: () => [msg(`Uh oh, that password doesn’t match that account. Please try again.`)],
  expiredToken: () => [msg(`Expired or invalid token. Select "Resend verification email" and try again.`)],
};

export class VerifyAccount extends LitElement {
  static properties = {
    subject: { type: String },
    passwordMessages: { state: true },
    view: { state: true },
    codeMessages: { state: true },
    passwordWarning: { state: true },
    revealPassword: { state: true },
    pending: { state: true },
    password: { state: true },
    code: { type: String },
  };

  constructor() {
    super();

    /** @type {boolean | string[]} */
    this.passwordMessages = [];

    new ContextConsumer(this, { context: routerContext, callback: value => (this.router = value) });

    this.codeMessages = [];
    this.password = '';
    this.revealPassword = false;
    this.passwordValid = false;
    this.passwordWarning = false;
    this.pending = true;
    this.code = '';
    this.resend = false;

    this.view = 'verify';
    this.subject = '';
    this.hibpCheck = new HibpCheck();
  }

  createRenderRoot() {
    return this;
  }

  render() {
    return html`
      ${when(
        this.subject,
        () => this.renderSimple(),
        () => this.renderForm(),
      )}
    `;
  }

  renderForm() {
    return html` ${this.renderSimple()} `;
  }

  renderSimple() {
    return html`${when(
      this.view === 'email',
      this.renderEmail,
      () => html`${when(this.pending, this.renderVerifying, this.renderPending)}`,
    )}`;
  }

  renderVerifying = () => {
    return html`
      <h1 class="lsi-headline4">${msg('Email verification')}</h1>
      <div class="sign-in-instructions">
        ${msg(
          `Enter the verification code from the email we've just sent to verify your account. It may take a few minutes to arrive.`,
        )}
        ${msg(`Can't find it? Check your spam folder.`)}
      </div>
      <div class="sign-in-field">
        <md-filled-text-field
          class="sign-in-input"
          .value=${this.code}
          @input=${this.onCodeInput}
          id="code"
          name="code"
          autocapitalize="characters"
          autocorrect="off"
          @keyup=${this.onEnter}
          .label=${msg('Verification code')}
          @focus=${this.onCodeFocus}
        >
        </md-filled-text-field>

        <onc-form-message .messages=${this.codeMessages}></onc-form-message>
      </div>

      <div class="sign-in-row">
        <md-filled-button @click=${this.checkCode} class="onc-button" .disabled=${this.code.length < 14}
          >${msg('Verify account')}</md-filled-button
        >

        <md-text-button class="onc-button" @click=${() => this.checkSubject()}
          >${msg(`Resend verification email`)}</md-text-button
        >
      </div>
    `;
  };

  renderPending = () => {
    const pwd = this.revealPassword ? pwd_.text : pwd_.password;

    return html`
      <h1 class="headline4">${msg('Enter your new password')}</h1>

      <div ref="textinput" class="sign-in-field">
        <md-filled-text-field
          class="sign-in-input"
          @input=${this.onInput}
          id="password"
          name="password"
          autocapitalize="none"
          autocorrect="off"
          @blur=${this.onPasswordBlur}
          @focus=${this.onPasswordFocus}
          @keyup=${this.onEnter}
          .label=${msg('New Password')}
          .type=${pwd.type}
        >
          <md-icon slot="trailing-icon" @click=${this.onToggle} .title=${pwd.title}>${pwd.icon}</md-icon>
        </md-filled-text-field>

        <onc-form-message .messages=${this.passwordMessages} .warning=${this.passwordWarning}></onc-form-message>

        <div class="lsi-field-notifications">
          <p class="lsi-field-notifications-prompt">${msg('Passwords need to include...')}</p>
          <p class="lsi-field-notification">
            <span class="lsi-field-notification-symbol">•</span>
            <span class="field-notification-item">${msg(' Eight or more characters')}</span>
          </p>
          <p class="lsi-field-notification">
            <span class="lsi-field-notification-symbol">•</span>
            <span class="field-notification-item">${msg(' At least one letter')}</span>
          </p>
          <p class="lsi-field-notification">
            <span class="lsi-field-notification-symbol">•</span>
            <span class="field-notification-item">${msg(' At least one number or symbol')}</span>
          </p>
        </div>
      </div>

      <div class="sign-in-row">
        <md-filled-button @click=${this.verifyAndLogin} class="onc-button">${msg('Continue')}</md-filled-button>
      </div>
    `;
  };

  renderEmail = () => {
    return html` <onc-check-account @next=${this.checkValidation} .title=${msg(`Enter email`)}> </onc-check-account>`;
  };

  onToggle() {
    this.revealPassword = !this.revealPassword;
  }

  onCodeInput({ target: { value } }) {
    const formattedValue = format(value, 4);
    this.code = formattedValue;
  }

  onInput({ target: { value } }) {
    this.password = value;
  }

  onEnter({ key, target: { value } }) {
    if (key === 'Enter' && value) {
      this.checkValidationAndSignIn();
    }
  }

  checkValidationAndSignIn() {
    console.log('not implemented');
  }

  async checkValidation({ detail: { username } }) {
    this.username = username;

    await this.sendVerifyAccount({ resend: true });
    this.view = 'verify';
  }

  async checkSubject() {
    if (this.subject) {
      this.sendVerifyAccount({ resend: true });
    } else {
      this.view = 'email';
    }
  }

  async checkPasswordValid(value) {
    this.passwordMessages = passwordValidate([value]);

    this.passwordValid =
      typeof this.passwordMessages === 'boolean' ? this.passwordMessages : this.passwordMessages.length === 0;
    this.passwordWarning = false;

    if (this.passwordValid) {
      const result = await this.hibpCheck.check(value);
      if (result) {
        this.passwordValid = false;
        this.passwordMessages = [
          msg(
            `That password has been part of a data breach elsewhere on the internet. We suggest you pick something different.`,
          ),
        ];
        this.passwordWarning = true;
      }
    }
  }

  async onPasswordBlur({ target: { value } }) {
    await this.checkPasswordValid(value);
  }

  onPasswordFocus() {
    this.passwordMessages = [];
  }

  onCodeFocus() {
    this.codeMessages = [];
  }

  async verifyAndLogin() {
    this.checkPasswordValid(this.password);

    if (this.passwordValid) {
      try {
        await Auth.verifyAccount(this, { password: this.password, code: this.code });

        // @ts-ignore
        window.__router?.goto('/', { history: true });
      } catch (error) {
        const { messages } = getMessageForError(error, keyMap_);
        this.passwordMessages = messages;
      }
    }
  }

  async checkCode() {
    await Auth.checkCode({ code: this.code })
      .then(() => {
        this.pending = false;
      })
      .catch(error => {
        const { messages } = getMessageForError(error, keyMap_);
        this.codeMessages = messages;
      });
  }

  async sendVerifyAccount({ resend }) {
    return Auth.sendVerifyAccount({ subject: this.subject, resend }).catch(error => {
      const { messages } = getMessageForError(error, keyMap_);
      this.codeMessages = messages;
    });
  }
}

const OncVerifyAccount = class OncVerifyAccount extends VerifyAccount {
  static styles = css`
    md-filled-text-field {
      --md-sys-color-surface-variant: #f5f5f5;
    }

    *,
    ::before,
    ::after {
      box-sizing: border-box;
    }

    .onc-button {
      --md-sys-color-primary: var(--onc-signin-color, #808080);
      --md-filled-button-label-text-font: manrope, roboto, sans-serif;
      --md-filled-button-label-text-size: 16px;
      --md-filled-button-container-shape: 9px;
    }

    .sign-in-field {
      margin-bottom: 16px;
    }

    .sign-in-input {
      --md-filled-text-field-trailing-icon-color: cornflowerblue;

      width: 100%;
      padding-top: 16px;
    }

    .sign-in-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1em;
    }

    .headline4 {
      font-family: Manrope, Roboto, sans-serif;
      font-size: 2.125rem;
      font-weight: 400;
      line-height: 2.5rem;
      text-decoration: inherit;
      text-transform: inherit;
      letter-spacing: 0.0074em;

      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
    }

    .field-notifications {
      margin: 0;
      padding: 0;
      padding-top: 8px;

      font: inherit;
      font-size: 100%;
      font-size: 0.875rem;
      line-height: 1.125rem;
      color: black;
      vertical-align: baseline;

      border: 0;
    }

    .field-notifications-prompt {
      margin: 0;
      padding: 0;

      font-size: 0.875rem;
      font-weight: bold;
      line-height: 1.125rem;
    }

    .field-notification {
      margin: 0;
      padding: 0;

      font: inherit;
      font-size: 100%;
      vertical-align: baseline;

      border: 0;
    }

    .field-notification-symbol {
      margin: 0;
      margin-right: 4px;
      padding: 0;

      font: inherit;
      font-size: 100%;
      vertical-align: baseline;

      border: 0;
    }
  `;
};

customElements.define('onc-verify-account', OncVerifyAccount);

// ===
// Private functions
// ===

function format(value, k) {
  const string_ = [
    ...value
      .trim()
      .replaceAll(/[^\dA-Za-z]/g, '')
      .toUpperCase(),
  ];

  const length_ = string_.length;

  for (let index = 0; index < length_; index = index + k) {
    if (index !== 0) {
      string_[index - 1] = string_[index - 1] + '-';
    }
  }

  return string_.join('');
}
