import { shuffle } from '@ounce/onc';
import { stimuliSource } from '@tasks/chicken/stimuli-it.js';
import { TaskControllerBase } from '@tasks/task-controller-base.js';
import { addMinutes, isBefore } from 'date-fns';
import { graphics } from '~/graphics/index.js';
import { RewardSource } from './reward-source.js';
import { rewardLocations } from './sheep-data.js';
import { SheepPhase } from './sheep-phase.js';
import { SheepPhaseSetting } from './specs/sheep-phase-setting.js';
import { SheepSettings } from './specs/sheep-settings.js';

/**
 * @typedef {import('lit').ReactiveControllerHost} ReactiveControllerHost
 * @typedef {import('lit').ReactiveElement} ReactiveElement
 * @typedef {import("./specs/sheep-demo.js").SheepDemo} SheepDemo
 * @typedef {import('./specs/record-types.js').PhaseRecord} PhaseRecord
 *
 * @typedef {import('./specs/sheep-profile.js').SheepProfile} SheepProfile
 * @typedef {import('./sheep-types.js').PhaseSetting} PhaseSetting
 * @typedef {import('./sheep-types.js').GoBlock} GoBlock
 * @typedef {import('./sheep-types.js').MixedBlock} MixedBlock
 * @typedef {import('./sheep-types.js').SheepBlock} SheepBlock
 */

const defaultStimuliSet_ = [...nums(35)].filter(n => ![31, 10, 22, 23, 26].includes(n));

export class TaskController extends TaskControllerBase {
  /**
   * @param {ReactiveControllerHost & ReactiveElement} host
   * @param {string } taskId
   */
  constructor(host, taskId) {
    super(host, taskId);

    const taskRecord = this.project?.getById(taskId);
    this.taskSettings_ = new SheepSettings(taskRecord);

    const icon = graphics.sheep.s31;
    this.rewardSrc = new RewardSource(rewardLocations, icon);

    this.taskProfile = /** @type {SheepProfile} */ (this.profile.tasks.get(taskId));

    this.phases = undefined;
    this.totalBlocks_ = undefined;
    this.phaseIndex = 0;
    this.mode_ = 'play';

    // const ss = task.stimuliSet;
    const targets = this.taskProfile.targets;

    if (!targets?.length) {
      const newTargets = shuffle.getRandomSubarray(defaultStimuliSet_, 15);

      const a = new Set(newTargets);

      const distractors = defaultStimuliSet_.filter(x => !a.has(x));

      this.taskProfile.targets = newTargets;
      this.taskProfile.distractors = distractors;
      this.taskProfile.kind = 'random'; // ss.kind;

      this.saveTaskProfile();
    }

    this.targets = this.taskProfile.targets;
  }

  get durations() {
    return this.taskSettings.durations;
  }

  get taskSettings() {
    return this.taskSettings_;
  }

  get mode() {
    return this.mode_;
  }
  set mode(value) {
    if (this.mode_ !== value) {
      this.phases = undefined;
    }
    this.mode_ = value;
  }

  get gameName() {
    return 'sheep';
  }

  get rewards() {
    return this.rewardSrc.rewards;
  }

  /**
   * @param {{}} phaseDurations
   */
  setAdaptationLastAdaptive(phaseDurations) {
    this.taskProfile.adaptation.adaptiveDurations = phaseDurations;
  }

  setAdaptationLastRun() {
    this.taskProfile.adaptation.lastRun = new Date();
    this.saveTaskProfile();
  }

  /**
   * @param {{ date: Date; mean: number; sd: number; }} stats
   */
  setLastSimpleStats(stats) {
    this.taskProfile.adaptation.lastSimpleStats = stats;
    this.saveTaskProfile();
  }

  /**
   * @param {number} count
   */
  addRewards(count) {
    this.rewardSrc.addRewards(count);
  }

  getStimuli() {
    const targets = this.mode === 'play' ? this.targets : ['31'];
    const stimuli = stimuliSource(targets);

    return stimuli;
  }

  getPhases() {
    if (this.phases === undefined) {
      const selectedPhases =
        this.mode === 'demo'
          ? getDemoPhases(this.taskSettings.demo)
          : getMainPhases(this.taskSettings.phases, this.taskProfile.adaptation);

      let totalBlocks = 0;
      for (const phase of selectedPhases) {
        totalBlocks += phase.blocks.length;
      }

      this.totalBlocks_ = totalBlocks;

      this.phases = selectedPhases.map(phaseInfo => new SheepPhase(phaseInfo, totalBlocks));
    }
    return this.phases;

    // return [...phaseGenerator(this.task.phases, this.task.demo, this.mode)];
  }

  get totalBlocks() {
    if (this.totalBlocks_ === undefined) {
      this.getPhases();
    }

    return this.totalBlocks_;
  }

  // getPhasesX() {
  //   const selectedPhases = phaseSelection(this.task.phases, this.taskProfile.adaptation, this.task.demo, this.mode);

  //   let totalBlocks = 0;
  //   this.blockCount = 0;
  //   for (const phase of selectedPhases) {
  //     totalBlocks += phase.blocks.length;
  //   }

  //   this.totalBlocks = totalBlocks;

  //   return selectedPhases.map(phaseInfo => new SheepPhase(phaseInfo, totalBlocks));
  // }
}

// ===
// Private functions
// ===

/**
 *
 * @param {number} max
 */
function* nums(max) {
  let index = 1;
  while (index <= max) {
    yield index++;
  }
}

/**
 * @param {SheepPhaseSetting[]} phases
 * @param {{ lastRun: number | Date; }} adaptation
 */
function determinePhaseStartIndex(phases, adaptation) {
  if (!adaptation?.lastRun) {
    return 0;
  }

  const now = new Date();
  let candidateIndex;
  for (const [index, phase] of phases.entries()) {
    if (phase.type === 'adaptive') {
      candidateIndex = index;
      continue;
    }

    const skipDate = addMinutes(adaptation.lastRun, phase.skipTimeMinutes);

    if (isBefore(skipDate, now)) {
      return index;
    }
  }

  // if the last phase had skip time settings and it was skipped, we will use it anyway
  return candidateIndex ?? phases.length - 1;
}

/**
 * @param {SheepPhaseSetting} phase
 */
function getBlocks(phase) {
  const {
    blocks: {
      go: { count: goCount, ...goRest },
      mixed: { count: mixedCount, ...mixedRest },
    },
  } = phase;

  /**
   * @type {GoBlock[]}
   */
  // @ts-ignore
  const goBlocks = Array.from({ length: goCount }, (v, id) => ({ id, type: 'go', ...goRest }));

  /**
   * @type {MixedBlock[]}
   */
  // @ts-ignore
  const mixedBlocks = Array.from({ length: mixedCount }, (v, id) => ({
    id: id + goCount,
    type: 'mixed',
    ...mixedRest,
  }));

  const blocks = [...goBlocks, ...mixedBlocks];

  blocks.at(-1).isLastBlock = true;

  return blocks;
}

/**
 *
 * @param {SheepDemo} demo
 */
function getDemoPhases(demo) {
  /**
   * @type {PhaseRecord}
   */
  const demoPhase = {
    type: 'practice',
    blocks: {
      go: { count: 0, trials: 0 },
      mixed: {
        count: 1,
        goTrials: demo.practice.goTrials,
        nogoTrials: demo.practice.nogoTrials,
        goTrialsStart: 1,
        predictedNoGo: 'no',
      },
    },
    maxNoResponseTrials: demo.practice.maxNoResponseTrials,
  };

  const demoPhaseSettings = new SheepPhaseSetting(demoPhase);

  const blocks = getBlocks(demoPhaseSettings);

  /**
   * @type {PhaseRecord}
   */
  const animationPhase = {
    type: 'animation',
    blocks: {
      go: { count: 0, trials: 0 },
      mixed: {
        count: 1,
        goTrials: demo.animation.goTrials,
        nogoTrials: demo.animation.nogoTrials,
        goTrialsStart: 1,
        predictedNoGo: 'no',
      },
    },
    maxNoResponseTrials: 0,
  };

  const animationPhaseSettings = new SheepPhaseSetting(animationPhase);

  const animationblocks = getBlocks(animationPhaseSettings);

  return [
    { index: 0, isLastPhase: false, phaseSettings: animationPhaseSettings, blocks: animationblocks },
    { index: 0, isLastPhase: true, phaseSettings: demoPhaseSettings, blocks },
  ];
}

/**
 * @param {SheepPhaseSetting[]} phases
 * @param {{ lastRun: number | Date; }} [adaptation]
 */
function getMainPhases(phases, adaptation) {
  const startIndex = determinePhaseStartIndex(phases, adaptation);

  const selectedPhases = [];

  for (let index = startIndex; index < phases.length; index++) {
    const blocks = getBlocks(phases[index]);
    selectedPhases.push({ index, isLastPhase: false, phaseSettings: phases[index], blocks });
  }

  selectedPhases.at(-1).isLastPhase = true;

  return selectedPhases;
}
