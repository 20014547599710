import { LitElement, css, html } from 'lit';

export class TaskProgressBar extends LitElement {
  static properties = { value: { type: Number } };

  constructor() {
    super();
    this.value = 0;
  }

  render() {
    return html`
      <div class="progress-wrapper">
        <md-linear-progress class="progress" .value=${this.value}></md-linear-progress>
      </div>
    `;
  }
}
const OncTaskProgressBar = class OncTaskProgressBar extends TaskProgressBar {
  static styles = css`
    :host {
      position: absolute;
      top: 0;
      right: 5rem;
    }

    .progress-wrapper {
      width: 15rem;
    }
    .progress {
      margin-top: 1rem;

      border: 2px solid #767a7a;
      --md-linear-progress-track-height: 20px;
      --md-linear-progress-track-shape: 20px;
      --md-linear-progress-active-indicator-color: #5ac134;
      --md-linear-progress-active-indicator-height: 20px;
    }
  `;
};
customElements.define('onc-task-progress-bar', OncTaskProgressBar);
