import { openDB } from 'idb';
import { clone } from './clone.js';

// note: this version should be incremented if stores are added/removed from the database.
const databaseVersion_ = 1;
const stores_ = ['queue', 'profile'];

function createObjectStores(database, storeNames) {
  for (const storeName of storeNames) {
    if (!database.objectStoreNames.contains(storeName)) {
      database.createObjectStore(storeName);
    }
  }
}

const databasePromise_ = openDB('dbstore', databaseVersion_, {
  upgrade(database) {
    createObjectStores(database, stores_);
  },
  blocked() {
    console.log('Database blocked: Please close all other tabs with this site open.');
  },
  blocking() {
    console.log('db version change detected Jim...');
  },
});

class Store {
  constructor(storeName, databasePromise = databasePromise_) {
    this.storeName = storeName;
    this.dbPromise = databasePromise;
  }

  async get(key) {
    const response = await this.dbPromise;
    return response.get(this.storeName, key);
  }
  async set(key, value) {
    const response = await this.dbPromise;
    return response.put(this.storeName, value, key);
  }

  async safeSet(key, value) {
    const response = await this.dbPromise;
    return response.put(this.storeName, clone(value), key);
  }

  async delete(key) {
    const response = await this.dbPromise;
    return response.delete(this.storeName, key);
  }
  async clear() {
    const response = await this.dbPromise;
    return response.clear(this.storeName);
  }
  async keys() {
    const response = await this.dbPromise;
    return response.getAllKeys(this.storeName);
  }
}

export { Store };
