import { ContextConsumer, ContextProvider } from '@lit/context';
import { Task } from '@lit/task';
import { ViewController } from '@tasks/chicken/view-controller';
import { LitElement, css, html, nothing } from 'lit';
import { projectContext } from '~/app/contexts';
import { bgMotor } from '~/graphics/bg-motor.js';
import { audio } from './motor-audio.js';
import { motorTaskContext } from './motor-context';
import { TaskController } from './task-controller';

/**
 * @typedef {import('./motor-types.js').TrialResult} TrialResult
 */

const _targetRatio = 16 / 10;

export class MotorGame extends LitElement {
  static properties = { taskId: { type: String }, block: { state: true }, progressValue: { state: true } };

  taskProvider = new ContextProvider(this, { context: motorTaskContext });

  /**
   * @type {(value?:{trialResponses: [], maxNoResponseExceeded: boolean}|{action:string}) => void}
   */
  viewNextPromiseResolve;

  constructor() {
    super();

    this.taskId = '';
    this.block = undefined;
    this.progressValue = 0;
    this.lastProgress = 0;
    this.blockProgress = 0;
    this.choiceOnly = false;

    new ContextConsumer(this, { context: projectContext, callback: value => (this.project = value) });

    this.viewNextPromise = this.getViewNextPromise();
    this.taskController = undefined;

    this.viewController = new ViewController(this, [
      {
        name: 'none',
        render: () => nothing,
      },
      {
        name: 'trailer',
        render: () => html`
          <onc-task-trailer ?choiceOnly=${this.choiceOnly} @next=${this.onTrailerNext}></onc-task-trailer>
        `,
      },
      {
        name: 'presenter',
        render: (block, phaseSettings) => {
          const showBorder = this.taskController.showLayout;
          return html`
            <onc-task-progress-bar class="progress-bar" .value=${this.progressValue}> </onc-task-progress-bar>
            <onc-layout-task class="motor-layout" ?showBorder=${showBorder} .targetRatio=${_targetRatio}
              ><onc-motor-presenter
                @next=${this.viewNext}
                .block=${block}
                .phaseSettings=${phaseSettings}
                @progress=${this.onProgress}
              ></onc-motor-presenter>
            </onc-layout-task>
          `;
        },
      },
      {
        name: 'block-feedback',
        render: block => {
          const showBorder = this.taskController.showLayout;
          return html`
            <onc-motor-block-feedback
              ?showBorder=${showBorder}
              .progress=${this.blockProgress}
              .lastProgress=${this.lastProgress}
              @next=${this.viewNext}
              .block=${block}
            ></onc-motor-block-feedback>
          `;
        },
      },
      {
        name: 'end-game',
        render: () => {
          const showBorder = this.taskController.showLayout;
          return html`
            <onc-layout-task ?showBorder=${showBorder} .targetRatio=${_targetRatio}>
              <onc-end-game ?showBorder=${showBorder} @next=${this.viewNext}></onc-end-game>
            </onc-layout-task>
          `;
        },
      },
    ]);

    this.getSounds = new Task(this, {
      task: async ([taskId]) => {
        this.taskController = new TaskController(this, taskId);
        this.taskProvider.setValue(this.taskController);

        await this.taskController.createSounds(audio);
        await this.taskController.setupResults([{ start: new Date() }]);

        this.next();
      },
      args: () => [this.taskId],
    });
  }

  render() {
    return html`<onc-screen-svg>${bgMotor}</onc-screen-svg>${this.getSounds.render({
        complete: () => {
          return html`${this.viewController.outlet()}`;
        },
      })} `;
  }

  /**
   * @param {Event&{target: {action: String}}} event
   */
  async onTrailerNext(event) {
    const action = event.target.action;

    this.taskController.mode = action;
    this.choiceOnly = true;

    this.viewNextPromiseResolve({ action });

    this.viewNextPromise = this.getViewNextPromise();
  }

  /**
   * @param {Event&{target: {trialResponses: [], maxNoResponseExceeded: boolean}}} event
   */
  async viewNext(event) {
    if (event) {
      const {
        target: { trialResponses, maxNoResponseExceeded },
      } = event;
      this.viewNextPromiseResolve({ trialResponses, maxNoResponseExceeded });
    } else {
      this.viewNextPromiseResolve();
    }
    this.viewNextPromise = this.getViewNextPromise();
  }

  async next() {
    this.taskController.taskStarted();

    for (;;) {
      await this.runPhases();

      if (this.taskController.mode === 'play') {
        break;
      }
    }

    this.done();
  }

  async runPhases() {
    const { showTrailer, debugRewards } = this.taskController;

    if (showTrailer) {
      this.viewController.goto('trailer');
      await this.viewNextPromise;

      if (this.taskController['aborted']) {
        return this.done();
      }
    }

    if (debugRewards) {
      this.lastProgress = 0;
      this.blockProgress = 0.9;

      const names = ['b0', 'b10', 'b20'];
      for (let i = 0; i < 30; i++) {
        const name = names[Math.floor(i / 10)];
        this.taskController.addRewards([
          {
            name,
            n: i % 10,
          },
        ]);
      }
      this.viewController.goto('block-feedback');
      await this.viewNextPromise;
    }

    const phases = this.taskController.getPhases();

    this.blockProgress = 0;
    const totalBlocks = this.taskController.totalBlocks;
    this.blockCount = 0;

    for (const { phaseSettings, blocks } of phases) {
      const { numberOfBlocks } = phaseSettings;

      const phaseData = {
        startPhase: new Date(),
        type: phaseSettings.type,
        totalBlocks,
        numberOfBlocks,
      };

      await this.taskController.pushItem(phaseData);

      const blockResult = await this.runBlocks(blocks, phaseSettings, totalBlocks);

      const endInfo = {
        endPhase: new Date(),
      };

      await this.taskController.pushItem(endInfo);

      if (blockResult?.maxNoResponseExceeded) {
        this.viewController.goto('end-game');
        await this.viewNextPromise;
        break;
      }

      if (this.taskController['aborted']) {
        break;
      }
    }
  }

  /**
   * @param {{ id: number; rows: any; cols: any; }[]} blocks
   * @param {import("./specs/motor-phase-setting.js").MotorPhaseSetting} phaseSettings
   * @param {number} totalBlocks
   */
  async runBlocks(blocks, phaseSettings, totalBlocks) {
    for (const block of blocks) {
      this.block = {
        ...block,
        maxNoResponseTrials: phaseSettings.maxNoResponseTrials,
      };

      this.viewController.goto('presenter', block, phaseSettings);
      this.requestUpdate();

      const { trialResponses, maxNoResponseExceeded } =
        /** @type {{trialResponses: [], maxNoResponseExceeded: boolean}}*/ (await this.viewNextPromise);

      this.blockCount += 1;
      this.lastProgress = this.blockProgress;
      this.blockProgress = this.blockCount / totalBlocks;

      this.progressValue = 0;
      if (maxNoResponseExceeded) {
        return { maxNoResponseExceeded, id: block.id };
      }

      if (phaseSettings.type !== 'main') {
        continue;
      }

      if (this.taskController['aborted']) {
        break;
      }

      const rewards = this.calculateRewards(trialResponses);

      this.taskController.addRewards(rewards);

      if (phaseSettings['showBlockFeedback']) {
        this.viewController.goto('block-feedback', block);

        await this.viewNextPromise;
        if (this.taskController['aborted']) {
          break;
        }
      }
    }
  }

  calculateRewards(responses) {
    const rewards = [];
    for (const response of responses) {
      if (response.isCorrect) {
        rewards.push(response.stimulusId);
      }
    }

    return rewards;
  }

  onProgress({ target: { progressValue } }) {
    this.progressValue = progressValue;
  }

  async done() {
    await this.taskController.taskCompleted();
    this.dispatchEvent(new Event('next'));
  }

  /**
   * @returns {Promise<({trialResponses: [], maxNoResponseExceeded: boolean}|{action:string})?>}
   */
  getViewNextPromise() {
    return new Promise(resolve => {
      this.viewNextPromiseResolve = resolve;
    });
  }
}
const OncMotorGame = class OncMotorGame extends MotorGame {
  static styles = css`
    .motor-layout {
      overflow: hidden;
    }
  `;
};
customElements.define('onc-motor-game', OncMotorGame);
