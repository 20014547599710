import { longFence2 } from './long-fence-2.js';
import { longFence } from './long-fence.js';
import { pig } from './pig.js';

import { s1 } from './s1.js';
import { s2 } from './s2.js';
import { s3 } from './s3.js';
import { s4 } from './s4.js';
import { s5 } from './s5.js';
import { s6 } from './s6.js';
import { s7 } from './s7.js';
import { s8 } from './s8.js';
import { s9 } from './s9.js';

import { s10 } from './s10.js';
import { s11 } from './s11.js';
import { s12 } from './s12.js';
import { s13 } from './s13.js';
import { s14 } from './s14.js';
import { s15 } from './s15.js';
import { s16 } from './s16.js';
import { s17 } from './s17.js';
import { s18 } from './s18.js';
import { s19 } from './s19.js';

import { s20 } from './s20.js';
import { s21 } from './s21.js';
import { s22 } from './s22.js';
import { s23 } from './s23.js';
import { s24 } from './s24.js';
import { s25 } from './s25.js';
import { s26 } from './s26.js';
import { s27 } from './s27.js';
import { s28 } from './s28.js';
import { s29 } from './s29.js';

import { s30 } from './s30.js';
import { s31 } from './s31.js';
import { s32 } from './s32.js';
import { s33 } from './s33.js';
import { s34 } from './s34.js';
import { s35 } from './s35.js';
import { s36 } from './s36.js';
import { s37 } from './s37.js';
import { s38 } from './s38.js';
import { s39 } from './s39.js';

import { s40 } from './s40.js';
import { s41 } from './s41.js';
import { s42 } from './s42.js';
import { s43 } from './s43.js';

import { sBack } from './s-back.js';

export const sheep = {
  s1,
  s2,
  s3,
  s4,
  s5,
  s6,
  s7,
  s8,
  s9,
  s10,
  s11,
  s12,
  s13,
  s14,
  s15,
  s16,
  s17,
  s18,
  s19,
  s20,
  s21,
  s22,
  s23,
  s24,
  s25,
  s26,
  s27,
  s28,
  s29,
  s30,
  s31,
  s32,
  s33,
  s34,
  s35,
  s36,
  s37,
  s38,
  s39,
  s40,
  s41,
  s42,
  s43,

  sBack,

  pig,
  longFence,
  longFence2,
};
