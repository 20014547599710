/**
 * @typedef {import('./sheep-types.js').Adaptation} Adaptation
 * @typedef {import('./sheep-types.js').GoAdaptation} GoAdaptation
 * @typedef {import('./sheep-types.js').NoGoAdaptation} NoGoAdaptation
 */

export class TrialAdaptation {
  constructor(phaseSettings) {
    this.phaseSettings = phaseSettings;

    const { go: goAdaptation, nogo: nogoAdaptation } = this.phaseSettings.adaptation;

    this.goAdaptation = goAdaptation;
    this.nogoAdaptation = nogoAdaptation;
    this.threshold = nogoAdaptation.threshold;

    this.count = 0;
    this.countType = '';
  }

  updateCount(trialCorrect) {
    if (trialCorrect !== this.countType) {
      this.count = 1;
      this.countType = trialCorrect;
      return;
    }

    this.count += 1;
  }

  adaptDuration(trialCorrect, duration) {
    this.updateCount(trialCorrect);

    // const adapt = this.threshold - 1 === (this.count - 1) % this.threshold;
    const adapt = this.count % this.threshold === 0;

    if (adapt) {
      const deltaName = trialCorrect ? 'correct' : 'incorrect';

      const adapted = {};

      for (const [key, value] of Object.entries(duration)) {
        /**
         * @type {GoAdaptation|NoGoAdaptation}
         */
        const adaptation = this.phaseSettings.adaptation[key];

        adapted[key] = Math.min(adaptation.max, Math.max(value * adaptation.multiplier[deltaName], adaptation.min));
      }

      return adapted;
    }

    return duration;
  }
}
