/**
 * @typedef { import('./record-types.js').MemoryDemoRecord}  MemoryDemoRecord
 */
export class MemoryDemo {
  /**
   *
   * @param {MemoryDemoRecord} demoRecord
   */
  constructor(demoRecord) {
    this.animation = {
      trials: demoRecord.animation.trials,
    };

    this.practice = {
      trials: demoRecord.practice.trials,
      maxNoResponseTrials: demoRecord.practice.maxNoResponseTrials,
    };
  }
}
