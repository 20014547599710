const config = {
  library: 'https://accounts.google.com/gsi/client',
  defaultButtonConfig: { theme: 'outline', size: 'large' },
  scopes: 'email profile openid',
};

const libraryState = {
  apiLoaded: false,
  apiLoadInitiated: false,
};

export const loadGApiPromise = new Promise(resolve => {
  if (!libraryState.apiLoadIntitited) {
    const script = document.createElement('script');
    libraryState.apiLoadIntitited = true;
    script.addEventListener('load', () => {
      libraryState.apiLoaded = true;
      resolve(window.google);
    });
    script.src = config.library;
    script.async = true;
    script.defer = true;
    document.head.append(script);
  }
});

export async function onConnected(idConfiguration, button, options) {
  await loadGApiPromise;

  // console.log('loaded G');

  renderSigninButton(idConfiguration, button, options);

  if (options.prompt) {
    googleOneTap({
      clientId: options.clientId,
      callback: options.callback,
    });
  }
}

/**
 * For retriving the JWT payload from the credential
 * @param token JWT credential string
 * @returns Decoded payload from the JWT credential string
 */
export function decodeCredential(token) {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replaceAll('-', '+').replaceAll('_', '/');
    const jsonPayload = decodeURIComponent(
      [...atob(base64)]
        .map(function (c) {
          return '%' + ('00' + c.codePointAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );
    return JSON.parse(jsonPayload);
  } catch {
    throw 'JWT provided is invalid';
  }
}

// ===
// Private functions
// ===

function renderSigninButton(idConfiguration, button, options) {
  window.google.accounts.id.initialize(idConfiguration);
  if (button) {
    window.google.accounts.id.renderButton(button, options.buttonConfig);
  }
}

const handlePromptError = options => {
  const notification = options.notification;
  let errorMessage = '';
  if (notification.isNotDisplayed()) {
    errorMessage =
      notification.getNotDisplayedReason() === 'suppressed_by_user'
        ? `Prompt was suppressed by user'. Refer https://developers.google.com/identity/gsi/web/guides/features#exponential_cooldown for more info`
        : `Prompt was not displayed, reason for not displaying: ${notification.getNotDisplayedReason()}`;
  }
  if (notification.isSkippedMoment()) {
    errorMessage = `Prompt was skipped, reason for skipping: ${notification.getSkippedReason()}`;
  }

  if (errorMessage.length > 0) {
    if (options.error) {
      options.error(errorMessage);
    } else {
      options.reject(errorMessage);
    }
  }
};

/**
 * A function to open one-tap and automatic log-in prompt
 * @param options Options to customise the behavior of one-tap and automatic log-in prompt
 * @returns A promise which get resolved once user login through the prompt
 */
export const googleOneTap = async options => {
  !options && (options = {});

  const idConfig = {};
  options.clientId && (idConfig.client_id = options.clientId);
  options.context && (idConfig.context = options.context);
  options.autoLogin != undefined && (idConfig.auto_select = options.autoLogin);
  options.cancelOnTapOutside != undefined && (idConfig.cancel_on_tap_outside = options.cancelOnTapOutside);

  const google = await loadGApiPromise;

  return new Promise((resolve, reject) => {
    idConfig.callback = response => {
      options && options.callback && options.callback(response);
      if (response.credential) {
        resolve(response);
      } else {
        reject(response);
      }
    };

    google.accounts.id.initialize(idConfig);
    google.accounts.id.prompt(notification => {
      options && options.onNotification && options.onNotification(notification);
      handlePromptError({
        notification,
        reject,
        error: options && options.error,
      });
    });
  });
};
