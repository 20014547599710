import { html } from 'lit';

export const egg = html`
  <svg
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    viewBox="0 0 215 285"
  >
    <path
      d="m214.1 146.5c-3.9-52.99-39.7-144.6-106.6-144.6-61.3 0-103.2 91.7-106.6 144.6-6.802 106.4 57.21 138.5 106.6 138.5 48.08 0 114.1-35.28 106.5-138.5"
      fill="#dda16b"
    />
    <path
      d="m172.9 144.1c-6.026 0-10.91-4.886-10.91-10.91 0-6.026 4.886-10.91 10.91-10.91 6.026 0 10.91 4.886 10.91 10.91 0 6.026-4.886 10.91-10.91 10.91m-26.91 98.21c-6.428 0-11.64-5.211-11.64-11.64 0-6.428 5.212-11.64 11.64-11.64 6.429 0 11.64 5.211 11.64 11.64s-5.211 11.64-11.64 11.64m-22.91-64.02c-5.424 0-9.821-4.398-9.821-9.821 0-5.424 4.398-9.821 9.821-9.821 5.425 0 9.821 4.398 9.821 9.821 0 5.424-4.396 9.821-9.821 9.821m13.45-84.02c0 2.209-1.789 4.001-4.001 4.001-2.211 0-4.001-1.792-4.001-4.001s1.79-4.001 4.001-4.001c2.212 0 4.001 1.792 4.001 4.001m-6.6-43.3c5.625 0 10.18 4.56 10.18 10.18 0 5.625-4.56 10.18-10.18 10.18-5.625 0-10.18-4.559-10.18-10.18 0-5.625 4.56-10.18 10.18-10.18m-45.55 185.4c-4.22 0-7.639-3.419-7.639-7.639 0-4.219 3.419-7.638 7.639-7.638 4.219 0 7.639 3.419 7.639 7.638 0 4.22-3.42 7.639-7.639 7.639m-18.89-54.6c-7.432 0-13.46-6.024-13.46-13.46 0-7.435 6.026-13.46 13.46-13.46 7.431 0 13.46 6.024 13.46 13.46 0 7.435-6.028 13.46-13.46 13.46m-5.09-77.1c3.818 0 6.911 3.094 6.911 6.911 0 3.819-3.094 6.911-6.911 6.911-3.819 0-6.911-3.092-6.911-6.911 0-3.818 3.092-6.911 6.911-6.911m19.28-36.38c2.008 0 3.638 1.63 3.638 3.638 0 2.009-1.63 3.638-3.638 3.638-2.009 0-3.638-1.629-3.638-3.638 0-2.008 1.629-3.638 3.638-3.638m-54.93 58.98c-2.61 0-4.729-2.119-4.729-4.729s2.119-4.729 4.729-4.729 4.729 2.119 4.729 4.729-2.119 4.729-4.729 4.729"
      fill="#bc8f79"
    />
  </svg>
`;
