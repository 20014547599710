export class CurrentUser {
  accessToken;
  email;
  name;
  roles = { role: '', teams: [] };

  tokenData;
  username;
  userref;

  constructor(userInfo, rbacCan) {
    this.rbacCan = rbacCan;

    const { accessToken, email, name, roles, tokenData, username, userref } = userInfo ?? {};

    this.accessToken = accessToken;
    this.email = email;
    this.name = name;
    this.roles = roles;
    this.tokenData = tokenData;
    this.username = username;
    this.userref = userref;
  }

  asRecord() {
    return {
      accessToken: this.accessToken,
      email: this.email,
      name: this.name,
      roles: this.roles,
      tokenData: this.tokenData,
      username: this.username,
      userref: this.userref,
    };
  }

  /**
   * @param {string} userId
   */
  isSelf(userId) {
    return userId === this.tokenData.id;
  }

  can = (operation, parameters) => {
    return this.myRole && this.rbacCan(this.myRole, operation, parameters);
  };

  get myRole() {
    return this.roles?.role;
  }

  get loggedIn() {
    return !!this.accessToken;
  }

  get myTeams() {
    return this.roles?.teams;
  }

  get userRef() {
    return this.userref;
  }

  get authToken() {
    return this.accessToken;
  }
}
