/* eslint-disable lit/prefer-static-styles */
import { LitElement, css, html, nothing } from 'lit';
import { createRef, ref } from 'lit/directives/ref.js';

const _defaultTargetRatio = 4 / 3;
export class LayoutTask extends LitElement {
  static properties = { targetRatio: { type: Number }, showBorder: { type: Boolean } };

  constructor() {
    super();
    this.targetRatio = _defaultTargetRatio;
    this.showBorder = false;
    this.innerReference = createRef();
  }

  firstUpdated() {
    this.observer = new ResizeObserver(([iw]) => {
      const innerRect = iw.contentRect;
      this.dispatchEvent(new CustomEvent('layout-rect', { detail: { innerRect } }));
    });

    const innerRect = this.innerReference.value?.getBoundingClientRect();

    this.dispatchEvent(new CustomEvent('layout-rect', { detail: { innerRect } }));

    this.observer.observe(this.innerReference.value);
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    if (this.observer) {
      this.observer.disconnect();
    }
  }

  // debug=${this.showBorder || nothing} style=${styleMap(this.innerStyle)}>
  render() {
    return html`
      <style>
        :host {
          flex-direction: column;
        }
        @media (max-aspect-ratio: ${this.targetRatio} / 1) {
          :host {
            flex-direction: row;
          }
        }

        .inner-wrapper {
          aspect-ratio: ${this.targetRatio};
        }
      </style>

      <div class="inner-wrapper" ${ref(this.innerReference)} debug=${this.showBorder || nothing}>
        <div class="inner-content">
          <slot></slot>
        </div>
      </div>
    `;
  }
}

const OncLayoutTask = class OncLayoutTask extends LayoutTask {
  static styles = css`
    :host {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 100%;
      height: 100%;
    }

    .inner-content {
      position: relative;
      width: 100%;
      height: 100%;
    }

    .inner-wrapper {
      max-width: 100%;
      max-height: 100%;
      margin: auto;
      flex: 1;
    }

    .inner-wrapper[debug] {
      border: 2px solid #ffc0cb;
    }
  `;
};
customElements.define('onc-layout-task', OncLayoutTask);
