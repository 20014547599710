export const audio = {
  'sheep trailer': 'S1 Sheep trailer.m4a',
  'mixed block cue': 'S3 Mixed block cue.m4a',
  'go block cue': 'S4 Go Block cue.m4a',
  'choice demo': 'C2 Choice-demo.m4a',
  'choice play': 'C2 Choice-play.m4a',
  'sheep sound': 'sheep-baa.m4a',
  'good feedback nogo': 'pig-grunt.m4a',
  'no response go': 'S14 no response go.m4a',
  'demo mixed cue': 'C4 Demo block cue.m4a',
  'demo go cue': 'C4 Demo block cue.m4a',
  'bad feedback nogo demo': 'S13 bad feedback no-go demo.m4a',
  'dog bark': 'dogbark.m4a',
  'end game': 'C13 end game.m4a',
  'animation mixed block cue': 'S18 animation mixed block cue.m4a',
  'demo block cue 1': 'C4 Demo block cue.m4a',
  'demo sheep stimulus': 'S8 demo sheep stimulus.m4a',
  'demo nogo stimulus': 'S9 demo pig stimulus.m4a',
  'demo go fixation cue': 'S7 demo go fixation cue.m4a',
  'demo mixed fixation cue': 'S6 Demo mixed fixation cue.m4a',
};
