/* global webkitAudioContext */

// ployfill promise variant of decodeAudioData for iOS
if (!window.AudioContext && window['webkitAudioContext']) {
  const oldFunction = window['webkitAudioContext'].prototype.decodeAudioData;

  window['webkitAudioContext'].prototype.decodeAudioData = function (/** @type {ArrayBuffer} */ arraybuffer) {
    return new Promise((resolve, reject) => {
      oldFunction.call(this, arraybuffer, resolve, reject);
    });
  };
}

export class AudioGraph {
  constructor({ context = getAudioContext(), mobileAutoEnable = true, muted = false } = {}) {
    this.muted = muted;
    this.mobileAutoEnable = mobileAutoEnable;

    this.masterGain = createMasterGain_(context, muted);

    this.codecs_ = getCodecs();
    this.volume_ = 1;
    this.navigator_ = typeof window !== 'undefined' && window.navigator ? window.navigator : undefined;
    this.context = context;

    if (mobileAutoEnable) {
      this.enableMobileAudio();
    }
  }

  get destination() {
    return this.masterGain || this.context.destination;
  }

  async close() {
    if (this.context && this.context.close !== undefined) {
      // disconnect the old gain node
      if (this.masterGain) {
        this.masterGain.disconnect();
        this.masterGain = undefined;
      }

      this.context.close();
      this.context = undefined;
    }
  }
  get codecs() {
    return { ...this.codecs_ };
  }
  get volume() {
    return this.volume_;
  }

  set volume(vol) {
    if (vol >= 0 && vol <= 1) {
      this.volume_ = vol;
      this.muted = false;
      this.masterGain.gain.setValueAtTime(vol, this.context.currentTime);
    }
  }

  mute(muted) {
    this.muted = muted;
    this.masterGain.gain.setValueAtTime(muted ? 0 : this.volume_, this.context.currentTime);
  }

  async resume() {
    return this.context.resume();
  }

  enableMobileAudio() {
    // Only run this on mobile devices if audio isn't already eanbled.
    const isMobile =
      detectIpad() ||
      /iphone|ipad|ipod|android|blackberry|bb10|silk|mobi|chrome/i.test(this.navigator_ && this.navigator_.userAgent);

    if (this.mobileEnabled_ || !this.context || !isMobile) {
      return;
    }

    this.mobileEnabled_ = false;
    this.mobileAutoEnable = false;
    // Some mobile devices/platforms have distortion issues when opening/closing tabs and/or web views.
    // Bugs in the browser (especially Mobile Safari) can cause the sampleRate to change from 44100 to 48000.
    // By calling unload(), we create a new AudioContext with the correct sampleRate.
    if (!this.mobileUnloaded_ && this.context.sampleRate !== 44_100) {
      this.mobileUnloaded_ = true;
      this.close();

      this.context = getAudioContext();

      // if get a new context need to replace the old master gain as it is connected to the old context
      this.masterGain = createMasterGain_(this.context, this.muted);
    }

    // Scratch buffer for enabling iOS 7-9 to dispose of web audio buffers correctly, as per:
    // http://stackoverflow.com/questions/24119684
    // this code no longer supports ios < 11

    // Call this method on touch start to create and play a buffer,
    // then check if the audio actually played to determine if
    // audio has now been unlocked on iOS, Android, etc.
    const unlock = () => {
      const scratchBuffer = this.context.createBuffer(1, 1, 22_050);

      // Create an empty buffer.
      const source = this.context.createBufferSource();
      source.buffer = scratchBuffer;
      source.connect(this.context.destination);

      // Play the empty buffer.
      source.start(0);

      // Calling resume() on a stack initiated by user gesture is what actually unlocks the
      // audio on Android Chrome >= 55.
      if (typeof this.context.resume === 'function') {
        this.context.resume();
      }

      // if (audioElementSelector) {
      //   const destinationElement = this.setDestination(audioElementSelector);
      //   if (destinationElement) {
      //     destinationElement.play();
      //   }
      // }

      // Setup a timeout to check that we are unlocked on the next event loop.
      source.addEventListener('ended', () => {
        source.disconnect(0);

        // Update the unlocked state and prevent this check from happening again.
        this.mobileEnabled_ = true;

        // Remove the touch start listener.
        document.removeEventListener('touchstart', unlock, true);
        document.removeEventListener('touchend', unlock, true);
        document.removeEventListener('click', unlock, true);
      });
    };

    // Setup a touch start listener to attempt an unlock in.
    document.addEventListener('touchstart', unlock, true);
    document.addEventListener('touchend', unlock, true);
    document.addEventListener('click', unlock, true);

    return this;
  }

  unlock() {
    const body = document.body;
    const unlockHandler = () => {
      // Calling resume() on a stack initiated by user gesture is what actually unlocks the
      // audio on Android Chrome >= 55.
      if (typeof this.context.resume === 'function') {
        this.context.resume().then(
          () => {
            // Remove the touch start listener.
            body.removeEventListener('touchstart', unlockHandler);
            body.removeEventListener('touchend', unlockHandler);
            body.removeEventListener('click', unlockHandler);
          },
          () => {
            body.removeEventListener('touchstart', unlockHandler);
            body.removeEventListener('touchend', unlockHandler);
            body.removeEventListener('click', unlockHandler);
          },
        );
      }
    };

    if (body) {
      body.addEventListener('touchstart', unlockHandler, false);
      body.addEventListener('touchend', unlockHandler, false);
      body.addEventListener('click', unlockHandler, false);
    }
  }
}

// ===
// Private functions
// ===

function createMasterGain_(context, muted) {
  const masterGain = context.createGain();
  masterGain.gain.setValueAtTime(muted ? 0 : 1, context.currentTime);
  masterGain.connect(context.destination);

  return masterGain;
}

/**
 *
 * @returns {AudioContext}
 */
function getAudioContext() {
  // @ts-ignore
  return typeof AudioContext === 'undefined' ? new webkitAudioContext() : new AudioContext();
}

function getCodecs() {
  let audioTest;

  /**
   * @type {{[x :string]: boolean }}
   */
  const codecs = {};

  // Must wrap in a try/catch because IE11 in server mode throws an error.
  try {
    audioTest = typeof Audio === 'undefined' ? undefined : new Audio();
  } catch {
    return codecs;
  }

  if (!audioTest || typeof audioTest.canPlayType !== 'function') {
    return codecs;
  }

  // eslint-disable-next-line prefer-const
  for (let [key, mimeType] of tests_()) {
    if (!Array.isArray(mimeType)) {
      mimeType = [mimeType];
    }
    codecs[key] = mimeType.some(mt => !!audioTest.canPlayType(mt).replace(/^no$/, ''));
  }

  return codecs;
}

/**
 *
 * @returns {[string, string| string[]][]}
 */
function tests_() {
  return [
    ['mp3', 'audio/mp3'],
    ['mpeg', 'audio/mpeg'],
    ['opus', 'audio/ogg; codecs="opus"'],
    ['ogg', 'audio/ogg; codecs="vorbis"'],
    ['oga', 'audio/ogg; codecs="vorbis"'],
    ['wav', 'audio/wav; codecs="1"'],
    ['aac', 'audio/aac;'],
    ['weba', 'audio/webm; codecs="vorbis"'],
    ['webm', 'audio/webm; codecs="vorbis"'],
    ['dolby', 'audio/mp4; codecs="ec-3"'],
    ['caf', ['audio/x-m4a;', 'audio/m4a;', 'audio/aac;']],
    ['mp4', ['audio/x-mp4;', 'audio/mp4;', 'audio/aac;']],
    ['flac', ['audio/x-flac;', 'audio/flac;']],
  ];
}

function detectIpad() {
  const ua = navigator.userAgent;
  if (ua.includes('iPad')) {
    return true;
  }

  if (ua.includes('Macintosh')) {
    try {
      document.createEvent('TouchEvent');
      return true;
    } catch {
      // empty
    }
  }

  return false;
}
