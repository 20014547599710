export function customIpadBehaviour() {
  // we want to suppress active/hover on iPad as it is persistent across page loads in a web app.
  const isIpad = detectIpad();
  if (isIpad) {
    // using classList is safe here as iOS is known to support it.
    document.querySelector('html').classList.add('ipad');
  } // isIpad

  injectManifestLink(isIpad);
  captureContextMenu();
  checkIOS9();
}

// ===
// Private functions
// ===

function injectManifestLink(isIpad) {
  const link = document.createElement('link');

  link.setAttribute('rel', 'manifest');
  link.setAttribute('href', isIpad ? '/client-ios.webmanifest' : '/client.webmanifest');

  const metaElement = document.querySelector('meta[name=viewport]');

  metaElement.parentNode.insertBefore(link, metaElement);
}

function captureContextMenu() {
  window.addEventListener('contextmenu', event => {
    event.returnValue = false;
    event.preventDefault();
  });
}

function checkIOS9() {
  if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
    const iosVersion = navigator.userAgent.match(/os (\d+)_(\d+)/i);
    if (iosVersion[1] >= 9) {
      document
        .querySelector('meta[name=viewport]')
        .setAttribute('content', 'initial-scale=1.0001, minimum-scale=1.0001, maximum-scale=1.0001, user-scalable=no');
    }
  }
}

function detectIpad() {
  const ua = navigator.userAgent;
  if (ua.includes('iPad')) {
    return true;
  }

  if (ua.includes('Macintosh')) {
    try {
      document.createEvent('TouchEvent');
      return true;
    } catch {
      // empty
    }
  }

  return false;
}
