import { html } from 'lit';

export const eggCrack0 = html`
  <svg version="1.1" viewBox="0 0 190 150" xmlns="http://www.w3.org/2000/svg">
    <path
      class="egg-shell"
      d="m72.34 146.9c35.4 7.962 102.5 2.918 115.8-40.4 12.25-39.72-39.32-85.01-73.24-97.71-68.16-25.47-101.9 9.642-111.8 41.62-9.615 31.17 0.2104 80.97 69.22 96.48"
      fill="#dda16b"
      stroke-width="2.075"
    />
    <path
      class="egg-spots"
      d="m82.32 120.2c1.204-3.904 5.374-6.1 9.314-4.908 3.94 1.192 6.16 5.327 4.953 9.232-1.204 3.904-5.374 6.1-9.314 4.908-3.938-1.194-6.154-5.327-4.951-9.232m-58.8-36.89c1.283-4.166 5.73-6.51 9.934-5.236 4.2 1.272 6.568 5.682 5.28 9.847-1.283 4.164-5.73 6.51-9.934 5.236-4.2-1.275-6.568-5.682-5.28-9.847m46.42-2.167c1.084-3.513 4.836-5.492 8.381-4.418 3.547 1.074 5.54 4.794 4.457 8.307-1.084 3.516-4.836 5.492-8.381 4.418-3.547-1.074-5.54-4.794-4.457-8.307m52.24 25.36c-1.444-0.4379-2.258-1.952-1.817-3.385 0.4417-1.431 1.971-2.237 3.415-1.799 1.444 0.4379 2.257 1.952 1.815 3.385-0.4417 1.433-1.971 2.237-3.413 1.799m29.59 4.331c-1.125 3.646-5.015 5.696-8.694 4.581-3.675-1.114-5.745-4.969-4.62-8.618 1.123-3.643 5.013-5.695 8.694-4.581 3.675 1.114 5.745 4.972 4.622 8.618m-112.2-66.21c0.844-2.735 3.761-4.271 6.52-3.437 2.757 0.8366 4.309 3.728 3.465 6.463-0.844 2.733-3.761 4.271-6.52 3.435-2.759-0.8343-4.309-3.728-3.467-6.463m39.45-1.448c1.485-4.815 6.627-7.529 11.49-6.053 4.859 1.472 7.589 6.569 6.107 11.39-1.484 4.815-6.627 7.529-11.49 6.053-4.859-1.472-7.589-6.569-6.107-11.39m51.42 11.98c-0.7625 2.472-3.402 3.865-5.897 3.109-2.496-0.7558-3.9-3.373-3.138-5.847 0.7648-2.475 3.402-3.865 5.899-3.109 2.494 0.7558 3.899 3.373 3.136 5.847m19.92 19.69c-0.4021 1.301-1.792 2.034-3.105 1.636-1.313-0.3966-2.051-1.776-1.65-3.075 0.4021-1.301 1.792-2.034 3.105-1.638 1.313 0.3986 2.052 1.776 1.65 3.078m-27.55-47.26c0.5209-1.691 2.329-2.644 4.034-2.128 1.706 0.5164 2.667 2.309 2.146 4.001-0.5209 1.69-2.329 2.644-4.036 2.125-1.704-0.5164-2.667-2.309-2.144-3.999"
      fill="#d3bda3"
      stroke-width="2.075"
    />
  </svg>
`;
