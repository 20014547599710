import { stimuliSource } from '@tasks/chicken/stimuli-it.js';
import { TaskControllerBase } from '@tasks/task-controller-base.js';
import { rewardLocations } from './motor-data.js';
import { MotorRewardSource } from './motor-reward-source.js';
import { MotorPhaseSetting } from './specs/motor-phase-setting.js';
import { MotorSettings } from './specs/motor-settings.js';

/**
 * @typedef {import('lit').ReactiveControllerHost} ReactiveControllerHost
 * @typedef {import('lit').ReactiveElement} ReactiveElement
 * @typedef {import("./specs/motor-demo.js").MotorDemo} MotorDemo
 */

export class TaskController extends TaskControllerBase {
  /**
   * @param {ReactiveControllerHost & ReactiveElement} host
   * @param {String} taskId
   */
  constructor(host, taskId) {
    super(host, taskId);

    const taskRecord = this.project?.getById(taskId);
    this.taskSettings_ = new MotorSettings(taskRecord);

    const targets = this.mode === 'play' ? ['b20', 'b10', 'b0'] : ['b0', 'b10', 'b20'];

    const nums = Array.from({ length: 10 }, (_, index) => index);

    const items = targets.flatMap(name => nums.map(n => ({ name, n })));

    this.stimuli = stimuliSource(items);

    this.rewardSrc = new MotorRewardSource(rewardLocations, this.stimuli);

    this.phases = undefined;
    this.totalBlocks_ = undefined;
    this.phaseIndex = 0;
    this.mode_ = 'play';
  }

  get durations() {
    return this.taskSettings.durations;
  }

  get taskSettings() {
    return this.taskSettings_;
  }
  get mode() {
    return this.mode_;
  }
  set mode(value) {
    if (this.mode_ !== value) {
      this.phases = undefined;
    }
    this.mode_ = value;
  }

  get gameName() {
    return 'motor';
  }

  get rewards() {
    return this.rewardSrc.rewards;
  }

  /**
   * @param {Object[]} values
   */
  addRewards(values) {
    this.rewardSrc.addRewardItems(values);
  }

  getPhases() {
    if (this.phases === undefined) {
      this.phases =
        this.mode === 'demo' ? getDemoPhases(this.taskSettings.demo) : getMainPhases(this.taskSettings.phases);
    }
    return this.phases;
  }

  get totalBlocks() {
    if (this.totalBlocks_ === undefined) {
      let totalBlocks = 0;
      for (const phase of this.getPhases()) {
        totalBlocks += phase.blocks.length;
      }

      this.totalBlocks_ = totalBlocks;
    }

    return this.totalBlocks_;
  }
}

// ===
// Private functions
// ===

/**
 * @param {MotorPhaseSetting } phase
 */
function getBlocks(phase) {
  const { rows, cols } = phase;

  if (phase.type === 'practice') {
    return [{ id: 1, rows, cols }];
  }

  const { numberOfBlocks } = phase;
  return Array.from({ length: numberOfBlocks }, (v, id) => ({ id, rows, cols }));
}

/**
 * @param {string} gridSpec
 */
function parseGrid(gridSpec) {
  const [cols, rows] = gridSpec.split('x').map(value => Number(value.trim()));

  return { rows, cols };
}

/**
 * @param {MotorDemo} demo
 */
function getDemoPhases(demo) {
  const demoPhase = {
    type: 'practice',
    grid: demo.practice.grid,
    numberOfBlocks: 1,
    maxNoResponseTrials: demo.practice.maxNoResponseTrials,
  };

  const demoPhaseSettings = new MotorPhaseSetting(demoPhase);

  const blocks = getBlocks(demoPhaseSettings);

  const animationPhase = {
    type: 'animation',
    grid: demo.animation.grid,
    numberOfBlocks: 1,
    maxNoResponseTrials: 0,
  };

  const animationPhaseSettings = new MotorPhaseSetting(animationPhase);

  const animationblocks = getBlocks(animationPhaseSettings);

  return [
    { index: 0, phaseSettings: animationPhaseSettings, blocks: animationblocks },
    { index: 1, phaseSettings: demoPhaseSettings, blocks },
  ];
}

/**
 * @param {MotorPhaseSetting[]} phases
 */
function getMainPhases(phases) {
  const items = [];
  const startIndex = 0;
  for (let index = startIndex; index < phases.length; index++) {
    const phase = { ...phases[index] };

    const { rows, cols } = parseGrid(phase.grid);

    phase.rows = rows;
    phase.cols = cols;
    const blocks = getBlocks(phase);
    items.push({ index, phaseSettings: phases[index], blocks });
  }

  return items;
}
