import { html } from 'lit';

export const coopRunSmall = html`
  <svg version="1.1" preserveAspectRatio="xMinYMax slice" viewBox="0 0 1980 373" xmlns="http://www.w3.org/2000/svg">
    <path d="m82.134 284.8 296.42 77.73 1601.1-1.141" fill="none" stroke="#ff7800" stroke-width="19.282" />
    <path d="m73.71 269.9 311.06 84.2 1594.7-1.141" fill="none" stroke="#ff9700" stroke-width="13.771" />
    <g transform="matrix(.5828 0 0 .7312 0 .07312)" stroke-width="1.342">
      <path d="m123.9 510h13.42v-65.4h-13.42z" fill="#ff7800" />
      <path d="m0 169h175v-169.1h-175z" fill="#683014" />
      <path d="m0 169v275.6h151l5.369-275.6z" fill="#ff9700" />
      <path d="m0 201.2h156.4v-32.22h-156.4z" fill="#b35d00" />
      <g fill="#cd4a00">
        <path d="m130.6 377.1-19.07 19.08 19.07 19.08 19.07-19.08z" />
        <path d="m93.07 377.1-19.07 19.08 19.07 19.08 19.07-19.08z" />
        <path d="m55.49 377.1-19.07 19.08 19.07 19.08 19.07-19.08z" />
        <path d="m0 395v2.32l17.92 17.92 19.07-19.08-19.07-19.08z" />
      </g>
    </g>
    <rect class="slope-rect" x="101.7" y="270.34" width="243.47" height="75.173" fill="none" stroke-width=".86298">
      <title>slope</title>
    </rect>
  </svg>
`;
