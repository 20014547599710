import { html } from 'lit';

export const eggHatching = html`
  <svg version="1.1" viewBox="0 0 101 142" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <filter id="a" x="-.063256" y="-.059274" width="1.1265" height="1.1185" color-interpolation-filters="sRGB">
        <feGaussianBlur stdDeviation="5.4659594" />
      </filter>
    </defs>
    <g stroke-width=".5606">
      <path
        d="m97.75 99.05c-0.5206 20.76-20.72 37.29-45.12 36.68-24.4-0.6102-43.73-18.12-43.22-38.89 0.5211-20.76 20.72-37.1 45.12-36.49s43.77 17.94 43.22 38.7"
        fill="#af5b2b"
      />
      <g fill="#b6382f">
        <path
          d="m38.1 20.31c1.964-0.696 3.799-0.4778 5.459 0.02185 1.654 0.5283 3.139 1.445 4.369 2.636 1.229 1.188 2.189 2.667 2.758 4.32 0.5408 1.667 0.7382 3.499 0.08586 5.455-1.907-0.7884-3.221-1.589-4.423-2.398-1.184-0.809-2.196-1.635-3.133-2.555-0.9403-0.9175-1.804-1.927-2.647-3.104-0.8418-1.201-1.634-2.516-2.466-4.379"
        />
        <path
          d="m55.54 8.936c1.8 1.422 2.897 3.084 3.758 4.804 0.8338 1.729 1.365 3.546 1.611 5.422 0.2396 1.876 0.1874 3.817-0.2598 5.766-0.479 1.918-1.299 3.986-3.114 5.451-0.9504-2.056-1.652-3.638-2.197-5.329-0.5324-1.666-0.915-3.329-1.129-5.039-0.2115-1.709-0.2665-3.462-0.1094-5.283 0.1812-1.831 0.5219-3.706 1.439-5.791"
        />
        <path
          d="m62.57 13.08c1.417 1.644 2.096 3.368 2.532 5.094 0.4079 1.729 0.5 3.474 0.2867 5.211-0.2093 1.738-0.729 3.476-1.658 5.123-0.9386 1.578-2.321 3.316-4.491 3.878-0.207-2.116-0.5547-3.595-0.6519-5.195-0.1122-1.562-0.09259-3.09 0.1032-4.631 0.1924-1.541 0.5493-3.09 1.129-4.661 0.6048-1.573 1.373-3.158 2.751-4.816"
        />
      </g>
      <g fill="#3c3c3b">
        <path
          d="m6.453 60.68c2.434-1.316 5.236-1.86 8.148-1.829 1.454 0.06779 2.929 0.316 4.373 0.7687 1.428 0.4749 2.809 1.151 4.084 1.983 1.274 0.8342 2.534 1.731 3.666 2.771 1.128 1.044 1.985 2.317 2.526 3.693 1.053 2.775 1.375 5.741 0.3153 8.784l-0.649 0.1882c-2.512-1.961-4.059-3.902-5.56-5.682-0.7311-0.8918-1.314-1.805-1.831-2.787-0.5025-0.9897-1.083-1.965-1.849-2.758-0.7542-0.8044-1.608-1.517-2.56-2.113-0.9335-0.6279-1.975-1.14-3.092-1.527-2.226-0.838-4.79-1.089-7.34-0.8565z"
        />
        <path
          d="m6.062 50.77c1.645-1.189 3.49-2.06 5.501-2.604 2.002-0.5464 4.201-0.7372 6.406-0.344 2.197 0.4106 4.327 1.44 6.094 2.857 1.761 1.432 3.161 3.193 4.31 5.031 1.157 1.923 2.069 3.565 3.058 5.375 0.9331 1.791 1.647 3.693 2.071 5.707 0.4141 2.008 0.6587 4.091 0.5446 6.228-0.1285 2.111-0.391 4.362-1.831 6.325l-0.6322-0.2359c-1.288-3.679-3.086-6.88-4.529-10.3-0.7344-1.698-1.356-3.445-1.846-5.27-0.4773-1.783-0.9722-3.775-1.612-5.3-1.345-3.16-3.413-5.901-6.536-7.166-3.107-1.351-7.096-1.012-10.65 0.284z"
        />
        <path
          d="m23.68 45.97c1.72-0.6897 3.663-0.7094 5.589-0.2347 0.9487 0.2599 1.885 0.6514 2.767 1.153 0.8611 0.5262 1.665 1.155 2.391 1.853 0.7277 0.6985 1.481 1.397 2.148 2.167 0.657 0.7775 1.072 1.688 1.216 2.655 0.1408 0.9624 0.1167 1.953-0.1397 2.939-0.2334 0.983-0.6448 1.967-1.459 2.949l-0.6684 0.1115c-1.089-0.6447-1.833-1.275-2.475-1.89-0.6608-0.6077-1.163-1.203-1.593-1.781-0.4293-0.5804-0.6751-1.181-0.7879-1.864-0.106-0.6834-0.2266-1.389-0.5126-1.992-0.2794-0.606-0.6149-1.186-1.013-1.721-0.3787-0.5602-0.8317-1.085-1.362-1.552-1.036-0.9679-2.436-1.732-4.003-2.128z"
        />
        <path
          d="m52.8 98.3c-0.3282-0.01626-0.6448-0.0577-0.9592-0.1104-20.68-1.353-31.39-17.71-31.39-17.71-3.274 9.069-3.545 19.36-3.545 19.36-0.01683 19.77 15.75 36.03 35.65 36.53l0.9554-38.09c-0.156 0.0062-0.3007 0.03921-0.455 0.0353-0.08527-0.0021-0.1683-0.0038-0.2524-0.0062"
        />
      </g>
      <path
        d="m29.45 51.77-0.01122-0.01513s-0.0011 0.07283-0.0039 0.1894c-0.06957 0.6762-0.1352 1.355-0.152 2.047-0.0056 0.2112 0.01066 0.4179 9e-3 0.6296-0.2064 2.543-0.6852 6.51-1.9 10.68-0.8557 2.923-2.067 5.943-3.819 8.637-1.273 1.966-2.297 4.207-3.131 6.523 0 0 10.72 16.36 31.39 17.71h0.0051c0.3103 0.05266 0.628 0.0941 0.9541 0.1104 0.08418 0.0021 0.1672 0.0045 0.2524 0.0067 0.1537 0.0038 0.299-0.02912 0.455-0.0353l0.4974-19.84 1.231-49.05c-13.23-0.3311-24.33 9.536-25.78 22.42"
        fill="#af5b2b"
      />
      <path
        d="m87.52 82.54s-12.28 16.26-34.05 15.71l-0.9554 38.09c19.9 0.498 36.48-14.99 37.43-34.74 0 0 0.2396-9.96-2.434-19.06"
        fill="#575756"
      />
      <path
        d="m84.62 75.48c-1.474-2.556-2.492-5.363-3.195-8.103-1.203-4.669-1.491-9.137-1.552-11.81 0.0029-0.1036 0.01625-0.2028 0.01852-0.3059 0.01178-0.461-0.02189-0.9132-0.03367-1.368 0.0034-0.5447 0.01852-0.8733 0.01852-0.8733l-0.03982 0.05716c-0.7879-12.97-11.38-23.4-24.63-23.72l-1.231 49.05-0.4975 19.84c21.77 0.5447 34.05-15.71 34.05-15.71-0.7311-2.493-1.673-4.926-2.909-7.077"
        fill="#ea8232"
      />
      <path
        d="m63.24 72.75c0-5e-3 0-0.01009 4.2e-4 -0.01513 0.1408-5.615-4.832-10.3-9.028-10.4-4.193-0.1048-9.398 4.325-9.537 9.939-0.05665 2.249-0.3787 8.153 3.875 8.258 1.254 0.03139 2.377-0.5182 3.147-1.389 0.7012 0.09242 1.408 0.1468 2.088 0.1636 0.6827 0.01736 1.389-0.0017 2.096-0.05884 0.7231 0.9086 1.818 1.514 3.075 1.545 4.243 0.1059 4.226-5.783 4.28-8.04"
        fill="#ea575b"
      />
      <path
        d="m44.51 43.54c-6.221-0.1558-11.38 4.501-11.54 10.4-0.1481 5.905 4.777 10.81 11 10.97 4.651 0.1165 8.624 0.8931 10.44-2.865l0.2945-11.76c-1.625-3.861-5.547-6.64-10.2-6.758"
        fill="#b6382f"
      />
      <path
        d="m64.67 65.48c6.221 0.1558 11.39-4.501 11.54-10.41 0.1481-5.901-4.777-10.81-11-10.97-4.663-0.1165-8.729 2.472-10.54 6.258-0.0029-0.0067-0.0096-0.01681-0.01233-0.02522l-0.2951 11.76c0.0034-0.0095 0.0101-0.01626 0.01402-0.02635 1.616 3.874 5.631 3.294 10.3 3.411"
        fill="#ea575b"
      />
      <path
        d="m52.42 62.53-2.773 1.728c-1.115 0.6964-1.5 2.202-0.8548 3.348l4.091 7.266c0.3232 0.5758 0.7597 0.87 1.203 0.8805l0.3445-13.72c-0.7264-0.01795-1.45 0.1451-2.01 0.4942"
        fill="#f39e2b"
      />
      <path
        d="m55.33 74.93 4.449-7.052c0.7037-1.112 0.3933-2.635-0.6869-3.387l-2.682-1.864c-0.5404-0.3765-1.259-0.5758-1.982-0.5938l-0.3439 13.72c0.4432 0.01063 0.8944-0.2605 1.245-0.8195"
        fill="#fbdd58"
      />
      <path
        d="m46.19 54.3c-0.05051 2.014-1.729 3.606-3.742 3.555-2.017-0.05043-3.611-1.723-3.56-3.738 0.05051-2.011 1.726-3.602 3.743-3.552 2.012 0.05043 3.609 1.723 3.559 3.735"
        fill="#4e1412"
      />
      <path
        d="m69.97 54.93c-0.05051 2.014-1.729 3.606-3.742 3.555-2.015-0.05043-3.61-1.723-3.559-3.738 0.05051-2.011 1.727-3.602 3.742-3.552 2.014 0.05043 3.61 1.723 3.56 3.735"
        fill="#4e1412"
      />
      <path
        d="m45.18 52.87c-0.01738 0.6884-0.5913 1.233-1.279 1.216-0.6894-0.01681-1.234-0.5901-1.216-1.278 0.01738-0.6892 0.5892-1.233 1.279-1.215 0.6877 0.01736 1.234 0.5888 1.216 1.277"
        fill="#fff"
      />
      <path
        d="m68.84 53.41c-0.01738 0.6884-0.5884 1.233-1.279 1.216-0.6894-0.01681-1.234-0.5901-1.216-1.278 0.01738-0.6892 0.5897-1.233 1.279-1.215 0.6894 0.01736 1.233 0.5888 1.216 1.277"
        fill="#fff"
      />
      <path
        d="m39.05 5.015c3.277 1.904 5.749 4.095 8.022 5.955 0.556 0.4631 1.108 0.8822 1.581 1.232 0.2519 0.1882 0.4604 0.2779 0.6886 0.4409l0.9222 0.646c1.329 0.9469 2.86 2.309 4.091 3.937 2.532 3.29 3.623 7.346 3.588 10.97-0.05219 3.674-0.9924 7.136-3.144 10.3-2.064-3.205-3.34-6.14-4.659-8.712-1.29-2.597-2.499-4.661-3.854-6.455-0.6936-0.9233-1.454-1.795-2.487-2.98l-0.8376-0.9746c-0.3484-0.3893-0.6974-0.87-0.9689-1.282-0.5812-0.8435-1.006-1.689-1.377-2.523-1.402-3.35-2.02-6.758-1.567-10.56"
        fill="#ea575b"
      />
    </g>
    <path
      transform="matrix(.4209 0 0 .4203 -1.03 -2.147)"
      d="m32.75 190 51.21-10.15 2.11-47.23 18.69 6.528 9.775-21.01 35.8 2.351 24.7-9.85 2.497 12.6 45.42-16.36c-25.18-48.33-70.9-97.95-124.6-87.31-64.12 12.7-88.95 117.3-81.51 173.4 2.342 17.68 6.132 33.09 11.05 46.46l30.1-14.7z"
      fill="#404041"
      fill-opacity=".5467"
      filter="url(#a)"
      stroke-width="1.333"
    />
    <g stroke-width=".5606">
      <path
        class="egg-shell"
        d="m11.17 74.64 21.55-4.266 0.8881-19.85 7.866 2.744 4.114-8.83 15.07 0.9881 10.4-4.14 1.051 5.295 19.12-6.876c-10.6-20.31-29.84-41.17-52.44-36.69-26.99 5.337-37.44 49.3-34.31 72.88 0.9857 7.43 2.581 13.91 4.651 19.53l12.67-6.178z"
        fill="#dda16b"
      />
      <g class="egg-spots" fill="#d3bda3">
        <path
          d="m13.07 65.48c-1.149 0.2275-2.266-0.5195-2.495-1.667-0.2278-1.147 0.5202-2.263 1.669-2.491 1.149-0.2275 2.267 0.5195 2.495 1.667 0.2272 1.147-0.5206 2.263-1.67 2.491"
        />
        <path
          d="m32.15 34.76c0.8843-0.1748 1.744 0.3994 1.919 1.282 0.1751 0.8828-0.4 1.741-1.284 1.916-0.8834 0.1748-1.744-0.3994-1.919-1.282-0.1751-0.8828 0.4-1.741 1.284-1.916"
        />
        <path
          d="m26.82 52.4c1.681-0.3328 3.313 0.7582 3.646 2.436 0.3333 1.679-0.7593 3.308-2.44 3.64s-3.312-0.7582-3.646-2.436c-0.3333-1.679 0.7593-3.308 2.44-3.64"
        />
        <path
          d="m52.76 22.72c2.476-0.49 4.882 1.118 5.37 3.59 0.4908 2.473-1.119 4.875-3.596 5.363-2.476 0.49-4.882-1.118-5.37-3.59-0.4908-2.473 1.119-4.875 3.596-5.363"
        />
        <path
          d="m59.37 41.14c0.1924 0.9708-0.4386 1.915-1.412 2.108-0.9735 0.1927-1.918-0.4392-2.111-1.41-0.1924-0.9708 0.4386-1.915 1.412-2.108 0.9735-0.1927 1.918 0.4396 2.111 1.41"
        />
      </g>
      <path
        class="egg-crack"
        d="m18.35 72.96-2.25-8.296-0.221-0.8141 0.7597-0.2056 9.032-2.442-0.2839 0.6913-6.027-12.22 6.877 11.76 0.2878 0.4926-0.5711 0.1989-8.834 3.076 0.5392-1.02 2.833 8.12z"
        fill="#8c6239"
      />
      <path
        class="egg-crack"
        d="m71.15 43.7 6.856-7.918 0.463 1.313-9.525-2.365-1.018-0.2527 0.8106-0.5934 6.772-4.955-6.153 5.699-0.207-0.846 9.659 1.716 1.296 0.2297-0.8333 1.083-6.385 8.305z"
        fill="#8c6239"
      />
      <path
        class="egg-shell"
        d="m97.16 61.27-20.08 3.031v-5.396l-11.01 2.042-14.59-3.893-5.758 7.859-7.18-4.22-4.735 19.3h-21.97l7.584 16.37-13.63 3.602c5.038 31.78 28.39 42 47.1 42 21.6 0 51.26-15.79 47.85-62.07-0.4073-5.522-1.591-11.98-3.543-18.64"
        fill="#dda16b"
      />
      <g class="egg-spots" fill="#d3bda3">
        <path
          d="m33.55 95.78c-3.336 0-6.044-2.7-6.044-6.035s2.705-6.035 6.044-6.035 6.044 2.7 6.044 6.035-2.705 6.035-6.044 6.035"
        />
        <path
          d="m42.03 120.2c-1.894 0-3.429-1.532-3.429-3.424s1.535-3.424 3.429-3.424 3.429 1.532 3.429 3.424-1.535 3.424-3.429 3.424"
        />
        <path
          d="m59.37 94.14c-2.434 0-4.407-1.971-4.407-4.4s1.974-4.4 4.407-4.4 4.407 1.971 4.407 4.4-1.974 4.4-4.407 4.4"
        />
        <path
          d="m69.64 122.8c-2.886 0-5.223-2.335-5.223-5.216s2.339-5.216 5.223-5.216c2.885 0 5.223 2.336 5.223 5.216 0 2.881-2.34 5.216-5.223 5.216"
        />
        <path
          d="m81.67 78.84c-2.705 0-4.899-2.19-4.899-4.892s2.193-4.892 4.899-4.892 4.899 2.19 4.899 4.892-2.193 4.892-4.899 4.892"
        />
      </g>
      <path
        class="egg-crack"
        d="m5.612 97.5 13.55-2.577-0.984 1.98-7.193-16.37-0.6507-1.483 23.491 0.63637-0.4079 0.3205 4.933-18.53-4.117 18.72-0.06844 0.3109-0.3394 0.0095-21.92 0.5938 0.9196-1.44 7.921 16.04 0.7677 1.553-1.752 0.427-13.4 3.267z"
        fill="#8c6239"
      />
      <path
        class="egg-crack"
        d="m97.16 63.84-19.35 2.33-1.355 0.163-0.08022-1.275-0.4604-7.338 1.146 0.7422-10.93 2.934-0.138 0.03698-0.1083-0.03639-14.6-4.921 14.84 4.133-0.2457 4.21e-4 10.65-3.834 0.9609-0.3457 0.184 1.089 1.225 7.25-1.434-1.112 19.27-2.957z"
        fill="#8c6239"
      />
    </g>
  </svg>
`;
