import { loadAndDecode } from './buffer-utils.js';
import { SoundBase } from './sound-base.js';

/**
 * @typedef { import('./buffer.js').URLInfo} URLInfo
 */

/**
 * @type {WeakMap<Promise, (when:number?)=> void>}
 */
const stopMethods = new WeakMap();

const createAbortError = reason => {
  const error = new Error(`Play aborted: ${reason}`);
  error.name = 'AbortError';
  return error;
};

export class Sound extends SoundBase {
  /**
   * @param {AudioContext} context
   * @param {URLInfo | URLInfo[] | ArrayBuffer[] | AudioBuffer[]} urlsOrBuffers
   * @param {Object} options
   */
  static async createAndLoad(context, urlsOrBuffers, options) {
    const instance = new Sound(context, urlsOrBuffers, options);

    await instance.load();

    return instance;
  }

  /**
   * @type {AudioBuffer[]}
   */
  audioBuffers;

  #urlsOrBuffers;

  /**
   * @param {AudioContext} context
   * @param {URLInfo | URLInfo[] | ArrayBuffer[] | AudioBuffer[]} urlsOrBuffers
   * @param {Object} options
   */
  constructor(context, urlsOrBuffers, options) {
    super(context, options);

    if (urlsOrBuffers?.[0] instanceof AudioBuffer) {
      this.audioBuffers = /** @type {AudioBuffer[]} */ (urlsOrBuffers);
    } else {
      this.#urlsOrBuffers = /** @type {string | string[] | ArrayBuffer[]} */ (urlsOrBuffers);
    }
  }

  async load() {
    if (!this.audioBuffers) {
      this.audioBuffers = await loadAndDecode(this.context, this.#urlsOrBuffers);
    }
    return this;
  }

  refreshNodes() {
    const sources = this.audioBuffers.map(b => {
      const source = this.context.createBufferSource();
      source.buffer = b;
      source.connect(this.sourceDestination);

      return source;
    });

    return sources;
  }

  /**
   *
   * @param {Object} param0
   * @param {number} [param0.when]
   * @param {boolean} [param0.loop]
   * @param {AbortSignal} [param0.signal]
   * @param {Object} [param0.value]
   * @returns
   */
  play({ when = this.context.currentTime, loop = false, signal, value } = {}) {
    if (!this.audioBuffers) {
      throw new Error('No audio buffers loadedd');
    }

    if (signal?.aborted) {
      return Promise.reject(createAbortError(signal.reason));
    }

    let settle;
    let rejectFunction;

    const sources = this.refreshNodes();

    const signalListener = () => {
      this.stopSources(sources, 0.1);
      rejectFunction(createAbortError(signal.reason));
    };

    const cleanup = () => {
      signal?.removeEventListener('abort', signalListener);
    };

    const playPromise = new Promise((resolve, reject) => {
      settle = () => {
        cleanup();
        resolve(value);
      };

      rejectFunction = reject;

      let lastChunkOffset = 0;
      for (const [index, s] of sources.entries()) {
        s.addEventListener('ended', () => {
          // eslint-disable-next-line unicorn/prefer-add-event-listener
          s.onended = undefined;
          s.disconnect(0);

          if (index === sources.length - 1) {
            settle();
          }
        });

        s.loop = loop;
        s.start(when + lastChunkOffset);
        lastChunkOffset += s.buffer.duration;
      }
    });

    signal?.addEventListener('abort', signalListener, { once: true });

    stopMethods.set(playPromise, (when = 0.1) => {
      this.stopSources(sources, when);
    });

    return playPromise;
  }

  /**
   * @param {AudioBufferSourceNode[]} sources
   * @param {number} when
   */
  stopSources(sources, when) {
    for (const source of sources) {
      source.stop(this.context.currentTime + when);
    }
  }

  /**
   * @param {Promise} promise
   * @param {number} [when]
   */
  static stop(promise, when) {
    stopMethods.get(promise)?.(when);
  }
}
