import { html } from 'lit';

export const c15 = html`
  <svg
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    viewBox="0 0 620 670"
  >
    <path
      d="m505 464.8c0 87.2-82.9 158.6-185.3 158.6-102.3 0-185.2-71.5-185.2-158.6 0-87.2 83-157.9 185.2-157.9 102.3 0 185.2 70.69 185.2 157.9"
      fill="#b3b3b3"
    />
    <g fill="#3c3c3b">
      <path
        d="m118.8 313.4c10.06-5.778 21.74-8.356 33.96-8.53 6.101 0.1325 12.31 1.02 18.4 2.766 6.038 1.844 11.89 4.534 17.32 7.895 5.425 3.368 10.8 7 15.65 11.25 4.839 4.261 8.562 9.515 10.98 15.24 4.709 11.54 6.365 23.96 2.246 36.82l-2.7 0.86c-10.74-7.965-17.42-15.95-23.9-23.26-3.158-3.668-5.694-7.44-7.964-11.51-2.211-4.101-4.745-8.132-8.039-11.38-3.245-3.298-6.899-6.198-10.95-8.6-3.979-2.536-8.398-4.576-13.12-6.086-9.418-3.282-20.2-4.065-30.85-2.822l-1.03-2.648z"
      />
      <path
        d="m115 271.8c6.77-5.164 14.41-9.012 22.78-11.51 8.335-2.502 17.52-3.536 26.82-2.118 9.246 1.495 18.28 5.589 25.82 11.35 7.532 5.828 13.59 13.08 18.6 20.66 5.051 7.95 9.044 14.75 13.39 22.24 4.098 7.42 7.291 15.32 9.282 23.74 1.945 8.384 3.189 17.1 2.938 26.09-0.3188 8.872-1.181 18.35-7.008 26.74l-2.676-0.9225c-5.785-15.31-13.66-28.56-20.06-42.78-3.256-7.05-6.044-14.31-8.294-21.92-2.186-7.434-4.471-15.74-7.315-22.08-5.966-13.12-14.92-24.4-28.15-29.39-13.16-5.345-29.85-3.501-44.62 2.312l-1.489-2.418z"
      />
      <path
        d="m188.8 249.6c7.136-3.074 15.28-3.361 23.4-1.572 4.002 0.9925 7.968 2.536 11.72 4.549 3.664 2.116 7.098 4.674 10.21 7.524 3.124 2.858 6.355 5.709 9.232 8.874 2.834 3.192 4.67 6.972 5.372 11.02 0.6925 4.025 0.6962 8.181-0.275 12.35-0.8738 4.15-2.495 8.321-5.806 12.52l-2.788 0.5375c-4.632-2.591-7.819-5.155-10.57-7.67-2.834-2.481-5-4.926-6.865-7.309-1.859-2.389-2.951-4.884-3.496-7.74-0.5175-2.859-1.098-5.806-2.359-8.308-1.236-2.515-2.702-4.911-4.429-7.119-1.645-2.314-3.599-4.465-5.869-6.372-4.444-3.954-10.39-7.014-17-8.51l-0.4688-2.78z"
      />
      <path
        d="m316.3 466.3c-1.376-0.035-2.708-0.175-4.031-0.3625-86.8-3.508-133.4-71.05-133.4-71.05-12.76 38.41-12.82 81.62-12.82 81.62 2.009 82.95 69.82 149.5 153.2 149.5v-160c-0.6538 0.0412-1.258 0.195-1.904 0.195h-1.059z"
      />
    </g>
    <path
      d="m213.8 273.5-0.0487-0.0625v0.7962c-0.22 2.844-0.4238 5.701-0.4238 8.608 0 0.8862 0.0913 1.752 0.105 2.64-0.5975 10.7-2.186 27.4-6.84 45.02-3.28 12.36-8.041 25.16-15.1 36.66-5.128 8.384-9.184 17.89-12.44 27.7 0 0 46.62 67.55 133.4 71.05h0.0213c1.306 0.1875 2.641 0.3275 4.01 0.3625h1.059c0.6462 0 1.251-0.1538 1.904-0.195v-289.3c-55.48 0-101 42.56-105.7 96.8"
      fill="#b3b3b3"
    />
    <path
      d="m460 396.5s-49.72 69.54-141 69.54v160c83.48 0 151.4-66.72 153.2-149.8 0 0-0.0425-41.82-12.21-79.76"
      fill="#575756"
    />
    <path
      d="m447.5 367.2c-6.445-10.57-11.01-22.25-14.24-33.65-5.534-19.48-7.21-38.19-7.745-49.38 0-0.4325 0.045-0.8525 0.045-1.285 0-1.936-0.1888-3.829-0.2862-5.736-0.0425-2.285-0.0138-3.668-0.0138-3.668l-0.1612 0.2438c-4.664-54.34-50.15-96.99-105.7-96.99v289.3c91.3 0 141-69.54 141-69.54-3.325-10.39-7.528-20.5-12.94-29.39"
      fill="#ccc"
    />
    <g fill="#999">
      <path
        d="m357.5 357.8v-0.0625c0-23.6-21.3-42.7-38.9-42.7-17.59 0-38.92 19.12-38.92 42.71 0 9.445-0.73 34.25 17.11 34.25 5.258 0 9.908-2.424 13.05-6.162 2.949 0.315 5.918 0.4688 8.768 0.4688 2.865 0 5.824-0.1538 8.779-0.4688 3.126 3.739 7.78 6.162 13.05 6.162 17.8 0 17.1-24.7 17.1-34.19"
      />
      <path
        d="m276.3 237.3c-26.09 0-47.24 20.1-47.24 44.88 0 24.8 21.15 44.89 47.24 44.89 19.5 0 36.24 2.844 43.46-13.12v-49.36c-7.218-16.04-23.95-27.28-43.46-27.28"
      />
      <path
        d="m362.5 327.2c26.09 0 47.25-20.09 47.25-44.89 0-24.78-21.16-44.88-47.25-44.88-19.56 0-36.34 11.29-43.52 27.38-0.0137-0.0288-0.0412-0.07-0.055-0.105v49.36c0.0138-0.0412 0.0413-0.0687 0.055-0.1112 7.182 16.09 23.96 13.24 43.52 13.24"
      />
    </g>
    <path
      d="m311.3 316.2-11.44 7.545c-4.601 3.04-6.054 9.398-3.232 14.14l17.91 30.08c1.415 2.382 3.276 3.57 5.135 3.57v-57.61c-3.046 0-6.061 0.7612-8.37 2.284"
      fill="#f39e2b"
    />
    <path
      d="m325 367.9 17.91-30.08c2.83-4.744 1.37-11.1-3.235-14.14l-11.44-7.545c-2.306-1.524-5.338-2.285-8.37-2.285v57.61c1.859 0 3.72-1.188 5.131-3.57"
      fill="#fbdd58"
    />
    <path
      d="m283.7 282.3c0 8.461-6.868 15.31-15.31 15.31-8.46 0-15.31-6.854-15.31-15.31 0-8.446 6.854-15.3 15.31-15.3 8.44 0 15.31 6.854 15.31 15.3"
      fill="#4e1412"
    />
    <path
      d="m383.7 282.3c0 8.461-6.864 15.31-15.31 15.31-8.45 0-15.31-6.854-15.31-15.31 0-8.446 6.86-15.3 15.31-15.3 8.448 0 15.31 6.854 15.31 15.3"
      fill="#4e1412"
    />
    <path
      d="m280 276.3c0 2.892-2.348 5.24-5.232 5.24-2.892 0-5.234-2.348-5.234-5.24s2.341-5.232 5.234-5.232c2.885 0 5.232 2.34 5.232 5.232"
      fill="#fff"
    />
    <path
      d="m378.7 276.3c0 2.892-2.338 5.24-5.23 5.24-2.891 0-5.232-2.348-5.232-5.24s2.341-5.232 5.232-5.232c2.892 0 5.23 2.34 5.23 5.232"
      fill="#fff"
    />
    <path
      d="m318.7 466c-0.6538 0.0412-1.258 0.195-1.904 0.195h-1.059c-1.376-0.035-2.708-0.1738-4.031-0.3625-3.388-0.1375-6.708-0.3825-9.972-0.7062v159.9c5.572 0.6138 11.23 0.9388 16.96 0.9388 5.844 0 11.61-0.3375 17.28-0.975v-159.9c-5.584 0.55-11.34 0.85-17.28 0.85"
      fill="#c1272d"
    />
    <path
      d="m336.3 465.2v159.9c11.88-1.332 23.35-4.018 34.25-7.908v-158.9c-10.54 3.28-21.96 5.731-34.25 6.941"
      fill="#662d91"
    />
    <path
      d="m267.5 458.2v159.1c10.9 3.862 22.38 6.524 34.25 7.831v-159.9c-12.3-1.221-23.72-3.705-34.25-7.052"
      fill="#662d91"
    />
    <g fill="#c1272d">
      <path d="m232.5 443v157c10.56 7.106 22.05 12.92 34.25 17.25v-159.1c-12.7-4-24.1-9.3-34.2-15.1" />
      <path d="m370 458.3v158.9c12.21-4.358 23.7-10.22 34.25-17.36v-156.2c-10.1 5.666-21.52 10.84-34.25 14.8" />
      <path d="m471.3 476.3s-0.0425-41.82-12.21-79.76-7.151 9.995-21 22.4v149.1c20.12-25.31 32.41-57.12 33.2-91.79" />
    </g>
    <path
      d="m403.7 599.8c12.98-8.778 24.52-19.49 34.25-31.72v-149.1c-8.766 7.858-20.22 16.68-34.25 24.55v156.2z"
      fill="#662d91"
    />
    <path
      d="m177.5 394.8c-12.76 38.4-12.82 81.6-12.82 81.6 0.8412 34.78 13.25 66.68 33.54 92.01v-150.8c-13.68-12.6-20.72-22.8-20.72-22.8"
      fill="#c1272d"
    />
    <path
      d="m198.8 417.6v150.8c9.738 12.16 21.29 22.81 34.25 31.52v-157c-14.06-8.104-25.51-17.2-34.25-25.29"
      fill="#662d91"
    />
    <path
      d="m371.3 540.4c0 31.06-25.19 56.25-56.25 56.25s-56.25-25.19-56.25-56.25 25.19-56.25 56.25-56.25 56.25 25.19 56.25 56.25"
      fill="#fff"
    />
    <path d="m323.5 578.1v-75.34h-9.882c-2.338 8.925-10.09 13.49-23.06 13.49v9.882h18.06v51.96h14.88z" fill="#709941" />
    <g fill-rule="evenodd">
      <path
        d="m330 108.2c0 6.418-5.202 11.62-11.62 11.62s-11.62-5.201-11.62-11.62c0-6.418 5.201-11.62 11.62-11.62 6.416 0 11.62 5.201 11.62 11.62"
        fill="#2e3192"
      />
      <path
        d="m400 150.4c-0.2912-17.69-10.06-31.82-21.75-31.64 0 0-36.78-9.611-57.51-10.29-20.65-0.615-64.4 12.29-64.4 12.29-11.7 0.1925-20.9 14.64-20.61 32.32l-11.1 70.35 79.82-1.314 8.355-0.1362 101.4-1.669-14.26-69.94z"
        fill="#0071bc"
      />
      <path
        opacity=".47"
        d="m231.8 176.2-7.036 47.22 79.82-1.312 8.355-0.1375 101.4-1.669-10.25-47.01-84.88-6.946-87.5 9.859z"
        fill="#603813"
      />
      <path
        d="m320 211.5c-24.54 0-98.32 9.21-98.32 9.21l-5.879 22.24s-2.79 12.58 13.46 12.58 44.98-12.58 89.11-12.58c44.15 0 76.65 12.58 85.31 12.58 8.666 0 22.02-2.3 18.75-12.58l-7-22.2s-72.98-9.21-95.36-9.21"
        fill="#2e3192"
      />
      <path
        opacity=".33"
        d="m414.2 220.3-14.26-69.94c-0.2912-17.69-10.06-31.82-21.75-31.64 0 0-36.78-9.611-57.51-10.29-0.7212 0.0125-1.584-0.1138-2.352-0.17l-0.025 134.8 32.78 2.411c2.578 0.2362 5.138 0.6325 7.665 1.185l34.25 7.046c8.138 2.625 13.35 4.025 21.21 0.7l8.164-5.696v-5.61l-8.164-22.68z"
        fill="#917561"
      />
    </g>
  </svg>
`;
