import '@material/web/button/filled-button.js';
import '@material/web/button/filled-tonal-button.js';
import '@material/web/button/outlined-button.js';
import '@material/web/button/text-button.js';
import '@material/web/checkbox/checkbox.js';
import '@material/web/dialog/dialog.js';
import '@material/web/divider/divider.js';
import '@material/web/fab/fab.js';
import '@material/web/icon/icon.js';
import '@material/web/iconbutton/filled-tonal-icon-button.js';
import '@material/web/iconbutton/icon-button.js';
import '@material/web/menu/menu-item.js';
import '@material/web/menu/menu.js';
import '@material/web/progress/circular-progress.js';
import '@material/web/progress/linear-progress.js';
import '@material/web/radio/radio.js';
import '@material/web/select/filled-select.js';
import '@material/web/select/outlined-select.js';
import '@material/web/select/select-option.js';
import '@material/web/textfield/filled-text-field.js';
import '@material/web/textfield/outlined-text-field.js';

import '~/components/svg-icon.js';
import '~/general/about.js';
import('~/general/help.js');

import '~/components/navbar.js';
import '~/components/onc-warning-dialog.js';
import '~/components/screen-svg';
import '~/components/snackbar.js';

import '~/app/init-app.js';

import '~/signin/form-message.js';
import '~/signin/sign-in/check-account.js';
import '~/signin/sign-in/check-password.js';
import '~/signin/sign-in/forgot-password.js';
import '~/signin/sign-in/light-signin.js';
import '~/signin/sign-in/sign-in.js';
import '~/signin/sign-in/verify-account.js';

import '~/signin/identity/onc-facebook-identity';
import '~/signin/identity/onc-google-identity';

import '~/home/home.js';

import '@tasks/layout-task.js';
import '@tasks/task-progress.js';

import '@tasks/chicken/block-feedback.js';
import '@tasks/chicken/chicken-rewards.js';
import '@tasks/chicken/chicken-task-progress.js';
import '@tasks/chicken/index.js';
import '@tasks/chicken/presenter.js';

import '@tasks/memory/block-feedback.js';
import '@tasks/memory/index.js';
import '@tasks/memory/memory-rewards.js';
import '@tasks/memory/presenter.js';

import '@tasks/motor/block-feedback.js';
import '@tasks/motor/end-game.js';
import '@tasks/motor/index.js';
import '@tasks/motor/motor-rewards.js';
import '@tasks/motor/presenter.js';
import '@tasks/motor/task-trailer.js';

import '@tasks/sheep/block-feedback.js';
import '@tasks/sheep/index.js';
import '@tasks/sheep/presenter.js';
import '@tasks/sheep/sheep-rewards.js';
import '@tasks/sheep/task-progress.js';

import '~/tasks/game-item.js';
