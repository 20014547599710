import { html } from 'lit';

export const c17 = html`
  <svg
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    viewBox="0 0 620 670"
  >
    <path
      d="m504.6 465c0 87.2-82.95 158.6-185.2 158.6-102.3 0-185.2-71.48-185.2-158.6 0-87.19 82.95-157.9 185.2-157.9 102.3 0 185.2 70.69 185.2 157.9"
      fill="#f7931e"
    />
    <g fill="#c1272d">
      <path
        d="m118.2 313.8c10.06-5.778 21.74-8.356 33.96-8.53 6.104 0.1325 12.31 1.02 18.4 2.766 6.036 1.845 11.89 4.534 17.32 7.895 5.425 3.368 10.8 7 15.65 11.25 4.839 4.262 8.562 9.516 10.98 15.24 4.709 11.54 6.365 23.96 2.246 36.82l-2.7 0.86c-10.74-7.965-17.42-15.95-23.9-23.26-3.158-3.668-5.694-7.44-7.964-11.51-2.212-4.101-4.745-8.132-8.039-11.38-3.245-3.298-6.899-6.198-10.95-8.6-3.979-2.536-8.398-4.576-13.12-6.086-9.418-3.282-20.2-4.065-30.85-2.821l-1.03-2.649z"
      />
      <path
        d="m115.4 272.6c6.77-5.162 14.41-9.012 22.78-11.51 8.335-2.502 17.52-3.536 26.82-2.118 9.246 1.495 18.28 5.589 25.82 11.35 7.532 5.828 13.59 13.08 18.6 20.66 5.051 7.95 9.044 14.75 13.39 22.24 4.098 7.42 7.291 15.32 9.281 23.74 1.946 8.384 3.19 17.1 2.939 26.09-0.3188 8.872-1.181 18.35-7.008 26.74l-2.676-0.9225c-5.785-15.31-13.66-28.56-20.06-42.78-3.256-7.05-6.044-14.31-8.294-21.92-2.186-7.434-4.471-15.74-7.315-22.08-5.966-13.12-14.92-24.4-28.15-29.39-13.16-5.345-29.85-3.501-44.62 2.312l-1.46-2.4z"
      />
      <path
        d="m188.8 250c7.136-3.074 15.28-3.361 23.4-1.572 4.002 0.9925 7.968 2.536 11.72 4.549 3.664 2.116 7.098 4.674 10.21 7.525 3.124 2.856 6.355 5.708 9.232 8.872 2.834 3.192 4.671 6.972 5.372 11.02 0.6925 4.024 0.6962 8.181-0.275 12.35-0.8738 4.15-2.495 8.321-5.806 12.52l-2.788 0.5375c-4.632-2.591-7.818-5.155-10.57-7.67-2.834-2.481-5-4.926-6.865-7.309-1.859-2.389-2.951-4.884-3.496-7.74-0.5175-2.859-1.098-5.806-2.359-8.308-1.236-2.515-2.702-4.911-4.429-7.119-1.645-2.314-3.599-4.465-5.869-6.372-4.444-3.954-10.39-7.014-17-8.51l-0.4688-2.78z"
      />
    </g>
    <path
      d="m316.2 466.3c-1.376-0.035-2.708-0.175-4.031-0.3625-86.8-3.508-133.4-71.05-133.4-71.05-12.76 38.41-12.82 81.62-12.82 81.62 2.009 82.95 69.82 149.5 153.2 149.5v-160c-0.6538 0.0413-1.258 0.195-1.904 0.195h-1.059z"
      fill="#d4145a"
    />
    <path
      d="m213.6 273.8-0.0487-0.0625s0.004 0.3075 0.004 0.7962c-0.22 2.844-0.4238 5.701-0.4238 8.608 0 0.8862 0.0913 1.754 0.105 2.641-0.5975 10.7-2.186 27.4-6.84 45.02-3.28 12.36-8.041 25.16-15.1 36.66-5.128 8.384-9.184 17.89-12.44 27.7 0 0 46.62 67.55 133.4 71.05h0.0213c1.306 0.1875 2.641 0.3275 4.01 0.3625h1.059c0.6462 0 1.25-0.1538 1.904-0.195v-289.3c-55.48 0-101 42.56-105.7 96.8"
      fill="#f7931e"
    />
    <path
      d="m460.2 396.3s-49.72 69.54-141 69.54v160c83.48 0 151.4-66.72 153.2-149.8 0 0-0.0425-41.82-12.21-79.76"
      fill="#d4145a"
    />
    <path
      d="m447.3 367.6c-6.445-10.57-11.01-22.25-14.24-33.65-5.534-19.48-7.21-38.19-7.745-49.38 0-0.4325 0.045-0.8525 0.045-1.285 0-1.936-0.1888-3.829-0.2862-5.736-0.0425-2.285-0.0137-3.668-0.0137-3.668l-0.1612 0.2438c-4.664-54.34-50.15-96.99-105.7-96.99v289.3c91.29 0 141-69.54 141-69.54-3.325-10.39-7.528-20.5-12.94-29.39"
      fill="#fbb03b"
    />
    <g fill="#999">
      <path
        d="m357.4 357.6v-0.0625c0-23.58-21.34-42.71-38.92-42.71-17.59 0-38.92 19.12-38.92 42.71 0 9.445-0.73 34.25 17.11 34.25 5.258 0 9.906-2.425 13.05-6.162 2.949 0.315 5.918 0.4688 8.768 0.4688 2.865 0 5.824-0.1538 8.779-0.4688 3.126 3.738 7.78 6.162 13.05 6.162 17.8 0 17.1-24.7 17.1-34.19"
      />
      <path
        d="m275.7 237.6c-26.09 0-47.24 20.1-47.24 44.88 0 24.8 21.15 44.89 47.24 44.89 19.5 0 36.24 2.844 43.46-13.12v-49.36c-7.3-16-24-27.2-43.5-27.2"
      />
      <path
        d="m362.8 327.6c26.09 0 47.25-20.09 47.25-44.89 0-24.78-21.16-44.88-47.25-44.88-19.56 0-36.34 11.29-43.52 27.38-0.015-0.0288-0.0425-0.07-0.0563-0.105v49.36c0.0138-0.0412 0.0413-0.0687 0.0563-0.1112 7.181 16.09 23.95 13.24 43.52 13.24"
      />
    </g>
    <path
      d="m310.9 316.3-11.44 7.545c-4.601 3.04-6.054 9.398-3.231 14.14l17.9 30.08c1.415 2.382 3.276 3.57 5.135 3.57v-57.61c-3.046 0-6.061 0.7612-8.37 2.285"
      fill="#f39e2b"
    />
    <path
      d="m324.4 368.8 17.91-30.08c2.83-4.744 1.37-11.1-3.235-14.14l-11.44-7.545c-2.306-1.524-5.339-2.285-8.37-2.285v57.61c1.859 0 3.72-1.188 5.131-3.57"
      fill="#fbdd58"
    />
    <path
      d="m284 282.6c0 8.461-6.868 15.31-15.31 15.31-8.46 0-15.31-6.854-15.31-15.31 0-8.446 6.854-15.3 15.31-15.3 8.44 0 15.31 6.854 15.31 15.3"
      fill="#4e1412"
    />
    <path
      d="m383.7 282.6c0 8.461-6.864 15.31-15.31 15.31-8.45 0-15.31-6.854-15.31-15.31 0-8.446 6.86-15.3 15.31-15.3 8.448 0 15.31 6.854 15.31 15.3"
      fill="#4e1412"
    />
    <path
      d="m279.6 276.3c0 2.892-2.348 5.24-5.232 5.24-2.892 0-5.234-2.348-5.234-5.24s2.341-5.232 5.234-5.232c2.885 0 5.232 2.34 5.232 5.232"
      fill="#fff"
    />
    <path
      d="m378.8 276.3c0 2.892-2.338 5.24-5.23 5.24-2.891 0-5.232-2.348-5.232-5.24s2.341-5.232 5.232-5.232c2.892 0 5.23 2.34 5.23 5.232"
      fill="#fff"
    />
    <path
      d="m245.9 135c8.158-3.126 15.88-2.404 22.88-0.4825 6.99 2.044 13.31 5.736 18.58 10.61 5.274 4.855 9.452 10.96 12.01 17.84 2.442 6.939 3.462 14.61 0.9325 22.9-8.072-3.109-13.66-6.329-18.79-9.599-5.048-3.27-9.376-6.63-13.4-10.4-4.039-3.751-7.766-7.895-11.42-12.75-3.6-4.95-7.1-10.39-10.8-18.13"
      fill="#999"
    />
    <path
      d="m318 85.03c7.692 5.781 12.46 12.64 16.26 19.76 3.675 7.171 6.092 14.74 7.322 22.6 1.201 7.846 1.188 16-0.4825 24.22-1.806 8.101-5.026 16.86-12.48 23.2-4.2-8.531-7.305-15.1-9.768-22.14-2.408-6.934-4.185-13.88-5.261-21.02-1.065-7.151-1.481-14.5-1.012-22.16 0.6-7.7 1.8-15.61 5.5-24.46"
      fill="#d4145a"
    />
    <path
      d="m347.7 102.5c6.11 6.752 9.142 13.91 11.15 21.1 1.89 7.214 2.459 14.54 1.75 21.85-0.695 7.315-2.69 14.66-6.41 21.68-3.77 6.721-9.38 14.16-18.42 16.75-1.09-8.86-2.704-15.02-3.278-21.72-0.635-6.542-0.7125-12.96-0.055-19.45 0.6462-6.488 1.976-13.02 4.24-19.69 2.369-6.669 5.422-13.4 11.02-20.5"
      fill="#ed1e79"
    />
    <path
      d="m248.4 71.28c13.92 7.646 24.54 16.59 34.26 24.15 2.379 1.886 4.738 3.588 6.756 5.006 1.076 0.7612 1.96 1.118 2.931 1.778l3.934 2.614c5.669 3.835 12.23 9.39 17.55 16.1 10.96 13.54 15.95 30.45 16.19 45.69 0.1688 15.42-3.409 30.08-12.09 43.55-8.991-13.24-14.65-25.41-20.45-36.08-5.68-10.77-10.96-19.31-16.82-26.69-3.004-3.804-6.284-7.381-10.74-12.25l-3.612-4.004c-1.502-1.596-3.015-3.578-4.195-5.279-2.526-3.479-4.395-6.982-6.036-10.44-6.229-13.91-9.178-28.15-7.675-44.15"
      fill="#f0f"
    />
    <path
      d="m326 181.3 58.34 38.31c3.825 1.275 7.334-4.282 7.334-11.62v-77.82c0-8.139-4.262-13.84-8.328-11.12l-58.34 38.88c-2.498 1.665-4.169 6.122-4.169 11.12v0.6362c0 5.725 2.176 10.62 5.162 11.62"
      fill="#c1272d"
    />
    <path
      d="m309 157.5-58.3-38.31c-3.825-1.275-7.334 4.282-7.334 11.62v77.82c0 8.139 4.262 13.84 8.328 11.12l58.34-38.88c2.498-1.665 4.169-6.122 4.169-11.12v-0.6362c0-5.725-2.176-10.62-5.162-11.62"
      fill="#c1272d"
    />
    <path
      d="m332.7 168.8c0 7.901-6.405 14.3-14.3 14.3-7.9 0-14.3-6.405-14.3-14.3 0-7.901 6.405-14.3 14.3-14.3 7.901 0 14.3 6.405 14.3 14.3"
      fill="#ed1c24"
    />
    <g fill="#f0f">
      <path
        d="m305.8 486.3c4.342 0 7.854 3.506 7.854 7.854v13.82c0 4.348-3.511 7.88-7.854 7.88h-39.38c-4.294 0-7.852-3.532-7.852-7.852v-13.85c0-4.348 3.559-7.854 7.852-7.854h39.38z"
      />
      <path
        d="m368.6 486.3c4.342 0 7.848 3.506 7.848 7.854v13.82c0 4.348-3.559 7.88-7.848 7.88h-39.31c-4.348 0-7.906-3.532-7.906-7.88v-13.82c0-4.348 3.559-7.854 7.906-7.854h39.31z"
      />
      <path
        d="m302.7 512.6c0-4.342 3.511-7.854 7.854-7.854h13.82c4.348 0 7.854 3.511 7.854 7.854v39.34c0 4.348-3.506 7.854-7.854 7.854h-13.82c-4.342 0-7.854-3.506-7.854-7.854v-39.34z"
      />
      <path
        d="m302.7 450c0-4.348 3.511-7.854 7.854-7.854h13.82c4.348 0 7.854 3.506 7.854 7.854v39.31c0 4.341-3.506 7.874-7.854 7.874h-13.82c-4.342 0-7.854-3.532-7.854-7.874v-39.31z"
      />
    </g>
    <g fill="#d100ff">
      <path
        d="m319.7 481.3c3.088 3.061 3.088 8.035 0 11.1l-9.789 9.789c-3.092 3.066-8.062 3.066-11.1 0l-27.8-27.8c-3.088-3.088-3.088-8.062 0-11.12l9.789-9.794c3.034-3.061 8.009-3.061 11.1 0l27.8 27.82z"
      />
      <path
        d="m364 526.3c3.088 3.061 3.088 8.035 0.0538 11.1l-9.8 9.8c-3.092 3.066-8.062 3.066-11.15 0l-27.8-27.8c-3.088-3.088-3.088-8.062 0-11.12l9.789-9.789c3.088-3.04 8.062-3.066 11.1 0.0213l27.8 27.8z"
      />
      <path
        d="m298.7 498.8c3.04-3.04 8.009-3.04 11.1 0l9.789 9.789c3.088 3.061 3.088 8.062 0 11.12l-27.8 27.8c-3.088 3.066-8.062 3.066-11.1 0l-9.8-9.8c-3.088-3.06-3.088-8.035 0-11.1l27.8-27.82z"
      />
      <path
        d="m343.2 453.8c3.088-3.061 8.058-3.061 11.15 0l9.735 9.794c3.088 3.061 3.088 8.036 0.0538 11.12l-27.85 27.8c-3.034 3.066-8.009 3.066-11.1 0l-9.789-9.788c-3.034-3.061-3.088-8.036 0-11.1l27.8-27.82z"
      />
    </g>
    <path
      d="m332 487.6c7.538 7.569 7.538 19.82 0 27.39-7.538 7.536-19.79 7.536-27.38-0.0275-7.538-7.536-7.538-19.79 0-27.32 7.59-7.564 19.84-7.564 27.38-0.0263"
      fill="#e89ff4"
    />
  </svg>
`;
