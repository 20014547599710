import { objectID } from '@ounce/onc';
import { openDB } from 'idb';
import { appVersion } from '~/version.js';
import { clone } from './clone.js';

const makeKey = (userref, taskId) => `${userref}-${taskId}`;

// note: this version should be incremented if stores are added/removed from the database.
const databaseVersion_ = 1;
const stores_ = [{ database: 'results', keyPath: 'taskKey' }];

function createObjectStores(database, stores) {
  for (const store of stores) {
    const storeName = store.database ?? store;

    if (!database.objectStoreNames.contains(storeName)) {
      const options = {};
      if (store.keyPath) {
        options.keyPath = store.keyPath;
      }

      database.createObjectStore(storeName, options);
    }
  }
}

const databasePromise_ = storeName =>
  openDB(storeName, databaseVersion_, {
    upgrade(database) {
      createObjectStores(database, stores_);
    },
    blocked() {
      console.log('Database blocked: Please close all other tabs with this site open.');
    },
    blocking() {
      console.log('db version change detected Jim...');
    },
  });

/**
 * ResultsStore manages task results
 */
export class ResultsStore {
  constructor(dataStore, storeName = 'results') {
    this.dirty = false;
    // this.havePutCheckpoint = false;
    this.currentResults = undefined;
    this.authToken = undefined;
    this.dbPromise = databasePromise_(storeName);

    this.dataStore = dataStore;
  }

  get sessionId() {
    return this.currentResults?.sessionId;
  }

  setDirty = nv => (this.dirty = nv);

  putResults = async payload => {
    const response = await this.dbPromise;
    return response.put('results', clone(payload));
  };

  setState = async (newValue, options) => {
    this.dirty = true;

    const currentResults = this.currentResults;

    if (typeof newValue == 'string') {
      currentResults.state = newValue;
      currentResults.disposition = newValue;
    } else {
      const { state: taskState, disposition } = newValue;
      taskState && (currentResults.state = taskState);
      currentResults.disposition = disposition ?? taskState;
    }

    if (!options?.noSave) {
      return this.putResults(currentResults);
    }
  };

  async completeAndSave(state, disposition = state) {
    await this.pushItem({ end: new Date() });
    await this.saveResults({ state: { state, disposition } });
  }

  getState = () => {
    return this.currentResults?.state ?? 'unknown';
  };

  doPushItems = items => {
    if (Array.isArray(items)) {
      for (const item of items) {
        this.pushItem(item);
      }
    } else {
      this.pushItem(items);
    }
  };

  async setupResults(user, options, items) {
    const { sessionInfo = {}, task, projectId, sui } = options;

    const { _id: taskId, taskId: taskTypeId, name: taskName = taskTypeId } = task;

    const schemaId = task.schemaId ?? '';
    const taskSchemaType = schemaId.replace(/-schema$/, '');

    sessionInfo.appVersion = appVersion;
    sessionInfo.userAgent = navigator.userAgent;

    const { calendar, timeZone } = Intl.DateTimeFormat().resolvedOptions();

    sessionInfo.timeZone = timeZone;
    sessionInfo.calendar = calendar;
    // sessionInfo.directConnection = $store.directConnection.value ?? { status: 'unknown' };

    const { authToken, userref } = user;

    const sessionOptions = {
      state: 'idle',
      taskId,
      taskTypeId,
      taskName,
      taskSchemaType,
      projectId,
      userref,
      sessionInfo,
      sui,
    };

    this.createResults(sessionOptions, authToken);

    items && this.doPushItems(items);
  }

  async saveResults(options) {
    const { unmounting = false, state } = options ?? {};

    state && this.setState(state);

    if (this.dirty) {
      if (unmounting) {
        this.setState('terminated', { noSave: true });
      }

      // do not destructure earlier in case changing state

      const { taskKey, ...value } = this.currentResults;
      await this.dataStore.save({
        token: this.authToken,
        value,
      });

      this.dirty = false;

      // any results are now in a queue to the server so dont double process on init app
      (value.state == 'completed' || value.state == 'terminated') && (await this.clearResults());
    }
  }

  pushItem = async item => {
    const results = this.currentResults;

    results.items.push(item);
    this.dirty = true;
    return this.putResults(results);
  };

  setSessionInfo = (key, value) => {
    const results = this.currentResults;
    const sessionInfo = { ...results.sessionInfo, [key]: value };

    results.sessionInfo = sessionInfo;

    this.dirty = true;
    return this.putResults(results);
  };

  createResults = async (
    { userref, sui, sessionInfo, projectId, taskName, taskId, taskTypeId, taskSchemaType, state: taskState },
    authToken,
  ) => {
    const session = {
      userref,
      sui,
      taskTypeId,
      taskId,
      taskName,
      taskSchemaType,
      taskKey: makeKey(userref, taskId),
      projectId,
      state: taskState,
      disposition: '',
      created: new Date(),
      sessionId: objectID().toString(),
      sessionInfo,
      items: [],
    };

    this.currentResults = session;
    this.authToken = authToken;

    await this.putResults(session);
    return session;
  };

  getAllResults = async () => {
    const allResults = [];
    const response = await this.dbPromise;
    let cursor = await response.transaction('results').store.openCursor();
    while (cursor) {
      allResults.push(cursor.value);
      cursor = await cursor.continue();
    }

    return allResults.length > 0 ? allResults : undefined;
  };

  deleteResults = async key => {
    this.dirty = false;
    const response = await this.dbPromise;
    await response.delete('results', key);
  };

  clearResults = async () => {
    const results = this.currentResults;
    const { taskKey } = results;
    this.dirty = false;
    const response = await this.dbPromise;

    await response.delete('results', taskKey);
    await response.delete('results', `${taskKey}-state`);
    this.authToken = undefined;
    this.currentResults = undefined;
  };
}
