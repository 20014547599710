import { api } from '@state/api.js';
import { jwtDecode } from 'jwt-decode';

export const Auth = {
  async verifyAccount(host, { code, password }) {
    const { user } = /** @type {{user: object}}*/ (
      await api.post(`/api/verify-account`, { json: { code, password } }).json()
    );

    if (user) {
      if (!user.name) {
        user.name = 'n/a';
      }
      const {
        payload: { userref, ...roles },
        ...info
      } = user;
      info.userref = userref;
      info.tokenData = getPayload(user.accessToken);
      info.roles = roles;

      if (host) {
        host.dispatchEvent(new CustomEvent('user-update', { detail: info, bubbles: true, composed: true }));
      }

      return info;
    }
  },

  async tryLoginWithCredential(host, credential) {
    const { user } = /** @type {{user: object}}*/ (
      await api
        .post(`/credential`, {
          json: { credential },
        })
        .json()
    );

    if (user) {
      if (!user.name) {
        user.name = 'n/a';
      }
      const {
        payload: { userref, ...roles },
        ...info
      } = user;
      info.userref = userref;
      info.tokenData = getPayload(user.accessToken);
      info.roles = roles;

      if (host) {
        host.dispatchEvent(new CustomEvent('user-update', { bubbles: true, composed: true, detail: info }));
      }

      return info;
    }
  },

  // Logs in the current user.
  async logIn(host, { username, password }) {
    // await this.logOut(); // todo: fixme
    const { user } = /** @type {{user: object}}*/ (
      await api
        .post('/token', {
          json: {
            username,
            password,
          },
        })
        .json()
    );

    if (user) {
      if (!user.name) {
        user.name = 'n/a';
      }
      const {
        payload: { userref, ...roles },
        ...info
      } = user;
      info.userref = userref;
      info.tokenData = getPayload(user.accessToken);
      info.roles = roles;

      if (host) {
        host.dispatchEvent(new CustomEvent('user-update', { bubbles: true, composed: true, detail: info }));
      }
      return info;
    }
  },

  async loginWithCode({ password, confirm, code }) {
    const { user } = /** @type {{user: object}}*/ (
      await api
        .put(`/api/reset`, {
          json: { password, confirm, code },
        })
        .json()
    );

    if (user) {
      if (!user.name) {
        user.name = 'n/a';
      }
      const {
        payload: { userref, ...roles },
        ...info
      } = user;
      info.userref = userref;
      info.tokenData = getPayload(user.accessToken);
      info.roles = roles;

      return info;
    }
  },

  // Logs out the current user.
  async logOut(currentUser) {
    const options = {};

    const accessToken = currentUser?.accessToken;

    if (accessToken) {
      options.headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      await api.post(`/logout`, options);
    }
  },

  async checkResetCode({ code }) {
    await api.post(`/api/check-reset-code`, { json: { code } });
  },

  async sendVerifyAccount({ subject, resend }) {
    await api.post(`/api/send-verify-account?lang=${navigator.language}`, { json: { subject, resend } });
  },

  async checkCode({ code }) {
    await api.post(`/api/check-code`, { json: { code } });
  },

  // Check account status.
  async accountStatus(subject) {
    const response = /** @type {{status: string}} */ (
      await api
        .post('/api/account/status', {
          json: {
            subject,
          },
        })
        .json()
    );

    return response;
  },
};

// ===
// Private functions
// ===

function getPayload(token) {
  if (token) {
    return jwtDecode(token);
  }
}
