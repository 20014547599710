const clone = o =>
  typeof o === 'object' && o !== null // only clone objects
    ? Array.isArray(o) // if cloning an array
      ? o.map(element => clone(element)) // clone each of its elements
      : o instanceof Date
      ? o
      : cloneAll(o)
    : o;

export { clone };

function cloneAll(o) {
  const ca = {};

  for (const key of Object.keys(o)) {
    ca[key] = clone(o[key]);
  }
  return ca;
}
