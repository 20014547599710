/**
 *
 * @typedef {import('lit').ReactiveControllerHost} ReactiveControllerHost
 * @typedef {import('lit').ReactiveElement} ReactiveElement
 * @typedef {import('@state/project/profile.js').Profile} Profile
 * @typedef {import('@tasks/farm-game-settings.js').FarmGameSettings}  FarmGameSettings
 * @typedef {import('@state/project/project.js').Project} Project
 */

import { Sound } from '@audio/sound';
import { ContextConsumer } from '@lit/context';
import { profileContext, projectContext, resultsContext, storeContext, userContext } from '~/app/contexts';
import { SoundController } from './sound-controller';

export class TaskControllerBase {
  host;

  /**
   * @type {Profile}
   */
  profile;

  taskProfile;

  /**
   * @param {ReactiveControllerHost & ReactiveElement} host
   * @param {String} taskId
   */
  constructor(host, taskId) {
    (this.host = host).addController(this);

    this.taskId = taskId;

    this.soundKeys = new Set();

    /** @type {Project} */
    this.project;

    new ContextConsumer(host, { context: projectContext, callback: value => (this.project = value) });

    new ContextConsumer(host, {
      context: storeContext,
      callback: ({ dataStore }) => (this.dataStore = dataStore),
    });

    new ContextConsumer(host, {
      context: resultsContext,
      callback: value => (this.results = value),
    });
    new ContextConsumer(host, { context: userContext, callback: value => (this.user = value) });
    new ContextConsumer(host, {
      context: profileContext,
      callback: value => (this.profile = value),
    });

    this.taskProfile = this.profile.tasks.get(taskId);

    this.abortController = new AbortController();

    this.soundController = new SoundController(host);
  }

  /**
   * @returns {{showTrailer: Boolean,  showLayout: Boolean, debugRewards: Boolean}}
   */
  get taskSettings() {
    throw new Error('Override this method in the sub class');
  }

  get projectId() {
    return this.project.projectId;
  }

  get gameName() {
    return '';
  }

  get showLayout() {
    return this.taskSettings.showLayout;
  }

  get showTrailer() {
    return this.taskSettings.showTrailer;
  }

  get debugRewards() {
    return this.taskSettings.debugRewards;
  }

  get aborted() {
    return this.abortController.signal.aborted;
  }

  saveTaskRecord(record) {
    const value = {
      operation: 'profile-save',
      tasks: { [this.taskId]: record },
      userref: this.user.userRef,
      projectId: this.projectId,
    };

    const token = this.user.authToken;
    this.dataStore.save({ token, value });
  }

  saveTaskProfile() {
    const record = this.taskProfile.asRecord();

    this.saveTaskRecord(record);
  }

  /**
   * @param {{ start: Date; stimuli?: {}}[]} items
   * @param {Object?} [sessionInfo]
   */
  setupResults(items, sessionInfo) {
    const options = {
      task: this.taskSettings,
      projectId: this.projectId,
      sui: this.profile.pui,
      sessionInfo,
    };

    return this.results.setupResults(this.user, options, items);
  }

  /**
   * @param {Object} item
   */
  pushItem(item) {
    return this.results.pushItem(item);
  }

  async taskCompleted(state = 'completed') {
    const disposition = this.abortController.signal.aborted ? 'terminated' : 'completed';

    await this.results.completeAndSave(state, disposition);

    this.taskProfile.count += 1;
    this.taskProfile.state = state;

    this.saveTaskProfile();
  }

  taskStarted(state = 'started') {
    this.results.setState('running');
    this.taskProfile.runs += 1;
    this.taskProfile.state = state;

    this.saveTaskProfile();
  }

  /**
   * @param {{ [s: string]: any; } } options
   */
  async createSounds(options, base = '/media/sounds/') {
    for (const [key, value] of Object.entries(options)) {
      if (!value) {
        continue;
      }
      if (!this.soundKeys.has(key)) {
        this.soundKeys.add(key);

        await this.soundController.createSound(key, [`${base}${value}`]);
      }
    }
  }

  /**
   * @param {string} key
   * @param {string | string[]} path
   * @param {Object} options
   */
  async createSound(key, path, options) {
    if (!this.soundKeys.has(key)) {
      this.soundKeys.add(key);
      return this.soundController.createSound(key, path, options);
    }
  }

  /**
   * @param {Sound|string} soundOrKey
   */
  playSound(soundOrKey, options = {}) {
    return this.soundController.playSound(soundOrKey, options);
  }

  /**
   * @param {Promise<any>} promise
   * @param {number} [when]
   */
  stop(promise, when) {
    Sound.stop(promise, when);
  }

  listAsTargets(items = '') {
    const targets = items.split(',').map(item => item.trim());
    return targets;
  }

  hostConnected() {}

  hostDisconnected() {
    this.abortController.abort('disconnected');
  }
}
