import { ContextConsumer } from '@lit/context';
import { Task } from '@lit/task';
import { SoundController } from '@tasks/sound-controller';
import jsonata from 'jsonata';
import { LitElement, css, html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { styleMap } from 'lit/directives/style-map.js';
import { when } from 'lit/directives/when.js';
import { Auth } from '~/app/auth';
import { profileContext, projectContext, routerContext, userContext } from '~/app/contexts';
import { bg } from '~/graphics/bg';
import { taskInsGraphics } from '~/graphics/task-ins-graphics';
import { showErrors } from '~/use-view-helper';
import { productName } from '~/version';
import styles from './home.css';

const schemaTaskMap_ = {};

const taskKind = ({ schemaId }) => {
  const [shortName] = schemaId.split('-');
  const mappedName = schemaTaskMap_[shortName] ?? shortName;

  return mappedName;
};

let _playing;

const taskNames = ['motor', 'chicken', 'sheep', 'memory'];

export class Home extends LitElement {
  static properties = { playing: { state: true }, sound: { state: true }, project: { state: true } };

  soundController = new SoundController(this);

  constructor() {
    super();

    this.tasks = [];

    if (_playing === undefined) {
      _playing = window.location.hostname !== 'localhost';
    }

    this.playing = false;
    this.soundActive = false;

    new ContextConsumer(this, { context: projectContext, callback: value => (this.project = value), subscribe: true });
    new ContextConsumer(this, { context: routerContext, callback: value => (this.router = value) });
    new ContextConsumer(this, { context: userContext, subscribe: true, callback: nv => (this.currentUser = nv) });
    new ContextConsumer(this, { context: profileContext, callback: value => (this.profile = value), subscribe: true });

    this.sound = undefined;

    this.getSounds = new Task(this, {
      task: async ([project]) => {
        try {
          await this.makeSounds();

          this.tasks = project?.tasks ?? [];
          this.tasksByName = Object.fromEntries(this.tasks.map(task => [task.schemaId.slice(0, -7), task]));

          this.playing = this.tasks?.length > 0 ? _playing : false;

          this.flow = await this.getFlow_(this.tasks);
        } catch (error) {
          showErrors(error);
        }
      },
      args: () => [this.project],
    });
  }

  async playSounds() {
    if (!this.sound) {
      return;
    }

    if (this.playing) {
      if (!this.soundActive) {
        this.audioPromise = this.soundController.playSound(this.sound, { loop: true });
        this.soundActive = true;

        this.audioPromise.catch(error => console.log(error));
      }
      return;
    }

    this.soundController.stop(this.audioPromise);
    this.soundActive = false;
  }

  /**
   * @param {any[]} tasks
   */
  async getFlow_(tasks) {
    const nameReferenceMap = {};

    const flow = {};

    if (this.profile) {
      const taskProfiles = Object.fromEntries(this.profile.tasks.entries());

      for (const {
        _id,
        model: { name },
      } of tasks) {
        nameReferenceMap[name] = _id;
      }

      for (const task of tasks) {
        const visible = (await evaluateCondition('visible', task, taskProfiles, nameReferenceMap)) ?? true;
        const taskProfile = this.profile.tasks.get(task._id);

        const tpState = taskProfile?.state;

        const enabled =
          (await evaluateCondition('enabled', task, taskProfiles, nameReferenceMap)) ?? tpState != 'completed';

        flow[task._id] = { visible, enabled };
      }
    }

    return flow;
  }

  async makeSounds() {
    if (!this.sound) {
      this.sound = await this.soundController.createSound('background', `/media/sounds/HomeScreen.m4a`, false);
      this.sound.volume = 0.2;
    }
  }

  /**
   * @param {Map<string,any>} changed
   */
  willUpdate(changed) {
    if (changed.has('playing') || changed.has('sound')) {
      this.playSounds();
    }
  }

  render() {
    return html`
      <onc-screen-svg>${bg}</onc-screen-svg>
      <div class="wrapper">
        <onc-navbar .navTitle=${productName} home-page></onc-navbar>
        ${this.getSounds.render({
          pending: () => html` <div>Loading...</div> `,
          complete: () =>
            html`${when(
              this.currentUser.loggedIn,
              () => this.renderTaskView(),
              () => this.renderLoginView(),
            )}`,
        })}
      </div>
    `;
  }

  renderLoginView() {
    return html`
      <section class="cs-info cs-options">
        <div class="cs-info-signin">
          <div>Please sign in with one of these options:</div>
          <div class="cs-info-signin-action">
            <md-outlined-button @click=${this.toSignIn} class="onc-button"
              >Sign in with email/password</md-outlined-button
            >

            <onc-google-identity
              clientId="892944390283-fj2sg0s9u71c76nq5e57dtbnqkssf03i.apps.googleusercontent.com"
              @credential=${this.onCredential}
            ></onc-google-identity>

            <onc-facebook-identity
              clientId="3531862970469720"
              appearance="list"
              @credential=${this.onCredential}
            ></onc-facebook-identity>
          </div>
        </div>
      </section>
    `;
  }

  renderTaskView() {
    const play = html`
      <md-outlined-button class="sound-button" @click=${this.onPlay}
        >${this.playing ? 'Stop' : 'Play'}
        <md-icon class="sound-button-icon" slot="icon">volume_down</md-icon>
      </md-outlined-button>
    `;
    return html`
      <div class="tasks">${this.renderTasks()}</div>
      <div class="sound-action">${play}</div>
    `;
  }

  toSignIn() {
    this.router.goto('/signin', { history: true });
  }

  async onCredential({ detail: { credential } }) {
    await Auth.tryLoginWithCredential(this, credential);
  }

  renderTasks() {
    const games = [];
    let index = 0;

    for (const name of taskNames) {
      const task = this.tasksByName[name] ?? {};
      const { visible, enabled } = this.flow[task?._id] ?? {};

      if (visible) {
        const classes = { 'game-button': 1, 'game-button-disabled': !enabled, [`game-${index++}`]: 1 };
        const styles = { '--adt-dis-display': enabled ? 'none' : 'block' };

        const game = html`
          <onc-svg-icon
            class=${classMap(classes)}
            style=${styleMap(styles)}
            @click=${() => enabled && this.onSelect(task)}
            >${taskInsGraphics[`${taskKind(task)}-game`]}</onc-svg-icon
          >
        `;
        games.push(game);
      } else {
        const classes = { 'game-button': 1, [`game-${index++}`]: 1 };
        games.push(html` <onc-svg-icon class=${classMap(classes)}>${taskInsGraphics[`no-game`]}</onc-svg-icon> `);
      }
    }

    return games;
  }

  onSelect({ _id, schemaId }) {
    const [shortName] = schemaId.split('-');
    const mappedName = schemaTaskMap_[shortName] ?? shortName;
    this.router.goto(`/task/${mappedName}/${_id}`, { history: true });
  }

  onPlay = async () => {
    this.playing = !this.playing;
    _playing = this.playing;

    return;
  };
}
const OncHome = class OncHome extends Home {
  static styles = [
    styles,
    css`
      .cs-info {
        margin-top: 2em;
        background-color: white;
        padding: 0.5em;
        border-radius: 10px;
        color: #757575;
        font-size: 20px;
      }

      .cs-info h1 {
        color: #000;

        font:
          400 1.5rem / 2rem Manrope,
          Roboto;
      }

      .cs-info-signin {
        color: #757575;
        font-size: 20px;

        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 20px;
      }
      .cs-info-signin-action {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        row-gap: 10px;
      }

      .cs-options {
        display: flex;
        justify-items: center;
        margin: auto;
      }
    `,
  ];
};
customElements.define('onc-home', OncHome);

// ===
// Private functions
// ===

const selector_ = /\$(?!\w)(?:{([^\]]+?)})?/g;

function parseExpression({ expression, nameRefMap, curTask }) {
  const matches = [];
  const temporary = expression.replaceAll(selector_, (match, p1) => {
    if (!p1) {
      p1 = curTask.settings.ref ?? curTask.settings.name;
    }

    const key = nameRefMap[p1] ?? p1;
    matches.push({ key, p1 });
    return `"${key}"`;
  });

  return temporary;
}

async function evaluateExpression({ ctx, expression }) {
  const expr = jsonata(expression);
  return expr.evaluate(ctx);
}

async function evaluateCondition(condition, task, ti, nameReferenceMap) {
  const expr = task.model.flow?.[condition];

  if (!expr) {
    return;
  }

  const parsed = parseExpression({
    expression: expr,
    nameRefMap: nameReferenceMap,
    curTask: task,
  });

  return evaluateExpression({ ctx: ti, expression: parsed });
}

// const MyMixin = (/** @type {typeof LitElement} */ superClass) =>
//   class extends superClass {
//     consume(context, name, subscribe) {
//       new ContextConsumer(this, {
//         context,
//         callback: value => {
//           console.log(value);
//           this[name] = value;
//         },
//         subscribe,
//       });
//     }
//   };
