import { ContextConsumer } from '@lit/context';
import { msg } from '@lit/localize';
import { shuffle } from '@ounce/onc';
import { LitElement, html, nothing } from 'lit';
import { createRef, ref } from 'lit/directives/ref.js';
import { when } from 'lit/directives/when.js';
import seedrandom from 'seedrandom';
import '~/vma.js';
import { Auth } from './auth.js';
import { loggerContext, storeContext, userContext } from './contexts.js';
import { ProfileController } from './profile-controller.js';
import { ProjectController } from './project-controller.js';
import { RouterController } from './router-contoller.js';

export class App extends LitElement {
  static properties = {
    initialised: { state: true },
    ready: { state: true },
    currentUser: { state: true },
  };

  constructor() {
    super();

    this.logger = undefined;
    this.currentUser = undefined;

    consume(this, loggerContext, value => (this.logger = value));
    consume(this, userContext, value => (this.currentUser = value), true);
    consume(this, storeContext, ({ store }) => (this.store = store));

    this.profileController = new ProfileController(this);
    this.projectController = new ProjectController(this, value => this.profileController.setCurrentProfile(value));

    const routes = [
      { path: '/', render: () => html`<onc-home></onc-home>` },
      {
        path: '/task/chicken/:taskId',
        render: ({ taskId }) => html`<onc-chicken-game .taskId=${taskId} @next=${this.next}></onc-chicken-game>`,
      },
      {
        path: '/task/memory/:taskId',
        render: ({ taskId }) => html`<onc-memory-game .taskId=${taskId} @next=${this.next}></onc-memory-game>`,
      },
      {
        path: '/task/sheep/:taskId',
        render: ({ taskId }) => html`<onc-sheep-game .taskId=${taskId} @next=${this.next}></onc-sheep-game>`,
      },
      {
        path: '/task/motor/:taskId',
        render: ({ taskId }) => html`<onc-motor-game .taskId=${taskId} @next=${this.next}></onc-motor-game>`,
      },
      {
        path: '/help',
        render: () => html`<onc-help></onc-help>`,
        // enter: async () => {
        //   await import('../general/help.js');
        // },
      },
      {
        path: '/admin/*',
        render: () => html`<onc-admin-root></onc-admin-root>`,
        enter: async () => {
          await import('../admin/admin-vma.js');
          return true;
        },
      },
      {
        path: '/logout',
        render: () => nothing,
        enter: async () => {
          await Auth.logOut(this.currentUser);

          this.dispatchEvent(new CustomEvent('user-update', { bubbles: true, composed: true, detail: {} }));

          setTimeout(() => {
            window.history.replaceState({}, '', '/');
            this.routerController.goto('/');
          }, 30);
          return true;
        },
      },
      {
        path: '/details',
        render: () => html`<onc-my-details></onc-my-details>`,
        enter: async () => {
          await import('~/login/my-details.js');
          return true;
        },
      },

      {
        path: '/details/cp',
        render: () => html`<onc-change-password></onc-change-password>`,
        enter: async () => {
          await import('~/login/change-password.js');
          return true;
        },
      },

      { path: '/signin', render: () => html`<onc-sign-in></onc-sign-in>` },
      { path: '/verify/:code?', render: ({ code = '' }) => html`<onc-sign-in .code=${code} verify></onc-sign-in>` },
      { path: '/forgot', render: () => html`<onc-forgot-password></onc-forgot-password>` },
    ];

    this.routerController = new RouterController(this, routes);

    // @ts-ignore
    window.__router = this.routerController;

    this.appReference = createRef();
    this.snackReference = createRef();

    const rng = seedrandom();
    shuffle.setRng(rng);
  }

  next() {
    this.routerController.goto(`/`, { history: true });
  }

  async firstUpdated() {
    this.logger?.log('app first updated');
  }

  connectedCallback() {
    super.connectedCallback();

    this.store.setSnackbarHandler(this.onSnack);

    document.body.addEventListener(
      'sw-waiting',

      /** @param {CustomEvent} event */
      ({ detail: { actionHandler } }) => {
        this.onSnack({
          message: msg('Press Update to get a new version '),
          actionText: msg('Update'),
          dismissAction: true,
          leading: true,
          actionHandler,
        });
      },
      { passive: true },
    );

    this.ready = true;
  }

  render() {
    return html`
      ${this.renderContent()}

      <onc-snackbar ${ref(this.snackReference)}></onc-snackbar>
    `;
  }

  renderContent() {
    return this.ready
      ? html` ${when(
          this.initialised,
          () => this.routerController.outlet(),
          () => html`<onc-init-app @init-app=${this.onInitApp}></onc-init-app> `,
        )}`
      : nothing;
  }

  // @ts-ignore
  onSnack = snack => this.snackReference.value.show(snack);

  // we wait until have handled any pending results or other initialisation
  onInitApp = ({ detail: { type } }) => {
    switch (type) {
      case 'initialised': {
        this.initialised = true;

        break;
      }
    }
  };
}

const OncApp = class OncApp extends App {};

customElements.define('onc-app', OncApp);

// ===
// Private functions
// ===

function consume(host, context, callback, subscribe) {
  new ContextConsumer(host, {
    context,
    callback,
    subscribe,
  });
}
