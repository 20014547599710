import { html } from 'lit';

export const pig = html`
  <svg
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    viewBox="0 0 560 620"
  >
    <path
      d="m449.2 213.4s26.29 107.5-22.39 121.8c-48.58 14.36-75.45 13.45-75.45 13.45s-1.421-91.88 45.08-112.2l52.8-22.95"
      fill="#f29e9f"
    />
    <path
      d="m434.2 237.1s17.85 78.05-14.45 83.52c-35.5 6.028-46.78 3.83-46.78 3.83s-2.849-59.32 27.18-72.46l34.05-14.89"
      fill="#d76c79"
    />
    <path
      d="m107.8 213.4s-26.29 107.5 22.39 121.8c48.58 14.36 75.45 13.45 75.45 13.45s1.421-91.88-45.08-112.2l-52.76-22.95"
      fill="#f29e9f"
    />
    <path
      d="m122.8 237.1s-17.85 78.05 14.45 83.52c35.5 6.028 46.78 3.83 46.78 3.83s2.849-59.32-27.18-72.46l-34.05-14.89"
      fill="#d76c79"
    />
    <path d="m258.7 600.9h-43.48v-59.95h43.48v59.95z" fill="#d87c80" />
    <path d="m341.9 600.9h-43.48v-59.95h43.48v59.95z" fill="#f29e9f" />
    <path
      d="m236.5 608.4c1.839 0 3.335 1.494 3.335 3.332v8.322h34.96c0-20.9-16.95-37.82-37.84-37.82s-37.85 16.94-37.85 37.82h34.05v-8.322c0.006-1.839 1.492-3.332 3.338-3.332"
      fill="#faccb7"
    />
    <path
      d="m319.6 608.4c1.835 0 3.335 1.494 3.335 3.332v8.322h34.96c0-20.9-16.94-37.82-37.85-37.82-20.88 0-37.82 16.94-37.82 37.82h34.05v-8.322c-0.004-1.839 1.48-3.332 3.325-3.332"
      fill="#faccb7"
    />
    <g fill="#d87c80">
      <path
        d="m166.5 460.9c-0.2875 3.282-0.4925 6.595-0.4925 9.96 0 62.9 50.42 113.9 112.7 113.9v-55.18c-50.26 0-93.4-28.29-112.2-68.69"
      />
      <path d="m278.6 529.6v-172.6c-58.89 0-107.2 45.7-112.2 104 18.78 40.39 61.91 68.68 112.2 68.68" />
      <path d="m278.6 357.2v172.6c50.28 0 93.4-28.29 112.2-68.69-5-58.3-53.3-103.9-112.2-103.9" />
    </g>
    <path
      d="m391.4 470.9c0-3.365 0-14.76-4.772-32.68-15.98 64.58-57.64 91.4-107.9 91.4v55.18c62.24 0 112.7-51 112.7-113.9"
      fill="#f29e9f"
    />
    <path
      d="m391.7 487.2c10.56 8.91 28.08 10.65 40.52 4.884 7.355-3.391 25.29-18.36 9.102-22.99-8.772-2.508-17.12 3.276-16.16 12.35 1.36 12.76 14.51 19.06 25.92 18.48 4.069-0.2088 13.12-6.71 4.936-6.289-9.972 0.5075-19.88-8.154-18.66-18.71 0.0812-0.3688 0.1888-0.7375 0.29-1.112-5.7-0.025-6.338 1.319-1.925 4.031 1.45 3.432-5.7 8.261-7.65 9.535-7 4.598-19.16 0.1112-24.95-4.769-3.052-2.57-14.34 2.135-11.42 4.59"
      fill="#f29e9f"
    />
    <path
      d="m180.7 410.9c1.644 8.829 5.66 26.39 5.66 26.39 7.596 41.8 44.4 74.09 92.12 74.09v-264.6c-63.04 0-108.4 43.78-108.4 97.81 0.001 25.61 6.164 42.04 10.66 66.31"
      fill="#d87c80"
    />
    <path
      d="m376.2 410.9c-1.636 8.829-5.64 26.39-5.64 26.39-7.602 41.8-44.4 74.09-92.15 74.09v-264.6c63.05 0 108.4 43.8 108.4 97.84-0.002 25.59-6.158 42.01-10.66 66.29"
      fill="#f29e9f"
    />
    <path
      d="m278.5 477.2c-28.99 16.82-52.1-6.354-52.1-25.62s22.89-34.36 52.1-34.36c29.22 0 52.11 15.1 52.11 34.36 0 19.28-26.12 42.45-52.11 25.62"
      fill="#d76c79"
    />
    <g fill="#3c3c3b">
      <path
        d="m265.9 449.6c0 6.005-4.868 10.88-10.88 10.88s-10.88-4.874-10.88-10.88c0-6.005 4.868-10.87 10.88-10.87 6.015 0 10.88 4.868 10.88 10.87"
      />
      <path
        d="m314.1 449.6c0 6.005-4.868 10.88-10.88 10.88-6.005 0-10.88-4.874-10.88-10.88 0-6.005 4.874-10.87 10.88-10.87 6.004 0 10.87 4.868 10.87 10.87"
      />
      <path
        d="m221.9 348.4c0 6.625-5.36 11.99-12 11.99-6.618 0-11.98-5.369-11.98-11.99 0-6.618 5.36-11.99 11.98-11.99 6.638 0 12 5.374 12 11.99"
      />
      <path
        d="m359.2 348.4c0 6.625-5.36 11.99-11.98 11.99-6.638 0-11.99-5.369-11.99-11.99 0-6.618 5.354-11.99 11.99-11.99 6.624 0 11.98 5.374 11.98 11.99"
      />
    </g>
    <path
      d="m218.1 344.6c0 2.498-2.021 4.522-4.525 4.522-2.498 0-4.525-2.025-4.525-4.522 0-2.504 2.028-4.529 4.525-4.529 2.504 0 4.525 2.025 4.525 4.529"
      fill="#fff"
    />
    <path
      d="m356.2 344.6c0 2.498-2.035 4.522-4.531 4.522-2.498 0-4.532-2.025-4.532-4.522 0-2.504 2.035-4.529 4.532-4.529 2.496 0 4.531 2.025 4.531 4.529"
      fill="#fff"
    />
  </svg>
`;
