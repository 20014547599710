import {css} from "lit-element/lit-element.js";
export default css`.form-message {
  display: inline-block;
  margin: 8px 0;
  padding: 16px;
  position: relative;
  width: 100%;
  color: white;
}
.form-message__text {
  font-size: 0.875rem;
  line-height: 1.125rem;
  margin: 0;
  padding: 0;
}
.form-message:after, .form-message:before {
  border-bottom: 8px solid;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  content: "";
  display: block;
  left: 24px;
  margin-left: -4px;
  position: absolute;
  top: -6px;
  width: 0;
}
.form-message--error {
  background-color: #e4134f;
}
.form-message--error:after, .form-message--error:before {
  border-bottom-color: #e4134f;
}
.form-message--info {
  background-color: #fff;
  border: 2px solid #006def;
  color: #000;
}
.form-message--info:before {
  border-bottom-color: #006def;
  top: -8px;
}
.form-message--info:after {
  border-bottom-color: #fff;
  top: -5px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2hvbWUvcGhpbGlwL29uYy9GYXJtL2Zhcm0vY2xpZW50L3NpZ25pbiIsInNvdXJjZXMiOlsiZm9ybS1tZXNzYWdlLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTtFQUNBO0VBQ0E7RUFDQTs7QUFHRjtFQUVFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUdGO0VBQ0U7O0FBQ0E7RUFFRTs7QUFJSjtFQUNFO0VBQ0E7RUFDQTs7QUFFQTtFQUNFO0VBQ0E7O0FBRUY7RUFDRTtFQUNBIiwic291cmNlc0NvbnRlbnQiOlsiLmZvcm0tbWVzc2FnZSB7XG4gIGRpc3BsYXk6IGlubGluZS1ibG9jaztcbiAgbWFyZ2luOiA4cHggMDtcbiAgcGFkZGluZzogMTZweDtcbiAgcG9zaXRpb246IHJlbGF0aXZlO1xuICB3aWR0aDogMTAwJTtcbiAgY29sb3I6IHdoaXRlO1xuXG4gICZfX3RleHQge1xuICAgIGZvbnQtc2l6ZTogMC44NzVyZW07XG4gICAgbGluZS1oZWlnaHQ6IDEuMTI1cmVtO1xuICAgIG1hcmdpbjogMDtcbiAgICBwYWRkaW5nOiAwO1xuICB9XG5cbiAgJjphZnRlcixcbiAgJjpiZWZvcmUge1xuICAgIGJvcmRlci1ib3R0b206IDhweCBzb2xpZDtcbiAgICBib3JkZXItbGVmdDogOHB4IHNvbGlkIHRyYW5zcGFyZW50O1xuICAgIGJvcmRlci1yaWdodDogOHB4IHNvbGlkIHRyYW5zcGFyZW50O1xuICAgIGNvbnRlbnQ6ICcnO1xuICAgIGRpc3BsYXk6IGJsb2NrO1xuICAgIGxlZnQ6IDI0cHg7XG4gICAgbWFyZ2luLWxlZnQ6IC00cHg7XG4gICAgcG9zaXRpb246IGFic29sdXRlO1xuICAgIHRvcDogLTZweDtcbiAgICB3aWR0aDogMDtcbiAgfVxuXG4gICYtLWVycm9yIHtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiAjZTQxMzRmO1xuICAgICY6YWZ0ZXIsXG4gICAgJjpiZWZvcmUge1xuICAgICAgYm9yZGVyLWJvdHRvbS1jb2xvcjogI2U0MTM0ZjtcbiAgICB9XG4gIH1cblxuICAmLS1pbmZvIHtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiAjZmZmO1xuICAgIGJvcmRlcjogMnB4IHNvbGlkICMwMDZkZWY7XG4gICAgY29sb3I6ICMwMDA7XG5cbiAgICAmOmJlZm9yZSB7XG4gICAgICBib3JkZXItYm90dG9tLWNvbG9yOiAjMDA2ZGVmO1xuICAgICAgdG9wOiAtOHB4O1xuICAgIH1cbiAgICAmOmFmdGVyIHtcbiAgICAgIGJvcmRlci1ib3R0b20tY29sb3I6ICNmZmY7XG4gICAgICB0b3A6IC01cHg7XG4gICAgfVxuICB9XG59XG4iXX0= */`;
