import { html } from 'lit';

export const longFence2 = html`
  <svg
    version="1.1"
    viewBox="0 0 713.34 190.69"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path d="m713.11 190.69h-16.21v-82.64l8.005-7.536 8.208 7.537v82.64z" fill="#786d4f" />
    <path d="m704.88 100.55v90.07h8.1v-82.64l-8.1-7.437z" fill="#5f5339" />
    <path d="m610.41 186.19h-16.21v-82.64l8.005-7.536 8.208 7.538v82.64z" fill="#786d4f" />
    <path d="m602.41 96.176v90.07h8.1v-82.64l-8.1-7.438z" fill="#5f5339" />
    <path d="m507.91 177.39h-16.21v-82.64l8.005-7.536 8.208 7.538v82.64z" fill="#786d4f" />
    <path d="m499.81 87.426v90.07h8.1v-82.64l-8.1-7.438z" fill="#5f5339" />
    <path d="m405.41 164.19h-16.21v-82.64l8.005-7.536 8.208 7.538v82.64z" fill="#786d4f" />
    <path d="m397.31 74.176v90.07h8.101v-82.64l-8.101-7.438z" fill="#5f5339" />
    <path d="m302.91 143.39h-16.21v-82.64l8.005-7.536 8.208 7.538v82.64z" fill="#786d4f" />
    <path d="m294.81 53.426v90.07h8.1v-82.64l-8.1-7.438z" fill="#5f5339" />
    <path d="m200.41 124.69h-16.21v-82.64l8.005-7.536 8.208 7.538v82.64z" fill="#786d4f" />
    <path d="m192.31 34.676v90.07h8.1v-82.64l-8.1-7.438z" fill="#5f5339" />
    <path d="m97.824 102.69h-16.21v-82.64l8.005-7.536 8.208 7.538v82.64z" fill="#786d4f" />
    <path d="m89.824 12.676v90.07h8.101v-82.64l-8.101-7.438z" fill="#5f5339" />
    <path d="m686.81 190.69h-16.21v-82.64l8.005-7.536 8.208 7.537v82.64z" fill="#786d4f" />
    <path d="m678.67 100.55v90.07h8.1v-82.64l-8.1-7.437z" fill="#5f5339" />
    <path d="m584.81 184.89h-16.21v-82.64l8.005-7.536 8.208 7.538v82.64z" fill="#786d4f" />
    <path d="m576.81 94.926v90.07h8.1v-82.64l-8.1-7.438z" fill="#5f5339" />
    <path d="m482.31 174.89h-16.21v-82.64l8.005-7.536 8.208 7.538v82.64z" fill="#786d4f" />
    <path d="m474.21 84.926v90.07h8.101v-82.64l-8.101-7.438z" fill="#5f5339" />
    <path d="m379.81 160.39h-16.21v-82.64l8.005-7.536 8.208 7.538v82.64z" fill="#786d4f" />
    <path d="m371.71 70.426v90.07h8.1v-82.64l-8.1-7.438z" fill="#5f5339" />
    <path d="m277.31 138.39h-16.21v-82.64l8.005-7.536 8.208 7.538v82.64z" fill="#786d4f" />
    <path d="m269.21 48.426v90.07h8.1v-82.64l-8.1-7.438z" fill="#5f5339" />
    <path d="m174.81 119.69h-16.21v-82.64l8.005-7.536 8.208 7.538v82.64z" fill="#786d4f" />
    <path d="m166.71 29.676v90.07h8.1v-82.64l-8.1-7.438z" fill="#5f5339" />
    <path d="m72.194 98.786h-16.21v-82.64l8.004-7.536 8.209 7.538v82.64z" fill="#786d4f" />
    <path d="m64.074 8.676v90.07h8.1v-82.64l-8.1-7.438z" fill="#5f5339" />
    <path d="m661.81 190.69h-16.21v-82.64l8.005-7.536 8.208 7.537v82.64z" fill="#786d4f" />
    <path d="m653.71 100.55v90.07h8.1v-82.64l-8.1-7.437z" fill="#5f5339" />
    <path d="m559.21 182.39h-16.21v-82.64l8.004-7.536 8.209 7.538v82.64z" fill="#786d4f" />
    <path d="m551.21 92.426v90.07h8.1v-82.64l-8.1-7.438z" fill="#5f5339" />
    <path d="m456.71 172.39h-16.21v-82.64l8.004-7.536 8.209 7.538v82.64z" fill="#786d4f" />
    <path d="m448.61 82.426v90.07h8.1v-82.64l-8.1-7.438z" fill="#5f5339" />
    <path d="m354.21 155.39h-16.21v-82.64l8.005-7.536 8.208 7.538v82.64z" fill="#786d4f" />
    <path d="m346.11 65.426v90.07h8.1v-82.64l-8.1-7.438z" fill="#5f5339" />
    <path d="m251.71 134.69h-16.21v-82.64l8.005-7.536 8.208 7.538v82.64z" fill="#786d4f" />
    <path d="m243.61 44.676v90.07h8.1v-82.64l-8.1-7.438z" fill="#5f5339" />
    <path d="m149.11 114.69h-16.21v-82.64l8.005-7.536 8.208 7.538v82.64z" fill="#786d4f" />
    <path d="m141.11 24.676v90.07h8.1v-82.64l-8.1-7.438z" fill="#5f5339" />
    <path d="m46.574 93.926h-16.21v-82.64l8.005-7.536 8.208 7.538v82.64z" fill="#786d4f" />
    <path d="m38.444 3.923v90.08h8.1v-82.64l-8.1-7.438z" fill="#5f5339" />
    <path d="m636.21 190.69h-16.21v-82.64l8.004-7.536 8.209 7.537v82.64z" fill="#786d4f" />
    <path d="m628.11 100.55v90.07h8.1v-82.64l-8.1-7.437z" fill="#5f5339" />
    <path d="m533.61 181.19h-16.21v-82.64l8.004-7.536 8.209 7.538v82.64z" fill="#786d4f" />
    <path d="m525.61 91.176v90.07h8.1v-82.64l-8.1-7.438z" fill="#5f5339" />
    <path d="m431.11 169.89h-16.21v-82.64l8.005-7.536 8.208 7.538v82.64z" fill="#786d4f" />
    <path d="m422.91 79.926v90.07h8.1v-82.64l-8.1-7.438z" fill="#5f5339" />
    <path d="m328.61 149.19h-16.21v-82.64l8.005-7.536 8.208 7.538v82.64z" fill="#786d4f" />
    <path d="m320.41 59.176v90.07h8.1v-82.64l-8.1-7.438z" fill="#5f5339" />
    <path d="m226.11 129.69h-16.21v-82.64l8.005-7.536 8.208 7.538v82.64z" fill="#786d4f" />
    <path d="m217.91 39.676v90.07h8.1v-82.64l-8.1-7.438z" fill="#5f5339" />
    <path d="m123.41 109.69h-16.21v-82.64l8.005-7.536 8.208 7.538v82.64z" fill="#786d4f" />
    <path d="m115.41 19.676v90.07h8.1v-82.64l-8.1-7.438z" fill="#5f5339" />
    <path d="m20.944 90.176h-16.21v-82.64l8.005-7.536 8.208 7.537v82.64z" fill="#786d4f" />
    <path d="m12.824 0.173v90.08h8.1v-82.64l-8.1-7.437z" fill="#5f5339" />
    <path d="m4.6961 58.796v3.209l8.114 1.388v-3.209z" fill="#534832" />
    <path d="m12.824 60.176v3.209l8.086 1.384v-3.21l-8.086-1.382z" fill="#4f442e" />
    <path d="m30.444 63.296v3.209l8.061 1.504v-3.21l-8.061-1.502z" fill="#534832" />
    <path d="m38.444 64.796v3.209l8.1 1.51v-3.209z" fill="#4f442e" />
    <path d="m56.074 68.176v3.21l8.111 1.588v-3.21z" fill="#534832" />
    <path d="m64.074 69.796v3.21l8.1 1.585v-3.21z" fill="#4f442e" />
    <path d="m81.694 73.296v3.21l8.112 1.588v-3.21z" fill="#534832" />
    <path d="m89.824 74.926v3.209l8.091 1.584v-3.209z" fill="#4f442e" />
    <path d="m107.31 79.526v3.209l8.089 1.584v-3.21l-8.089-1.582z" fill="#534832" />
    <path d="m115.41 81.146v3.209l8.1 1.586v-3.21z" fill="#4f442e" />
    <path d="m132.91 84.846v3.209l8.095 1.585v-3.21z" fill="#534832" />
    <path d="m141.11 86.476v3.209l8.1 1.586v-3.21z" fill="#4f442e" />
    <path d="m158.61 90.676v3.209l8.09 1.584v-3.209z" fill="#534832" />
    <path d="m166.71 92.296v3.209l8.1 1.586v-3.21z" fill="#4f442e" />
    <path d="m184.21 95.796v3.209l8.085 1.582v-3.209z" fill="#534832" />
    <path d="m192.31 97.296v3.209l8.1 1.586v-3.21z" fill="#4f442e" />
    <path d="m209.81 100.69v3.21l8.112 1.588v-3.21z" fill="#534832" />
    <path d="m217.91 102.29v3.21l8.098 1.584v-3.209z" fill="#4f442e" />
    <path d="m235.51 105.69v3.209l8.074 1.519v-3.21z" fill="#534832" />
    <path d="m243.61 107.19v3.209l8.1 1.524v-3.21l-8.1-1.522z" fill="#4f442e" />
    <path d="m261.11 110.69v3.21l8.081 1.45v-3.209z" fill="#534832" />
    <path d="m269.21 112.19v3.209l8.1 1.455v-3.209z" fill="#4f442e" />
    <path d="m286.71 116.29v3.209l8.1 1.455v-3.21z" fill="#534832" />
    <path d="m294.81 117.79v3.209l8.1 1.455v-3.21z" fill="#4f442e" />
    <path d="m312.31 121.39v3.209l8.082 1.451v-3.209z" fill="#534832" />
    <path d="m320.41 122.89v3.209l8.1 1.455v-3.21z" fill="#4f442e" />
    <path d="m338.11 125.79v3.209l8.065 1.349v-3.209z" fill="#534832" />
    <path d="m346.11 127.19v3.209l8.1 1.355v-3.21z" fill="#4f442e" />
    <path d="m363.61 130.09v3.21l8.112 1.281v-3.21z" fill="#534832" />
    <path d="m371.71 131.29v3.21l8.068 1.274v-3.21z" fill="#4f442e" />
    <path d="m389.21 133.79v3.209l8.112 1.281v-3.209z" fill="#534832" />
    <path d="m397.31 135.19v3.209l8.06 1.274v-3.21l-8.06-1.272z" fill="#4f442e" />
    <path d="m414.91 138.79v3.209l8.098 1.154v-3.209z" fill="#534832" />
    <path d="m422.91 139.89v3.209l8.1 1.155v-3.209z" fill="#4f442e" />
    <path d="m440.61 142.49v3.209l8.068 1.056v-3.209z" fill="#534832" />
    <path d="m448.61 143.59v3.209l8.1 1.061v-3.209z" fill="#4f442e" />
    <path d="m466.21 146.49v3.209l8.075 0.9462v-3.21l-8.075-0.945z" fill="#534832" />
    <path d="m474.21 147.39v3.209l8.101 0.9488v-3.209z" fill="#4f442e" />
    <path
      d="m2.5 56.14c112.33 16.872 440.85 107.88 708.29 105.32"
      fill="none"
      stroke="#82775d"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="5"
    />
    <path d="m491.81 149.39v3.209l8.056 0.9438v-3.209z" fill="#534832" />
    <path d="m499.81 150.39v3.209l8.1 0.9488v-3.209z" fill="#4f442e" />
    <path d="m517.41 152.39v3.21l8.112 0.8238v-3.209l-8.112-0.825z" fill="#534832" />
    <path d="m525.61 153.19v3.209l8.062 0.82v-3.209z" fill="#4f442e" />
    <path d="m543.11 154.69v3.209l8.07 0.8212v-3.21l-8.07-0.82z" fill="#534832" />
    <path d="m551.21 155.59v3.209l8.1 0.8238v-3.209z" fill="#4f442e" />
    <path d="m568.71 157.39v3.21l8.078 0.6338v-3.209l-8.078-0.635z" fill="#534832" />
    <path d="m576.81 157.99v3.209l8.1 0.6362v-3.209z" fill="#4f442e" />
    <path d="m594.31 159.59v3.209l8.085 0.4488v-3.21l-8.085-0.4475z" fill="#534832" />
    <path d="m602.41 160.09v3.209l8.1 0.45v-3.21l-8.1-0.4488z" fill="#4f442e" />
    <path d="m620.05 161.59v3.21l8.091 0.3788v-3.209l-8.091-0.38z" fill="#534832" />
    <path d="m628.11 161.89v3.209l8.1 0.38v-3.209z" fill="#4f442e" />
    <path d="m645.61 163.09v3.21l8.099 0.2612v-3.21z" fill="#534832" />
    <path d="m653.71 163.29v3.21l8.1 0.2612v-3.21z" fill="#4f442e" />
    <path d="m670.64 163.79v3.21l8.112 0.0737v-3.21z" fill="#534832" />
    <path d="m678.74 163.99v3.21l8.084 0.0737v-3.21z" fill="#4f442e" />
    <path d="m705.02 164.09-8.112 0.02v3.209l8.112-0.02z" fill="#534832" />
    <path d="m713.11 164.09-8.1 0.02v3.209l8.1-0.02z" fill="#4f442e" />
    <g fill="#a1977d">
      <path
        d="m704.31 162.79c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m679.31 162.59c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m653.61 162.09c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m628.11 160.69c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m602.41 158.99c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m576.81 157.09c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m551.11 154.69c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m525.41 151.99c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m499.81 149.19c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m474.31 146.29c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m448.61 142.39c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m422.91 138.79c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m397.41 134.59c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m371.71 130.39c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m346.11 125.99c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m320.41 121.39c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m294.81 116.49c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m269.21 111.49c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m243.61 106.49c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m217.91 101.19c0.69 0 1.25-0.56 1.25-1.25 0-0.69-0.56-1.25-1.25-1.25s-1.25 0.56-1.25 1.25c0 0.69 0.56 1.25 1.25 1.25"
      />
      <path
        d="m192.21 96.046c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m166.61 90.846c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m140.91 85.126c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m115.41 79.776c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m89.694 74.126c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m63.944 68.546c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m38.324 63.526c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m12.824 58.796c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
    </g>
    <path d="m4.6961 29.546v3.209l8.114 1.388v-3.209z" fill="#534832" />
    <path d="m12.824 30.926v3.209l8.086 1.384v-3.21l-8.086-1.382z" fill="#4f442e" />
    <path d="m30.444 34.046v3.209l8.061 1.504v-3.21l-8.061-1.502z" fill="#534832" />
    <path d="m38.444 35.546v3.209l8.1 1.51v-3.209z" fill="#4f442e" />
    <path d="m56.074 39.196v3.21l8.111 1.586v-3.209l-8.111-1.588z" fill="#534832" />
    <path d="m64.074 40.826v3.209l8.1 1.586v-3.21z" fill="#4f442e" />
    <path d="m81.694 44.746v3.21l8.112 1.588v-3.21z" fill="#534832" />
    <path d="m89.824 46.246v3.209l8.091 1.584v-3.209z" fill="#4f442e" />
    <path d="m107.31 50.376v3.209l8.089 1.584v-3.21l-8.089-1.582z" fill="#534832" />
    <path d="m115.41 51.876v3.209l8.1 1.586v-3.21z" fill="#4f442e" />
    <path d="m133.01 55.296v3.21l8.095 1.584v-3.209z" fill="#534832" />
    <path d="m141.11 56.926v3.21l8.1 1.585v-3.21z" fill="#4f442e" />
    <path d="m158.61 60.926v3.209l8.09 1.584v-3.209z" fill="#534832" />
    <path d="m166.71 62.546v3.209l8.1 1.586v-3.21z" fill="#4f442e" />
    <path d="m184.21 66.426v3.209l8.085 1.582v-3.209z" fill="#534832" />
    <path d="m192.31 68.046v3.209l8.1 1.586v-3.21z" fill="#4f442e" />
    <path d="m209.81 71.426v3.21l8.112 1.588v-3.21z" fill="#534832" />
    <path d="m217.91 73.046v3.209l8.098 1.585v-3.209z" fill="#4f442e" />
    <path d="m235.61 77.026v3.209l8.074 1.519v-3.21z" fill="#534832" />
    <path d="m243.61 78.526v3.209l8.1 1.524v-3.21l-8.1-1.522z" fill="#4f442e" />
    <path d="m261.11 82.076v3.21l8.081 1.45v-3.209z" fill="#534832" />
    <path d="m269.21 83.576v3.209l8.1 1.455v-3.209z" fill="#4f442e" />
    <path d="m286.71 87.196v3.209l8.1 1.455v-3.21z" fill="#534832" />
    <path d="m294.81 88.576v3.209l8.1 1.455v-3.21z" fill="#4f442e" />
    <path d="m312.31 92.176v3.209l8.082 1.451v-3.209z" fill="#534832" />
    <path d="m320.41 93.546v3.209l8.1 1.455v-3.21z" fill="#4f442e" />
    <path d="m354.21 99.176v3.21l0.0487 7e-3v-3.209l-0.0487-0.0088z" fill="#3d592f" />
    <path d="m338.11 96.546v3.209l8.065 1.349v-3.209z" fill="#534832" />
    <path d="m346.11 97.796v3.209l8.1 1.355v-3.21z" fill="#4f442e" />
    <path d="m363.61 101.18v3.21l0.0337 5e-3v-3.21z" fill="#3d592f" />
    <path d="m363.61 101.18v3.21l8.112 1.281v-3.21z" fill="#534832" />
    <path d="m371.71 102.39v3.21l8.068 1.274v-3.21z" fill="#4f442e" />
    <path d="m389.21 105.49v3.209l0.04 6e-3v-3.209z" fill="#3d592f" />
    <path d="m389.21 105.49v3.209l8.112 1.281v-3.209z" fill="#534832" />
    <path d="m397.31 106.69v3.209l8.06 1.274v-3.21l-8.06-1.272z" fill="#4f442e" />
    <path d="m414.91 109.69v3.21l8.098 1.154v-3.21z" fill="#534832" />
    <path d="m422.91 110.89v3.21l8.1 1.154v-3.209z" fill="#4f442e" />
    <path d="m440.61 113.49v3.209l8.068 1.056v-3.209z" fill="#534832" />
    <path d="m448.61 114.69v3.209l8.1 1.061v-3.209z" fill="#4f442e" />
    <path d="m466.21 117.19v3.209l8.075 0.9462v-3.21l-8.075-0.945z" fill="#534832" />
    <path d="m474.21 118.19v3.209l8.101 0.9488v-3.209z" fill="#4f442e" />
    <path d="m491.81 120.19v3.209l8.056 0.9438v-3.209z" fill="#534832" />
    <path d="m499.81 121.19v3.209l8.1 0.9488v-3.209z" fill="#4f442e" />
    <path d="m517.51 123.19v3.21l8.112 0.8238v-3.209l-8.112-0.825z" fill="#534832" />
    <path d="m525.61 123.89v3.209l8.062 0.82v-3.209z" fill="#4f442e" />
    <path d="m543.11 125.79v3.209l8.07 0.8212v-3.21l-8.07-0.82z" fill="#534832" />
    <path d="m551.21 126.59v3.209l8.1 0.8238v-3.209z" fill="#4f442e" />
    <path d="m568.71 128.19v3.21l8.078 0.6338v-3.209l-8.078-0.635z" fill="#534832" />
    <path d="m576.81 128.79v3.209l8.1 0.6362v-3.209z" fill="#4f442e" />
    <path d="m594.31 130.19v3.209l8.085 0.4488v-3.21l-8.085-0.4475z" fill="#534832" />
    <path d="m602.41 130.59v3.209l8.1 0.45v-3.21l-8.1-0.4488z" fill="#4f442e" />
    <path d="m620.01 132.69v3.21l8.091 0.3788v-3.209l-8.091-0.38z" fill="#534832" />
    <path
      d="m2.2781 27.041c112.33 16.872 440.85 107.88 708.29 105.32"
      fill="none"
      stroke="#82775d"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="5"
    />
    <path d="m628.11 133.09v3.209l8.1 0.38v-3.209z" fill="#4f442e" />
    <path d="m645.61 133.99v3.209l8.099 0.2612v-3.209z" fill="#534832" />
    <path d="m653.71 134.29v3.209l8.1 0.2625v-3.21l-8.1-0.2612z" fill="#4f442e" />
    <path d="m670.72 134.79v3.21l8.112 0.0737v-3.21l-8.112-0.0738z" fill="#534832" />
    <path d="m678.74 134.89v3.21l8.084 0.0737v-3.21l-8.084-0.0738z" fill="#4f442e" />
    <path d="m704.98 135.19-8.112 0.02v3.209l8.112-0.02z" fill="#534832" />
    <path d="m713.11 135.19-8.1 0.02v3.209l8.1-0.02z" fill="#4f442e" />
    <g fill="#a1977d">
      <path
        d="m704.51 134.09c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m679.31 133.89c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m653.61 133.39c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m628.11 132.09c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m602.41 129.69c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m576.81 127.99c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m551.11 125.69c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m525.41 123.09c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m499.81 120.19c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m474.31 117.09c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m448.61 113.79c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m422.91 109.89c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m397.41 105.79c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m371.71 101.5c0.69 0 1.25-0.56 1.25-1.25 0-0.69-0.56-1.25-1.25-1.25s-1.25 0.56-1.25 1.25c0 0.68999 0.56 1.25 1.25 1.25"
      />
      <path
        d="m346.11 96.876c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m320.41 92.546c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m294.81 87.446c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m269.21 82.526c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m243.61 77.276c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m217.91 72.096c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m192.21 66.796c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m166.61 61.296c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m140.91 55.976c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m115.41 50.626c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m89.694 44.876c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m63.944 39.576c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m38.324 34.176c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
      <path
        d="m12.824 29.546c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25-1.25 0.56-1.25 1.25 0.56 1.25 1.25 1.25"
      />
    </g>
  </svg>
`;
