import { RewardSource } from '@tasks/sheep/reward-source';

export class MotorRewardSource extends RewardSource {
  getNextReward(graphic) {
    const [x, y, w, h] = this.locationsIt.next().value;

    return { x, y, w, h, graphic };
  }

  /**
   * @param {string[]} values
   */
  addRewardItems(values) {
    for (const reward of values) {
      this.rewards_.push(this.getNextReward(reward));
    }
  }
}
