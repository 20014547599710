import { LitElement, nothing } from 'lit';

export class SignIn extends LitElement {
  static properties = {
    view: { state: true },
    code: { type: String },
    verify: { type: Boolean },
  };
  constructor() {
    super();

    this.verify = false;
    this.code = '';
  }

  // don't render anything as we are in shadow dom and password managers cant reliably see inside nested shadow dom.
  // we will render a light dom sign in to a well known node on connect and remove it and its children on disconnect
  render() {
    return nothing;
  }

  connectedCallback() {
    super.connectedCallback();

    const rootNode = document.body.querySelector('#login');

    const node = document.createElement('onc-light-sign-in');
    if (this.verify) {
      node.setAttribute('verify', 'true');
    }

    if (this.code) {
      node.setAttribute('code', this.code);
    }

    rootNode.append(node);
  }

  disconnectedCallback() {
    const rootNode = document.body.querySelector('#login');
    rootNode.innerHTML = '';
    super.disconnectedCallback();
  }
}

const OncSignIn = class OncSignIn extends SignIn {};
customElements.define('onc-sign-in', OncSignIn);
