import { html } from 'lit';

export const tree = html`
  <svg version="1.1" viewBox="0 0 590 565" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m236.6 469.9c3.456 17.08 3.488 54.72-1.638 87.14l59.35 5.352c1.091-26.64 6.58-91.76 7.095-117.6 0.6975-34.12 18.52-37.16 41.98-42.86 23.35-5.73 37.96-3.002 68.56-16.88 0 0-2.245-2.456-2.941-5.959-17.92 6.11-47.1 14.39-63.29 11.89 6.641-9.461 13.52-19.7 24.08-22.86 10.55-3.2 29.62-7.626 29.62-7.626s-4.306-2.716-5.912-4.186c-7.158 1.759-19.05 3.124-20.44 0.895-1.364-2.212 11.77-20.6 11.77-20.6s-7.126-0.4388-8.856-0.6212-6.882 19.51-29.39 32.61c-22.55 13.05-38.86 26.39-40.44 22.61-1.609-3.805 2.061-22.99 2.788-39.81 0.9412-23.06 7.431-41.99 20.16-64.59 12.7-22.62 16.08-34.89 34.32-52-3.639-6.64-2.061-9.794-2.061-9.794s-14.31 17.18-17.46 16.54c-3.064-0.6362 6.095-21.28 6.095-21.28l-8.612-5.808s-29.75 111.9-49.72 108.7c-5.368-0.8638-0.455-34.69 2.94-52.56 3.366-17.86 8.734-28.62 12.59-33.55 3.882-4.944 12.22-18.36 12.22-18.36s-8.129 0.7288-12.68-3.061c-14.3 21.75-21.69 51.25-24.94 70.25-5.188 30.88-11.68 47.62-15.59 45.88-3.942-1.699-9.068-55.62-9.916-64.31-0.8788-8.672 0.6675-13.8 12.65-30.54 11.95-16.75 12.01-22.38 12.01-22.38s-6.792 2.669-13.08 0.7738-6.399 18.56-11.86 23.65c-5.368 5.139-6.032 24.58-10.76 16.52-4.761-8.05-12.31-21.01-16.29-33.31-4.032-12.31-5.306-14.24-5.306-14.24l-9.856-5.322s0.9712 2.958-0.2412 3.852c7.186 14.94 14.89 34.3 25.5 56.94 10.58 22.64 15.98 102.6 10.7 100.8-5.185-1.818-50.72-58.66-53.12-68.16-2.425-9.506-7.035-31.56-7.975-51.06-6.034-3.474-6.276-4.352-6.276-4.352s0.94 19.29-1.182 19.3c-2.062 0.0312-23.11-26.26-23.11-26.26l-5.974 0.9862s21.01 22.95 28.99 42.49c7.975 19.52 5.761 28.66-10.55 14.45-11.25-9.809-40.7-39.22-40.7-39.22l-3.365-0.09s1.395 10.73 0.88 12.25 39.3 32.84 52.21 46.44c20.99 22.08 71.2 61.88 66.16 132.8-0.7575 10.99-21.44-11.3-28.78-18.64-7.4-7.338-28.96-38.08-28.96-38.08s-3.094 4.124-5.155 4.351c12.1 15.72 25.29 36.42 22.68 36.16-2.638-0.2438-47.45-8.491-47.45-8.491s3.154 3.382-2.032 8.174c21.38 3.805 22.96 5.745 34.94 7.308 12.02 1.85 41.26 13.91 45.6 35.5"
      fill="#654209"
    />
    <g fill="#fff">
      <path
        d="m179.4 311.1c10.13 10.67 27.11 25.49 41.36 45.95 3.396-4.868 5.882-10.34 7.489-16.06-14.22-17.48-30.41-39.25-31.81-44.81-2.425-9.508-7.035-31.56-7.975-51.06-6.034-3.474-6.276-4.352-6.276-4.352s0.9388 19.29-1.182 19.3c-2.062 0.03-23.11-26.26-23.11-26.26l-5.975 0.9862s21.01 22.95 28.99 42.49c7.976 19.52 5.762 28.66-10.55 14.45-11.25-9.81-40.7-39.22-40.7-39.22l-3.366-0.09s1.396 10.74 0.8812 12.25 39.3 32.84 52.21 46.44"
        opacity=".5"
      />
      <path
        d="m212.9 206.7c7.188 14.94 14.89 34.3 25.5 56.94 4.58 9.84 8.218 30.49 10.4 50.58 4.032 1.578 8.4 2.669 12.52 1.774-2.698-17.12-5.154-42.56-5.7-48.3-0.8788-8.674 0.6675-13.8 12.65-30.54 11.95-16.75 12.01-22.38 12.01-22.38s-6.792 2.669-13.08 0.7725-6.399 18.56-11.86 23.65c-5.368 5.139-6.034 24.58-10.76 16.52-4.762-8.051-12.31-21.01-16.29-33.31-4.034-12.31-5.306-14.24-5.306-14.24l-9.856-5.322s0.97 2.959-0.2425 3.852"
        opacity=".5"
      />
      <path
        d="m289.1 311.1c0.4238-11.7 3.154-29.19 5.276-40.4 3.366-17.86 8.732-28.62 12.59-33.55 3.881-4.942 12.22-18.36 12.22-18.36s-8.128 0.7275-12.68-3.062c-14.29 21.84-21.68 51.39-24.92 70.32-1.76 10.51-3.669 19.32-5.58 26.4 4.155 1.258 8.975 0.3788 13.1-1.335"
        opacity=".5"
      />
      <path
        d="m312.9 323.7c3.305-12.04 8.551-23.84 15.89-36.82 12.7-22.62 16.08-34.89 34.32-52-3.639-6.64-2.061-9.792-2.061-9.792s-14.31 17.18-17.46 16.54c-3.062-0.6362 6.096-21.28 6.096-21.28l-8.612-5.806s-20.8 78.26-39.3 102c3.365 3.016 7.065 5.715 11.13 7.156"
        opacity=".5"
      />
      <path
        d="m332.9 398.4c1.608 1.911 3.428 3.442 5.368 4.641 1.546-0.3812 3.092-0.7438 4.669-1.122 23.35-5.731 37.96-3.002 68.56-16.88 0 0-2.245-2.456-2.942-5.959-17.92 6.109-47.09 14.39-63.29 11.89 6.642-9.461 13.52-19.7 24.08-22.86 10.55-3.2 29.62-7.626 29.62-7.626s-4.305-2.715-5.912-4.185c-7.156 1.759-19.05 3.124-20.44 0.8938-1.364-2.212 11.77-20.6 11.77-20.6s-7.126-0.44-8.855-0.6212-6.884 19.51-29.39 32.61c-5.732 3.321-11.04 6.642-15.8 9.704-2.395 6.732-2.669 13.82 2.548 20.12"
        opacity=".5"
      />
      <path
        d="m206.6 435.4c-0.12-0.6512 0.7288-1.485 1.395-1.319 4.944 1.259 9.764-0.8338 13.04-4.426-1.73-1.73-3.215-3.276-4.398-4.444-7.399-7.338-28.96-38.08-28.96-38.08s-3.092 4.124-5.154 4.352c12.1 15.72 25.29 36.42 22.68 36.16-2.638-0.2438-47.45-8.491-47.45-8.491s3.154 3.381-2.032 8.172c21.38 3.805 22.95 5.746 34.94 7.308 4.005 0.545 9.948 2.26 16.22 5.079 0.1212-1.394 0.06-2.804-0.2738-4.321"
        opacity=".5"
      />
    </g>
    <path
      d="m220.4 327.8s39.7-7.4 17.95-37.96c4.245-9.279-10.86-20.38-19.29-17.02 15.25-10.05 15.4-40.52-10.67-42.36 0 0 17.08-41.31-35.15-40.75 9.37-21.98-21.65-34.8-37-17.1-7.429-17.8-31.92-9.476-34.54 4.881-7.429-23.71-56.34-18.89-51.49 20.91-15.12-7.686-20.25 4.974-19.5 12.6-14.22-10.22-38.85 15.04-17.92 37.1-17.68 5.884-13.65 32.12-1.031 33.88-14.38 14.3 0.7288 16.92 4.974 18.65-11.1 17.66-2.759 32.92 11.62 26.26-6.004 18.56 20.14 9.386 20.14 9.386-6.034 23.69 28.39 45 41.15 25.61 1.578 19.44 19.31 10.22 19.31 10.22-0.1212 14.4 35.3 15.44 37.09-3.198 11.71 18.68 42.12-1.486 38.85-13.32 15.09 6.912 36.2-5.688 35.5-27.68"
      fill="#00ac2e"
    />
    <path
      d="m94.49 374.2c14.44 2.532 28.6 2.078 43.15 1.334 6.732-0.3488 13.55-0.7425 20.22-1.835 6.246-1.06 16.71-2.244 21.38-7.14 3.579-3.7 4.245-7.581 9.461-9.112 3.7-1.091 7.398-0.7438 10.95-1.275 2.061-16-13.25-20.44-20.38-16.15-0.7888-5.61-4.276-5.489-6.43-4.336 1.789-11.68-17.29-21.24-26.32-6.99-6.398-4.352-20.65 1.091-22.38 5.049-1.304-6.171-10.28-4.472-14.5-0.6362-1.546-16.08-43.28-7.688-34.48 20.98-8.46-2.411-15.29 1.091-18.86 5.398 10.1 9.264 24.99 12.4 38.18 14.72"
      fill="#fff"
      opacity=".5"
    />
    <path
      d="m242.9 315.8c0.8175-0.9862 1.455-2.139 1.849-3.518 1.578-5.611 0.3338-10.25-1.606-15.54-1.212-3.35-2.549-6.974-1.972-10.6 0.6075-3.791 2.548-7.689 2.185-11.58-0.9412-10.07-7.886-19.92-14.29-27.35-5.882-6.854-12.49-13.01-19.2-19.02-15.25-13.61-29.56-28.39-45.18-41.55-8.218-6.914-16.92-13.45-26.56-18.22-4.305-2.138-12.4-5.854-18.5-5.429-0.3025 4.215 1.334 8.142 4.034 9.31-13.95-0.9562-7.066 18.38 0.7575 21.02-25.35 10.37-4.61 40.22 18.04 39.5-6.338 41.09 24.14 45.61 24.14 45.61-14.1 28.81 26.8 37.75 46.06 23.85 4.276 17.51 25.26 1.896 25.26 1.896-0.455 4.836 1.515 8.701 4.972 11.63"
      fill="#fff"
      opacity=".5"
    />
    <path
      d="m454.1 247.6s-1.699-12.25-14.89-9.172c-13.19 3.108-10.13 15.92-10.13 15.92-9.341-3.472-16.46 2.078-14.71 8.262-7.309-10.02-43.88-11.98-41.81 35.16-2.82-1.94-5.186 2.775-5.186 2.775-8.339-10.01-35.62-3.836-25.65 16.09 0 0-23.01 3.942-12.01 19.66 0 0-11.89 9.218-6.49 22.5 5.428 13.29 16.8 8.234 16.8 8.234s-15.08 29.98 16.86 30.42c-0.6388 10.84 16.19 12.96 16.19 12.96s-3.182 24.92 12.74 27.98c2.579 16.09 31.48 28.76 42.55 12.42 3.122 6.17 11.25-0.7738 11.25-0.7738-0.6075 7.99 12.01 13.39 24.8 4.14 6.188 21.75 58.65 31.49 51.85-15.75 10.76 3.929 16.95-3.031 14.71-8.686 6.914 7.595 18.78-1.638 8.702-15 24.65 12.61 45.76-15.34 42.66-28.56-3.092-13.29-23.16-18.72-23.16-18.72s25.62-17.06 1.031-35.31c11.01-12.62 10.13-47.01-34.09-40.12-0.91-4.245-4.61-6.201-4.61-6.201 11.32-4.712-1.412-32.7-27.89-17.99-0.9-29.74-28.05-37.64-39.54-20.4"
      fill="#009035"
    />
    <path
      d="m477.9 242.4c-8.156-2.898-16.95-3.731-25.44-5.382-0.6988-0.1375-0.6675-0.865-0.365-1.365 5.52-9.31 16.29-23.14 6.702-33.36-5.519-5.868-13.7-7.201-21.48-6.519-18.14 1.592-36.09 9.992-51.34 19.58-15.01 9.43-26.84 22.91-35.88 38.09-10.64 17.95-20.05 42.05-8.552 61.79 0.3638 0.5912-0.3025 1.318-0.8175 1.501-7.672 2.971-14.71 8.202-14.01 16.21 9.522-2.229 18.08-8.188 19.99-15.95 0 0 25.11 22.85 49.58 9.885 21.9-12.18 18.44-24.42 16.74-32.32 10.49 1.834 24.54-16.5 23.71-26.11 7.185 14.65 41.15 1.015 48.85-21.98-2.305-1.652-4.852-3.062-7.702-4.062"
      fill="#fff"
      opacity=".5"
    />
    <path
      d="m469.1 204.2c18.5-17.5 11.22-57.86-23.01-63.88 3.395-5.868-4.124-8.446-4.124-8.446s9.37-29.31-20.65-37.01c0.03-4.2-2.426-10.05-2.426-10.05s3.458-28.54-16.5-41.28c-19.99-12.65-35.96 3.23-35.96 3.23-4.882-14.29-18.35-8.491-18.35-8.491-0.7575-14.25-14.89-21.9-23.32-9.355 0 0-5.73-21-23.35-9.325-8.279-11.81-25.01-7.702-29.22-1.835 3.458-20.98-40.79-24.54-41.88 10.72-10.83-5.945-19.22 0.7262-21.78 8.248-5.791-12.61-15.08 6.655-15.08 6.655-15.74-18.56-47.72 14.04-23.65 38.5-14.22 1.608-8.461 15.04-8.461 15.04-11.64-5.095-11.7 6.67-11.7 6.67-16.71-6.806-36.02 14.9-11.16 41.08-14.19 2.426-13.46 18.38-7.641 20.92-13.34-0.91-6.761 17.61 0.7575 20.16-24.32 9.948-4.426 38.58 17.29 37.86-6.064 39.4 23.14 43.74 23.14 43.74-13.52 27.62 25.71 36.22 44.19 22.88 4.064 16.8 24.2 1.82 24.2 1.82-1.789 20.09 39.15 22.8 56.38 10.74 3.548 27.3 43.76 19.12 48.06 1.501 0 0 24.08 21.92 47.55 9.49 20.99-11.69 17.68-23.44 16.08-31 10.04 1.758 23.52-15.82 22.71-25.05 9.925 20.26 72.88-15.52 37.98-53.48"
      fill="#64c600"
    />
    <path
      d="m221.6 403.6s18.01-13.34 9.491-31.96c-8.521-18.62-32.75-10.67-37.18-7.264 4.215-18.81-11.5-24.12-18.52-19.45-0.7288-5.732-4.032-5.596-6.035-4.429 1.699-11.92-16.12-21.66-24.65-7.08-6.005-4.428-19.35 1.182-20.99 5.216-1.212-6.294-9.642-4.55-13.59-0.6062-1.425-16.44-40.54-7.749-32.39 21.5-12.4-3.836-21.16 7.202-20.1 12.86 0 0-18.8 0.7275-16.7 22.56 2.091 21.86 26.11 20.86 26.11 20.86s-15.71 14.75 4.489 24.91c-5.914 6.686 3.001 22.94 22.95 16.02-0.395 8.008 12.91 8.659 16.04-1.332 4.215 9.172 22.71 11.19 31.56 1.728 3.971 6.929 14.16 3.079 17.71-6.354 9.795 9.114 46.51 11.89 40.02-19.8 10.96 1.938 25.04-11.44 21.79-27.39"
      fill="#2ea836"
    />
  </svg>
`;
