export class SoundBase {
  context;

  gainNode;

  #isPlaying_ = false;
  #volume_ = 1;

  /**
   * @param {AudioContext} context
   * @param {Object} param1
   * @param {boolean} [param1.muted]
   * @param {AudioNode} [param1.destination]
   * @param {boolean} [param1.omitGainNode]
   */
  constructor(context, { muted = false, destination, omitGainNode } = {}) {
    this.context = context;
    this.muted = muted;

    const nextNode = destination ?? this.context.destination;

    if (omitGainNode) {
      this.sourceDestination_ = nextNode;
    } else {
      this.gainNode = this.context.createGain();
      this.gainNode.connect(nextNode);
      this.volume = 1;
      this.sourceDestination_ = this.gainNode;
    }
  }

  get isPlaying() {
    return this.#isPlaying_;
  }

  set isPlaying(value) {
    this.#isPlaying_ = value;
  }

  get volume() {
    return this.#volume_;
  }

  set volume(vol) {
    if (vol >= 0 && vol <= 1) {
      this.#volume_ = vol;
      this.muted = false;
      this.gainNode?.gain.setValueAtTime(vol, this.context.currentTime);
    }
  }

  /**
   * @param {AudioNode} destination
   */
  connect(destination) {
    if (this.gainNode) {
      this.gainNode.connect(destination);
    } else {
      this.sourceDestination_ = destination;
    }
  }

  /**
   * @param {boolean} muted
   */
  mute(muted) {
    this.muted = muted;
    this.gainNode?.gain.setValueAtTime(muted ? 0 : this.#volume_, this.context.currentTime);
  }

  get sourceDestination() {
    return this.sourceDestination_;
  }
  set sourceDestination(destination) {
    this.sourceDestination_ = destination;
  }
}
