import { c1 } from './c1.js';
import { c2 } from './c2.js';
import { c3 } from './c3.js';
import { c4 } from './c4.js';
import { c5 } from './c5.js';
import { c6 } from './c6.js';
import { c7 } from './c7.js';
import { c8 } from './c8.js';
import { c9 } from './c9.js';

import { c10 } from './c10.js';
import { c11 } from './c11.js';
import { c12 } from './c12.js';
import { c13 } from './c13.js';
import { c14 } from './c14.js';
import { c15 } from './c15.js';
import { c16 } from './c16.js';
import { c17 } from './c17.js';
import { c18 } from './c18.js';
import { c19 } from './c19.js';

import { c20 } from './c20.js';
import { c21 } from './c21.js';
import { c22 } from './c22.js';
import { c23 } from './c23.js';
import { c24 } from './c24.js';
import { c25 } from './c25.js';
import { c26 } from './c26.js';
import { c27 } from './c27.js';
import { c28 } from './c28.js';
import { c29 } from './c29.js';

import { c30 } from './c30.js';
import { c31 } from './c31.js';
import { c32 } from './c32.js';
import { c33 } from './c33.js';
import { c34 } from './c34.js';

import { egg } from './egg.js';
import { halfEgg } from './half-egg.js';

import { hutchClosed } from './hutch-closed.js';
import { hutchOpen } from './hutch-open.js';

export const chicken = {
  c1,
  c2,
  c3,
  c4,
  c5,
  c6,
  c7,
  c8,
  c9,
  c10,
  c11,
  c12,
  c13,
  c14,
  c15,
  c16,
  c17,
  c18,
  c19,
  c20,
  c21,
  c22,
  c23,
  c24,
  c25,
  c26,
  c27,
  c28,
  c29,
  c30,
  c31,
  c32,
  c33,
  c34,
  egg,
  halfEgg,
  hutchClosed,
  hutchOpen,
};
