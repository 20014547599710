import { html } from 'lit';

export const bg = html`
  <svg
    preserveAspectRatio="xMidYMid slice"
    version="1.1"
    viewBox="0 0 1280 960"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path d="m0 960h1280v-960h-1280v960z" fill="#18dbf0" />
    <path
      d="m1288 1v-2.425h-1244s35.85 74.2 187.4 45.18c150.8-29.02 196-41.92 289.5 16.12 93.5 58.02 210.9-77.78 372.1-3.63 161.2 74.12 203.5-46.41 394.5-55.25"
      fill="#46eef7"
    />
    <path
      d="m1280 105.3v856.7h-1280v-873.9s16.78 87.38 151.2 47.47c134.3-39.92 192.5-53.58 275.1-11.7 185.5 93.9 281.5-65.78 441.5 23.4 160.2 89.3 267.2-95.81 412.2-42"
      fill="#18c0f0"
    />
    <path
      d="m147.33 41.129c-16.77 0.125-18.86 15.17-18.86 15.17-10.14-3.4-11.17 7.31-11.17 7.31-4.22-2.79-18.3 1.56-18.35 10.68v0.1c5e-3 0.8588 0.1338 1.76 0.4088 2.701 3.676 12.56 22.55 8.771 22.55 8.771s0 4.246 7.306 6.965c4.332 1.612 7.099 0.2225 8.629-1.168 1.051-0.9538 1.519-1.908 1.514-1.891 8.192 4.754 14.75-1.355 14.75-1.355 3.731 3.846 7.202 5.216 10.24 5.241h0.18c7.05-0.0625 11.7-7.325 11.7-7.325s13.12 0.215 13.15-9.766v-0.07625c-1e-3 -0.475-0.0325-0.9738-0.0963-1.496-1.22-9.929-11.77-8.154-11.77-8.154-3.138-5.775-9.842-5.602-9.842-5.602s0-19.03-18.49-20.05c-0.4875-0.0275-0.9625-0.0425-1.43-0.045h-0.425z"
      fill="#fff"
      opacity=".5"
    />
    <path
      d="m127.33 54.46s2.234-16.14 20.71-15.13c18.48 1.022 18.48 20.05 18.48 20.05s6.702-0.1662 9.841 5.61c0 0 10.57-1.774 11.77 8.146 1.411 11.58-13.04 11.35-13.04 11.35s-9.619 14.99-22.12 2.076c0 0-6.56 6.116-14.77 1.362 0.0162-0.04-2.836 5.776-10.12 3.059-7.306-2.726-7.306-6.965-7.306-6.965s-18.87 3.78-22.55-8.771c-3.201-10.99 13.31-16.54 17.92-13.49 0 0 1.046-10.7 11.19-7.298"
      fill="#dceeeb"
    />
    <path
      d="m188.14 73.14c-0.5388-4.381-2.9-6.481-5.356-7.455 1.092 1.14 1.918 2.772 2.202 5.086 1.316 10.77-12.14 10.55-12.14 10.55s-8.938 13.95-20.57 1.934c0 0-6.1 5.689-13.74 1.268 0.0162-0.03125-2.63 5.372-9.412 2.844-6.799-2.528-6.799-6.481-6.799-6.481s-17.54 3.526-20.97-8.152c-1.235-4.231 0.6812-7.599 3.644-9.881-4.658 2.29-8.478 6.585-6.781 12.4 3.676 12.55 22.55 8.77 22.55 8.77s0 4.24 7.305 6.965c7.29 2.719 10.14-3.098 10.13-3.058 8.21 4.754 14.77-1.364 14.77-1.364 12.5 12.92 22.12-2.075 22.12-2.075s14.45 0.2375 13.04-11.35"
      fill="#bee2e9"
    />
    <path
      d="m1040.9 38.529c-16.77 0.125-18.86 15.17-18.86 15.17-10.14-3.4-11.17 7.31-11.17 7.31-4.22-2.79-18.3 1.56-18.35 10.68v0.1c5e-3 0.8588 0.1338 1.76 0.4088 2.701 3.676 12.56 22.55 8.771 22.55 8.771s0 4.246 7.306 6.965c4.332 1.612 7.099 0.2225 8.629-1.168 1.051-0.9538 1.519-1.908 1.514-1.891 8.192 4.754 14.75-1.355 14.75-1.355 3.731 3.846 7.202 5.216 10.24 5.241h0.18c7.05-0.0625 11.7-7.325 11.7-7.325s13.12 0.215 13.15-9.766v-0.07625c-1e-3 -0.475-0.032-0.9738-0.096-1.496-1.22-9.929-11.77-8.154-11.77-8.154-3.138-5.775-9.842-5.602-9.842-5.602s0-19.03-18.49-20.05c-0.4875-0.0275-0.9625-0.0425-1.43-0.045h-0.425z"
      fill="#fff"
      opacity=".5"
    />
    <path
      d="m1020.9 51.86s2.234-16.14 20.71-15.13c18.48 1.022 18.48 20.05 18.48 20.05s6.702-0.1662 9.841 5.61c0 0 10.57-1.774 11.77 8.146 1.411 11.58-13.04 11.35-13.04 11.35s-9.619 14.99-22.12 2.076c0 0-6.56 6.116-14.77 1.362 0.016-0.04-2.836 5.776-10.12 3.059-7.306-2.726-7.306-6.965-7.306-6.965s-18.87 3.78-22.55-8.771c-3.201-10.99 13.31-16.54 17.92-13.49 0 0 1.046-10.7 11.19-7.298"
      fill="#dceeeb"
    />
    <path
      d="m1081.7 70.54c-0.5388-4.381-2.9-6.481-5.356-7.455 1.092 1.14 1.918 2.772 2.202 5.086 1.316 10.77-12.14 10.55-12.14 10.55s-8.938 13.95-20.57 1.934c0 0-6.1 5.689-13.74 1.268 0.016-0.03125-2.63 5.372-9.412 2.844-6.799-2.528-6.799-6.481-6.799-6.481s-17.54 3.526-20.97-8.152c-1.235-4.231 0.6812-7.599 3.644-9.881-4.658 2.29-8.478 6.585-6.781 12.4 3.676 12.55 22.55 8.77 22.55 8.77s0 4.24 7.305 6.965c7.29 2.719 10.14-3.098 10.13-3.058 8.21 4.754 14.77-1.364 14.77-1.364 12.5 12.92 22.12-2.075 22.12-2.075s14.45 0.2375 13.04-11.35"
      fill="#bee2e9"
    />
    <path
      d="m454.42 135.14c-16.77 0.125-18.86 15.17-18.86 15.17-10.14-3.4-11.17 7.31-11.17 7.31-4.22-2.79-18.3 1.56-18.35 10.68v0.1c5e-3 0.8588 0.1338 1.76 0.4088 2.701 3.676 12.56 22.55 8.771 22.55 8.771s0 4.246 7.306 6.965c4.332 1.612 7.099 0.2225 8.629-1.168 1.051-0.9538 1.519-1.908 1.514-1.891 8.192 4.754 14.75-1.355 14.75-1.355 3.731 3.846 7.202 5.216 10.24 5.241h0.18c7.05-0.0625 11.7-7.325 11.7-7.325s13.12 0.215 13.15-9.766v-0.0763c-1e-3 -0.475-0.0325-0.9738-0.0963-1.496-1.22-9.929-11.77-8.154-11.77-8.154-3.138-5.775-9.842-5.602-9.842-5.602s0-19.03-18.49-20.05c-0.4875-0.0275-0.9625-0.0425-1.43-0.045h-0.425z"
      fill="#fff"
      opacity=".5"
    />
    <path
      d="m434.42 148.47s2.234-16.14 20.71-15.13c18.48 1.022 18.48 20.05 18.48 20.05s6.702-0.1662 9.841 5.61c0 0 10.57-1.774 11.77 8.146 1.411 11.58-13.04 11.35-13.04 11.35s-9.619 14.99-22.12 2.076c0 0-6.56 6.116-14.77 1.362 0.0162-0.04-2.836 5.776-10.12 3.059-7.306-2.726-7.306-6.965-7.306-6.965s-18.87 3.78-22.55-8.771c-3.201-10.99 13.31-16.54 17.92-13.49 0 0 1.046-10.7 11.19-7.298"
      fill="#dceeeb"
    />
    <path
      d="m495.23 167.15c-0.5388-4.381-2.9-6.481-5.356-7.455 1.092 1.14 1.918 2.772 2.202 5.086 1.316 10.77-12.14 10.55-12.14 10.55s-8.938 13.95-20.57 1.934c0 0-6.1 5.689-13.74 1.268 0.0162-0.0312-2.63 5.372-9.412 2.844-6.799-2.528-6.799-6.481-6.799-6.481s-17.54 3.526-20.97-8.152c-1.235-4.231 0.6812-7.599 3.644-9.881-4.658 2.29-8.478 6.585-6.781 12.4 3.676 12.55 22.55 8.77 22.55 8.77s0 4.24 7.305 6.965c7.29 2.719 10.14-3.098 10.13-3.058 8.21 4.754 14.77-1.364 14.77-1.364 12.5 12.92 22.12-2.075 22.12-2.075s14.45 0.2375 13.04-11.35"
      fill="#bee2e9"
    />
    <path
      d="m1126 206.7c-13.2 0-26.4 0.2485-39.6 0.6973l-0.088 0.0117c-229.1 7.75-450.1 75-677.2 98.88-54.46 5.721-109.2 9.278-164 9.598-54.25 0.31-108-4.049-162.1-6.783-27.19-1.377-55.07-2.426-83-4.383v161.8c89.1-30.75 182.3-48.04 277-48.2 121.9-0.21 243.4 24.48 362.6 47.74 122.4 23.9 244.5 50.31 368.2 66.97 89.98 12.11 181.2 18.67 272.2 18.13v-330.9c-20.4-3.663-40.91-6.703-61.53-8.906-30.72-3.284-61.55-4.635-92.41-4.637z"
      fill="#fff"
      opacity=".5"
    />
    <path
      d="m1127 231.7c-13.77-0.0224-27.54 0.2262-41.31 0.6914-229.1 7.769-450.1 75-677.2 98.84-54.45 5.722-109.2 9.278-164 9.598-54 0.3-107.8-4.1-161.9-6.8-27.04-1.4-54.8-2.4-82.6-4.4v161.6c88.99-30.66 182.1-47.9 276.6-48.07 121.9-0.21 243.4 24.47 362.6 47.74 122.4 23.9 244.5 50.31 368.2 66.97 90.11 12.12 181.4 18.68 272.5 18.12v-330.7c-20.55-3.703-41.22-6.772-62-8.986-30.15-3.224-60.4-4.58-90.69-4.629z"
      fill="#00ac2e"
    />
    <path
      d="m279.1 361.8c-9.154 0-18.3 0.1462-27.46 0.4512l0.6-0.1c-85.5 2.9-171.1 18.9-252.2 47.3v257.3h1280v-172.3c-100.2 0.4135-200.4-7.05-299.6-21.61-123.5-18.11-245.4-44.84-367.9-68.26-109.6-20.98-221.2-42.81-333.4-42.81h-0.1035z"
      fill="#fff"
      opacity=".5"
    />
    <path
      d="m275.1 389.6c-7.576 0.0433-15.15 0.1883-22.73 0.4414-85.6 2.9-171.3 18.9-252.4 47.2v257.2h1280v-172.2c-100 0.4-200-7-298.9-21.6-123.5-18.1-245.3-44.8-367.9-68.2-111.1-21.3-224.5-43.5-338.1-42.8z"
      fill="#64c600"
    />
    <path
      d="m759.1 500.3c-33.51 0-67 1.178-100.4 3.898l0.3-0.1c-72.9 6-145.4 16.9-217.2 30.4-146.5 27.4-293.6 65.2-441.8 89.2v69.1h1280v-130.9c-127-23.5-255-46-383.8-55.9-45.59-3.482-91.36-5.684-137.1-5.684z"
      fill="#fff"
      opacity=".5"
    />
    <path
      d="m763 524.9c-34.67-0.0843-69.33 1.081-103.9 3.895-72.94 5.93-145.4 16.84-217.2 30.31-146.5 27.4-293.7 65.2-441.9 89.2v168.4h1280v-230.3c-127.1-23.41-254.4-45.92-383.4-55.79-44.43-3.395-89.03-5.569-133.6-5.678z"
      fill="#64c600"
    />
    <path
      d="m521 628.4c-45.74 0-91.51 2.198-137.1 5.682-129.1 9.9-256.6 32.5-383.9 55.9v130.9h1280v-69.16c-148.1-23.95-295.2-61.71-441.5-89.11-71.9-13.48-144.4-24.39-217.2-30.31-33.39-2.716-66.86-3.898-100.4-3.898h-0.0488 0.1z"
      fill="#fff"
      opacity=".5"
    />
    <path
      d="m517.6 653c-44.58 0.1083-89.18 2.282-133.6 5.678-129.2 9.8-256.7 32.4-384 55.9v245.4h1280v-183.7c-148-23.95-295-61.68-441.3-89.07-71.88-13.48-144.3-24.38-217.2-30.31-34.56-2.813-69.22-3.979-103.9-3.895z"
      fill="#64c600"
    />
  </svg>
`;
