export default {};

export const rewardLocations = [
  [45.43, 37.29, 7.27, 12.87],
  [74.41, 30.78, 7.27, 12.87],
  [1.38, 41.51, 7.27, 12.87],
  [57.16, 34.08, 7.27, 12.87],
  [86.82, 31.26, 7.27, 12.87],
  [20.54, 42.16, 7.27, 12.87],
  [39.58, 39.62, 7.27, 12.87],
  [63.43, 31.13, 7.27, 12.87],
  [7.66, 42.43, 7.27, 12.87],
  [92.58, 32.55, 7.27, 12.87],
  [32.28, 40.38, 7.27, 12.87],
  [13.87, 41.19, 7.27, 12.87],
  [68.72, 29.47, 7.27, 12.87],
  [80.62, 30.07, 7.27, 12.87],
  [27.03, 40.87, 7.27, 12.87],
  [51.08, 35.16, 7.27, 12.87],
  [10.43, 52.76, 7.27, 12.87],
  [83.39, 40.39, 7.27, 12.87],
  [42.35, 49.94, 7.27, 12.87],
  [66.21, 41.45, 7.27, 12.87],
  [4.15, 51.84, 7.27, 12.87],
  [29.8, 51.21, 7.27, 12.87],
  [89.58, 41.59, 7.27, 12.87],
  [23.31, 52.49, 7.27, 12.87],
  [53.85, 45.49, 7.27, 12.87],
  [71.49, 39.79, 7.27, 12.87],
  [35.05, 50.7, 7.27, 12.87],
  [16.64, 51.51, 7.27, 12.87],
  [48.2, 47.62, 7.27, 12.87],
  [77.19, 41.1, 7.27, 12.87],
  [59.93, 44.4, 7.27, 12.87],
  [13.74, 61.67, 7.27, 12.87],
  [80.48, 50.54, 7.27, 12.87],
  [45.29, 57.77, 7.27, 12.87],
  [1.25, 61.99, 7.27, 12.87],
  [63.3, 51.6, 7.27, 12.87],
  [92.44, 53.02, 7.27, 12.87],
  [26.89, 61.36, 7.27, 12.87],
  [68.59, 49.94, 7.27, 12.87],
  [7.53, 62.91, 7.27, 12.87],
  [39.44, 60.09, 7.27, 12.87],
  [74.28, 51.26, 7.27, 12.87],
  [20.4, 62.64, 7.27, 12.87],
  [32.14, 60.85, 7.27, 12.87],
  [57.03, 54.56, 7.27, 12.87],
  [86.69, 51.74, 7.27, 12.87],
  [50.94, 55.64, 7.27, 12.87],
  [16.51, 71.99, 7.27, 12.87],
  [48.07, 68.1, 7.27, 12.87],
  [83.26, 60.86, 7.27, 12.87],
  [29.66, 71.68, 7.27, 12.87],
  [71.36, 60.27, 7.27, 12.87],
  [4.01, 72.32, 7.27, 12.87],
  [89.45, 62.07, 7.27, 12.87],
  [66.07, 61.94, 7.27, 12.87],
  [23.18, 72.97, 7.27, 12.87],
  [53.72, 65.96, 7.27, 12.87],
  [77.05, 61.58, 7.27, 12.87],
  [10.29, 73.23, 7.27, 12.87],
  [42.22, 70.41, 7.27, 12.87],
  [59.8, 64.88, 7.27, 12.87],
  [34.91, 71.17, 7.27, 12.87],
  [80.79, 67.97, 7.27, 12.87],
  [63.09, 72.36, 7.27, 12.87],
  [72.39, 68.47, 7.27, 12.87],
];
