import { html } from 'lit';

export const c23 = html`
  <svg
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    viewBox="0 0 620 670"
  >
    <path
      d="m504.8 464.6c0 87.2-82.95 158.6-185.2 158.6-102.4 0.1-185.3-71.4-185.3-158.6 0-87.19 82.95-157.9 185.2-157.9 102.3 0 185.2 70.69 185.2 157.9"
      fill="#b3b3b3"
    />
    <g fill="#999">
      <path
        d="m246.3 140.3c8.158-3.126 15.88-2.404 22.88-0.4825 6.99 2.044 13.31 5.736 18.58 10.61 5.274 4.855 9.452 10.96 12.01 17.84 2.442 6.939 3.462 14.61 0.9325 22.9-8.072-3.109-13.66-6.329-18.79-9.599-5.048-3.27-9.376-6.63-13.4-10.4-4.039-3.751-7.766-7.895-11.42-12.75-3.654-4.95-7.111-10.39-10.79-18.12"
      />
      <path
        d="m318.5 90.77c7.692 5.781 12.46 12.64 16.26 19.76 3.675 7.171 6.092 14.74 7.322 22.6 1.201 7.846 1.188 16-0.4825 24.22-1.806 8.101-5.026 16.86-12.48 23.2-4.2-8.531-7.305-15.1-9.768-22.14-2.408-6.934-4.185-13.88-5.261-21.02-1.065-7.151-1.481-14.5-1.012-22.16 0.565-7.704 1.795-15.61 5.421-24.46"
      />
      <path
        d="m348.5 107.5c6.11 6.752 9.142 13.91 11.15 21.1 1.891 7.214 2.46 14.54 1.751 21.85-0.695 7.315-2.69 14.66-6.41 21.68-3.77 6.721-9.38 14.16-18.42 16.75-1.09-8.86-2.704-15.02-3.278-21.72-0.635-6.542-0.7125-12.96-0.0563-19.45 0.6475-6.488 1.978-13.02 4.241-19.69 2.369-6.668 5.421-13.4 11.02-20.5"
      />
    </g>
    <g fill="#3c3c3b">
      <path
        d="m118 313.3c10.06-5.778 21.74-8.356 33.96-8.53 6.102 0.1325 12.31 1.02 18.4 2.766 6.036 1.844 11.89 4.534 17.32 7.895 5.425 3.368 10.8 7 15.65 11.25 4.839 4.261 8.562 9.515 10.98 15.24 4.709 11.54 6.365 23.96 2.246 36.82l-2.7 0.86c-10.74-7.965-17.42-15.95-23.9-23.26-3.158-3.668-5.694-7.44-7.964-11.51-2.212-4.101-4.745-8.132-8.039-11.38-3.245-3.298-6.899-6.198-10.95-8.6-3.979-2.536-8.398-4.576-13.12-6.086-9.418-3.282-20.2-4.066-30.85-2.822l-1.03-2.648z"
      />
      <path
        d="m115.3 271.8c6.77-5.164 14.41-9.012 22.78-11.51 8.335-2.502 17.52-3.536 26.82-2.118 9.246 1.495 18.28 5.589 25.82 11.35 7.532 5.828 13.59 13.08 18.6 20.66 5.051 7.95 9.044 14.75 13.39 22.24 4.098 7.42 7.291 15.32 9.282 23.74 1.945 8.384 3.189 17.1 2.938 26.09-0.3188 8.872-1.181 18.35-7.008 26.74l-2.676-0.9225c-5.785-15.31-13.66-28.56-20.06-42.78-3.256-7.05-6.044-14.31-8.294-21.92-2.186-7.434-4.471-15.74-7.315-22.08-5.966-13.12-14.92-24.4-28.15-29.39-13.16-5.345-29.85-3.501-44.62 2.312l-1.488-2.418z"
      />
      <path
        d="m188.8 249.6c7.136-3.074 15.28-3.361 23.4-1.572 4.002 0.9925 7.968 2.536 11.72 4.549 3.664 2.116 7.098 4.674 10.21 7.524 3.124 2.858 6.355 5.709 9.232 8.874 2.834 3.192 4.671 6.972 5.372 11.02 0.6925 4.024 0.6962 8.181-0.275 12.35-0.8738 4.15-2.495 8.321-5.806 12.52l-2.788 0.5375c-4.632-2.591-7.818-5.155-10.57-7.67-2.834-2.481-5-4.926-6.865-7.309-1.859-2.389-2.951-4.884-3.496-7.74-0.5175-2.859-1.098-5.806-2.359-8.308-1.236-2.515-2.704-4.911-4.429-7.119-1.645-2.314-3.599-4.465-5.869-6.372-4.444-3.954-10.39-7.014-17-8.51l-0.4688-2.78z"
      />
      <path
        d="m316.1 466.2c-1.376-0.035-2.708-0.175-4.031-0.3625-86.8-3.508-133.4-71.05-133.4-71.05-12.76 38.41-12.82 81.62-12.82 81.62 2.009 82.95 69.82 149.5 153.2 149.5v-160c-0.6538 0.0413-1.258 0.195-1.904 0.195h-1.059z"
      />
    </g>
    <path
      d="m213.4 273.4-0.0487-0.0625s0.004 0.3062 0.004 0.7962c-0.22 2.844-0.4238 5.701-0.4238 8.608 0 0.8862 0.0913 1.752 0.105 2.64-0.5975 10.7-2.186 27.4-6.84 45.02-3.28 12.36-8.041 25.16-15.1 36.66-5.128 8.384-9.184 17.89-12.44 27.7 0 0 46.62 67.55 133.4 71.05h0.0213c1.306 0.1875 2.641 0.3275 4.01 0.3625h1.059c0.6462 0 1.25-0.1538 1.904-0.195v-289.3c-55.48 0-101 42.56-105.7 96.8"
      fill="#b3b3b3"
    />
    <path
      d="m459.8 396.5s-49.72 69.54-141 69.54v160c83.48 0 151.4-66.72 153.2-149.8 0 0-0.0425-41.82-12.21-79.76"
      fill="#575756"
    />
    <path
      d="m447.3 367c-6.445-10.57-11.01-22.25-14.24-33.65-5.534-19.48-7.21-38.19-7.745-49.38 0-0.4325 0.045-0.8525 0.045-1.285 0-1.936-0.1888-3.829-0.2862-5.736-0.0425-2.285-0.0138-3.668-0.0138-3.668l-0.1612 0.2438c-4.664-54.34-50.15-96.99-105.7-96.99v289.3c91.29 0 141-69.54 141-69.54-3.325-10.39-7.528-20.5-12.94-29.39"
      fill="#ccc"
    />
    <g fill="#999">
      <path
        d="m357.3 357.8v-0.0625c0-23.6-21.3-42.7-38.9-42.7-17.59 0-38.92 19.12-38.92 42.71 0 9.445-0.73 34.25 17.11 34.25 5.258 0 9.906-2.424 13.05-6.162 2.949 0.315 5.918 0.4688 8.768 0.4688 2.865 0 5.824-0.1538 8.779-0.4688 3.126 3.739 7.78 6.162 13.05 6.162 17.8 0 17.1-24.7 17.1-34.19"
      />
      <path
        d="m276.1 237.3c-26.09 0-47.24 20.1-47.24 44.88 0 24.8 21.15 44.89 47.24 44.89 19.5 0 36.24 2.844 43.46-13.12v-49.36c-7.3-16.1-24-27.3-43.5-27.3"
      />
      <path
        d="m362.3 327c26.09 0 47.25-20.09 47.25-44.89 0-24.78-21.16-44.88-47.25-44.88-19.56 0-36.34 11.29-43.52 27.38-0.015-0.0288-0.0425-0.07-0.0563-0.105v49.36c0.0138-0.0412 0.0413-0.0688 0.0563-0.1112 7.182 16.09 23.95 13.24 43.52 13.24"
      />
    </g>
    <path
      d="m311.1 316.2-11.44 7.545c-4.601 3.04-6.054 9.398-3.231 14.14l17.9 30.08c1.415 2.382 3.276 3.57 5.135 3.57v-57.61c-3.046 0-6.061 0.76-8.37 2.284"
      fill="#f39e2b"
    />
    <path
      d="m324.8 367.9 17.91-30.08c2.83-4.744 1.37-11.1-3.235-14.14l-11.44-7.545c-2.306-1.524-5.339-2.285-8.37-2.285v57.61c1.859 0 3.72-1.188 5.131-3.57"
      fill="#fbdd58"
    />
    <path
      d="m283.5 282.3c0 8.461-6.868 15.31-15.31 15.31-8.46 0-15.31-6.854-15.31-15.31 0-8.446 6.854-15.3 15.31-15.3 8.44 0 15.31 6.854 15.31 15.3"
      fill="#4e1412"
    />
    <path
      d="m383.5 282.3c0 8.461-6.864 15.31-15.31 15.31-8.45 0-15.31-6.854-15.31-15.31 0-8.446 6.86-15.3 15.31-15.3 8.448 0 15.31 6.854 15.31 15.3"
      fill="#4e1412"
    />
    <path
      d="m279.8 276.2c0 2.892-2.348 5.24-5.232 5.24-2.892 0-5.234-2.348-5.234-5.24s2.341-5.232 5.234-5.232c2.885 0 5.232 2.34 5.232 5.232"
      fill="#fff"
    />
    <path
      d="m378.5 276.2c0 2.892-2.338 5.24-5.23 5.24-2.891 0-5.232-2.348-5.232-5.24s2.341-5.232 5.232-5.232c2.892 0 5.23 2.34 5.23 5.232"
      fill="#fff"
    />
    <path
      d="m228.7 207.3s116.5 57.15 167.8 12.75c33.2-28.8 7.2-141.4-56-149.6-95.2-12.58-122.9 96.9-111.8 136.9"
      fill="#93278f"
    />
    <path
      d="m216.5 176.6 2.472 24.85s5.321 17.79 13.68 19.74c8.36 1.951 68.46 28.45 86.59 25.76 18.12-2.695 80.51 2.441 98.01-26.31s11.45-63.75-12.4-51.25-41.54 18.65-75.82 11.12c-34.29-7.524-98.35-36.24-110.1-27.42-11.75 8.75-2.5 23.5-2.5 23.5"
      fill="#662d91"
    />
    <path
      d="m147.1 393s12.34 13.05 25.59 16.14c13.24 3.09 108.4 45.08 137.1 40.8 28.7-4.27 127.5 3.865 155.2-41.66 27.72-45.54 18.14-46.5-19.64-26.7s-65.79 29.54-120.1 17.62-155.8-57.39-174.2-43.45c-18.55 13.95-3.916 37.26-3.916 37.26"
      fill="#93278f"
    />
    <path
      d="m396.1 555.2s14.62-10.43 19.51-23.11c4.885-12.69 37.94-41.31 29.85-94.01-7.958-51.88-38.14-59.1-50.25-18.2-14.5 48.96-46.74 106-35.49 126.4 11.26 20.29 36.36 9.014 36.36 9.014"
      fill="#93278f"
    />
    <path
      d="m497.3 404s-11.22 14.02-24.15 18.21c-12.94 4.179-104.3 53.92-133.2 52.05-28.96-1.87-126.8 14.45-158.1-28.62-31.41-43.08-21.94-44.84 17.35-28.25s68.01 23.96 121.1 7.581c53.12-16.39 150.5-70.14 170.1-57.78 19.64 12.36 6.999 36.8 6.999 36.8"
      fill="#662d91"
    />
    <path
      d="m326.1 26.89c0.4688 4.086 0.9388 8.174 1.408 12.26l13.5-5.135c0.8238 2.144 1.048 4.515 0.6362 6.775 3.404-0.2525 6.808-0.5038 10.21-0.755l-1.1 5.4 17 0.1c-4.308 3.689-8.615 7.376-12.92 11.06 4.145 0.29 8.289 0.58 12.43 0.8688-5.075 1.83-9.814 4.584-13.91 8.088l7.968-0.5062c-0.2638 3.328-1.619 6.56-3.806 9.082 3.666-0.2575 7.375 0.08 10.94 0.9938-3.216 3.445-7.806 5.571-12.51 5.799-0.2075 1.031 0.3125 2.166 1.228 2.684l-6.125 1.03-0.1212 13.64c0.805-2.336-0.7462-5.196-3.144-5.796-1.93 3.619-3.86 7.239-5.79 10.86-0.9462-4.864-2.554-9.598-4.766-14.04-1.314 4.52-2.628 9.039-3.941 13.56-0.3038-4.135-0.6088-8.271-0.9125-12.41-4.311 7.038-10.68 12.8-18.12 16.38l-0.755-19.9c-2.628 1.2-5.255 2.401-7.884 3.601 0.4688-2.301 1.831-4.406 3.738-5.776-6.185 2.69-12.88 4.204-19.62 4.438 2.18-3.894 5.888-6.906 10.14-8.242-4.54-0.3662-9.08-0.7325-13.62-1.098 5.22-0.0575 10.44-0.115 15.66-0.1712-3-1.93-6.5-2.98-10-2.98 2.856 0.1462 5.624-2.172 5.979-5.01-4.016-0.1012-8.012-0.9812-11.7-2.576l15.52-0.8112c-3.3-1.33-6.8-2.15-10.4-2.41 4.52-1.111 7.396-6.588 5.746-10.94 2.379-0.115 4.758-0.2312 7.138-0.3462-0.295-1.004 0.4838-2.179 1.522-2.298-3.41-2.684-6.968-5.18-10.65-7.474 3.799 4.195 9.46 6.638 15.12 6.524 0.84-2.056 1.064-4.36 0.635-6.54 0.2662 1.301 1.654 2.264 2.965 2.059-0.375-4.734-0.75-9.468-1.125-14.2 0.5088 3.489 3.349 6.516 6.799 7.248 1.22-4.586 3.788-8.805 7.3-12"
      fill="#29abe2"
    />
  </svg>
`;
