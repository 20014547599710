import {css} from "lit-element/lit-element.js";
export default css`:host {
  overflow: auto;
  display: block;
  width: 100%;
  height: 100%;

  --onc-navbar-color: rgba(0, 0, 0, 0);
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.game-button {
  --onc-svg-icon-width: 100%;
  --onc-svg-icon-height: 100%;
  --adt-ins-display: none;
  --adt-dis-display: none;
  --adt-fin-display: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  cursor: pointer;
  display: block;
  width: 100%;
}

.game-button-disabled {
  cursor: default;
}

.game-0 {
  grid-area: g0;
}

.game-1 {
  grid-area: g1;
}

.game-2 {
  grid-area: g2;
}

.game-3 {
  grid-area: g3;
}

.tasks {
  display: grid;
  grid-column-gap: 20px;
  grid-template-areas: 'l0 g0 g0 r0' 'g1 g1 g2 g2' 'l1 g3 g3 r1';
  grid-template-columns: minmax(min-content, 180px) minmax(min-content, 180px) minmax(min-content, 180px) minmax(
      min-content,
      180px
    );
  justify-items: center;

  width: 40vw;
  margin: auto;
}

.sound-action {
  margin-bottom: 10px;
  margin-left: 10px;
}

.sound-button {
  --md-sys-color-primary: #fff;
  --md-sys-color-outline: #fff;
  --md-outlined-button-label-text-color: #fff;
}
.sound-button-icon {
  color: #fff;
}
`;
