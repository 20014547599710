import { ContextConsumer } from '@lit/context';
import { msg } from '@lit/localize';
import { LitElement, css, html, nothing } from 'lit';
import { createRef } from 'lit/directives/ref.js';
import { when } from 'lit/directives/when.js';
import { routerContext, userContext } from '~/app/contexts.js';
import { fullProductName } from '~/version';

const navMenuKeys = new Map([
  ['mydetails', { path: '/details' }],
  ['logout', { path: '/logout' }],
]);

const onReload = () => {
  location.reload();
};

const adminRights = ['language:edit', 'results:manage', 'globalusers:admin', 'projects:edit', 'data:manage'];

const onChoose = ({ target }) => {
  target.nextElementSibling?.show();
};

export class Navbar extends LitElement {
  static properties = {
    showAdmin: { state: true },
    homePage: { type: Boolean, attribute: 'home-page' },
    loggedIn: { type: Boolean },
    navTitle: { type: String },
    fixedAdjust: { type: Boolean },
    currentUser: { state: true },
  };

  constructor() {
    super();

    new ContextConsumer(this, { context: userContext, subscribe: true, callback: nv => (this.currentUser = nv) });
    new ContextConsumer(this, { context: routerContext, callback: value => (this.router = value) });

    this.homePage = false;
    this.navTitle = '';

    this.fixedAdjust = true;
    this.menuReference = createRef();
    this.anchorReference = createRef();

    this.loggedIn = true;
    this.showAdmin = false;

    // todo: fixme
    this.showLanguageSelection = false;
  }

  /**
   * @param {Map<string, any>} changedProperties
   */
  willUpdate(changedProperties) {
    if (changedProperties.has('currentUser')) {
      const { name = '', email = '' } = this.currentUser ?? {};
      const cleanName = name === 'n/a' ? '' : name;
      this.user = {
        name: cleanName,
        email,
        initial: cleanName > 0 ? cleanName[0] : email[0],
      };
      this.showAdmin = adminRights.some(right => this.currentUser.can?.(right));
    }
  }

  render() {
    return html`
      <header class="navbar">
        <div class="navbar-row">
          <section class="navbar-section navbar-section-align-start">
            ${when(
              this.showAdmin && this.homePage,
              () => html`
                <md-icon-button href="" @click=${this.toNav} title=${msg('Admin menu')} class="onc-icon-button">
                  <md-icon>admin_panel_settings</md-icon>
                </md-icon-button>
              `,

              () => html`
                <md-icon-button @click=${this.toNav} title=${msg('Home')} class="onc-icon-button"
                  ><md-icon>home</md-icon></md-icon-button
                >
              `,
            )}

            <div class="top-app-bar-tla">${this.navTitle ?? fullProductName}</div>
          </section>

          ${this.renderSignin()} ${this.renderContent()}
        </div>
      </header>

      ${this.fixedAdjust ? html` <div class="navbar-fixed-adjust"></div>` : nothing}
    `;
  }

  renderSignin() {
    if (!this.currentUser.loggedIn) {
      return html`
        <section class="navbar-section navbar-section-align-end">
          <md-outlined-button class="onc-button" @click=${this.toSignIn}>${msg('Sign in')}</md-outlined-button>
        </section>
      `;
    }
  }

  renderContent() {
    if (this.currentUser.loggedIn) {
      return html`
        <section class="navbar-section navbar-section-align-end">
          ${this.renderLanguageSelection()}
          <md-icon-button href="" @click=${onReload} title=${msg('reload application')} class="onc-icon-button"
            ><md-icon>refresh</md-icon>
          </md-icon-button>
          <md-icon-button class="onc-icon-button" title=${msg('help')} @click=${this.onHelp}
            ><md-icon>help</md-icon></md-icon-button
          >
          <div style="position: relative">
            <md-icon-button @click=${onChoose} .title=${this.user.email ?? ''} class="onc-icon-button" id="anchor"
              ><md-icon>account_circle</md-icon>
            </md-icon-button>
            <md-menu class="navbar-menu" anchor="anchor">
              <md-menu-item>
                <div slot="headline">${this.user.name}</div>
                <div slot="supporting-text">${this.user.email}</div>
                <md-icon slot="start">account_circle</md-icon>
              </md-menu-item>
              <md-divider></md-divider>
              <md-menu-item @click=${() => this.onSelect('mydetails')}>
                <div slot="headline">${msg(`My Details`)}</div>
                <md-icon slot="start">manage_accounts</md-icon>
              </md-menu-item>
              <md-menu-item @click=${() => this.onSelect('logout')}>
                <div slot="headline">${msg(`Log out`)}</div>
                <md-icon slot="start">logout</md-icon>
              </md-menu-item>
            </md-menu>
          </div>
          <slot name="overflow"></slot>
        </section>
      `;
    }
  }

  renderLanguageSelection() {
    // if (this.showLanguageSelection) {
    //   return html`
    //     <md-outlined-select  v-model="locale">
    //       <md-select-option
    //         .value="item.value"
    //         .headline="item.text"
    //         .selected="item.value===locale"
    //         v-for="item in knownLocales"
    //       ></md-select-option>
    //     </md-outlined-select>
    //   `;
    // }
  }

  onMenu() {
    // @ts-ignore
    this.menuReference.value.show();
  }

  onSelect = (/** @type {string} */ name) => {
    const entry = navMenuKeys.get(name);

    if (entry) {
      this.router.goto(entry.path, { history: true });
    }
  };

  toNav = () => {
    const path = this.showAdmin && this.homePage ? '/admin/projects' : '/';

    this.router.goto(path, { history: true });
  };

  toSignIn = () => {
    this.router.goto('/signin', { history: true });
  };

  onHelp = () => {
    this.router.goto('/help', { history: true });
  };

  onMenuClosed() {
    this.menuopen = false;
  }
}

const OncNavbar = class OncNavbar extends Navbar {
  static styles = css`
    .navbar {
      position: fixed;
      z-index: 4;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      box-sizing: border-box;
      width: 100%;

      color: #fff;

      background-color: #ffa219;
      background-color: var(--onc-navbar-color, #ffa219);
    }

    .navbar-fixed-adjust {
      padding-top: 64px;
    }

    .navbar-row {
      position: relative;

      display: flex;

      box-sizing: border-box;
      width: 100%;
      height: 64px;
    }

    .navbar-section-align-start {
      justify-content: flex-start;
      order: -1;
    }

    .navbar-section-align-end {
      justify-content: flex-end;
      order: 1;
    }

    .navbar-section {
      z-index: 1;

      display: inline-flex;
      flex: 1 1 auto;
      align-items: center;

      min-width: 0;
      padding: 8px 12px;
    }

    .top-app-bar-tla {
      margin-left: 10px;
      font-family: var(--md-sys-typescale-headline-small-font);
      font-weight: var(--md-sys-typescale-headline-small-weight);
    }

    .onc-icon-button {
      --md-sys-color-on-surface-variant: var(--onc-navbar-icon-color, white);
      --md-icon-font-variation-settings: 'FILL' 1;
    }

    .navbar-menu {
      --md-icon-color: var(--onc-navbar-menu-icon-color, #1f1b16);
    }
  `;
};

customElements.define('onc-navbar', OncNavbar);
