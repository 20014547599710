import { msg, str } from '@lit/localize';
import { LitElement, css, html } from 'lit';
import { Auth } from '~/app/auth.js';
import { validate as emailValidate } from '~/validators/email.js';
import { fullProductName } from '~/version.js';
import { getMessageForError } from '../message-error.js';

const keyMap_ = {
  noSuchUser: () => msg(`Sorry, we can’t find an account with that email.`),
};

export class ForgotPassword extends LitElement {
  static properties = { messages: { state: true }, subject: { type: String } };
  constructor() {
    super();

    /** @type {string | boolean | string[]} */
    this.messages = [];
    this.subject = '';
  }

  createRenderRoot() {
    return this;
  }

  render() {
    return html`
      <h1 class="lsi-headline4">${msg(`What's your email address?`)}</h1>

      <div class="sign-in-instructions">
        ${msg(
          str`Enter the email registered with ${fullProductName} and we'll send you a link to reset your password.`,
        )}
      </div>
      <div class="sign-in-field">
        <md-filled-text-field
          class="sign-in-input"
          id="name"
          .value=${this.subject}
          @input=${this.onInput}
          name="email"
          autocomplete="email"
          @blur=${this.onEmailBlur}
          @focus=${this.onEmailFocus}
          @keyup=${this.onEnter}
          .label=${msg(`Email`)}
        >
        </md-filled-text-field>

        <onc-form-message .messages=${this.messages}></onc-form-message>
      </div>

      <md-filled-button @click=${this.sendResetInstructions} class="sign-in-action"
        >${msg(`Reset Password`)}</md-filled-button
      >
    `;
  }

  onInput({ target: { value } }) {
    this.subject = value;
  }

  onEnter({ key, target: { value } }) {
    if (key === 'Enter' && value) {
      this.sendResetInstructions();
    }
  }

  onEmailBlur({ target: { value } }) {
    const messages = emailValidate([value]);
    this.messages = messages;
  }

  onEmailFocus() {
    this.messages = [];
  }

  async sendResetInstructions() {
    const messages = emailValidate([this.subject]);
    this.messages = messages;

    if (messages == true) {
      await Auth.sendVerifyAccount({ subject: this.subject, resend: false })
        .then(() => {
          this.dispatchEvent(new Event('verify'));
        })
        .catch((/** @type {Error} */ error) => {
          const { messages } = getMessageForError(error, keyMap_);
          this.messages = messages;
        });
    }
  }
}

const OncForgotPassword = class OncForgotPassword extends ForgotPassword {
  static styles = css`
    *,
    :before,
    :after {
      box-sizing: border-box;
    }

    .headline4 {
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-family: Manrope, Roboto, sans-serif;

      font-size: 2.125rem;
      line-height: 2.5rem;
      font-weight: 400;
      letter-spacing: 0.0073529412em;
      text-decoration: inherit;
      text-transform: inherit;
    }

    .sign-in-field {
      margin-bottom: 16px;
    }

    .sign-in-input {
      padding-top: 16px;
      width: 100%;

      --md-filled-text-field-trailing-icon-color: cornflowerblue;
      --md-sys-color-surface-variant: #f5f5f5;
    }

    .sign-in-instructions {
      margin-bottom: 32px;
      font-size: 1.25rem;
    }

    .sign-in-action {
      width: 100%;
      margin-left: 0;
      --md-sys-color-primary: var(--onc-signin-color, #808080);
      --md-filled-button-container-shape: 9px;
    }
  `;
};

customElements.define('onc-forgot-password', OncForgotPassword);
