import { chickenGameIns } from './chicken-game-ins';
import { memoryGameIns } from './memory-game-ins';
import { motorGameIns } from './motor-game-ins';
import { noGameIns } from './no-game-ins';
import { sheepGameIns } from './sheep-game-ins';

export const taskInsGraphics = {
  'chicken-game': chickenGameIns,
  'sheep-game': sheepGameIns,
  'memory-game': memoryGameIns,
  'motor-game': motorGameIns,
  'no-game': noGameIns,
};
