import { FarmGameSettings } from '@tasks/farm-game-settings';
import { MotorDemo } from './motor-demo';
import { MotorDurations } from './motor-durations';
import { MotorPhaseSetting } from './motor-phase-setting';

/**
 * @typedef { import('./record-types.js').MotorTaskRecord}  MotorTaskRecord
 */

export class MotorSettings extends FarmGameSettings {
  /**
   *
   * @param {MotorTaskRecord} taskRecord
   */
  constructor(taskRecord) {
    super(taskRecord);

    this.durations_ = new MotorDurations(taskRecord.model.durations);

    this.phases_ = [new MotorPhaseSetting(taskRecord.model.phase)];

    this.demo_ = new MotorDemo(taskRecord.model.demo);

    this.hitTest = taskRecord.model.common.hitTest;

    this.phaseIndex = 0;
  }

  get durations() {
    return this.durations_;
  }

  get demo() {
    return this.demo_;
  }
  get phases() {
    return this.phases_;
  }

  get currentPhase() {
    return this.phases_[this.phaseIndex];
  }
}
