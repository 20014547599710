import { configureLocalization, str } from '@lit/localize';
import { lookup } from 'bcp-47-match';
import { html } from 'lit';

/**
 * @type {Map<string, {[x: string]: string| any}>}
 */
const localizedTemplates = new Map(); // [['en', templates]]);

export const configureAndSetDefaultLocale = async (sourceLocale, targetLocales) => {
  const { setLocale } = configureLocalization({
    sourceLocale,
    targetLocales,
    loadLocale: async locale => {
      const cachedTemplates = localizedTemplates.get(locale);
      if (cachedTemplates) {
        return { templates: cachedTemplates };
      }

      const templates = await importLocale(locale);
      // console.log(templates);

      localizedTemplates.set(locale, templates);

      return { templates };
    },
  });

  const lang = navigator.language;

  const match = lookup(targetLocales, lang) ?? targetLocales[0];

  await setLocale(match);
};

async function importLocale(locale) {
  // const { templates } = await import('~/generated/locales/en.js');

  const { templates } = await import(`/locale/${locale}.js`);

  return templates?.(html, str);
}
