import { ContextConsumer, ContextProvider } from '@lit/context';
import { Task } from '@lit/task';
import { ElapsedTimer } from '@ounce/onc';
import { ViewController } from '@tasks/chicken/view-controller';
import { sampleStandardDeviation } from '@tasks/stats.js';
import { LitElement, css, html, nothing } from 'lit';
import { projectContext } from '~/app/contexts';
import { bg } from '~/graphics/bg';
import { graphics } from '~/graphics/index.js';
import { audio } from './sheep-audio.js';
import { sheepTaskContext } from './sheep-context.js';
import { TaskController } from './task-controller';
import { TrialAdaptation } from './trial-adaptation.js';

/**
 * @typedef {import('./sheep-types.js').TrialResult} TrialResult
 * @typedef {import('./sheep-phase.js').SheepPhase} SheepPhase
 */

const _targetRatio = 16 / 10;

export class SheepGame extends LitElement {
  static properties = {
    taskId: { type: String },
    block: { state: true },
    phaseSettings: { state: true },
    progressValue: { state: true },
  };

  taskProvider = new ContextProvider(this, { context: sheepTaskContext });

  /**
   * @type {(value?:{trialResponses:[], blockTrials: any[], currentDuration: {}, maxNoResponseExceeded: boolean}|{action:string}, ) => void}
   */
  viewNextPromiseResolve;

  constructor() {
    super();

    this.bgItems = [];
    this.taskId = '';
    this.taskController = undefined;
    this.choiceOnly = false;
    this.progressValue = 0;
    this.lastProgress = 0;
    this.blockProgress = 0;

    new ContextConsumer(this, { context: projectContext, callback: value => (this.project = value) });

    this.viewNextPromise = this.getViewNextPromise();

    this.viewController = new ViewController(this, [
      {
        name: 'none',
        render: () => nothing,
      },
      {
        name: 'trailer',
        render: () => html`
          <onc-task-trailer
            ?choiceOnly=${this.choiceOnly}
            @next=${this.onTrailerNext}
            .bgItems=${this.bgItems}
          ></onc-task-trailer>
        `,
      },
      {
        name: 'presenter',
        render: (block, durations, phaseSettings, trialAdaptation) => {
          const showBorder = this.taskController.showLayout;
          return html`
            <onc-task-progress-bar class="progress-bar" .value=${this.progressValue}> </onc-task-progress-bar>
            <onc-layout-task class="sheep-layout" ?showBorder=${showBorder} .targetRatio=${_targetRatio}>
              <onc-sheep-presenter
                @next=${this.viewNext}
                .block=${block}
                .stimulusDuration=${durations}
                .phaseSettings=${phaseSettings}
                .trialAdaptation=${trialAdaptation}
                @progress=${this.onProgress}
              ></onc-sheep-presenter
            ></onc-layout-task>
          `;
        },
      },
      {
        name: 'block-feedback',
        render: block => {
          const showBorder = this.taskController.showLayout;
          return html`
            <onc-sheep-block-feedback
              ?showBorder=${showBorder}
              .progress=${this.blockProgress}
              .lastProgress=${this.lastProgress}
              @next=${this.viewNext}
              .block=${block}
            ></onc-sheep-block-feedback>
          `;
        },
      },
      {
        name: 'end-game',
        render: () => {
          const showBorder = this.taskController.showLayout;
          return html`
            <onc-layout-task ?showBorder=${showBorder} .targetRatio=${_targetRatio}>
              <onc-end-game ?showBorder=${showBorder} @next=${this.viewNext}></onc-end-game>
            </onc-layout-task>
          `;
        },
      },
    ]);

    this.getSounds = new Task(this, {
      task: async () => {
        this.taskController = new TaskController(this, this.taskId);
        this.taskProvider.setValue(this.taskController);

        const stimuli = {
          targets: this.taskController.taskProfile.targets,
        };

        await this.taskController.createSounds(audio);
        await this.taskController.setupResults([{ start: new Date(), stimuli }]);

        this.responseTimer = new ElapsedTimer();

        this.next();
      },
      args: () => [],
    });
  }

  render() {
    return html`<onc-screen-svg>${bg}</onc-screen-svg>${this.getSounds.render({
        complete: () => html`${this.viewController.outlet()}`,
      })} `;
  }

  /**
   * @param {Event&{target: {action: String}}} event
   */
  async onTrailerNext(event) {
    const action = event.target.action;

    this.taskController.mode = action;
    this.choiceOnly = true;

    this.viewNextPromiseResolve({ action });

    this.viewNextPromise = this.getViewNextPromise();
  }

  /**
   * @param {Event&{target: {trialResponses: [], maxNoResponseExceeded: boolean, blockTrials: any[], currentDuration: {}}}} event
   */
  async viewNext(event) {
    if (event) {
      const {
        target: { trialResponses, maxNoResponseExceeded, blockTrials, currentDuration },
      } = event;
      this.viewNextPromiseResolve({ trialResponses, blockTrials, maxNoResponseExceeded, currentDuration });
    } else {
      this.viewNextPromiseResolve();
    }
    this.viewNextPromise = this.getViewNextPromise();
  }

  async next() {
    this.taskController.taskStarted();

    for (;;) {
      await this.runPhases();

      if (this.taskController.mode === 'play') {
        break;
      }
    }

    this.done();
  }

  async runPhases() {
    const { showTrailer, debugRewards } = this.taskController;

    if (showTrailer) {
      this.viewController.goto('trailer');
      await this.viewNextPromise;

      if (this.taskController['aborted']) {
        return this.done();
      }
    }
    if (debugRewards) {
      this.lastProgress = 0;
      this.blockProgress = 0.9;
      this.taskController.addRewards(65);
      this.viewController.goto('block-feedback');
      await this.viewNextPromise;
    }

    const phases = this.taskController.getPhases();
    this.blockProgress = 0;
    const totalBlocks = this.taskController.totalBlocks;
    this.blockCount = 0;

    for (const phase of phases) {
      const { settings: phaseSettings } = phase;
      const { numberOfBlocks } = phaseSettings;

      let trialAdaptation;

      if (phaseSettings.type === 'adaptive') {
        this.taskController.setAdaptationLastRun();
        trialAdaptation = new TrialAdaptation(phaseSettings);
      }

      const phaseData = {
        startPhase: new Date(),
        type: phaseSettings.type,
        totalBlocks,
        numberOfBlocks,
      };

      await this.taskController.pushItem(phaseData);

      const blockResult = await this.runBlocks(phase, trialAdaptation);

      const endInfo = {
        endPhase: new Date(),
      };

      await this.taskController.pushItem(endInfo);

      if (phaseSettings.type === 'simple') {
        const meanAndSd = calculateMeanAndSD(this.blockTrials);

        this.taskController.setLastSimpleStats({ ...meanAndSd, date: new Date() });
      } else if (phaseSettings.type === 'adaptive') {
        this.taskController.setAdaptationLastAdaptive(this.phaseDurations);
      }

      if (blockResult?.maxNoResponseExceeded) {
        this.viewController.goto('end-game');
        await this.viewNextPromise;
        break;
      }

      if (this.taskController['aborted']) {
        break;
      }
    }
  }

  getPhaseDurations(phaseSettings) {
    const durations = { ...this.taskController.durations.stimulus };

    if (phaseSettings.type !== 'adaptive') {
      return durations;
    }

    const adaptation = this.taskController.taskProfile.adaptation;

    const adaptiveDurations = adaptation.adaptiveDurations;

    if (adaptiveDurations) {
      const adapted = {};

      for (const [key, value] of Object.entries(adaptiveDurations)) {
        const {
          multiplier: { duration },
          min,
          max,
        } = phaseSettings.adaptation[key];
        adapted[key] = Math.max(min, Math.min(duration * value, max));
      }

      return adapted;
    } else if (adaptation.lastSimpleStats) {
      const multiplier = phaseSettings.adaptation.multiplier;
      const { mean, sd } = adaptation.lastSimpleStats;

      const duration = mean + sd * multiplier;

      const goDuration = Math.max(phaseSettings.adaptation.go.min, Math.min(duration, phaseSettings.adaptation.go.max));
      const nogoDuration = Math.max(
        phaseSettings.adaptation.nogo.min,
        Math.min(duration, phaseSettings.adaptation.nogo.max),
      );
      return { go: goDuration, nogo: nogoDuration };
    }

    return durations;
  }

  /**
   * @param {SheepPhase} phase
   * @param {TrialAdaptation} trialAdaptation
   */
  async runBlocks(phase, trialAdaptation) {
    let durations = this.getPhaseDurations(phase.settings);

    this.phaseDurations = durations;

    for (const block of phase.blocks) {
      await this.taskController.pushItem({
        startBlock: new Date(),
        block: block.id,
        type: block.type,
      });

      this.viewController.goto('presenter', block, durations, phase.settings, trialAdaptation);
      this.requestUpdate();

      this.progressValue = 0;

      const { trialResponses, blockTrials, maxNoResponseExceeded, currentDuration } =
        /** @type {{trialResponses: [], blockTrials: any[], maxNoResponseExceeded: boolean, currentDuration: {}}}*/ (
          await this.viewNextPromise
        );

      if (currentDuration) {
        durations = currentDuration;
      }

      await this.taskController.pushItem({
        endBlock: new Date(),
      });

      this.trialResponses = trialResponses;
      this.blockTrials = blockTrials;

      this.blockCount += 1;
      this.lastProgress = this.blockProgress;
      this.blockProgress = this.blockCount / phase.totalBlocks;

      if (maxNoResponseExceeded) {
        return { maxNoResponseExceeded, id: block.id, isLastBlock: block.isLastBlock, blockTrials };
      }

      if (phase.type === 'practice' || phase.type === 'animation') {
        continue;
      }

      if (this.taskController['aborted']) {
        break;
      }

      const rewards = this.calculateRewards(trialResponses);

      this.taskController.addRewards(rewards);

      if (phase.showBlockFeedback) {
        this.viewController.goto('block-feedback', block);

        await this.viewNextPromise;

        if (this.taskController['aborted']) {
          break;
        }
      }
    }

    this.phaseDurations = durations;
    return {};
  }

  // const count = blockData.trials.reduce((p, v) => {
  //   if (v.response.length > 0 && v.response[1] && v.type === 'go') {
  //     p += 1;
  //   } else if (v.response.length === 0 || !v.response[1]) {
  //     p = p - 1;
  //   }

  //   return p;
  // }, 0);
  // return count;
  calculateRewards(responses) {
    let rewards = 0;
    for (const response of responses) {
      if (response.type === 'go' && response.isCorrect) {
        rewards += 1;
      } else if (response.isCorrect === false) {
        rewards -= 1;
      }
    }

    return rewards;
  }

  /**
   * @returns {Promise<({trialResponses: [], maxNoResponseExceeded: boolean}|{action:string})?>}
   */
  getViewNextPromise() {
    return new Promise(resolve => {
      this.viewNextPromiseResolve = resolve;
    });
  }

  onProgress({ target: { progressValue } }) {
    this.progressValue = progressValue;
  }

  async done() {
    await this.taskController.taskCompleted();
    this.dispatchEvent(new Event('next'));
  }

  /**
   * @param {any} items
   */
  async makeUIItems(items) {
    const uiItems = [];
    for (const [y, x, w, name, allbg = 1] of items) {
      const [key, value] = name.split(':', 2);

      const icon = graphics[key][value];

      uiItems.push({
        top: y,
        left: x,
        width: w,
        name,
        icon,
        allbg,
      });
    }

    return uiItems;
  }
}
const OncSheepGame = class OncSheepGame extends SheepGame {
  static styles = css`
    .sheep-layout {
      overflow: hidden;
    }
  `;
};
customElements.define('onc-sheep-game', OncSheepGame);

/**
 * @param {any[]} trialResults
 */
function calculateMeanAndSD(trialResults) {
  const goTrialResponseTimes = trialResults
    .filter(({ type }) => type === 'go')
    .map(({ response: { elapsed } }) => elapsed);

  const meanAndSd = sampleStandardDeviation(goTrialResponseTimes);

  return meanAndSd;
}
