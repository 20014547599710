import { ContextProvider } from '@lit/context';
import { Task } from '@lit/task';
import { shuffle } from '@ounce/onc';
import { api } from '@state/api.js';
import { DataStore } from '@state/data-store.js';
import { ResultsStore } from '@state/results-store.js';
import { Store } from '@state/store.js';
import { LitElement, css, html } from 'lit';
import seedrandom from 'seedrandom';
import { configureAndSetDefaultLocale } from '~/i18n/localization.js';
import { setupExtraStores } from '~/local/main-stores.js';
import roles from '~/roles.js';
import { AuthController } from './auth-controller.js';
import { loggerContext, resultsContext, storeContext, userContext } from './contexts.js';
import { customIpadBehaviour } from './ipad-custom.js';

export class AppRoot extends LitElement {
  static properties = { ready: { state: true } };

  constructor() {
    super();

    provider(this, loggerContext, 'logger');
    provider(this, storeContext, 'stores');

    this.logger = {
      log: message => {
        console.log(`[app-root] ${message}`);
      },
    };

    const rng = seedrandom();
    shuffle.setRng(rng);

    this.initStores = new Task(this, {
      task: async () => {
        const dataStore = new DataStore();
        const store = new Store();

        const { audioGraph } = await setupExtraStores();

        const stores = { dataStore, store, audioGraph };
        new ContextProvider(this, {
          context: resultsContext,
          initialValue: new ResultsStore(dataStore),
        });

        const userProvider = new ContextProvider(this, { context: userContext });

        this.stores = stores;

        this.authController = new AuthController(this, roles, user => {
          userProvider.setValue(user);
        });

        try {
          const { sourceLocale, targetLocales } = /** @type {{sourceLocale: String, targetLocales: string[]}}*/ (
            await api.get('/locale/list').json()
          );

          await configureAndSetDefaultLocale(sourceLocale, targetLocales);
        } catch (error) {
          console.log(error.toString());
        }
      },
      args: () => [],
    });
  }

  provide(host, context, name) {
    const provider = new ContextProvider(host, { context });

    let storage;

    Object.defineProperty(this, name, {
      get: function () {
        return storage;
      },
      set: function (value) {
        provider.setValue(value);
        storage = value;
      },
    });
  }

  connectedCallback() {
    super.connectedCallback();

    const appElement = document.querySelector('#app');
    appElement.remove();

    customIpadBehaviour();
  }

  render() {
    return this.initStores.render({
      pending: () => html`<div class="home-splash">Initialising...</div>`,
      complete: () => html`<onc-app></onc-app> `,
      error: error => html`<pre>${error.toString}</pre>`,
    });
  }
}

const OncAppRoot = class OncAppRoot extends AppRoot {
  static styles = css`
    .home-splash {
      visibility: hidden;
      opacity: 0;

      animation: fade-in 1s;
      animation-fill-mode: forwards;
      animation-delay: 1s;
    }

    @keyframes fade-in {
      0% {
        visibility: hidden;
        opacity: 0;
      }

      100% {
        visibility: visible;
        opacity: 1;
      }
    }
  `;
};

customElements.define('onc-app-root', OncAppRoot);

// ===
// Private functions
// ===

function provider(host, context, name) {
  const provider = new ContextProvider(host, { context });

  if (name) {
    let storage;

    Object.defineProperty(host, name, {
      get: function () {
        return storage;
      },
      set: function (value) {
        provider.setValue(value);
        storage = value;
      },
    });
  }

  return provider;
}
