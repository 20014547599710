import { html } from 'lit';

export const fence = html`
  <svg
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    viewBox="0 0 500 440"
  >
    <g fill="#897a5b">
      <path d="m79.54 440h-77.99v-397.5l38.5-36.25 39.49 36.25v397.5z" />
      <path d="m184.5 440h-77.92v-397.5l38.45-36.25 39.48 36.25v397.5z" />
      <path d="m288.3 440h-77.92v-397.5l38.45-36.25 39.48 36.25v397.5z" />
      <path d="m393.3 440h-77.99v-397.5l38.45-36.25 39.54 36.25v397.5z" />
      <path d="m498.3 440h-77.99v-397.5l38.5-36.25 39.49 36.25v397.5z" />
      <path d="m40.79 6.248v433.2h38.96v-397.5l-38.96-35.75z" />
      <path d="m145.8 6.248v433.2h38.96v-397.5l-38.96-35.75z" />
      <path d="m249.5 6.248v433.2h38.96v-397.5l-38.96-35.75z" />
      <path d="m354.5 6.248v433.2h39.02v-397.5l-39.02-35.75z" />
      <path d="m459.5 6.248v433.2h38.96v-397.5l-38.96-35.75z" />
      <path d="m79.54 168.7h-77.99v-21.81h77.99v21.81z" />
      <path d="m184.5 168.7h-77.92v-21.81h77.92v21.81z" />
      <path d="m288.3 168.7h-77.92v-21.81h77.92v21.81z" />
      <path d="m393.3 168.7h-77.99v-21.81h77.99v21.81z" />
      <path d="m498.3 168.7h-77.99v-21.81h77.99v21.81z" />
    </g>
    <path d="m498.3 153.7h-496.6v-21.82h496.6v21.82z" fill="#93856c" />
    <g fill="#afa28b">
      <path
        d="m45.79 142.5c0 2.712-2.192 4.906-4.906 4.906-2.712 0-4.905-2.194-4.905-4.906s2.192-4.906 4.905-4.906c2.714 0 4.906 2.194 4.906 4.906"
      />
      <path
        d="m150.8 142.5c0 2.712-2.192 4.906-4.906 4.906-2.712 0-4.905-2.194-4.905-4.906s2.192-4.906 4.905-4.906c2.714 0 4.906 2.194 4.906 4.906"
      />
      <path
        d="m254.5 142.5c0 2.712-2.192 4.906-4.906 4.906-2.712 0-4.905-2.194-4.905-4.906s2.192-4.906 4.905-4.906c2.714 0 4.906 2.194 4.906 4.906"
      />
      <path
        d="m359.5 142.5c0 2.712-2.134 4.906-4.906 4.906-2.654 0-4.846-2.194-4.846-4.906s2.192-4.906 4.846-4.906c2.772 0 4.906 2.194 4.906 4.906"
      />
      <path
        d="m464.5 142.5c0 2.712-2.192 4.906-4.906 4.906-2.712 0-4.905-2.194-4.905-4.906s2.192-4.906 4.905-4.906c2.714 0 4.906 2.194 4.906 4.906"
      />
    </g>
    <g fill="#897a5b">
      <path d="m79.54 355h-77.99v-21.79h77.99v21.79z" />
      <path d="m184.5 355h-77.92v-21.79h77.92v21.79z" />
      <path d="m288.3 355h-77.92v-21.79h77.92v21.79z" />
      <path d="m393.3 355h-77.99v-21.79h77.99v21.79z" />
      <path d="m498.3 355h-77.99v-21.79h77.99v21.79z" />
    </g>
    <path d="m498.3 340h-496.6v-21.79h496.6v21.79z" fill="#93856c" />
    <g fill="#afa28b">
      <path
        d="m45.79 330c0 2.684-2.192 4.876-4.906 4.876-2.712 0-4.905-2.192-4.905-4.876 0-2.712 2.192-4.935 4.905-4.935 2.714 0 4.906 2.222 4.906 4.935"
      />
      <path
        d="m150.8 330c0 2.684-2.192 4.876-4.906 4.876-2.712 0-4.905-2.192-4.905-4.876 0-2.712 2.192-4.935 4.905-4.935 2.714 0 4.906 2.222 4.906 4.935"
      />
      <path
        d="m254.5 330c0 2.684-2.192 4.876-4.906 4.876-2.712 0-4.905-2.192-4.905-4.876 0-2.712 2.192-4.935 4.905-4.935 2.714 0 4.906 2.222 4.906 4.935"
      />
      <path
        d="m359.5 330c0 2.684-2.134 4.876-4.906 4.876-2.654 0-4.846-2.192-4.846-4.876 0-2.712 2.192-4.935 4.846-4.935 2.772 0 4.906 2.222 4.906 4.935"
      />
      <path
        d="m464.5 330c0 2.684-2.192 4.876-4.906 4.876-2.712 0-4.905-2.192-4.905-4.876 0-2.712 2.192-4.935 4.905-4.935 2.714 0 4.906 2.222 4.906 4.935"
      />
    </g>
  </svg>
`;
