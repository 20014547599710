import { LitElement, css, html } from 'lit';
import { fetchUserDetails, signInWithFacebook } from './facebook-utils';

export class FacebookIdentity extends LitElement {
  static properties = {
    clientId: { type: String },
    appearance: { type: String },
  };
  constructor() {
    super();

    this.clientId = undefined;

    this.appearance = 'list';
  }

  get button() {
    return this.renderRoot?.querySelector('.g-btn');
  }

  render() {
    if (window.location.protocol === 'https:') {
      return html`
        <div class="provider" part="facebookContainer">
          <button class="facebook-btn" part="facebookButton" @click="${this.signIn}">
            <img part="facebookIcon" width="20px" height="20px" src="/media/identity/facebook-icon-list.svg" />
            Sign in with Facebook
          </button>
        </div>
      `;
    }
  }

  async signIn() {
    const idConfiguration = {
      client_id: this.clientId,
    };
    const response = await signInWithFacebook(idConfiguration);

    // console.log(response);

    const details = await fetchUserDetails(response);

    // console.log(details);

    this.dispatchEvent(
      new CustomEvent('credential', {
        detail: {
          credential: details,
        },
      }),
    );
  }

  // handleCredentialResponse(response) {
  //   console.log('Callback is called', response);
  //   const webToken = decodeCredential(response.credential);
  //   console.log('Response payload', webToken);
  //   console.log({
  //     email: webToken?.email,
  //     name: webToken?.name,
  //     imageUrl: webToken?.picture,
  //     accessToken: response.credential,
  //     accessTokenExpiration: new Date(webToken?.exp),
  //     provider: 'Google',
  //     error: undefined,
  //   });
  // }

  // firstUpdated() {
  //   if (window.location.protocol === 'https:') {
  //     const idConfiguration = {
  //       client_id: this.clientId,
  //       callback: data => this.handleCredentialResponse(data),
  //     };

  //     onConnected(idConfiguration);
  //   }
  // }

  connectedCallback() {
    super.connectedCallback();

    if (!this.clientId) {
      throw new Error('Prop client id required');
    }
  }
}

const OncFacebookIdentity = class OncFacebookIdentity extends FacebookIdentity {
  static styles = css`
    :host {
      display: inline-block;
    }

    .facebook-btn {
      display: flex;
      align-items: center;

      height: 40px;

      font-size: 14px;
      color: white;

      background-color: rgb(56 84 153);
      border-radius: 4px;
    }

    .facebook-btn img {
      margin-right: 4px;
    }

    .facebook-btn:hover {
      background-color: #314a86;
    }

    button {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    }
  `;
};
customElements.define('onc-facebook-identity', OncFacebookIdentity);
