import { ContextConsumer } from '@lit/context';
import { Task } from '@lit/task';
import { LitElement, html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { graphics } from '~/graphics/index.js';
import { chickenTaskContext } from './chicken-context.js';

const bgItems = [
  [3.42, 6.99, 7.23, 37.65, 'memory:waterTower'],
  [22.38, 29.7, 41.6, 57.09, 'app:straw'],
  [83.36, 23.98, 16.55, 25.5, 'app:tree'],
];

const _targetRatio = 16 / 10;
export class ChickenBlockFeedback extends LitElement {
  static properties = {
    showBorder: { type: Boolean },
    items: { state: true },
    layoutRect: { state: true },
    progress: { type: Number },
    lastProgress: { type: Number },
  };

  constructor() {
    super();

    // /** @type {Object[]} */
    this.items = [];
    this.layoutRect = {};
    this.soundController = undefined;
    this.state = 0;
    this.progress = 0;
    this.lastProgress = 0;
    this.showBorder = false;

    this.rewardsComplete = new Promise(resolve => (this.rewardsCompleteResolve = resolve));

    this.durations = {};

    new ContextConsumer(this, {
      context: chickenTaskContext,

      callback: value => (this.taskController = value),
    });

    this.getTrials = new Task(this, {
      task: async () => {
        this.bgItems = await this.layUIItems(bgItems);

        this.durations = this.taskController.durations;
        this.state = 0;
        this.next();
      },
      args: () => [],
    });
  }

  playSound(key) {
    return this.taskController.playSound(key);
  }

  async next() {
    this.state = 0;
  }

  async done() {
    await this.rewardsComplete;

    this.dispatchEvent(new Event('next'));
  }

  render() {
    return html`${this.getTrials.render({
      complete: () => {
        return html`
          <onc-layout-task showBorder=${this.showBorder} .targetRatio=${_targetRatio} @layout-rect=${this.onLayout}>
            ${this.renderGItems(this.bgItems)}${this.renderChickenRewards()}</onc-layout-task
          >
          ${this.renderTaskProgress()}
        `;
      },
    })}`;
  }

  onLayout({ detail: { innerRect } }) {
    this.layoutRect = { width: innerRect.width, height: innerRect.height, left: innerRect.left, top: innerRect.top };
  }

  /**
   * @param {any[]} gItems
   */
  renderGItems(gItems) {
    const border = this.taskController.showLayout;
    const items = gItems.map(
      item => html`<onc-game-item class=${classMap({ border })} .item=${item}>${item.icon}</onc-game-item>`,
    );
    return html` <div class="content">${items}</div> `;
  }

  renderChickenRewards() {
    const rewards = this.taskController.rewards;
    const showLayout = this.taskController.showLayout;
    return html`<onc-chicken-rewards
      .rewardItems=${rewards}
      ?showLayout=${showLayout}
      @next=${this.onRewards}
    ></onc-chicken-rewards>`;
  }

  renderTaskProgress() {
    const showLayout = this.taskController.showLayout;
    return html`
      <onc-chicken-task-progress
        @done=${this.onNext}
        .layoutRect=${this.layoutRect}
        .progress=${this.progress}
        ?showLayout=${showLayout}
        .lastProgress=${this.lastProgress}
      ></onc-chicken-task-progress>
    `;
  }

  onRewards() {
    this.rewardsCompleteResolve();
  }

  onNext() {
    this.done();
  }

  async layUIItems(items) {
    const uiItems = [];
    for (const [x, y, w, h, graphic] of items) {
      const [key, value] = graphic.split(':', 2);

      const icon = graphics[key][value];

      uiItems.push({
        top: y / 100,
        left: x / 100,
        width: w,
        height: h,
        icon,
      });
    }

    return uiItems;
  }
}
const OncChickenBlockFeedback = class OncChickenBlockFeedback extends ChickenBlockFeedback {};
customElements.define('onc-chicken-block-feedback', OncChickenBlockFeedback);
