import { ContextConsumer, ContextProvider } from '@lit/context';
import { Task } from '@lit/task';
import { ElapsedTimer } from '@ounce/onc';
import { LitElement, css, html, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { literal } from 'lit/static-html.js';
import { projectContext } from '~/app/contexts';
import { bg } from '~/graphics/bg.js';
import { graphics } from '~/graphics/index.js';
import { audio, chickensAudio } from './chicken-audio.js';
import { chickenTaskContext } from './chicken-context.js';
import { TaskController } from './task-controller';
import { ViewController } from './view-controller';

/**
 * @typedef {import('./specs/chicken-phase-setting.js').ChickenPhaseSetting} ChickenPhaseSetting
 * @typedef {import('./chicken-types.js').ChickenBlock} ChickenBlock
 * @typedef {[number, number, number, string, number?]} UiItem
 */

/**
 * @type {UiItem[]}
 */
const background = [
  [0.15, 0.8, 18, 'app:tree'],
  // [0.15, 0.05, 4, 'chicken:c31'],
];
const difficultyMax = 8;
const _targetRatio = 16 / 10;

export class ChickenGame extends LitElement {
  static properties = { taskId: { type: String }, block: { state: true }, progressValue: { state: true } };

  taskProvider = new ContextProvider(this, { context: chickenTaskContext });

  /**
   * @type {(value?:{trialResponses: [], maxNoResponseExceeded: boolean}|{action:string}) => void}
   */
  viewNextPromiseResolve;

  constructor() {
    super();
    this.fieldTag = literal`onc-help`;

    this.bgItems = [];
    this.taskId = '';
    this.block = undefined;
    this.taskController = undefined;
    this.progressValue = 0;
    this.lastProgress = 0;
    this.blockProgress = 0;
    this.choiceOnly = false;

    this.mode = 'play';

    new ContextConsumer(this, { context: projectContext, callback: value => (this.project = value) });

    this.viewNextPromise = this.getViewNextPromise();

    this.taskController = undefined;

    this.viewController = new ViewController(this, [
      {
        name: 'none',
        render: () => nothing,
      },
      { name: 'testx', render: () => this.renderBGItems() },
      {
        name: 'test',
        render: () => html` <onc-layout-task showBorder .targetRatio=${_targetRatio}> tester </onc-layout-task> `,
      },
      {
        name: 'trailer',
        render: () => html`
          <onc-task-trailer
            ?choiceOnly=${this.choiceOnly}
            @next=${this.onTrailerNext}
            .bgItems=${this.bgItems}
          ></onc-task-trailer>
        `,
      },

      {
        name: 'presenter',
        render: (block, phaseSettings) => {
          const showBorder = this.taskController.showLayout;
          return html`
            <onc-task-progress-bar class="progress-bar" .value=${this.progressValue}> </onc-task-progress-bar>
            <onc-layout-task class="chicken-layout" ?showBorder=${showBorder} .targetRatio=${_targetRatio}
              ><onc-chicken-presenter
                @next=${event => this.viewNext(event)}
                .block=${block}
                .phaseSettings=${phaseSettings}
                @progress=${this.onProgress}
              ></onc-chicken-presenter
            ></onc-layout-task>
          `;
        },
      },
      {
        name: 'block-feedback',
        render: block => {
          const showBorder = this.taskController.showLayout;
          return html`
            <onc-chicken-block-feedback
              ?showBorder=${showBorder}
              .progress=${this.blockProgress}
              .lastProgress=${this.lastProgress}
              @next=${this.viewNext}
              .block=${block}
            ></onc-chicken-block-feedback>
          `;
        },
      },
      {
        name: 'end-game',
        render: () => {
          const showBorder = this.taskController.showLayout;
          return html`
            <onc-layout-task ?showBorder=${showBorder} .targetRatio=${_targetRatio}>
              <onc-end-game ?showBorder=${showBorder} @next=${this.viewNext}></onc-end-game>
            </onc-layout-task>
          `;
        },
      },
    ]);

    this.getSounds = new Task(this, {
      task: async () => {
        this.bgItems = await this.makeUIItems(background);

        this.taskController = new TaskController(this, this.taskId);
        this.taskProvider.setValue(this.taskController);

        await this.taskController.createSounds(audio);

        const stimuli = {
          kind: this.taskController.taskProfile.kind,
          targets: this.taskController.taskProfile.targets,
        };

        await this.taskController.setupResults([{ start: new Date(), stimuli }]);

        this.responseTimer = new ElapsedTimer();

        this.next().catch(error => {
          if (error?.name !== 'AbortError') {
            console.log(error);
          }
        });
      },
      args: () => [],
    });
  }

  render() {
    return html`<onc-screen-svg>${bg}</onc-screen-svg>${this.getSounds.render({
        complete: () => html`${this.viewController.outlet()}`,
      })} `;
  }

  renderBGItems() {
    const border = false;
    const items = this.bgItems.map(
      item => html`<onc-game-item class=${classMap({ border })} .item=${item}>${item.icon}</onc-game-item>`,
    );
    return html` <div class="content">${items}</div> `;
  }

  /**
   * @param {number} difficulty
   */
  async prepareAudio(difficulty) {
    const key = `${difficulty}chickens`;
    const soundFile = chickensAudio[key];

    await this.taskController.createSound(key, [`/media/sounds/${soundFile}`], false);
  }

  /**
   * @param {CustomEvent & {target:{action: string}}} event
   */
  async onTrailerNext(event) {
    const action = event.target.action;

    this.taskController.mode = action;
    this.choiceOnly = true;

    this.viewNextPromiseResolve({ action });

    this.viewNextPromise = this.getViewNextPromise();
  }

  /**
   * @param {Event&{target: {trialResponses: [], maxNoResponseExceeded: boolean}}} event
   */
  async viewNext(event) {
    if (event) {
      const {
        target: { trialResponses, maxNoResponseExceeded },
      } = event;
      this.viewNextPromiseResolve({ trialResponses, maxNoResponseExceeded });
    } else {
      this.viewNextPromiseResolve();
    }
    this.viewNextPromise = this.getViewNextPromise();
  }

  async next() {
    this.taskController.taskStarted();

    for (;;) {
      await this.runPhases();

      if (this.taskController.mode === 'play') {
        break;
      }
    }

    this.done();
  }

  async runPhases() {
    const { showTrailer, debugRewards } = this.taskController;
    if (showTrailer) {
      this.viewController.goto('trailer');
      await this.viewNextPromise;

      if (this.taskController['aborted']) {
        return this.done();
      }
    }
    if (debugRewards) {
      this.lastProgress = 0;
      this.blockProgress = 0.9;

      this.taskController.addRewards(44);
      this.viewController.goto('block-feedback');
      await this.viewNextPromise;
    }

    // this.viewController.goto('end-game');
    // await this.viewNextPromise;

    const phases = this.taskController.getPhases();
    this.blockProgress = 0;
    const totalBlocks = this.taskController.totalBlocks;
    this.blockCount = 0;

    for (const { phaseSettings, blocks } of phases) {
      this.difficulty = this.setInitialDifficulty(phaseSettings);
      const { numberOfBlocks } = phaseSettings;

      await this.prepareAudio(this.difficulty);

      if (phaseSettings.type === 'adaptive') {
        this.taskController.setAdaptationLastRun();
      }

      const phaseData = {
        startPhase: new Date(),
        type: phaseSettings.type,
        totalBlocks,
        numberOfBlocks,
      };

      await this.taskController.pushItem(phaseData);

      const blockResult = await this.runBlocks(blocks, phaseSettings, totalBlocks);

      const endInfo = {
        endPhase: new Date(),
      };

      await this.taskController.pushItem(endInfo);

      if (phaseSettings.type === 'adaptive') {
        this.taskController.setAdaptationLastAdaptive(this.difficulty);
      }

      if (blockResult?.maxNoResponseExceeded) {
        this.viewController.goto('end-game');
        await this.viewNextPromise;
        break;
      }

      if (this.taskController['aborted']) {
        break;
      }
    }
  }

  /**
   * @param {ChickenBlock[]} blocks
   * @param {ChickenPhaseSetting} phaseSettings
   * @param {number} totalBlocks
   */
  async runBlocks(blocks, phaseSettings, totalBlocks) {
    for (const block of blocks) {
      this.block = {
        ...block,
        difficulty: this.difficulty,
        maxNoResponseTrials: phaseSettings.maxNoResponseTrials,
      };

      this.viewController.goto('presenter', this.block, phaseSettings);
      this.requestUpdate();

      this.progressValue = 0;

      if (this.taskController['aborted']) {
        break;
      }

      const { trialResponses, maxNoResponseExceeded } =
        /** @type {{trialResponses: [], maxNoResponseExceeded: boolean}}*/ (await this.viewNextPromise);

      this.trialResponses = trialResponses;

      this.blockCount += 1;
      this.lastProgress = 0; // this.blockProgress;
      this.blockProgress = this.blockCount / totalBlocks;

      if (maxNoResponseExceeded) {
        return { maxNoResponseExceeded, id: block.id };
      }

      if (phaseSettings.type === 'practice') {
        continue;
      }

      const nextDifficulty = this.adaptDifficulty(phaseSettings, this.difficulty, trialResponses);

      // calculate score

      this.difficulty = nextDifficulty;

      await this.prepareAudio(this.difficulty);

      const rewards = this.calculateRewards(trialResponses);

      this.taskController.addRewards(rewards);

      if (phaseSettings['showBlockFeedback']) {
        this.viewController.goto('block-feedback', block);

        await this.viewNextPromise;

        if (this.taskController['aborted']) {
          break;
        }
      }
    }
  }

  /**
   *
   * @param {{isCorrect: Boolean}[]} responses
   * @returns
   */
  calculateRewards(responses) {
    let rewards = 0;
    for (const response of responses) {
      if (response.isCorrect) {
        rewards += 1;
      }
    }

    return rewards;
  }

  /**
   * @param {{isCorrect: Boolean}[]} trialResponses
   */
  calculateRatioCorrect(trialResponses) {
    let correct = 0;

    for (const { isCorrect } of trialResponses) {
      isCorrect && (correct += 1);
    }

    return correct / trialResponses.length;
  }

  /**
   * @param {ChickenPhaseSetting} phaseSettings
   */
  setInitialDifficulty(phaseSettings) {
    if (phaseSettings.type !== 'adaptive') {
      return phaseSettings.initialDifficulty;
    }

    const savedDifficulty = this.taskController.taskProfile.adaptation.difficulty;

    if (savedDifficulty === undefined) {
      return phaseSettings.initialDifficulty;
    }

    return Math.min(difficultyMax, Math.max(1, savedDifficulty + phaseSettings.difficultyChange));
  }

  /**
   * @param {ChickenPhaseSetting} phaseSettings
   * @param {number} difficulty
   * @param {any[]} trialResponses
   */
  adaptDifficulty(phaseSettings, difficulty, trialResponses) {
    if (phaseSettings.type === 'simple') {
      return difficulty;
    }

    const { increaseThreshold, increaseDelta, decreaseThreshold, decreaseDelta, maxDifficulty } = phaseSettings;

    const ratioCorrect = this.calculateRatioCorrect(trialResponses);

    if (ratioCorrect >= increaseThreshold) {
      difficulty = Math.ceil(Math.min(maxDifficulty, difficulty + increaseDelta));
    } else if (ratioCorrect <= decreaseThreshold) {
      difficulty = Math.floor(Math.max(1, difficulty - decreaseDelta));
    }

    return difficulty;
  }

  /**
   * @returns {Promise<({trialResponses: [], maxNoResponseExceeded}|{action:string})?>}
   */
  getViewNextPromise() {
    return new Promise(resolve => {
      this.viewNextPromiseResolve = resolve;
    });
  }

  onProgress({ target: { progressValue } }) {
    this.progressValue = progressValue;
  }

  async done() {
    await this.taskController.taskCompleted();

    this.dispatchEvent(new Event('next'));
  }

  /**
   * @param {UiItem[]} items
   */
  async makeUIItems(items) {
    const uiItems = [];
    for (const [y, x, w, name, allbg = 1] of items) {
      const [key, value] = name.split(':', 2);

      const icon = graphics[key][value];

      uiItems.push({
        top: y,
        left: x,
        width: w,
        name,
        icon,
        allbg,
      });
    }

    return uiItems;
  }
}

const OncChickenGame = class OncChickenGame extends ChickenGame {
  static styles = css`
    :host {
      overflow: hidden;
      display: block;
      width: 100%;
      height: 100%;
    }

    .content {
      width: 100%;
      height: 100%;
    }

    .chicken-layout {
      overflow: hidden;
    }
  `;
};
customElements.define('onc-chicken-game', OncChickenGame);
