import { html } from 'lit';

export const windmill = html`
  <svg
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    viewBox="0 0 260 750"
  >
    <path d="m128.8 130.5-116.6-48.43l1.511-3.595 116.6 48.39-1.512 3.6z" fill="#60453e" />
    <path d="m128.8 130.5-1.511-3.595 116.6-48.38 1.475 3.588-116.5 48.39z" fill="#60453e" />
    <g fill="#68433a">
      <path d="m181.3 289h-103.6v-20.76h103.6v20.76z" />
      <path d="m200 458.7h-137.5v-20.76h137.5v20.76z" />
      <path d="m222.6 628.6h-183v-20.72h183v20.72z" />
      <path
        d="m146.3 128.6c0 9.236-7.479 16.68-16.68 16.68-9.236 0-16.71-7.436-16.71-16.68 0-9.236 7.479-16.68 16.71-16.68 9.196 0 16.68 7.439 16.68 16.68"
      />
    </g>
    <path
      d="m130 48.96c-43.94 0-79.65 35.76-79.65 79.7 0 43.89 35.72 79.65 79.65 79.65 43.94 0 79.7-35.76 79.7-79.65 0-43.96-35.76-79.72-79.7-79.72m0 162.2c-45.48 0-82.5-37-82.5-82.6 0-45.52 37.02-82.6 82.55-82.6 45.52 0 82.6 37.08 82.6 82.6s-37.08 82.55-82.6 82.55"
      fill="#513027"
    />
    <path
      d="m130 82.08c-26.11 0-47.36 21.25-47.36 47.36s21.25 47.38 47.36 47.38 47.38-21.25 47.38-47.38c0-26.1-21.2-47.4-47.4-47.4m0 97.59c-27.71 0-50.26-22.52-50.26-50.22 0-27.75 22.55-50.31 50.26-50.31s50.28 22.56 50.28 50.31c0 27.71-22.56 50.22-50.28 50.22"
      fill="#513027"
    />
    <path d="m123.3 147.6v602.4h15.48v-602.4h-15.48z" fill="#7f7b79" />
    <path d="m96.2 277.4-65.11 472.6h15.65l61.62-447.5v-23.45l-12.16-1.686z" fill="#68433a" />
    <path d="m163.8 277.4-15.36 2.123 64.82 470.5h15.65l-65.11-472.6z" fill="#68433a" />
    <g fill="#aaa">
      <path d="m136.3 80.2h-11.16l-6.946-78.05h25.05l-6.95 78.05z" />
      <path d="m136.3 178.2h-11.16l-6.946 78.02h25.05l-6.95-78.02z" />
      <path d="m81.28 123.6v11.16l-78.05 6.95v-25.05l78.05 6.938z" />
      <path d="m178.8 123.6v11.16l78.02 6.95v-25.05l-78 6.8z" />
      <path d="m100 90.58-7.889 7.926-60.08-50.26 17.74-17.74 50.22 60.08z" />
      <path d="m168.8 160-7.846 7.846 50.22 60.11 17.74-17.69-60.11-50.28z" />
      <path d="m91.28 160 7.889 7.846-50.22 60.11-17.74-17.69 60.08-50.28z" />
      <path d="m161.3 90.58 7.846 7.926 60.11-50.26-17.74-17.74-50.22 60.08z" />
      <path d="m116.3 81.96-10.3 4.33-36.81-69.11 23.04-9.765 24.07 74.54z" />
      <path d="m155 172.1-10.26 4.415 24.08 74.55 23.05-9.81-36.86-69.15z" />
      <path d="m82.53 143.2 4.331 10.26-69.1 36.86-9.812-23.05 74.59-24.08z" />
      <path d="m173.8 105 4.331 10.3 74.55-24.08-9.762-23.09-69.11 36.86z" />
      <path d="m87.53 105.7-4.168 10.34-74.96-22.8 9.4-23.21 69.72 35.68z" />
      <path d="m177.6 142.5-4.21 10.3 69.76 35.72 9.399-23.25-74.95-22.78z" />
      <path d="m106.3 172.5 10.38 4.21-22.85 74.95-23.21-9.4 35.68-69.76z" />
      <path d="m143.8 81.7 10.34 4.169 35.71-69.72-23.25-9.4-22.8 74.95z" />
    </g>
    <g fill="#8e8e8e">
      <path d="m130 2.206v78.05h5.968l6.945-78.05h-12.91z" />
      <path d="m148.8 83.7 5.311 2.126 35.71-69.72-10.79-4.362-30.24 71.96z" />
      <path d="m221.3 39.96-55.95 54.35 4.089 4.168 60.11-50.25-8.25-8.262z" />
      <path d="m178.8 128.6v6.169l78.02 6.956v-13.12h-78.02z" />
      <path d="m176.3 147.7-2.042 5.026 69.76 35.72 4.862-12.1-72.59-28.65z" />
      <path d="m111.3 84.08 4.984-2.126-24.08-74.54-10.82 4.575 29.91 72.09z" />
      <path d="m100 90.58-50.23-60.08-9.47 9.48 56.06 54.28 3.64-3.68z" />
      <path d="m13.78 80.2 71.89 30.38 1.96-4.9-69.73-35.69-4.12 10.21z" />
      <path d="m81.28 123.6-78.05-6.948v11.94l78.05 0.6v-5.6z" />
      <path d="m85.03 148.2-2.084-4.946-74.58 24.08 4.65 10.91 72.01-30.04z" />
      <path d="m95.03 163.7-3.762-3.72-60.08 50.28 8.256 8.215 55.58-54.76z" />
      <path d="m165 164-3.758 3.758 50.22 60.11 8.662-8.662-55.12-55.21z" />
      <path d="m111.3 174.4-4.579-1.879-35.68 69.76 12.55 5.068 27.7-72.95z" />
      <path d="m130 178.2h-5.599l-6.951 78.02h12.55v-78.02z" />
      <path d="m151.3 173.8-6.374 2.738 24.08 74.55 11.89-5.068-29.6-72.22z" />
      <path d="m247.6 80.58-72.54 28.81 2.494 5.846 74.55-24.08-4.5-10.59z" />
    </g>
    <path
      d="m88.78 451.4 41.8-95.4 41.9 91.6-43 59.6-40.7-55.8zm23.5-172.9 17.74-101.8 17.7 101.8-17.3 39.6-18.1-39.6zm97.39 338.8-70.58-96.85 48.51-67.44-6.294-4.495 7.068-3.226-49.4-108.3 19.16-43.56 7.684-1.348-27.95-160.5 0.2462-1.552-0.5312-0.08-0.41-2.535-7.152 1.225-7.151-1.225-0.4475 2.535-0.5325 0.08 0.2438 1.552-27.91 160.6 7.888 1.375 19.9 43.65-49.2 112.1 7.11 3.105-6.252 4.579 46.3 63.55-69.69 96.86 12.55 9.031 66.74-92.69 67.6 92.78 12.5-9.158z"
      fill="#68433a"
    />
    <path
      opacity=".5"
      d="m130.7 112v638h6.945v-121.6h58.64l16.74 121.6h15.65l-16.73-121.5h9.443v-20.72h-12.34l-20.55-149.1h10.95v-20.76h-13.81l-20.51-149.1h15.9v-20.76h-19.65l-21.98-126.4c3.962-3.062 6.535-7.761 6.535-13.16 0-8.745-6.7-15.77-15.24-16.55zm6.924 109.9 8.051 46.13h-8.051v-46.13zm0 66.89h5.23l-5.23 11.89v-11.89zm14.3 17.91 11.64 84.66 0.004 0.0117-24.88-54.55 13.23-30.12zm-14.3 65.04 30.16 66.12h-30.16v-66.12zm0 86.88h26.4l-26.4 36.7v-36.7zm36.89 11.97 16.72 121.6-52.43-71.95 35.71-49.61zm-36.89 74.29 45.81 62.84h-45.81v-62.84z"
      fill="#68433a"
    />
  </svg>
`;
