import { msg } from '@lit/localize';
import { LitElement, css, html } from 'lit';
import { Auth } from '~/app/auth';
import { validate as emailValidate } from '~/validators/email.js';

export class CheckAccount extends LitElement {
  static properties = {
    accountMessages: { state: true },
    subject: { type: String },
    titleText: { type: String },
  };

  constructor() {
    super();

    this.titleText = undefined;

    /** @type {string | boolean | string[]} */
    this.accountMessages = [];

    this.subject = '';
  }

  createRenderRoot() {
    return this;
  }

  render() {
    return html`
      <h1 class="lsi-headline4">${this.titleText ?? msg('Sign in')}</h1>

      <div class="sign-in-field">
        <md-filled-text-field
          .value=${this.subject}
          @input=${this.onInput}
          id="email"
          class="sign-in-input"
          name="email"
          autocomplete="username"
          @blur=${this.onEmailBlur}
          @focus=${this.onEmailFocus}
          @keyup=${this.onEnter}
          .label=${msg('Email')}
        >
        </md-filled-text-field>

        <onc-form-message .messages=${this.accountMessages}></onc-form-message>
      </div>

      <div class="sign-in-row">
        <md-filled-button @click=${this.checkAccountStatus} class="">${msg('Continue')}</md-filled-button>
      </div>
    `;
  }

  onInput({ target: { value } }) {
    this.subject = value;
  }

  /**
   *
   * @param {Event&{key: string,target:{value: string, }}} event
   */
  onEnter(event) {
    event.preventDefault();

    const {
      key,
      target: { value },
    } = event;
    if (key === 'Enter' && value) {
      this.checkAccountStatus();
    }
  }

  /**
   *
   * @param {Event} [event]
   */
  async checkAccountStatus(event) {
    event?.preventDefault();
    const { status } = await Auth.accountStatus(this.subject);

    if (status === 'none') {
      const { messages } = getMessageForError('noSuchUser');
      this.accountMessages = messages;
    } else {
      this.dispatchEvent(new CustomEvent('next', { detail: { status, username: this.subject } }));
    }
  }

  /**
   *
   * @param {Event&{target:{value: string}}} event
   */
  onEmailBlur(event) {
    event.preventDefault();
    const {
      target: { value },
    } = event;
    const messages = emailValidate([value]);
    this.accountMessages = messages;
  }

  onEmailFocus() {
    this.accountMessages = [];
  }
}

const OncCheckAccount = class OncCheckAccount extends CheckAccount {
  static styles = css`
    md-filled-text-field {
      --md-sys-color-surface-variant: #f5f5f5;
    }

    .sign-in-row md-filled-button {
      --md-sys-color-primary: var(--onc-signin-color, black);
      --md-filled-button-container-shape: 9px;

      width: 50%;
    }

    .sign-in-field {
      margin-bottom: 16px;
    }

    .sign-in-input {
      --md-filled-text-field-trailing-icon-color: cornflowerblue;

      width: 100%;
      padding-top: 16px;

      .mdc-textfield {
        width: 100%;
      }
    }

    .mdc-typography-headline4 {
      font-family: Manrope, Roboto, sans-serif;
      font-size: 2.125rem;
      font-weight: 400;
      line-height: 2.5rem;
      text-decoration: inherit;
      text-transform: inherit;
      letter-spacing: 0.0074em;

      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
    }
  `;
};

customElements.define('onc-check-account', OncCheckAccount);

// ===
// Private functions
// ===

/**
 * @type {{[x:string]: ()=> string | string[]}}
 */
const keyMap_ = {
  unknown: () => [msg(`Sorry, it looks like something’s not working right now. Please try again in a few minutes.`)],
  noSuchUser: () => msg(`Sorry, we can’t find an account with that email.`),
};

/**
 * @param {string} lookupKey
 */
function getMessageForError(lookupKey) {
  const item = (keyMap_[lookupKey] ?? keyMap_['unknown'])();

  return { messages: Array.isArray(item) ? item : [item] };
}
