import { Sound } from '@audio/sound';
import { ContextConsumer } from '@lit/context';
import { storeContext } from '~/app/contexts';

/**
 * @typedef {import('lit').ReactiveControllerHost} ReactiveControllerHost
 * @typedef {import('lit').ReactiveElement} ReactiveElement
 */

export class SoundController {
  host;

  /**
   * @type {AbortController}
   */
  abortController;

  /**
   * @type { Map<string, Sound>}
   */
  sounds = new Map();

  /**
   *
   * @param {ReactiveControllerHost &  ReactiveElement} host
   */
  constructor(host) {
    (this.host = host).addController(this);

    new ContextConsumer(host, { context: storeContext, callback: ({ audioGraph }) => (this.audioGraph = audioGraph) });
  }

  async createSounds(options, base = '/media/sounds/') {
    for (const [key, value] of Object.entries(options)) {
      if (!value) {
        continue;
      }
      await this.createSound(key, [`${base}${value}`]);
    }
  }

  /**
   * @param {string} key
   * @param {string | string[]} path
   * @param {Object} options
   */
  async createSound(key, path, options) {
    const sound = await Sound.createAndLoad(this.audioGraph.context, path, options);

    this.sounds.set(key, sound);

    return sound;
  }

  /**
   * @param {Sound|string} soundOrKey
   */
  playSound(soundOrKey, options = {}) {
    const when = this.audioGraph.context.currentTime + 0.01; // offset to ensure sounds start concurrently

    const sound = typeof soundOrKey === 'string' ? this.sounds.get(soundOrKey) : soundOrKey;
    if (!this.abortController) {
      this.abortController = new AbortController();
    }

    const playOptions = { when, ...options, signal: this.abortController.signal };

    return sound.play(playOptions);
  }

  stop(promise) {
    Sound.stop(promise);
  }

  hostConnected() {}

  hostDisconnected() {
    this.abortController?.abort('disconnected');
  }
}
