/**
 * @typedef { import('./record-types.js').PhaseRecord}  PhaseRecord
 */

export class MemoryPhaseSetting {
  /**
   *
   * @param {PhaseRecord} phaseRecord
   */
  constructor(phaseRecord) {
    const {
      showBlockFeedback = 'no',
      numberOfBlocks,
      trialsPerBlock,
      maxNoResponseTrials,
      type = 'main',
    } = phaseRecord;

    this.showBlockFeedback = showBlockFeedback === 'yes' ? true : false;
    this.numberOfBlocks = numberOfBlocks;
    this.trialsPerBlock = trialsPerBlock;
    this.maxNoResponseTrials = maxNoResponseTrials;
    this.type = type;
  }
}
