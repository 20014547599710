import { html } from 'lit';

export const boots = html`
  <svg
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    viewBox="0 0 380 570"
  >
    <path
      d="m134.9 491.4h218.6s12.96-5.004 0-98.7c-13.68-98.58 21.14-254 0-382.5-21.38 0.25-211.2 2.326-243 0 12.28 181.9 87.89 312.1 48.56 381.7-39.21 69.82-24.19 99.5-24.19 99.5"
      fill="#c68722"
    />
    <path
      d="m19.95 470.1c19.54-29.55 107.5-3.665 135.1-70.36 23.56-56.92 168.4 46.05 168.4 46.05s48.25 61.19 30.29 75.11c-176.2-16.6-164 42.52-333.8 23.22-18.32-4.9-19.55-44.6 0-74.02"
      fill="#c68722"
    />
    <path
      d="m226.7 515.7c-2.314-7.33-3.54-15.02-3.54-22.96 0-41.89 127.8-141.9 130.2-100 4.04 67.79 21.14 58.15 21.14 100 0 7.942-3.79 28.48-6.104 35.68l-141.8-12.71z"
      fill="#755825"
    />
    <g fill="#3a2610">
      <path
        d="m59.07 560.7c41.78 0 84.78-7.08 126.9-14.18 64.86-10.74 125.9-20.76 179.5-6.354l6.341-23.45c-58.62-15.88-125.2-4.75-189.8 5.862-58.38 9.775-118.7 19.79-172.1 9.538l-4.5 23.94c17.6 3.299 35.42 4.638 53.62 4.638"
      />
      <path d="m271.7 569.9h98.94v-51.3h-98.94v51.3z" />
      <path d="m176.6 569.9h21.25v-30.79h-21.25v30.79z" />
      <path d="m133.9 569.9h21.25v-30.79h-21.25v30.79z" />
      <path d="m91.45 569.9h21.25v-30.79h-21.25v30.79z" />
      <path d="m48.7 569.9h21.38v-30.79h-21.38v30.79z" />
      <path d="m6.197 569.9h21.25v-25.28l-21.25-12.1v37.38z" />
      <path
        opacity=".2"
        d="m374.2 492.8c0-33.22-10.76-34.2-17.22-66.92-0.975-9.656-2.201-20.52-3.915-33.11-13.68-98.58 21.14-254 0-382.5-9.769 0.25-54.6 0.7388-103.4 0.9762v526c7.33-0.975 14.79-1.951 21.99-2.564v35.3h98.94v-50.32l0.7262-2.94h-0.3638c1.711-8.7 3.3-18.85 3.3-23.98"
      />
    </g>
  </svg>
`;
