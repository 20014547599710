import { msg, str } from '@lit/localize';
import { LitElement, html } from 'lit';
import { productName, version } from '~/version.js';

export class About extends LitElement {
  static properties = {
    open: { type: Boolean },
  };

  constructor() {
    super();
    /**
     * Opens the dialog when set to `true` and closes it when set to `false`.
     */
    this.open = false;
  }

  render() {
    return html`
      <md-dialog ?open=${this.open} @closed=${this.onClosed}>
        <div slot="headline">${msg(str`About ${productName}`)}</div>
        <form id="form" method="dialog" slot="content">
          <div class="content">
            <div>${msg(str`Site version: ${version}`)}</div>
            <section>${msg(html`about-content`)}</section>
          </div>
        </form>
        <div slot="actions">
          <md-text-button tabindex="0" form="form" value="accept"
            >${msg('Close', { id: 'sabout-close', desc: 'about close button text' })}</md-text-button
          >
        </div>
      </md-dialog>
    `;
  }

  show() {
    this.open = true;
  }

  onClosed() {
    this.open = false;
    this.dispatchEvent(new Event('closed'));
  }
}

const OncAbout = class OncAbout extends About {};
customElements.define('onc-about', OncAbout);
