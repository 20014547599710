import { objectID } from '@ounce/onc';
import { clone } from '@state/clone.js';
import ky from 'ky';
import { Queue } from './background-sync/queue.js';
import { setupDatabase } from './data-database.js';

const responseTimeout_ = 3000;
const rcInitialDelay_ = 3000;

let database;

export class DataStore {
  constructor() {
    this.count = 0;
    this.status = 'up';
    this.uploadUrl = '';

    this.queue = new Queue('DataQueue');

    database = setupDatabase();
    this.setStatusUp();
  }

  get name() {
    return 'data';
  }

  setStatusUp = () => {
    this.status = 'up';
    this.uploadNow();
  };

  uploadNow = async () => {
    // if we are already uploading or know we cant, do nothing
    if (uploading_ || this.status !== 'up') {
      return;
    }
    // note we are uploading and get lsit of items
    uploading_ = true;

    try {
      const keys = await database.keys();
      this.count = keys.length;

      if (keys.length > 0) {
        await processKeys(keys, database, () => this.incrementCount(-1));
      }
      uploading_ = false;

      // if there are any more, try and upload them
      if (this.count > 0) {
        this.uploadNow();
      }
    } catch (error) {
      // if we get here an upload failed
      // so we will go into the waiting state and retry
      uploading_ = false;
      console.dir(error.toString());

      this.setWaitingAndRetry({ delay: rcInitialDelay_ });
    }
  };

  incrementCount = (n = 1) => {
    this.count -= n;
    if (this.count < 0) {
      this.count = 0;
    }
  };

  setWaitingAndRetry = ({ delay }) => {
    this.status = 'waiting';

    setTimeout(() => {
      this.checkComms({ delay });
    }, delay);
  };

  checkComms = async ({ delay }) => {
    this.status = 'checking';
    try {
      await ky.get(testUrl_());
      this.setStatusUp();
      this.setStatusUp();
    } catch {
      this.status = 'waiting';
      // 1.5 * current delay capped at 1hour
      delay = Math.min(Math.ceil(delay * 1.5), 3_600_000);
      setTimeout(() => {
        this.checkComms({ delay });
      }, delay);
    }
  };

  saveEx = async ({ token, value }) => {
    const item = {
      id: objectID().toString(),
      type: 'media',
      token,
      createDate: new Date(),
      value,
    };

    await database.set(item.id, item);

    // update counter and trigger an upload
    this.incrementCount(1);
    this.uploadNow();

    return item.id;
  };

  save = async ({ token, value }) => {
    const rawValue = clone(value);

    const item = {
      id: objectID().toString(),
      token,
      createDate: new Date(),
      value: rawValue,
    };

    await postData(item, this.queue);

    return item.id;
  };

  setUploadUrl = newValue => {
    this.uploadUrl = newValue;
  };
}

// ===
// Private functions
// ===

async function postData(data, queue) {
  const {
    id,
    createDate,
    token,
    value: { uploadurl, method = 'post', ...dataValue },
  } = data;

  const dataurl = uploadurl ?? '/api/data';
  const request = new Request(dataurl, {
    headers: { 'content-type': 'application/json' },
    method,

    body: JSON.stringify({ id, token, createDate, value: dataValue }),
  });
  try {
    await ky(request, { timeout: responseTimeout_ });
  } catch (error) {
    await queue.pushRequest({ request });
    console.dir(error);
    throw error;
  }
}

let uploading_;

const testUrl_ = () => {
  return `/favicon.ico?_=${Math.floor(Math.random() * 1_000_000_000)}`;
};

async function processKey(key, database) {
  const data = await database.get(key);
  if (!data.value) {
    data.value = {};
  }

  const {
    id,
    createDate,
    token,
    value: { uploadurl, method = 'post', ...dataValue },
  } = data;

  if (data.type === 'media') {
    const mediaurl = uploadurl ?? '/api/dataaudio';
    const formData = new FormData();

    formData.append('id', id);
    formData.append('createDate', createDate);
    for (const [key, value] of Object.entries(dataValue)) {
      formData.append(key, value);
    }

    return ky(mediaurl, {
      timeout: responseTimeout_,
      method,
      headers: {
        Authorization: `Bearer: ${token}`,
      },
      body: formData,
    });
  }

  const dataurl = uploadurl ?? '/api/data';

  return ky(dataurl, {
    timeout: responseTimeout_,
    method,
    json: { id, token, createDate, value: dataValue },
  });
}

async function processKeys(keys, database, onProcessed) {
  if (!keys || keys.length === 0) {
    keys = [];
  }

  for await (const v of keys) {
    await processKey(v, database);
    await database.delete(v);
    await onProcessed();
  }
}
