const config = {
  library: 'https://connect.facebook.net/en_US/sdk.js',
};

export const loadApiPromise = new Promise((resolve, reject) => {
  const fb = window.FB;
  if (fb) {
    return resolve();
  }
  const scriptElement = window.document.createElement('script');
  scriptElement.async = true;
  scriptElement.src = config.library;
  scriptElement.addEventListener('load', () => resolve());

  scriptElement.addEventListener('error', error => reject(error));
  window.document.head.append(scriptElement);
});

export async function onConnected(idConfiguration) {
  await loadApiPromise;

  console.log('loaded fb');

  const response = await initFacebookSdk(idConfiguration.client_id);

  console.log(response);

  const signinResponse = await signInWithFacebookSdk(response);

  console.log(signinResponse);
}

export async function signInWithFacebook(idConfiguration) {
  await loadApiPromise;

  // console.log('loaded fb');

  const initResponse = await initFacebookSdk(idConfiguration.client_id);

  return signInWithFacebookSdk(initResponse);
}

// ===
// Private functions
// ===

function initFacebookSdk(clientId) {
  if (!window.FB) {
    return Promise.reject("Couldn't find window.FB");
  }

  console.log(clientId);
  window.FB.init({
    appId: clientId,
    version: 'v6.0',
    // xfbml: true,
    // cookie: true,
    // frictionlessRequests: true,
  });

  // console.log(window.FB);

  return new Promise(resolve => window.FB.getLoginStatus(response => resolve(response)));
}

function signInWithFacebookSdk(statusResponse) {
  // Speed through if we're already signed in to FB.
  if (statusResponse.status === 'connected') {
    // window.FB.logout(() => console.log('logged out'));
    return Promise.resolve(statusResponse);
  }

  return new Promise(resolve => {
    window.FB.login(loginResponse => resolve(loginResponse), { scope: 'email' });
  });
}

export function fetchUserDetails(statusResponse) {
  if (statusResponse.status !== 'connected') {
    return Promise.reject({
      message: 'Facebook sign in failed and may have been cancelled by the user.',
      status: statusResponse.status,
    });
  }

  const authResponse = statusResponse.authResponse;
  return new Promise(resolve => {
    const requestArguments = {
      fields: 'name, email',
    };

    window.FB.api('/me', requestArguments, userDetails => {
      resolve(getSignInResultFromUserDetails(userDetails, authResponse));
    });
  });
}

function getSignInResultFromUserDetails(userDetails, authResponse) {
  if (!userDetails?.email) {
    throw new Error(
      "Facebook sign-in succeeded, but the resulting user details didn't contain an email. User details: " +
        JSON.stringify(userDetails),
    );
  }

  // authResponse.expiresIn is specified in seconds from now.
  const expiresInSeconds = authResponse.expiresIn;
  const expiration = new Date();
  expiration.setSeconds(expiresInSeconds || 10_000);

  return {
    email: userDetails.email,
    name: userDetails.name,
    error: undefined,
    accessToken: authResponse?.accessToken,
    accessTokenExpiration: expiration,
    provider: 'Facebook',
    providerData: {
      user: userDetails,
      auth: authResponse,
    },
  };
}
