export class SheepPhase {
  /**
   *
   * @typedef {import('./sheep-types.js').SheepBlock} SheepBlock
   * @typedef {import('./specs/sheep-phase-setting.js').SheepPhaseSetting} SheepPhaseSetting
   *
   * @param {Object} param0
   * @param {Number} param0.index
   * @param {SheepPhaseSetting} param0.phaseSettings
   * @param {SheepBlock[]} param0.blocks
   * @param {Boolean} param0.isLastPhase
   * @param {Number} totalBlocks
   */
  constructor({ index, phaseSettings, blocks, isLastPhase = false }, totalBlocks) {
    this.settings = phaseSettings;
    this.totalBlocks = totalBlocks;
    this.index = index;
    this.isLastPhase = isLastPhase;
    this.blocks_ = blocks;
  }

  get type() {
    return this.settings.type;
  }

  get showBlockFeedback() {
    return this.settings.type === 'practice' ? true : this.settings.showBlockFeedback;
  }

  get blocks() {
    return this.blocks_;
  }
}
