import { html } from 'lit';

export const fencepathNarrowLong = html`
  <svg
    version="1.1"
    viewBox="0 0 1934.7 258.72"
    preserveAspectRatio="xMidYMin slice"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m0.1875 258.72h1934.5v-128.87h-1934.5z" fill="#bfbda6" stroke-width="1.3138" />
    <g stroke-width="1.333">
      <path
        d="m6.5137 176.79c-1.5901-0.0283-2.6578 0.73808-6.3262 1.6992v27.193l3.7363-2.0918 11.73-23.941c-5.525-2.06-7.5983-2.8319-9.1406-2.8594z"
        fill="#cf6e0d"
      />
      <path d="m19.596 130.04-19.596 0.45508v34.963c0.72864-0.4577 1.5872-1.0507 2.3359-1.5781z" fill="#a6523b" />
      <path d="m94.76 207.15 6.085-3.555 9.952-23.94c-18.16-6.592 0 0-42.62 2.001l-11.37 26.15z" fill="#cf6e0d" />
      <path
        d="m54.55 169.87c18.29-0.0707 24.22 3.37 38.62-3.85 0.7571-0.3799 2.075-1.346 3.137-2.135l14.74-33.84-39.74 0.9238z"
        fill="#a6523b"
      />
      <path
        d="m25.41 222.08-11.45 23.86c5.628 10.79 26.69 7.833 40.71 4.808l14.08-33.01c-11.44-4.083-28.55-5.979-43.35 4.328"
        fill="#cf6e0d"
      />
      <path
        d="m20.25 181.6-10.67 21.9c1.261 3.528 0.1413 4.555 4.211 6.816l35.04-1.742 14.48-32.42c-13.49-4.468-27.83-7.617-43.06 5.444"
        fill="#d68747"
      />
      <path d="m2.9605 251.4 2.75-0.2 15.18-32.69-17.93-1.59z" fill="#a6523b" />
      <path d="m192.96 209.13 5.823-3.554 8.145-23.95c-18.69-6.592 0 0-42.52 2.001l-9.396 26.15z" fill="#cf6e0d" />
      <path
        d="m149.86 171.47c18.3-0.0707 24.51 3.37 38.36-3.85 0.7292-0.3799 1.976-1.346 2.978-2.135l12.19-33.84-39.71 0.9238z"
        fill="#a6523b"
      />
      <path
        d="m124.76 223.7-9.643 23.86c6.456 10.79 27.31 7.833 41.12 4.808l11.58-33.01c-11.77-4.082-29.05-5.977-43.07 4.33"
        fill="#cf6e0d"
      />
      <path
        d="m116.36 183.63-9.014 21.9c1.532 3.528 0.4879 4.555 4.737 6.816l34.96-1.744 12.02-32.42c-13.86-4.468-28.46-7.617-42.71 5.444"
        fill="#d68747"
      />
      <path d="m63.9 249.1 3.073 6.69 40.23-2.933 12.71-32.69-35.32-3.11-5.931 0.5719z" fill="#c97317" />
      <path
        d="m19.01 139.34-12.25 23.86c4.607 10.79 24.55 7.833 37.92 4.808l15.22-33.01c-10.47-4.082-26.39-5.977-40.9 4.33"
        fill="#cf6e0d"
      />
      <path
        d="m110.66 138.28-10.49 23.86c5.393 10.79 25.11 7.833 38.24 4.808l12.8-33.01c-10.76-4.083-26.81-5.979-40.55 4.328"
        fill="#cf6e0d"
      />
      <path
        d="m213.16 185.2-7.354 21.9c1.804 3.528 0.8371 4.555 5.263 6.816l34.87-1.742 9.563-32.42c-14.21-4.468-29.07-7.617-42.35 5.444"
        fill="#d68747"
      />
      <path d="m165.76 250.6 3.586 6.69 40.06-2.933 10.23-32.69-35.6-3.11-5.895 0.5719z" fill="#c97317" />
      <path
        d="m203.96 140.01-8.682 23.86c6.224 10.79 25.74 7.833 38.66 4.808l10.3-33.01c-11.09-4.083-27.3-5.979-40.27 4.328"
        fill="#cf6e0d"
      />
      <path d="m295.26 209.91 5.547-3.555 6.269-23.95c-19.22-6.59 0 0-42.4 2.002l-7.346 26.15z" fill="#cf6e0d" />
      <path
        d="m250.76 170.14c18.28-0.0707 24.75 3.37 38.03-3.85 0.6998-0.3799 1.868-1.346 2.807-2.135l9.519-33.84-39.6 0.9238z"
        fill="#a6523b"
      />
      <path
        d="m228.26 224.35-7.774 23.86c7.306 10.79 27.94 7.833 41.52 4.808l8.998-33.01c-12.1-4.082-29.54-5.977-42.75 4.33"
        fill="#cf6e0d"
      />
      <path d="m393.66 207.15 5.272-3.555 4.479-23.94c-19.66-6.592 0 0-42.16 2.001l-5.392 26.15z" fill="#cf6e0d" />
      <path
        d="m344.86 169.87c18.26-0.0707 24.99 3.37 37.74-3.85 0.6705-0.3799 1.768-1.346 2.649-2.135l7.012-33.84-39.52 0.9238z"
        fill="#a6523b"
      />
      <path
        d="m327.66 222.08-5.99 23.86c8.097 10.79 28.47 7.833 41.82 4.808l6.532-33.01c-12.38-4.083-29.93-5.979-42.36 4.328"
        fill="#cf6e0d"
      />
      <path
        d="m313.26 181.6-5.661 21.9c2.069 3.528 1.182 4.555 5.769 6.816l34.66-1.742 7.057-32.42c-14.52-4.468-29.58-7.617-41.82 5.444"
        fill="#d68747"
      />
      <path d="m270.86 247.4 4.09 6.69 39.74-2.933 7.707-32.69-35.75-3.109-5.836 0.5705z" fill="#c97317" />
      <path d="m490.86 207.15 5.008-3.555 2.698-23.94c-20.15-6.592 0 0-42.02 2.001l-3.447 26.15z" fill="#cf6e0d" />
      <path
        d="m439.36 169.87c18.26-0.0707 25.25 3.37 37.44-3.85 0.6425-0.3799 1.668-1.346 2.49-2.135l4.496-33.84-39.46 0.9238z"
        fill="#a6523b"
      />
      <path
        d="m426.06 222.08-4.216 23.86c8.898 10.79 29.06 7.833 42.18 4.808l4.079-33.01c-12.68-4.083-30.37-5.979-42.03 4.328"
        fill="#cf6e0d"
      />
      <path
        d="m408.56 181.6-4.034 21.9c2.33 3.528 1.521 4.555 6.277 6.816l34.52-1.742 4.648-32.42c-14.85-4.468-30.14-7.617-41.42 5.444"
        fill="#d68747"
      />
      <path d="m370.96 247.4 4.587 6.69 39.51-2.933 5.277-32.69-35.99-3.109-5.793 0.5705z" fill="#a6523b" />
      <path d="m589.66 209.13 4.747-3.554 0.8891-23.95c-20.69-6.592 0 0-41.92 2.001l-1.472 26.15z" fill="#cf6e0d" />
      <path
        d="m535.26 171.47c18.28-0.0707 25.53 3.37 37.2-3.85 0.6145-0.3799 1.568-1.346 2.331-2.135l1.941-33.84-39.43 0.9238z"
        fill="#a6523b"
      />
      <path
        d="m525.76 223.7-2.414 23.86c9.726 10.79 29.69 7.833 42.59 4.808l1.586-33.01c-13.01-4.082-30.86-5.977-41.76 4.33"
        fill="#cf6e0d"
      />
      <path
        d="m505.36 183.63-2.379 21.9c2.602 3.528 1.869 4.555 6.802 6.816l34.44-1.744 2.199-32.42c-15.21-4.468-30.77-7.617-41.06 5.444"
        fill="#d68747"
      />
      <path d="m472.66 249.1 5.099 6.69 39.35-2.933 2.809-32.69-36.27-3.11-5.757 0.5719z" fill="#c97317" />
    </g>
    <g fill="#cf6e0d" stroke-width="1.333">
      <path
        d="m306.06 137.88-6.728 23.86c7.093 10.79 26.34 7.833 38.99 4.808l7.595-33.01c-11.4-4.083-27.74-5.979-39.86 4.328"
      />
      <path
        d="m394.56 139.34-5.017 23.86c7.877 10.79 26.94 7.833 39.38 4.808l5.231-33.01c-11.71-4.082-28.21-5.977-39.59 4.33"
      />
      <path
        d="m485.86 138.28-3.261 23.86c8.662 10.79 27.49 7.833 39.7 4.808l2.802-33.01c-12-4.083-28.62-5.979-39.24 4.328"
      />
    </g>
    <path
      d="m584.56 170.14c18.26-0.0707 25.63 3.37 37.02-3.85 0.5998-0.3799 1.514-1.346 2.246-2.135l0.6278-33.84-39.36 0.9238z"
      fill="#a6523b"
      stroke-width="1.333"
    />
    <g fill="#cf6e0d" stroke-width="1.333">
      <path
        d="m576.26 224.35-1.505 23.86c10.14 10.79 29.99 7.833 42.79 4.808l0.3279-33.01c-13.17-4.082-31.1-5.977-41.62 4.33"
      />
      <path d="m737.16 207.15 4.338-3.555-1.813-23.94c-21.39-6.592 0 0-41.63 2.001l1.48 26.15z" />
      <path d="m638.76 207.15 4.606-3.555-0.012-23.94c-20.9-6.592 0 0-41.79 2.001l-0.4879 26.15z" />
    </g>
    <g stroke-width="1.333">
      <path
        d="m678.66 169.87c18.25-0.0707 25.87 3.37 36.72-3.85 0.5705-0.3799 1.414-1.346 2.087-2.135l-1.88-33.84-39.28 0.9238z"
        fill="#a6523b"
      />
      <path
        d="m675.16 222.08 0.2799 23.86c10.93 10.79 30.53 7.833 43.08 4.808l-2.138-33.01c-13.45-4.083-31.5-5.979-41.22 4.328"
        fill="#cf6e0d"
      />
      <path
        d="m650.16 181.6 0.0933 21.9c2.995 3.528 2.379 4.555 7.559 6.816l34.19-1.742-1.46-32.42c-15.69-4.468-31.58-7.617-40.39 5.444"
        fill="#d68747"
      />
      <path d="m624.96 247.4 5.848 6.69 38.96-2.933-0.8798-32.69-36.58-3.109-5.685 0.5705z" fill="#c97317" />
      <path d="m834.36 207.15 4.074-3.555-3.594-23.94c-21.89-6.592 0 0-41.48 2.001l3.424 26.15z" fill="#cf6e0d" />
      <path
        d="m773.06 169.87c18.24-0.0707 26.13 3.37 36.43-3.85 0.5425-0.3799 1.314-1.346 1.929-2.135l-4.395-33.84-39.22 0.9238z"
        fill="#a6523b"
      />
      <path
        d="m773.46 222.08 2.053 23.86c11.73 10.79 31.11 7.833 43.43 4.808l-4.591-33.01c-13.76-4.083-31.94-5.979-40.9 4.328"
        fill="#cf6e0d"
      />
      <path
        d="m745.46 181.6 1.721 21.9c3.258 3.528 2.718 4.555 8.067 6.816l34.06-1.742-3.868-32.42c-16.02-4.468-32.15-7.617-39.98 5.444"
        fill="#d68747"
      />
      <path d="m725.16 247.4 6.344 6.69 38.75-2.933-3.31-32.69-36.8-3.109-5.643 0.5705z" fill="#a6523b" />
      <path d="m933.66 209.13 3.812-3.554-5.403-23.95c-22.42-6.592 0 0-41.4 2.001l5.4 26.15z" fill="#cf6e0d" />
      <path
        d="m869.26 171.47c18.26-0.0707 26.42 3.37 36.19-3.85 0.5145-0.3799 1.214-1.346 1.77-2.135l-6.952-33.84-39.19 0.9238z"
        fill="#a6523b"
      />
      <path
        d="m873.66 223.7 3.855 23.86c12.56 10.79 31.74 7.833 43.86 4.808l-7.085-33.01c-14.08-4.082-32.43-5.977-40.62 4.33"
        fill="#cf6e0d"
      />
      <path
        d="m842.66 183.63 3.375 21.9c3.528 3.528 3.066 4.555 8.594 6.816l33.98-1.744-6.317-32.42c-16.38-4.468-32.77-7.617-39.63 5.444"
        fill="#d68747"
      />
      <path d="m827.26 249.1 6.857 6.69 38.58-2.933-5.777-32.69-37.08-3.11-5.608 0.5719z" fill="#c97317" />
    </g>
    <g fill="#cf6e0d" stroke-width="1.333">
      <path
        d="m631.46 137.88-0.4572 23.86c9.928 10.79 28.39 7.833 40.24 4.808l-1.074-33.01c-12.47-4.083-29.31-5.979-38.71 4.328"
      />
      <path
        d="m720.26 139.34 1.252 23.86c10.71 10.79 28.99 7.833 40.64 4.808l-3.44-33.01c-12.78-4.082-29.78-5.977-38.46 4.33"
      />
      <path
        d="m811.46 138.28 3.009 23.86c11.5 10.79 29.54 7.833 40.96 4.808l-5.868-33.01c-13.07-4.083-30.19-5.979-38.1 4.328"
      />
    </g>
    <g stroke-width="1.333">
      <path
        d="m940.36 185.2 5.035 21.9c3.8 3.528 3.414 4.555 9.119 6.816l33.88-1.742-8.775-32.42c-16.74-4.468-33.39-7.617-39.27 5.444"
        fill="#d68747"
      />
      <path d="m929.96 250.6 7.37 6.69 38.39-2.933-8.255-32.69-37.36-3.11-5.569 0.5719z" fill="#c97317" />
      <path
        d="m905.66 140.01 4.817 23.86c12.33 10.79 30.18 7.833 41.38 4.808l-8.369-33.01c-13.4-4.083-30.69-5.979-37.83 4.328"
        fill="#cf6e0d"
      />
      <path d="m1036 209.91 3.536-3.555-7.278-23.95c-22.94-6.59 0 0-41.27 2.002l7.45 26.15z" fill="#cf6e0d" />
      <path
        d="m969.56 170.14c18.24-0.0707 26.66 3.37 35.86-3.85 0.4839-0.3799 1.106-1.346 1.598-2.135l-9.626-33.84-39.08 0.9238z"
        fill="#a6523b"
      />
      <path
        d="m977.66 224.35 5.725 23.86c13.41 10.79 32.37 7.833 44.24 4.808l-9.67-33.01c-14.41-4.082-32.91-5.977-40.3 4.33"
        fill="#cf6e0d"
      />
      <path d="m1133 207.15 3.26-3.555-9.067-23.94c-23.39-6.592 0 0-41.03 2.001l9.403 26.15z" fill="#cf6e0d" />
      <path
        d="m1063 169.87c18.22-0.0707 26.9 3.37 35.55-3.85 0.4559-0.3799 1.006-1.346 1.441-2.135l-12.13-33.84-39 0.9238z"
        fill="#a6523b"
      />
      <path
        d="m1076 222.08 7.509 23.86c14.2 10.79 32.9 7.833 44.54 4.808l-12.14-33.01c-14.69-4.083-33.31-5.979-39.91 4.328"
        fill="#cf6e0d"
      />
      <path
        d="m1039 181.6 6.729 21.9c4.064 3.528 3.759 4.555 9.624 6.816l33.67-1.742-11.28-32.42c-17.05-4.468-33.88-7.617-38.74 5.444"
        fill="#d68747"
      />
      <path d="m1033 247.4 7.874 6.69 38.07-2.933-10.78-32.69-37.51-3.109-5.513 0.5705z" fill="#c97317" />
      <path d="m1231 207.15 2.997-3.555-10.85-23.94c-23.89-6.592 0 0-40.88 2.001l11.35 26.15z" fill="#cf6e0d" />
      <path
        d="m1158 169.87c18.22-0.0707 27.15 3.37 35.27-3.85 0.4265-0.3799 0.9051-1.346 1.281-2.135l-14.65-33.84-38.94 0.9238z"
        fill="#a6523b"
      />
      <path
        d="m1174 222.08 9.282 23.86c15.01 10.79 33.48 7.833 44.9 4.808l-14.58-33.01c-14.98-4.083-33.75-5.979-39.59 4.328"
        fill="#cf6e0d"
      />
      <path
        d="m1134 181.6 8.357 21.9c4.327 3.528 4.098 4.555 10.13 6.816l33.54-1.742-13.69-32.42c-17.38-4.468-34.46-7.617-38.34 5.444"
        fill="#d68747"
      />
      <path d="m1134 247.4 8.371 6.69 37.86-2.933-13.21-32.69-37.75-3.109-5.471 0.5705z" fill="#a6523b" />
      <path d="m1330 209.13 2.735-3.554-12.66-23.95c-24.42-6.592 0 0-40.79 2.001l13.32 26.15z" fill="#cf6e0d" />
      <path
        d="m1255 171.47c18.24-0.0707 27.43 3.37 35.03-3.85 0.3986-0.3799 0.8052-1.346 1.122-2.135l-17.21-33.84-38.91 0.9238z"
        fill="#a6523b"
      />
      <path
        d="m1275 223.7 11.09 23.86c15.84 10.79 34.11 7.833 45.31 4.808l-17.08-33.01c-15.32-4.082-34.24-5.977-39.31 4.33"
        fill="#cf6e0d"
      />
      <path
        d="m1232 183.63 10.01 21.9c4.598 3.528 4.446 4.555 10.66 6.816l33.46-1.744-16.14-32.42c-17.74-4.468-35.08-7.617-37.99 5.444"
        fill="#d68747"
      />
      <path d="m1236 249.1 8.884 6.69 37.68-2.933-15.68-32.69-38.03-3.11-5.435 0.5719z" fill="#c97317" />
    </g>
    <g fill="#cf6e0d" stroke-width="1.333">
      <path
        d="m1007 137.88 6.772 23.86c13.2 10.79 30.77 7.833 41.71 4.808l-11.07-33.01c-13.72-4.083-31.13-5.979-37.4 4.328"
      />
      <path
        d="m1096 139.34 8.482 23.86c13.98 10.79 31.37 7.833 42.1 4.808l-13.44-33.01c-14.02-4.082-31.58-5.977-37.14 4.33"
      />
      <path
        d="m1187 138.28 10.24 23.86c14.77 10.79 31.91 7.833 42.42 4.808l-15.86-33.01c-14.3-4.083-31.99-5.979-36.79 4.328"
      />
    </g>
    <g stroke-width="1.333">
      <path
        d="m1330 185.2 11.67 21.9c4.868 3.528 4.795 4.555 11.18 6.816l33.36-1.742-18.6-32.42c-18.1-4.468-35.7-7.617-37.62 5.444"
        fill="#d68747"
      />
      <path
        d="m1281 140.01 12.05 23.86c15.6 10.79 32.55 7.833 42.83 4.808l-18.37-33.01c-14.64-4.083-32.5-5.979-36.51 4.328"
        fill="#cf6e0d"
      />
      <path
        d="m1349 169.87c18.21-0.0707 27.66 3.37 34.68-3.85 0.3692-0.3799 0.7025-1.346 0.9597-2.135l-19.76-33.84-38.79 0.9238z"
        fill="#a6523b"
      />
    </g>
    <g fill="#8a7a5c" stroke-width="1.336">
      <path d="m708.15 129.99h-23.25v-119.1l11.48-10.86 11.77 10.86z" />
      <path d="m739.25 129.99h-23.23v-119.1l11.46-10.86 11.77 10.86z" />
      <path d="m770.45 129.99h-23.23v-119.1l11.46-10.86 11.77 10.86z" />
      <path d="m801.65 129.99h-23.25v-119.1l11.46-10.86 11.79 10.86z" />
      <path d="m832.85 129.99h-23.25v-119.1l11.48-10.86 11.77 10.86z" />
    </g>
    <g fill="#706142" stroke-width="1.336">
      <path d="m708.15 104.49h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
      <path d="m739.25 104.49h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
      <path d="m770.45 104.49h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
      <path d="m801.65 104.49h-11.63v25.4h11.63zm0-55.85h-11.63v44.84h11.63zm-11.63-48.55v37.54h11.63v-26.82z" />
      <path d="m832.85 104.49h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
    </g>
    <g fill="#8a7a5c" stroke-width="1.336">
      <path d="m864.05 129.99h-23.25v-119.1l11.48-10.86 11.77 10.86z" />
      <path d="m895.35 129.99h-23.23v-119.1l11.46-10.86 11.77 10.86z" />
      <path d="m926.55 129.99h-23.23v-119.1l11.46-10.86 11.77 10.86z" />
      <path d="m957.75 129.99h-23.25v-119.1l11.46-10.86 11.79 10.86z" />
      <path d="m988.95 129.99h-23.25v-119.1l11.48-10.86 11.77 10.86z" />
    </g>
    <g fill="#706142" stroke-width="1.336">
      <path d="m864.05 104.49h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
      <path d="m895.35 104.49h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
      <path d="m926.55 104.49h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
      <path d="m957.75 104.49h-11.63v25.4h11.63zm0-55.85h-11.63v44.84h11.63zm-11.63-48.55v37.54h11.63v-26.82z" />
      <path d="m988.95 104.49h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
    </g>
    <g fill="#8a7a5c" stroke-width="1.336">
      <path d="m1020.2 129.99h-23.25v-119.1l11.48-10.86 11.77 10.86z" />
      <path d="m1051.2 129.99h-23.23v-119.1l11.46-10.86 11.77 10.86z" />
      <path d="m1082.2 129.99h-23.23v-119.1l11.46-10.86 11.77 10.86z" />
      <path d="m1114.2 129.99h-23.25v-119.1l11.46-10.86 11.79 10.86z" />
      <path d="m1145.2 129.99h-23.25v-119.1l11.48-10.86 11.77 10.86z" />
    </g>
    <g fill="#706142" stroke-width="1.336">
      <path
        d="m1020.2 100.09h-0.164v4.478h-11.45v25.4h11.62zm0-55.85h-0.164v4.478h-11.45v44.84h11.62zm-11.62-44.08v37.54h11.62v-26.82z"
      />
      <path
        d="m1051.2 100.09h-0.164v4.478h-11.45v25.4h11.62zm0-55.85h-0.164v4.478h-11.45v44.84h11.62zm-11.62-44.08v37.54h11.62v-26.82z"
      />
      <path
        d="m1082.2 100.09h-0.164v4.478h-11.45v25.4h11.62zm0-55.85h-0.164v4.478h-11.45v44.84h11.62zm-11.62-44.08v37.54h11.62v-26.82z"
      />
      <path
        d="m1114.2 100.09h-0.164v4.478h-11.47v25.4h11.63zm0-55.85h-0.164v4.478h-11.47v44.84h11.63zm-11.63-44.08v37.54h11.63v-26.82z"
      />
      <path
        d="m1145.2 100.09h-0.164v4.478h-11.45v25.4h11.62zm0-55.85h-0.164v4.478h-11.45v44.84h11.62zm-11.62-44.08v37.54h11.62v-26.82z"
      />
    </g>
    <g fill="#8a7a5c" stroke-width="1.336">
      <path d="m1176.2 129.99h-23.25v-119.1l11.48-10.86 11.77 10.86z" />
      <path d="m1207.2 129.99h-23.23v-119.1l11.46-10.86 11.77 10.86z" />
      <path d="m1238.2 129.99h-23.23v-119.1l11.46-10.86 11.77 10.86z" />
      <path d="m1270.2 129.99h-23.25v-119.1l11.46-10.86 11.79 10.86z" />
      <path d="m1301.2 129.99h-23.25v-119.1l11.48-10.86 11.77 10.86z" />
    </g>
    <g fill="#706142" stroke-width="1.336">
      <path d="m1176.2 104.49h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
      <path d="m1207.2 104.49h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
      <path d="m1238.2 104.49h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
      <path d="m1270.2 104.49h-11.63v25.4h11.63zm0-55.85h-11.63v44.84h11.63zm-11.63-48.55v37.54h11.63v-26.82z" />
      <path d="m1301.2 104.49h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
    </g>
    <g stroke-width="1.336">
      <path d="m1332.2 129.99h-23.25v-119.1l11.48-10.86 11.77 10.86z" fill="#8a7a5c" />
      <path d="m1364.2 129.99h-23.23v-119.1l11.46-10.86 11.77 10.86z" fill="#8a7a5c" />
      <path
        d="m1332.2 104.49h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z"
        fill="#706142"
      />
      <path
        d="m1364.2 104.49h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z"
        fill="#706142"
      />
    </g>
    <path d="m103.96 44.264h1830.6v-6.539h-1830.6z" fill="#94856b" stroke-width="2.1732" />
    <path d="m103.84 100.09h1830.8v-6.529h-1830.8z" fill="#94856b" stroke-width="2.1734" />
    <g stroke-width="1.336">
      <path d="m696.55 44.264h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m708.15 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m708.15 42.254h-23.25v2.057h23.25z" fill="#78694f" />
      <path d="m727.75 44.264h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m739.25 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m739.25 42.254h-23.23v2.057h23.23z" fill="#78694f" />
      <path d="m758.85 44.264h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m770.45 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m770.45 42.254h-23.23v2.057h23.23z" fill="#78694f" />
      <path d="m790.05 44.264h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m801.65 44.264h-11.63v4.478h11.63z" fill="#5c4c33" />
      <path d="m801.65 42.254h-23.25v2.057h23.25z" fill="#78694f" />
      <path d="m821.25 44.264h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m832.85 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m832.85 42.254h-23.25v2.057h23.25z" fill="#78694f" />
    </g>
    <g fill="#b0a38c" stroke-width="1.336">
      <path
        d="m697.95 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m729.15 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m760.35 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m791.55 41.044c0 0.8131-0.6358 1.469-1.462 1.469-0.7918 0-1.445-0.6565-1.445-1.469s0.6532-1.469 1.445-1.469c0.8265 0 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m822.75 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
    </g>
    <g stroke-width="1.336">
      <path d="m696.55 100.09h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m708.15 100.09h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m708.15 98.114h-23.25v2.048h23.25z" fill="#78694f" />
      <path d="m727.75 100.09h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m739.25 100.09h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m739.25 98.114h-23.23v2.048h23.23z" fill="#78694f" />
      <path d="m758.85 100.09h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m770.45 100.09h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m770.45 98.114h-23.23v2.048h23.23z" fill="#78694f" />
      <path d="m790.05 100.09h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m801.65 100.09h-11.63v4.478h11.63z" fill="#5c4c33" />
      <path d="m801.65 98.114h-23.25v2.048h23.25z" fill="#78694f" />
      <path d="m821.25 100.09h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m832.85 100.09h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m832.85 98.114h-23.25v2.048h23.25z" fill="#78694f" />
    </g>
    <g fill="#b0a38c" stroke-width="1.336">
      <path
        d="m697.95 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
      />
      <path
        d="m729.15 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
      />
      <path
        d="m760.35 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
      />
      <path
        d="m791.55 96.904c0 0.8038-0.6358 1.461-1.462 1.461-0.7918 0-1.445-0.6578-1.445-1.461 0-0.8131 0.6532-1.479 1.445-1.479 0.8265 0 1.462 0.6658 1.462 1.479"
      />
      <path
        d="m822.75 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
      />
    </g>
    <g stroke-width="1.336">
      <path d="m852.45 44.264h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m864.05 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m864.05 42.254h-23.25v2.057h23.25z" fill="#78694f" />
      <path d="m883.65 44.264h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m895.35 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m895.35 42.254h-23.23v2.057h23.23z" fill="#78694f" />
      <path d="m914.85 44.264h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m926.55 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m926.55 42.254h-23.23v2.057h23.23z" fill="#78694f" />
      <path d="m946.05 44.264h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m957.75 44.264h-11.63v4.478h11.63z" fill="#5c4c33" />
      <path d="m957.75 42.254h-23.25v2.057h23.25z" fill="#78694f" />
      <path d="m977.35 44.264h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m988.95 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m988.95 42.254h-23.25v2.057h23.25z" fill="#78694f" />
    </g>
    <g fill="#b0a38c" stroke-width="1.336">
      <path
        d="m853.95 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m885.15 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m916.35 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m947.55 41.044c0 0.8131-0.6358 1.469-1.462 1.469-0.7918 0-1.445-0.6565-1.445-1.469s0.6532-1.469 1.445-1.469c0.8265 0 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m978.85 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
    </g>
    <g stroke-width="1.336">
      <path d="m852.45 100.09h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m864.05 100.09h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m864.05 98.114h-23.25v2.048h23.25z" fill="#78694f" />
      <path d="m883.65 100.09h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m895.35 100.09h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m895.35 98.114h-23.23v2.048h23.23z" fill="#78694f" />
      <path d="m914.85 100.09h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m926.55 100.09h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m926.55 98.114h-23.23v2.048h23.23z" fill="#78694f" />
      <path d="m946.05 100.09h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m957.75 100.09h-11.63v4.478h11.63z" fill="#5c4c33" />
      <path d="m957.75 98.114h-23.25v2.048h23.25z" fill="#78694f" />
      <path d="m977.35 100.09h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m988.95 100.09h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m988.95 98.114h-23.25v2.048h23.25z" fill="#78694f" />
    </g>
    <g fill="#b0a38c" stroke-width="1.336">
      <path
        d="m853.95 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
      />
      <path
        d="m885.15 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
      />
      <path
        d="m916.35 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
      />
      <path
        d="m947.55 96.904c0 0.8038-0.6358 1.461-1.462 1.461-0.7918 0-1.445-0.6578-1.445-1.461 0-0.8131 0.6532-1.479 1.445-1.479 0.8265 0 1.462 0.6658 1.462 1.479"
      />
      <path
        d="m978.85 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
      />
    </g>
    <g stroke-width="1.336">
      <path d="m996.75 48.814h0.1626v-4.478h-0.1626z" fill="#d1c9bd" />
      <path d="m1008.2 44.264h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1020.2 44.264h-11.45v4.478h11.45z" fill="#5c4c33" />
      <path d="m1020.2 42.254h-23.25v2.057h23.25z" fill="#78694f" />
      <path d="m1028.2 48.814h0.164v-4.478h-0.164z" fill="#d1c9bd" />
      <path d="m1040.2 44.264h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m1051.2 44.264h-11.45v4.478h11.45z" fill="#5c4c33" />
      <path d="m1051.2 42.254h-23.23v2.057h23.23z" fill="#78694f" />
      <path d="m1059.2 48.814h0.164v-4.478h-0.164z" fill="#d1c9bd" />
      <path d="m1071.2 44.264h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m1082.2 44.264h-11.45v4.478h11.45z" fill="#5c4c33" />
      <path d="m1082.2 42.254h-23.23v2.057h23.23z" fill="#78694f" />
      <path d="m1090.2 48.814h0.164v-4.478h-0.164z" fill="#d1c9bd" />
      <path d="m1102.2 44.264h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m1113.2 44.264h-11.47v4.478h11.47z" fill="#5c4c33" />
      <path d="m1113.2 42.254h-23.25v2.057h23.25z" fill="#78694f" />
      <path d="m1121.2 48.814h0.1626v-4.478h-0.1626z" fill="#d1c9bd" />
      <path d="m1133.2 44.264h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1145.2 44.264h-11.45v4.478h11.45z" fill="#5c4c33" />
      <path d="m1145.2 42.254h-23.25v2.057h23.25z" fill="#78694f" />
    </g>
    <g fill="#b0a38c" stroke-width="1.336">
      <path
        d="m1010.2 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m1041.2 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m1072.2 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m1103.2 41.044c0 0.8131-0.6358 1.469-1.462 1.469-0.7918 0-1.445-0.6565-1.445-1.469s0.6532-1.469 1.445-1.469c0.8265 0 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m1135.2 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
    </g>
    <g stroke-width="1.336">
      <path d="m996.75 104.49h0.1626v-4.478h-0.1626z" fill="#d1c9bd" />
      <path d="m1028.2 104.49h0.164v-4.478h-0.164z" fill="#d1c9bd" />
      <path d="m1040.2 100.09h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m1051.2 100.09h-11.45v4.478h11.45z" fill="#5c4c33" />
      <path d="m1051.2 98.114h-23.23v2.048h23.23z" fill="#78694f" />
      <path d="m1059.2 104.49h0.164v-4.478h-0.164z" fill="#d1c9bd" />
      <path d="m1071.2 100.09h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m1082.2 100.09h-11.45v4.478h11.45z" fill="#5c4c33" />
      <path d="m1082.2 98.114h-23.23v2.048h23.23z" fill="#78694f" />
      <path d="m1090.2 104.49h0.164v-4.478h-0.164z" fill="#d1c9bd" />
      <path d="m1102.2 100.09h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m1113.2 100.09h-11.47v4.478h11.47z" fill="#5c4c33" />
      <path d="m1113.2 98.114h-23.25v2.048h23.25z" fill="#78694f" />
      <path d="m1121.2 104.49h0.1626v-4.478h-0.1626z" fill="#d1c9bd" />
      <path d="m1133.2 100.09h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1145.2 100.09h-11.45v4.478h11.45z" fill="#5c4c33" />
      <path d="m1145.2 98.114h-23.25v2.048h23.25z" fill="#78694f" />
      <path d="m1008.2 100.09h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1020.2 100.09h-11.45v4.478h11.45z" fill="#5c4c33" />
      <path d="m1020.2 98.114h-23.25v2.048h23.25z" fill="#78694f" />
    </g>
    <g fill="#b0a38c" stroke-width="1.336">
      <path
        d="m1010.2 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
      />
      <path
        d="m1041.2 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
      />
      <path
        d="m1072.2 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
      />
      <path
        d="m1103.2 96.904c0 0.8038-0.6358 1.461-1.462 1.461-0.7918 0-1.445-0.6578-1.445-1.461 0-0.8131 0.6532-1.479 1.445-1.479 0.8265 0 1.462 0.6658 1.462 1.479"
      />
      <path
        d="m1135.2 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
      />
    </g>
    <g stroke-width="1.336">
      <path d="m1164.2 44.264h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1176.2 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m1176.2 42.254h-23.25v2.057h23.25z" fill="#78694f" />
      <path d="m1196.2 44.264h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m1207.2 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m1207.2 42.254h-23.23v2.057h23.23z" fill="#78694f" />
      <path d="m1227.2 44.264h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m1238.2 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m1238.2 42.254h-23.23v2.057h23.23z" fill="#78694f" />
      <path d="m1258.2 44.264h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m1270.2 44.264h-11.63v4.478h11.63z" fill="#5c4c33" />
      <path d="m1270.2 42.254h-23.25v2.057h23.25z" fill="#78694f" />
      <path d="m1289.2 44.264h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1301.2 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m1301.2 42.254h-23.25v2.057h23.25z" fill="#78694f" />
    </g>
    <g fill="#b0a38c" stroke-width="1.336">
      <path
        d="m1166.2 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m1197.2 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m1228.2 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m1259.2 41.044c0 0.8131-0.6358 1.469-1.462 1.469-0.7918 0-1.445-0.6565-1.445-1.469s0.6532-1.469 1.445-1.469c0.8265 0 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m1291.2 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
    </g>
    <g stroke-width="1.336">
      <path d="m1164.2 100.09h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1176.2 100.09h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m1176.2 98.114h-23.25v2.048h23.25z" fill="#78694f" />
      <path d="m1196.2 100.09h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m1207.2 100.09h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m1207.2 98.114h-23.23v2.048h23.23z" fill="#78694f" />
      <path d="m1227.2 100.09h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m1238.2 100.09h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m1238.2 98.114h-23.23v2.048h23.23z" fill="#78694f" />
      <path d="m1258.2 100.09h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m1270.2 100.09h-11.63v4.478h11.63z" fill="#5c4c33" />
      <path d="m1270.2 98.114h-23.25v2.048h23.25z" fill="#78694f" />
      <path d="m1289.2 100.09h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1301.2 100.09h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m1301.2 98.114h-23.25v2.048h23.25z" fill="#78694f" />
    </g>
    <g fill="#b0a38c" stroke-width="1.336">
      <path
        d="m1166.2 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
      />
      <path
        d="m1197.2 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
      />
      <path
        d="m1228.2 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
      />
      <path
        d="m1259.2 96.904c0 0.8038-0.6358 1.461-1.462 1.461-0.7918 0-1.445-0.6578-1.445-1.461 0-0.8131 0.6532-1.479 1.445-1.479 0.8265 0 1.462 0.6658 1.462 1.479"
      />
      <path
        d="m1291.2 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
      />
    </g>
    <g stroke-width="1.336">
      <path d="m1320.2 44.264h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1332.2 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m1332.2 42.254h-23.25v2.057h23.25z" fill="#78694f" />
      <path d="m1352.2 44.264h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m1364.2 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m1364.2 42.254h-23.23v2.057h23.23z" fill="#78694f" />
      <path
        d="m1322.2 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
        fill="#b0a38c"
      />
      <path
        d="m1353.2 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
        fill="#b0a38c"
      />
      <path d="m1320.2 100.09h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1332.2 100.09h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m1332.2 98.114h-23.25v2.048h23.25z" fill="#78694f" />
      <path d="m1352.2 100.09h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m1364.2 100.09h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m1364.2 98.114h-23.23v2.048h23.23z" fill="#78694f" />
      <path
        d="m1322.2 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
        fill="#b0a38c"
      />
      <path
        d="m1353.2 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
        fill="#b0a38c"
      />
      <path d="m0.1875 9.16v120.8h21.43v-119.1l-11.79-10.86z" fill="#8a7a5c" />
      <path d="m52.826 129.99h-23.25v-119.1l11.48-10.86 11.77 10.86z" fill="#8a7a5c" />
      <path
        d="m21.616 104.49h-11.63v25.4h11.63zm0-55.85h-11.63v44.84h11.63zm-11.63-48.55v37.54h11.63v-26.82z"
        fill="#706142"
      />
      <path
        d="m52.826 104.49h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z"
        fill="#706142"
      />
    </g>
    <g fill="#8a7a5c" stroke-width="1.336">
      <path d="m84.016 129.99h-23.25v-119.1l11.48-10.86 11.77 10.86z" />
      <path d="m115.15 129.99h-23.23v-119.1l11.46-10.86 11.77 10.86z" />
      <path d="m146.35 129.99h-23.23v-119.1l11.46-10.86 11.77 10.86z" />
      <path d="m177.55 129.99h-23.25v-119.1l11.46-10.86 11.79 10.86z" />
      <path d="m208.75 129.99h-23.25v-119.1l11.48-10.86 11.77 10.86z" />
    </g>
    <g fill="#706142" stroke-width="1.336">
      <path
        d="m84.016 100.09h-0.164v4.478h-11.45v25.4h11.62zm0-55.85h-0.164v4.478h-11.45v44.84h11.62zm-11.62-44.08v37.54h11.62v-26.82z"
      />
      <path
        d="m115.15 100.09h-0.164v4.478h-11.45v25.4h11.62zm0-55.85h-0.164v4.478h-11.45v44.84h11.62zm-11.62-44.08v37.54h11.62v-26.82z"
      />
      <path
        d="m146.35 100.09h-0.164v4.478h-11.45v25.4h11.62zm0-55.85h-0.164v4.478h-11.45v44.84h11.62zm-11.62-44.08v37.54h11.62v-26.82z"
      />
      <path
        d="m177.55 100.09h-0.164v4.478h-11.47v25.4h11.63zm0-55.85h-0.164v4.478h-11.47v44.84h11.63zm-11.63-44.08v37.54h11.63v-26.82z"
      />
      <path
        d="m208.75 100.09h-0.164v4.478h-11.45v25.4h11.62zm0-55.85h-0.164v4.478h-11.45v44.84h11.62zm-11.62-44.08v37.54h11.62v-26.82z"
      />
    </g>
    <g fill="#8a7a5c" stroke-width="1.336">
      <path d="m239.95 129.99h-23.25v-119.1l11.48-10.86 11.77 10.86z" />
      <path d="m271.15 129.99h-23.23v-119.1l11.46-10.86 11.77 10.86z" />
      <path d="m302.35 129.99h-23.23v-119.1l11.46-10.86 11.77 10.86z" />
      <path d="m333.65 129.99h-23.25v-119.1l11.46-10.86 11.79 10.86z" />
      <path d="m364.85 129.99h-23.25v-119.1l11.48-10.86 11.77 10.86z" />
    </g>
    <g fill="#706142" stroke-width="1.336">
      <path d="m239.95 104.49h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
      <path d="m271.15 104.49h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
      <path d="m302.35 104.49h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
      <path d="m333.65 104.49h-11.63v25.4h11.63zm0-55.85h-11.63v44.84h11.63zm-11.63-48.55v37.54h11.63v-26.82z" />
      <path d="m364.85 104.49h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.61-48.55v37.54h11.61v-26.82z" />
    </g>
    <g fill="#8a7a5c" stroke-width="1.336">
      <path d="m396.05 129.99h-23.25v-119.1l11.48-10.86 11.77 10.86z" />
      <path d="m427.25 129.99h-23.23v-119.1l11.46-10.86 11.77 10.86z" />
      <path d="m458.45 129.99h-23.23v-119.1l11.46-10.86 11.77 10.86z" />
      <path d="m489.65 129.99h-23.25v-119.1l11.46-10.86 11.79 10.86z" />
      <path d="m520.85 129.99h-23.25v-119.1l11.48-10.86 11.77 10.86z" />
    </g>
    <g fill="#706142" stroke-width="1.336">
      <path d="m396.05 104.49h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
      <path d="m427.25 104.49h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
      <path d="m458.45 104.49h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
      <path d="m489.65 104.49h-11.63v25.4h11.63zm0-55.85h-11.63v44.84h11.63zm-11.63-48.55v37.54h11.63v-26.82z" />
      <path d="m520.85 104.49h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
    </g>
    <path d="m553.55 129.99h-23.26v-119.1l11.48-10.87 11.77 10.87z" fill="#8a7a5c" stroke-width="1.337" />
    <path
      d="m553.55 104.59h-11.62v25.4h11.62zm0-55.87h-11.62v44.86h11.62zm-11.62-48.57v37.55h11.62v-26.83z"
      fill="#706142"
      stroke-width="1.337"
    />
    <g fill="#8a7a5c" stroke-width="1.336">
      <path d="m584.55 129.99h-23.25v-119.1l11.48-10.86 11.77 10.86z" />
      <path d="m615.75 129.99h-23.23v-119.1l11.46-10.86 11.77 10.86z" />
      <path d="m646.95 129.99h-23.23v-119.1l11.46-10.86 11.77 10.86z" />
      <path d="m678.15 129.99h-23.25v-119.1l11.46-10.86 11.79 10.86z" />
    </g>
    <g fill="#706142" stroke-width="1.336">
      <path d="m572.95 0.1842v37.54h11.62v-26.82z" />
      <path d="m584.55 48.814h-11.62v44.84h11.62z" />
      <path d="m584.55 104.49h-11.62v25.4h11.62z" />
      <path d="m604.15 0.1842v37.54h11.62v-26.82z" />
      <path d="m615.75 48.814h-11.62v44.84h11.62z" />
      <path d="m615.75 104.49h-11.62v25.4h11.62z" />
      <path d="m635.35 0.1842v37.54h11.62v-26.82z" />
      <path d="m646.95 48.814h-11.62v44.84h11.62z" />
      <path d="m646.95 104.49h-11.62v25.4h11.62z" />
      <path d="m666.55 0.1842v37.54h11.63v-26.82z" />
      <path d="m678.15 48.814h-11.63v44.84h11.63z" />
      <path d="m678.15 104.49h-11.63v25.4h11.63z" />
    </g>
    <g stroke-width="1.336">
      <path d="m0.1875 44.264h691.8v-6.539h-691.8z" fill="#94856b" />
      <path d="m0.1875 100.09h691.8v-6.529h-691.8z" fill="#94856b" />
      <path d="m0.1875 48.814h9.804v-4.478h-9.804z" fill="#706142" />
      <path d="m21.616 44.264h-11.63v4.478h11.63z" fill="#5c4c33" />
      <path d="m0.1875 42.254v2.057h21.43v-2.057z" fill="#78694f" />
      <path d="m41.206 44.264h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m52.826 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m52.826 42.254h-23.25v2.057h23.25z" fill="#78694f" />
      <path
        d="m11.456 41.044c0 0.8131-0.6358 1.469-1.462 1.469-0.7918 0-1.445-0.6565-1.445-1.469s0.6532-1.469 1.445-1.469c0.8265 0 1.462 0.6565 1.462 1.469"
        fill="#b0a38c"
      />
      <path
        d="m42.666 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
        fill="#b0a38c"
      />
      <path d="m0.1875 104.49h9.804v-4.478h-9.804z" fill="#706142" />
      <path d="m21.616 100.09h-11.63v4.478h11.63z" fill="#5c4c33" />
      <path d="m0.1875 98.114v2.049h21.43v-2.049z" fill="#78694f" />
      <path d="m41.206 100.09h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m52.826 100.09h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m52.826 98.114h-23.25v2.048h23.25z" fill="#78694f" />
      <path
        d="m11.456 96.904c0 0.8038-0.6358 1.461-1.462 1.461-0.7918 0-1.445-0.6578-1.445-1.461 0-0.8131 0.6532-1.479 1.445-1.479 0.8265 0 1.462 0.6658 1.462 1.479"
        fill="#b0a38c"
      />
      <path
        d="m42.666 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
        fill="#b0a38c"
      />
      <path d="m60.606 48.814h0.164v-4.478h-0.164z" fill="#d1c9bd" />
      <path d="m72.406 44.264h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m83.856 44.264h-11.45v4.478h11.45z" fill="#5c4c33" />
      <path d="m83.856 42.254h-23.25v2.057h23.25z" fill="#78694f" />
      <path d="m91.816 48.814h0.1626v-4.478h-0.1626z" fill="#d1c9bd" />
      <path d="m103.55 44.264h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m115.05 44.264h-11.45v4.478h11.45z" fill="#5c4c33" />
      <path d="m115.05 42.254h-23.23v2.057h23.23z" fill="#78694f" />
      <path d="m122.95 48.814h0.1626v-4.478h-0.1626z" fill="#d1c9bd" />
      <path d="m134.75 44.264h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m146.25 44.264h-11.45v4.478h11.45z" fill="#5c4c33" />
      <path d="m146.25 42.254h-23.23v2.057h23.23z" fill="#78694f" />
      <path d="m154.25 48.814h0.1626v-4.478h-0.1626z" fill="#d1c9bd" />
      <path d="m165.95 44.264h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m177.45 44.264h-11.47v4.478h11.47z" fill="#5c4c33" />
      <path d="m177.45 42.254h-23.25v2.057h23.25z" fill="#78694f" />
      <path d="m185.45 48.814h0.1626v-4.478h-0.1626z" fill="#d1c9bd" />
      <path d="m197.15 44.264h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m208.65 44.264h-11.45v4.478h11.45z" fill="#5c4c33" />
      <path d="m208.65 42.254h-23.25v2.057h23.25z" fill="#78694f" />
    </g>
    <g fill="#b0a38c" stroke-width="1.336">
      <path
        d="m73.696 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m104.85 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m136.15 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m167.35 41.044c0 0.8131-0.6358 1.469-1.462 1.469-0.7918 0-1.445-0.6565-1.445-1.469s0.6532-1.469 1.445-1.469c0.8265 0 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m198.55 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
    </g>
    <g stroke-width="1.336">
      <path d="m60.606 104.49h0.164v-4.478h-0.164z" fill="#d1c9bd" />
      <path d="m72.406 100.09h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m83.856 100.09h-11.45v4.478h11.45z" fill="#5c4c33" />
      <path d="m83.856 98.114h-23.25v2.048h23.25z" fill="#78694f" />
      <path d="m91.816 104.49h0.1626v-4.478h-0.1626z" fill="#d1c9bd" />
      <path d="m103.55 100.09h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m115.05 100.09h-11.45v4.478h11.45z" fill="#5c4c33" />
      <path d="m115.05 98.114h-23.23v2.048h23.23z" fill="#78694f" />
      <path d="m122.95 104.49h0.1626v-4.478h-0.1626z" fill="#d1c9bd" />
      <path d="m134.75 100.09h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m146.25 100.09h-11.45v4.478h11.45z" fill="#5c4c33" />
      <path d="m146.25 98.114h-23.23v2.048h23.23z" fill="#78694f" />
      <path d="m154.25 104.49h0.1626v-4.478h-0.1626z" fill="#d1c9bd" />
      <path d="m165.95 100.09h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m177.45 100.09h-11.47v4.478h11.47z" fill="#5c4c33" />
      <path d="m177.45 98.114h-23.25v2.048h23.25z" fill="#78694f" />
      <path d="m185.45 104.49h0.1626v-4.478h-0.1626z" fill="#d1c9bd" />
      <path d="m197.15 100.09h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m208.65 100.09h-11.45v4.478h11.45z" fill="#5c4c33" />
      <path d="m208.65 98.114h-23.25v2.048h23.25z" fill="#78694f" />
    </g>
    <g fill="#b0a38c" stroke-width="1.336">
      <path
        d="m73.696 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
      />
      <path
        d="m104.85 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
      />
      <path
        d="m136.15 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
      />
      <path
        d="m167.35 96.904c0 0.8038-0.6358 1.461-1.462 1.461-0.7918 0-1.445-0.6578-1.445-1.461 0-0.8131 0.6532-1.479 1.445-1.479 0.8265 0 1.462 0.6658 1.462 1.479"
      />
      <path
        d="m198.55 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
      />
    </g>
    <g stroke-width="1.336">
      <path d="m228.35 44.264h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m239.95 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m239.95 42.254h-23.25v2.057h23.25z" fill="#78694f" />
      <path d="m259.55 44.264h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m271.15 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m271.15 42.254h-23.23v2.057h23.23z" fill="#78694f" />
      <path d="m290.75 44.264h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m302.35 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m302.35 42.254h-23.23v2.057h23.23z" fill="#78694f" />
      <path d="m321.95 44.264h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m333.65 44.264h-11.63v4.478h11.63z" fill="#5c4c33" />
      <path d="m333.65 42.254h-23.25v2.057h23.25z" fill="#78694f" />
      <path d="m353.15 44.264h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m364.85 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m364.85 42.254h-23.25v2.057h23.25z" fill="#78694f" />
    </g>
    <g fill="#b0a38c" stroke-width="1.336">
      <path
        d="m229.85 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m261.05 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m292.25 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m323.45 41.044c0 0.8131-0.6358 1.469-1.462 1.469-0.7918 0-1.445-0.6565-1.445-1.469s0.6532-1.469 1.445-1.469c0.8265 0 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m354.55 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
    </g>
    <g stroke-width="1.336">
      <path d="m228.35 100.09h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m239.95 100.09h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m239.95 98.114h-23.25v2.048h23.25z" fill="#78694f" />
      <path d="m259.55 100.09h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m271.15 100.09h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m271.15 98.114h-23.23v2.048h23.23z" fill="#78694f" />
      <path d="m290.75 100.09h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m302.35 100.09h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m302.35 98.114h-23.23v2.048h23.23z" fill="#78694f" />
      <path d="m321.95 100.09h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m333.65 100.09h-11.63v4.478h11.63z" fill="#5c4c33" />
      <path d="m333.65 98.114h-23.25v2.048h23.25z" fill="#78694f" />
      <path d="m353.15 100.09h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m364.85 100.09h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m364.85 98.114h-23.25v2.048h23.25z" fill="#78694f" />
    </g>
    <g fill="#b0a38c" stroke-width="1.336">
      <path
        d="m229.85 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
      />
      <path
        d="m261.05 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
      />
      <path
        d="m292.25 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
      />
      <path
        d="m323.45 96.904c0 0.8038-0.6358 1.461-1.462 1.461-0.7918 0-1.445-0.6578-1.445-1.461 0-0.8131 0.6532-1.479 1.445-1.479 0.8265 0 1.462 0.6658 1.462 1.479"
      />
      <path
        d="m354.55 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
      />
    </g>
    <g stroke-width="1.336">
      <path d="m384.35 44.264h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m396.05 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m396.05 42.254h-23.25v2.057h23.25z" fill="#78694f" />
      <path d="m415.65 44.264h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m427.25 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m427.25 42.254h-23.23v2.057h23.23z" fill="#78694f" />
      <path d="m446.85 44.264h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m458.45 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m458.45 42.254h-23.23v2.057h23.23z" fill="#78694f" />
      <path d="m478.05 44.264h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m489.65 44.264h-11.63v4.478h11.63z" fill="#5c4c33" />
      <path d="m489.65 42.254h-23.25v2.057h23.25z" fill="#78694f" />
      <path d="m509.25 44.264h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m520.85 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m520.85 42.254h-23.25v2.057h23.25z" fill="#78694f" />
    </g>
    <g fill="#b0a38c" stroke-width="1.336">
      <path
        d="m385.75 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m416.95 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m448.35 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m479.45 41.044c0 0.8131-0.6358 1.469-1.462 1.469-0.7918 0-1.445-0.6565-1.445-1.469s0.6532-1.469 1.445-1.469c0.8265 0 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m510.65 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
    </g>
    <g stroke-width="1.336">
      <path d="m384.35 100.09h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m396.05 100.09h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m396.05 98.114h-23.25v2.048h23.25z" fill="#78694f" />
      <path d="m415.65 100.09h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m427.25 100.09h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m427.25 98.114h-23.23v2.048h23.23z" fill="#78694f" />
      <path d="m446.85 100.09h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m458.45 100.09h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m458.45 98.114h-23.23v2.048h23.23z" fill="#78694f" />
      <path d="m478.05 100.09h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m489.65 100.09h-11.63v4.478h11.63z" fill="#5c4c33" />
      <path d="m489.65 98.114h-23.25v2.048h23.25z" fill="#78694f" />
      <path d="m509.25 100.09h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m520.85 100.09h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m520.85 98.114h-23.25v2.048h23.25z" fill="#78694f" />
    </g>
    <g fill="#b0a38c" stroke-width="1.336">
      <path
        d="m385.75 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
      />
      <path
        d="m416.95 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
      />
      <path
        d="m448.35 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
      />
      <path
        d="m479.45 96.904c0 0.8038-0.6358 1.461-1.462 1.461-0.7918 0-1.445-0.6578-1.445-1.461 0-0.8131 0.6532-1.479 1.445-1.479 0.8265 0 1.462 0.6658 1.462 1.479"
      />
      <path
        d="m510.65 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
      />
    </g>
    <g stroke-width="1.336">
      <path d="m541.75 44.264h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m553.35 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m553.35 42.254h-23.25v2.057h23.25z" fill="#78694f" />
      <path d="m572.95 44.264h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m584.55 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m584.55 42.254h-23.23v2.057h23.23z" fill="#78694f" />
      <path d="m604.15 44.264h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m615.75 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m615.75 42.254h-23.23v2.057h23.23z" fill="#78694f" />
      <path d="m646.95 48.814h0.0187v-4.478h-0.0187z" fill="#d1c9bd" />
      <path d="m635.35 44.264h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m646.95 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m646.95 42.254h-23.25v2.057h23.25z" fill="#78694f" />
      <path d="m666.55 44.264h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m678.15 44.264h-11.63v4.478h11.63z" fill="#5c4c33" />
      <path d="m678.15 42.254h-23.25v2.057h23.25z" fill="#78694f" />
    </g>
    <g fill="#b0a38c" stroke-width="1.336">
      <path
        d="m543.25 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m574.45 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m605.55 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m636.75 41.044c0 0.8131-0.6358 1.469-1.462 1.469-0.7918 0-1.445-0.6565-1.445-1.469s0.6532-1.469 1.445-1.469c0.8265 0 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m667.95 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
    </g>
    <g stroke-width="1.336">
      <path d="m541.75 100.09h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m553.35 100.09h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m553.35 98.114h-23.25v2.048h23.25z" fill="#78694f" />
      <path d="m572.95 100.09h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m584.55 100.09h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m584.55 98.114h-23.23v2.048h23.23z" fill="#78694f" />
      <path d="m604.15 100.09h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m615.75 100.09h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m615.75 98.114h-23.23v2.048h23.23z" fill="#78694f" />
      <path d="m646.95 104.49h0.0187v-4.478h-0.0187z" fill="#d1c9bd" />
      <path d="m635.35 100.09h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m646.95 100.09h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m646.95 98.114h-23.25v2.048h23.25z" fill="#78694f" />
      <path d="m666.55 100.09h-11.62v4.478h11.62z" fill="#706142" />
      <path d="m678.15 100.09h-11.63v4.478h11.63z" fill="#5c4c33" />
      <path d="m678.15 98.114h-23.25v2.048h23.25z" fill="#78694f" />
    </g>
    <g fill="#b0a38c" stroke-width="1.336">
      <path
        d="m543.25 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
      />
      <path
        d="m574.45 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
      />
      <path
        d="m605.55 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
      />
      <path
        d="m636.75 96.904c0 0.8038-0.6358 1.461-1.462 1.461-0.7918 0-1.445-0.6578-1.445-1.461 0-0.8131 0.6532-1.479 1.445-1.479 0.8265 0 1.462 0.6658 1.462 1.479"
      />
      <path
        d="m667.95 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
      />
    </g>
    <path
      d="m1333.3 250.65 7.874 6.69 38.07-2.933-10.78-32.69-37.51-3.109-5.513 0.5705z"
      fill="#c97317"
      stroke-width="1.333"
    />
    <path
      d="m1134 247.4 8.371 6.69 37.86-2.933-13.21-32.69-37.75-3.109-5.471 0.5705z"
      fill="#a6523b"
      stroke-width="1.333"
    />
    <g fill="#cf6e0d" stroke-width="1.333">
      <path
        d="m1380.8 141.46 10.104 22.651c14.603 8.7981 31.572 3.3667 41.969-1.187l-15.663-31.095c-14.162-2.0855-31.664-1.4803-36.401 9.6153"
      />
      <path
        d="m1475.2 139.34 8.482 23.86c13.98 10.79 31.37 7.833 42.1 4.808l-13.44-33.01c-14.02-4.082-31.58-5.977-37.14 4.33"
      />
      <path
        d="m1566.2 138.28 10.24 23.86c14.77 10.79 31.91 7.833 42.42 4.808l-15.86-33.01c-14.3-4.083-31.99-5.979-36.79 4.328"
      />
    </g>
    <g fill="#a6523b" stroke-width="1.333">
      <path
        d="m1442.2 170.29c18.204-0.7571 27.008 2.3541 35.38-5.1866 0.4412-0.39681 0.9545-1.383 1.3595-2.1878l-13.396-33.359-38.938 2.3925z"
      />
      <path
        d="m1536.4 169.87c18.22-0.0707 27.15 3.37 35.27-3.85 0.4265-0.3799 0.9051-1.346 1.281-2.135l-14.65-33.84-38.94 0.9238z"
      />
      <path
        d="m1633.4 171.47c18.24-0.0707 27.43 3.37 35.03-3.85 0.3986-0.3799 0.8052-1.346 1.122-2.135l-17.21-33.84-38.91 0.9238z"
      />
    </g>
    <g fill="#cf6e0d" stroke-width="1.333">
      <path
        d="m1667.2 137.88 6.772 23.86c13.2 10.79 30.77 7.833 41.71 4.808l-11.07-33.01c-13.72-4.083-31.13-5.979-37.4 4.328"
      />
      <path
        d="m1756.2 139.34 8.482 23.86c13.98 10.79 31.37 7.833 42.1 4.808l-13.44-33.01c-14.02-4.082-31.58-5.977-37.14 4.33"
      />
      <path
        d="m1847.2 138.28 10.24 23.86c14.77 10.79 31.91 7.833 42.42 4.808l-15.86-33.01c-14.3-4.083-31.99-5.979-36.79 4.328"
      />
    </g>
    <g fill="#a6523b" stroke-width="1.333">
      <path
        d="m1722.4 169.87c18.22-0.0707 26.9 3.37 35.55-3.85 0.4559-0.3799 1.006-1.346 1.441-2.135l-12.13-33.84-39 0.9238z"
      />
      <path
        d="m1817.4 169.87c18.22-0.0707 27.15 3.37 35.27-3.85 0.4265-0.3799 0.9051-1.346 1.281-2.135l-14.65-33.84-38.94 0.9238z"
      />
      <path d="m1933.3 131.64-38.91 0.92383 19.969 38.902c8.4631-0.0328 14.962 0.68068 20.273 0.74414v-37.951z" />
    </g>
    <g stroke-width="1.333">
      <path
        d="m1432 208.16 3.6356-3.7343-6.5554-23.661c-22.713-5.4996 0 0-41.255 4.0016l6.6589 25.858z"
        fill="#cf6e0d"
      />
      <path
        d="m1438 185.2 5.035 21.9c3.8 3.528 3.414 4.555 9.119 6.816l33.88-1.742-8.775-32.42c-16.74-4.468-33.39-7.617-39.27 5.444"
        fill="#d68747"
      />
      <path d="m1533.6 207.91 3.536-3.555-7.278-23.95c-22.94-6.59 0 0-41.27 2.002l7.45 26.15z" fill="#cf6e0d" />
      <path d="m1630.6 207.15 3.26-3.555-9.067-23.94c-23.39-6.592 0 0-41.03 2.001l9.403 26.15z" fill="#cf6e0d" />
      <path
        d="m1536.6 181.6 6.729 21.9c4.064 3.528 3.759 4.555 9.624 6.816l33.67-1.742-11.28-32.42c-17.05-4.468-33.88-7.617-38.74 5.444"
        fill="#d68747"
      />
      <path
        d="m1631.6 183.6 8.357 21.9c4.327 3.528 4.098 4.555 10.13 6.816l33.54-1.742-13.69-32.42c-17.38-4.468-34.46-7.617-38.34 5.444"
        fill="#d68747"
      />
      <path d="m1727.5 209.13 3.812-3.554-5.403-23.95c-22.42-6.592 0 0-41.4 2.001l5.4 26.15z" fill="#cf6e0d" />
      <path
        d="m1734.2 185.2 5.035 21.9c3.8 3.528 3.414 4.555 9.119 6.816l33.88-1.742-8.775-32.42c-16.74-4.468-33.39-7.617-39.27 5.444"
        fill="#d68747"
      />
      <path d="m1829.8 209.91 3.536-3.555-7.278-23.95c-22.94-6.59 0 0-41.27 2.002l7.45 26.15z" fill="#cf6e0d" />
      <path d="m1926.8 207.15 3.26-3.555-9.067-23.94c-23.39-6.592 0 0-41.03 2.001l9.403 26.15z" fill="#cf6e0d" />
      <path
        d="m1832.8 181.6 6.729 21.9c4.064 3.528 3.759 4.555 9.624 6.816l33.67-1.742-11.28-32.42c-17.05-4.468-33.88-7.617-38.74 5.444"
        fill="#d68747"
      />
      <path d="m1934.7 174.55c-3.758 1.5328-6.4227 4.2749-7.4433 8.7832v2e-3l7.4394 16.195z" fill="#d68747" />
      <path
        d="m1379.8 225.38 6.6974 23.606c13.841 10.232 32.664 6.5007 44.4 2.9921l-11.014-32.586c-14.565-3.4884-33.127-4.6241-40.089 5.9769"
        fill="#cf6e0d"
      />
      <path
        d="m1478.6 222.08 7.509 23.86c14.2 10.79 32.9 7.833 44.54 4.808l-12.14-33.01c-14.69-4.083-33.31-5.979-39.91 4.328"
        fill="#cf6e0d"
      />
      <path d="m1435.6 247.4 7.874 6.69 38.07-2.933-10.78-32.69-37.51-3.109-5.513 0.5705z" fill="#c97317" />
      <path
        d="m1576.6 222.08 9.282 23.86c15.01 10.79 33.48 7.833 44.9 4.808l-14.58-33.01c-14.98-4.083-33.75-5.979-39.59 4.328"
        fill="#cf6e0d"
      />
      <path
        d="m1677.6 223.7 11.09 23.86c15.84 10.79 34.11 7.833 45.31 4.808l-17.08-33.01c-15.32-4.082-34.24-5.977-39.31 4.33"
        fill="#cf6e0d"
      />
      <path d="m1638.6 249.1 8.884 6.69 37.68-2.933-15.68-32.69-38.03-3.11-5.435 0.5719z" fill="#c97317" />
      <path d="m1536.6 247.4 8.371 6.69 37.86-2.933-13.21-32.69-37.75-3.109-5.471 0.5705z" fill="#a6523b" />
      <path
        d="m1735.1 224.35 5.725 23.86c13.41 10.79 32.37 7.833 44.24 4.808l-9.67-33.01c-14.41-4.082-32.91-5.977-40.3 4.33"
        fill="#cf6e0d"
      />
      <path
        d="m1833.4 222.08 7.509 23.86c14.2 10.79 32.9 7.833 44.54 4.808l-12.14-33.01c-14.69-4.083-33.31-5.979-39.91 4.328"
        fill="#cf6e0d"
      />
      <path d="m1790.4 247.4 7.874 6.69 38.07-2.933-10.78-32.69-37.51-3.109-5.513 0.5705z" fill="#c97317" />
      <path d="m1934.7 218.34c-1.3333 1.01-2.4549 2.2372-3.2988 3.7266l-0.012 0.0137 3.3105 8.5078z" fill="#cf6e0d" />
      <path
        d="m1886.6 215.36-5.4707 0.57031 10.199 31.473 8.3711 6.6894 34.939-2.707v-7.457l-10.289-25.459z"
        fill="#a6523b"
      />
    </g>
    <path d="m1397.2 129.99h-23.25v-119.1l11.48-10.86 11.77 10.86z" fill="#8a7a5c" stroke-width="1.336" />
    <path
      d="m1397.2 100.09h-0.164v4.478h-11.45v25.4h11.62zm0-55.85h-0.164v4.478h-11.45v44.84h11.62zm-11.62-44.08v37.54h11.62v-26.82z"
      fill="#706142"
      stroke-width="1.336"
    />
    <g fill="#8a7a5c" stroke-width="1.336">
      <path d="m1428.2 129.99h-23.25v-119.1l11.48-10.86 11.77 10.86z" />
      <path d="m1459.2 129.99h-23.23v-119.1l11.46-10.86 11.77 10.86z" />
      <path d="m1490.2 129.99h-23.23v-119.1l11.46-10.86 11.77 10.86z" />
      <path d="m1522.2 129.99h-23.25v-119.1l11.46-10.86 11.79 10.86z" />
      <path d="m1553.2 129.99h-23.25v-119.1l11.48-10.86 11.77 10.86z" />
    </g>
    <g fill="#706142" stroke-width="1.336">
      <path d="m1428.2 104.49h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
      <path d="m1459.2 104.49h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
      <path d="m1490.2 104.49h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
      <path d="m1522.2 104.49h-11.63v25.4h11.63zm0-55.85h-11.63v44.84h11.63zm-11.63-48.55v37.54h11.63v-26.82z" />
      <path d="m1553.2 104.49h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
    </g>
    <g stroke-width="1.336">
      <path d="m1584.2 129.99h-23.25v-119.1l11.48-10.86 11.77 10.86z" fill="#8a7a5c" />
      <path d="m1616.2 129.99h-23.23v-119.1l11.46-10.86 11.77 10.86z" fill="#8a7a5c" />
      <path
        d="m1584.2 104.49h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z"
        fill="#706142"
      />
      <path
        d="m1616.2 104.49h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z"
        fill="#706142"
      />
      <path d="m1650.2 129.99h-23.25v-119.1l11.48-10.86 11.77 10.86z" fill="#8a7a5c" />
      <path
        d="m1650.2 100.09h-0.164v4.478h-11.45v25.4h11.62zm0-55.85h-0.164v4.478h-11.45v44.84h11.62zm-11.62-44.08v37.54h11.62v-26.82z"
        fill="#706142"
      />
    </g>
    <g fill="#8a7a5c" stroke-width="1.336">
      <path d="m1681.2 129.99h-23.25v-119.1l11.48-10.86 11.77 10.86z" />
      <path d="m1712.2 129.99h-23.23v-119.1l11.46-10.86 11.77 10.86z" />
      <path d="m1743.2 129.99h-23.23v-119.1l11.46-10.86 11.77 10.86z" />
      <path d="m1775.2 129.99h-23.25v-119.1l11.46-10.86 11.79 10.86z" />
      <path d="m1806.2 129.99h-23.25v-119.1l11.48-10.86 11.77 10.86z" />
    </g>
    <g fill="#706142" stroke-width="1.336">
      <path d="m1681.2 104.49h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
      <path d="m1712.2 104.49h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
      <path d="m1743.2 104.49h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
      <path d="m1775.2 104.49h-11.63v25.4h11.63zm0-55.85h-11.63v44.84h11.63zm-11.63-48.55v37.54h11.63v-26.82z" />
      <path d="m1806.2 104.49h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z" />
    </g>
    <g stroke-width="1.336">
      <path d="m1837.2 129.99h-23.25v-119.1l11.48-10.86 11.77 10.86z" fill="#8a7a5c" />
      <path d="m1869.2 129.99h-23.23v-119.1l11.46-10.86 11.77 10.86z" fill="#8a7a5c" />
      <path
        d="m1837.2 104.49h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z"
        fill="#706142"
      />
      <path
        d="m1869.2 104.49h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z"
        fill="#706142"
      />
      <path d="m1901.7 129.99h-23.25v-119.1l11.48-10.86 11.77 10.86z" fill="#8a7a5c" />
      <path
        d="m1901.7 100.09h-0.164v4.478h-11.45v25.4h11.62zm0-55.85h-0.164v4.478h-11.45v44.84h11.62zm-11.62-44.08v37.54h11.62v-26.82z"
        fill="#706142"
      />
      <path d="m1932.7 129.99h-23.25v-119.1l11.48-10.86 11.77 10.86z" fill="#8a7a5c" />
      <path
        d="m1932.7 104.49h-11.62v25.4h11.62zm0-55.85h-11.62v44.84h11.62zm-11.62-48.55v37.54h11.62v-26.82z"
        fill="#706142"
      />
      <path d="m1385.4 44.264h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1397.4 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m1397.4 42.254h-23.25v2.057h23.25z" fill="#78694f" />
      <path d="m1416.4 44.264h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1428.4 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m1428.4 42.254h-23.25v2.057h23.25z" fill="#78694f" />
      <path d="m1447.4 44.264h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1459.4 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m1459.4 42.254h-23.25v2.057h23.25z" fill="#78694f" />
      <path d="m1478.4 44.264h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1490.4 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m1490.4 42.254h-23.25v2.057h23.25z" fill="#78694f" />
      <path d="m1510.4 44.264h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1522.4 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m1522.4 42.254h-23.25v2.057h23.25z" fill="#78694f" />
      <path d="m1541.4 44.264h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1553.4 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m1553.4 42.254h-23.25v2.057h23.25z" fill="#78694f" />
      <path d="m1572.4 44.264h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1584.4 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m1584.4 42.254h-23.25v2.057h23.25z" fill="#78694f" />
      <path d="m1604.4 44.264h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1616.4 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m1616.4 42.254h-23.25v2.057h23.25z" fill="#78694f" />
      <path d="m1638.4 44.264h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1650.4 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m1650.4 42.254h-23.25v2.057h23.25z" fill="#78694f" />
      <path d="m1669.4 44.264h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1681.4 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m1681.4 42.254h-23.25v2.057h23.25z" fill="#78694f" />
      <path d="m1700.4 44.264h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1712.4 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m1712.4 42.254h-23.25v2.057h23.25z" fill="#78694f" />
      <path d="m1731.4 44.264h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1743.4 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m1743.4 42.254h-23.25v2.057h23.25z" fill="#78694f" />
      <path d="m1763.4 44.264h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1775.4 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m1775.4 42.254h-23.25v2.057h23.25z" fill="#78694f" />
      <path d="m1794.4 44.264h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1806.4 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m1806.4 42.254h-23.25v2.057h23.25z" fill="#78694f" />
      <path d="m1825.4 44.264h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1837.4 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m1837.4 42.254h-23.25v2.057h23.25z" fill="#78694f" />
      <path d="m1857.4 44.264h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1869.4 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m1869.4 42.254h-23.25v2.057h23.25z" fill="#78694f" />
      <path d="m1889.9 44.264h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1901.9 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m1901.9 42.254h-23.25v2.057h23.25z" fill="#78694f" />
      <path d="m1920.9 44.264h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1932.9 44.264h-11.62v4.478h11.62z" fill="#5c4c33" />
      <path d="m1932.9 42.254h-23.25v2.057h23.25z" fill="#78694f" />
    </g>
    <g fill="#b0a38c" stroke-width="1.336">
      <path
        d="m1387 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m1418 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m1449 41.044c0 0.8131-0.6358 1.469-1.462 1.469-0.7918 0-1.445-0.6565-1.445-1.469s0.6532-1.469 1.445-1.469c0.8265 0 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m1480 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m1512 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m1543 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m1574 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m1606 41.044c0 0.8131-0.6358 1.469-1.462 1.469-0.7918 0-1.445-0.6565-1.445-1.469s0.6532-1.469 1.445-1.469c0.8265 0 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m1640.1 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m1671.1 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m1702.1 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m1733.1 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m1765.1 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m1796 41.044c0 0.8131-0.6358 1.469-1.462 1.469-0.7918 0-1.445-0.6565-1.445-1.469s0.6532-1.469 1.445-1.469c0.8265 0 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m1827.1 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m1859.1 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m1891.5 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
      <path
        d="m1922.5 41.044c0 0.8131-0.6532 1.469-1.462 1.469s-1.462-0.6565-1.462-1.469 0.6532-1.469 1.462-1.469 1.462 0.6565 1.462 1.469"
      />
    </g>
    <g stroke-width="1.336">
      <path d="m1385.4 100.09h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1397.4 100.09h-11.45v4.478h11.45z" fill="#5c4c33" />
      <path d="m1397.4 98.114h-23.25v2.048h23.25z" fill="#78694f" />
      <path
        d="m1387.4 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
        fill="#b0a38c"
      />
      <path d="m1416.4 100.09h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1428.4 100.09h-11.45v4.478h11.45z" fill="#5c4c33" />
      <path d="m1428.4 98.114h-23.25v2.048h23.25z" fill="#78694f" />
      <path
        d="m1418.4 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
        fill="#b0a38c"
      />
      <path d="m1447.4 100.09h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1459.4 100.09h-11.45v4.478h11.45z" fill="#5c4c33" />
      <path d="m1459.4 98.114h-23.25v2.048h23.25z" fill="#78694f" />
      <path
        d="m1449.4 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
        fill="#b0a38c"
      />
      <path d="m1478.4 100.09h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1490.4 100.09h-11.45v4.478h11.45z" fill="#5c4c33" />
      <path d="m1490.4 98.114h-23.25v2.048h23.25z" fill="#78694f" />
      <path
        d="m1480.4 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
        fill="#b0a38c"
      />
      <path d="m1510.4 100.09h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1522.4 100.09h-11.45v4.478h11.45z" fill="#5c4c33" />
      <path d="m1522.4 98.114h-23.25v2.048h23.25z" fill="#78694f" />
      <path
        d="m1512.4 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
        fill="#b0a38c"
      />
      <path d="m1541.4 100.09h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1553.4 100.09h-11.45v4.478h11.45z" fill="#5c4c33" />
      <path d="m1553.4 98.114h-23.25v2.048h23.25z" fill="#78694f" />
      <path
        d="m1543.4 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
        fill="#b0a38c"
      />
      <path d="m1572.4 100.09h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1584.4 100.09h-11.45v4.478h11.45z" fill="#5c4c33" />
      <path d="m1584.4 98.114h-23.25v2.048h23.25z" fill="#78694f" />
      <path
        d="m1574.4 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
        fill="#b0a38c"
      />
      <path d="m1604.4 100.09h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1616.4 100.09h-11.45v4.478h11.45z" fill="#5c4c33" />
      <path d="m1616.4 98.114h-23.25v2.048h23.25z" fill="#78694f" />
      <path
        d="m1606.4 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
        fill="#b0a38c"
      />
      <path d="m1638.4 100.09h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1650.4 100.09h-11.45v4.478h11.45z" fill="#5c4c33" />
      <path d="m1650.4 98.114h-23.25v2.048h23.25z" fill="#78694f" />
      <path
        d="m1640.4 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
        fill="#b0a38c"
      />
      <path d="m1669.4 100.09h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1681.4 100.09h-11.45v4.478h11.45z" fill="#5c4c33" />
      <path d="m1681.4 98.114h-23.25v2.048h23.25z" fill="#78694f" />
      <path
        d="m1671.4 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
        fill="#b0a38c"
      />
      <path d="m1700.4 100.09h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1712.4 100.09h-11.45v4.478h11.45z" fill="#5c4c33" />
      <path d="m1712.4 98.114h-23.25v2.048h23.25z" fill="#78694f" />
      <path
        d="m1702.4 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
        fill="#b0a38c"
      />
      <path d="m1731.4 100.09h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1743.4 100.09h-11.45v4.478h11.45z" fill="#5c4c33" />
      <path d="m1743.4 98.114h-23.25v2.048h23.25z" fill="#78694f" />
      <path
        d="m1733.4 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
        fill="#b0a38c"
      />
      <path d="m1763.4 100.09h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1775.4 100.09h-11.45v4.478h11.45z" fill="#5c4c33" />
      <path d="m1775.4 98.114h-23.25v2.048h23.25z" fill="#78694f" />
      <path
        d="m1765.4 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
        fill="#b0a38c"
      />
      <path d="m1794.4 100.09h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1806.4 100.09h-11.45v4.478h11.45z" fill="#5c4c33" />
      <path d="m1806.4 98.114h-23.25v2.048h23.25z" fill="#78694f" />
      <path
        d="m1796.4 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
        fill="#b0a38c"
      />
      <path d="m1825.4 100.09h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1837.4 100.09h-11.45v4.478h11.45z" fill="#5c4c33" />
      <path d="m1837.4 98.114h-23.25v2.048h23.25z" fill="#78694f" />
      <path
        d="m1827.4 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
        fill="#b0a38c"
      />
      <path d="m1857.4 100.09h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1869.4 100.09h-11.45v4.478h11.45z" fill="#5c4c33" />
      <path d="m1869.4 98.114h-23.25v2.048h23.25z" fill="#78694f" />
      <path
        d="m1859.4 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
        fill="#b0a38c"
      />
      <path d="m1889.9 100.09h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1901.9 100.09h-11.45v4.478h11.45z" fill="#5c4c33" />
      <path d="m1901.9 98.114h-23.25v2.048h23.25z" fill="#78694f" />
      <path
        d="m1891.9 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
        fill="#b0a38c"
      />
      <path d="m1920.9 100.09h-11.63v4.478h11.63z" fill="#706142" />
      <path d="m1932.9 100.09h-11.45v4.478h11.45z" fill="#5c4c33" />
      <path d="m1932.9 98.114h-23.25v2.048h23.25z" fill="#78694f" />
      <path
        d="m1922.9 96.904c0 0.8038-0.6532 1.461-1.462 1.461s-1.462-0.6578-1.462-1.461c0-0.8131 0.6532-1.479 1.462-1.479s1.462 0.6658 1.462 1.479"
        fill="#b0a38c"
      />
    </g>
  </svg>
`;
