import { html } from 'lit';

export const fingerTap = html`
  <svg version="1.1" viewBox="0 0 282.01 358.89" xmlns="http://www.w3.org/2000/svg">
    <g display="var(--tap-display, block)" fill="#c9c4af">
      <title>finger taps</title>
      <path
        d="m57.753 67.001c0-30.091 24.392-54.483 54.483-54.483s54.483 24.392 54.483 54.483v31.072c6.1347-9.8387 9.6907-21.452 9.6907-33.9 0-35.443-28.732-64.173-64.174-64.173s-64.174 28.731-64.174 64.173c0 12.448 3.556 24.061 9.6907 33.9z"
      />
      <path
        d="m76.523 70.801c0-19.728 15.992-35.721 35.72-35.721 19.729 0 35.721 15.993 35.721 35.721v25.011c5.6333-7.4787 8.976-16.78 8.976-26.865 0-24.685-20.012-44.696-44.697-44.696s-44.696 20.011-44.696 44.696c0 10.085 3.3426 19.387 8.976 26.865z"
      />
    </g>
    <path
      d="m282 201.96v81.04c0 9.3733-0.94667 18.707-2.84 27.853-1.8667 9.1333-4.68 18.08-8.3867 26.693-5.5733 12.96-18.333 21.347-32.44 21.347h-110.47c-14.347 0-27.853-6.7467-36.467-18.2l-80.053-106.55-6.2133-8.2667c-3.4533-4.1067-5.1333-9.1467-5.1333-14.253 0-3.7733 0.91999-7.5733 2.72-11.053 1.48-2.9067 3.5733-5.6 6.2667-7.8533 10.28-8.64 25.2-7.7867 33.333 1.8933l45.627 54.8v-167.76c0-13.707 11.36-24.76 25.173-24.267 13.173 0.46667 23.413 11.68 23.413 24.853v92.707c0-13.693 11.347-24.733 25.133-24.24 13.147 0.46666 23.373 11.667 23.373 24.813v7.0533c0.41334-6.32 3.2533-11.987 7.6-16.067 4.3333-4.08 10.173-6.5733 16.6-6.5733 13.4 0 24.253 10.867 24.253 24.253v17.773c0-13.387 10.853-24.253 24.253-24.253s24.253 10.867 24.253 24.253"
      fill="#ffd591"
    />
    <g fill="#ffba55">
      <path
        d="m42.333 194.6 45.624 54.8c-2.7133 2.7067-7.164 2.5027-9.616-0.44267l-41.061-49.317c-7.8666-9.36-22.067-10.453-32.28-2.72 1.1467-1.52 2.48-2.9333 4-4.2133 10.267-8.64 25.187-7.7867 33.333 1.8933"
      />
      <path
        d="m136.54 82.231v92.711c-4.4026 0-5.0533-12.959-5.0533-19.12v-68.551c0-13.173-5.3866-24.387-18.548-24.853-5.7586-0.2-15.945 1.6-20.212 4.76 4.5866-6.1733 12.053-10.093 20.4-9.8 13.16 0.46666 23.413 11.68 23.413 24.853"
      />
      <path
        d="m185.02 174.39c0 5.324 0.348 3.2147 0.028 8.1747-2.8266-1.1227-4.908-3.604-5.4626-6.5947-2.0493-11.076-11.367-19.823-22.952-20.233-5.7466-0.2-11.08 1.5867-15.333 4.7467 4.5866-6.1733 12.027-10.08 20.373-9.7867 12.773 0.45333 22.787 11.04 23.347 23.693"
      />
      <path
        d="m233.5 184.18v17.784c-2.1173 0-5.0533-2.96-5.0533-7.0227v-5.708c0-13.4-10.853-24.253-24.253-24.253-5.4133 0-10.4 1.76-14.427 4.76 0.86666-1.1733 1.8266-2.2533 2.88-3.24 4.3333-4.08 10.173-6.5733 16.6-6.5733 13.387 0 24.253 10.867 24.253 24.253"
      />
      <path
        d="m282.01 201.95v81.04c0 9.3733-0.95999 18.707-2.84 27.853-1.88 9.1333-4.68 18.08-8.3867 26.693-2.3067 5.3467-5.8267 9.92-10.173 13.453 2.0667-2.5067 3.8-5.3333 5.12-8.4133 3.7067-8.6133 6.52-17.56 8.4-26.693 1.88-9.1467 2.84-18.467 2.84-27.84v-81.04c0-13.4-10.867-24.267-24.267-24.267-5.4 0-10.387 1.7733-14.413 4.76 4.4133-5.9467 11.493-9.8 19.467-9.8 13.387 0 24.253 10.867 24.253 24.253"
      />
    </g>
    <path
      d="m131.39 80.031c-0.93068-9.5773-8.6748-17.412-18.454-17.759-10.448-0.372-19.1 7.592-19.875 17.759l-0.0494 9.352c-0.0147 2.8707 2.308 5.2067 5.1787 5.2067h28.08c2.8734 0 5.1974-2.34 5.1787-5.2133z"
      fill="#fff4e3"
    />
    <path
      d="m31.633 219.46-20.28 14.68-6.2133-8.2667c-3.4533-4.1067-5.1333-9.1467-5.1333-14.253 0-3.7733 0.92-7.5733 2.72-11.053 8.0266-4.8 18.373-2.88 24.52 4.0933l5.5333 7.5333c1.6933 2.32 1.1867 5.5867-1.1467 7.2667"
      fill="#fff4e3"
    />
    <g fill="none" stroke="#c3996b" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.3333">
      <path d="m101.75 127.01h18.888" />
      <path d="m101.75 134.88h18.888" />
      <path d="m100.14 130.94h23.194" />
    </g>
  </svg>
`;
