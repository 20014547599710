import { shuffle } from '@ounce/onc';

export function* stimuliSource(items) {
  for (;;) {
    items = shuffle.getRandomSubarray(items);
    for (const item of items) {
      yield item;
    }
  }
}

export class StimuliSource {
  constructor(items) {
    this.stimuli = stimuliSource(items);
  }

  next() {
    const { value } = this.stimuli.next();
    return value;
  }
}
