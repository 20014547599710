import { animate, fadeIn } from '@lit-labs/motion';
import { ResizeController } from '@lit-labs/observers/resize-controller.js';
import { ContextConsumer } from '@lit/context';
import gameDelay from 'delay';
import { LitElement, css, html, noChange, nothing } from 'lit';
import { Directive, PartType, directive } from 'lit/directive.js';
import { classMap } from 'lit/directives/class-map.js';
import { styleMap } from 'lit/directives/style-map.js';
import { graphics } from '~/graphics/index.js';
import { chickenTaskContext } from './chicken-context';

const progressBackground = [[0, 56.41, 100, 43.88, 'app:coopRunSmall']];

const progressItems = {
  0: { icon: ['app:eggCracked', 8.41, 10.17], styles: {}, duration: 1000, progress: 0 },
  1: {
    icon: ['app:eggCracked', 8.41, 10.17],
    duration: 1000,
    audio: 'egg-roll',
    progress: 1,
  },
  2: {
    icon: ['app:eggCracked', 8.41, 10.17],
    styles: { '--egg-crack-1': 'block' },
    audio: 'egg-crack',
    progress: 1,
  },

  3: {
    icon: ['app:eggCracked', 8.41, 10.17],
    styles: { '--egg-crack-1': 'block' },
    duration: 1000,
    audio: 'egg-roll',
    progress: 2,
  },

  4: {
    icon: ['app:eggCracked', 8.41, 10.17],
    styles: { '--egg-crack-1': 'block' },
    progress: 2,
  },

  5: {
    icon: ['app:eggCracked', 8.41, 10.17],
    styles: { '--egg-crack-1': 'block' },
    duration: 1000,
    audio: 'egg-roll',
    progress: 3,
  },
  6: {
    icon: ['app:eggCracked', 8.41, 10.17],
    styles: { '--egg-crack-1': 'block', '--egg-crack-2': 'block' },
    audio: 'egg-crack',
    progress: 3,
  },

  7: {
    icon: ['app:eggCracked', 8.41, 10.17],
    styles: { '--egg-crack-1': 'block', '--egg-crack-2': 'block' },
    duration: 1000,
    audio: 'egg-roll',
    progress: 4,
  },
  8: {
    icon: ['app:eggCracked', 8.41, 10.17],
    styles: { '--egg-crack-1': 'block', '--egg-crack-2': 'block' },

    progress: 4,
  },

  9: {
    icon: ['app:eggCracked', 8.41, 10.17],
    styles: { '--egg-crack-1': 'block', '--egg-crack-2': 'block' },
    duration: 1000,
    audio: 'egg-roll',
    progress: 5,
  },
  10: {
    icon: ['app:eggHatching', 8.41, 10.17],
    styles: {},
    audio: 'egg-crack',
    progress: 5,
  },

  11: {
    icon: ['app:eggHatching', 8.41, 10.17],
    styles: {},
    duration: 1000,
    audio: 'egg-roll',
    progress: 6,
  },
  12: {
    icon: ['app:eggChick', 12, 15],
    progressWidth: 8.41,
    styles: {},
    audio: 'chick tweet',
    progress: 6,
    end: true,
  },
};

class ResizeDirective extends Directive {
  constructor(partInfo) {
    super(partInfo);
    if (partInfo.type !== PartType.ELEMENT) {
      throw new Error('The `resizeDirective` directive must be used on Element');
    }
  }

  update(part) {
    const firstUpdate = this._host === undefined;
    if (firstUpdate) {
      this._host = part.options?.host;

      this.element = part.element;

      this._host.observer?.observe(this.element);
    }

    return this.render();
  }

  render() {
    return noChange;
  }
}

const resizeDirective = directive(ResizeDirective);

/**
 * @description
 * Uses layoutRect to scale the progress items according to layout specs.
 *
 * To set progress locations, uses slopeRect (defined on the chicken coop), to find beginning and end of the slope.
 *
 * Each state picks a progress item, which has properties for audio, icon, any icon styles, and its progress.
 *
 * The last progress property is used to locate the first progress item with a progress greater or equal
 * thus allowing the progress item to start part way through the states.
 *
 * When an animation completes, it checks to see if it has reached the max progress for the given progress value,
 * or has run out of progress items, in which case it stops and is done.
 */
export class ChickenTaskProgress extends LitElement {
  static properties = {
    styles: { state: true },
    layoutRect: { type: Object },
    slopeRect: { state: true },
    progressItem: { state: true },
    state: { state: true },
    progress: { type: Number },
    lastProgress: { type: Number },
    showLayout: { type: Boolean },
  };

  constructor() {
    super();

    // /** @type {Object[]} */
    this.items = [];
    this.animateDisabled = false;
    this.state = 0;
    this.haveState = false;
    this.layoutRect = {};
    this.styles = {};

    this.slopeRect = {};
    this.slopeEl = undefined;
    this.progress = 0;
    this.lastProgress = 0;
    this.progressItem = graphics.app.eggCracked;
    this.progressIcon = '';
    this.showLayout = false;

    this.progressHeight = 1;

    new ContextConsumer(this, {
      context: chickenTaskContext,
      callback: value => (this.taskController = value),
    });

    this.observer = new ResizeController(this, {
      target: undefined,
      callback: () => {
        if (!this.slopeEl) {
          this.slopeEl = this.renderRoot.querySelector('.slope-rect');
        }
        const slopeRect = this.slopeEl.getBoundingClientRect();

        if (slopeRect) {
          this.slopeRect = slopeRect;
        }
      },
    });

    this.items = this.makeUIItems(progressBackground);
    this.duration = 2000;
  }

  /**
   * @param {number} milliseconds
   */
  delay(milliseconds) {
    // return gameDelay(milliseconds, { signal: this.abortController.signal });
    return gameDelay(milliseconds, {});
  }

  playSound(key, loop = false) {
    return key && this.taskController.playSound(key, { loop });
  }

  /**
   * @param {Map<string,any>} changed
   */
  willUpdate(changed) {
    if (changed.has('layoutRect') || changed.has('slopeRect') || changed.has('state')) {
      if (!this.haveState) {
        this.haveState = true;
        const initialProgress = Math.floor(this.lastProgress * 6);

        const item = Object.entries(progressItems).find(([, value]) => {
          return value.progress >= initialProgress;
        });

        this.state = Number(item[0]);
      }

      const item = progressItems[this.state];
      const [progressIcon, w, h] = item.icon;

      this.duration = item.duration;

      const { width: layoutWidth, height: layoutHeight } = this.layoutRect;
      const width = layoutWidth > 0 ? (layoutWidth * w) / 100 : 200;
      const height = layoutHeight > 0 ? (layoutHeight * h) / 100 : 200;

      this.progressHeight = height;
      this.progressWidth = item.progressWidth ? (layoutWidth * item.progressWidth) / 100 : width;
      this.styles.width = `${width}px`;
      this.styles.height = `${height}px`;

      if (progressIcon !== this.progressIcon) {
        const [key, value] = progressIcon.split(':', 2);
        this.progressIcon = progressIcon;
        this.progressItem = graphics[key][value];
      }

      if (item.styles) {
        this.styles = { ...this.styles, ...item.styles };
      }

      switch (item.progress) {
        case 0: {
          this.styles['--pg-left'] = `${this.slopeRect.left}px`;
          this.styles['--pg-top'] = `${this.slopeRect.top - height + height * 0.15}px`;

          break;
        }
        case 1: {
          this.styles['--pg-left'] = `${this.slopeRect.right}px`;
          this.styles['--pg-top'] = `${this.slopeRect.bottom - height}px`;

          break;
        }
        default: {
          this.styles['--pg-top'] = `${this.slopeRect.bottom - height}px`;

          const space = document.body.clientWidth - this.slopeRect.right - this.progressWidth * 2;

          const factor = Math.min(1, (item.progress - 1) * (1 / 5));

          this.styles['--pg-left'] = `${this.slopeRect.right + space * factor}px`;

          break;
        }
      }
    }
  }

  render() {
    return html`${this.renderItems()} ${this.renderProgress()}`;
  }

  scaledWidth(itemWidth) {
    const { width: layoutWidth } = this.layoutRect;
    if (itemWidth === 100) {
      return '100%';
    }
    const sw = layoutWidth > 0 ? (layoutWidth * itemWidth) / 100 : 200;

    return `${sw}px`;
  }

  scaledHeight(itemHeight) {
    const { height: layoutHeight } = this.layoutRect;
    const sh = layoutHeight > 0 ? (layoutHeight * itemHeight) / 100 : 200;

    return `${sh}px`;
  }

  renderItems() {
    const items = this.items.map(item => {
      const style = {
        bottom: '0',
        width: this.scaledWidth(item.width),
        height: this.scaledHeight(item.height),
      };

      if (item.left === 100) {
        style.right = '0';
      } else {
        style.left = `${item.left}%`;
      }

      const itemClasses = { 'item-wrapper': 1 };
      if (this.showLayout) {
        itemClasses.border = 1;
      }

      return html` <div class=${classMap(itemClasses)} style=${styleMap(style)}>${item.icon}</div> `;
    });

    return items;
  }

  renderProgress() {
    if (!this.layoutRect?.width || !this.slopeRect?.width) {
      return nothing;
    }

    const progressClasses = { progress: 1 };
    if (this.showLayout) {
      progressClasses.border = 1;
    }

    return html`
      <div
        class=${classMap(progressClasses)}
        style=${styleMap(this.styles)}
        ${resizeDirective()}
        ${animate({
          disabled: this.animateDisabled,
          keyframeOptions: {
            duration: this.duration,
          },
          in: fadeIn,
          properties: ['left', 'top', 'opacity'],
          onComplete: () => this.onComplete(),
        })}
      >
        ${this.progressItem}
      </div>
    `;
  }

  async onComplete() {
    this.taskController.stop(this.audioPromise, 0);

    const maxProgress = Math.ceil(this.progress * 6);
    const nextState = this.state + 1;
    const item = progressItems[nextState];

    // if run out of items or position is for a progress we haven't reached
    // then we are done.
    if (!item || item.progress > maxProgress) {
      await this.delay(1000);
      this.dispatchEvent(new Event('done'));
      return;
    }

    if (item.end) {
      this.animateDisabled = true;
    }

    this.state = nextState;
    this.audioPromise = this.playSound(progressItems[this.state].audio);

    if (item.duration === undefined) {
      await this.audioPromise;

      return this.onComplete();
    }
  }

  makeUIItems(items) {
    const uiItems = items.map(([left, top, width, height, graphic]) => {
      const [key, value] = graphic.split(':', 2);

      const icon = graphics[key][value];
      return { left, top, width, height, icon };
    });

    return uiItems;
  }
}
const OncChickenTaskProgress = class OncChickenTaskProgress extends ChickenTaskProgress {
  static styles = css`
    .progress {
      position: absolute;
      left: var(--pg-left, 2%);
      top: var(--pg-top, 90%);
    }

    .progress svg {
      position: absolute;
      /* background: yellow; */
      width: 100%;
      height: 100%;
    }

    .progress.border svg {
      border: 1px solid yellow;
    }

    .item-wrapper {
      position: absolute;
      /* border: 1px solid red; */
    }

    .item-wrapper svg {
      position: absolute;
      /* background: cyan; */
      width: 100%;
      height: 100%;
    }

    .item-wrapper.border {
      border: 1px solid red;
    }

    .item-wrapper.border svg {
      border: 1px solid violet;
    }

    .test {
      background: red;
      position: absolute;
      top: 50%;
      width: 50%;
      height: 50%;
      left: 50%;

      border-radius: 20px;
    }
  `;
};

customElements.define('onc-chicken-task-progress', OncChickenTaskProgress);
