// some browsers don't support global this, so ensure it is available
import '@shared/globalthis/index.js';
import '@shared/object-entries/index.js';
import { html, render } from 'lit';
import '~/app/app';
import '~/app/app-root';

// eslint-disable-next-line unicorn/prefer-top-level-await
(async function main() {
  // @ts-ignore: Property 'UrlPattern' does not exist
  if (!globalThis.URLPattern) {
    await import('urlpattern-polyfill');
  }

  render(html` <onc-app-root></onc-app-root> `, document.body);
})();
