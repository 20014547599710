import { animate } from '@lit-labs/motion';
import { ContextConsumer } from '@lit/context';
import { Task } from '@lit/task';
import { ElapsedTimer, shuffle } from '@ounce/onc';
import gameDelay from 'delay';
import { LitElement, css, html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { createRef, ref } from 'lit/directives/ref.js';
import { styleMap } from 'lit/directives/style-map.js';
import { when } from 'lit/directives/when.js';
import { graphics } from '~/graphics/index.js';
import { motorTaskContext } from './motor-context.js';

/**
 * @typedef {import('./motor-types.js').MotorBlock} MotorBlock
 * @typedef {import('./motor-types.js').TrialwithIndex} TrialwithIndex
 * @typedef {import('./motor-types.js').Trial} Trial
 * @typedef {import('./specs/motor-phase-setting.js').MotorPhaseSetting} MotorPhaseSetting
 *
 * @typedef {{
 *   r: number,
 *   c: number,
 *   icon?: any;
 *   css?: any;
 *   selected?: boolean
 * }} Item
 */

export class MotorPresenter extends LitElement {
  static properties = {
    block: { type: Object },
    phaseSettings: { type: Object },
    items: { state: true },
    fingerStyles: { state: true },
    fingerShow: { state: true },
  };

  constructor() {
    super();

    this.items = [];
    this.soundController = undefined;

    this.contentRef = createRef();
    this.cellRef = createRef();

    /**
     * @type {Generator<TrialwithIndex,void, unknown>}
     */
    this.trialsIt = undefined;

    /**
     * @type {TrialwithIndex}
     */
    this.trial = undefined;
    this.trialResponses = [];
    this.trialNumber = 0;
    this.showLayout = false;

    /** @type {MotorBlock} */
    this.block = undefined;

    /** @type {MotorPhaseSetting} */
    this.phaseSettings = undefined;

    this.responseTimer = new ElapsedTimer();

    new ContextConsumer(this, {
      context: motorTaskContext,
      callback: value => (this.taskController = value),
    });

    this.noResponseCount = 0;
    this.maxNoResponseExceeded = false;
    this.progressValue = 0;
    this.contentStyles = {};
    this.fingerStyles = {};

    this.fingerShow = false;

    this.getTrials = new Task(this, {
      /**
       *
       * @param {[MotorBlock, MotorPhaseSetting]} param0
       */
      task: ([block, phaseSettings]) => {
        this.stimuli = this.taskController.stimuli;
        const { type: phaseType, maxNoResponseTrials } = phaseSettings;
        this.phaseType = phaseType;
        this.maxNoResponseTrials = maxNoResponseTrials;

        const multiplier = this.taskController.taskSettings.hitTest ?? 1;
        const size = 5 * multiplier;

        this.hitTestStyles = { width: `${size}em`, height: `${size}em` };

        this.showLayout = this.taskController.showLayout;

        this.contentStyles['--motor-cols'] = block.cols;
        this.contentStyles['--motor-rows'] = block.rows;

        if (!this.abortController) {
          this.abortController = new AbortController();
        }

        this.trialsIt = this.trialsGenerator(this.makeTrials(block));
        this.trialNumber = 0;

        this.fingerStyles.top = '90.75%';
        this.fingerStyles.left = '44.34%';
        this.fingerStyles.width = '11.48%';
        this.fingerStyles.height = '23.32%';

        this.fingerStyles['--tap-display'] = 'none';

        this.next().catch(error => {
          if (error?.name !== 'AbortError') {
            console.log(error);
          } else if (this.running) {
            this.done();
          }
        });
      },
      args: () => [this.block, this.phaseSettings],
    });
  }

  /**
   * @param {number} milliseconds
   */
  delay(milliseconds) {
    return gameDelay(milliseconds, { signal: this.abortController.signal });
  }

  /**
   * @param {Trial[]} trials
   * @returns {Generator<TrialwithIndex,void, unknown>}
   */
  *trialsGenerator(trials) {
    let index = 0;
    const theTrials = trials;
    const length = trials.length;

    while (index < length) {
      yield { index, progress: index / length, ...theTrials[index++] };
    }
  }

  /**
   * @param {string} key
   */
  playSound(key) {
    return this.taskController.playSound(key);
  }

  async next() {
    await this.taskController.pushItem({
      startBlock: new Date(),
      block: this.block.id,
    });

    if (this.phaseType === 'animation') {
      await this.playSound('animation block cue');
    } else {
      if (this.phaseType === 'practice') {
        await this.playSound('demo block cue 1');
      }

      const cueKey = `block cue`;

      await this.playSound(cueKey);
    }

    if (this.abortController.signal.aborted) {
      return;
    }

    this.running = true;

    for (const trial of this.trialsIt) {
      this.trial = trial;

      this.updateProgressValue(trial.progress);

      const { maxNoResponseExceeded, trialData } = await this.runTrial(this.trial);

      this.maxNoResponseExceeded = maxNoResponseExceeded;

      await this.taskController.pushItem(trialData);

      this.trialResponses.push(trialData);

      if (maxNoResponseExceeded || this.abortController.signal.aborted) {
        break;
      }
    }

    await this.taskController.pushItem({
      endBlock: new Date(),
    });
    this.done();
  }

  /**
   * @param {number} progress
   */
  updateProgressValue(progress) {
    this.progressValue = progress;
    this.dispatchEvent(new Event('progress'));
  }

  /**
   * @param {TrialwithIndex} trial
   */
  async handleNoResponse(trial) {
    this.items = [];
    const elapsed = this.responseTimer.elapsed();

    trial.response = { trial: trial.index, elapsed, noResponse: true, target: `r:${trial.r} c:${trial.c}` };

    this.noResponseTimer = undefined;

    await this.playSound(`no response`);

    const maxNoResponseTrials = this.maxNoResponseTrials ?? 3;

    this.noResponseCount += 1;
    if (this.noResponseCount >= maxNoResponseTrials) {
      return { maxNoResponseExceeded: true, trialData: trial.response };
    }

    return { trialData: trial.response };
  }

  /**
   * @param {TrialwithIndex} trial
   */
  async runTrial(trial) {
    const mode = this.taskController.mode;
    const durations = this.taskController.durations;

    if (this.phaseType === 'animation') {
      await this.playSound('animation fixation cue');
    } else {
      const audioPromise = mode === 'demo' ? this.playSound('demo fixation cue') : undefined;

      await Promise.all([this.delay(durations.fixation), audioPromise]);
    }

    this.items = this.makeUIItems(trial);

    const runPromise = new Promise(resolve => {
      this.runResolve = resolve;
    });

    if (this.phaseType === 'animation') {
      await this.updateComplete;

      requestAnimationFrame(() => this.cellChanged());
    } else {
      this.responseTimer.start();

      this.noResponseTimer = setTimeout(async () => {
        const response = await this.handleNoResponse(trial);

        this.runResolve(response);
      }, durations.stimulus);
    }

    return runPromise;
  }

  /**
   *
   * @param {Item} item
   */
  async handleClick(event, item, cellIndex) {
    if (this.phaseType === 'animation' && !event.detail?.finger) {
      return;
    }

    return this.handleClickAction(item, cellIndex);
  }

  /**
   * @param {Item} item
   * @param {number} cellIndex
   */
  async handleClickAction(item, cellIndex) {
    const elapsed = this.responseTimer.elapsed(); // - this.onsetTime;
    const durations = this.taskController.durations;
    clearTimeout(this.noResponseTimer);
    this.noResponseCount = 0;

    this.items = [];

    const trialCorrect = item.r !== -1;

    const soundKey = trialCorrect ? `ball sound` : this.phaseType === 'practice' ? 'bad feedback demo' : 'bad feedback';

    if (trialCorrect) {
      this.trial.response = {
        trial: this.trial.index,
        elapsed,
        isCorrect: true,
        target: `r:${item.r} c:${item.c}`,
        stimulusId: this.trial.stimulusId,
      };
    } else {
      const r = Math.floor(cellIndex / this.block.rows);
      const c = cellIndex % this.block.cols;

      this.trial.response = {
        trial: this.trial.index,
        elapsed,
        isCorrect: false,
        target: `r:${this.trial.r} c:${this.trial.c}`,
        hitCell: `r:${r} c:${c}`,
      };
    }

    const soundPromise = this.playSound(soundKey);

    const feedbackPromise = this.delay(durations.feedback);

    await Promise.all([soundPromise, feedbackPromise]);

    this.runResolve({ trialCorrect, trialData: this.trial.response });
  }

  done() {
    this.running = false;
    this.dispatchEvent(new Event('next'));
  }

  render() {
    return html`${this.getTrials.render({
      complete: () => {
        return html`
          <div class="content" style=${styleMap(this.contentStyles)}>
            ${when(
              this.fingerShow,
              () => html`
                <div
                  ${animate({
                    // in: fadeIn,
                    keyframeOptions: {
                      duration: 2000,
                    },
                    properties: ['left', 'top'],

                    onComplete: () => this.onComplete(),
                  })}
                  style=${styleMap(this.fingerStyles)}
                  ${ref(this.contentRef)}
                  class="finger-wrapper"
                >
                  <onc-svg-icon class="finger-icon">${graphics.app.fingerTap}</onc-svg-icon>
                </div>
              `,
            )}
            ${this.renderItems()}
          </div>
        `;
      },
    })}`;
  }

  renderItems() {
    const border = this.showLayout;
    const items = this.items.map(
      (item, index) => html`
        ${when(
          item.r === -1,
          () => html` <div class="motor-cell" @click=${event => this.handleClick(event, item, index)}></div>`,
          () => html`
            <div class="motor-cell">
              <div
                class=${classMap({ 'motor-cell-hittest': true, border })}
                style=${styleMap(this.hitTestStyles)}
                @click=${event => this.handleClick(event, item, index)}
                ${ref(this.cellRef)}
              >
                <div class=${classMap({ ball: true, [`${item.css}`]: true })}>
                  <onc-svg-icon>${item.icon}</onc-svg-icon>
                </div>
              </div>
            </div>
          `,
        )}
      `,
    );
    return items;
  }

  onComplete() {
    this.fingerStyles = { ...this.fingerStyles, ['--tap-display']: 'block' };

    setTimeout(() => {
      const click = new CustomEvent('click', { bubbles: true, detail: { finger: true } });
      this.cellRef.value.dispatchEvent(click);

      this.fingerShow = false;
      this.fingerStyles = { ...this.fingerStyles, top: '90.75%', left: '44.34%', ['--tap-display']: 'none' };
    }, 500);
  }

  cellChanged() {
    this.fingerShow = true;

    const cellElement = /** @type {HTMLElement}*/ (this.cellRef.value);
    const offsetParent = cellElement.offsetParent;

    const parentRect = offsetParent.getBoundingClientRect();
    const cellRect = cellElement.getBoundingClientRect();

    const relativeTop = cellRect.top - parentRect.top;
    const relativeLeft = cellRect.left - parentRect.left;

    const fw = (parentRect.width * 11.48) / 100;
    const fh = (parentRect.height * 23.32) / 100;

    const top = `${relativeTop + cellRect.height / 2 - fh * 0.2}px`;
    const left = `${relativeLeft + cellRect.width / 2 - fw * 0.4}px`;

    setTimeout(() => {
      this.fingerStyles = { ...this.fingerStyles, top, left };
    }, 100);
  }

  /**
   * @param {{ rows: number, cols: number }} block
   */
  makeTrials(block) {
    if (!block) {
      return [];
    }
    const { rows, cols } = block;

    const trials = [];

    for (let r = 0; r < rows; r++) {
      for (let c = 0; c < cols; c++) {
        const stimulusId = this.stimuli.next().value;

        const trial = { r, c, stimulusId, response: [] };
        trials.push(trial);
      }
    }

    return shuffle.getRandomSubarray(trials);
  }

  /**
   * @param {number} r
   * @param {number} c
   * @param {{name: string, n: number}} stimulusId
   */
  makeUIItem(r, c, stimulusId) {
    const { name, n } = stimulusId;
    const icon = graphics.motor[name];
    const css = `motor-c${n}`;

    return { r, c, icon, css, id: 'n/a' };
  }

  /**
   * @param {TrialwithIndex} trial
   */
  makeUIItems(trial) {
    const { r, c, stimulusId } = trial;

    /**
     * @type {Item[]}
     */
    const uiItems = [];

    for (let row = 0; row < this.block.rows; row++) {
      for (let col = 0; col < this.block.cols; col++) {
        if (row === r && col === c) {
          uiItems.push(this.makeUIItem(r, c, stimulusId));
        } else {
          uiItems.push({ r: -1, c: -1 });
        }
      }
    }

    return uiItems;
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.abortController.abort('disconnected');
    clearTimeout(this.noResponseTimer);
    this.runResolve?.();
  }
}

const OncMotorPresenter = class OncMotorPresenter extends MotorPresenter {
  static styles = css`
    :host {
      position: relative;

      display: flex;
      flex-direction: column;

      width: 100%;
      height: 100%;
    }

    .stimulus {
      border-radius: 7px;
    }

    .selected {
      background-color: rgb(255 255 255 / 60%);
    }

    .content {
      display: grid;
      grid-template-columns: repeat(var(--motor-cols, 3), 1fr);
      grid-template-rows: repeat(var(--motor-rows, 3), 1fr);
      flex: 1;

      padding: 2rem;

      overflow: hidden;
    }

    .ball {
      width: 5em;
      height: 5em;
    }

    .motor-cell-hittest {
      display: flex;
      align-items: center;
      justify-content: center;

      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    .motor-cell-hittest.border {
      border: 1px solid cyan;
    }

    .motor-cell {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 100%;
      height: 100%;

      /* border: 1px solid #222; */
    }

    .motor-c0 {
      --motor-fill: #05429bff;
    }

    .motor-c1 {
      --motor-fill: #006837ff;
    }

    .motor-c2 {
      --motor-fill: #582abcff;
    }

    .motor-c3 {
      --motor-fill: #ba1a26ff;
    }

    .motor-c4 {
      --motor-fill: #e5bc55ff;
    }

    .motor-c5 {
      --motor-fill: #8c7691ff;
    }

    .motor-c6 {
      --motor-fill: #9e005dff;
    }

    .motor-c7 {
      --motor-fill: #754c24ff;
    }

    .motor-c8 {
      --motor-fill: #f97923ff;
    }

    .motor-c9 {
      --motor-fill: #4d4d4dff;
    }

    .finger-wrapper {
      position: absolute;
      top: 50%;
      left: 45%;

      width: 5%;
      height: auto;
    }

    .finger-wrapper[noshow] {
      visibility: hidden;
    }
  `;
};
customElements.define('onc-motor-presenter', OncMotorPresenter);
