import { html } from 'lit';

export const waterTower = html`
  <svg
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    viewBox="0 0 450 1500"
  >
    <path d="m370.2 446h-290.6v-321.8h290.6v321.8z" fill="#ff2a00" />
    <g fill="#3c4b54">
      <path d="m400.1 142h-355.5l177.9-131.3 177.6 131.3z" />
      <path d="m381.4 456h-314.9v-23.43h314.9v23.43z" />
      <path d="m381.4 401.2h-314.9v-6.38h314.9v6.38z" />
      <path d="m291.6 591.6h-137.1v-6.479h137.1v6.479z" />
      <path d="m85.15 439.7c11.44 66.58 69.39 117.2 139.2 117.2 69.78 0 127.8-50.69 139.2-117.2h-278.4z" />
      <path d="m27.45 1485-27.46-2 112.2-1030 27.4 3.5-112.2 1030z" />
      <path d="m422.6 1485-112.3-1030l27.46-3.044 112.3 1030-27.44 2.99z" />
      <path d="m229.3 1499h-13.82v-1036h13.82v1036z" />
      <path d="m362.7 769.1h-264.3v-13.81h264.3v13.81z" />
      <path d="m403.8 1151h-346.5v-13.71h346.5v13.71z" />
      <path d="m354 763.4-229.9-307.6 2.762-1.999 229.8 307.6-2.617 1.993z" />
      <path d="m92.76 763.4-2.762-1.999 229.9-307.6 2.762 1.999-229.9 307.6z" />
      <path d="m58.23 1143-2.762-2.292 299-379.8 2.663 2.185-298.9 379.8z" />
      <path d="m396.3 1143-299-379.7l2.664-2.185 299 379.9-2.617 2.243z" />
      <path d="m437.5 1485-381.8-339.4 2.283-2.673 381.8 339.4-2.243 2.616z" />
      <path d="m9.749 1485-2.283-2.673 381.8-339.4 2.293 2.673l-381.8 339z" />
      <path d="m291.6 647.4h-137.1v-22.67h137.1v22.67z" />
      <path d="m226.8 658.8h-116.6v-11.43h116.6v11.43z" />
    </g>
    <path d="m349 437.2-33.74-36.78 2.576-2.282 31.07 33.81 29.35-34.58 2.667 2.193-31.93 37.63z" fill="#3a3a3a" />
    <path d="m286.7 437.2-33.74-36.78 2.576-2.282 31.07 33.81 29.35-34.58 2.667 2.193-31.93 37.63z" fill="#3a3a3a" />
    <path d="m255.5 627.3-33.74-36.68 2.568-2.39 31.07 33.84 29.36-34.69 2.654 2.292-31.92 37.63z" fill="#3c4b54" />
    <path d="m191.9 627.3-33.74-36.68 2.576-2.39 30.97 33.84 29.45-34.69 2.667 2.292-31.93 37.63z" fill="#3c4b54" />
    <g fill="#3a3a3a">
      <path d="m223.1 437.2-33.65-36.78 2.576-2.282 30.97 33.81 29.45-34.58 2.667 2.193-32.02 37.63z" />
      <path d="m160 437.2-33.64-36.78 2.578-2.282 30.97 33.81 29.45-34.58 2.568 2.193-31.93 37.63z" />
      <path d="m100.1 437.2-33.74-36.78 2.577-2.282 31.06 33.81 29.36-34.58 2.568 2.193-31.83 37.63z" />
    </g>
    <path
      d="m231.8 19.92c0 4.001-3.241 7.238-7.241 7.238h-2.957c-3.904 0-7.144-3.238-7.144-7.238v-12.77c0-4.001 3.241-7.151 7.144-7.151h2.957c4.007 0 7.248 3.152 7.248 7.151v12.77z"
      fill="#3c4b54"
    />
    <path
      opacity=".35"
      d="m229.3 1296v-6.293l155.4-138.1h0.5758l34.22 313.6-190.3-170zm0-144.4h150.2l-150.2 133.6v-133.6zm0-219.3 153.4 195 1.24 10.58h-154.6v-205.5zm0-6.728 115.5-146.7 37.25 341.9-152.7-194v-1.141zm0-156.1h114.4l0.4786 4.575-114.9 145.9v-150.6zm0-121.9h38.14l73.77 98.79 0.9572 9.239h-112.9v-108zm0-47.92 11.82 12.86 9.252 12.39h-21.08v-25.26zm25.45 22.58-10.96-11.81-13.74-18.48h50.41l-25.73 30.3zm-25.43-41.76 19.34-25.81c25.73-4.477 49.08-16.1 68.05-32.59l23.73 217.7-68.72-92.05h19.25v-22.67h-33.94l27.92-32.97h6.012v-6.478h-61.67v-5.152zm0-23.82c4.958-0.1944 9.721-0.6628 14.5-1.337l-14.5 19.44v-18.06zm140-154.6h5.243l-5.243 6.194v-6.194zm-27.87 91.95c7.623-11.33 13.52-23.82 17.63-37.07h22.12v-23.43h-11.92v-19.91l9.722-11.53h2.196v-6.38h-11.92v-253.8h30.89l-169-124.8v-9.434c0-4.001-3.24-7.151-7.241-7.151h-1.913v1500h7.34v-199.1l190.8 169.6 1.717 15.54 14.11-1.521 1.24 1.141 1.142-1.337 10.96-1.238-107.9-989.1z"
      fill="#510400"
    />
  </svg>
`;
