import { RBAC } from '@ounce/onc';
import { apiDefaults } from '@state/api.js';
import { CurrentUser } from '@state/current-user';
import { Store } from '@state/idb-database.js';

/**
 * @typedef {import('lit').ReactiveControllerHost} ReactiveControllerHost
 * @typedef {import('lit').ReactiveElement} ReactiveElement
 */

export class AuthController {
  /**
   *
   * @param {ReactiveControllerHost & ReactiveElement} host
   */
  constructor(host, roles, callback) {
    this.stateStore = new Store('profile');
    this.currentRbac = new RBAC(roles);

    this.callback = callback;

    (this.host = host).addController(this);
  }

  makeCurrentUser = newValue => {
    const { accessToken } = newValue || {};
    setDefaultAuthHeaders(accessToken);

    this.user = new CurrentUser(newValue, (role, operation, parameters) =>
      this.currentRbac.canSync(role, operation, parameters),
    );

    this.callback?.(this.user);
  };

  handleUserUpdate = async event => {
    const { detail: newValue } = event;
    await this.stateStore.set('auth.currentUser', newValue);

    this.makeCurrentUser(newValue);
  };

  hostConnected() {
    (async () => {
      const userInfo = await this.stateStore.get('auth.currentUser');
      this.makeCurrentUser(userInfo);
    })();

    this.host.addEventListener('user-update', this.handleUserUpdate);

    document.body.addEventListener('user-update', event => this.bodyHandler(event));
  }

  bodyHandler(event) {
    console.log(event);

    this.handleUserUpdate(event);
  }

  hostDisconnected() {
    this.host.removeEventListener('user-update', this.handleUserUpdate);
  }
}

// ===
// Private functions
// ===

function setDefaultAuthHeaders(accessToken) {
  apiDefaults.headers.common.Authorization = accessToken ? `Bearer ${accessToken}` : '';
}
