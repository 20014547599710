/**
 * @typedef {import('lit').ReactiveControllerHost} ReactiveControllerHost
 */

export class ViewController {
  host;
  _view;
  value = {};

  views = [];
  _currentView;
  _props = [];
  currentViewname;

  /**
   *
   * @param {ReactiveControllerHost} host
   * @param {{name: string, render: (...props)=> unknown}[]} views
   */
  constructor(host, views) {
    (this.host = host).addController(this);
    this.views = [...views];

    this._currentView = views[0];
    this.currentViewname = views[0].name;
  }

  goto(value, ...props) {
    this.currentViewname = '';

    const view = this.getView(value);

    if (view === undefined) {
      throw new Error(`No view found for ${value}`);
    }

    this._props = props;
    this._currentView = view;
    this.currentViewname = value;

    this.host.requestUpdate();
  }

  getView(viewName) {
    const matchedView = this.views.find(({ name }) => name === viewName);

    return matchedView;
  }

  outlet() {
    return this._currentView?.render?.(...this._props);
  }

  hostConnected() {}

  hostDisconnected() {}
}
