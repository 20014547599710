import { html } from 'lit';

export const eggCracked = html`
  <svg version="1.1" viewBox="0 0 190 150" xmlns="http://www.w3.org/2000/svg">
    <path
      class="egg-shell"
      d="m72.34 146.9c35.4 7.962 102.5 2.918 115.8-40.4 12.25-39.72-39.32-85.01-73.24-97.71-68.16-25.47-101.9 9.642-111.8 41.62-9.615 31.17 0.2104 80.97 69.22 96.48"
      fill="#dda16b"
      stroke-width="2.075"
    />
    <path
      class="egg-spots"
      d="m82.38 120.3c1.204-3.904 5.374-6.1 9.314-4.908s6.16 5.327 4.953 9.232c-1.204 3.904-5.374 6.1-9.314 4.908-3.938-1.194-6.154-5.327-4.951-9.232m-58.8-36.89c1.283-4.166 5.73-6.51 9.934-5.236 4.2 1.272 6.568 5.682 5.28 9.847-1.283 4.164-5.73 6.51-9.934 5.236-4.2-1.275-6.568-5.682-5.28-9.847m46.42-2.167c1.084-3.516 4.836-5.492 8.381-4.418 3.547 1.074 5.54 4.794 4.457 8.307-1.084 3.516-4.836 5.492-8.381 4.418-3.547-1.074-5.54-4.794-4.457-8.307m52.24 25.36c-1.444-0.4378-2.258-1.952-1.817-3.385 0.4417-1.431 1.971-2.237 3.415-1.799 1.444 0.4378 2.257 1.952 1.815 3.385-0.4417 1.433-1.971 2.237-3.413 1.799m29.59 4.331c-1.125 3.646-5.015 5.696-8.694 4.581-3.675-1.114-5.745-4.969-4.62-8.618 1.123-3.643 5.013-5.695 8.694-4.581 3.675 1.114 5.745 4.972 4.622 8.618m-112.2-66.21c0.844-2.735 3.761-4.271 6.52-3.437 2.757 0.8366 4.309 3.728 3.465 6.463-0.844 2.733-3.761 4.269-6.52 3.435-2.759-0.8366-4.309-3.728-3.467-6.463m39.45-1.448c1.485-4.815 6.627-7.529 11.49-6.053 4.859 1.472 7.589 6.569 6.107 11.39-1.484 4.815-6.627 7.529-11.49 6.053-4.859-1.472-7.589-6.569-6.107-11.39m51.42 11.98c-0.7625 2.472-3.402 3.865-5.897 3.109-2.496-0.7558-3.9-3.373-3.138-5.847 0.7648-2.475 3.402-3.865 5.899-3.109 2.494 0.7558 3.899 3.373 3.136 5.847m19.92 19.69c-0.4022 1.301-1.792 2.034-3.105 1.636-1.313-0.3966-2.051-1.776-1.65-3.075 0.4022-1.303 1.792-2.034 3.105-1.638 1.313 0.3986 2.052 1.776 1.65 3.078m-27.55-47.26c0.5209-1.691 2.329-2.644 4.034-2.128 1.706 0.5164 2.667 2.309 2.146 4.001-0.5209 1.69-2.329 2.644-4.036 2.125-1.704-0.5164-2.667-2.309-2.144-3.999"
      fill="#d3bda3"
      stroke-width="2.075"
    />
    <path
      display="var(--egg-crack-1, none)"
      d="m83.439 1.6914-1.2031 20.779-0.15625 2.7168 2.6074-0.42188 24.574-3.9629-8.4883 30.367-0.14063 0.50781 0.44336 0.23047 25.709 13.4-24.629-14.244 6.2324-18.451 11.719 1.8379-2.0234 12.68-0.14453 0.90234 0.86914-0.03125 20.76-0.75-19.836-0.68164 3.0215-12.92 0.26757-1.1582-1.252-0.28711-12.289-2.8242 3.3848-10.023 0.76172-2.248-2.4512 0.28516-24.344 2.8242 1.9883-18.078-5.3809-0.44922z"
      fill="#8c6239"
      stroke-width="2.074"
    >
      <title>crack-1</title>
    </path>
    <path
      display="var(--egg-crack-2, none)"
      d="m117.62 97.291 4.7441 7.9902-0.53125 15.404-17.148-2.1387-1.7617-0.21876-0.33008 1.8223-5.2148 28.949 4.7637 0.76172 4.0117-27.383 5.3691 0.33398-0.48047 0.19922 6.5547 14.42 0.85742 1.8848 1.2422-1.5606 8.3594-10.5 2.8438 10.801-1.7832-12.551-0.21289-1.502-1.0742 1.1738-8.6426 9.4629-4.4844-11.631 8.3008 0.51562 1.5488 0.09375-0.07227-1.4336-0.84765-17.07-0.01172-0.22265-0.09766-0.125-5.9023-7.4766z"
      fill="#8c6239"
      stroke-width="2.074"
    >
      <title>crack-2</title>
    </path>
  </svg>
`;
