import { TaskControllerBase } from '@tasks/task-controller-base.js';
import data, { rewardLocations } from './memory-data.js';
import { MemoryRewardSource } from './memory-reward-source.js';
import { MemoryPhaseSetting } from './specs/memory-phase-setting.js';
import { MemorySettings } from './specs/memory-setings.js';

/**
 * @typedef {import('lit').ReactiveControllerHost} ReactiveControllerHost
 * @typedef {import('lit').ReactiveElement} ReactiveElement
 * @typedef {import("./specs/memory-demo.js").MemoryDemo} MemoryDemo
 * @typedef {import('../chicken/specs/chicken-profile.js').ChickenProfile} ChickenProfile
 * @typedef {import('../sheep/specs/sheep-profile.js').SheepProfile} SheepProfile
 *
 */

export class TaskController extends TaskControllerBase {
  /**
   * @param {ReactiveControllerHost & ReactiveElement} host
   * @param {string} taskId
   */
  constructor(host, taskId) {
    super(host, taskId);

    const taskRecord = this.project?.getById(taskId);
    this.taskSettings_ = new MemorySettings(taskRecord);

    this.rewardSrc = new MemoryRewardSource(rewardLocations, { chicken: 'chicken:egg', sheep: 'sheep:s31' });

    this.chickenTaskProfile = /** @type {ChickenProfile} */ (this.profile.taskByType('chicken'));
    this.sheepTaskProfile = /** @type {SheepProfile} */ (this.profile.taskByType('sheep'));

    this.phases = undefined;
    this.totalBlocks_ = undefined;
    this.phaseIndex = 0;
    this.mode_ = 'play';

    const sheepTargets = (this.sheepTaskProfile.targets ?? data.defaultSheepStimuliSet.slice(0, 15)).map(s => `s${s}`);
    const chickenTargets = (this.chickenTaskProfile.targets ?? data.defaultChickenStimuliSet.slice(0, 15)).map(
      s => `c${s}`,
    );

    const sheepDistractors = (this.sheepTaskProfile.distractors ?? data.defaultSheepStimuliSet.slice(15, 30)).map(
      s => `s${s}`,
    );
    const chickenDistractors = (this.chickenTaskProfile.distractors ?? data.defaultChickenStimuliSet.slice(15, 30)).map(
      s => `c${s}`,
    );

    this.trialTargets = [...sheepTargets, ...chickenTargets];
    this.trialDistractorsSheep = sheepDistractors;
    this.trialDistractorsChicken = chickenDistractors;
  }

  get durations() {
    return this.taskSettings.durations;
  }

  get taskSettings() {
    return this.taskSettings_;
  }

  get mode() {
    return this.mode_;
  }
  set mode(value) {
    if (this.mode_ !== value) {
      this.phases = undefined;
    }
    this.mode_ = value;
  }

  get gameName() {
    return 'memory';
  }

  get targets() {
    return this.mode === 'play' ? this.trialTargets : ['c32', 's31'];
  }

  get distractorsChicken() {
    return this.mode === 'play' ? this.trialDistractorsChicken : ['c34'];
  }

  get distractorsSheep() {
    return this.mode === 'play' ? this.trialDistractorsSheep : ['s43'];
  }

  get rewards() {
    return this.rewardSrc.rewards;
  }

  /**
   * @param {{sheep: number, chicken: number}} counts
   */
  addRewards(counts) {
    this.rewardSrc.addRewards(counts);
  }

  getPhases() {
    if (this.phases === undefined) {
      const phases =
        this.mode === 'demo' ? getDemoPhases(this.taskSettings.demo) : getMainPhases(this.taskSettings.phases);

      let totalBlocks = 0;
      for (const phase of phases) {
        totalBlocks += phase.blocks.length;
      }

      this.totalBlocks_ = totalBlocks;
      this.phases = phases;
    }
    return this.phases;

    // return [...phaseGenerator(this.task.phases, this.task.demo, this.mode)];
  }

  get totalBlocks() {
    if (this.totalBlocks_ === undefined) {
      this.getPhases();
    }
    return this.totalBlocks_;
  }

  /**
   * @returns {string}
   */
  get feedbackType() {
    return this.taskSettings_.feedbackType;
  }
}

// ===
// Private functions
// ===

/**
 * @param {MemoryPhaseSetting} phase
 */
function getBlocks(phase) {
  if (phase.type === 'practice') {
    const { trialsPerBlock } = phase;

    return [{ id: 1, trials: trialsPerBlock }];
  }

  const { numberOfBlocks, trialsPerBlock } = phase;
  return Array.from({ length: numberOfBlocks }, (v, id) => ({ id, trials: trialsPerBlock }));
}

/**
 *
 * @param {MemoryDemo} demo
 */
function getDemoPhases(demo) {
  const demoPhase = {
    type: 'practice',
    trialsPerBlock: demo.practice.trials,
    maxNoResponseTrials: demo.practice.maxNoResponseTrials,
    numberOfBlocks: 1,
  };

  const demoPhaseSettings = new MemoryPhaseSetting(demoPhase);

  const blocks = getBlocks(demoPhaseSettings);

  const animationPhase = {
    type: 'animation',
    trialsPerBlock: demo.animation.trials,
    numberOfBlocks: 1,
    maxNoResponseTrials: 0,
  };

  const animationPhaseSettings = new MemoryPhaseSetting(animationPhase);

  const animationblocks = getBlocks(animationPhaseSettings);

  return [
    { index: 0, phaseSettings: animationPhaseSettings, blocks: animationblocks },
    { index: 1, phaseSettings: demoPhaseSettings, blocks },
  ];
}

/**
 * @param {MemoryPhaseSetting[]} phases
 */
function getMainPhases(phases) {
  const items = [];
  const startIndex = 0;
  for (let index = startIndex; index < phases.length; index++) {
    const phase = { ...phases[index] };

    const blocks = getBlocks(phase);
    items.push({ index, isLastPhase: false, phaseSettings: phases[index], blocks });
  }

  items.at(-1).isLastPhase = true;

  return items;
}
