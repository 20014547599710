import { html } from 'lit';

export const c24 = html`
  <svg
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    viewBox="0 0 620 670"
  >
    <path
      d="m488.2 464.6c0 87.2-82.95 158.6-185.2 158.6-102.4 0.1-185.3-71.4-185.3-158.6 0-87.19 82.95-157.9 185.2-157.9 102.3 0 185.2 70.69 185.2 157.9"
      fill="#b3b3b3"
    />
    <g fill="#a67c52">
      <path
        d="m229.5 140.3c8.158-3.126 15.88-2.404 22.88-0.4825 6.99 2.044 13.31 5.736 18.58 10.61 5.274 4.855 9.452 10.96 12.01 17.84 2.442 6.939 3.462 14.61 0.9325 22.9-8.072-3.109-13.66-6.329-18.79-9.599-5.048-3.27-9.376-6.63-13.4-10.4-4.039-3.751-7.765-7.895-11.42-12.75-3.654-4.95-7.111-10.39-10.79-18.12"
      />
      <path
        d="m301.9 90.79c7.692 5.781 12.46 12.64 16.26 19.76 3.675 7.171 6.092 14.74 7.322 22.6 1.201 7.846 1.188 16-0.4825 24.22-1.806 8.101-5.026 16.86-12.48 23.2-4.2-8.531-7.305-15.1-9.768-22.14-2.4-6.93-4.2-13.87-5.3-21.02-1.065-7.151-1.481-14.5-1.012-22.16 0.565-7.704 1.795-15.61 5.421-24.46"
      />
      <path
        d="m331.9 107.5c6.11 6.752 9.142 13.91 11.15 21.1 1.891 7.214 2.46 14.54 1.751 21.85-0.695 7.315-2.69 14.66-6.41 21.68-3.77 6.721-9.38 14.16-18.42 16.75-1.09-8.86-2.704-15.02-3.278-21.72-0.635-6.542-0.7125-12.96-0.0563-19.45 0.6475-6.488 1.978-13.02 4.241-19.69 2.369-6.668 5.421-13.4 11.02-20.5"
      />
    </g>
    <path
      d="m101.9 313.3c10.06-5.778 21.74-8.356 33.96-8.53 6.101 0.1325 12.31 1.02 18.4 2.766 6.036 1.844 11.89 4.534 17.32 7.895 5.425 3.368 10.8 7 15.65 11.25 4.839 4.261 8.562 9.515 10.98 15.24 4.709 11.54 6.365 23.96 2.246 36.82l-2.7 0.86c-10.74-7.965-17.42-15.95-23.9-23.26-3.158-3.668-5.694-7.44-7.964-11.51-2.212-4.101-4.745-8.132-8.039-11.38-3.245-3.298-6.899-6.198-10.95-8.6-3.979-2.536-8.398-4.576-13.12-6.086-9.418-3.282-20.2-4.066-30.85-2.822l-1.03-2.648z"
      fill="#8c6239"
    />
    <path
      d="m98.17 271.8c6.77-5.164 14.41-9.012 22.78-11.51 8.335-2.502 17.52-3.536 26.82-2.118 9.246 1.495 18.28 5.589 25.82 11.35 7.532 5.828 13.59 13.08 18.6 20.66 5.051 7.95 9.044 14.75 13.39 22.24 4.098 7.42 7.291 15.32 9.282 23.74 1.945 8.384 3.189 17.1 2.938 26.09-0.3188 8.872-1.181 18.35-7.008 26.74l-2.676-0.9225c-5.785-15.31-13.66-28.56-20.06-42.78-3.256-7.05-6.044-14.31-8.294-21.92-2.186-7.434-4.471-15.74-7.315-22.08-5.966-13.12-14.92-24.4-28.15-29.39-13.16-5.345-29.85-3.501-44.62 2.312l-1.488-2.418z"
      fill="#754c24"
    />
    <path
      d="m171.9 249.6c7.136-3.074 15.28-3.361 23.4-1.572 4.002 0.9925 7.968 2.536 11.72 4.549 3.664 2.116 7.098 4.674 10.21 7.524 3.124 2.858 6.355 5.709 9.232 8.874 2.834 3.192 4.67 6.972 5.372 11.02 0.6925 4.024 0.695 8.181-0.275 12.35-0.8738 4.15-2.495 8.321-5.806 12.52l-2.788 0.5375c-4.632-2.591-7.819-5.155-10.57-7.67-2.834-2.481-5-4.926-6.865-7.309-1.859-2.389-2.952-4.884-3.496-7.74-0.5175-2.859-1.098-5.806-2.359-8.308-1.236-2.515-2.704-4.911-4.429-7.119-1.645-2.314-3.599-4.465-5.869-6.372-4.444-3.954-10.39-7.014-17-8.51l-0.4688-2.78z"
      fill="#8c6239"
    />
    <path
      d="m299.5 466.2c-1.376-0.035-2.708-0.175-4.031-0.3625-86.8-3.508-133.4-71.05-133.4-71.05-12.76 38.41-12.82 81.62-12.82 81.62 2.009 82.95 69.82 149.5 153.2 149.5v-160c-0.6538 0.0413-1.258 0.195-1.904 0.195h-1.059z"
      fill="#1b1464"
    />
    <path
      d="m196.9 273.4-0.0488-0.0625v0.7962c-0.22 2.844-0.4238 5.701-0.4238 8.608 0 0.8862 0.0913 1.752 0.105 2.64-0.5975 10.7-2.186 27.4-6.84 45.02-3.28 12.36-8.041 25.16-15.1 36.66-5.128 8.384-9.184 17.89-12.44 27.7 0 0 46.62 67.55 133.4 71.05h0.0213c1.306 0.1875 2.641 0.3275 4.01 0.3625h1.059c0.6462 0 1.251-0.1538 1.904-0.195v-289.3c-55.48 0-101 42.56-105.7 96.8"
      fill="#b3b3b3"
    />
    <path
      d="m443.2 396.5s-49.72 69.54-141 69.54v160c83.48 0 151.4-66.72 153.2-149.8 0 0-0.0425-41.82-12.21-79.76"
      fill="#1b1464"
    />
    <path
      d="m430.7 367c-6.445-10.57-11.01-22.25-14.24-33.65-5.534-19.48-7.21-38.19-7.745-49.38 0-0.4325 0.045-0.8525 0.045-1.285 0-1.936-0.1888-3.829-0.2862-5.736-0.0425-2.285-0.0138-3.668-0.0138-3.668l-0.1612 0.2438c-4.664-54.34-50.15-96.99-105.7-96.99v289.3c91.3 0 141-69.54 141-69.54-3.325-10.39-7.528-20.5-12.94-29.39"
      fill="#ccc"
    />
    <g fill="#999">
      <path
        d="m340.7 357.8v-0.0625c0-23.6-21.3-42.7-38.9-42.7-17.59 0-38.92 19.12-38.92 42.71 0 9.445-0.73 34.25 17.11 34.25 5.258 0 9.908-2.424 13.05-6.162 2.949 0.315 5.918 0.4688 8.768 0.4688 2.865 0 5.824-0.1538 8.779-0.4688 3.126 3.739 7.78 6.162 13.05 6.162 17.8 0 17.1-24.7 17.1-34.19"
      />
      <path
        d="m259.5 237.3c-26.09 0-47.24 20.1-47.24 44.88 0 24.8 21.15 44.89 47.24 44.89 19.5 0 36.24 2.844 43.46-13.12v-49.36c-7.218-16.04-23.95-27.28-43.46-27.28"
      />
      <path
        d="m345.7 327c26.09 0 47.25-20.09 47.25-44.89 0-24.78-21.16-44.88-47.25-44.88-19.56 0-36.34 11.29-43.52 27.38-0.015-0.0288-0.0425-0.07-0.0563-0.105v49.36c0.0138-0.0412 0.0413-0.0687 0.0563-0.1112 7.182 16.09 23.95 13.24 43.52 13.24"
      />
    </g>
    <path
      d="m294.5 316.2-11.44 7.545c-4.601 3.04-6.054 9.398-3.231 14.14l17.9 30.08c1.415 2.382 3.276 3.57 5.135 3.57v-57.61c-3.046 0-6.061 0.76-8.37 2.284"
      fill="#f39e2b"
    />
    <path
      d="m308.2 367.9 17.91-30.08c2.83-4.744 1.37-11.1-3.235-14.14l-11.44-7.545c-2.306-1.524-5.338-2.285-8.37-2.285v57.61c1.859 0 3.72-1.188 5.131-3.57"
      fill="#fbdd58"
    />
    <path
      d="m266.9 282.3c0 8.461-6.868 15.31-15.31 15.31-8.46 0-15.31-6.854-15.31-15.31 0-8.446 6.854-15.3 15.31-15.3 8.44 0 15.31 6.854 15.31 15.3"
      fill="#4e1412"
    />
    <path
      d="m366.9 282.3c0 8.461-6.864 15.31-15.31 15.31-8.45 0-15.31-6.854-15.31-15.31 0-8.446 6.86-15.3 15.31-15.3 8.448 0 15.31 6.854 15.31 15.3"
      fill="#4e1412"
    />
    <path
      d="m263.2 276.2c0 2.892-2.348 5.24-5.232 5.24-2.892 0-5.234-2.348-5.234-5.24s2.341-5.232 5.234-5.232c2.885 0 5.232 2.34 5.232 5.232"
      fill="#fff"
    />
    <path
      d="m361.9 276.2c0 2.892-2.338 5.24-5.23 5.24s-5.232-2.348-5.232-5.24 2.34-5.232 5.232-5.232 5.23 2.34 5.23 5.232"
      fill="#fff"
    />
    <path
      d="m231.9 76.16c13.92 7.646 24.54 16.59 34.26 24.15 2.378 1.886 4.736 3.588 6.755 5.006 1.076 0.7612 1.96 1.118 2.931 1.778l3.934 2.614c5.669 3.835 12.23 9.39 17.55 16.09 10.96 13.55 15.95 30.45 16.19 45.69 0.1688 15.42-3.409 30.08-12.09 43.55-8.991-13.24-14.64-25.41-20.45-36.08-5.68-10.77-10.96-19.31-16.82-26.69-3.004-3.804-6.284-7.381-10.74-12.25l-3.5-3.97c-1.502-1.596-3.015-3.578-4.195-5.279-2.526-3.479-4.395-6.982-6.036-10.44-6.229-13.91-9.178-28.15-7.675-44.15"
      fill="#603813"
    />
    <g fill="#7f47dd">
      <path
        d="m158.2 365.4s49.85 93.96 141 91.46c91.2-2.5 152.5-85.42 152.5-85.42s-42.26 181.6-222.4 181.6c-30.24 0-71.25-187.8-71.25-187.8"
      />
      <path
        d="m464.5 371.5c0 6.788-5.502 12.29-12.29 12.29s-12.29-5.504-12.29-12.29c0-6.788 5.502-12.29 12.29-12.29 6.789 0 12.29 5.504 12.29 12.29"
      />
      <path
        d="m473.2 293.3c6.665 5.648 9.742 12.76 11.43 19.82 1.571 7.111 1.374 14.42-0.36 21.39-1.72 6.96-5.064 13.56-9.866 19.11-4.908 5.479-11.14 10.06-19.62 11.83-1.158-8.572-1.024-15.02-0.62-21.09 0.4388-5.999 1.302-11.41 2.669-16.75 1.348-5.345 3.186-10.6 5.681-16.15 2.582-5.584 5.688-11.23 10.69-18.18"
      />
      <path
        d="m521.9 333.2c-0.0362 8.735-3.502 15.66-7.829 21.5-4.434 5.778-10.16 10.33-16.61 13.49-6.435 3.16-13.64 4.845-20.98 4.742-7.351-0.235-14.88-2.06-21.68-7.415 5.819-6.401 10.85-10.45 15.75-14.04 4.875-3.524 9.572-6.342 14.54-8.731 4.959-2.406 10.17-4.382 16.01-6.036 5.936-1.615 12.26-2.871 20.8-3.506"
      />
    </g>
    <g fill-rule="evenodd">
      <path
        d="m375.7 157.3c0.9612-9.531-5.999-18.01-15.49-18.98 0 0-32.71 4.164-49.49 1.919-16.7-2.204-49.5-11.9-49.5-11.9-9.498-0.9588-17.94 5.969-18.9 15.5l-15.5 50.78 64.81 6.538 6.784 0.6838 82.38 8.309-5.076-52.84z"
        fill="#8c6239"
      />
      <path
        opacity=".47"
        d="m234.8 169.2-7.584 24.88 64.81 6.538 6.782 0.6838 82.38 8.309-2.499-25.9-143.9-14.51z"
        fill="#fcee21"
      />
      <path
        d="m144.4 201.2 0.44-4.362c0.9525-9.441 9.426-16.35 18.85-15.4l280.2 28.4c9.276 0.935 16.22 9.505 15.28 18.85l-0.4225 4.181-314.2-31.7z"
        fill="#754c24"
      />
      <path
        opacity=".33"
        d="m443.5 209.4-63.9-6.482-4.42-46.1c0.9612-9.531-5.999-18.01-15.49-18.98 0 0-32.71 4.162-49.49 1.918-0.585-0.0588-1.276-0.2038-1.895-0.305l-7.772 77.06 157.9 15.91 0.4212-4.181c0.975-9.345-5.974-17.91-15.25-18.85"
        fill="#917561"
      />
    </g>
    <g fill="#f0f">
      <path
        d="m234.5 494.5c2.401 0 4.344 1.939 4.344 4.344v7.644c0 2.405-1.942 4.359-4.344 4.359h-21.78c-2.375 0-4.344-1.954-4.344-4.344v-7.659c0-2.405 1.969-4.344 4.344-4.344h21.78z"
      />
      <path
        d="m268.2 494.5c2.401 0 4.341 1.939 4.341 4.344v7.644c0 2.405-1.969 4.359-4.341 4.359h-21.75c-2.405 0-4.374-1.954-4.374-4.359v-7.644c0-2.405 1.969-4.344 4.374-4.344h21.75z"
      />
      <path
        d="m231.9 509.2c0-2.401 1.942-4.344 4.344-4.344h7.644c2.405 0 4.345 1.942 4.345 4.344v21.76c0 2.405-1.94 4.344-4.345 4.344h-7.644c-2.401 0-4.344-1.939-4.344-4.344v-21.76z"
      />
      <path
        d="m231.9 474.4c0-2.405 1.942-4.344 4.344-4.344h7.644c2.405 0 4.345 1.939 4.345 4.344v21.75c0 2.401-1.94 4.356-4.345 4.356h-7.644c-2.401 0-4.344-1.955-4.344-4.356v-21.75z"
      />
    </g>
    <g fill="#d100ff">
      <path
        d="m241.9 492.4c1.708 1.692 1.708 4.445 0 6.138l-5.415 5.415c-1.71 1.696-4.459 1.696-6.14 0l-15.38-15.38c-1.709-1.708-1.709-4.459 0-6.152l5.414-5.418c1.679-1.692 4.43-1.692 6.138 0l15.38 15.39z"
      />
      <path
        d="m265.7 516.9c1.708 1.692 1.708 4.445 0.03 6.138l-5.415 5.415c-1.711 1.696-4.459 1.696-6.168 0l-15.38-15.38c-1.708-1.708-1.708-4.459 0-6.152l5.415-5.414c1.708-1.681 4.46-1.696 6.138 0.0113l15.5 15.5z"
      />
      <path
        d="m230.7 501.5c1.681-1.681 4.43-1.681 6.141 0l5.414 5.414c1.708 1.694 1.708 4.46 0 6.154l-15.38 15.38c-1.708 1.696-4.459 1.696-6.138 0l-5.414-5.415c-1.709-1.692-1.709-4.444 0-6.138l15.38-15.39z"
      />
      <path
        d="m254.5 476.9c1.708-1.692 4.456-1.692 6.168 0l5.385 5.418c1.708 1.692 1.708 4.445 0.03 6.152l-15.5 15.3c-1.679 1.696-4.43 1.696-6.138 0l-5.5-5.4c-1.678-1.694-1.708-4.445 0-6.138l15.38-15.39z"
      />
    </g>
    <path
      d="m248.2 495.6c4.169 4.186 4.169 10.96 0 15.15-4.169 4.169-10.94 4.169-15.14-0.015-4.17-4.169-4.17-10.94 0-15.11 4.199-4.184 10.97-4.184 15.14-0.015"
      fill="#e89ff4"
    />
  </svg>
`;
