export const audio = {
  'motor trailer': 'Mo1 motor trailer-1.m4a',
  'choice demo': 'C2 Choice-demo.m4a',
  'choice play': 'C2 Choice-play.m4a',
  'demo block cue 1': 'C4 Demo block cue.m4a',
  'demo fixation cue': 'Mo5 demo fixation cue.m4a',
  'block cue': 'Mo3 block cue-1.m4a',
  'ball sound': 'Mo6 ball sound.m4a',
  'bad feedback': 'Mo8 bad feedback.m4a',
  'bad feedback demo': 'Mo7 bad feedback demo.m4a',
  'no response': 'Mo9 no response.m4a',
  'end game': 'C13 end game.m4a',
  tractor: 'small-tractor.m4a',
  'animation block cue': 'Mo10 animation block cue.m4a',
  'animation fixation cue': 'Mo11 animation fixation cue.m4a',
};
