import { html } from 'lit';

export const c18 = html`
  <svg
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    viewBox="0 0 620 670"
  >
    <path
      d="m504.8 463.6c0 87.2-82.95 158.6-185.2 158.6-102.4 0-185.3-71.4-185.3-158.6s83-157.9 185.2-157.9c102.4 0 185.3 70.7 185.3 157.9"
      fill="#b3b3b3"
    />
    <g fill="#3c3c3b">
      <path
        d="m118 312.4c10.06-5.778 21.74-8.356 33.96-8.53 6.102 0.1325 12.31 1.02 18.4 2.766 6.036 1.845 11.89 4.534 17.32 7.895 5.425 3.368 10.8 7 15.65 11.25 4.839 4.262 8.562 9.516 10.98 15.24 4.709 11.54 6.365 23.96 2.246 36.82l-2.7 0.86c-10.74-7.965-17.42-15.95-23.9-23.26-3.158-3.668-5.694-7.44-7.964-11.51-2.212-4.101-4.745-8.132-8.039-11.38-3.245-3.298-6.899-6.198-10.95-8.6-3.979-2.536-8.398-4.576-13.12-6.086-9.418-3.282-20.2-4.065-30.85-2.821l-1.03-2.649z"
      />
      <path
        d="m115.3 271.1c6.77-5.16 14.41-9.01 22.78-11.51 8.33-2.5 17.52-3.53 26.82-2.11 9.246 1.495 18.28 5.589 25.82 11.35 7.532 5.828 13.59 13.08 18.6 20.66 5.051 7.95 9.044 14.75 13.39 22.24 4.098 7.42 7.291 15.32 9.282 23.74 1.945 8.384 3.189 17.1 2.938 26.09-0.3188 8.872-1.181 18.35-7.008 26.74l-2.676-0.9225c-5.785-15.31-13.66-28.56-20.06-42.78-3.256-7.05-6.044-14.31-8.294-21.92-2.186-7.434-4.471-15.74-7.315-22.08-5.966-13.12-14.92-24.4-28.15-29.39-13.16-5.345-29.85-3.501-44.62 2.312l-1.485-2.4z"
      />
      <path
        d="m188.8 248.6c7.136-3.074 15.28-3.361 23.4-1.572 4.002 0.9925 7.968 2.536 11.72 4.549 3.7 2.11 7.1 4.67 10.2 7.52 3.124 2.858 6.355 5.709 9.232 8.874 2.834 3.192 4.671 6.972 5.372 11.02 0.6925 4.024 0.6962 8.181-0.275 12.35-0.8738 4.15-2.495 8.321-5.806 12.52l-2.788 0.5375c-4.632-2.591-7.818-5.155-10.57-7.67-2.834-2.481-5-4.926-6.865-7.309-1.859-2.389-2.951-4.884-3.496-7.741-0.5175-2.858-1.098-5.805-2.359-8.306-1.236-2.515-2.704-4.911-4.429-7.119-1.645-2.314-3.599-4.465-5.869-6.372-4.444-3.954-10.39-7.014-17-8.51l-0.4688-2.78z"
      />
      <path
        d="m316.1 464.8c-1.376-0.035-2.708-0.175-4.031-0.3625-86.8-3.508-133.4-71.05-133.4-71.05-12.76 38.41-12.82 81.62-12.82 81.62 2.009 82.95 69.82 149.5 153.2 149.5v-160c-0.6538 0.0413-1.258 0.195-1.904 0.195h-1.059z"
      />
    </g>
    <path
      d="m213.4 272.3-0.0487-0.0625s0.004 0.3075 0.004 0.7962c-0.22 2.844-0.4238 5.701-0.4238 8.608 0 0.8862 0.0913 1.752 0.105 2.641-0.5975 10.7-2.186 27.4-6.84 45.02-3.28 12.36-8.041 25.16-15.1 36.66-5.128 8.384-9.184 17.89-12.44 27.7 0 0 46.62 67.55 133.4 71.05h0.0213c1.306 0.1875 2.641 0.3275 4.01 0.3625h1.059c0.6462 0 1.25-0.1538 1.904-0.195v-289.3c-55.48 0-101 42.56-105.7 96.8"
      fill="#b3b3b3"
    />
    <path
      d="m459.8 396.1s-49.72 69.54-141 69.54v160c83.48 0 151.4-66.72 153.2-149.8 0 0-0.0425-41.82-12.21-79.76"
      fill="#575756"
    />
    <path
      d="m447.3 366.1c-6.445-10.57-11.01-22.25-14.24-33.65-5.534-19.48-7.21-38.19-7.745-49.38 0-0.4325 0.045-0.8525 0.045-1.285 0-1.936-0.1888-3.829-0.2862-5.736-0.0425-2.285-0.0138-3.668-0.0138-3.668l-0.2 0.26c-4.7-54.34-50.1-96.99-105.7-96.99v289.3c91.29 0 141-69.54 141-69.54-3.325-10.39-7.528-20.5-12.94-29.39"
      fill="#ccc"
    />
    <path
      d="m357.3 357.4v-0.0625c0-23.58-21.34-42.71-38.92-42.71-17.59 0-38.92 19.12-38.92 42.71 0 9.445-0.73 34.25 17.11 34.25 5.258 0 9.906-2.424 13.05-6.162 2.949 0.315 5.918 0.4688 8.768 0.4688 2.865 0 5.824-0.1538 8.779-0.4688 3.126 3.739 7.78 6.162 13.05 6.162 17.8 0 17.1-24.7 17.1-34.19"
      fill="#999"
    />
    <path
      d="m276.1 236.1c-26.09 0-47.24 20.1-47.24 44.88 0 24.8 21.15 44.89 47.24 44.89 19.5 0 36.24 2.844 43.46-13.12v-49.36c-7.3-16.1-24-27.34-43.5-27.34"
    />
    <path
      d="m362.3 326.1c26.09 0 47.25-20.09 47.25-44.89 0-24.78-21.16-44.88-47.25-44.88-19.56 0-36.34 11.29-43.52 27.38-0.015-0.0288-0.0425-0.07-0.0563-0.105v49.36c0.0138-0.0412 0.0413-0.0687 0.0563-0.1112 7.182 16.09 23.95 13.24 43.52 13.24"
    />
    <path
      d="m311.1 314.8-11.44 7.545c-4.601 3.04-6.054 9.398-3.231 14.14l17.9 30.08c1.415 2.382 3.276 3.57 5.135 3.57v-57.61c-3.046 0-6.061 0.7612-8.37 2.285"
      fill="#f39e2b"
    />
    <path
      d="m324.8 367.4 17.91-30.08c2.83-4.744 1.37-11.1-3.235-14.14l-11.44-7.545c-2.306-1.524-5.339-2.285-8.37-2.285v57.61c1.859 0 3.72-1.188 5.131-3.57"
      fill="#fbdd58"
    />
    <path
      d="m283.5 281.1c0 8.461-6.868 15.31-15.31 15.31-8.46 0-15.31-6.854-15.31-15.31 0-8.446 6.854-15.3 15.31-15.3 8.44 0 15.31 6.854 15.31 15.3"
      fill="#666"
    />
    <path
      d="m383.5 281.1c0 8.461-6.864 15.31-15.31 15.31-8.45 0-15.31-6.854-15.31-15.31 0-8.446 6.86-15.3 15.31-15.3 8.448 0 15.31 6.854 15.31 15.3"
      fill="#666"
    />
    <path
      d="m279.8 274.8c0 2.892-2.348 5.24-5.232 5.24-2.892 0-5.234-2.348-5.234-5.24s2.341-5.232 5.234-5.232c2.885 0 5.232 2.34 5.232 5.232"
      fill="#fff"
    />
    <path
      d="m378.5 274.8c0 2.892-2.338 5.24-5.23 5.24-2.891 0-5.232-2.348-5.232-5.24s2.341-5.232 5.232-5.232c2.892 0 5.23 2.34 5.23 5.232"
      fill="#fff"
    />
    <path
      d="m448.5 409.8c-71.94 71.94-188.6 71.94-260.5 0"
      stroke="#808080"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="11.25"
      fill="none"
    />
    <path
      d="m318.5 466.1c-0.6525 0.0412-1.258 0.195-1.904 0.195h-1.059c-1.376-0.035-2.706-0.1738-4.031-0.3625-3.388-0.1375-6.708-0.3825-9.972-0.7062v159.9c5.572 0.6138 11.23 0.9388 16.96 0.9388 5.844 0 11.61-0.3375 17.28-0.975v-159.9c-5.584 0.55-11.34 0.85-17.28 0.85"
      fill="#fff"
    />
    <path d="m336.1 464.8v159.9c11.88-1.332 23.35-4.016 34.25-7.906v-158.9c-10.54 3.281-21.96 5.731-34.25 6.941" />
    <path d="m267.9 458.6v159.1c10.9 3.862 22.38 6.524 34.25 7.831v-159.9c-12.29-1.221-23.72-3.705-34.25-7.052" />
    <g fill="#fff">
      <path d="m233.7 443.6v157c10.56 7.106 22.05 12.92 34.25 17.25v-159.1c-12.74-4.05-24.16-9.351-34.25-15.16" />
      <path d="m371.1 458.6v158.9c12.21-4.358 23.7-10.22 34.25-17.36v-156.2c-10.1 5.666-21.52 10.84-34.25 14.8" />
      <path d="m472.3 476.1s-0.0425-41.82-12.21-79.76-7.151 9.995-21 22.4v149.1c20.12-25.31 32.41-57.12 33.2-91.79" />
    </g>
    <path d="m404.8 599.8c12.98-8.778 24.52-19.49 34.25-31.72v-149.1c-8.766 7.856-20.22 16.68-34.25 24.55v156.2z" />
    <path
      d="m178.6 394.8c-12.77 38.5-12.83 81.7-12.83 81.7 0.84 34.7 13.25 66.7 33.54 92v-150.8c-13.68-12.6-20.71-22.8-20.71-22.8"
      fill="#fff"
    />
    <path d="m199.4 417.4v150.8c9.738 12.16 21.29 22.81 34.25 31.52v-157c-14.06-8.104-25.51-17.2-34.25-25.29" />
    <path d="m234.9 289.8h-22.89v-22.89h22.89v22.89z" />
    <path d="m426.1 289.8h-22.89v-22.89h22.89v22.89z" />
  </svg>
`;
