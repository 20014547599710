import { html } from 'lit';

export const c14 = html`
  <svg
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    viewBox="0 0 620 670"
  >
    <path
      d="m505 464.8c0 87.2-82.9 158.6-185.3 158.6-102.3 0-185.2-71.5-185.2-158.6 0-87.2 83-157.9 185.2-157.9 102.3 0 185.2 70.69 185.2 157.9"
      fill="#b3b3b3"
    />
    <g fill="#3c3c3b">
      <path
        d="m118.8 313.4c10.06-5.778 21.74-8.356 33.96-8.53 6.101 0.1325 12.31 1.02 18.4 2.766 6.036 1.844 11.89 4.534 17.32 7.895 5.425 3.368 10.8 7 15.65 11.25 4.839 4.261 8.562 9.515 10.98 15.24 4.709 11.54 6.365 23.96 2.246 36.82l-2.7 0.86c-10.74-7.965-17.42-15.95-23.9-23.26-3.158-3.668-5.694-7.44-7.964-11.51-2.212-4.101-4.745-8.132-8.039-11.38-3.245-3.298-6.899-6.198-10.95-8.6-3.979-2.536-8.398-4.576-13.12-6.086-9.425-3.275-20.2-4.058-30.86-2.815l-1.025-2.648z"
      />
      <path
        d="m115 271.8c6.77-5.164 14.41-9.012 22.78-11.51 8.335-2.502 17.52-3.536 26.82-2.118 9.246 1.495 18.28 5.589 25.82 11.35 7.532 5.828 13.59 13.08 18.6 20.66 5.051 7.95 9.044 14.75 13.39 22.24 4.098 7.42 7.291 15.32 9.282 23.74 1.945 8.384 3.189 17.1 2.938 26.09-0.3188 8.872-1.181 18.35-7.008 26.74l-2.676-0.9225c-5.785-15.31-13.66-28.56-20.06-42.78-3.256-7.05-6.044-14.31-8.294-21.92-2.186-7.434-4.471-15.74-7.315-22.08-5.988-13.11-14.94-24.4-28.18-29.39-13.16-5.34-29.85-3.496-44.62 2.318l-1.488-2.418z"
      />
      <path
        d="m188.8 249.6c7.136-3.074 15.28-3.361 23.4-1.572 4.002 0.9925 7.968 2.536 11.72 4.549 3.664 2.116 7.098 4.674 10.21 7.524 3.124 2.858 6.355 5.709 9.232 8.874 2.834 3.192 4.67 6.972 5.372 11.02 0.6925 4.025 0.695 8.181-0.275 12.35-0.8738 4.15-2.495 8.321-5.806 12.52l-2.788 0.5375c-4.632-2.591-7.819-5.155-10.57-7.67-2.834-2.481-5-4.926-6.865-7.309-1.859-2.389-2.952-4.884-3.496-7.74-0.5175-2.859-1.098-5.806-2.359-8.308-1.236-2.515-2.704-4.911-4.429-7.119-1.645-2.314-3.599-4.465-5.869-6.372-4.444-3.954-10.39-7.014-17-8.51l-0.4625-2.762z"
      />
      <path
        d="m316.3 466.3c-1.376-0.035-2.708-0.175-4.031-0.3625-86.8-3.508-133.4-71.05-133.4-71.05-12.76 38.41-12.82 81.62-12.82 81.62 2.009 82.95 69.82 149.5 153.2 149.5v-160c-0.6912 0.0962-1.295 0.25-1.941 0.25h-1.059z"
      />
    </g>
    <path
      d="m213.8 273.5-0.0488-0.0625s0.004 0.3062 0.004 0.7962c-0.22 2.844-0.4238 5.701-0.4238 8.608 0 0.8862 0.0913 1.752 0.105 2.64-0.5975 10.7-2.186 27.4-6.84 45.02-3.28 12.36-8.041 25.16-15.1 36.66-5.128 8.384-9.184 17.89-12.44 27.7 0 0 46.62 67.55 133.4 71.05h0.0213c1.306 0.1875 2.641 0.3275 4.01 0.3625h1.059c0.6462 0 1.251-0.1538 1.904-0.195v-289.3c-55.42-0.05-100.9 42.5-105.6 96.75"
      fill="#b3b3b3"
    />
    <path
      d="m460 396.5s-49.72 69.54-141 69.54v160c83.48 0 151.4-66.72 153.2-149.8 0 0-0.0425-41.82-12.21-79.76"
      fill="#575756"
    />
    <path
      d="m447.5 367.2c-6.445-10.57-11.01-22.25-14.24-33.65-5.534-19.48-7.21-38.19-7.745-49.38 0-0.4325 0.045-0.8525 0.045-1.285 0-1.936-0.1888-3.829-0.2862-5.736-0.0425-2.285-0.0138-3.668-0.0138-3.668l-0.1612 0.2438c-4.664-54.34-50.15-96.99-105.7-96.99v289.3c91.3 0 141-69.54 141-69.54-3.265-10.34-7.468-20.46-12.88-29.34"
      fill="#ccc"
    />
    <g fill="#999">
      <path
        d="m357.5 357.8v-0.0625c0-23.6-21.3-42.7-38.9-42.7-17.59 0-38.92 19.12-38.92 42.71 0 9.445-0.73 34.25 17.11 34.25 5.258 0 9.908-2.424 13.05-6.162 2.949 0.315 5.918 0.4688 8.768 0.4688 2.865 0 5.824-0.1538 8.779-0.4688 3.126 3.739 7.78 6.162 13.05 6.162 17.8 0.0125 17.1-24.69 17.1-34.18"
      />
      <path
        d="m276.3 237.3c-26.09 0-47.24 20.1-47.24 44.88 0 24.8 21.15 44.89 47.24 44.89 19.5 0 36.24 2.844 43.46-13.12v-49.36c-7.225-16.04-23.95-27.28-43.46-27.28"
      />
      <path
        d="m362.5 327.2c26.09 0 47.25-20.09 47.25-44.89 0-24.79-21.16-44.88-47.25-44.88-19.56 0-36.34 11.29-43.52 27.38-0.015-0.0288-0.0425-0.07-0.0563-0.105v49.36c0.0138-0.0412 0.0413-0.0687 0.0563-0.1112 7.188 16.1 23.96 13.24 43.52 13.24"
      />
    </g>
    <path
      d="m283.7 282.3c0 8.461-6.868 15.31-15.31 15.31-8.46 0-15.31-6.854-15.31-15.31 0-8.446 6.854-15.3 15.31-15.3 8.445 0 15.31 6.854 15.31 15.3"
      fill="#4e1412"
    />
    <path
      d="m383.7 282.3c0 8.461-6.864 15.31-15.31 15.31-8.45 0-15.31-6.854-15.31-15.31 0-8.446 6.86-15.3 15.31-15.3 8.449 0 15.31 6.854 15.31 15.3"
      fill="#4e1412"
    />
    <path
      d="m280 276.3c0 2.892-2.348 5.24-5.232 5.24-2.892 0-5.234-2.348-5.234-5.24s2.341-5.232 5.234-5.232c2.885 0 5.232 2.34 5.232 5.232"
      fill="#fff"
    />
    <path
      d="m378.7 276.3c0 2.892-2.338 5.24-5.23 5.24s-5.232-2.348-5.232-5.24 2.34-5.232 5.232-5.232 5.23 2.34 5.23 5.232"
      fill="#fff"
    />
    <g fill-rule="evenodd">
      <path
        d="m381.3 119.4c-0.3-13.61-7.8-24.48-16.8-24.32 0 0-28.29-3.641-44.24-4.162-15.89-0.4725-49.52 5.705-49.52 5.705-8.998 0.1475-16.08 11.26-15.85 24.86l-8.542 74.11 61.4-1.01 6.426-0.105 78.06-1.275-10.98-73.8z"
        fill="#8c6239"
      />
      <path
        opacity=".47"
        d="m250.5 159.3-4.162 36.32 61.4-1.01 6.426-0.105 78.04-1.284-5.5-36.16-136.2 2.238z"
        fill="#603813"
      />
      <path
        d="m227.5 217-0.1025-6.228c-0.2212-13.48 4.041-24.51 9.48-24.6l161.6-2.422c5.354-0.0888 10 10.96 10.22 24.3l0.125 5.968-181.4 2.982z"
        fill="#8c6239"
      />
      <path
        opacity=".33"
        d="m390.5 183.8-9.568-64.39c-0.2238-13.61-7.734-24.48-16.72-24.32 0 0-28.29-3.642-44.24-4.162-0.555 0.0087-1.218-0.0875-1.809-0.1312l-0.0188 124.7 88.98-1.516-0.0975-5.97c-0.2125-13.35-7.725-24.35-16.51-24.2"
        fill="#917561"
      />
    </g>
    <path
      d="m403.7 281c0 19.45-15.78 35.22-35.22 35.22-19.45 0-35.22-15.78-35.22-35.22 0-19.45 15.78-35.22 35.22-35.22 19.45 0 35.22 15.78 35.22 35.22z"
      stroke="#000"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="11.25"
      fill="none"
    />
    <path
      d="m407.5 279.3c4.965 0.59 6.792 2.938 9.935 6.346 3.852 4.179 3.686 121.4 2.962 127.5"
      stroke="#000"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="6.436"
      fill="none"
    />
    <path
      d="m388.7 376.3c-4.868 6.058-11.93 10.12-19.94 12.34-4.001 0.9962-8.228 1.489-12.48 1.465-4.229-0.1562-8.46-0.8088-12.55-1.87-4.098-1.062-8.288-2.07-12.31-3.519-3.998-1.496-7.39-3.976-9.904-7.222-2.495-3.232-4.444-6.905-5.538-11.04-1.17-4.076-1.69-8.521-0.7325-13.79l2.211-1.78c5.308 0.1225 9.322 0.8975 12.94 1.83 3.665 0.8662 6.722 2.012 9.486 3.245 2.761 1.242 4.895 2.935 6.714 5.205 1.794 2.284 3.686 4.618 5.971 6.238 2.27 1.644 4.689 3.075 7.246 4.218 2.536 1.274 5.27 2.261 8.169 2.885 5.778 1.414 12.47 1.334 19.01-0.4375l1.712 2.25z"
    />
    <path
      d="m251.3 376.3c4.868 6.058 11.93 10.12 19.94 12.34 4.001 0.9962 8.228 1.489 12.48 1.465 4.229-0.1562 8.46-0.8088 12.55-1.87 4.098-1.062 8.288-2.07 12.31-3.519 3.998-1.496 7.39-3.976 9.904-7.222 2.495-3.232 4.444-6.905 5.538-11.04 1.171-4.076 1.691-8.521 0.7325-13.79l-2.211-1.78c-5.312 0.125-9.325 0.9-12.94 1.838-3.662 0.8625-6.725 2.012-9.488 3.238-2.762 1.25-4.9 2.938-6.712 5.212-1.8 2.288-3.688 4.615-5.975 6.235-2.275 1.644-4.688 3.075-7.25 4.218-2.538 1.274-5.262 2.261-8.162 2.885-5.775 1.414-12.47 1.334-19.01-0.4375l-1.712 2.238z"
    />
    <path
      d="m311.3 316.2-11.44 7.545c-4.601 3.04-6.054 9.398-3.231 14.14l17.91 30.06c1.415 2.382 3.276 3.57 5.135 3.57v-57.61c-3.049 0.004-6.064 0.765-8.372 2.288"
      fill="#f39e2b"
    />
    <path
      d="m325 367.9 17.91-30.08c2.83-4.744 1.37-11.1-3.235-14.14l-11.44-7.545c-2.306-1.524-5.338-2.285-8.37-2.285v57.61c1.859 0 3.72-1.188 5.131-3.57"
      fill="#fbdd58"
    />
    <path
      d="m327.5 472.4 58.34 20.3c3.825 0.675 7.334-2.269 7.334-6.154v-41.22c0-4.311-4.262-7.326-8.328-5.891l-58.35 20.58c-2.491 0.8875-4.162 3.248-4.162 5.898v0.3375c0 3.031 2.176 5.625 5.162 6.152"
      fill="#c1272d"
    />
    <path
      d="m310 459.4-58.34-20.3c-3.825-0.675-7.334 2.269-7.334 6.154v41.22c0 4.311 4.262 7.326 8.328 5.891l58.35-20.58c2.491-0.8875 4.162-3.248 4.162-5.898v-0.3375c0-3.031-2.176-5.625-5.162-6.152"
      fill="#c1272d"
    />
    <path
      d="m333.7 466c0 7.901-6.405 14.3-14.3 14.3-7.9 0-14.3-6.405-14.3-14.3 0-7.901 6.405-14.3 14.3-14.3s14.3 6.399 14.3 14.3"
      fill="#ed1c24"
    />
  </svg>
`;
