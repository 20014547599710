import { FarmGameSettings } from '@tasks/farm-game-settings';
import { SheepDemo } from './sheep-demo';
import { SheepDurations } from './sheep-durations';
import { SheepPhaseSetting } from './sheep-phase-setting';

/**
 * @typedef { import('./record-types.js').SheepTaskRecord}  SheepTaskRecord
 */

export class SheepSettings extends FarmGameSettings {
  /**
   *
   * @param {SheepTaskRecord} taskRecord
   */
  constructor(taskRecord) {
    super(taskRecord);

    this.durations_ = new SheepDurations(taskRecord.model.durations);

    this.phases_ =
      /** @type {SheepPhaseSetting[]}*/
      (taskRecord.model.phases.map(phase => new SheepPhaseSetting(phase)));
    this.demo_ = new SheepDemo(taskRecord.model.demo);

    this.phaseIndex = 0;
  }

  get durations() {
    return this.durations_;
  }

  get demo() {
    return this.demo_;
  }
  get phases() {
    return this.phases_;
  }

  get currentPhase() {
    return this.phases_[this.phaseIndex];
  }
}
