import { createContext } from '@lit/context';

/**
 * @typedef {import('./task-controller.js').TaskController} TaskController
 */

/**
 * @type {import('@lit/context').Context<Symbol,TaskController>}
 */
export const chickenTaskContext = createContext(Symbol.for('task'));
