import { html } from 'lit';

export const c31 = html`
  <svg
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    viewBox="0 0 620 670"
  >
    <path
      fill="#b3b3b3"
      d="m504.6 464.2c0 87.2-82.95 158.6-185.2 158.6-102.3 0-185.2-71.48-185.2-158.6 0-87.19 82.95-157.9 185.2-157.9 102.3 0 185.2 70.69 185.2 157.9"
    />
    <g fill="#999">
      <path
        d="m246.4 139.9c8.158-3.126 15.88-2.404 22.88-0.4825 6.99 2.044 13.31 5.736 18.58 10.61 5.274 4.855 9.452 10.96 12.01 17.84 2.442 6.939 3.461 14.61 0.9325 22.9-8.072-3.109-13.66-6.329-18.79-9.599-5.048-3.27-9.376-6.63-13.4-10.4-4.039-3.751-7.766-7.895-11.42-12.75-3.649-4.938-7.106-10.38-10.79-18.11"
      />
      <path
        d="m318.6 90.75c7.692 5.781 12.46 12.64 16.26 19.76 3.675 7.171 6.092 14.74 7.322 22.6 1.201 7.846 1.188 16-0.4825 24.22-1.806 8.101-5.026 16.86-12.48 23.2-4.2-8.531-7.305-15.1-9.768-22.14-2.408-6.934-4.185-13.88-5.261-21.02-1.065-7.151-1.481-14.5-1.012-22.16 0.5638-7.7 1.792-15.61 5.419-24.46"
      />
      <path
        d="m348.2 107.1c6.11 6.752 9.142 13.91 11.15 21.1 1.89 7.214 2.459 14.54 1.75 21.85-0.6975 7.325-2.692 14.68-6.412 21.68-3.77 6.725-9.38 14.16-18.42 16.75-1.09-8.86-2.704-15.02-3.278-21.72-0.635-6.542-0.7125-12.96-0.055-19.45 0.6462-6.488 1.976-13.02 4.24-19.69 2.374-6.675 5.426-13.4 11.03-20.51"
      />
    </g>
    <g fill="#3c3c3b">
      <path
        d="m118.2 312.9c10.06-5.778 21.74-8.356 33.96-8.53 6.102 0.1325 12.31 1.02 18.4 2.766 6.036 1.844 11.89 4.534 17.32 7.895 5.425 3.368 10.8 7 15.65 11.25 4.839 4.261 8.562 9.515 10.98 15.24 4.709 11.54 6.365 23.96 2.246 36.82l-2.7 0.86c-10.74-7.965-17.42-15.95-23.9-23.26-3.158-3.668-5.694-7.44-7.964-11.51-2.212-4.101-4.745-8.132-8.039-11.38-3.245-3.298-6.899-6.198-10.95-8.6-3.979-2.536-8.398-4.576-13.12-6.086-9.425-3.275-20.2-4.058-30.86-2.814l-1.025-2.649z"
      />
      <path
        d="m115.4 271.4c6.77-5.164 14.41-9.012 22.78-11.51 8.335-2.502 17.52-3.536 26.82-2.118 9.246 1.495 18.28 5.589 25.82 11.35 7.532 5.828 13.59 13.08 18.6 20.66 5.051 7.95 9.044 14.75 13.39 22.24 4.098 7.42 7.291 15.32 9.282 23.74 1.945 8.384 3.189 17.1 2.938 26.09-0.3188 8.872-1.181 18.35-7.008 26.74l-2.676-0.9225c-5.785-15.31-13.66-28.56-20.06-42.78-3.256-7.05-6.044-14.31-8.294-21.92-2.186-7.434-4.471-15.74-7.315-22.08-5.988-13.11-14.94-24.4-28.18-29.39-13.16-5.34-29.85-3.496-44.62 2.318l-1.488-2.418z"
      />
      <path
        d="m188.8 249.2c7.136-3.074 15.28-3.361 23.4-1.572 4.002 0.9925 7.968 2.536 11.72 4.549 3.664 2.116 7.098 4.674 10.21 7.524 3.124 2.858 6.355 5.709 9.232 8.874 2.834 3.192 4.671 6.972 5.372 11.02 0.6925 4.024 0.6962 8.181-0.275 12.35-0.8738 4.15-2.495 8.321-5.806 12.52l-2.788 0.5375c-4.632-2.591-7.818-5.155-10.57-7.67-2.834-2.481-5-4.926-6.865-7.309-1.859-2.389-2.951-4.884-3.496-7.74-0.5175-2.859-1.098-5.806-2.359-8.308-1.236-2.515-2.704-4.911-4.429-7.119-1.645-2.314-3.599-4.465-5.869-6.372-4.444-3.954-10.39-7.014-17-8.51l-0.4625-2.762z"
      />
    </g>
    <path
      fill="#fff"
      d="m316.2 465.8c-1.376-0.035-2.708-0.175-4.031-0.3625-86.8-3.508-133.4-71.05-133.4-71.05-12.76 38.41-12.82 81.62-12.82 81.62 2.009 82.95 69.82 149.5 153.2 149.5v-160c-0.6912 0.0962-1.295 0.25-1.941 0.25h-1.059z"
    />
    <path
      fill="#b3b3b3"
      d="m213.6 273-0.0488-0.0625s0.004 0.3062 0.004 0.7962c-0.22 2.844-0.4238 5.701-0.4238 8.608 0 0.8862 0.0913 1.752 0.105 2.64-0.5975 10.7-2.186 27.4-6.84 45.02-3.28 12.36-8.041 25.16-15.1 36.66-5.128 8.384-9.184 17.89-12.44 27.7 0 0 46.62 67.55 133.4 71.05h0.0213c1.306 0.1875 2.641 0.3275 4.01 0.3625h1.059c0.6462 0 1.25-0.1538 1.904-0.195v-83.32-206c-55.42-0.05-100.9 42.5-105.6 96.75"
    />
    <path
      fill="#fff"
      d="m460.2 396s-49.72 69.54-141 69.54v160c83.48 0 151.4-66.72 153.2-149.8 0 0-0.0425-41.82-12.21-79.76"
    />
    <path
      fill="#ccc"
      d="m447.3 366.6c-6.445-10.57-11.01-22.25-14.24-33.65-5.534-19.48-7.21-38.19-7.745-49.38 0-0.4325 0.045-0.8525 0.045-1.285 0-1.935-0.1888-3.829-0.2862-5.736-0.0425-2.285-0.0138-3.668-0.0138-3.668l-0.1612 0.2438c-4.664-54.34-50.15-96.99-105.7-96.99v206 83.32c91.3 0 141-69.54 141-69.54-3.265-10.34-7.468-20.46-12.88-29.34"
    />
    <g fill="#999">
      <path
        d="m357.4 357.4v-0.0625c0-23.58-21.34-42.71-38.92-42.71-17.59 0-38.92 19.12-38.92 42.71 0 9.445-0.73 34.25 17.11 34.25 5.258 0 9.906-2.424 13.05-6.162 2.949 0.315 5.918 0.4688 8.768 0.4688 2.865 0 5.824-0.1538 8.779-0.4688 3.126 3.739 7.78 6.162 13.05 6.162 17.8 0.0125 17.1-24.69 17.1-34.18"
      />
      <path
        d="m275.7 236.9c-26.09 0-47.24 20.1-47.24 44.88 0 24.8 21.15 44.89 47.24 44.89 19.5 0 36.24 2.844 43.46-13.12v-49.36c-7.225-16.04-23.95-27.28-43.46-27.28"
      />
      <path
        d="m362.8 326.6c26.09 0 47.25-20.09 47.25-44.89 0-24.79-21.16-44.88-47.25-44.88-19.56 0-36.34 11.29-43.52 27.38-0.015-0.0288-0.0425-0.07-0.0562-0.105v49.36c0.0138-0.0412 0.0412-0.0688 0.0562-0.1112 7.188 16.1 23.96 13.24 43.52 13.24"
      />
    </g>
    <path
      fill="#f39e2b"
      d="m310.9 315.8-11.44 7.545c-4.601 3.04-6.054 9.398-3.231 14.14l17.91 30.06c1.415 2.382 3.276 3.57 5.135 3.57v-57.61c-3.049 0.004-6.064 0.7638-8.372 2.288"
    />
    <path
      fill="#fbdd58"
      d="m324.4 367.5 17.91-30.08c2.83-4.744 1.37-11.1-3.235-14.14l-11.44-7.545c-2.306-1.524-5.339-2.285-8.37-2.285v57.61c1.859 0 3.72-1.188 5.131-3.57"
    />
    <path
      fill="#4e1412"
      d="m284 281.9c0 8.461-6.868 15.31-15.31 15.31-8.46 0-15.31-6.854-15.31-15.31 0-8.446 6.854-15.3 15.31-15.3 8.445 0 15.31 6.854 15.31 15.3"
    />
    <path
      fill="#4e1412"
      d="m383.7 281.9c0 8.461-6.864 15.31-15.31 15.31-8.45 0-15.31-6.854-15.31-15.31 0-8.446 6.86-15.3 15.31-15.3 8.449 0 15.31 6.854 15.31 15.3"
    />
    <path
      fill="#fff"
      d="m279.6 275.8c0 2.892-2.348 5.24-5.232 5.24-2.892 0-5.234-2.348-5.234-5.24s2.341-5.232 5.234-5.232c2.885 0 5.232 2.34 5.232 5.232"
    />
    <path
      fill="#fff"
      d="m378.8 275.8c0 2.892-2.338 5.24-5.23 5.24-2.891 0-5.232-2.348-5.232-5.24s2.341-5.232 5.232-5.232c2.892 0 5.23 2.34 5.23 5.232"
    />
    <path
      fill="#999"
      d="m249 75.75c13.92 7.646 24.54 16.59 34.26 24.15 2.379 1.886 4.738 3.588 6.756 5.006 1.076 0.7612 1.96 1.118 2.931 1.778l3.934 2.614c5.669 3.835 12.23 9.39 17.55 16.09 10.96 13.55 15.95 30.45 16.19 45.69 0.1688 15.42-3.409 30.08-12.09 43.55-8.991-13.24-14.65-25.41-20.45-36.08-5.68-10.77-10.96-19.31-16.82-26.69-3.004-3.804-6.284-7.381-10.74-12.25l-3.5-4c-1.502-1.596-3.015-3.578-4.195-5.279-2.526-3.479-4.395-6.982-6.036-10.44-6.235-13.89-9.184-28.12-7.681-44.12"
    />
    <g fill="#2e3192">
      <path
        d="m319.2 502.2c58.82 0 114-19.25 151.5-50.1-3.25-16.16-2.125-42.34-10.5-56.12-30.56 37.64-87.45 69.95-141 69.95s-109.8-34-140.4-71.64c-8.311 13.79-7.841 41.65-11.1 57.81 37.49 30.85 92.68 50.1 151.5 50.1"
      />
      <path
        d="m167.3 488.2c1.121 19.52 6.23 38.02 14.56 54.76 40.45 13.55 87.36 24.14 137.4 24.14 50.02 0 96.94-10.59 137.4-24.14 8.329-16.74 13.44-35.24 14.56-54.76-40 26.45-93.38 42.62-152 42.62-58.71 0-112.1-16.18-152-42.62"
      />
      <path
        d="m198.7 569.4c27.82 34.61 71.45 56.9 120.5 56.9s92.68-22.3 120.5-56.9c-34.75 15.95-76.12 25.21-120.5 25.21-44.36 0-85.71-9.262-120.5-25.21"
      />
    </g>
    <path
      fill="#666"
      d="m377.7 444.4h-109.8c-4.188 0-7.612 3.425-7.612 7.612v64.6c0 4.186 3.425 7.612 7.612 7.612h109.8c4.186 0 7.612-3.426 7.612-7.612v-64.6c0-4.188-3.426-7.612-7.612-7.612"
    />
    <path
      fill="#fff"
      d="m302.6 424.2c-2.338-1.749-5.928-0.395-7.56 2.026-1.631 2.421-1.839 5.498-1.994 8.414-0.1675 3.151-0.335 6.302-0.5025 9.454-0.0425 0.8075-0.0925 1.642-0.4612 2.361-0.5912 1.156-1.878 1.775-3.122 2.141-7.821 2.3-17.46-1.994-23.79 3.138-4.805 3.894-4.856 11.07-4.492 17.25 0.1262 2.145 0.2825 4.388 1.405 6.22 1.42 2.32 4.08 3.509 6.226 5.18 6.701 5.218 8.222 14.98 14.41 20.8 1.684 1.584 3.661 2.821 5.676 3.952 14.04 7.881 30.7 11.02 46.64 8.786 8.404-1.178 17.22-4.254 21.98-11.28 3.72-5.506 4.316-12.48 4.735-19.11 0.4788-7.579 0.795-15.58-2.656-22.34-3.201-6.274-9.422-10.98-11.1-17.82-1.519-6.215 0.9488-13.45-2.6-18.78-14.65-2.655-29.75-2.826-44.45-0.515"
    />
    <path
      fill="#010101"
      d="m319.2 510.9c-16.91 0-30.61-13.7-30.62-30.61 0.0125-16.91 13.71-30.62 30.62-30.62s30.61 13.71 30.61 30.62-13.7 30.61-30.61 30.61m-16.06-83.11h32.12c1.98 0 3.58 1.609 3.58 3.578 0 1.979-1.602 3.582-3.58 3.582h-32.12c-1.969 0-3.574-1.605-3.574-3.582 0-1.968 1.605-3.578 3.574-3.578m-31.21 40c-4.25 0-7.696-3.448-7.696-7.702 0-4.251 3.445-7.698 7.696-7.698 4.256 0 7.696 3.446 7.696 7.698 0 4.255-3.44 7.702-7.696 7.702m103.6-26.05h-21.5l-1.665-19.16c0-1.809-1.472-3.286-3.294-3.286h-59.68c-1.821 0-3.289 1.478-3.289 3.286l-1.666 19.16h-3.705v-4.286c0-1.5-1.218-2.712-2.718-2.712h-12.16c-1.5 0-2.718 1.214-2.718 2.712v4.286h-0.2062c-3.45 0.0125-6.225 2.775-6.225 6.2v66.54c0 3.426 2.778 6.198 6.198 6.198h112.6c3.422 0 6.198-2.771 6.198-6.198v-66.54c0-3.42-2.775-6.188-6.198-6.188m-56.3 17.46c-11.64 0.0125-21.05 9.438-21.08 21.08 0.025 11.64 9.435 21.05 21.08 21.08 11.63-0.03 21.05-9.438 21.08-21.08 0-11.8-9.4-21.2-21-21.2"
    />
    <g stroke="#000" stroke-miterlimit="10" fill="none">
      <path d="m193.9 362.1s-6.191 45.62 66.38 89.95" stroke-linecap="round" stroke-width="8.75" />
      <path d="m448 362.1s6.191 45.62-66.38 89.95" stroke-linecap="round" stroke-width="8.75" />
      <path
        d="m292.2 316.5h-48.2c-5.249 0-9.505-4.255-9.505-9.505v-48.2c0-5.25 4.256-9.505 9.505-9.505h48.2c5.249 0 9.505 4.255 9.505 9.505v48.2c0 5.245-4.256 9.5-9.505 9.5z"
        stroke-width="13.75"
      />
      <path
        d="m391.9 316.5h-48.2c-5.249 0-9.505-4.255-9.505-9.505v-48.2c0-5.25 4.256-9.505 9.505-9.505h48.2c5.249 0 9.505 4.255 9.505 9.505v48.2c0 5.245-4.256 9.5-9.505 9.5z"
        stroke-width="13.75"
      />
      <g stroke-width="6.436" stroke-linecap="round">
        <path d="m303.7 285.8c7.915-7.825 20.68-7.751 28.5 0.1638" />
        <path d="m401.4 273.2c7.915-7.825 20.68-7.751 28.5 0.1638" />
        <path d="m207.2 273.2c7.915-7.825 20.68-7.751 28.5 0.1638" />
      </g>
    </g>
  </svg>
`;
