/**
 * @param {number[]} data
 */
export function average(data, n = data.length) {
  if (n <= 0) {
    return 0;
  }

  let sum = 0;

  for (const value of data) {
    sum += value;
  }

  return sum / n;
}

/**
 * @param {number[]} values
 */
export function sampleStandardDeviation(values) {
  const avg = average(values);

  const squareDiffs = values.map(value => {
    const diff = value - avg;
    return diff * diff;
  });

  const avgSquaredDiff = average(squareDiffs, squareDiffs.length - 1);

  return { mean: avg, sd: Math.sqrt(avgSquaredDiff) };
}
