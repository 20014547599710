import { html } from 'lit';

export const hutchClosed = html`
  <svg version="1.1" viewBox="0 0 610 1e3" xmlns="http://www.w3.org/2000/svg">
    <path d="m504.4 775h-398.6l-57.62-514 255-212.6 259 212.6-57.75 514z" fill="#ff9700" />
    <path
      d="m562.2 285.4-217.3-169.8-287.3 229.9-7.72-69.9 270-213.6 258.1 206.1-15.75 17.25z"
      fill="#683d28"
      opacity=".5"
    />
    <path
      d="m422.2 369.5c0 66.22-53.48 119.5-119.4 119.5-66.2 0-119.7-53.25-119.7-119.5 0-65.95 53.5-119.4 119.7-119.4 65.96 0 119.4 53.5 119.4 119.4"
      fill="#1e1d1b"
    />
    <path
      d="m334.8 552.6c0 17.4-13.99 31.38-31.21 31.38-17.4 0-31.21-13.98-31.21-31.38 0-17.22 13.81-31.21 31.21-31.21 17.22 0 31.21 13.99 31.21 31.21"
      fill="#ffb655"
    />
    <g fill="#c14e00">
      <path d="m126.6 684-35.6-35.59 35.6-35.6 35.59 35.6-35.6 35.6z" opacity=".8" />
      <path d="m197.8 684-35.44-35.59 35.44-35.6 35.6 35.6-35.6 35.6z" opacity=".8" />
      <path d="m268.5 684-35.59-35.59 35.59-35.6 35.6 35.6-35.6 35.6z" opacity=".8" />
      <path d="m339.8 684-35.6-35.59 35.6-35.6 35.59 35.6-35.6 35.6z" opacity=".8" />
      <path d="m411 684-35.44-35.59 35.44-35.6 35.6 35.6-35.6 35.6z" opacity=".8" />
      <path d="m482.2 684-35.6-35.59 35.6-35.6 35.59 35.6-35.6 35.6z" opacity=".8" />
    </g>
    <path d="m573.5 305.1-270.6-211.1-266.8 211-35.72-45.2 302.2-239.2 306.5 239.1-35.62 45.5z" fill="#683d28" />
    <path d="m422.2 775h-239.1v-405.5h239.1v405.5z" fill="#1e1d1b" />
    <path
      d="m303.5 250c-66.2 0-119.7 53.5-119.7 119.4v405.5h239.1v-405.5c0.1-65.9-53.4-119.4-119.4-119.4"
      fill="#ef8800"
    />
    <path d="m303.5 20v754.6h201.2l54.12-481.8 15.28 11.87 35.59-45.52-306.2-239.2z" fill="#ff9700" opacity=".5" />
  </svg>
`;
