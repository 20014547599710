import { html } from 'lit';

export const c7 = html`
  <svg
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    viewBox="0 0 620 670"
  >
    <path
      d="m504.6 464.6c0 87.2-82.95 158.6-185.2 158.6-102.3 0-185.2-71.48-185.2-158.6 0-87.19 82.95-157.9 185.2-157.9 102.3 0 185.2 70.69 185.2 157.9"
      fill="#b3b3b3"
    />
    <g transform="translate(115.4 82.37)" fill="#c1272d">
      <path
        d="m2.775 230.9c10.06-5.778 21.74-8.356 33.96-8.53 6.104 0.1325 12.31 1.02 18.4 2.766 6.036 1.844 11.89 4.534 17.32 7.895 5.425 3.368 10.8 7 15.65 11.25 4.839 4.261 8.562 9.515 10.98 15.24 4.709 11.54 6.365 23.96 2.246 36.82l-2.7 0.86c-10.74-7.965-17.42-15.95-23.9-23.26-3.158-3.668-5.694-7.44-7.964-11.51-2.212-4.101-4.745-8.132-8.039-11.38-3.245-3.298-6.899-6.198-10.95-8.6-3.979-2.536-8.398-4.576-13.12-6.086-9.425-3.275-20.2-4.059-30.86-2.815l-1.025-2.648z"
      />
      <path
        d="m0.025 189.4c6.77-5.164 14.41-9.012 22.78-11.51 8.335-2.502 17.52-3.536 26.82-2.118 9.246 1.495 18.28 5.589 25.82 11.35 7.532 5.828 13.59 13.08 18.6 20.66 5.051 7.95 9.044 14.75 13.39 22.24 4.098 7.42 7.291 15.32 9.281 23.74 1.946 8.384 3.19 17.1 2.939 26.09-0.3188 8.872-1.181 18.35-7.008 26.74l-2.676-0.9225c-5.785-15.31-13.66-28.56-20.06-42.78-3.256-7.05-6.044-14.31-8.294-21.92-2.186-7.434-4.471-15.74-7.315-22.08-5.988-13.11-14.94-24.4-28.18-29.39-13.16-5.34-29.85-3.496-44.62 2.318l-1.488-2.418z"
      />
      <path
        d="m73.4 167.2c7.136-3.074 15.28-3.361 23.4-1.572 4.002 0.9925 7.968 2.536 11.72 4.549 3.664 2.116 7.098 4.674 10.21 7.524 3.124 2.858 6.355 5.709 9.232 8.874 2.834 3.192 4.671 6.972 5.372 11.02 0.6925 4.024 0.6962 8.181-0.275 12.35-0.8738 4.15-2.495 8.321-5.806 12.52l-2.788 0.5375c-4.632-2.591-7.818-5.155-10.57-7.67-2.834-2.481-5-4.926-6.865-7.309-1.859-2.389-2.951-4.884-3.496-7.74-0.5175-2.859-1.098-5.806-2.359-8.308-1.236-2.515-2.702-4.911-4.429-7.119-1.645-2.314-3.599-4.465-5.869-6.372-4.444-3.954-10.39-7.014-17-8.51l-0.4625-2.762z"
      />
    </g>
    <path
      d="m316.2 466.2c-1.376-0.035-2.708-0.175-4.031-0.3625-86.8-3.508-133.4-71.05-133.4-71.05-12.76 38.41-12.82 81.62-12.82 81.62 2.009 82.95 69.82 149.5 153.2 149.5v-160c-0.6912 0.0962-1.295 0.25-1.941 0.25h-1.059z"
      fill="#3c3c3b"
    />
    <path
      d="m213.6 273.4-0.0488-0.0625s0.004 0.3062 0.004 0.7962c-0.22 2.844-0.4238 5.701-0.4238 8.608 0 0.8862 0.0913 1.752 0.105 2.64-0.5975 10.7-2.186 27.4-6.84 45.02-3.28 12.36-8.041 25.16-15.1 36.66-5.128 8.384-9.184 17.89-12.44 27.7 0 0 46.62 67.55 133.4 71.05h0.0213c1.306 0.1875 2.641 0.3275 4.01 0.3625h1.059c0.6462 0 1.25-0.1538 1.904-0.195v-289.3c-55.42-0.05-100.9 42.5-105.6 96.75"
      fill="#b3b3b3"
    />
    <path
      d="m460.2 396.5s-49.72 69.54-141 69.54v160c83.48 0 151.4-66.72 153.2-149.8 0 0-0.0425-41.82-12.21-79.76"
      fill="#575756"
    />
    <path
      d="m447.3 367c-6.445-10.57-11.01-22.25-14.24-33.65-5.534-19.48-7.21-38.19-7.745-49.38 0-0.4325 0.045-0.8525 0.045-1.285 0-1.936-0.1888-3.829-0.2862-5.736-0.0425-2.285-0.0137-3.668-0.0137-3.668l-0.1612 0.2438c-4.664-54.34-50.15-96.99-105.7-96.99v289.3c91.29 0 141-69.54 141-69.54-3.265-10.34-7.468-20.46-12.88-29.34"
      fill="#ccc"
    />
    <g transform="translate(115.4 82.37)" fill="#999">
      <path
        d="m242 275.4v-0.0625c0-23.58-21.34-42.71-38.92-42.71-17.59 0-38.92 19.12-38.92 42.71 0 9.445-0.73 34.25 17.11 34.25 5.258 0 9.906-2.424 13.05-6.162 2.949 0.315 5.918 0.4688 8.768 0.4688 2.865 0 5.824-0.1538 8.779-0.4688 3.126 3.739 7.78 6.162 13.05 6.162 17.8 0.0125 17.1-24.69 17.1-34.18"
      />
      <path
        d="m160.3 154.9c-26.09 0-47.24 20.1-47.24 44.88 0 24.8 21.15 44.89 47.24 44.89 19.5 0 36.24 2.844 43.46-13.12v-49.36c-7.225-16.04-23.95-27.28-43.46-27.28"
      />
      <path
        d="m247.4 244.6c26.09 0 47.25-20.09 47.25-44.89 0-24.79-21.16-44.88-47.25-44.88-19.56 0-36.34 11.29-43.52 27.38-0.015-0.0288-0.0425-0.07-0.0563-0.105v49.36c0.0138-0.0412 0.0413-0.0687 0.0563-0.1112 7.188 16.1 23.96 13.24 43.52 13.24"
      />
    </g>
    <path
      d="m310.9 316.2-11.44 7.545c-4.601 3.04-6.054 9.398-3.231 14.14l17.91 30.06c1.415 2.382 3.276 3.57 5.135 3.57v-57.61c-3.049 0.004-6.064 0.7638-8.372 2.288"
      fill="#f39e2b"
    />
    <path
      d="m324.4 367.9 17.91-30.08c2.83-4.744 1.37-11.1-3.235-14.14l-11.44-7.545c-2.306-1.524-5.339-2.285-8.37-2.285v57.61c1.859 0 3.72-1.188 5.131-3.57"
      fill="#fbdd58"
    />
    <path
      d="m284 282.3c0 8.461-6.868 15.31-15.31 15.31-8.46 0-15.31-6.854-15.31-15.31 0-8.446 6.854-15.3 15.31-15.3 8.445 0 15.31 6.854 15.31 15.3"
      fill="#4e1412"
    />
    <path
      d="m383.7 282.3c0 8.461-6.864 15.31-15.31 15.31-8.45 0-15.31-6.854-15.31-15.31 0-8.446 6.86-15.3 15.31-15.3 8.449 0 15.31 6.854 15.31 15.3"
      fill="#4e1412"
    />
    <path
      d="m279.6 276.2c0 2.892-2.348 5.24-5.232 5.24-2.892 0-5.234-2.348-5.234-5.24s2.341-5.232 5.234-5.232c2.885 0 5.232 2.34 5.232 5.232"
      fill="#fff"
    />
    <path
      d="m378.8 276.2c0 2.892-2.338 5.24-5.23 5.24-2.891 0-5.232-2.348-5.232-5.24s2.341-5.232 5.232-5.232c2.892 0 5.23 2.34 5.23 5.232"
      fill="#fff"
    />
    <path
      d="m329 154.5c-5.812 0-10.52-4.715-10.52-10.52v-35.06c0-5.819 4.711-10.52 10.52-10.52 5.815 0 10.52 4.704 10.52 10.52v35.06c0.002 5.81-4.699 10.52-10.51 10.52"
      fill="#b6382f"
    />
    <path
      d="m347.8 165c-5.819 0-10.52-4.708-10.52-10.52v-35.06c0-5.811 4.701-10.52 10.52-10.52 5.805 0 10.52 4.704 10.52 10.52v35.06c-0.001 5.805-4.712 10.51-10.52 10.51"
      fill="#b6382f"
    />
    <path
      d="m292.8 82.5c7.151 5.736 11.89 11.66 16.14 16.68 2.16 2.624 3.698 4.03 5.871 6.462 2.194 2.594 4.628 5.785 6.621 9.35 4.071 7.11 6.591 15.75 6.456 24.28-0.1975 8.53-2.659 16.95-8.785 23.89-5.552-7.394-9.201-13.39-12.68-18.81-3.428-5.441-6.418-10.2-9.422-15.55-1.53-2.686-2.995-5.521-4.639-8.969-1.769-3.569-3.538-8.564-3.898-12.65-0.8888-8.4 0.1675-16.39 4.321-24.68"
      fill="#ea575b"
    />
    <g fill-rule="evenodd">
      <path
        d="m393.8 157.3c0.9612-9.531-5.999-18.01-15.49-18.98 0 0-32.71 4.164-49.49 1.919-16.7-2.204-49.5-11.9-49.5-11.9-9.498-0.9588-17.94 5.969-18.9 15.5l-15.5 50.78 64.81 6.538 6.784 0.6838 82.38 8.3-5.075-52.84z"
        fill="#ed1e79"
      />
      <path
        opacity=".47"
        d="m252.6 169.2-7.584 24.88 64.81 6.538 6.782 0.6838 82.38 8.309-2.499-25.9-143.9-14.51z"
        fill="#fcee21"
      />
      <path
        d="m162.8 201.2 0.44-4.362c0.9525-9.441 9.426-16.35 18.85-15.4l280.1 28.42c9.276 0.935 16.22 9.505 15.28 18.85l-0.375 4.188-314.2-31.7z"
        fill="#ed1e79"
      />
      <path
        opacity=".33"
        d="m462.2 209.4-63.9-6.482-4.42-46.1c0.9612-9.531-5.999-18.01-15.49-18.98 0 0-32.71 4.162-49.49 1.918-0.585-0.0588-1.276-0.2038-1.895-0.305l-7.772 77.06 157.9 15.91 0.4212-4.181c0.975-9.345-5.974-17.91-15.25-18.85"
        fill="#917561"
      />
    </g>
    <g stroke-miterlimit="10">
      <circle opacity=".7" stroke-width="14.36" cy="281.9" cx="378.8" r="52.16" />
      <circle opacity=".7" cx="261.1" cy="281.9" r="52.16" stroke-width="14.36" />
      <path
        d="m304.3 286.4c7.915-7.825 20.68-7.751 28.5 0.1638"
        stroke="#000"
        stroke-linecap="round"
        stroke-width="6.436"
        fill="none"
      />
    </g>
  </svg>
`;
