import { shuffle } from '@ounce/onc';

export class RewardSource {
  /**
   * @param {any } rewardSet
   * @param {any } iconOrGen
   */
  constructor(rewardSet, iconOrGen) {
    /**
     * @type {[x: number, y: number, w: number, h: number][]}
     */
    this.locations = shuffle.getRandomSubarray(rewardSet.locations ?? rewardSet);

    /**
     * @type {Generator<[x: number, y: number, w: number, h: number], [], unknown>}
     */
    this.locationsIt = this.locationsGen(this.locations);

    /**
     * @type {Generator<string, string, unknown>}
     */
    this.iconIt = iconOrGen.next ? iconOrGen : this.iconGen(iconOrGen);

    this.rewards_ = [];
  }

  /**
   * @param {string} icon
   * @returns {Generator<string, string, unknown>}
   */
  *iconGen(icon) {
    for (;;) {
      yield icon;
    }
  }

  /**
   * @param {[x: number, y: number, w: number, h: number][]} locations
   * @returns {Generator<[x: number, y: number, w: number, h: number], [], unknown>}
   */
  *locationsGen(locations) {
    for (;;) {
      for (const item of locations) {
        yield item;
      }
    }
  }

  getNextReward() {
    const [x, y, w, h] = this.locationsIt.next().value;
    const graphic = this.iconIt.next().value;

    return { x, y, w, h, graphic };
  }

  /**
   * @param {number} count
   */
  addRewards(count) {
    if (count < 0) {
      for (; count < 0; count++) {
        this.rewards_.pop();
      }
    } else {
      for (let index = 0; index < count; index++) {
        this.rewards_.push(this.getNextReward());
      }
    }
  }

  get rewards() {
    return this.rewards_;
  }
}
