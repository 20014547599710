import { beehive } from './beehive.js';
import { boots } from './boots.js';
import { egg } from './egg.js';
import { fence } from './fence.js';
import { kennelX } from './kennel-x.js';
import { kennel } from './kennel.js';
import { shrub } from './shrub.js';
import { waterTower } from './water-tower.js';
import { windmill } from './windmill.js';

export const memory = { beehive, boots, egg, fence, kennelX, kennel, shrub, waterTower, windmill };
